import { Operation, split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { FragmentDefinitionNode, OperationDefinitionNode } from "graphql";
import { httpLink } from "./httpLink";
import { uploadLink } from "./uploadLink";
import { wsLink } from "./wsLink";

const requestLink = split(isSubscriptionOperation, wsLink, httpLink);

export const terminalLink = split(isUpload, uploadLink, requestLink);

function isFile(value: unknown): boolean {
  return (
    (typeof File !== "undefined" && value instanceof File) ||
    (typeof Blob !== "undefined" && value instanceof Blob) ||
    (Array.isArray(value) &&
      value.some((item) =>
        Object.keys(item).length > 1
          ? Object.values(item).some(isFile)
          : isFile(item),
      ))
  );
}

function isUpload({ variables }: Operation) {
  return Object.values(variables).some(isFile);
}

function isOperationDefinitionNode(
  node: OperationDefinitionNode | FragmentDefinitionNode,
): node is OperationDefinitionNode {
  return node.kind === "OperationDefinition";
}

function isSubscriptionOperation({ query }: Operation) {
  const node = getMainDefinition(query) as OperationDefinitionNode;

  if (!isOperationDefinitionNode(node)) {
    return false;
  }

  return node.operation === "subscription";
}
