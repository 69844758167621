import React from "react";
import classNames from "classnames";
import styles from "./Description.module.scss";

export interface DescriptionProps {
  center?: boolean;
  spacious?: boolean;
}

export const Description: React.FC<DescriptionProps> = ({
  center,
  spacious,
  children,
}) => (
  <span
    className={classNames(styles.description, {
      [styles["description--center"]]: center,
      [styles["description--spacious"]]: spacious,
    })}
  >
    {children}
  </span>
);
