import React, { useEffect } from "react";
import { Modal } from "../../components/Modal/Modal";
import {
  ViewerOrganizations,
  useViewer,
  useUpdateWhitelistedDomains,
} from "../../schema";
import { Button } from "../../components/Button/Button";
import { Field } from "../../components/Field/Field";
import { Form } from "../../components/Form/Form";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./CompanyDetailsView.module.scss";

export interface UpdateSecurityProps {
  activeOrganization: ViewerOrganizations;
  whitelistedDomains?: string[];
  onModalClose(): void;
}

export const UpdateSecurity: React.FC<UpdateSecurityProps> = ({
  activeOrganization,
  whitelistedDomains,
  onModalClose,
}) => {
  // load active organization
  const { loading: isFetching, error: fetchError } = useViewer({
    fetchPolicy: "network-only",
    variables: {
      organizationId: activeOrganization.id,
    },
  });

  // setup form
  const {
    useInput,
    loading: isSaving,
    error: saveError,
    submit: updateWhitelistedDomains,
  } = useForm({
    mutation: useUpdateWhitelistedDomains,
    options: { refetchQueries: ["Viewer"] },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const domainsInput = useInput({ name: "domainWhitelist", optional: true });

  useEffect(() => {
    if (!activeOrganization || !whitelistedDomains) {
      return;
    }

    domainsInput.setValue(whitelistedDomains.join(", "));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, whitelistedDomains]);

  // handle loading error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading organization failed"
        error={fetchError}
        modal={{ title: "Update organization", onModalClose }}
      />
    );
  }

  // handle saving error
  if (saveError) {
    return (
      <ErrorView
        title="Updating organization failed"
        error={saveError}
        modal={{ title: activeOrganization.companyName, onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !activeOrganization) {
    return <LoadingView overlay />;
  }

  // submits the form
  const submit = () => {
    updateWhitelistedDomains({
      organizationId: activeOrganization.id,
      domainWhitelist: domainsInput.value
        ? domainsInput.value.toString().replace(/@/g, "").split(/,\s?/)
        : undefined,
    });
  };

  return (
    <Modal
      title="White-listed domains"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button
            data-testid="2b5f4623f4"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="0e83d4e80f"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <div className={styles["white-list-info"]}>
        <p>Please list the domains from where receiving PR-s is enabled.</p>
        <p>
          Domains should be entered in a form of &quot;@companyname.com,
          @company.eu&quot;.
        </p>
      </div>

      <Form onSubmit={submit}>
        <Field {...domainsInput} short textarea autofocus label="Domains" />
      </Form>
    </Modal>
  );
};
