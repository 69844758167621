import React, {
  FocusEvent,
  InputHTMLAttributes,
  useState,
  useContext,
} from "react";
import classNames from "classnames";
import ReactAutosuggest, {
  InputProps,
  OnSuggestionSelected,
  OnSuggestionsClearRequested,
  RenderInputComponent,
  RenderSuggestion,
  RenderSuggestionsContainer,
  SuggestionsFetchRequested,
  AutosuggestPropsSingleSection,
} from "react-autosuggest";
import useOnClickOutside from "use-onclickoutside";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { Loading } from "../Loading/Loading";
import { Button } from "../Button/Button";
import { EditableModeContext } from "../Form/Form";
import { FieldError } from "../FieldError/FieldError";
import { NotAvailable } from "../NotAvailable/NotAvailable";
import styles from "./Autosuggest.module.scss";

// export as types to make it easier to define props in using components
export type FetchSuggestions<TSuggestion> = (
  value: string,
) => Promise<TSuggestion[]>;
export type GetSuggestionValue<TSuggestion> = (
  suggestion: TSuggestion,
) => string;
export type RenderAddon<TSuggestion> = (
  suggestion: TSuggestion,
) => React.ReactNode;
export type GetCreateNewText = (value: string) => string;
export type OnChoose<TSuggestion> = (item: TSuggestion | undefined) => void;
export type OnChange = (newValue: string) => void;
export type OnBlur = (
  e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement>,
) => void;
export type OnCreateNew = (value: string) => void | Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AutosuggestProps<TSuggestion>
  extends Partial<AutosuggestPropsSingleSection<TSuggestion>> {
  value: string;
  chosenSuggestion: TSuggestion | undefined;
  fetchSuggestions: FetchSuggestions<TSuggestion>;
  renderSuggestion: RenderSuggestion<TSuggestion>;
  getSuggestionValue: GetSuggestionValue<TSuggestion>;
  onChange: OnChange;
  onChoose: OnChoose<TSuggestion>;
  onCreateNew?: OnCreateNew;
  onBlur?: OnBlur;
  name?: string;
  label?: string;
  placeholder?: string;
  errors?: string[];
  required?: boolean;
  short?: boolean;
  clearable?: boolean;
  loading?: boolean;
  lockedLabel?: string;
  suggestForEmpty?: boolean;
  renderAddon?: RenderAddon<TSuggestion>;
  getCreateNewText?: GetCreateNewText;
  className?: string;
  wide?: boolean;
  hasPermissionToCreateNew?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Autosuggest: React.FC<AutosuggestProps<any>> = ({
  fetchSuggestions,
  renderSuggestion,
  getSuggestionValue,
  name,
  label,
  placeholder,
  value,
  chosenSuggestion,
  errors,
  required,
  short,
  clearable,
  loading: isExternalLoading,
  lockedLabel,
  suggestForEmpty,
  renderAddon,
  getCreateNewText,
  onChange,
  onBlur,
  onChoose,
  onCreateNew,
  className,
  wide,
  hasPermissionToCreateNew = true,
  ...rest
}) => {
  // we don't really know the type here
  type TSuggestion = unknown;

  // setup local state (most of the state is managed externally)
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [suggestions, setSuggestions] = useState<TSuggestion[]>([]);

  // context set by form to indicate if all its child inputs should be enabled (true) or disabled (false)
  const isEditable = useContext(EditableModeContext);

  // reference to the wrap surrounding autosuggest
  const wrapRef = React.useRef<HTMLDivElement>(null);

  // reference to the autosuggest
  const autosuggestRef = React.useRef<ReactAutosuggest>(null);

  // handle clicking outside the autosuggest component
  useOnClickOutside(wrapRef, () => {
    // clear value if no suggestion has been chosen
    if (chosenSuggestion === undefined && value !== "") {
      onChange("");
    }
  });

  // called when new suggestions need to be fetched, fetches suggestions
  const onSuggestionsFetchRequested: SuggestionsFetchRequested = async ({
    value,
  }) => {
    // setup loading state
    setIsFetchLoading(true);
    setError(undefined);

    // attempt to fetch new suggestions
    try {
      // TODO: fetching data should be debounced but loading & error state should not
      const suggestions = await fetchSuggestions(value);

      setSuggestions(suggestions);
    } catch (error) {
      setError(`Fetching suggestions failed (${error.message})`);
      setSuggestions([]);
    } finally {
      setIsFetchLoading(false);
    }
  };

  // called when clearing suggestions is requested
  const onSuggestionsClearRequested: OnSuggestionsClearRequested = () => {
    setSuggestions([]);
    setError(undefined);
  };

  // called when a suggestion is selected
  const onSuggestionSelected: OnSuggestionSelected<TSuggestion> = (
    _event,
    { suggestion },
  ) => {
    // set chosen suggestion
    onChange(getSuggestionValue(suggestion));
    onChoose(suggestion);
  };

  // combine internal error with external errors
  const combinedErrors = [...(errors || []), ...(error ? [error] : [])];

  // combined loading state
  const isLoading = isFetchLoading || isCreateLoading || isExternalLoading;

  // field identifier
  const fieldId = `${name}-field`;

  // resolve whether no suggestions could be found
  const isSuggestionNotFound =
    value.length > 0 &&
    !isCreateLoading &&
    combinedErrors.length === 0 &&
    suggestions.length === 0 &&
    chosenSuggestion === undefined;

  // attempts to create new item, handles loading and errors
  const createNew = async () => {
    if (!onCreateNew) {
      return;
    }

    // initiate loading state
    setIsCreateLoading(true);

    // attempt to create new item using provided callback
    try {
      await onCreateNew(value);
    } catch (error) {
      console.error("autosuggest onCreateNew callback threw error", error);

      setIsCreateLoading(false);
    } finally {
      // clear loading state
      setIsCreateLoading(false);

      // blur the autosuggest input
      if (autosuggestRef.current) {
        autosuggestRef.current.input?.blur();
      }
    }
  };

  // props passed to the autosuggest input
  const inputProps: InputProps<TSuggestion> = {
    name,
    value,
    placeholder,
    onBlur: (e) => {
      // call the external blur handler if set
      if (typeof onBlur === "function") {
        onBlur(e);
      }
    },
    onChange: (_e, { newValue /*, method */ }) => {
      // update value and clear chosen suggestion
      onChange(newValue);

      if (chosenSuggestion !== undefined) {
        onChoose(undefined);
      }
    },
    onKeyDown: (e) => {
      // persist event for async processing
      // https://reactjs.org/docs/events.html#event-pooling
      // e.persist();

      // handle enter
      if (e.which === 13) {
        // create new item on enter key press if no suggestion was found
        if (
          isSuggestionNotFound &&
          onCreateNew &&
          value &&
          hasPermissionToCreateNew
        ) {
          createNew();
        }

        // avoid submitting the form on enter
        e.preventDefault();

        return false;
      }
    },
  };

  // renders the suggestions container
  const renderSuggestionsContainer: RenderSuggestionsContainer = ({
    containerProps,
    children /*, query*/,
  }) => (
    <div {...containerProps} className={styles.suggestions}>
      {children}
    </div>
  );

  // renders the input
  const renderInputComponent: RenderInputComponent = (inputProps) => (
    <>
      <input
        {...(inputProps as InputHTMLAttributes<HTMLInputElement>)}
        className={classNames(styles.input, {
          [styles["input--wide"]]: wide,
        })}
        autoComplete="none"
      />
      {isLoading ? (
        <div className={styles.addon}>
          <Loading small />
        </div>
      ) : chosenSuggestion && renderAddon ? (
        <div className={styles.addon}>{renderAddon(chosenSuggestion)}</div>
      ) : null}
    </>
  );

  // render simple label-value if not editable
  if (!isEditable) {
    return (
      <div className={styles["information"]}>
        <div className={styles["left-column"]}>{lockedLabel || label}</div>
        <div className={styles["right-column"]}>
          {value ? value : <NotAvailable alignLeft />}
        </div>
      </div>
    );
  }

  // render the autosuggest field
  return (
    <div ref={wrapRef} className={classNames({ [styles["wrap--wide"]]: wide })}>
      {label && (
        <FieldLabel label={label} htmlFor={fieldId} required={required} />
      )}
      <div
        className={classNames(
          styles.autosuggest,
          "notranslate",
          { [styles["autosuggest--short"]]: short },
          { [styles["autosuggest--wide"]]: wide },
          className,
        )}
      >
        <div className={styles.content}>
          <ReactAutosuggest
            ref={autosuggestRef}
            highlightFirstSuggestion
            focusInputOnSuggestionClick={false}
            shouldRenderSuggestions={
              suggestForEmpty === true ? () => true : undefined
            }
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSuggestionsContainer={renderSuggestionsContainer}
            renderInputComponent={renderInputComponent}
            inputProps={inputProps}
            {...rest}
          />
          {onCreateNew &&
            value &&
            isSuggestionNotFound &&
            hasPermissionToCreateNew && (
              <div
                className={classNames(
                  styles.suggestions,
                  styles["suggestions--not-found"],
                )}
              >
                <Button
                  data-testid="2cad47d9d9"
                  text
                  onClick={() => {
                    // ignore creating new if currently loading
                    if (isLoading) {
                      return;
                    }

                    // attempt to create new item
                    createNew();
                  }}
                >
                  {getCreateNewText
                    ? getCreateNewText(value)
                    : `Create new called "${value}"`}
                </Button>
              </div>
            )}
          {errors !== undefined &&
            errors.map((error, index) => (
              <FieldError key={index} error={error} />
            ))}
        </div>

        {clearable && chosenSuggestion && isEditable && (
          <div
            className={styles.clear}
            onClick={() => {
              onChange("");
              onChoose(undefined);
            }}
          >
            Clear
          </div>
        )}
      </div>
    </div>
  );
};
