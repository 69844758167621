import React, { useCallback, useEffect, useState } from "react";
import { Form } from "../../components/Form/Form";
import { Button } from "../../components/Button/Button";
import {
  ViewerOrganizations,
  useUploadProductExcel,
  usePurchaseRequestByCode,
} from "../../schema";
import { Modal } from "../../components/Modal/Modal";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { FieldError } from "../../components/FieldError/FieldError";
import { ExcelProductsImportErrorsTable } from "../../components/ExcelImportErrorsTable/ExcelProductsImportErrorsTable";
import { ExcelProductsImportExampleTable } from "../../components/ExcelImportExampleTable/ExcelProductsImportExampleTable";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import styles from "../PurchaseRequestView/PurchaseRequestView.module.scss";
import { useRouter } from "../../hooks/useRouter";

interface RouteParams {
  purchaseRequestCode: string;
}

export interface UploadExcelFormProps {
  organization: ViewerOrganizations;
  onModalClose(): void;
}

export const UploadItemsExcelToPurchaseRequest: React.FC<UploadExcelFormProps> =
  ({ organization, onModalClose }) => {
    // extract modal code from URL parameter
    const { match, history } = useRouter<RouteParams>();
    const { purchaseRequestCode } = match.params;

    // attempt to load purchase request info
    const { data: prData } = usePurchaseRequestByCode({
      fetchPolicy: "network-only",
      variables: {
        organizationId: organization.id,
        code: purchaseRequestCode,
      },
    });

    const purchaseRequestId = prData?.viewer?.purchaseRequestByCode.id;

    // TODO: consider using useForm and implementing useDropzone etc
    // setup state (not using form for a single input)
    const [selectedFile, setSelectedFile] = useState<File | undefined>(
      undefined,
    );

    // setup uploading product excel
    const [uploadProductExcel, { loading, error, data: uploadData }] =
      useUploadProductExcel({
        refetchQueries: ["PaginatedPurchaseRequests"],
        awaitRefetchQueries: true,
      });

    // handles file drop
    const onDrop = useCallback<OnDropCallbackFn>((acceptedFiles) => {
      // return if not exactly one file was provided
      if (acceptedFiles.length !== 1) {
        return;
      }

      // set selected file
      setSelectedFile(acceptedFiles[0]);
    }, []);

    // get import result
    const uploadResult = uploadData ? uploadData.uploadProductExcel : undefined;

    // redirect to PR modal on success
    useEffect(() => {
      if (uploadResult?.success) {
        history.push(`/${organization.urlName}/PR-${purchaseRequestCode}`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadResult]);

    const submit = async () => {
      // we can only upload a file if it has been selected
      if (!selectedFile) {
        return;
      }

      if (purchaseRequestId) {
        // attempt to import the products excel file
        await uploadProductExcel({
          variables: {
            organizationId: organization.id,
            name: "",
            purchaseRequestId: purchaseRequestId,
            excel: selectedFile,
          },
        });

        // clear selected file
        setSelectedFile(undefined);
      }
    };

    const hasErrors = uploadResult && !uploadResult.success && !selectedFile;

    return (
      <Modal
        wide
        title={`Upload Items from Excel to PR-${purchaseRequestCode}`}
        onCloseRequested={() => onModalClose()}
        footer={
          <>
            <Button
              data-testid="671b09ce6d"
              secondary
              onClick={() => {
                history.replace(
                  `/${organization.urlName}/PR-${purchaseRequestCode}`,
                );
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="e6bd4d54d7"
              loading={loading}
              disabled={loading || selectedFile === undefined}
              onClick={submit}
            >
              Import
            </Button>
          </>
        }
      >
        <div className={styles["wrap"]}>
          <Form onSubmit={submit}>
            <Dropzone
              required
              multiple={false}
              loading={loading}
              error={error}
              selectedFile={selectedFile}
              label="Spreadsheet to import"
              rejectTypeText="Please provide valid spreadsheet file (.csv, .xls, .xlsx)"
              onDrop={onDrop}
            >
              Drag and drop your product list Excel / CSV file here
            </Dropzone>

            {hasErrors && uploadResult ? (
              <div>
                <FieldError error="Provided spreadsheet file is not valid, please fix the issues listed below and try again" />
                <ExcelProductsImportErrorsTable rows={uploadResult.rows} />
              </div>
            ) : (
              <>
                <FieldLabel label="Example spreadsheet structure" />
                <ExcelProductsImportExampleTable />
              </>
            )}
          </Form>
        </div>
      </Modal>
    );
  };
