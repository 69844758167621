import React, { CSSProperties } from "react";
import classNames from "classnames";
import { RenderElementProps } from "slate-react";
import { Element } from "slate";
import styles from "./CodeElement.module.scss";

export interface CodeElementProps extends RenderElementProps {
  className?: string;
  style?: CSSProperties;
}

export type CodeElementType = Element & { type: "code" };

export const CodeElement: React.FC<CodeElementProps> = (props) => {
  const { className, attributes, children, style } = props;

  return (
    <pre
      style={style}
      className={classNames(styles["wrap"], className)}
      {...attributes}
    >
      <code>{children}</code>
    </pre>
  );
};

// helper that determines if an element is a CodeElement
export function isCodeElement(element: Element): element is CodeElementType {
  return element.type === "code";
}
