import React from "react";
import classNames from "classnames";
import { RenderSuggestion } from "react-autosuggest";
import { renderHighlighted } from "../../services/renderHighlighted";
import {
  Autosuggest,
  FetchSuggestions,
  GetCreateNewText,
  GetSuggestionValue,
  OnChoose,
  OnCreateNew,
  AutosuggestProps,
} from "../Autosuggest/Autosuggest";
import {
  SupplierFullTextSearchResultTypeEnum,
  SupplierStatusEnum,
} from "../../schema";
import { Avatar } from "../Avatar/Avatar";
import { assertUnreachable } from "../../services/assertUnreachable";
import { ContactPersonIcon } from "../../theme/svg/ContactPersonIcon";
import styles from "./SupplierAutosuggest.module.scss";

export enum SuggestionType {
  CATEGORY = "CATEGORY",
  SUPPLIER = "SUPPLIER",
  CONTACT_PERSON = "CONTACT_PERSON",
}
export interface SupplierSuggestion {
  id: string;
  name: string;
  email: string | null;
  type: SupplierFullTextSearchResultTypeEnum;
  status: SupplierStatusEnum | null;
  isUsed?: boolean;
}

export type SupplierAutosuggestProps = Partial<
  AutosuggestProps<SupplierSuggestion>
> & {
  className?: string;
  fetchSuggestions: FetchSuggestions<SupplierSuggestion>;
  onChoose: OnChoose<SupplierSuggestion>;
  onCreateNew?: OnCreateNew;
};

export const SupplierAutosuggest: React.FC<SupplierAutosuggestProps> = (
  props,
) => {
  const { className, fetchSuggestions, onChoose, onCreateNew, ...rest } = props;

  const [userTypedValue, setUserTypedValue] = React.useState("");

  const [chosenSuggestion, setChosenSuggestion] =
    React.useState<SupplierSuggestion>();

  const getSuggestionValue: GetSuggestionValue<SupplierSuggestion> = (
    suggestion,
  ) => {
    return suggestion.name;
  };

  const getEmail = (suggestion: SupplierSuggestion): string => {
    return suggestion.email ? suggestion.email : "N/A";
  };

  const getVirtualUser = (suggestion: SupplierSuggestion) => {
    switch (suggestion.type) {
      case SupplierFullTextSearchResultTypeEnum.CATEGORY: {
        return {
          id: suggestion.id,
          firstName: "",
          lastName: "",
          icon: <div className={styles["srm-icon"]}>SRM</div>,
        };
      }

      case SupplierFullTextSearchResultTypeEnum.CONTACT_PERSON: {
        return {
          id: suggestion.id,
          firstName: "",
          lastName: "",
          icon: (
            <div className={styles["contact-person-icon"]}>
              <ContactPersonIcon />
            </div>
          ),
        };
      }

      case SupplierFullTextSearchResultTypeEnum.SUPPLIER: {
        return {
          id: suggestion.id,
          firstName: suggestion.name,
          lastName: "",
          avatarUrl: null,
        };
      }

      default:
        assertUnreachable(
          suggestion.type,
          `Unhandled suggestion type ${suggestion.type}. This should not happen.`,
        );
    }
  };

  const renderSupplierStatus = (status: SupplierStatusEnum) => {
    switch (status) {
      case SupplierStatusEnum.NEW:
        return (
          <div
            className={classNames(
              styles["supplier-status"],
              styles["supplier-status--new"],
            )}
          >
            NEW
          </div>
        );

      case SupplierStatusEnum.APPROVED:
        return (
          <div
            className={classNames(
              styles["supplier-status"],
              styles["supplier-status--approved"],
            )}
          >
            APPROVED
          </div>
        );

      case SupplierStatusEnum.INACTIVE:
        return (
          <div
            className={classNames(
              styles["supplier-status"],
              styles["supplier-status--inactive"],
            )}
          >
            INACTIVE
          </div>
        );

      case SupplierStatusEnum.ARCHIVED:
        return (
          <div
            className={classNames(
              styles["supplier-status"],
              styles["supplier-status--archived"],
            )}
          >
            ARCHIVED
          </div>
        );

      default:
        return assertUnreachable(
          status,
          `Status "${status}" is not handled, this should not happen`,
        );
    }
  };

  const renderSuggestion: RenderSuggestion<SupplierSuggestion> = (
    suggestion,
    { query, isHighlighted },
  ) => {
    return (
      <div
        className={classNames(styles.suggestion, {
          [styles["suggestion--highlight"]]: isHighlighted,
          [styles["suggestion--used"]]: suggestion.isUsed,
        })}
      >
        {<Avatar large user={getVirtualUser(suggestion)} />}

        <div
          className={classNames(styles.name, {
            [styles["name--category"]]:
              suggestion.type === SupplierFullTextSearchResultTypeEnum.CATEGORY,
          })}
        >
          <div>
            {renderHighlighted(
              suggestion.name,
              query,
              styles["text-highlight"],
            )}
          </div>
          {suggestion.name && suggestion.email && (
            <div className={styles.email}>
              {renderHighlighted(
                getEmail(suggestion),
                query,
                styles["text-highlight"],
              )}
            </div>
          )}
        </div>

        {suggestion.type === SupplierFullTextSearchResultTypeEnum.SUPPLIER &&
          suggestion.status &&
          renderSupplierStatus(suggestion.status)}
      </div>
    );
  };

  const onChange = (value: string) => {
    setUserTypedValue(value);
  };

  const onChooseProxy: OnChoose<SupplierSuggestion> = (chosen) => {
    setChosenSuggestion(chosen);
    onChoose(chosen);
    setUserTypedValue("");
  };

  // returns text for creating new item
  const getCreateNewText: GetCreateNewText = (userInputText) =>
    `Add new supplier "${userInputText}"`;

  return (
    <div className={classNames([styles["wrap"], className])}>
      <Autosuggest
        value={userTypedValue}
        onChange={onChange}
        chosenSuggestion={chosenSuggestion}
        fetchSuggestions={fetchSuggestions}
        onChoose={onChooseProxy}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        getCreateNewText={getCreateNewText}
        onCreateNew={onCreateNew}
        {...rest}
      />
    </div>
  );
};
