import { getSafeHtmlComponent } from "./getSafeHtmlComponent";

export function convertHtmlStringToSafeJsx(
  htmlString: string,
  className?: string,
) {
  // find all mention placeholders in html string
  const mentionPlaceholderRe = /\{\{@\[(.+?)\}\}/g;
  const allMentionMatches = htmlString.match(mentionPlaceholderRe);

  // find all hashtagged mentions in html string
  const tagPlaceholderRe = /\{\{#\[(.+?)\}\}/g;
  const allTagMatches = htmlString.match(tagPlaceholderRe);

  // initiate replaced string with input arg value
  let replacedString = htmlString;

  // replace all mention templates with a mentioned name
  if (allMentionMatches) {
    allMentionMatches.forEach((mention) => {
      const mentionNameRe = /(?:@\[)(.+?)(?:\])/i;
      const mentionedName = mention.match(mentionNameRe);

      replacedString = mentionedName
        ? replacedString.replace(
            mention,
            `<span style="background-color:#cee4e5;border-radius:5px;padding:2px;">@${mentionedName[1]}</span>`,
          )
        : "";
    });
  }

  // replace all tag templates with a tagged name
  if (allTagMatches) {
    allTagMatches.forEach((tag) => {
      const tagNameRe = /(?:#\[)(.+?)(?:\])/i;
      const taggedName = tag.match(tagNameRe);

      replacedString = taggedName
        ? replacedString.replace(
            tag,
            `<span  style="background-color:#c2acbf;border-radius:5px;padding:2px">#${taggedName[1]}</span>`,
          )
        : "";
    });
  }

  return getSafeHtmlComponent(replacedString, className);
}
