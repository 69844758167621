import React from "react";
import { ApolloError } from "apollo-client";
import classNames from "classnames";
import { Email } from "../components/Email/Email";
import styles from "../components/SelectedSuppliers/SelectedSuppliers.module.scss";
import { Email as EmailType } from "../schema";
import { AddressBookWithOptionalParameters } from "../components/RequesterCorrespondenceBar/RequesterCorrespondenceBar";
import { convertHtmlStringToSafeJsx } from "./convertHtmlStringToSafeJsx";

// Needed for the discriminated unions to work. Simple `Omit` will break it
// discussion https://github.com/microsoft/TypeScript/issues/31501
type OmitBetterStrict<T, K extends keyof T> = T extends any
  ? Pick<T, Exclude<keyof T, K>>
  : never;

type EmailTypeWithOmittedFields = OmitBetterStrict<
  EmailType,
  "type" | "updatedDate" | "data"
> & {
  inboundData?: string | null;
  outboundData?: string | null;
};
export interface GetEmailByTypeProps {
  email: EmailTypeWithOmittedFields;
  addressBookEmails?: AddressBookWithOptionalParameters[];
  displayNarrowView?: boolean;
  className?: string;
  isInitiallyOpen?: boolean;
  onSendReplySuccess?: (email: string) => void;
  onSendReplyError?: (error: string | ApolloError) => void;
}

export function getEmailByType(props: GetEmailByTypeProps) {
  const {
    email,
    addressBookEmails,
    displayNarrowView,
    className,
    isInitiallyOpen = false,
    onSendReplySuccess,
    onSendReplyError,
  } = props;

  if (!email || !email.__typename) {
    return null;
  }

  const addressBookEmailSuggestions = addressBookEmails
    ? addressBookEmails.map((addressBook) => ({
        id: addressBook.id,
        display: addressBook.email,
      }))
    : [];

  switch (email.__typename) {
    case "OutboundEmail": {
      const data = email.outboundData
        ? (JSON.parse(email.outboundData) as any)
        : undefined;

      return (
        <Email
          className={classNames(styles["email"], className)}
          key={email.id}
          id={email.id}
          fromUser={{
            id: email.creatorId ?? "",
            email: email.fromEmail ?? "unknown",
            firstName: data.outboundInfo.user.firstName,
            lastName: data.outboundInfo.user.lastName,
            avatarUrl: data.outboundInfo.user.avatarUrl,
          }}
          ccUser={email.ccEmail ? { email: email.ccEmail } : undefined}
          subject={email.subject ?? ""}
          createdDate={new Date(email.createdDate)}
          bodyText={email.textBody ?? ""}
          attachments={
            email.hasAttachments && data.attachmentInfo && data.attachmentInfo
          }
          displayNarrowView={displayNarrowView}
          hasBeenRead={true} // we consider outbound email always to be read
          isInitiallyOpen={isInitiallyOpen}
        />
      );
    }

    case "InboundEmail": {
      const data = email.inboundData
        ? (JSON.parse(email.inboundData) as any)
        : undefined;

      return (
        <Email
          className={classNames(styles["email"], className)}
          key={email.id}
          id={email.id}
          fromUser={{
            id: email.supplierId || email.requesterId || "",
            email: email.fromEmail ?? "unknown",
            firstName: "",
            lastName: email.supplierName || email.requesterName || "",
          }}
          subject={email.subject ?? ""}
          ccEmail={email.ccEmail}
          createdDate={new Date(email.createdDate)}
          bodyText={
            email.htmlBody
              ? convertHtmlStringToSafeJsx(email.htmlBody)
              : email.textBody
          }
          attachments={
            email.hasAttachments && data.msg.attachments && data.msg.attachments
          }
          showReplyForm={true}
          onReplySuccess={onSendReplySuccess}
          onReplyError={onSendReplyError}
          displayNarrowView={displayNarrowView}
          hasBeenRead={email.hasBeenRead}
          isInitiallyOpen={isInitiallyOpen}
          addressBookEmails={addressBookEmailSuggestions}
        />
      );
    }

    default:
      return null;
  }
}
