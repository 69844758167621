import React, { useEffect } from "react";
import {
  OnChangeHandlerFunc,
  MentionItem,
  MentionsInput,
  Mention,
  SuggestionDataItem,
} from "react-mentions";
import { InputInfo, MultiSelectInfo } from "../../lib/react-apollo-hooks-form";
import { FieldError } from "../FieldError/FieldError";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import styles from "./MentionInputField.module.scss";

export enum MentionItemType {
  MENTION,
  TAG,
}

export interface MentionInputFieldProps {
  formTextInput: InputInfo;
  multiSelectInput: MultiSelectInfo;
  className: string;
  label?: string;
  placeholder?: string;
  mentionListData?: SuggestionDataItem[];
  tagListData?: SuggestionDataItem[];
  inputRef: React.RefObject<HTMLTextAreaElement>;
}

export default function MentionInputField(props: MentionInputFieldProps) {
  const {
    formTextInput,
    multiSelectInput,
    className,
    placeholder = "",
    label,
    mentionListData = [],
    tagListData = [],
    inputRef,
  } = props;

  // field identifier
  const fieldId = `${formTextInput.name}-field`;

  useEffect(() => {
    inputRef.current?.setAttribute("placeholder", placeholder);
    inputRef.current?.setAttribute("name", formTextInput.name);
  }, [inputRef, formTextInput, placeholder]);

  const onChange: OnChangeHandlerFunc = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[],
  ) => {
    formTextInput.setValue(newValue);

    multiSelectInput.setValue(
      mentions.map((mention) => {
        // get mention type by the MentionInput child order
        const mentionType =
          mention.childIndex === 0
            ? MentionItemType.MENTION
            : MentionItemType.TAG;

        // build the multi-select value by mention type
        return mentionType === MentionItemType.MENTION
          ? { mentionedUserId: mention.id, taggedSupplierId: null }
          : { mentionedUserId: null, taggedSupplierId: mention.id };
      }),
    );
  };

  return (
    <div className={className}>
      {label !== undefined && label !== "" && (
        <FieldLabel
          label={label}
          htmlFor={fieldId}
          required={formTextInput.required}
        />
      )}
      <MentionsInput
        value={formTextInput.value.toString()}
        onChange={onChange}
        inputRef={inputRef}
        classNames={styles}
        id={fieldId}
      >
        <Mention
          trigger="@"
          data={mentionListData}
          className={styles.mentions__mention}
          markup={"{{@[__display__](__id__)}}"}
          displayTransform={(_id, display) => `@${display}`}
        />
        <Mention
          trigger="#"
          data={tagListData}
          className={styles.mentions__tag}
          markup={"{{#[__display__](__id__)}}"}
          displayTransform={(_id, display) => `#${display}`}
        />
      </MentionsInput>
      {formTextInput.errors !== undefined &&
        formTextInput.errors.map((error, index) => (
          <FieldError key={index} error={error} />
        ))}
    </div>
  );
}
