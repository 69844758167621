import React from "react";
import { Link } from "react-router-dom";
import { BasicTable, Cell } from "../../components/BasicTable/BasicTable";
import { Avatar } from "../../components/Avatar/Avatar";
import { Status } from "../../components/Status/Status";
import {
  ViewerOrganizations,
  useOrganizationApprovalWorkflowsByStatus,
  ApprovalWorkflowStatusEnum,
} from "../../schema";
import { View } from "../../components/View/View";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { NotAvailable } from "../../components/NotAvailable/NotAvailable";
import { formatDatetime } from "../../services/formatDatetime";
import { getWorkflowStatusText } from "../../services/getWorkflowStatusText";
import { getWorkflowCardStatusLevel } from "../../services/getWorkflowCardStatusLevel";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import styles from "./ApprovalsView.module.scss";

export interface ApprovalsProps {
  organization: ViewerOrganizations;
}

const overDeadlineMessage = (
  diffWithDeadline: number | null,
): string | undefined => {
  if (!diffWithDeadline || diffWithDeadline <= 0) return "";
  if (diffWithDeadline === 1) return "1 day";
  if (diffWithDeadline > 1) return diffWithDeadline + " days";
};

export const ApprovalsView: React.FC<ApprovalsProps> = ({ organization }) => {
  const statuses = Object.keys(
    ApprovalWorkflowStatusEnum,
  ) as ApprovalWorkflowStatusEnum[];

  // load approvals
  const { data, loading, error } = useOrganizationApprovalWorkflowsByStatus({
    fetchPolicy: "network-only",
    variables: {
      id: organization.id,
      approvalWorkflowStatuses: statuses,
    },
  });

  // handle error
  if (error) {
    return <ErrorView title="Approvals could not be found" error={error} />;
  }

  // handle loading
  if (loading || !data || !data.viewer) {
    return <LoadingView />;
  }

  const approvalWorkflows = data.viewer.organization.approvalWorkflowsByStatus;

  return (
    <View secondary>
      <h1>Approval workflows</h1>
      <BasicTable>
        <thead>
          <tr>
            <Cell secondary header>
              #
            </Cell>
            <Cell secondary header>
              Subject
            </Cell>
            <Cell secondary header>
              Purchase Request name
            </Cell>
            <Cell secondary header nowrap center>
              Started by
            </Cell>
            <Cell secondary header>
              Started
            </Cell>
            <Cell secondary header>
              Last Update
            </Cell>
            <Cell secondary header>
              Status
            </Cell>
            <Cell secondary header>
              Approvers
            </Cell>
            <Cell secondary header nowrap>
              Over deadline
            </Cell>
          </tr>
        </thead>
        <tbody>
          {approvalWorkflows.length === 0 ? (
            <tr>
              <td className={styles["no-data-cell"]} colSpan={9}>
                You have not added any Approval Workflows yet
              </td>
            </tr>
          ) : (
            approvalWorkflows
              .sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
              .map((approvalWf) => (
                <tr key={approvalWf.id}>
                  <Cell secondary compact>
                    <b>AWF-{approvalWf.code}</b>
                  </Cell>
                  <Cell secondary>
                    <b>
                      PR-{approvalWf.purchaseRequest?.code}{" "}
                      {approvalWf.purchaseRequest ? (
                        approvalWf.purchaseRequest.name
                      ) : (
                        <NotAvailable alignLeft />
                      )}
                    </b>
                  </Cell>
                  <Cell secondary>
                    <Link
                      data-testid="6f3bbdf2a4"
                      to={`/${organization.urlName}/PR-${approvalWf.purchaseRequest?.code}`}
                    >
                      {approvalWf.requestName ? (
                        approvalWf.requestName
                      ) : (
                        <NotAvailable alignLeft />
                      )}
                    </Link>
                  </Cell>
                  <Cell secondary center>
                    <Avatar user={approvalWf.creator} />
                  </Cell>
                  <Cell secondary>
                    {formatDatetime(approvalWf.createdDate)}
                  </Cell>
                  <Cell secondary>{formatTimeAgo(approvalWf.updatedDate)}</Cell>
                  <Cell secondary>
                    <Status
                      level={getWorkflowCardStatusLevel(approvalWf.status)}
                      text={getWorkflowStatusText(approvalWf.status)}
                    />
                  </Cell>
                  <Cell secondary>
                    {approvalWf.approvalWorkflowStats.approvedRequestCount}/
                    {approvalWf.approvalWorkflowStats.totalRequestCount}
                  </Cell>
                  <Cell secondary style={{ color: "#f55c21" }}>
                    {overDeadlineMessage(approvalWf.diffWithDeadline)}
                  </Cell>
                </tr>
              ))
          )}
        </tbody>
      </BasicTable>
    </View>
  );
};
