import React from "react";
import classNames from "classnames";
import { Manager, Reference, Popper } from "react-popper";
import useOnClickOutside from "use-onclickoutside";
import styles from "./Tooltip.module.scss";

export interface TooltipProps {
  children: React.ReactNode;
  reference: React.ReactNode;
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const { className, reference, children } = props;
  const wrapRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [hasUpdated, setHasUpdated] = React.useState(false);

  useOnClickOutside(wrapRef, () => {
    setIsOpen(false);
  });

  return (
    <Manager>
      <div ref={wrapRef} className={classNames(styles["wrap"], className)}>
        <Reference>
          {({ ref }) => (
            <span
              ref={ref}
              onMouseOver={() => {
                setIsOpen(!isOpen);
                setHasUpdated(false);
              }}
              onMouseOut={() => {
                setIsOpen(!isOpen);
                setHasUpdated(false);
              }}
            >
              <span>{reference}</span>
            </span>
          )}
        </Reference>
        <Popper placement="top-start" positionFixed>
          {({ ref, style, placement, scheduleUpdate }) => {
            if (isOpen && !hasUpdated) {
              scheduleUpdate();
              setHasUpdated(true);
            }
            return (
              <div
                className={classNames(styles.content, {
                  [styles.opened]: isOpen,
                })}
                ref={ref}
                style={style}
                data-placement={placement}
              >
                <span className={styles.info}>{children}</span>
              </div>
            );
          }}
        </Popper>
      </div>
    </Manager>
  );
};
