import React from "react";
import { useRouter } from "../../hooks/useRouter";
import {
  ViewerOrganizations,
  ViewerViewer,
  useGetItemById,
  CurrencyEnum,
  Attachment,
} from "../../schema";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { EditPurchaseRequestProduct } from "./EditPurchaseRequestProduct";
import { EditPurchaseRequestService } from "./EditPurchaseRequestService";

export interface RequestViewProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  onModalClose(): void;
}

interface RouteParams {
  itemType: string;
  modalType: string;
  code: string;
  itemId: string;
}

export interface ItemParams {
  id: string;
  name: string;
  currency?: CurrencyEnum | null;
  expectedDeliveryDate?: string;
  additionalInfo?: string | null;
  unit?: string | null;
  productUrl?: string | null;
  code?: string | null;
  suggestedSupplier?: string | null;
  supplierCode?: string | null;
  unitPrice?: number | null;
  quantity?: number | null;
  customFields?: { [key: string]: string | null };
  esourcingFields?: any;
  attachments?: Pick<Attachment, "id" | "filename" | "url">[];
}

export const EditPurchaseRequestItemView: React.FC<RequestViewProps> = ({
  organization,
  onModalClose,
}) => {
  // use history and get url parameters
  const { match, history } = useRouter<RouteParams>();
  const { itemType, modalType, code, itemId } = match.params;

  // get item by id
  const { data, loading, error } = useGetItemById({
    fetchPolicy: "network-only",
    variables: {
      purchaseRequestItemId: itemId,
    },
  });

  const item = data?.viewer?.purchaseRequestItemById as ItemParams;

  // cancels adding purchase request item
  const cancel = () => {
    history.push(`/${organization.urlName}/${modalType}-${code}`);
  };

  // render error view
  if (error) {
    return (
      <ErrorView
        title="Requested item not found"
        error="Requested item could not be found."
        modal={{ title: "Edit item", onModalClose }}
      />
    );
  }

  // render loading view
  if ((loading && !data) || !item) {
    return <LoadingView overlay />;
  }

  // render editing modal based on category
  switch (itemType) {
    case "product":
      return (
        <EditPurchaseRequestProduct
          organization={organization}
          product={item}
          onSuccess={() =>
            history.push(`/${organization.urlName}/${modalType}-${code}`)
          }
          onCancel={() => cancel()}
          onModalClose={onModalClose}
        />
      );

    case "service":
      return (
        <EditPurchaseRequestService
          organization={organization}
          service={item}
          onSuccess={() =>
            history.push(`/${organization.urlName}/${modalType}-${code}`)
          }
          onCancel={() => cancel()}
          onModalClose={onModalClose}
        />
      );
  }
  return <ErrorView error="Requested item is neither product or service." />;
};
