import React from "react";
import classNames from "classnames";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";
import { Field } from "../Field/Field";
import { Datepicker } from "../Datepicker/Datepicker";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { AttachmentListSelectItem } from "../AttachmentListSelectItem/AttachmentListSelectItem";
import { RfqEventFormInputs } from "../RfqEventForm/RfqEventForm";
import { Unbox } from "../../lib/helpers/typescript-helpers";
import { Attachments } from "../Attachments/Attachments";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import { formatFilesize } from "../../services/formatFilesize";
import { useReadOnlyContext } from "../../contexts/readonly-context";
import styles from "./SourcingEventSection.module.scss";

export interface SourcingEventFormProps {
  className?: string;
  attachedFiles: File[];
  setAttachedFiles: (files: File[]) => void;
}

export const SourcingEventSection: React.FC<SourcingEventFormProps> = (
  props,
) => {
  const { className, attachedFiles, setAttachedFiles } = props;

  const [responseDeadline, setResponseDeadline] = React.useState<Date>();

  // set files state on drop
  const onFileDrop = React.useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // add selected file
      setAttachedFiles([...attachedFiles, ...acceptedFiles]);
    },
    [attachedFiles, setAttachedFiles],
  );

  // use read only context
  const isReadOnly = useReadOnlyContext();

  const { register, errors, watch } = useFormContext();

  const { fields: selectedAttachments } = useFieldArray<
    Unbox<RfqEventFormInputs["selectedAttachments"]>
  >({
    name: "selectedAttachments",
  });

  const formValuesResponseDeadline = watch("responseDeadline");

  // set local state from pre-existing form values if present and valid
  React.useEffect(() => {
    if (formValuesResponseDeadline) {
      const parsedDateTime = parse(
        formValuesResponseDeadline,
        "dd/MM/yyyy HH:mm",
        new Date(),
      );

      isValid(parsedDateTime) && setResponseDeadline(parsedDateTime);
    }
  }, [formValuesResponseDeadline]);

  return (
    <div className={classNames(styles["wrap"], className)}>
      <div className={styles["accordion-contents"]}>
        <Field
          addon={"RFQ"}
          label={"Sourcing event name"}
          required
          name="eventName"
          readOnly={isReadOnly}
          inputRef={register({ required: "This field is required" })}
          errors={[errors?.eventName?.message]}
        />
        <div className={styles["date-picker-container"]}>
          <Controller
            render={(props) => (
              <Datepicker
                onChange={(date) => {
                  if (date) {
                    setResponseDeadline(date);
                    props.onChange(date);
                  }
                }}
                label={"Response deadline"}
                showTimeSelect
                timeFormat="HH:mm"
                timeCaption="Time"
                dateFormat="dd/MM/yyyy HH:mm"
                minDate={new Date()}
                autoComplete="false"
                readOnly={isReadOnly}
                selected={responseDeadline}
                errors={[errors?.responseDeadline?.message]}
                required
              />
            )}
            rules={{ required: "This field is required" }}
            name={"responseDeadline"}
          />
          <div className={styles["date-picker-time-ago"]}>
            {`(${responseDeadline && formatTimeAgo(responseDeadline)})`}
          </div>
        </div>
        <div className={styles["two-columns"]}>
          <Field
            className={styles["additional-info"]}
            textarea
            textareaNumRows={5}
            readOnly={isReadOnly}
            label={"Additional info"}
            name={"additionalInfo"}
            textareaRef={register}
          />
          <div className={styles["second-column"]}>
            <div className={styles["attachment-list"]}>
              <div className={styles["attachment-list-title"]}>
                Selected files will be added to Sourcing event:
              </div>
              {selectedAttachments.map((attachment) => (
                <AttachmentListSelectItem
                  key={attachment.id}
                  attachment={attachment}
                />
              ))}

              <div className={styles["upload-list"]}>
                {/* attachments not yet reached server side */}
                {attachedFiles && attachedFiles.length > 0 && (
                  <div>
                    {attachedFiles.map((file) => (
                      <div
                        key={file.name}
                        className={styles["attachment-item"]}
                      >
                        {!isReadOnly && (
                          <DeleteFileIcon
                            className={classNames(styles["delete-file-icon"])}
                            onClick={() =>
                              setAttachedFiles(
                                attachedFiles.filter(
                                  (stateFile) => file.name !== stateFile.name,
                                ),
                              )
                            }
                          />
                        )}
                        <div>{file.name}</div>
                        <div className={styles["filesize"]}>{`(${formatFilesize(
                          file.size,
                        )})`}</div>
                      </div>
                    ))}
                  </div>
                )}
                {!isReadOnly && (
                  <Attachments
                    secondary
                    multiple
                    loading={false}
                    onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
