import { isApolloError } from "apollo-client";

import { getFieldErrors } from "../lib/react-apollo-hooks-form";

export function getCombinedApolloErrorMessage(error: Error) {
  const isDevMode = process.env.NODE_ENV === "development";
  const genericErrorMessage =
    "Oops! Something went wrong, please try again later";

  // return generic error if not apollo error
  if (!isApolloError(error)) {
    return isDevMode ? error.message : genericErrorMessage;
  }

  // handle network and field errors
  if (error.networkError) {
    return isDevMode ? error.networkError.message : genericErrorMessage;
  } else {
    const fieldErrors = getFieldErrors(error.graphQLErrors);

    // build field errors message
    const fieldErrorsMessage = Object.values(fieldErrors)
      .map((errorList) =>
        Array.isArray(errorList) ? errorList.join(", ") : "",
      )
      .join(", ");

    // return the original message if the issue was not with the fields
    if (fieldErrorsMessage.length === 0) {
      return isDevMode ? error.message : genericErrorMessage;
    }

    return fieldErrorsMessage;
  }
}
