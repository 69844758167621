import React from "react";
import classNames from "classnames";
import format from "date-fns/format";
import parse from "date-fns/parse";
import addBusinessDays from "date-fns/addBusinessDays";
import { useForm, FormProvider } from "react-hook-form";
import * as R from "ramda";
import { Accordion } from "../Accordion/Accordion";
import { SourcingEventSection } from "../SourcingEventSection/SourcingEventSection";
import {
  Attachment,
  RfxByCodeRfxByCode,
  useCreateOrUpdateSourcingEvent,
  ViewerOrganizations,
} from "../../schema";
import {
  EventTypeEnum,
  StatusNotice,
  StatusNoticeProps,
} from "../StatusNotice/StatusNotice";
import { scrollToRef } from "../../services/scrollToRef";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import styles from "../RfqEventForm/RfqEventForm.module.scss";

export interface RfqMetadataFormProps {
  className?: string;
  organization: ViewerOrganizations;
  rfx: RfxByCodeRfxByCode;
}

export interface RfqMetadataFormInputs {
  eventName?: string;
  responseDeadline?: Date | string | null;
  additionalInfo?: string;
  selectedAttachments: (Pick<Attachment, "id" | "filename" | "url" | "size"> & {
    selected: boolean;
  })[];
}

export const RfqMetadataForm: React.FC<RfqMetadataFormProps> = (props) => {
  const { className, organization, rfx } = props;

  // attachments to be uploaded
  const [attachedFiles, setAttachedFiles] = React.useState<File[]>([]);

  // state change status notice state
  const [statusNotice, setStatusNotice] =
    React.useState<StatusNoticeProps | null>(null);
  const topRef = React.useRef<HTMLDivElement>(null);
  const bottomRef = React.useRef<HTMLDivElement>(null);

  const sourcingEvent = rfx.sourcingEvent;

  const [
    createOrUpdateSourcingEvent,
    {
      loading: isCreateOrUpdateSourcingEventLoading,
      error: isCreateOrUpdateSourcingEventError,
    },
  ] = useCreateOrUpdateSourcingEvent({
    refetchQueries: ["RfxByCode"],
    awaitRefetchQueries: true,
    onError: () => {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Sorry! There was an error while saving the sourcing event",
        eventDescription: "Please try again",
      });

      // scroll to status message
      scrollToRef(topRef);
    },
  });

  // handle saving error
  React.useEffect(() => {
    if (isCreateOrUpdateSourcingEventError) {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Something unexpected happened",
        eventDescription:
          "We couldn't create or update the sourcing event. Please try again.",
      });
    }
  }, [isCreateOrUpdateSourcingEventError]);

  const formMethods = useForm<RfqMetadataFormInputs>({
    defaultValues: {
      eventName: sourcingEvent?.name || rfx.name,
      additionalInfo: sourcingEvent?.additionalInfo ?? "",
      responseDeadline: sourcingEvent?.responseDeadline
        ? format(new Date(sourcingEvent.responseDeadline), "dd/MM/yyyy HH:mm")
        : format(
            addBusinessDays(new Date(), 2).setMinutes(0),
            "dd/MM/yyyy HH:mm",
          ),
      selectedAttachments:
        rfx.attachments?.map((rfxAttachment) =>
          R.assoc(
            "selected",
            R.includes(
              rfxAttachment.id,
              sourcingEvent?.attachments?.map((a) => a.id) ?? [],
            ),
            rfxAttachment,
          ),
        ) ?? [],
    },
  });

  const { handleSubmit, errors } = formMethods;

  const onSubmit = async (
    formData: RfqMetadataFormInputs & {
      attachments: { [attachmentId: string]: boolean };
    },
  ) => {
    await createOrUpdateSourcingEvent({
      variables: {
        sourcingEventId: sourcingEvent?.id ?? null,
        organizationId: organization.id,
        rfxId: rfx.id,
        questionnaire: null,
        name: formData.eventName ?? "",
        responseDeadline:
          typeof formData.responseDeadline === "string"
            ? parse(formData.responseDeadline, "dd/MM/yyyy HH:mm", new Date())
            : formData.responseDeadline,
        additionalInfo: formData.additionalInfo ?? null,
        selectedAttachmentIds:
          R.compose<{ [k: string]: boolean }, any, any>(
            R.prop("true"),
            R.invert,
          )(formData.attachments) ?? [],
        uploadedAttachments: attachedFiles,
      },
      refetchQueries: ["PaginatedRfx", "RfxByCode"],
      awaitRefetchQueries: true,
    });
  };

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Please fill in the fields that are required",
      });

      // scroll to status message
      scrollToRef(topRef);
    }
  }, [errors]);

  return (
    <FormProvider {...formMethods}>
      {/* display status notices */}
      <div ref={topRef}></div>

      {statusNotice && (
        <StatusNotice
          level={statusNotice.level}
          eventTitle={statusNotice.eventTitle}
          eventDescription={statusNotice.eventDescription}
          onCloseRequested={() => {
            setStatusNotice(null);
          }}
        />
      )}

      {/* display form */}
      <form
        id="rfq-event-form"
        onSubmit={handleSubmit(onSubmit)}
        className={styles["form"]}
      >
        <div className={classNames(styles["wrap"], className)}>
          <Accordion isInitiallyOpen={true} title={"Sourcing event"}>
            <SourcingEventSection
              attachedFiles={attachedFiles}
              setAttachedFiles={setAttachedFiles}
            />
          </Accordion>
        </div>
        <div ref={bottomRef}></div>
      </form>

      {/* handle loading */}
      {isCreateOrUpdateSourcingEventLoading && <LoadingView overlay />}
    </FormProvider>
  );
};
