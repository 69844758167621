import React from "react";
import { Link } from "react-router-dom";
import {
  RequesterPortalViewPurchaseRequests,
  RequesterPortalViewOrganization,
} from "../../schema";
import { Button } from "../../components/Button/Button";
import { PurchaseRequestSummary } from "../../components/PurchaseRequestSummary/PurchaseRequestSummary";
import { useRouter } from "../../hooks/useRouter";
import { getRequesterPortalCardStatus } from "../../services/getRequesterPortalCardStatus";
import styles from "./ListRequestFromPortalView.module.scss";

interface ListRequestFromPortalViewProps {
  organization: RequesterPortalViewOrganization;
  purchaseRequests: RequesterPortalViewPurchaseRequests[];
}

export const ListRequestFromPortalView: React.FC<ListRequestFromPortalViewProps> = ({
  organization,
  purchaseRequests,
}) => {
  const { history } = useRouter();

  return (
    <>
      <div className={styles["content-header"]}>
        <h1 className={styles["content-header__title"]}>
          My purchase requests
        </h1>
        <Link to={`purchase-requests/create`}>
          <Button>Add new request</Button>
        </Link>
      </div>
      <div>
        {purchaseRequests?.map((purchaseRequest) => {
          const cardStatus = getRequesterPortalCardStatus(purchaseRequest);

          return (
            <PurchaseRequestSummary
              key={purchaseRequest.id}
              assignee={purchaseRequest.assignee || undefined}
              cardStatus={cardStatus}
              code={purchaseRequest.code}
              department={purchaseRequest.department || undefined}
              name={purchaseRequest.name}
              id={purchaseRequest.id}
              items={purchaseRequest.items || undefined}
              onClick={() => {
                history.push(
                  `/${organization.urlName}/purchase-requests/${purchaseRequest.code}`,
                );
              }}
              project={purchaseRequest.project || undefined}
            />
          );
        })}
      </div>
    </>
  );
};
