import React from "react";
import classNames from "classnames";
import styles from "./Overlay.module.scss";

export interface OverlayProps {
  className?: string;
}

export const Overlay: React.FC<OverlayProps> = ({ children, className }) => (
  <div className={classNames(styles.overlay, className)}>{children}</div>
);
