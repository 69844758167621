import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import introspectionQueryResultData from "../introspection-result";
import { config } from "../config";
import { isVersionMismatchError } from "./isVersionMismatchError";
import { terminalLink } from "./terminalLink";

export enum GraphqlEvent {
  VERSION_MISMATCH = "VERSION_MISMATCH",
}

export type GraphqlEventListenerFn = (event: GraphqlEvent) => void;

export function setupGraphqlClient() {
  // list of event listeners
  const eventListeners: GraphqlEventListenerFn[] = [];

  // registers a new event listener
  const addEventListener = (listener: GraphqlEventListenerFn) => {
    eventListeners.push(listener);
  };

  const publishEvent = (event: GraphqlEvent) => {
    for (const eventListner of eventListeners) {
      eventListner(event);
    }
  };

  // configure fragment matcher
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  // configure context link
  const contextLink = setContext(({ operationName }, { headers }) => {
    // augment headers with client metadata
    return {
      headers: {
        ...headers,
        "x-client-operation": operationName,
        "x-client-version": config.clientVersion,
        "x-client-path": window.location.pathname,
      },
    };
  });

  // configure error link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      for (const graphQLError of graphQLErrors) {
        // check for special error type
        if (isVersionMismatchError(graphQLError)) {
          console.warn(
            "Current client version is different from the latest version",
          );

          // publish version mismatch event
          publishEvent(GraphqlEvent.VERSION_MISMATCH);
        } else {
          console.warn(`[GraphQL] ${graphQLError.message}`, graphQLError);
        }
      }

    if (networkError) {
      console.warn(`[GraphQL] ${networkError}`);
    }
  });

  // setup apollo graphql client
  const client = new ApolloClient({
    link: ApolloLink.from([contextLink, errorLink]).concat(terminalLink),
    cache: new InMemoryCache({ fragmentMatcher }),
  });

  return {
    client,
    addEventListener,
  };
}
