import { createMachine, assign, sendParent, InvokeCreator } from "xstate";
import { RfxTypeEnum, RfxSupplierResolutionEnum } from "../../schema";
import { SuppliersMachineEventsEnum } from "./Suppliers.machine";

// state and events enums
export enum SupplierResolutionState {
  IDLE = "IDLE",
  WAITING_PARENT = "WAITING_PARENT",
  SAVING = "SAVING",
  FAILURE = "FAILURE",
}

export enum SupplierResolutionEvents {
  CHANGE = "CHANGE",
  CLEAR = "CLEAR",
  SET_READONLY = "SET_READONLY",
  SET_EDITABLE = "SET_EDITABLE",
  APPROVED = "APPROVED",
}

// machine types
export type SupplierResolutionContext = {
  supplierId: string;
  rfxType: RfxTypeEnum;
  selectedResolution: RfxSupplierResolutionEnum | null;
  isReadOnly: boolean;
};

export type SupplierResolutionMachineState = {
  value: keyof typeof SupplierResolutionState;
  context: SupplierResolutionContext;
};

export type SupplierResolutionMachineEvent =
  | {
      type: SupplierResolutionEvents.CHANGE;
      resolution: RfxSupplierResolutionEnum;
    }
  | { type: SupplierResolutionEvents.CLEAR }
  | { type: SupplierResolutionEvents.SET_READONLY }
  | { type: SupplierResolutionEvents.SET_EDITABLE }
  | { type: SupplierResolutionEvents.APPROVED };

// guards
const isEditable = (context: SupplierResolutionContext) => !context.isReadOnly;

// statechart creator
export function createSupplierResolutionMachine(props: {
  context: SupplierResolutionContext;
  services: {
    saveResolution: InvokeCreator<
      SupplierResolutionContext,
      SupplierResolutionMachineEvent,
      any
    >;
  };
}) {
  // extract context
  const {
    supplierId,
    rfxType = RfxTypeEnum.RFI,
    selectedResolution = null,
    isReadOnly = false,
  } = props.context;

  // extract services
  const { saveResolution } = props.services;

  return createMachine<
    SupplierResolutionContext,
    SupplierResolutionMachineEvent,
    SupplierResolutionMachineState
  >({
    id: "supplierResolutionMachine",
    initial: SupplierResolutionState.IDLE,
    context: {
      supplierId,
      rfxType,
      selectedResolution,
      isReadOnly,
    },
    states: {
      [SupplierResolutionState.IDLE]: {
        on: {
          CHANGE: {
            target: SupplierResolutionState.WAITING_PARENT,
            actions: [
              assign({
                selectedResolution: (_context, event) => {
                  return event.resolution;
                },
              }),
              sendParent({
                type: SuppliersMachineEventsEnum.CHANGE_SELECTION,
                supplierId,
              }),
            ],
            cond: isEditable,
          },
          CLEAR: {
            target: SupplierResolutionState.SAVING,
            actions: assign({
              selectedResolution: (_context, _event) => null,
              isReadOnly: (_context, _event) => true,
            }),
            cond: isEditable,
          },
          SET_READONLY: {
            actions: assign({ isReadOnly: (_context, _event) => true }),
          },
          SET_EDITABLE: {
            actions: assign({ isReadOnly: (_context, _event) => false }),
          },
        },
      },
      [SupplierResolutionState.WAITING_PARENT]: {
        on: {
          APPROVED: {
            target: SupplierResolutionState.SAVING,
          },
        },
      },
      [SupplierResolutionState.SAVING]: {
        // call parent and respond to events from parent for next state
        invoke: {
          id: "save-resolution",
          src: saveResolution,
          onDone: {
            target: SupplierResolutionState.IDLE,
          },
          onError: { target: SupplierResolutionState.FAILURE },
        },
      },
      [SupplierResolutionState.FAILURE]: {
        // TODO: reset to previous resolution?
      },
    },
  });
}
