import { OrderTypeEnum, PaginatedReceivingEdges } from "../schema";
import { CardInfo } from "../components/Card/Card";
import { AvatarUser } from "../components/Avatar/Avatar";
import { DraggableType } from "../components/Swimlane/Swimlane";
import { pick } from "./pick";
import { getItemInfo } from "./getItemInfo";

export function getReceivingCardInfo(
  info: PaginatedReceivingEdges,
  users: AvatarUser[],
  assigningCardId: string | undefined,
): CardInfo {
  // validate order type
  if (info.type !== OrderTypeEnum.RECEIVING) {
    throw new Error(`Expected order of type INVOICE but got ${info.type}`);
  }

  const prefix = "PO";
  const cardCode = `${prefix}-${info.code}`;

  // return card info
  return {
    ...pick(
      info,
      "id",
      "code",
      "name",
      "assignee",
      "projects",
      "requestors",
      "createdDate",
    ),
    type: DraggableType.CARD,
    prefix,
    status: info.cardStatus,
    suppliers: info.supplier ? [info.supplier] : [],
    items: info.items.map((item) => getItemInfo(item, info.id, cardCode)),
    users,
    description: "", // TODO: implement dynamic description
    labels: [], // TODO: use real labels once implmeneted
    attachmentCount: null, // TODO: use real attachment count once implemented
    commentCount: null, // TODO: use real comment count once implemented
    assigning: info.id === assigningCardId,
  };
}
