import React from "react";
import classNames from "classnames";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import styles from "./FieldWrap.module.scss";

export interface FieldWrapProps {
  required?: boolean;
  short?: boolean;
  label?: string;
}

export const FieldWrap: React.FC<FieldWrapProps> = ({
  children,
  short,
  label,
  required,
}) => {
  return (
    <div
      className={classNames(styles["outer-wrap"], {
        [styles["outer-wrap--short"]]: short,
      })}
    >
      {label !== undefined && label !== "" && (
        <FieldLabel label={label} required={required} />
      )}
      <div className={styles["field-wrap"]}>{children}</div>
    </div>
  );
};
