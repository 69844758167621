import React, { ChangeEvent } from "react";
import classNames from "classnames";
import styles from "./Checkbox.module.scss";

export interface CheckboxProps {
  name?: string;
  value?: string;
  defaultChecked?: boolean;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement> | React.RefObject<HTMLInputElement>;
  onChangeCallback?(e: ChangeEvent<HTMLInputElement>): void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  className,
  name,
  value,
  defaultChecked,
  inputRef,
  onChangeCallback,
}) => {
  const [checked, setChecked] = React.useState(defaultChecked);

  return (
    <label className={classNames(styles.checkbox, className)}>
      <input
        type="checkbox"
        name={name}
        defaultValue={value}
        checked={checked}
        className={styles["checkbox__input"]}
        ref={inputRef}
        onChange={(e) => {
          setChecked(e.target.checked);

          if (typeof onChangeCallback === "function") {
            onChangeCallback(e);
          }
        }}
        data-testid={`${name}`}
      />
      <span className={styles["checkbox__checkmark"]} />
      <span className={styles["checkbox__content"]}>{children}</span>
    </label>
  );
};
