export function checkDownloadUrlValidity(url: string) {
  const xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (xhr.status === 200) {
      window.location.href = url;
    }
  };

  xhr.open("head", url);
  xhr.send(null);
}
