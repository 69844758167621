import React from "react";
import { ApolloError } from "apollo-client";
import classNames from "classnames";
import {
  RfxByCodeQuery,
  useAddSupplierResolution,
  RfxSupplierResolutionEnum,
  RfxStatusEnum,
  RfxTypeEnum,
} from "../../schema";
import { getEmailByType } from "../../services/getEmailByType";
import { getSuppliersSelectedForCurrentRfxType } from "../../services/getSuppliersSelectedForCurrentRfxType";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import { ButtonGroup } from "../ButtonGroup/ButtonGroup";
import styles from "./SelectedSuppliers.module.scss";
import { SupplierResolutionEvents } from "./SupplierResolution.machine";
import { useSuppliersMachine } from "./Suppliers.machine";

export interface SelectedSuppliersProps {
  data: RfxByCodeQuery;
  onSendReplySuccess?: (email: string) => void;
  onSendReplyError?: (error: string | ApolloError) => void;
}

export const SelectedSuppliers: React.FC<SelectedSuppliersProps> = (props) => {
  const { data, onSendReplySuccess, onSendReplyError } = props;

  const [
    areDecisionButtonsDisabled,
    setAreDecisionButtonsDisabled,
  ] = React.useState(false);

  // resolve info
  const rfx = data?.viewer ? data.viewer.rfxByCode : undefined;
  const organization = data?.viewer ? data.viewer?.organization : undefined;

  // set default input values once rfx is loaded
  React.useEffect(() => {
    if (!rfx) {
      return;
    }

    if (rfx.status === RfxStatusEnum.COMPLETE) {
      setAreDecisionButtonsDisabled(true);
    }

    if (rfx.status === RfxStatusEnum.ONGOING) {
      setAreDecisionButtonsDisabled(false);
    }
  }, [rfx]);

  const [
    addSupplierResolution,
    // TODO: handle { loading: addSupplierResolutionLoading, error: addSupplierResolutionError },
  ] = useAddSupplierResolution();

  const {
    parentState,
    getChildStateById,
    getChildResolutionById,
    sendToChildById,
  } = useSuppliersMachine({
    rfxId: rfx?.id ?? null,
    isReadOnly: areDecisionButtonsDisabled,
    supplierResolutions: rfx?.supplierResolutions ?? [],
    saveResolution: addSupplierResolution,
  });

  const suppliersWithResolution =
    rfx?.suppliers &&
    rfx.suppliers.map((supplier) => {
      const supplierResolution =
        rfx.supplierResolutions &&
        rfx.supplierResolutions.find((sr) => sr.supplierId === supplier.id);

      return {
        ...supplier,
        ...{ resolution: supplierResolution?.resolution ?? null },
      };
    });

  // sub-selections of suppliers based on resolution
  const suppliersSelectedForRFI = suppliersWithResolution?.filter(
    (swr) => swr.resolution === RfxSupplierResolutionEnum.NOMINATE_TO_RFI,
  );
  const suppliersSelectedForRFP = suppliersWithResolution?.filter(
    (swr) => swr.resolution === RfxSupplierResolutionEnum.NOMINATE_TO_RFP,
  );

  const suppliersSelectedForCurrentType =
    rfx && suppliersWithResolution
      ? getSuppliersSelectedForCurrentRfxType(rfx, suppliersWithResolution)
      : null;

  // handle loading (also avoid form content flicker)
  if (
    !rfx ||
    !organization ||
    !suppliersSelectedForCurrentType ||
    !suppliersSelectedForRFI ||
    !suppliersSelectedForRFP
  ) {
    return <LoadingView overlay />;
  }

  return (
    <div className={styles["wrap"]}>
      <div className={styles["table"]}>
        {/* TABLE HEAD */}
        <div className={styles["head"]}>
          <div className={classNames(styles["cell"], styles["first-col"])}>
            SUPPLIERS
          </div>
          <div className={classNames(styles["cell"], styles["second-col"])}>
            CORRESPONDENCE
          </div>
          {rfx.type !== RfxTypeEnum.RFQ && (
            <div className={classNames(styles["cell"], styles["third-col"])}>
              DECISION
            </div>
          )}
        </div>

        {/* Currently selectable suppliers */}
        {suppliersSelectedForCurrentType.map((supplier) => (
          <div key={supplier.id} className={styles["row"]}>
            <div className={classNames(styles["cell"], styles["first-col"])}>
              <div className={styles["supplier-name"]}>{supplier.name}</div>
              <div className={styles["supplier-email"]}>
                {supplier.defaultContactPerson.email}
              </div>
            </div>
            <div className={classNames(styles["cell"], styles["second-col"])}>
              {rfx.emails.filter((email) => email.supplierId === supplier.id)
                .length > 0 ? (
                rfx.emails
                  .filter((email) => email.supplierId === supplier.id)
                  .map((email) =>
                    getEmailByType({
                      email,
                      onSendReplySuccess,
                      onSendReplyError,
                    }),
                  )
              ) : (
                <div className={styles["no-emails"]}>
                  No emails sent out yet
                </div>
              )}
            </div>
            <div className={classNames(styles["cell"], styles["third-col"])}>
              {rfx.type !== RfxTypeEnum.RFQ && (
                <ButtonGroup
                  relativeId={supplier.id}
                  options={[
                    ...(rfx.type && [RfxTypeEnum.RFI].includes(rfx.type)
                      ? [
                          {
                            key: RfxSupplierResolutionEnum.NOMINATE_TO_RFP,
                            title: "RFP/RFQ",
                            isDisabled:
                              parentState.context.isReadOnly ||
                              getChildStateById(supplier.id)?.context
                                .isReadOnly,
                          },
                        ]
                      : []),
                    ...(rfx.type &&
                    [RfxTypeEnum.RFI, RfxTypeEnum.RFP].includes(rfx.type)
                      ? [
                          {
                            key: RfxSupplierResolutionEnum.NOMINATE_TO_RFQ,
                            title: "eSourcing",
                            isDisabled:
                              parentState.context.isReadOnly ||
                              getChildStateById(supplier.id)?.context
                                .isReadOnly,
                          },
                        ]
                      : []),
                    {
                      key: RfxSupplierResolutionEnum.NOMINATE_TO_PO,
                      title: "PO",
                      isDisabled:
                        parentState.context.isReadOnly ||
                        getChildStateById(supplier.id)?.context.isReadOnly,
                    },
                  ]}
                  selectedKey={getChildResolutionById(supplier.id)}
                  onChange={(info) => {
                    sendToChildById(supplier.id, {
                      type: SupplierResolutionEvents.CHANGE,
                      resolution: info.value as RfxSupplierResolutionEnum,
                    });
                  }}
                />
              )}
            </div>
          </div>
        ))}

        {/* RFP/RFQ suppliers. Show only at eSourcing */}
        {rfx.type &&
          rfx.type === RfxTypeEnum.RFQ &&
          suppliersSelectedForRFP.length > 0 && (
            <>
              <div className={styles["subheading-row"]}>
                <div className={styles["subheading-col"]}>RFP/RFQ</div>
                <div className={styles["subheading-col"]}></div>
                <div className={styles["subheading-col"]}></div>
              </div>

              {suppliersSelectedForRFP.map((supplier) => (
                <div key={supplier.id} className={styles["row"]}>
                  <div
                    className={classNames(styles["cell"], styles["first-col"])}
                  >
                    <div className={styles["supplier-name"]}>
                      {supplier.name}
                    </div>
                    <div className={styles["supplier-email"]}>
                      {supplier.defaultContactPerson.email}
                    </div>
                  </div>
                  <div
                    className={classNames(styles["cell"], styles["second-col"])}
                  >
                    {rfx.emails.filter(
                      (email) => email.supplierId === supplier.id,
                    ).length > 0 ? (
                      rfx.emails
                        .filter((email) => email.supplierId === supplier.id)
                        .map((email) =>
                          getEmailByType({
                            email,
                            onSendReplySuccess,
                            onSendReplyError,
                          }),
                        )
                    ) : (
                      <div className={styles["no-emails"]}>
                        No emails sent out yet
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames(styles["cell"], styles["third-col"])}
                  ></div>
                </div>
              ))}
            </>
          )}

        {/* RFI suppliers. Show only after RFI */}
        {rfx.type &&
          [RfxTypeEnum.RFP, RfxTypeEnum.RFQ].includes(rfx.type) &&
          suppliersSelectedForRFI.length > 0 && (
            <>
              <div className={styles["subheading-row"]}>
                <div className={styles["subheading-col"]}>RFI</div>
                <div className={styles["subheading-col"]}></div>
                <div className={styles["subheading-col"]}></div>
              </div>

              {suppliersSelectedForRFI.map((supplier) => (
                <div key={supplier.id} className={styles["row"]}>
                  <div
                    className={classNames(styles["cell"], styles["first-col"])}
                  >
                    <div className={styles["supplier-name"]}>
                      {supplier.name}
                    </div>
                    <div className={styles["supplier-email"]}>
                      {supplier.defaultContactPerson.email}
                    </div>
                  </div>
                  <div
                    className={classNames(styles["cell"], styles["second-col"])}
                  >
                    {rfx.emails.filter(
                      (email) => email.supplierId === supplier.id,
                    ).length > 0 ? (
                      rfx.emails
                        .filter((email) => email.supplierId === supplier.id)
                        .map((email) =>
                          getEmailByType({
                            email,
                            onSendReplySuccess,
                            onSendReplyError,
                          }),
                        )
                    ) : (
                      <div className={styles["no-emails"]}>
                        No emails sent out yet
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames(styles["cell"], styles["third-col"])}
                  ></div>
                </div>
              ))}
            </>
          )}
      </div>
    </div>
  );
};
