import React from "react";
import { Route, Switch } from "react-router";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { Version } from "./components/Version/Version";
import { useViewerQuery } from "./schema";
import { ApprovalPortalView } from "./views/ApprovalRequestsView/ApprovalPortalView";
import { ExperimentsView } from "./views/ExperimentsView/ExperimentsView";
import { LoadingView } from "./views/LoadingView/LoadingView";
import { LoginSupplierView } from "./views/LoginSupplierView/LoginSupplierView";
import { LoginView } from "./views/LoginView/LoginView";
import { MaintenanceView } from "./views/MaintenanceView/MaintenanceView";
import { MemberView } from "./views/MemberView/MemberView";
import { RequesterPortalView } from "./views/RequesterPortalView/RequesterPortalView";
import { SupplierPortalDetailView } from "./views/SupplierPortalDetailView/SupplierPortalDetailView";
import { SupplierPortalListView } from "./views/SupplierPortalListView/SupplierPortalListView";
import { SupplierPortalSupplierRegistrationView } from "./views/SupplierPortalSupplierRegistrationView/SupplierPortalSupplierRegistrationView";

export const App: React.FC = () => {
  // request logged in viewer info
  const { data, error, loading } = useViewerQuery();

  // user is authenticated if logged in viewer exists
  const isAuthenticated = data?.viewer !== null;

  // is this failed then the server is probably offline
  if (error) {
    return <MaintenanceView error={error} />;
  }

  // handle loading
  if (!data || loading) {
    return <LoadingView />;
  }

  // show either member or login view based on whether the user is authenticated
  return (
    <>
      <Switch>
        <Route
          exact
          path="/login/esourcing/:sourcingRequestId?/:token?"
          component={LoginSupplierView}
        />
        <Route exact path="/login/:token?" component={LoginView} />
        <Route path="/experiments">
          <ExperimentsView viewer={data.viewer} />
        </Route>
        <Route
          exact
          path="/esourcing/registration/:sourcingEventLinkToken/:eventName?"
          component={SupplierPortalSupplierRegistrationView}
        />
        <ProtectedRoute
          exact
          path={`/esourcing`}
          allowed={isAuthenticated}
          redirect="/login/esourcing"
        >
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <SupplierPortalListView viewer={data.viewer!} />
        </ProtectedRoute>
        <ProtectedRoute
          path={`/esourcing/:sourcingRequestId`}
          allowed={isAuthenticated}
          redirect="/login/esourcing/:sourcingRequestId"
        >
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <SupplierPortalDetailView viewer={data.viewer!} />
        </ProtectedRoute>
        <ProtectedRoute
          path={`/:activeOrganization/approval-requests`}
          allowed={isAuthenticated}
          redirect="/login"
        >
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ApprovalPortalView viewer={data.viewer!} />
        </ProtectedRoute>
        <ProtectedRoute
          path={`/:activeOrganization/purchase-requests`}
          allowed={isAuthenticated}
          redirect="/login"
        >
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <RequesterPortalView viewer={data.viewer!} />
        </ProtectedRoute>
        <ProtectedRoute path="/" allowed={isAuthenticated} redirect="/login">
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <MemberView viewer={data.viewer!} />
        </ProtectedRoute>
      </Switch>
      <Version />
    </>
  );
};
