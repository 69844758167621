import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useForm,
  FormProvider,
} from "react-hook-form";
import { NonUploadedFiles } from "../../components/NonUploadedFiles/NonUploadedFiles";
import { useRouter } from "../../hooks/useRouter";
import {
  useCreateDepartment,
  useCreateCostCentre,
  useCreateProject,
  PurchaseRequestsDetailsByCodePurchaseRequestByCode,
  useRemovePurchaseRequestAttachment,
  PurchaseRequestByCodeOrganization,
  useCreateRequestor,
  ViewerViewer,
  PurchaseRequestItemTypeEnum,
  useDeletePurchaseRequestItem,
  UploadProductExcelRows,
  CreateRequestorMutationVariables,
  useUploadProductExcel,
  PurchaseRequestStatusEnum,
  Attachment,
  Maybe,
  RequesterPortalViewDepartment,
  RequesterPortalViewProject,
  RequesterPortalViewCostCentre,
  UserRoleEnum,
} from "../../schema";
import { costCentreToAutosuggestItem } from "../../services/costCentreToAutosuggestItem";
import { departmentToAutosuggestItem } from "../../services/departmentToAutosuggestItem";
import { isCreatedByEmail } from "../../services/isCreatedByEmail";
import { matches } from "../../services/matches";
import { projectToAutosuggestItem } from "../../services/projectsToAutosuggestItem";
import { QuantityUnitInfo } from "../../services/quantityUnitToAutosuggestItem";
import { userToAutosuggestItem } from "../../services/userToAutosuggestItem";
import { PlusIcon } from "../../theme/svg/PlusIcon";
import { TooltipIcon } from "../../theme/svg/TooltipIcon";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import { AccordionSecondary } from "../Accordion/AccordionSecondary";
import { Attachments } from "../Attachments/Attachments";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";

import { Button } from "../Button/Button";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { ExcelProductsImportErrorsTable } from "../ExcelImportErrorsTable/ExcelProductsImportErrorsTable";
import { Field } from "../Field/Field";
import { FieldError } from "../FieldError/FieldError";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { Form } from "../Form/Form";
import {
  ItemAutosuggest,
  AutosuggestItem,
} from "../ItemAutosuggest/ItemAutosuggest";
import {
  PurchaseRequestItemForm,
  PurchaseRequestItemProp,
} from "../PurchaseRequestItemForm/PurchaseRequestItemForm";
import { PurchaseRequestItems } from "../PurchaseRequestItems/PurchaseRequestItems";
import { RequestorInput } from "../RequestorInput/RequestorInput";
import { UploadedAttachment } from "../UploadedAttachment/UploadedAttachment";
import { ItemParams } from "../../views/EditPurchaseRequestItemView/EditPurchaseRequestItemView";
import styles from "./PurchaseRequestForm.module.scss";

export type CreatePurchaseRequestFormData = {
  name: string;
  requestorId?: string | null;
  departmentId?: string | null;
  projectId?: string | null;
  costCentreId?: string | null;
  notes?: string | null;
  items: PurchaseRequestItemProp[];
  files?: File[];
};

export interface UpdatePurchaseRequestFormData
  extends CreatePurchaseRequestFormData {
  purchaseRequestId: string;
}

export type PurchaseRequestByCodeOrganizationWithOptionalParameters = Omit<
  PurchaseRequestByCodeOrganization,
  "addressBook"
>;

export interface PurchaseRequestFormProps {
  organization: PurchaseRequestByCodeOrganizationWithOptionalParameters;
  viewer: ViewerViewer;
  purchaseRequest?: PurchaseRequestsDetailsByCodePurchaseRequestByCode;
  fromPortal?: boolean;
  isEditing?: boolean;
  error?: string;
  files: File[];
  department: Maybe<RequesterPortalViewDepartment>;
  project: Maybe<RequesterPortalViewProject>;
  costCentre: Maybe<RequesterPortalViewCostCentre>;
  setFiles: (files: File[]) => void;
  savePurchaseRequest: (
    values: CreatePurchaseRequestFormData & UpdatePurchaseRequestFormData,
    newStatus?: PurchaseRequestStatusEnum | undefined,
    purchaseRequestId?: string,
  ) => Promise<void>;
  setIsEditingItem?: React.Dispatch<
    React.SetStateAction<{ item: ItemParams; itemType: string } | undefined>
  >;
}

export const PurchaseRequestForm: React.FC<PurchaseRequestFormProps> = ({
  organization,
  viewer,
  purchaseRequest,
  fromPortal = false,
  isEditing,
  error,
  files,
  setFiles,
  savePurchaseRequest,
  setIsEditingItem,
  department,
  project,
  costCentre,
}) => {
  // Initial states
  const [departmentDisplayValue, setDepartmentDisplayValue] = useState(
    department?.name ?? "",
  );

  const [chosenDepartment, setChosenDepartment] = useState<
    AutosuggestItem | undefined
  >(department ? { id: department.id, name: department.name } : undefined);

  const [projectDisplayValue, setProjectDisplayValue] = useState(
    project?.name ?? "",
  );

  const [chosenProject, setChosenProject] = useState<
    AutosuggestItem | undefined
  >(project ? { id: project.id, name: project.name } : undefined);

  const [costCentreDisplayValue, setCostCentreDisplayValue] = useState(
    costCentre?.name ?? "",
  );

  const [chosenCostCentre, setChosenCostCentre] = useState<
    AutosuggestItem | undefined
  >(costCentre ? { id: costCentre.id, name: costCentre.name } : undefined);

  const [chosenRequestor, setChosenRequestor] = useState<AutosuggestItem>();

  const [requestorDisplayValue, setRequestorDisplayValue] = useState("");

  const [areRequestorFieldsActive, setAreRequestorFieldsActive] =
    useState(false);
  const [attachments, setAttachments] = useState<
    Pick<Attachment, "id" | "filename" | "url">[]
  >([]);
  const [isAddingItem, setIsAddingItem] = useState<boolean>(false);
  const [selectedExcelFile, setSelectedExcelFile] = useState<
    File[] | undefined
  >(undefined);
  const [hasExcelError, setHasExcelError] = useState<boolean>(false);
  const [excelUploadError, setExcelUploadError] = useState<
    UploadProductExcelRows[] | undefined
  >(undefined);
  const [isFromEmail, setIsFromEmail] = useState<boolean>(false);
  const [requesterTempName, setRequesterTempName] = useState<string>("");
  // keep track of which purchase request we are deleting
  const [deletingPurchaseRequestItemId, setDeletingPurchaseRequestItemId] =
    useState<string>();

  const { history } = useRouter();

  // Used to control refetch queries;
  const suggestBaseOptions = {
    refetchQueries: ["CreatePurchaseRequestView"],
    awaitRefetchQueries: true,
  };

  if (isEditing) {
    suggestBaseOptions.refetchQueries.push("PurchaseRequestByCode");
  }

  if (fromPortal) {
    suggestBaseOptions.refetchQueries.push("RequesterPortalView");
  } else {
    suggestBaseOptions.refetchQueries.push("PaginatedPurchaseRequests");
  }

  // Resolve required information
  const costCentres = organization ? organization.costCentres : [];
  const departments = organization ? organization.departments : [];
  const projects = organization ? organization.projects : [];
  const units = organization
    ? (organization.quantityUnits as QuantityUnitInfo[])
    : [];
  const users = organization ? organization.users : [];

  // Configure suggestions
  const getRequestorUsers = async (value: string) =>
    users
      .filter(
        (user) =>
          matches(value, `${user.firstName} ${user.lastName}`) ||
          matches(value, user.email),
      )
      .map(userToAutosuggestItem);

  const suggestCostCentres = async (value: string) =>
    (value.length === 0
      ? costCentres
      : costCentres.filter((costCentre) => matches(value, costCentre.name))
    ).map(costCentreToAutosuggestItem);

  const suggestDepartments = async (value: string) =>
    (value.length === 0
      ? departments
      : departments.filter((department) => matches(value, department.name))
    ).map(departmentToAutosuggestItem);

  const suggestProjects = async (value: string) =>
    (value.length === 0
      ? projects
      : projects.filter((project) => matches(value, project.name))
    ).map(projectToAutosuggestItem);

  // Setup creation of suggest items
  const [createCostCentre, { loading: isCreatingCostCentre }] =
    useCreateCostCentre(suggestBaseOptions);

  const [createDepartment, { loading: isCreatingDepartment }] =
    useCreateDepartment(suggestBaseOptions);

  const [createProject, { loading: isCreatingProject }] =
    useCreateProject(suggestBaseOptions);

  // Configure create requestor mutation
  const [
    createRequestor,
    { loading: isCreateRequestorLoading, error: requestorError },
  ] = useCreateRequestor({
    refetchQueries: ["CreatePurchaseRequestView"],
    awaitRefetchQueries: true,
  });

  // Submit creating requestor
  const submitCreatingRequestor = async (
    values: Pick<
      CreateRequestorMutationVariables,
      "firstName" | "lastName" | "email"
    >,
  ) => {
    const response = await createRequestor({
      variables: {
        ...values,
        organizationId: organization.id,
      },
    });

    // set the chosen requestor as the created user
    if (response.data) {
      setAreRequestorFieldsActive(false);
      setChosenRequestor(userToAutosuggestItem(response.data.createRequestor));
      setRequestorDisplayValue(
        `${response.data.createRequestor.firstName} ${response.data.createRequestor.lastName}`,
      );
    }
  };

  // Configure create requestor form
  const formRequestorMethods = useForm<CreateRequestorMutationVariables>();

  // Setup deleting purchase request item
  const [deletePurchaseRequestItem] =
    useDeletePurchaseRequestItem(suggestBaseOptions);

  // attempts to delete requested purchase request item
  const deleteItem = async (purchaseRequestItemId: string) => {
    setDeletingPurchaseRequestItemId(purchaseRequestItemId);

    try {
      await deletePurchaseRequestItem({ variables: { purchaseRequestItemId } });
    } finally {
      setDeletingPurchaseRequestItemId(undefined);
    }
  };

  // set up creation roles for autosuggest
  const allowedCreationRoles = [
    UserRoleEnum.ADMIN,
    UserRoleEnum.KEY_USER,
    UserRoleEnum.BUYER,
  ];

  const isAllowedToCreate = viewer.roles.some((role) =>
    allowedCreationRoles.includes(role),
  );

  // Configure Form
  const {
    errors,
    control,
    getValues,
    register,
    reset,
    setValue,
    trigger,
    formState,
  } = useFormContext<CreatePurchaseRequestFormData>();

  // Configure item fields
  const { fields, append, remove } = useFieldArray<PurchaseRequestItemProp>({
    control,
    name: "items",
  });

  const addNewItem = async () => {
    // Trigger validation to only create new item if has all fields filled
    const result = await trigger();
    if (result) {
      setIsAddingItem(true);
      append({
        name: "",
        quantity: null,
        type: PurchaseRequestItemTypeEnum.PRODUCT,
      });
    }
  };

  const removeItem = async (index: number, id?: string) => {
    remove(index);

    if (id) {
      await deletePurchaseRequestItem({
        variables: { purchaseRequestItemId: id },
      });
    }
  };

  // Configure attachments
  const [removePRAttachment] = useRemovePurchaseRequestAttachment({
    refetchQueries: ["PurchaseRequestByCode"],
    awaitRefetchQueries: true,
  });

  // Setup uploading product excel
  const [uploadProductExcel, { loading: isUploadingExcel }] =
    useUploadProductExcel(suggestBaseOptions);

  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // set selected file
      setFiles([...(files ?? []), ...acceptedFiles]);
    },
    [files, setFiles],
  );

  // setup removing attachment
  const removeAttachment = (
    attachmentId: string,
    purchaseRequestId: string,
  ) => {
    removePRAttachment({
      variables: { attachmentId, purchaseRequestId },
    });

    setAttachments(
      attachments.filter((stateFile) => attachmentId !== stateFile.id),
    );
  };

  // Configure excel
  // handles file drop
  const onDropExcel = useCallback<OnDropCallbackFn>(async (acceptedFiles) => {
    // return if not exactly one file was provided
    if (acceptedFiles.length !== 1) {
      return;
    }

    // Remove error state
    setHasExcelError(false);

    // set selected file
    setSelectedExcelFile([acceptedFiles[0]]);

    // Upload excel file
    const uploadResult = await uploadProductExcel({
      variables: {
        organizationId: organization.id,
        purchaseRequestId: purchaseRequest ? purchaseRequest.id : null,
        name: getValues("name") ?? "",
        excel: acceptedFiles[0],
      },
      refetchQueries: ["PaginatedPurchaseRequests"],
    });

    // If has invalid result return
    if (!uploadResult.data) return;

    // Has error on excel upload
    if (uploadResult.data.uploadProductExcel.success === false) {
      // Set errors
      setExcelUploadError(uploadResult.data.uploadProductExcel.rows);
      setHasExcelError(true);
    } else {
      setSelectedExcelFile(undefined);

      const values = {
        ...getValues(),
        ...{
          name:
            getValues("name").length > 0
              ? getValues("name")
              : uploadResult.data.uploadProductExcel.purchaseRequest?.name ??
                "",
          purchaseRequestId:
            uploadResult.data.uploadProductExcel.purchaseRequest?.id ?? "",
        },
      };

      savePurchaseRequest(
        values,
        undefined,
        uploadResult.data.uploadProductExcel.purchaseRequest?.id,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fill also department, project and cost centre for the requestor
  useEffect(() => {
    if (!chosenRequestor) {
      return;
    }

    const user = users.find((u) => u.id === chosenRequestor.id);

    if (!user?.roles.includes(UserRoleEnum.REQUESTER)) {
      return;
    }

    if (user.department && !chosenDepartment) {
      setChosenDepartment(user.department);
      setDepartmentDisplayValue(user.department.name);
    }

    if (user.project && !chosenProject) {
      setChosenProject(user.project);
      setProjectDisplayValue(user.project.name);
    }

    if (user.costCentre && !chosenCostCentre) {
      setChosenCostCentre(user.costCentre);
      setCostCentreDisplayValue(user.costCentre.name);
    }
  }, [chosenRequestor]);

  // Reflect state into hook form
  useEffect(() => {
    setHasExcelError(false);
    if (selectedExcelFile && selectedExcelFile.length > 0) {
      // Remove pending items to be created/updated
      remove();
    } else {
      // Crate a blank item to be created
      if (fields.length === 0)
        append({
          name: "",
          quantity: null,
          type: PurchaseRequestItemTypeEnum.PRODUCT,
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExcelFile]);

  useEffect(() => {
    setHasExcelError(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelUploadError]);

  // Fill items if is editing purchase request
  useEffect(() => {
    if (!purchaseRequest) {
      return;
    }

    const prRequestor = users.find(
      (user) => user.id === purchaseRequest.requestorId,
    );
    const prDepartment = departments.find(
      (department) => department.id === purchaseRequest.departmentId,
    );
    const prProject = projects.find(
      (project) => project.id === purchaseRequest.projectId,
    );
    const prCostCentre = costCentres.find(
      (costCentre) => costCentre.id === purchaseRequest.costCentreId,
    );

    if (prRequestor && !fromPortal) {
      // convert user to autosuggest item
      const requestorItem = userToAutosuggestItem(prRequestor);
      setChosenRequestor(requestorItem);
      setRequestorDisplayValue(requestorItem.name || "");
      setValue("requestorId", requestorItem.name || "");
    }

    if (prDepartment) {
      const departmentItem = departmentToAutosuggestItem(prDepartment);
      setChosenDepartment(departmentItem);
      setDepartmentDisplayValue(departmentItem.name || "");
      setValue("departmentId", departmentItem.id || "");
    }

    if (prProject) {
      const projectItem = projectToAutosuggestItem(prProject);
      setChosenProject(projectItem);
      setProjectDisplayValue(projectItem.name || "");
      setValue("projectId", projectItem.id || "");
    }

    if (prCostCentre) {
      const costCentreItem = costCentreToAutosuggestItem(prCostCentre);
      setChosenCostCentre(costCentreItem);
      setCostCentreDisplayValue(costCentreItem.name || "");
      setValue("costCentreId", costCentreItem.id || "");
    }

    if (purchaseRequest.attachments) {
      setAttachments(purchaseRequest.attachments as []);
    }

    // Check if the request is created from email and not from portal
    // Used to hide the additional information field
    setIsFromEmail(isCreatedByEmail(purchaseRequest));

    // Set default values
    reset({
      name: purchaseRequest.name,
      notes: purchaseRequest.notes,
      items:
        purchaseRequest.items.length > 0
          ? []
          : [{ name: "", type: PurchaseRequestItemTypeEnum.PRODUCT }],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseRequest]);

  const customNameValidator = (name: string) => {
    const formValues = getValues();
    const isNameEmpty = name.length === 0;
    const hasFormItems =
      Array.isArray(formValues.items) && formValues.items.length > 0;
    const itemNameNotEmpty =
      hasFormItems && formValues.items[0].name.length > 0;

    if (isNameEmpty && itemNameNotEmpty) {
      setValue("name", formValues.items[0].name);

      return true;
    }

    return !isNameEmpty;
  };

  return (
    <Form
      error={error}
      className={classNames(styles.wrap, {
        [styles["wrap--create"]]: !isEditing,
      })}
    >
      {isUploadingExcel && <LoadingView overlay />}
      <div>
        <AccordionSecondary
          title={
            <h3
              className={classNames(styles["accordion-title"], {
                [styles["accordion-title--portal"]]: fromPortal,
              })}
            >
              Request info
            </h3>
          }
          secondary={fromPortal}
        >
          <div className={styles.content}>
            <div className={styles["content-fields"]}>
              {purchaseRequest && (
                <input
                  type="hidden"
                  name="purchaseRequestId"
                  defaultValue={purchaseRequest.id}
                  ref={register}
                />
              )}
              <Field
                className={styles.input}
                inputRef={register({
                  validate: customNameValidator,
                })}
                errors={errors.name ? ["Should not be left empty"] : undefined}
                onChange={(e) => {
                  setValue("name", e.target.value);
                }}
                addon={
                  purchaseRequest && !fromPortal
                    ? `PR-${purchaseRequest.code}`
                    : undefined
                }
                name="name"
                label="Request name"
                required
              />

              {!fromPortal && (
                <div className={styles["suggest"]}>
                  <input
                    type="hidden"
                    name="requestorId"
                    defaultValue={chosenRequestor?.id}
                    ref={register}
                  />
                  <ItemAutosuggest
                    fetchSuggestions={getRequestorUsers}
                    value={requestorDisplayValue}
                    chosenSuggestion={chosenRequestor}
                    onChange={(newValue) => setRequestorDisplayValue(newValue)}
                    onChoose={(requestor: AutosuggestItem | undefined) =>
                      setChosenRequestor(requestor)
                    }
                    onCreateNew={() => {
                      setAreRequestorFieldsActive(true);
                      setRequesterTempName(requestorDisplayValue);
                      setChosenRequestor(undefined);
                      setRequestorDisplayValue("");
                    }}
                    loading={isCreateRequestorLoading}
                    name="requestorSuggest"
                    label="Requested by"
                    lockedLabel="Requested by"
                  />
                  {areRequestorFieldsActive && (
                    <div>
                      <ErrorMessage error={requestorError} />
                      <FormProvider {...formRequestorMethods}>
                        <RequestorInput
                          isCreateRequestorLoading={isCreateRequestorLoading}
                          requesterTempName={requesterTempName}
                          setAreRequestorFieldsActive={
                            setAreRequestorFieldsActive
                          }
                          submitCreatingRequestor={submitCreatingRequestor}
                        />
                      </FormProvider>
                    </div>
                  )}
                </div>
              )}

              <div className={styles["suggest"]}>
                <input
                  type="hidden"
                  name="departmentId"
                  defaultValue={chosenDepartment?.id}
                  ref={register}
                />
                <ItemAutosuggest
                  fetchSuggestions={suggestDepartments}
                  value={departmentDisplayValue}
                  chosenSuggestion={chosenDepartment}
                  onChange={(newValue) => setDepartmentDisplayValue(newValue)}
                  onChoose={(department: AutosuggestItem | undefined) =>
                    setChosenDepartment(department)
                  }
                  hasPermissionToCreateNew={isAllowedToCreate}
                  onCreateNew={async (name: string) => {
                    const createDepartmentResponse = await createDepartment({
                      variables: {
                        organizationId: organization.id,
                        shippingAddressId: null,
                        invoiceAddressId: null,
                        name: name,
                        code: null,
                        domain: null,
                      },
                    });
                    const createdDepartment =
                      createDepartmentResponse.data?.createDepartment;

                    if (createdDepartment) {
                      setChosenDepartment(
                        departmentToAutosuggestItem(createdDepartment),
                      );
                      setDepartmentDisplayValue(name);
                    }
                  }}
                  loading={isCreatingDepartment}
                  name="unitSuggest"
                  label="Business Unit"
                  lockedLabel="Unit"
                />
              </div>

              <div className={styles["suggest"]}>
                <input
                  type="hidden"
                  name="projectId"
                  defaultValue={chosenProject?.id}
                  ref={register}
                />
                <ItemAutosuggest
                  fetchSuggestions={suggestProjects}
                  value={projectDisplayValue}
                  chosenSuggestion={chosenProject}
                  onChange={(newValue) => setProjectDisplayValue(newValue)}
                  onChoose={(project: AutosuggestItem | undefined) =>
                    setChosenProject(project)
                  }
                  onCreateNew={async (name: string) => {
                    const createdProjectResponse = await createProject({
                      variables: {
                        organizationId: organization.id,
                        name: name,
                      },
                    });
                    const createdProject =
                      createdProjectResponse.data?.createProject;

                    if (createdProject) {
                      setChosenProject(
                        projectToAutosuggestItem(createdProject),
                      );
                      setProjectDisplayValue(name);
                    }
                  }}
                  loading={isCreatingProject}
                  name="projectSuggest"
                  label="Project / product"
                  lockedLabel="Project / product"
                />
              </div>

              <div className={styles["suggest"]}>
                <input
                  type="hidden"
                  name="costCentreId"
                  defaultValue={chosenCostCentre?.id}
                  ref={register}
                />
                <ItemAutosuggest
                  fetchSuggestions={suggestCostCentres}
                  value={costCentreDisplayValue}
                  chosenSuggestion={chosenCostCentre}
                  onChange={(newValue) => setCostCentreDisplayValue(newValue)}
                  onChoose={(costCentre: AutosuggestItem | undefined) =>
                    setChosenCostCentre(costCentre)
                  }
                  hasPermissionToCreateNew={isAllowedToCreate}
                  onCreateNew={async (name: string) => {
                    const createCostCentreResponse = await createCostCentre({
                      variables: {
                        organizationId: organization.id,
                        name: name,
                      },
                    });
                    const createdCostCentre =
                      createCostCentreResponse.data?.createCostCentre;

                    if (createdCostCentre) {
                      const item =
                        costCentreToAutosuggestItem(createdCostCentre);

                      setChosenCostCentre(item);
                      setCostCentreDisplayValue(name);
                    }
                  }}
                  loading={isCreatingCostCentre}
                  name="costCentreSuggest"
                  label="Cost center"
                  lockedLabel="Cost center"
                />
              </div>

              <Field
                className={classNames(styles.input, {
                  [styles["input--hide"]]: isFromEmail,
                })}
                textareaRef={register}
                onChange={(e) => {
                  setValue("notes", e.target.value);
                }}
                name="notes"
                label="Additional info"
                textarea
              />

              {((attachments && attachments.length > 0) ||
                (files && files.length > 0)) && (
                <>
                  <FieldLabel label="Attachments" />
                  <div className={styles.attachments}>
                    {attachments &&
                      attachments.length > 0 &&
                      attachments.map((attachment) => (
                        <UploadedAttachment
                          key={attachment.id}
                          attachment={attachment}
                          onRemove={() => {
                            purchaseRequest?.id &&
                              removeAttachment(
                                attachment.id,
                                purchaseRequest.id,
                              );
                          }}
                        />
                      ))}
                    {files && files.length > 0 && (
                      <NonUploadedFiles files={files} setFiles={setFiles} />
                    )}
                  </div>
                </>
              )}

              <div className={styles["attachment-button"]}>
                <Controller
                  defaultValue={""}
                  as={Attachments}
                  control={control}
                  secondary
                  multiple
                  loading={false}
                  onDrop={(acceptedFiles: File[]) => onDrop(acceptedFiles)}
                  name="files"
                />
              </div>
            </div>
          </div>
        </AccordionSecondary>
        <AccordionSecondary
          title={<h3 className={styles["accordion-title"]}>Requested items</h3>}
          secondary={fromPortal}
        >
          <div
            className={classNames(styles.content, {
              [styles["content--portal"]]: fromPortal,
            })}
          >
            {hasExcelError && excelUploadError && (
              <div className={styles["excel-item"]}>
                <>
                  <FieldError error="Provided spreadsheet file is not valid, please fix the issues listed below and try again" />
                  <div className={styles["excel-container"]}>
                    <ExcelProductsImportErrorsTable rows={excelUploadError} />
                  </div>
                </>
              </div>
            )}
            {/* Request Items */}
            {purchaseRequest && purchaseRequest.items.length > 0 && (
              <PurchaseRequestItems
                isEditable={
                  purchaseRequest?.status === PurchaseRequestStatusEnum.DRAFT ||
                  purchaseRequest?.status ===
                    PurchaseRequestStatusEnum.CREATED_FROM_EMAIL
                }
                items={purchaseRequest?.items || []}
                loadingItemId={deletingPurchaseRequestItemId}
                showItemProgress
                showCtaButtons
                onEdit={(itemId, itemType) => {
                  if (fromPortal && setIsEditingItem) {
                    setIsEditingItem({
                      item: purchaseRequest.items.find(
                        (obj) => obj.id === itemId,
                      ) as ItemParams,
                      itemType,
                    });
                  } else {
                    itemType === "PRODUCT"
                      ? history.push(
                          `/${organization.urlName}/edit-product/PR-${purchaseRequest.code}/item-${itemId}`,
                        )
                      : history.push(
                          `/${organization.urlName}/edit-service/PR-${purchaseRequest.code}/item-${itemId}`,
                        );
                  }
                }}
                onDelete={(purchaseRequestItemId) =>
                  deleteItem(purchaseRequestItemId)
                }
              />
            )}
            {/* Request Item to be created */}
            {fields.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <PurchaseRequestItemForm
                    index={index}
                    organization={organization}
                    units={units}
                    value={item}
                    active={
                      isAddingItem &&
                      fields.length > 1 &&
                      index + 1 === fields.length
                    }
                    removeItem={removeItem}
                    fromPortal={fromPortal}
                  />
                </React.Fragment>
              );
            })}
            {(!purchaseRequest?.items || purchaseRequest.items.length === 0) &&
              (!Object.keys(formState.touched).includes("items") ||
                formState.touched.items?.length === 0) && (
                <div className={styles["attachment-button"]}>
                  <a
                    href="https://www.procurementflow.com/helpcenter/how-to-create-pr-from-excel-bill-of-materials-bom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["excel-helper"]}
                  >
                    <TooltipIcon />
                  </a>
                  <Controller
                    as={Attachments}
                    control={control}
                    multiple
                    secondary
                    loading={false}
                    accept={[
                      "text/csv",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                    onDrop={(acceptedFiles: File[]) =>
                      onDropExcel(acceptedFiles)
                    }
                    name="excel"
                    title="Import from Excel"
                  />
                </div>
              )}
          </div>
          {!selectedExcelFile && (
            <div className={styles.footer}>
              <Button
                className={styles["button-add"]}
                onClick={() => {
                  addNewItem();
                }}
              >
                <PlusIcon /> Add new item
              </Button>
            </div>
          )}
        </AccordionSecondary>
      </div>
    </Form>
  );
};
