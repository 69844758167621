import React from "react";
import classNames from "classnames";
import { CommentHollowIcon } from "../../theme/svg/CommentHollowIcon";
import { Avatar } from "../Avatar/Avatar";
import styles from "./EmailLink.module.scss";

export interface EmailLinkProps {
  className?: string;
  subject?: string;
  toEmail?: string;
  contactPerson?: {
    id: string;
    email?: string;
    avatarUrl?: string;
    firstName: string;
    lastName: string;
  };
}

export const EmailLink: React.FC<EmailLinkProps> = (props) => {
  const { className, subject, toEmail, contactPerson } = props;

  return (
    <div className={classNames(styles["wrap"], className)}>
      <div className={styles["icon-wrapper"]}>
        <CommentHollowIcon className={styles["icon"]} />
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${toEmail}?cc=${
          contactPerson?.email ?? ""
        }&subject=${encodeURIComponent(subject ?? "")}`}
        className={styles["link"]}
      >
        Ask questions
      </a>
      <div className={styles["avatar"]}>
        <Avatar user={contactPerson} />
      </div>
    </div>
  );
};
