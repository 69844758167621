import React, { useEffect, useState } from "react";
import { useVersionQuery } from "../../schema";
import { Notification } from "../Notification/Notification";
import { isVersionDifferent } from "../../services/isVersionDifferent";
import styles from "./Version.module.scss";

export const Version: React.FC = () => {
  // setup state
  const [initialClientVersion, setInitialClientVersion] = useState<
    string | undefined
  >(undefined);
  const [showVersionNotification, setShowVersionNotification] = useState(true);

  // fetch version
  const { data, loading, refetch } = useVersionQuery({
    errorPolicy: "ignore",
  });

  // refetch version when window receives focus or becomes visible again
  useEffect(() => {
    const onWindowVisibilityChange = () => {
      if (!document.hidden) {
        refetch();
      }
    };

    window.addEventListener("visibilitychange", onWindowVisibilityChange);
    window.addEventListener("focus", onWindowVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", onWindowVisibilityChange);
      window.removeEventListener("focus", onWindowVisibilityChange);
    };
  }, [refetch]);

  // show loading state
  if (!data || loading || !data.version) {
    return <div className={styles.version}>Checking for new version</div>;
  }

  // store initial versions
  if (!initialClientVersion) {
    setInitialClientVersion(data.version.client);

    return null;
  }

  // check for version differences (at least minor version needs to be different)
  const isVersionMismatch = isVersionDifferent(
    initialClientVersion,
    data.version.client,
    "minor",
  );

  return (
    <>
      <Notification
        show={isVersionMismatch && showVersionNotification}
        onCloseRequested={() => setShowVersionNotification(false)}
      >
        New application version {data.version.client} is available (current is{" "}
        {initialClientVersion}).
        <br />
        Please{" "}
        <a
          data-testid="25dad6fc21"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
          title="Click here to reload the page"
        >
          reload the page
        </a>{" "}
        for optimum experience!
      </Notification>

      <div className={styles.version}>
        <span title="Client version">v{initialClientVersion}</span> /{" "}
        <span title="Server version">v{data.version.server}</span>
      </div>
    </>
  );
};
