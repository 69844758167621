import React from "react";
import { formatDatetime } from "../../services/formatDatetime";
import styles from "./OfferSentStatus.module.scss";

export enum OfferSentStatusEnum {
  SENT = "SENT",
  PENDING = "PENDING",
  PREVIEW = "PREVIEW",
}
interface OfferSentStatusProps {
  dateTime?: Date;
  state?: OfferSentStatusEnum;
}

export const OfferSentStatus: React.FC<OfferSentStatusProps> = (props) => {
  const { dateTime, state } = props;

  return (
    <div data-state={state} className={styles.wrap}>
      {state === OfferSentStatusEnum.SENT && dateTime && (
        <div className={styles.status}>{`SENT ${formatDatetime(
          dateTime,
        )}`}</div>
      )}
      {state === OfferSentStatusEnum.PENDING && (
        <div className={styles.status}>NEW</div>
      )}
      {state === OfferSentStatusEnum.PREVIEW && (
        <div className={styles.status}>PREVIEW</div>
      )}
    </div>
  );
};
