import React from "react";
import classNames from "classnames";
import { ViewerViewer } from "../../schema";
import { Header } from "../../components/Header/Header";
import { ProfileDropdown } from "../../components/ProfileDropdown/ProfileDropdown";
import styles from "./SupplierPortalLayout.module.scss";

export interface SupplierPortalLayoutProps {
  organization?: {
    urlName?: string | null;
    companyLogoUrl?: string | null;
    companyName?: string | null;
  };
  children: JSX.Element;
  className?: string;
  viewer?: ViewerViewer;
}

export const SupplierPortalLayout: React.FC<SupplierPortalLayoutProps> = (
  props,
) => {
  const { className, organization, viewer, children } = props;

  return (
    <div className={classNames(styles["view"], className)}>
      {/* header */}
      <Header
        secondary
        organization={organization}
        viewer={viewer}
        title="Supplier Portal"
        isPortal
      >
        {viewer && <ProfileDropdown viewer={viewer} user={viewer} />}
      </Header>

      {/* TODO: menu */}
      <div className={styles["menu"]}></div>

      <div className={styles["content-wrap"]}>
        <div className={styles["content"]}>{children}</div>
      </div>
    </div>
  );
};
