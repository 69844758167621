import React, { useEffect, useState } from "react";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import {
  useOrganizationUserById,
  useUpdateOrganizationUser,
  UserRoleEnum,
  Viewer,
  UserStatusEnum,
  OrganizationUserByIdDepartments,
  OrganizationUserByIdProjects,
  OrganizationUserByIdCostCentres,
  useCreateDepartment,
  useCreateCostCentre,
  useCreateProject,
} from "../../schema";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Unit } from "../../components/Unit/Unit";
import { FieldError } from "../../components/FieldError/FieldError";
import { Grid } from "../../components/Grid/Grid";
import { matches } from "../../services/matches";
import { ItemAutosuggest } from "../../components/ItemAutosuggest/ItemAutosuggest";
import styles from "./CompanyDetailsView.module.scss";

export interface UpdateUserProps {
  organizationId: string;
  userId: string;
  countries: Viewer["countries"];
  onModalClose(): void;
}

type RoleMap = { [role in keyof typeof UserRoleEnum]: boolean };

export const UpdateUser: React.FC<UpdateUserProps> = ({
  organizationId,
  userId,
  countries,
  onModalClose,
}) => {
  // setup state
  const [isInitialized, setIsInitialized] = useState(false);
  const [userRolesError, setUserRolesError] = useState<string>();

  // setup form
  const {
    useInput,
    useCheckbox,
    useSelect,
    loading: isSaving,
    error: saveError,
    submit: updateUser,
  } = useForm({
    mutation: useUpdateOrganizationUser,
    onSuccess: () => onModalClose(),
    onError: (e) => {
      const roleErrors = e.graphQLErrors.filter(
        (err) => err?.extensions?.errors["roles"],
      );

      if (roleErrors.length > 0) {
        setUserRolesError("At least 1 role has to be selected");
      }
    },
  });

  // load user by id
  const {
    data,
    loading: isFetching,
    error: fetchError,
  } = useOrganizationUserById({
    fetchPolicy: "network-only",
    variables: {
      organizationId,
      userId,
    },
  });

  // configure inputs
  const firstNameInput = useInput({ name: "firstName" });
  const lastNameInput = useInput({ name: "lastName" });
  const emailInput = useInput({ name: "email" });
  const requesterRoleCheckbox = useCheckbox({ name: "role-requester" });
  const approverRoleCheckbox = useCheckbox({ name: "role-approver" });
  const buyerRoleCheckbox = useCheckbox({ name: "role-buyer" });
  const keyUserRoleCheckbox = useCheckbox({ name: "role-key-user" });
  const phoneNumberInput = useInput({ name: "phoneNumber", optional: true });
  const countrySelect = useSelect({
    name: "countryCode",
    options: [
      { label: "--", value: "" },
      ...countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    ],
    optional: true,
  });
  const statuses = Object.keys(UserStatusEnum) as Array<
    keyof typeof UserStatusEnum
  >;
  const statusSelect = useSelect({
    name: "status",
    options: statuses.map((status) => ({
      label: status.charAt(0) + status.slice(1).toLowerCase(),
      value: status,
    })),
    optional: true,
  });

  // get user info if loaded
  const user = data?.viewer && data.viewer.organization.userById;

  // company data
  const departments = data?.viewer?.organization?.departments;

  const projects = data?.viewer?.organization?.projects;

  const costCentres = data?.viewer?.organization?.costCentres;

  // autosuggest state
  const [chosenDepartment, setChosenDepartment] = useState<
    OrganizationUserByIdDepartments | undefined
  >(undefined);
  const [chosenProject, setChosenProject] = useState<
    OrganizationUserByIdProjects | undefined
  >(undefined);
  const [chosenCostCentre, setChosenCostCentre] = useState<
    OrganizationUserByIdCostCentres | undefined
  >(undefined);

  const [departmentDisplayValue, setDepartmentDisplayValue] = useState<string>(
    chosenDepartment ? chosenDepartment.name : "",
  );

  const [projectDisplayValue, setProjectDisplayValue] = useState<string>(
    chosenProject ? chosenProject.name : "",
  );

  const [costCentreDisplayValue, setCostCentreDisplayValue] = useState<string>(
    chosenCostCentre ? chosenCostCentre.name : "",
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    firstNameInput.setValue(user.firstName);
    lastNameInput.setValue(user.lastName);
    emailInput.setValue(user.email);
    countrySelect.setValue(user.countryCode || "");
    requesterRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.REQUESTER));
    approverRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.APPROVER));
    buyerRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.BUYER));
    keyUserRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.KEY_USER));
    statusSelect.setValue(user.status);
    phoneNumberInput.setValue(user.phoneNumber || "");

    setChosenDepartment(user.department ?? undefined);
    setDepartmentDisplayValue(user.department?.name ?? "");

    setChosenProject(user.project ?? undefined);
    setProjectDisplayValue(user.project?.name ?? "");

    setChosenCostCentre(user.costCentre ?? undefined);
    setCostCentreDisplayValue(user.costCentre?.name ?? "");

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Setup creation of suggestion items
  // Used to control refetch queries;
  const suggestBaseOptions = {
    refetchQueries: ["OrganizationUserById"],
    awaitRefetchQueries: true,
  };

  const [createCostCentre, { loading: isCreatingCostCentre }] =
    useCreateCostCentre(suggestBaseOptions);

  const [createDepartment, { loading: isCreatingDepartment }] =
    useCreateDepartment(suggestBaseOptions);

  const [createProject, { loading: isCreatingProject }] =
    useCreateProject(suggestBaseOptions);

  // submit form
  const submit = () => {
    const selectedRolesMap: RoleMap = {
      [UserRoleEnum.REQUESTER]: requesterRoleCheckbox.value,
      [UserRoleEnum.APPROVER]: approverRoleCheckbox.value,
      [UserRoleEnum.BUYER]: buyerRoleCheckbox.value,
      [UserRoleEnum.KEY_USER]: keyUserRoleCheckbox.value,
      [UserRoleEnum.SUPPLIER]: false,
      [UserRoleEnum.ADMIN]: false,
    };

    const roleNames = Object.keys(selectedRolesMap) as UserRoleEnum[];
    const selectedRoles = roleNames.reduce<UserRoleEnum[]>((result, role) => {
      const hasRole = selectedRolesMap[role];

      if (!hasRole) {
        return result;
      }

      return [...result, role];
    }, []);

    updateUser({
      userId,
      organizationId,
      roles: selectedRoles,
      departmentId: chosenDepartment?.id ?? null,
      projectId: chosenProject?.id ?? null,
      costCentreId: chosenCostCentre?.id ?? null,
    });
  };

  const fetchDepartments = async (value: string) =>
    value.length === 0
      ? departments ?? []
      : departments?.filter((department) => matches(value, department.name)) ??
        [];

  const fetchProjects = async (value: string) =>
    value.length === 0
      ? projects ?? []
      : projects?.filter((project) => matches(value, project.name)) ?? [];

  const fetchCostCentres = async (value: string) =>
    value.length === 0
      ? costCentres ?? []
      : costCentres?.filter((costCentre) => matches(value, costCentre.name)) ??
        [];

  // handle fetch error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading user failed"
        error={fetchError}
        modal={{ title: "Edit user", onModalClose }}
      />
    );
  }

  // handle save error
  if (saveError) {
    return (
      <ErrorView
        title="Saving user failed"
        error={saveError}
        modal={{ title: "Edit user", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || isSaving || !isInitialized || !user) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      title="Edit user"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="16094acc6f" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="fcda6a99b6"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form onSubmit={submit} className={styles["right-margin"]}>
        <Field {...firstNameInput} label="First name" short />
        <Field {...lastNameInput} label="Last name" short />
        <Field {...emailInput} label="Email" short />
        <Grid gridAreas={"left right"} gridColumns={"1fr 2fr"}>
          <div>
            <FieldLabel label="Roles" />
            <Field {...requesterRoleCheckbox} checkbox>
              Requester
            </Field>
            <Field {...approverRoleCheckbox} checkbox>
              Approver
            </Field>
            <Field {...buyerRoleCheckbox} checkbox>
              Buyer
            </Field>
            <Field {...keyUserRoleCheckbox} checkbox>
              Key-user
            </Field>
            {userRolesError && (
              <Unit>
                <FieldError error={userRolesError} />
              </Unit>
            )}
          </div>

          <div>
            <ItemAutosuggest
              label={"Business Unit"}
              fetchSuggestions={fetchDepartments}
              value={departmentDisplayValue}
              chosenSuggestion={chosenDepartment}
              onChange={(newValue) => setDepartmentDisplayValue(newValue)}
              onChoose={(department) => {
                if (department) {
                  setChosenDepartment(
                    departments?.find((dep) => dep.id === department.id),
                  );
                } else {
                  setChosenDepartment(undefined);
                }
              }}
              onCreateNew={async (name: string) => {
                const createDepartmentResponse = await createDepartment({
                  variables: {
                    organizationId,
                    shippingAddressId: null,
                    invoiceAddressId: null,
                    name: name,
                    code: null,
                    domain: null,
                  },
                });
                const createdDepartment =
                  createDepartmentResponse.data?.createDepartment;

                if (createdDepartment) {
                  setChosenDepartment({
                    id: createdDepartment.id,
                    name: createdDepartment.name,
                    code: null,
                  });
                  setDepartmentDisplayValue(name);
                }
              }}
              loading={isCreatingDepartment}
            />

            <ItemAutosuggest
              label={"Project/Product"}
              fetchSuggestions={fetchProjects}
              value={projectDisplayValue}
              chosenSuggestion={chosenProject}
              onChange={(newValue) => setProjectDisplayValue(newValue)}
              onChoose={(project) => {
                if (project) {
                  setChosenProject(
                    projects?.find((proj) => proj.id === project.id),
                  );
                } else {
                  setChosenProject(undefined);
                }
              }}
              onCreateNew={async (name: string) => {
                const createProjectResponse = await createProject({
                  variables: {
                    organizationId,
                    name: name,
                  },
                });
                const createdProject =
                  createProjectResponse.data?.createProject;

                if (createdProject) {
                  setChosenProject({
                    id: createdProject.id,
                    name: createdProject.name,
                  });
                  setProjectDisplayValue(name);
                }
              }}
              loading={isCreatingProject}
            />

            <ItemAutosuggest
              label={"Cost Center"}
              fetchSuggestions={fetchCostCentres}
              value={costCentreDisplayValue}
              chosenSuggestion={chosenCostCentre}
              onChange={(newValue) => setCostCentreDisplayValue(newValue)}
              onChoose={(costCentre) => {
                if (costCentre) {
                  setChosenCostCentre(
                    costCentres?.find(
                      (costCtr) => costCtr.id === costCentre.id,
                    ),
                  );
                } else {
                  setChosenCostCentre(undefined);
                }
              }}
              onCreateNew={async (name: string) => {
                const createCostCentreResponse = await createCostCentre({
                  variables: {
                    organizationId,
                    name: name,
                  },
                });
                const createdCostCentre =
                  createCostCentreResponse.data?.createCostCentre;

                if (createdCostCentre) {
                  setChosenCostCentre({
                    id: createdCostCentre.id,
                    name: createdCostCentre.name,
                  });
                  setCostCentreDisplayValue(name);
                }
              }}
              loading={isCreatingCostCentre}
            />
          </div>
        </Grid>
        <Field {...phoneNumberInput} label="Phone number" short />
        <Select {...countrySelect} label="Country" short />
        <Select {...statusSelect} label="Status" short />
      </Form>
    </Modal>
  );
};
