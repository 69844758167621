import React, { useState } from "react";

import { ViewerOrganizations } from "../../schema";

import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./ReportingView.module.scss";

export interface ReportingViewProps {
  organization: ViewerOrganizations;
}

export const ReportingView: React.FC<ReportingViewProps> = ({
  organization,
}) => {
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true);

  return (
    <>
      {isIframeLoading && <LoadingView />}

      <iframe
        className={styles.iframe}
        src={organization.embeddedReport ?? undefined}
        width={"100%"}
        height={"100%"}
        onLoad={() => setIsIframeLoading(false)}
      />
    </>
  );
};
