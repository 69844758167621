import formatDistanceToNow from "date-fns/formatDistanceToNow";
/**
 * Formats date or date string to human readable time difference
 *
 * @param date - input date or date string
 * @example - Example output "about 2 days ago"
 */
export function formatTimeAgo(date: Date | string) {
  if (!date) {
    return null;
  }

  return formatDistanceToNow(new Date(date), {
    includeSeconds: true,
    addSuffix: true,
  });
}
