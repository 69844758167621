import React, { forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray } from "react-hook-form";

import { Field } from "../Field/Field";
import { Button } from "../Button/Button";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import styles from "./EsourcingQuantityFieldList.module.scss";

export interface EsourcingQuantityFieldListProps {
  itemIndex: number;
  className?: string;
}

export const EsourcingQuantityFieldList = forwardRef(
  (props: EsourcingQuantityFieldListProps, ref) => {
    const { itemIndex, className } = props;

    const { register } = useFormContext();
    const { fields, remove, append } = useFieldArray({
      name: `items[${itemIndex}].esourcingFields.quantities`,
    });

    useImperativeHandle(ref, () => ({
      append,
    }));

    const handleRemove = (quantityIndex: number) => {
      return function () {
        remove(quantityIndex);
      };
    };

    return (
      <div className={classNames(className)}>
        {fields.map((listItem, quantityIndex) => {
          return (
            <div key={listItem.id} className={styles["esourcing-field"]}>
              <Field
                key={quantityIndex}
                defaultValue={listItem.quantity ?? ""}
                name={`items[${itemIndex}].esourcingFields.quantities[${quantityIndex}].quantity`}
                label={`eSourcing Quantity ${quantityIndex + 1}`}
                inputRef={register()}
                short
              />
              <Button
                className={styles["remove-button"]}
                icon={<RemoveIcon />}
                onClick={handleRemove(quantityIndex)}
              />
            </div>
          );
        })}
      </div>
    );
  },
);
