import { AutosuggestItem } from "../components/ItemAutosuggest/ItemAutosuggest";
import { PurchaseRequestByCodeProjects } from "../schema";

export function projectToAutosuggestItem(
  project: PurchaseRequestByCodeProjects,
): AutosuggestItem {
  return {
    id: project.id,
    name: project.name,
  };
}
