import classNames from "classnames";
import React from "react";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { Attachments } from "../Attachments/Attachments";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";
import { Attachment } from "../../schema";
import styles from "./AddFiles.module.scss";

export interface AddFilesProps {
  className?: string;
  isEditable?: boolean;
  attachments?: Pick<Attachment, "id" | "url" | "filename">[];
  onAddFiles?: (uploadedFiles: File[] | undefined) => void;
}

export const AddFiles: React.FC<AddFilesProps> = (props) => {
  const { className, attachments, isEditable = true, onAddFiles } = props;

  // attachments to be uploaded
  const [attachedFiles, setAttachedFiles] = React.useState<File[]>([]);

  // attachments from server
  const [uploadedAttachments, setUploadedAttachments] = React.useState<
    typeof attachments | undefined
  >();

  // set files state on drop
  const onFileDrop = React.useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // add selected file
      setAttachedFiles([...attachedFiles, ...acceptedFiles]);
    },
    [attachedFiles],
  );

  React.useEffect(() => {
    // call callback to parent with files
    if (typeof onAddFiles === "function") {
      onAddFiles(attachedFiles);
    }
  }, [onAddFiles, attachedFiles]);

  // set files from parent (server)
  React.useEffect(() => {
    setUploadedAttachments(attachments);
  }, [attachments]);

  return (
    <div className={classNames(styles["wrap"], className)}>
      {isEditable && (
        <Attachments
          multiple
          loading={false}
          onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}
        />
      )}
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles["attachments"]}>
            {/* attachments on server */}
            {uploadedAttachments && (
              <>
                {uploadedAttachments?.map((attachment) => (
                  <div key={attachment.id}>
                    <a
                      href={attachment.url ?? "#"}
                      className={styles.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className={styles.name}>{attachment.filename}</span>
                      <OpenExternalIcon
                        className={classNames(
                          styles.icon,
                          styles["icon--open"],
                        )}
                      />
                    </a>
                  </div>
                ))}
              </>
            )}

            {/* attachments to be uploaded */}
            {attachedFiles && attachedFiles.length > 0 && (
              <>
                {attachedFiles.map((file) => (
                  <div key={file.name} className={styles["attachment-item"]}>
                    <DeleteFileIcon
                      className={classNames(styles["delete-file-icon"])}
                      onClick={() =>
                        setAttachedFiles(
                          attachedFiles.filter(
                            (stateFile) => file.name !== stateFile.name,
                          ),
                        )
                      }
                    />
                    {file.name}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
