import React from "react";
import { assertUnreachable } from "../../services/assertUnreachable";

/**
 * State of the headless UI component
 */
export interface AccordionState {
  isOpen: boolean;
}

/**
 * All possible actions with the state
 */
export enum AccordionActionTypesEnum {
  TOGGLE = "TOGGLE",
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}

/**
 * Default reducer that can be overridden by developer in consumer components
 */
function defaultReducer(
  state: AccordionState,
  action: { type: AccordionActionTypesEnum },
) {
  switch (action.type) {
    case AccordionActionTypesEnum.TOGGLE: {
      return { isOpen: !state.isOpen };
    }

    case AccordionActionTypesEnum.OPEN: {
      return { isOpen: true };
    }

    case AccordionActionTypesEnum.CLOSE: {
      return { isOpen: false };
    }

    default: {
      assertUnreachable(action.type, `Unhandled action: ${action.type}`);
    }
  }
}

/**
 * Hook that exposes parts of the state and its manipulation methods
 */
export function useAccordion({
  reducer = defaultReducer,
  initialState = { isOpen: true },
}) {
  const [{ isOpen }, dispatch] = React.useReducer(reducer, initialState);

  const toggle = () => dispatch({ type: AccordionActionTypesEnum.TOGGLE });

  const setOpen = () => dispatch({ type: AccordionActionTypesEnum.OPEN });

  const setClosed = () => dispatch({ type: AccordionActionTypesEnum.CLOSE });

  return { isOpen, toggle, setOpen, setClosed };
}
