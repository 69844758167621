import * as React from "react";
export const PinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="#d8d7da"
      d="M235.961 227.066v191.316l19.997 83.598 20.081-80.221V227.066"
    />
    <circle cx={256} cy={119.463} r={109.444} fill="#ff5d5d" />
    <path d="M375.459 119.459C375.459 53.589 321.87 0 256 0S136.541 53.589 136.541 119.459c0 55.491 38.033 102.265 89.401 115.627v183.295c0 .786.092 1.567.275 2.332l19.997 83.598a10.02 10.02 0 009.692 7.689h.054a10.02 10.02 0 009.719-7.587l20.081-80.221c.198-.796.3-1.612.3-2.433V235.086c51.366-13.362 89.399-60.135 89.399-115.627zM266.02 420.524l-9.84 39.312-10.2-42.636V238.495c3.304.276 6.645.424 10.02.424s6.715-.148 10.02-.424v182.029zM256 218.879c-54.82 0-99.42-44.6-99.42-99.42s44.6-99.42 99.42-99.42 99.42 44.6 99.42 99.42-44.6 99.42-99.42 99.42z" />
    <path d="M256 39.577c-44.047 0-79.882 35.835-79.882 79.882 0 5.534 4.487 10.02 10.02 10.02s10.02-4.486 10.02-10.02c0-32.997 26.845-59.843 59.843-59.843 5.533 0 10.02-4.486 10.02-10.02S261.533 39.577 256 39.577zM186.238 136.767h-.2c-5.533 0-10.02 4.486-10.02 10.02s4.487 10.02 10.02 10.02h.2c5.533 0 10.02-4.486 10.02-10.02-.001-5.534-4.487-10.02-10.02-10.02z" />
  </svg>
);
