import React from "react";
import classNames from "classnames";
import { Avatar, AvatarUser } from "../Avatar/Avatar";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { Separator } from "../Separator/Separator";
import { CloseIcon } from "../../theme/svg/CloseIcon";
import { CheckboxIcon } from "../../theme/svg/CheckboxIcon";
import styles from "./Approver.module.scss";

export interface ApproverProps {
  approver: AvatarUser;
  dueDate: string | null;
  decisionDate?: string | null;
  name: string;
  status?: string | null;
  sequenceNumber?: number;
  approved?: boolean;
  declined?: boolean;
  creatorComment?: string | null;
  approverComment?: string | null;
  className?: string;
}

export const Approver: React.FC<ApproverProps> = ({
  approver,
  dueDate,
  decisionDate,
  name,
  status,
  sequenceNumber,
  approved,
  declined,
  approverComment,
  creatorComment,
  className,
}) => {
  const renderStatusIcon = () => {
    if (approved) {
      return (
        <CheckmarkIcon
          className={classNames(
            styles["status-icon"],
            styles["status-icon--approved"],
          )}
        />
      );
    }
    if (declined) {
      return (
        <CloseIcon
          className={classNames(
            styles["status-icon"],
            styles["status-icon--declined"],
          )}
        />
      );
    }
    return <CheckboxIcon className={styles["status-icon"]} />;
  };

  return (
    <div className={classNames(styles.approver, className)}>
      {sequenceNumber && <div className={styles.side}>#{sequenceNumber}</div>}
      <div className={styles.container}>
        <div>
          <div className={classNames(styles.row, styles.gap)}>
            {name} <Avatar className={styles.avatar} user={approver} />
          </div>
          <div
            className={classNames(styles["text-color-secondary"], styles.gap)}
          >
            Due: {dueDate || "Not specified"}
          </div>
          {creatorComment && (
            <div className={styles["small-text"]}>
              Comment to approver: {creatorComment}
            </div>
          )}
        </div>
        <Separator className={classNames(styles.gap, styles.separator)} />
        <div className={classNames(styles.section, styles["small-text"])}>
          {decisionDate && (
            <div
              className={classNames(styles["text-color-secondary"], styles.gap)}
            >
              Decided on {decisionDate}
            </div>
          )}
          <div className={styles.gap}>Status: {status}</div>
          {approverComment && <div>Approver comment: {approverComment}</div>}
          {renderStatusIcon()}
        </div>
      </div>
    </div>
  );
};
