export interface GoogleAnalyticsCustomEventData {
  category: string;
  action: string;
  label: string | number;
  value?: string | number;
}

export function sendGoogleAnalyticsEvent(
  event: GoogleAnalyticsCustomEventData,
) {
  const gtmDataLayer = window.dataLayer;

  if (!gtmDataLayer) {
    return false;
  }

  const extendedEvent = { ...event, event: "GAEvent" };

  try {
    gtmDataLayer.push(extendedEvent);
  } catch (error) {
    return false;
  }

  return true;
}
