import React from "react";
import classNames from "classnames";
import styles from "./Chip.module.scss";

export interface ChipProps {
  id: string | number;
  children?: React.ReactNode;
  className?: string;
  onRemove?: (id: string | number) => void;
}

export const Chip: React.FC<ChipProps> = ({
  id,
  children,
  className,
  onRemove,
}) => {
  return (
    <div className={classNames(styles["wrap"], className)}>
      <div>{children}</div>
      {typeof onRemove === "function" && (
        <>
          <div className={styles["divider"]}>|</div>
          <div
            className={styles["remove-icon"]}
            onClick={() => onRemove(id)}
          ></div>
        </>
      )}
    </div>
  );
};
