import React from "react";
import { UAParser } from "ua-parser-js";
import { BrowserNotSupportedView } from "../../views/BrowserNotSupportedView/BrowserNotSupportedView";

export interface BrowserSupportCheckerProps {
  children: React.ReactElement;
}

const supportedBrowsers = {
  "Chrome": 83,
  "Firefox": 78,
  "Edge": 84,
  "Safari": 13,
  "Mobile Safari": 13,
  "Android Browser": 81,
};

export const BrowserSupportChecker: React.FC<BrowserSupportCheckerProps> = (
  props,
) => {
  const { children } = props;

  const ua = new UAParser();

  const browserData = ua.getBrowser();

  const isBrowserCompatible = !!Object.entries(supportedBrowsers).find(
    ([supportedBrowser, supportedMinVersion]) => {
      return (
        supportedBrowser === browserData.name &&
        supportedMinVersion <=
          (browserData.major ? parseInt(browserData.major) : Infinity)
      );
    },
  );

  if (!isBrowserCompatible) {
    return <BrowserNotSupportedView />;
  }

  return children;
};
