import React, { FormEvent, createContext } from "react";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export type FormProps = {
  error?: Error | string;
  onSubmit?(): void;
  className?: string;
  isEditable?: boolean;
};

export const EditableModeContext = createContext(true);

export const Form: React.FC<FormProps> = ({
  error,
  onSubmit,
  children,
  className,
  isEditable = true,
}) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent submitting the form
    event.preventDefault();

    // call the submit handler if available
    if (typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <form autoComplete="off" className={className} onSubmit={handleSubmit}>
      <ErrorMessage error={error} />
      <EditableModeContext.Provider value={isEditable}>
        {children}
      </EditableModeContext.Provider>
      {/* since the form footer with the submit button is rendered outside the form, submitting the form with enter */}
      {/* becomes impossible without this (requires a submit button inside form) */}
      <input type="submit" style={{ display: "none" }} />
    </form>
  );
};
