export function initializeGoogleTagManager(id?: string) {
  if (!id) {
    return;
  }

  ((w, d, s, l, i) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    w[l] = w[l] || [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    w[l].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });

    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s) as HTMLScriptElement;
    const dl = l !== "dataLayer" ? "&l=" + l : "";

    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;

    if (f.parentNode) {
      f.parentNode.insertBefore(j, f);
    }
  })(window, document, "script", "dataLayer", id);
}
