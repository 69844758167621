import React from "react";
import { createMachine, spawn, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { pick } from "ramda";
import { RfxTypeEnum, RfxSupplierResolutionEnum } from "../../schema";
import {
  createSupplierResolutionMachine,
  SupplierResolutionMachineEvent,
  SupplierResolutionEvents,
} from "./SupplierResolution.machine";

// state and events enums
export enum SuppliersState {
  INIT = "INIT",
  LOADED = "LOADED",
}

export enum SuppliersMachineEventsEnum {
  CHANGE_SELECTION = "CHANGE_SELECTION",
  UPDATE_CONTEXT = "UPDATE_CONTEXT",
}

// machine types
export interface SuppliersMachineContext {
  supplierResolutionActors: { [supplierId: string]: any };
  isReadOnly: boolean;
}

export type SuppliersMachineState = {
  value: keyof typeof SuppliersState;
  context: SuppliersMachineContext;
};

export type SuppliersMachineEvent =
  | {
      type: SuppliersMachineEventsEnum.CHANGE_SELECTION;
      supplierId: string;
      resolution: RfxSupplierResolutionEnum;
    }
  | {
      type: SuppliersMachineEventsEnum.UPDATE_CONTEXT;
      isReadOnly: boolean;
      supplierResolutions: {
        supplierId: string;
        resolution: RfxSupplierResolutionEnum | null;
      }[];
    };

export type SupplierMachineProps = Omit<
  SuppliersMachineContext,
  "supplierResolutionActors"
> & {
  supplierResolutions: {
    supplierId: string;
    resolution: RfxSupplierResolutionEnum | null;
  }[];
  saveResolution: () => Promise<any>;
  rfxId: string | null;
};

// function for creating a parameterized child machine for each supplier
const createSupplierResolutionMachines = ({
  isReadOnly = false,
  rfxType = RfxTypeEnum.RFI,
  supplierResolutions = [],
  saveResolution,
  rfxId,
}: {
  isReadOnly: boolean;
  rfxType: RfxTypeEnum;
  supplierResolutions: {
    supplierId: string;
    resolution: RfxSupplierResolutionEnum | null;
  }[];
  saveResolution: (props: any) => Promise<any>;
  rfxId: string | null;
}) =>
  supplierResolutions.map((supplierResolution) =>
    createSupplierResolutionMachine({
      context: {
        supplierId: supplierResolution.supplierId,
        isReadOnly,
        rfxType,
        selectedResolution: supplierResolution.resolution,
      },
      services: {
        saveResolution: (context, _event) => {
          return saveResolution({
            variables: {
              supplierId: supplierResolution.supplierId,
              resolution: context.selectedResolution,
              rfxId,
            },
            refetchQueries: ["RfxByCode"],
            awaitRefetchQueries: true,
          });
        },
      },
    }),
  );

// parent statechart parameterized creator
export function createSuppliersMachine({
  isReadOnly = false,
  supplierResolutions = [],
  saveResolution,
  rfxId,
}: SupplierMachineProps) {
  return createMachine<
    SuppliersMachineContext,
    SuppliersMachineEvent,
    SuppliersMachineState
  >({
    id: "suppliersMachine",
    initial: SuppliersState.INIT,
    context: { supplierResolutionActors: {}, isReadOnly },
    states: {
      [SuppliersState.INIT]: {
        always: {
          target: SuppliersState.LOADED,
          actions: createSupplierResolutionMachines({
            isReadOnly,
            rfxType: RfxTypeEnum.RFI,
            supplierResolutions,
            saveResolution,
            rfxId,
          }).map((machine) => {
            return assign({
              supplierResolutionActors: (context, _event) => ({
                ...context.supplierResolutionActors,
                ...{
                  [machine.context?.supplierId ?? ""]: spawn(
                    machine,
                    machine.context?.supplierId,
                  ),
                },
              }),
            });
          }),
        },
      },
      [SuppliersState.LOADED]: {
        entry: (context) => {
          // set not selected for PO children context to read only
          const [selectedForPO] = Object.entries(
            context.supplierResolutionActors,
          ).filter(
            ([_supplierId, actor]) =>
              actor.state.context.selectedResolution ===
              RfxSupplierResolutionEnum.NOMINATE_TO_PO,
          );

          if (selectedForPO && selectedForPO.length) {
            const otherChildren = pick(
              Object.keys(context.supplierResolutionActors).filter(
                (x) => x !== selectedForPO[0],
              ),
              context.supplierResolutionActors,
            );

            Object.values(otherChildren).forEach((o) =>
              o.send({ type: SupplierResolutionEvents.SET_READONLY }),
            );
          }
        },
        on: {
          CHANGE_SELECTION: {
            target: SuppliersState.LOADED,
            actions: (context, event) => {
              const changedChild =
                context.supplierResolutionActors[event.supplierId];
              const changedChildContext = changedChild.state.context;

              const otherChildren = pick(
                Object.keys(context.supplierResolutionActors).filter(
                  (x) => x !== event.supplierId,
                ),
                context.supplierResolutionActors,
              );

              if (
                changedChildContext.selectedResolution ===
                RfxSupplierResolutionEnum.NOMINATE_TO_PO
              ) {
                changedChild.send({ type: SupplierResolutionEvents.APPROVED });
                Object.values(otherChildren).forEach((o) =>
                  o.send({ type: SupplierResolutionEvents.CLEAR }),
                );
              }

              if (
                changedChildContext.selectedResolution !==
                RfxSupplierResolutionEnum.NOMINATE_TO_PO
              ) {
                changedChild.send({ type: SupplierResolutionEvents.APPROVED });
                Object.values(otherChildren).forEach((o) =>
                  o.send({ type: SupplierResolutionEvents.SET_EDITABLE }),
                );
              }
            },
          },
          UPDATE_CONTEXT: {
            target: SuppliersState.LOADED,
            actions: [
              assign({
                isReadOnly: (_context, event) => {
                  return event.isReadOnly;
                },
                supplierResolutionActors: (_context, event) => {
                  // create new machines first
                  const machines = createSupplierResolutionMachines({
                    isReadOnly: event.isReadOnly,
                    rfxType: RfxTypeEnum.RFI,
                    supplierResolutions: event.supplierResolutions,
                    saveResolution,
                    rfxId,
                  });
                  // then return the spawned machines to assign new actors
                  return machines.reduce((acc, machine) => {
                    return {
                      ...acc,
                      ...{
                        [machine.context?.supplierId ?? ""]: spawn(
                          machine,
                          machine.context?.supplierId,
                        ),
                      },
                    };
                  }, {});
                },
              }),
            ],
          },
        },
      },
    },
  });
}

// hook for React component usage
export function useSuppliersMachine(props: SupplierMachineProps) {
  // start parent machine service with initial props
  const [state, send] = useMachine(
    createSuppliersMachine({
      isReadOnly: props.isReadOnly,
      supplierResolutions: props.supplierResolutions,
      saveResolution: props.saveResolution,
      rfxId: props.rfxId,
    }),
  );

  // when machine props change, issue context update event
  React.useEffect(() => {
    send({
      type: SuppliersMachineEventsEnum.UPDATE_CONTEXT,
      isReadOnly: props.isReadOnly,
      supplierResolutions: props.supplierResolutions,
    });
  }, [props.isReadOnly, props.supplierResolutions, send]);

  // expose useful actions on children
  const getChildStateById = (supplierId: string) => {
    return state.context.supplierResolutionActors[supplierId]?.state;
  };

  const getChildResolutionById = (supplierId: string) => {
    return state.context.supplierResolutionActors[supplierId]?.state.context
      .selectedResolution;
  };

  const sendToChildById = (
    supplierId: string,
    event: SupplierResolutionMachineEvent,
  ) => {
    state.context.supplierResolutionActors[supplierId]?.send(event);
  };

  return {
    parentState: state,
    sendToParent: send,
    getChildStateById,
    getChildResolutionById,
    sendToChildById,
  };
}
