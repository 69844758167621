import React from "react";
import { AccordionSecondary } from "../../components/Accordion/AccordionSecondary";
import { ApprovalRequest } from "../../components/ApprovalRequest/ApprovalRequest";
import {
  ApprovalStatus,
  ApprovalStatusHeader,
} from "../../components/ApprovalStatus/ApprovalStatus";
import { RequestAttachments } from "../../components/RequestAttachments/RequestAttachments";
import { RequestDetails } from "../../components/RequestDetails/RequestDetails";
import { RequestedItems } from "../../components/RequestedItems/RequestedItems";
import { SectionSubtitle } from "../../components/SectionSubtitle/SectionSubtitle";
import { useActiveOrganization } from "../../hooks/useActiveOrganization";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  ViewerViewer,
  useViewerApprovalWorkflows,
  ApprovalWorkflowStatusEnum,
  ApprovalRequestDecisionEnum,
  useUpdateApprovalRequest,
  PurchaseRequestsDetailsByCodeAttachments,
} from "../../schema";
import { formatDatetime } from "../../services/formatDatetime";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { getWorkflowCardStatusLevel } from "../../services/getWorkflowCardStatusLevel";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";
import styles from "./ApprovalRequestsDetailView.module.scss";
import { mapApprovalWorkflowStatusCodeToStatus } from "./ApprovalRequestsView";

export interface ApprovalRequestsDetailViewProps {
  viewer: ViewerViewer;
}

export const ApprovalRequestsDetailView: React.FC<ApprovalRequestsDetailViewProps> = ({
  viewer,
}) => {
  // use router
  const { history } = useRouter();

  const activeOrganization = useActiveOrganization(viewer.organizations);

  // setup decision form
  const {
    loading: isUpdateApprovalRequestLoading,
    error: updateApprovalRequestError,
    useInput,
    submit: submitUpdateApprovalRequest,
  } = useForm({
    mutation: useUpdateApprovalRequest,
    options: {
      refetchQueries: ["ViewerApprovalWorkflows"],
      awaitRefetchQueries: true,
    },
  });

  // configure decision form inputs
  const commentInput = useInput({ name: "comment" });

  const {
    data: viewerAwfData,
    loading: isViewerAwfDataLoading,
    error: viewerAwfDataError,
  } = useViewerApprovalWorkflows({
    variables: {
      approvalWorkflowStatuses: [
        ApprovalWorkflowStatusEnum.IN_PROGRESS,
        ApprovalWorkflowStatusEnum.APPROVED,
        ApprovalWorkflowStatusEnum.DECLINED,
      ],
    },
  });

  if (!activeOrganization) {
    return <WithoutOrganizationView viewer={viewer} />;
  }

  // compound loading and error states
  const isLoading = isUpdateApprovalRequestLoading || isViewerAwfDataLoading;
  const error = updateApprovalRequestError || viewerAwfDataError;

  if (error !== undefined) {
    return <ErrorView error={error}></ErrorView>;
  }

  if (isLoading || !viewerAwfData) {
    return <LoadingView overlay></LoadingView>;
  }

  const approvalWorkflows = viewerAwfData.viewer?.approvalWorkflowsByStatus;

  const tokens = history.location.pathname.split("/");
  const locationApprovalWorkflowId = tokens.length >= 3 ? tokens[3] : undefined;

  const currentAwf = approvalWorkflows?.find(
    (awf) => awf.id === locationApprovalWorkflowId,
  );
  const currentAwfPurchaseRequest = currentAwf?.purchaseRequest;

  // filter out current viewer's approval request
  const currentAr = currentAwf?.approvalRequests.find(
    (ar) => ar.approver.id === viewer.id,
  );

  if (!currentAwf || !currentAr) {
    history.push(`/${activeOrganization.urlName}/approval-requests`);
    return null;
  }

  return (
    <>
      <ApprovalRequest
        purchaseRequestCreatedDate={formatTimeAgo(
          currentAwf.purchaseRequest?.createdDate,
        )}
        workflowUpdatedDate={formatTimeAgo(currentAwf.updatedDate)}
        secondary
        title={currentAwf.requestName}
        level={getWorkflowCardStatusLevel(currentAwf.status)}
        status={mapApprovalWorkflowStatusCodeToStatus(currentAwf.status)}
        statusCode={currentAwf.status}
        price={
          currentAwf.approvalAmount ? `${currentAwf.approvalAmount} EUR` : null
        }
        requester={`${currentAwf.requestor && currentAwf.requestor.firstName} ${
          currentAwf.requestor && currentAwf.requestor.lastName
        }`}
        dueDate={
          currentAr.dueDate ? formatDatetime(currentAr.dueDate) : undefined
        }
        code={currentAwf.code}
      />
      <div className={styles["request-sessions"]}>
        <div className={styles["request-section"]}>
          <AccordionSecondary
            title={
              <h3 className={styles["request-section-title"]}>
                Request details
              </h3>
            }
          >
            <RequestDetails
              name={currentAwf.requestName}
              notes={currentAwfPurchaseRequest?.notes}
              requestor={currentAwf.requestor}
              department={currentAwfPurchaseRequest?.department}
              project={currentAwfPurchaseRequest?.project}
              costCentre={currentAwfPurchaseRequest?.costCentre}
              attachments={
                currentAwfPurchaseRequest?.attachments as PurchaseRequestsDetailsByCodeAttachments[]
              }
            />
          </AccordionSecondary>
          <AccordionSecondary
            title={
              <h3 className={styles["request-section-title"]}>
                Requested items
              </h3>
            }
          >
            {currentAwf?.purchaseRequest && (
              <RequestedItems
                secondary
                items={currentAwf?.purchaseRequest?.items}
                totalTitle="Amount requested"
              />
            )}
          </AccordionSecondary>
        </div>

        <div className={styles["request-section"]}>
          <AccordionSecondary
            title={
              <h3 className={styles["request-section-title"]}>Approvals</h3>
            }
          >
            <SectionSubtitle
              title="Comment for approvers"
              value={currentAr.creatorComment}
            />
            <RequestAttachments
              attachments={currentAwfPurchaseRequest?.attachments}
            />
            <div className={styles["request-statuses"]}>
              {currentAwf.requestor && (
                <ApprovalStatusHeader
                  creator={currentAwf.requestor}
                  awfCode={currentAwf.code}
                  requestName={currentAwf.requestName}
                  prCode={currentAwf.purchaseRequest?.code}
                  status={`${currentAwf.approvalRequests.reduce(
                    (acc, value) => {
                      return value.decision ===
                        ApprovalRequestDecisionEnum.APPROVED
                        ? acc + 1
                        : acc;
                    },
                    0,
                  )}/${currentAwf.approvalRequests.length}`}
                />
              )}
              {currentAwf.approvalRequests
                .sort((a, b) => (a.sequenceNumber < b.sequenceNumber ? -1 : 1))
                .map((ar) => {
                  return (
                    <ApprovalStatus
                      key={ar.id}
                      approver={ar.approver}
                      dueDate={
                        ar.dueDate ? formatDatetime(ar.dueDate) : undefined
                      }
                      decisionDate={formatTimeAgo(ar.decisionDate)}
                      decision={ar.decision}
                      status={
                        ar.decision && ApprovalRequestDecisionEnum[ar.decision]
                      }
                      participantName={`${ar.approver.firstName} ${ar.approver.lastName}`}
                      enableDecisionForm={
                        !ar.decision && ar.approver.id === viewer.id
                      }
                      creatorComment={ar.creatorComment}
                      approverComment={ar.approverComment}
                      sequenceNumber={ar.sequenceNumber}
                      commentInput={commentInput}
                      approveAction={() => {
                        submitUpdateApprovalRequest({
                          approvalRequestId: ar.id,
                          decision: ApprovalRequestDecisionEnum.APPROVED,
                        });
                      }}
                      rejectAction={() => {
                        submitUpdateApprovalRequest({
                          approvalRequestId: ar.id,
                          decision: ApprovalRequestDecisionEnum.DECLINED,
                        });
                      }}
                    />
                  );
                })}
            </div>
          </AccordionSecondary>
        </div>
      </div>
    </>
  );
};
