import React from "react";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { useKeyboardEvent } from "../../hooks/useKeyboardEvent";
import { EditIcon } from "../../theme/svg/EditIcon";
import styles from "./RevealingInput.module.scss";
import { useRevealingInputMachine } from "./RevealingInput.machine";

export interface RevealingInputProps {
  className?: string;
  placeholder?: string;
  errors?: string[];
  onEditingFinished: () => Promise<any>;
  name?: string;
  defaultValue?: string | number;
  inputRef?: (ref: HTMLInputElement) => void;
}

export const RevealingInput: React.FC<RevealingInputProps> = (props) => {
  const {
    className,
    placeholder,
    errors,
    onEditingFinished,
    inputRef,
    name,
    defaultValue,
  } = props;

  // use refs
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLInputElement>(null);

  // component's statechart logic
  const {
    state,
    startEditing,
    insertValue,
    finishEditing,
    loadMachineState,
  } = useRevealingInputMachine({
    onEditingFinished,
  });

  React.useEffect(() => {
    // if the inputRef exists and innerRef has a value for input, assign it to form's inputRef
    if (inputRef && innerRef.current) {
      inputRef(innerRef.current);
    }

    // set the state machine up in SUCCESS state with the default value from props
    if (defaultValue) {
      loadMachineState(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, defaultValue]);

  // handle clicking outside of the component
  useOnClickOutside(wrapRef, finishEditing);

  // handle pressing ENTER in the input
  useKeyboardEvent({
    el: innerRef.current ?? undefined,
    eventType: "keydown",
    key: "Enter",
    handler: finishEditing,
  });

  return (
    <div ref={wrapRef} className={classNames(styles["wrap"], className)}>
      {/* text display mode element */}
      <div
        className={styles["text-display"]}
        data-state={state.value}
        onClick={startEditing}
      >
        <div className={styles["text-display__value"]}>
          {state.context.value ?? placeholder}
        </div>

        <div className={styles["text-display__icon"]}>
          <EditIcon />
        </div>
      </div>

      {/* input mode element */}
      <div className={styles["input-display"]}>
        <input
          ref={innerRef}
          name={name}
          defaultValue={defaultValue}
          className={styles["input-field"]}
          data-state={state.value}
          type="text"
          autoFocus
          onChange={(e) => {
            startEditing();
            insertValue(e.currentTarget.value);
          }}
          onBlur={finishEditing}
        />
      </div>

      {/* external errors */}
      {errors &&
        errors.map((error, idx) => (
          <div
            key={idx}
            className={styles["input-error"]}
            data-state={state.value}
          >
            {error}
          </div>
        ))}

      {/* internal errors */}
      {state.context.errors &&
        state.context.errors.map((error, idx) => (
          <div
            key={idx}
            className={styles["input-error"]}
            data-state={state.value}
          >
            {error}
          </div>
        ))}
    </div>
  );
};
