import classNames from "classnames";
import React from "react";
import {
  Dropdown,
  DropdownContent,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { FilterDropdownsOpen } from "../../views/MemberView/MemberView";
import { alertNotImplemented } from "../../services/alertNotImplemented";
import { Button } from "../Button/Button";
import styles from "./MenuFilter.module.scss";

export interface MenuFilterProps {
  right?: boolean;
  title?: string;
  label?: string;
  className?: string;
  filterDropdownsOpen: FilterDropdownsOpen;
  setFilterDropdownsOpen: React.Dispatch<
    React.SetStateAction<FilterDropdownsOpen>
  >;
}

export const MenuFilter: React.FC<MenuFilterProps> = ({
  children,
  right,
  title,
  label,
  className,
  filterDropdownsOpen,
  setFilterDropdownsOpen,
}) => {
  return (
    <Dropdown
      data-testid="43324d54c7"
      onCloseRequested={() => {
        setFilterDropdownsOpen(
          Object.assign({}, filterDropdownsOpen, {
            assignee: false,
            project: false,
            supplier: false,
            department: false,
          }),
        );
      }}
      className={classNames(styles["menu-filter"], className)}
      isFilter
    >
      {label && <p className={styles.label}>{label}</p>}
      <DropdownSelector
        onClick={() => {
          switch (label) {
            case undefined:
              setFilterDropdownsOpen(
                Object.assign({}, filterDropdownsOpen, {
                  assignee: !filterDropdownsOpen.assignee,
                  project: false,
                  supplier: false,
                  department: false,
                  dateRange: false,
                }),
              );
              break;
            case "Projects":
              setFilterDropdownsOpen(
                Object.assign({}, filterDropdownsOpen, {
                  project: !filterDropdownsOpen.project,
                  assignee: false,
                  supplier: false,
                  department: false,
                  dateRange: false,
                }),
              );
              break;
            case "Suppliers":
              setFilterDropdownsOpen(
                Object.assign({}, filterDropdownsOpen, {
                  supplier: !filterDropdownsOpen.supplier,
                  assignee: false,
                  project: false,
                  department: false,
                  dateRange: false,
                }),
              );
              break;
            case "Units":
              setFilterDropdownsOpen(
                Object.assign({}, filterDropdownsOpen, {
                  department: !filterDropdownsOpen.department,
                  assignee: false,
                  project: false,
                  supplier: false,
                  dateRange: false,
                }),
              );
              break;
            case "Labels (Status)":
              alertNotImplemented();
              break;
          }
        }}
        className={styles["filter__selector"]}
      >
        {title}
      </DropdownSelector>
      <DropdownContent
        right={right}
        open={
          label === undefined
            ? filterDropdownsOpen.assignee
            : label === "Projects"
            ? filterDropdownsOpen.project
            : label === "Suppliers"
            ? filterDropdownsOpen.supplier
            : label === "Units"
            ? filterDropdownsOpen.department
            : false
        }
        isFilter
        onCloseRequested={() => {
          setFilterDropdownsOpen(
            Object.assign({}, filterDropdownsOpen, {
              assignee: false,
              project: false,
              supplier: false,
              department: false,
            }),
          );
        }}
      >
        {children}
      </DropdownContent>
    </Dropdown>
  );
};

export interface FilterBlockButtonProps {
  onClick?(): void;
  addon?: React.ReactNode;
  isOpen?: boolean;
  isFilterActive?: boolean;
}

export const FilterBlockButton: React.FC<FilterBlockButtonProps> = ({
  onClick,
  children,
  addon,
  isOpen,
  isFilterActive,
}) => (
  <Button
    data-testid="796be27393"
    quentary
    icon={addon}
    onClick={onClick}
    className={classNames({
      [styles["filter-button--open"]]: isOpen,
      [styles["filter-button--filter-active"]]: isFilterActive,
    })}
  >
    {children}
  </Button>
);
