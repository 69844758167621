import * as React from "react";
export const QuestionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg version="1.1" viewBox="0 0 19.2 19.2" {...props}>
    <path
      d="M9.6,3.6c-2.2,0-4,1.8-4,4h2c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.8-0.5,1.4-1.2,1.7L10,9.4c-0.8,0.3-1.4,1.1-1.4,1.9v1.3h2v-1.3
	l0.4-0.1c1.5-0.5,2.6-2,2.6-3.6C13.6,5.4,11.8,3.6,9.6,3.6z"
    />
    <rect x="8.6" y="13.6" width="2" height="2" />
  </svg>
);
