import React from "react";
import classNames from "classnames";
import styles from "./Unit.module.scss";

export interface UnitProps {
  small?: boolean;
  className?: string;
}

export const Unit: React.FC<UnitProps> = ({ children, small, className }) => {
  return (
    <div
      className={classNames(
        styles.unit,
        { [styles["unit--small"]]: small },
        className,
      )}
    >
      {children}
    </div>
  );
};
