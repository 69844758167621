import React from "react";
import classNames from "classnames";
import styles from "./Label.module.scss";

export enum LabelColor {
  COMMENT = "#3e9ddf",
  RED = "RED",
  GREEN = "GREEN",
  CYAN = "CYAN",
  YELLOW = "#f6b913",
  PURPLE = "PURPLE",
}

export interface LabelInfo {
  color: LabelColor;
  text: string;
}

export interface LabelProps extends LabelInfo {
  className?: string;
}

export const Label: React.FC<LabelProps> = ({ color, text, className }) => {
  return (
    <div
      title={text}
      className={classNames(styles.label, className)}
      style={{ background: color }}
    />
  );
};
