import React from "react";
import { Redirect } from "react-router";
import { Field } from "../../components/Field/Field";
import { View } from "../../components/View/View";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { useLoginWithToken, useSendLoginToken } from "../../schema";
import { isEmail } from "../../validators/isEmail";
import { Form } from "../../components/Form/Form";
import { Button } from "../../components/Button/Button";
import { LogoIcon } from "../../theme/svg/LogoIcon";
import { LoginIllustration } from "../../theme/svg/LoginIllustration";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingView } from "../LoadingView/LoadingView";
import { useLogin } from "../../hooks/useLogin";
import styles from "./LoginView.module.scss";

interface Params {
  token?: string;
}

export const LoginView: React.FC = () => {
  // extract token from params if exists
  const { match } = useRouter<Params>();
  const { token } = match.params;

  // attempt to login user using token if available
  const {
    loading: isLoginByTokenLoading,
    success: loginSuccess,
    error: loginError,
    reset: resetLogin,
  } = useLogin({
    token,
    mutationFunction: useLoginWithToken,
  });

  // configure form and inputs
  const {
    useInput,
    submit,
    reset,
    loading: isLoginFormLoading,
    error,
    success,
  } = useForm({
    mutation: useSendLoginToken,
  });
  const emailInput = useInput({ name: "email", validators: [isEmail] });

  if (isLoginByTokenLoading) {
    return <LoadingView />;
  } else if (loginSuccess) {
    return <Redirect to="/" />;
  }

  return (
    <View className={styles.view}>
      <div className={styles.section}>
        <div className={styles.logo__wrapper}>
          <LogoIcon width="48px" height="48px" className={styles.logo} />
          <span>
            <strong>ProcurementFlow</strong>
          </span>
        </div>

        <h1>Log In</h1>
        <div className={styles.login__form__wrap}>
          {loginError ? (
            <ErrorMessage error="Session has expired, please enter the e-mail to get new invite" />
          ) : null}
          {success ? (
            <div className={styles.login__success_wrapper}>
              <p>
                <strong>Login link was sent to your email!</strong>
              </p>
              <p>
                Open your email client and login with the magic link that we
                sent you.
              </p>
              <a
                data-testid="c3c356f71f"
                href="#reset"
                onClick={(e) => {
                  e.preventDefault();
                  reset();
                }}
                className={styles.reset__form__link}
              >
                Log in as another user
              </a>
            </div>
          ) : (
            <Form
              onSubmit={() => {
                resetLogin();
                submit();
              }}
              error={error}
              className={styles.form}
            >
              <Field
                {...emailInput}
                autofocus
                label="Email Address"
                placeholder="Enter your email address"
                className={styles.input}
              />
              <Button
                data-testid="356ac4698e"
                block
                large
                type="submit"
                loading={isLoginFormLoading}
                disabled={isLoginFormLoading}
              >
                Log In
              </Button>
            </Form>
          )}
        </div>
      </div>
      <div className={styles.illustration__wrapper}>
        <LoginIllustration className={styles.illustration} />
      </div>
    </View>
  );
};
