import React from "react";
import { Switch, Route } from "react-router-dom";
import { PortalLayout } from "../../components/PortalLayout/PortalLayout";
import { useActiveOrganization } from "../../hooks/useActiveOrganization";
import {
  ViewerViewer,
  useApprovalPortalView,
  ApprovalWorkflowStatusEnum,
  UserRoleEnum,
  PurchaseRequestStatusEnum,
} from "../../schema";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";
import { ApprovalRequestsDetailView } from "./ApprovalRequestsDetailView";
import { ApprovalRequestsView } from "./ApprovalRequestsView";

interface RequesterPortalViewProps {
  viewer: ViewerViewer;
}

export const ApprovalPortalView: React.FC<RequesterPortalViewProps> = ({
  viewer,
}) => {
  const organization = useActiveOrganization(viewer.organizations);

  const { data, loading, error } = useApprovalPortalView({
    variables: {
      approvalWorkflowStatuses: [
        ApprovalWorkflowStatusEnum.IN_PROGRESS,
        ApprovalWorkflowStatusEnum.APPROVED,
        ApprovalWorkflowStatusEnum.DECLINED,
      ],
      organizationId: organization!.id,
      filter: {
        userId: viewer?.id || null,
        projectId: null,
        supplierId: null,
        departmentId: null,
        dateRange: null,
        status: null,
      },
    },
  });

  if (error !== undefined) {
    return <ErrorView error={error}></ErrorView>;
  }

  if (loading || !data?.viewer) {
    return <LoadingView overlay></LoadingView>;
  }

  if (!organization) {
    return <WithoutOrganizationView viewer={viewer} />;
  }

  const hasKanbanAccess = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER, UserRoleEnum.BUYER].includes(
      role,
    ),
  );

  const approvalWorkflows = data.viewer?.approvalWorkflowsByStatus;
  const purchaseRequests = data.viewer?.organization.purchaseRequests;

  const inProgressApprovalsRequests: number =
    approvalWorkflows?.filter(
      (i) => i.status === ApprovalWorkflowStatusEnum.IN_PROGRESS,
    ).length || 0;
  const inProgressPurchaseRequests: number =
    purchaseRequests?.filter(
      (i) =>
        i.status === PurchaseRequestStatusEnum.READY &&
        !i.latestApprovalWorkflow,
    ).length || 0;

  return (
    <PortalLayout
      hasKanbanAccess={hasKanbanAccess}
      inProgressApprovalsRequests={inProgressApprovalsRequests}
      inProgressPurchaseRequests={inProgressPurchaseRequests}
      organization={organization}
      viewer={viewer}
    >
      <Switch>
        <Route
          exact
          path={`/:activeOrganization/approval-requests/:approvalRequestId`}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ApprovalRequestsDetailView viewer={viewer} />
        </Route>
        <Route exact path={`/:activeOrganization/approval-requests`}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ApprovalRequestsView viewer={viewer} />
        </Route>
      </Switch>
    </PortalLayout>
  );
};
