import * as React from "react";
export const CheckboxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 26" {...props}>
    <rect
      stroke="#4A90E2"
      strokeWidth={3}
      x={1.5}
      y={1.5}
      width={23}
      height={23}
      rx={3}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);
