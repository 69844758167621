import React from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import styles from "./QuestionInput.module.scss";

export interface QuestionInputProps {
  name: string;
  isEditable?: boolean;
  questionText: string;
  answerValue?: string;
}

export const QuestionInput: React.FC<QuestionInputProps> = ({
  name,
  isEditable,
  questionText,
  answerValue,
}) => {
  const { register, setValue } = useFormContext();

  const fieldId = `${name}-field`;

  React.useEffect(() => {
    setValue(name, answerValue);
  }, [name, answerValue, setValue]);

  return (
    <div className={styles.wrap}>
      <div className={styles.body}>
        {/* editable state */}
        <div className={styles["field-container"]}>
          <label className={classNames(styles["label"])} htmlFor={fieldId}>
            {questionText}
          </label>
          <div className={styles["textarea-grow-wrap"]}>
            <textarea
              rows={3}
              id={fieldId}
              ref={register()}
              name={name}
              className={styles["field"]}
              readOnly={!isEditable}
            />
          </div>

          <ErrorMessage
            name={name}
            render={({ message }) => (
              <p className={styles["error-message"]}>{message}</p>
            )}
          />
        </div>
      </div>
    </div>
  );
};
