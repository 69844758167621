import React from "react";
import { Modal } from "../../components/Modal/Modal";
import styles from "./ErrorView.module.scss";

export interface ErrorViewProps {
  error: Error | string;
  title?: string;
  modal?: ErrorViewModalProps;
}

export interface ErrorViewModalProps {
  title: string;
  onModalClose(): any;
}

export const ErrorView: React.FC<ErrorViewProps> = ({
  error,
  title,
  modal,
}) => {
  const isDevelopmentMode = process.env.NODE_ENV === "development";
  const message =
    error instanceof Error
      ? isDevelopmentMode
        ? error.message
        : "System error occured, we're working on the issue. Please try again later"
      : error;

  const modalErrorView = (
    <div className={styles["error-view"]}>
      <h1 className={styles.title}>{title || "Oops, something went wrong!"}</h1>
      <div className={styles.message}>{message}</div>
    </div>
  );

  if (modal) {
    return (
      <Modal title={modal.title} onCloseRequested={modal.onModalClose}>
        {modalErrorView}
      </Modal>
    );
  }

  return <>{modalErrorView}</>;
};
