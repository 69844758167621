import React from "react";
import styles from "./Debug.module.scss";

export interface DebugProps {
  title?: string;
}

export const Debug: React.FC<DebugProps> = ({ title, children }) => (
  <div className={styles.debug}>
    {title ? <div className={styles.title}>{title}</div> : null}
    <div className={styles.content}>
      {JSON.stringify(
        children,
        (_key, value) => (value === undefined ? "[undefined]" : value),
        "  ",
      )}
    </div>
  </div>
);
