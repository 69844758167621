import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";

// all possible state and events enums
export enum RevealingInputStateEnum {
  INIT = "INIT",
  EDITING = "EDITING",
  TRANSITIONING = "TRANSITIONING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum RevealingInputEventEnum {
  LOAD_STATE = "LOAD_STATE",
  START_EDITING = "START_EDITING",
  TYPING = "TYPING",
  FINISH_EDITING = "FINISH_EDITING",
}

// type definitions for use with the createMachine
export type RevealingInputMachineContext = {
  value: string | number | null;
  errors: string[];
};

export type RevealingInputMachineState = {
  value: keyof typeof RevealingInputStateEnum;
  context: RevealingInputMachineContext;
};

export type RevealingInputMachineEvent =
  | {
      type: RevealingInputEventEnum.LOAD_STATE;
      stateToLoad: RevealingInputStateEnum;
      value: string | number | null;
    }
  | { type: RevealingInputEventEnum.START_EDITING }
  | { type: RevealingInputEventEnum.TYPING; value: string | null }
  | { type: RevealingInputEventEnum.FINISH_EDITING };

// input parameters for the machine creating factory
export type RevealingInputMachineProps = {
  onEditingFinished: () => Promise<any>;
};

// guards
const inputHasValue = (context: RevealingInputMachineContext) =>
  !!context.value;

// create parameterized statechart
export function createRevealingInputMachine({
  onEditingFinished,
}: RevealingInputMachineProps) {
  return createMachine<
    RevealingInputMachineContext,
    RevealingInputMachineEvent,
    RevealingInputMachineState
  >({
    id: "RevealingInputMachine",
    initial: RevealingInputStateEnum.INIT,
    states: {
      [RevealingInputStateEnum.INIT]: {
        on: {
          START_EDITING: { target: RevealingInputStateEnum.EDITING },
        },
      },
      [RevealingInputStateEnum.EDITING]: {
        on: {
          TYPING: {
            target: RevealingInputStateEnum.EDITING,
            internal: true,
            actions: assign({
              value: (_context, event) =>
                event.value && event.value.length > 0 ? event.value : null,
            }),
          },
          FINISH_EDITING: [
            {
              target: RevealingInputStateEnum.TRANSITIONING,
              cond: inputHasValue,
            },
            { target: RevealingInputStateEnum.INIT },
          ],
        },
      },
      [RevealingInputStateEnum.TRANSITIONING]: {
        invoke: {
          id: "onEditingFinished",
          src: (_context, _event) => onEditingFinished(),
          onDone: {
            target: RevealingInputStateEnum.SUCCESS,
          },
          onError: {
            target: RevealingInputStateEnum.FAILURE,
            actions: assign({
              errors: (_context, event) => {
                return [event.data];
              },
            }),
          },
        },
      },
      [RevealingInputStateEnum.SUCCESS]: {
        on: {
          START_EDITING: { target: RevealingInputStateEnum.EDITING },
        },
      },
      [RevealingInputStateEnum.FAILURE]: {
        on: {
          START_EDITING: {
            target: RevealingInputStateEnum.EDITING,
            actions: assign({ errors: (_context, _event) => [] }),
          },
          FINISH_EDITING: { target: RevealingInputStateEnum.TRANSITIONING },
        },
      },
    },
    on: {
      LOAD_STATE: [
        {
          target: RevealingInputStateEnum.INIT,
          internal: true,
          cond: (_context, event) => {
            return event.stateToLoad === RevealingInputStateEnum.INIT;
          },
          actions: assign((_context, _event) => ({ errors: [], value: null })),
        },
        {
          target: RevealingInputStateEnum.SUCCESS,
          internal: true,
          cond: (_context, event) => {
            return event.stateToLoad === RevealingInputStateEnum.SUCCESS;
          },
          actions: assign((_context, event) => ({
            errors: [],
            value: event.value,
          })),
        },
      ],
    },
  });
}

// hook for convenient usage in React component
export function useRevealingInputMachine({
  onEditingFinished,
}: RevealingInputMachineProps) {
  const machine = createRevealingInputMachine({
    onEditingFinished,
  });

  const [state, send] = useMachine(machine);

  // load machine state
  const loadMachineState = (value: string | number | null) => {
    send({
      type: RevealingInputEventEnum.LOAD_STATE,
      stateToLoad: RevealingInputStateEnum.SUCCESS,
      value,
    });
  };

  // reset
  const resetField = () => {
    send({
      type: RevealingInputEventEnum.LOAD_STATE,
      stateToLoad: RevealingInputStateEnum.INIT,
      value: null,
    });
  };

  // start editing
  const startEditing = () => {
    send({ type: RevealingInputEventEnum.START_EDITING });
  };

  // insert a value
  const insertValue = (value: string | null) => {
    send({ type: RevealingInputEventEnum.TYPING, value });
  };

  // finish editing
  const finishEditing = () => {
    send({ type: RevealingInputEventEnum.FINISH_EDITING });
  };

  return {
    state,
    send,
    loadMachineState,
    resetField,
    startEditing,
    insertValue,
    finishEditing,
  };
}
