import React, { useState } from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import {
  OrderByCodeCountries,
  ViewerOrganizations,
  useCreateOrganizationUser,
  UserRoleEnum,
} from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { notEmpty } from "../../validators/notEmpty";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { FieldError } from "../../components/FieldError/FieldError";
import { Unit } from "../../components/Unit/Unit";

export interface CreateUserProps {
  countries: OrderByCodeCountries[];
  activeOrganization: ViewerOrganizations;
  organizationId: string;
  onModalClose(): void;
}

type RoleMap = { [role in keyof typeof UserRoleEnum]: boolean };

export const CreateUser: React.FC<CreateUserProps> = ({
  countries,
  activeOrganization,
  onModalClose,
}) => {
  const [userRolesError, setUserRolesError] = useState<string>();

  // setup form
  const {
    useInput,
    useSelect,
    useCheckbox,
    loading,
    error,
    submit: createUser,
  } = useForm({
    mutation: useCreateOrganizationUser,
    options: {
      refetchQueries: ["Viewer"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
    onError: (e) => {
      const roleErrors = e.graphQLErrors.filter(
        (err) => err?.extensions?.errors["roles"],
      );

      if (roleErrors.length > 0) {
        setUserRolesError("At least 1 role has to be selected");
      }
    },
  });

  // configure inputs
  const firstNameInput = useInput({
    name: "firstName",
    validators: [notEmpty],
  });
  const lastNameInput = useInput({ name: "lastName", validators: [notEmpty] });
  const emailInput = useInput({ name: "email", validators: [notEmpty] });
  const requesterRoleCheckbox = useCheckbox({ name: "role-requester" });
  const approverRoleCheckbox = useCheckbox({ name: "role-approver" });
  const buyerRoleCheckbox = useCheckbox({ name: "role-buyer" });
  const keyUserRoleCheckbox = useCheckbox({ name: "role-key-user" });
  const phoneNumberInput = useInput({ name: "phoneNumber", optional: true });
  const countryCodeInput = useSelect({
    name: "countryCode",
    options: countries
      ? [{ label: "-", value: "" }].concat(
          countries.map((country) => ({
            label: country.name,
            value: country.code,
          })),
        )
      : [],
    optional: true,
    defaultValue: "",
  });

  const submit = () => {
    const selectedRolesMap: RoleMap = {
      [UserRoleEnum.REQUESTER]: requesterRoleCheckbox.value,
      [UserRoleEnum.APPROVER]: approverRoleCheckbox.value,
      [UserRoleEnum.BUYER]: buyerRoleCheckbox.value,
      [UserRoleEnum.KEY_USER]: keyUserRoleCheckbox.value,
      [UserRoleEnum.SUPPLIER]: false,
      [UserRoleEnum.ADMIN]: false,
    };

    const roleNames = Object.keys(selectedRolesMap) as UserRoleEnum[];
    const selectedRoles = roleNames.reduce<UserRoleEnum[]>((result, role) => {
      const hasRole = selectedRolesMap[role];

      if (!hasRole) {
        return result;
      }

      return [...result, role];
    }, []);

    createUser({
      organizationId: activeOrganization.id,
      roles: selectedRoles,
    });
  };

  return (
    <Modal
      title="Add new user"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="ce96879dab" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="74ec0e6b8a"
            loading={loading}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={submit}>
        <Field {...firstNameInput} label="First name" short />
        <Field {...lastNameInput} label="Last name" short />
        <Field {...emailInput} label="Email" short />
        <FieldLabel label="Roles" />
        <Field {...requesterRoleCheckbox} checkbox>
          Requester
        </Field>
        <Field {...approverRoleCheckbox} checkbox>
          Approver
        </Field>
        <Field {...buyerRoleCheckbox} checkbox>
          Buyer
        </Field>
        <Field {...keyUserRoleCheckbox} checkbox>
          Key-user
        </Field>
        {userRolesError && (
          <Unit>
            <FieldError error={userRolesError} />
          </Unit>
        )}
        <Field {...phoneNumberInput} label="Phone number" short />
        <Select {...countryCodeInput} label="Country" short />
      </Form>
    </Modal>
  );
};
