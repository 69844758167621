import React from "react";
import classNames from "classnames";
import { Header } from "../../components/Header/Header";
import styles from "./BrowserNotSupportedView.module.scss";

export interface BrowserNotSupportedViewProps {
  className?: string;
}

export const BrowserNotSupportedView: React.FC<BrowserNotSupportedViewProps> = (
  props,
) => {
  const { className } = props;

  return (
    <div className={classNames(styles["wrap"], className)}>
      <Header></Header>
      <div className={styles.content}>
        <p>
          We are sorry to inform you that we only support the following
          browsers:
        </p>
        <p>
          Chrome &gt;= 83, Firefox &gt;= 78, Edge &gt;= 84, Safari (also mobile)
          &gt;= 13, Android Browser &gt;= 81
        </p>
        <div className={styles["action"]}>
          Please use a browser from this list or update your current browser and
          we are glad to see you here again!
        </div>
      </div>
    </div>
  );
};
