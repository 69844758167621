// colors
export const LANE_COLOR_PR_HEADER = "#d1d1d5";
export const LANE_COLOR_PR_BODY = "#e9eaee";

export const LANE_COLOR_RFX_HEADER = "#fee8ad";
export const LANE_COLOR_RFX_BODY = "#feeec2";

export const LANE_COLOR_ORDER_HEADER = "#fee8ad";
export const LANE_COLOR_ORDER_BODY = "#feeec2";

export const LANE_COLOR_RECEIVING_HEADER = "#b3e1f5";
export const LANE_COLOR_RECEIVING_BODY = "#ccebf8";

export const LANE_COLOR_INVOICES_HEADER = "#bee5b2";
export const LANE_COLOR_INVOICES_BODY = "#cfebc5";

// rules
export const CARD_ITEMS_VISIBLE_COUNT = 3;

export interface Currency {
  symbol: string;
  name: string;
}

// list of swimlanes
export enum SwimlaneId {
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  RFX = "RFX",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  RECEIVING = "RECEIVING",
  INVOICE = "INVOICE",
}

// list of supported currencies
export const currencies = [
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
  },
  {
    symbol: "AFN",
    name: "Afghan Afghani",
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
  },
  {
    symbol: "AMD",
    name: "Armenian Dram",
  },
  {
    symbol: "ANG",
    name: "Netherlands Antillean Guilder",
  },
  {
    symbol: "AOA",
    name: "Angolan Kwanza",
  },
  {
    symbol: "ARS",
    name: "Argentine Peso",
  },
  {
    symbol: "AUD",
    name: "Australian Dollar",
  },
  {
    symbol: "AWG",
    name: "Aruban Florin",
  },
  {
    symbol: "AZN",
    name: "Azerbaijani Manat",
  },
  {
    symbol: "BAM",
    name: "Bosnia-Herzegovina Convertible Mark",
  },
  {
    symbol: "BBD",
    name: "Barbadian Dollar",
  },
  {
    symbol: "BDT",
    name: "Bangladeshi Taka",
  },
  {
    symbol: "BGN",
    name: "Bulgarian Lev",
  },
  {
    symbol: "BHD",
    name: "Bahraini Dinar",
  },
  {
    symbol: "BIF",
    name: "Burundian Franc",
  },
  {
    symbol: "BMD",
    name: "Bermudan Dollar",
  },
  {
    symbol: "BND",
    name: "Brunei Dollar",
  },
  {
    symbol: "BOB",
    name: "Bolivian Boliviano",
  },
  {
    symbol: "BRL",
    name: "Brazilian Real",
  },
  {
    symbol: "BSD",
    name: "Bahamian Dollar",
  },
  {
    symbol: "BTN",
    name: "Bhutanese Ngultrum",
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
  },
  {
    symbol: "BYN",
    name: "Belarusian Ruble",
  },
  {
    symbol: "BZD",
    name: "Belize Dollar",
  },
  {
    symbol: "CAD",
    name: "Canadian Dollar",
  },
  {
    symbol: "CDF",
    name: "Congolese Franc",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
  },
  {
    symbol: "CLF",
    name: "Chilean Unit of Account (UF)",
  },
  {
    symbol: "CLP",
    name: "Chilean Peso",
  },
  {
    symbol: "CNH",
    name: "Chinese Yuan (Offshore)",
  },
  {
    symbol: "CNY",
    name: "Chinese Yuan",
  },
  {
    symbol: "COP",
    name: "Colombian Peso",
  },
  {
    symbol: "CRC",
    name: "Costa Rican Colón",
  },
  {
    symbol: "CUC",
    name: "Cuban Convertible Peso",
  },
  {
    symbol: "CUP",
    name: "Cuban Peso",
  },
  {
    symbol: "CVE",
    name: "Cape Verdean Escudo",
  },
  {
    symbol: "CZK",
    name: "Czech Republic Koruna",
  },
  {
    symbol: "DJF",
    name: "Djiboutian Franc",
  },
  {
    symbol: "DKK",
    name: "Danish Krone",
  },
  {
    symbol: "DOP",
    name: "Dominican Peso",
  },
  {
    symbol: "DZD",
    name: "Algerian Dinar",
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
  },
  {
    symbol: "ERN",
    name: "Eritrean Nakfa",
  },
  {
    symbol: "ETB",
    name: "Ethiopian Birr",
  },
  {
    symbol: "EUR",
    name: "Euro",
  },
  {
    symbol: "FJD",
    name: "Fijian Dollar",
  },
  {
    symbol: "FKP",
    name: "Falkland Islands Pound",
  },
  {
    symbol: "GBP",
    name: "British Pound Sterling",
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
  },
  {
    symbol: "GGP",
    name: "Guernsey Pound",
  },
  {
    symbol: "GHS",
    name: "Ghanaian Cedi",
  },
  {
    symbol: "GIP",
    name: "Gibraltar Pound",
  },
  {
    symbol: "GMD",
    name: "Gambian Dalasi",
  },
  {
    symbol: "GNF",
    name: "Guinean Franc",
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
  },
  {
    symbol: "GYD",
    name: "Guyanaese Dollar",
  },
  {
    symbol: "HKD",
    name: "Hong Kong Dollar",
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
  },
  {
    symbol: "HRK",
    name: "Croatian Kuna",
  },
  {
    symbol: "HTG",
    name: "Haitian Gourde",
  },
  {
    symbol: "HUF",
    name: "Hungarian Forint",
  },
  {
    symbol: "IDR",
    name: "Indonesian Rupiah",
  },
  {
    symbol: "ILS",
    name: "Israeli New Sheqel",
  },
  {
    symbol: "IMP",
    name: "Manx pound",
  },
  {
    symbol: "INR",
    name: "Indian Rupee",
  },
  {
    symbol: "IQD",
    name: "Iraqi Dinar",
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
  },
  {
    symbol: "ISK",
    name: "Icelandic Króna",
  },
  {
    symbol: "JEP",
    name: "Jersey Pound",
  },
  {
    symbol: "JMD",
    name: "Jamaican Dollar",
  },
  {
    symbol: "JOD",
    name: "Jordanian Dinar",
  },
  {
    symbol: "JPY",
    name: "Japanese Yen",
  },
  {
    symbol: "KES",
    name: "Kenyan Shilling",
  },
  {
    symbol: "KGS",
    name: "Kyrgystani Som",
  },
  {
    symbol: "KHR",
    name: "Cambodian Riel",
  },
  {
    symbol: "KMF",
    name: "Comorian Franc",
  },
  {
    symbol: "KPW",
    name: "North Korean Won",
  },
  {
    symbol: "KRW",
    name: "South Korean Won",
  },
  {
    symbol: "KWD",
    name: "Kuwaiti Dinar",
  },
  {
    symbol: "KYD",
    name: "Cayman Islands Dollar",
  },
  {
    symbol: "KZT",
    name: "Kazakhstani Tenge",
  },
  {
    symbol: "LAK",
    name: "Laotian Kip",
  },
  {
    symbol: "LBP",
    name: "Lebanese Pound",
  },
  {
    symbol: "LKR",
    name: "Sri Lankan Rupee",
  },
  {
    symbol: "LRD",
    name: "Liberian Dollar",
  },
  {
    symbol: "LSL",
    name: "Lesotho Loti",
  },
  {
    symbol: "LYD",
    name: "Libyan Dinar",
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
  },
  {
    symbol: "MGA",
    name: "Malagasy Ariary",
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
  },
  {
    symbol: "MNT",
    name: "Mongolian Tugrik",
  },
  {
    symbol: "MOP",
    name: "Macanese Pataca",
  },
  {
    symbol: "MRO",
    name: "Mauritanian Ouguiya (pre-2018)",
  },
  {
    symbol: "MRU",
    name: "Mauritanian Ouguiya",
  },
  {
    symbol: "MUR",
    name: "Mauritian Rupee",
  },
  {
    symbol: "MVR",
    name: "Maldivian Rufiyaa",
  },
  {
    symbol: "MWK",
    name: "Malawian Kwacha",
  },
  {
    symbol: "MXN",
    name: "Mexican Peso",
  },
  {
    symbol: "MYR",
    name: "Malaysian Ringgit",
  },
  {
    symbol: "MZN",
    name: "Mozambican Metical",
  },
  {
    symbol: "NAD",
    name: "Namibian Dollar",
  },
  {
    symbol: "NGN",
    name: "Nigerian Naira",
  },
  {
    symbol: "NIO",
    name: "Nicaraguan Córdoba",
  },
  {
    symbol: "NOK",
    name: "Norwegian Krone",
  },
  {
    symbol: "NPR",
    name: "Nepalese Rupee",
  },
  {
    symbol: "NZD",
    name: "New Zealand Dollar",
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
  },
  {
    symbol: "PAB",
    name: "Panamanian Balboa",
  },
  {
    symbol: "PEN",
    name: "Peruvian Nuevo Sol",
  },
  {
    symbol: "PGK",
    name: "Papua New Guinean Kina",
  },
  {
    symbol: "PHP",
    name: "Philippine Peso",
  },
  {
    symbol: "PKR",
    name: "Pakistani Rupee",
  },
  {
    symbol: "PLN",
    name: "Polish Zloty",
  },
  {
    symbol: "PYG",
    name: "Paraguayan Guarani",
  },
  {
    symbol: "QAR",
    name: "Qatari Rial",
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
  },
  {
    symbol: "RSD",
    name: "Serbian Dinar",
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
  },
  {
    symbol: "SAR",
    name: "Saudi Riyal",
  },
  {
    symbol: "SBD",
    name: "Solomon Islands Dollar",
  },
  {
    symbol: "SCR",
    name: "Seychellois Rupee",
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
  },
  {
    symbol: "SEK",
    name: "Swedish Krona",
  },
  {
    symbol: "SGD",
    name: "Singapore Dollar",
  },
  {
    symbol: "SHP",
    name: "Saint Helena Pound",
  },
  {
    symbol: "SLL",
    name: "Sierra Leonean Leone",
  },
  {
    symbol: "SOS",
    name: "Somali Shilling",
  },
  {
    symbol: "SRD",
    name: "Surinamese Dollar",
  },
  {
    symbol: "SSP",
    name: "South Sudanese Pound",
  },
  {
    symbol: "STD",
    name: "São Tomé and Príncipe Dobra (pre-2018)",
  },
  {
    symbol: "STN",
    name: "São Tomé and Príncipe Dobra",
  },
  {
    symbol: "SVC",
    name: "Salvadoran Colón",
  },
  {
    symbol: "SYP",
    name: "Syrian Pound",
  },
  {
    symbol: "SZL",
    name: "Swazi Lilangeni",
  },
  {
    symbol: "THB",
    name: "Thai Baht",
  },
  {
    symbol: "TJS",
    name: "Tajikistani Somoni",
  },
  {
    symbol: "TMT",
    name: "Turkmenistani Manat",
  },
  {
    symbol: "TND",
    name: "Tunisian Dinar",
  },
  {
    symbol: "TOP",
    name: "Tongan Pa'anga",
  },
  {
    symbol: "TRY",
    name: "Turkish Lira",
  },
  {
    symbol: "TTD",
    name: "Trinidad and Tobago Dollar",
  },
  {
    symbol: "TWD",
    name: "New Taiwan Dollar",
  },
  {
    symbol: "TZS",
    name: "Tanzanian Shilling",
  },
  {
    symbol: "UAH",
    name: "Ukrainian Hryvnia",
  },
  {
    symbol: "UGX",
    name: "Ugandan Shilling",
  },
  {
    symbol: "USD",
    name: "United States Dollar",
  },
  {
    symbol: "UYU",
    name: "Uruguayan Peso",
  },
  {
    symbol: "UZS",
    name: "Uzbekistan Som",
  },
  {
    symbol: "VEF",
    name: "Venezuelan Bolívar Fuerte",
  },
  {
    symbol: "VND",
    name: "Vietnamese Dong",
  },
  {
    symbol: "VUV",
    name: "Vanuatu Vatu",
  },
  {
    symbol: "WST",
    name: "Samoan Tala",
  },
  {
    symbol: "XAF",
    name: "CFA Franc BEAC",
  },
  {
    symbol: "XAG",
    name: "Silver Ounce",
  },
  {
    symbol: "XAU",
    name: "Gold Ounce",
  },
  {
    symbol: "XCD",
    name: "East Caribbean Dollar",
  },
  {
    symbol: "XDR",
    name: "Special Drawing Rights",
  },
  {
    symbol: "XOF",
    name: "CFA Franc BCEAO",
  },
  {
    symbol: "XPD",
    name: "Palladium Ounce",
  },
  {
    symbol: "XPF",
    name: "CFP Franc",
  },
  {
    symbol: "XPT",
    name: "Platinum Ounce",
  },
  {
    symbol: "YER",
    name: "Yemeni Rial",
  },
  {
    symbol: "ZAR",
    name: "South African Rand",
  },
  {
    symbol: "ZMW",
    name: "Zambian Kwacha",
  },
  {
    symbol: "ZWL",
    name: "Zimbabwean Dollar",
  },
];

export const incotermOptions = [
  {
    label: "EXW",
    value: "EXW",
  },
  {
    label: "FCA",
    value: "FCA",
  },
  {
    label: "CPT",
    value: "CPT",
  },
  {
    label: "CIP",
    value: "CIP",
  },
  {
    label: "DAT",
    value: "DAT",
  },
  {
    label: "DAP",
    value: "DAP",
  },
  {
    label: "DDP",
    value: "DDP",
  },
  {
    label: "FAS",
    value: "FAS",
  },
  {
    label: "FOB",
    value: "FOB",
  },
  {
    label: "CFR",
    value: "CFR",
  },
  {
    label: "CIF",
    value: "CIF",
  },
  {
    label: "CNI",
    value: "CNI",
  },
  {
    label: "DTP",
    value: "DTP",
  },
  {
    label: "DPP",
    value: "DPP",
  },
];

export const DEFAULT_UPLOAD_MIME_TYPES = ""; // accept all MIME types

export const DEFAULT_UPLOAD_FILE_SIZE_MAX_LIMIT = 100 * 1e6; // 100 MB

export const DEFAULT_UPLOAD_FILE_SIZE_MIN_LIMIT = 1000; // 1000 B
