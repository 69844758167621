import React, { useState, useCallback } from "react";
import parseISO from "date-fns/parseISO";
import { useForm, FormProvider, Controller } from "react-hook-form";
import * as R from "ramda";
import { Field } from "../../components/Field/Field";
import { Button } from "../../components/Button/Button";
import {
  useUpdatePurchaseRequestService,
  useRemovePurchaseRequestItemAttachment,
  UpdatePurchaseRequestServiceMutationVariables,
  ViewerOrganizations,
} from "../../schema";
import { Datepicker } from "../../components/Datepicker/Datepicker";
import { Select } from "../../components/Select/Select";
import { currencies } from "../../constants";
import { Modal } from "../../components/Modal/Modal";
import { Accordion } from "../../components/Accordion/Accordion";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { UploadedAttachment } from "../../components/UploadedAttachment/UploadedAttachment";
import { NonUploadedFiles } from "../../components/NonUploadedFiles/NonUploadedFiles";
import { NotAvailable } from "../../components/NotAvailable/NotAvailable";
import { Attachments } from "../../components/Attachments/Attachments";
import { OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import styles from "../PurchaseRequestView/PurchaseRequestView.module.scss";
import { NumberInput } from "../../components/NumberInput/NumberInput";
import {
  StatusNotice,
  StatusNoticeProps,
  EventTypeEnum,
} from "../../components/StatusNotice/StatusNotice";
import { CustomFieldList } from "../../components/CustomFieldList/CustomFieldList";
import { isDefined } from "../../services/isDefined";
import { EsourcingFieldList } from "../../components/EsourcingFieldList/EsourcingFieldList";
import { ItemParams } from "./EditPurchaseRequestItemView";

export interface RequestProductFormProps {
  organization: ViewerOrganizations;
  service: ItemParams;
  purchaseRequestCode?: string;
  onSuccess(): void;
  onCancel(): void;
  onModalClose(): void;
}

export interface UpdatePurchaseRequestServiceFormInputs
  extends Omit<UpdatePurchaseRequestServiceMutationVariables, "customFields"> {
  customFields: { label: string; value: string | number | null }[];
  esourcingFields: string | null;
}

export const EditPurchaseRequestService: React.FC<RequestProductFormProps> = ({
  organization,
  service,
  onSuccess,
  onCancel,
  onModalClose,
}) => {
  // state change status notice state
  const [statusNotice, setStatusNotice] =
    React.useState<StatusNoticeProps | null>(null);
  const statusNoticeRef = React.useRef<HTMLDivElement>(null);

  const [responseDeadline, setResponseDeadline] = React.useState<Date>();

  const [files, setFiles] = useState<File[]>();

  type customFieldType = typeof service.customFields;

  const defaultValues: UpdatePurchaseRequestServiceFormInputs = {
    purchaseRequestItemId: service.id,
    name: service.name,
    code: service.code ?? null,
    currency:
      isDefined(service.currency) &&
      service.currency !== null &&
      R.not(R.isEmpty(service.currency))
        ? service.currency
        : organization.baseCurrency,
    unitPrice: service.unitPrice ?? null,
    suggestedSupplier: service.suggestedSupplier ?? null,
    supplierCode: service.supplierCode ?? null,
    expectedDeliveryDate: service.expectedDeliveryDate
      ? parseISO(service.expectedDeliveryDate)
      : null,
    additionalInfo: service.additionalInfo ?? null,
    productUrl: service.productUrl ?? null,
    customFields:
      R.compose<customFieldType, NonNullable<customFieldType>, any, any>(
        R.map(R.zipObj(["label", "value"])),
        R.toPairs,
        R.defaultTo({}),
      )(service.customFields) ?? [],
    esourcingFields: service.esourcingFields ?? [],
    attachments: null,
  };

  const formMethods = useForm<UpdatePurchaseRequestServiceFormInputs>({
    defaultValues,
  });

  const { register, handleSubmit } = formMethods;

  // mutation to update the purchase request product
  const [
    updatePurchaseRequestService,
    { loading: isUpdatePurchaseRequestServiceLoading },
  ] = useUpdatePurchaseRequestService({
    refetchQueries: [
      "PaginatedPurchaseRequests",
      "PurchaseRequestByCode",
      "GetItemById",
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setStatusNotice({
        level: EventTypeEnum.SUCCESS,
        eventTitle: "Successfully saved!",
      });

      // scroll to status message
      statusNoticeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    onError: () => {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Sorry! There was an error when saving the product",
        eventDescription: "Please try again",
      });

      // scroll to status message
      statusNoticeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  });

  // handle saving the form
  const onSave = async (
    formData: UpdatePurchaseRequestServiceMutationVariables,
  ) => {
    const customFieldsToMutationFormat = R.compose<
      { label: string; value: string }[],
      any[],
      { [k: string]: string },
      string
    >(
      JSON.stringify,
      R.fromPairs,
      R.map(R.values),
    )(
      (formData.customFields as unknown as {
        label: string;
        value: string;
      }[]) ?? [],
    );

    // call the mutation with formData
    await updatePurchaseRequestService({
      variables: {
        ...defaultValues,
        ...formData,
        unitPrice: formData.unitPrice
          ? parseFloat(formData.unitPrice.toString())
          : null,
        currency: R.not(R.isEmpty(formData.currency))
          ? formData.currency
          : organization.baseCurrency,
        attachments: files ?? [],
        customFields: customFieldsToMutationFormat,
        esourcingFields: JSON.stringify(formData.esourcingFields) ?? null,
      },
    });

    // empty files list
    setFiles([]);

    onSuccess();
  };

  // setup removing attachment
  const [removeAttachment] = useRemovePurchaseRequestItemAttachment({
    refetchQueries: ["PurchaseRequestByCode", "GetItemById"],
    awaitRefetchQueries: true,
  });

  // set files state on drop
  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // set selected file
      setFiles([...(files ?? []), ...acceptedFiles]);
    },
    [files],
  );

  return (
    <Modal
      title={service.name}
      onCloseRequested={() => onModalClose()}
      footer={
        <>
          <Button data-testid="e191b4232c" secondary onClick={() => onCancel()}>
            Cancel
          </Button>

          <Button
            form="purchase-request-service-form"
            type="submit"
            loading={isUpdatePurchaseRequestServiceLoading}
            disabled={isUpdatePurchaseRequestServiceLoading}
            data-testid="b1fe64e852"
          >
            Save
          </Button>
        </>
      }
    >
      <div className={styles["wrap"]}>
        {/* status notice */}
        {statusNotice && (
          <div ref={statusNoticeRef}>
            <StatusNotice
              level={statusNotice.level}
              eventTitle={statusNotice.eventTitle}
              eventDescription={statusNotice.eventDescription}
              onCloseRequested={() => {
                setStatusNotice(null);
              }}
            />
          </div>
        )}

        <form
          id="purchase-request-service-form"
          onSubmit={handleSubmit(onSave)}
        >
          <FormProvider {...formMethods}>
            <Field
              name={"name"}
              inputRef={register({ required: "Name is required" })}
              short
              required
              autofocus
              label="Service name"
              placeholder="Item name"
            />

            <Accordion isInitiallyOpen={false} title="Optional">
              <Field
                name={"suggestedSupplier"}
                inputRef={register}
                short
                label="Suggested Supplier"
              />
              <NumberInput
                name={"unitPrice"}
                inputRef={register}
                short
                label="Unit price"
                addon={
                  <Select
                    name={"currency"}
                    options={currencies.map((currency) => ({
                      value: currency.symbol,
                      label: currency.symbol,
                    }))}
                    inputRef={register}
                    secondary
                  />
                }
              />
              <Controller
                name="expectedDeliveryDate"
                render={(props) => (
                  <Datepicker
                    onChange={(date) => {
                      if (date) {
                        setResponseDeadline(date);
                        props.onChange(date);
                      }
                    }}
                    label="When do you want it delivered"
                    minDate={new Date()}
                    selected={responseDeadline}
                    onClear={() => {
                      setResponseDeadline(undefined);
                    }}
                  />
                )}
              />
              <Field
                name={"productUrl"}
                inputRef={register}
                short
                label="Link"
              />
              <Field
                name={"additionalInfo"}
                textareaRef={register}
                textarea
                short
                label="Additional info"
              />
              <Field
                name={"code"}
                inputRef={register}
                short
                label="Item code"
              />
              <Field
                name={"supplierCode"}
                inputRef={register}
                short
                label="Supplier code"
              />
              {/* Custom Fields */}
              <FieldLabel label="Custom Fields" />
              <CustomFieldList className={styles["custom-fields-list"]} />
              <FieldLabel label="Attachments" />
              <div className={styles["file-list"]}>
                {service.attachments && service.attachments.length > 0
                  ? service.attachments.map((attachment) => (
                      <UploadedAttachment
                        key={attachment.id}
                        attachment={attachment}
                        onRemove={() =>
                          removeAttachment({
                            variables: {
                              attachmentId: attachment.id,
                              purchaseRequestItemId: service.id,
                            },
                          })
                        }
                      />
                    ))
                  : !files && <NotAvailable alignLeft />}

                {files && files.length > 0 && (
                  <NonUploadedFiles files={files} setFiles={setFiles} />
                )}
              </div>
              <Attachments
                multiple
                loading={false}
                onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
              />

              {/* Esourcing Fields */}
              {service.esourcingFields ? (
                <EsourcingFieldList />
              ) : (
                <>
                  <FieldLabel label="Esourcing Fields" />
                  <NotAvailable alignLeft />
                </>
              )}
            </Accordion>
          </FormProvider>
        </form>
      </div>
    </Modal>
  );
};
