import React from "react";
import { Field } from "../Field/Field";
import { Button } from "../Button/Button";
import { Form } from "../Form/Form";
import { InputInfo } from "../../lib/react-apollo-hooks-form";
import styles from "./FeedUpdateReplyInput.module.scss";

export interface FeedUpdateReplyProps {
  replyInput: InputInfo;
  submitReply: () => void;
  discardReply: () => void;
  className?: string;
}

export default function FeedUpdateReplyInput(props: FeedUpdateReplyProps) {
  return (
    <>
      <Form onSubmit={props.submitReply} className={props.className}>
        <Field {...props.replyInput} textarea />
        <div className={styles["respond-wrap"]}>
          <Button
            data-testid="5cc387a29b"
            secondary
            warning
            onClick={props.discardReply}
          >
            Discard
          </Button>
          <Button data-testid="631a24c724" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}
