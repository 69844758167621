import React, { ChangeEvent } from "react";
import classNames from "classnames";
import styles from "./Radio.module.scss";

export interface RadioProps {
  name?: string;
  value?: string;
  checked?: boolean;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement> | React.RefObject<HTMLInputElement>;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

export const Radio: React.FC<RadioProps> = ({
  children,
  className,
  name,
  value,
  checked,
  inputRef,
  onChange,
}) => (
  <label className={classNames(styles.radio, className)}>
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className={styles["radio__input"]}
      onChange={onChange}
      ref={inputRef}
      data-testid={`${name}-${value}`}
    />
    <span className={styles["radio__checkmark"]} />
    <span className={styles["radio__content"]}>{children}</span>
  </label>
);
