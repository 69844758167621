import React from "react";
import classNames from "classnames";
import styles from "./FieldError.module.scss";

export interface FieldErrorProps {
  className?: string;
  error: string | undefined;
}

export const FieldError: React.FC<FieldErrorProps> = ({ className, error }) => {
  if (!error || error.length === 0) {
    return null;
  }

  return (
    <p className={classNames(styles["field-error"], className)}>{error}</p>
  );
};
