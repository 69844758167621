import React from "react";
import styles from "./StatusNotice.module.scss";

export enum EventTypeEnum {
  ALERT = "ALERT",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
}

interface StatusNoticeColorScheme extends React.CSSProperties {
  "--borderColor"?: string;
}

export interface StatusNoticeProps {
  eventTitle?: React.ReactNode;
  level?: EventTypeEnum;
  eventDescription?: React.ReactNode;
  onCloseRequested?: () => void;
}

export const StatusNotice: React.FC<StatusNoticeProps> = ({
  eventTitle,
  eventDescription,
  level = EventTypeEnum.SUCCESS,
  onCloseRequested,
}) => {
  const levelToColorScheme: { [key: string]: StatusNoticeColorScheme } = {
    [EventTypeEnum.ALERT]: {
      "--borderColor": styles.labelAlert,
    },
    [EventTypeEnum.SUCCESS]: {
      "--borderColor": styles.labelSuccess,
    },
    [EventTypeEnum.WARNING]: {
      "--borderColor": styles.labelWarning,
    },
  };

  return (
    <div
      className={styles.wrap}
      style={level ? levelToColorScheme[level] : levelToColorScheme.SUCCESS}
    >
      <div className={styles["content-wrap"]}>
        <div className={styles["event-container"]}>
          <div className={styles["event-meta"]}>
            <div className={styles["event-title"]}>
              <div>{eventTitle}</div>
            </div>
          </div>
          {eventDescription && (
            <div className={styles["event-description"]}>
              {eventDescription}
            </div>
          )}
        </div>
        <button
          data-testid="5fa4af8af7ad"
          onClick={onCloseRequested}
          className={styles["close-button"]}
        ></button>
      </div>
    </div>
  );
};
