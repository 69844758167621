import React from "react";
import { UploadSuppliersExcelRows } from "../../schema";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { Description } from "../Description/Description";
import styles from "./ExcelImportErrorsTable.module.scss";

export interface ExcelImportErrorsTableProps {
  rows: UploadSuppliersExcelRows[];
}

export const ExcelSuppliersImportErrorsTable: React.FC<ExcelImportErrorsTableProps> = ({
  rows,
}) => (
  <>
    <BasicTable>
      <thead>
        <tr>
          <Cell header compact>
            Row
          </Cell>
          <Cell header nowrap>
            Name*
          </Cell>
          <Cell header nowrap>
            Email*
          </Cell>
          <Cell header nowrap>
            Web
          </Cell>
          <Cell header nowrap>
            Address
          </Cell>
          <Cell header nowrap>
            City
          </Cell>
          <Cell header nowrap>
            Zip
          </Cell>
          <Cell header nowrap>
            Country
          </Cell>
          <Cell header nowrap>
            Payment Terms
          </Cell>
          <Cell header nowrap>
            Currency
          </Cell>
          <Cell header nowrap>
            VAT identification
          </Cell>
          <Cell header nowrap>
            Business registry
          </Cell>
          <Cell header nowrap>
            LEI code
          </Cell>
          <Cell header nowrap>
            D-U-N-S code
          </Cell>
          <Cell header nowrap>
            ERP Supplier code
          </Cell>
          <Cell header nowrap>
            Custom Field 1
          </Cell>
          <Cell header nowrap>
            Custom Field 2
          </Cell>
          <Cell header nowrap>
            Custom Field 3
          </Cell>
          <Cell header nowrap>
            Status
          </Cell>
          <Cell header nowrap>
            Our contact
          </Cell>
          <Cell header nowrap>
            Notes
          </Cell>
        </tr>
      </thead>
      <tbody>
        {rows
          .filter((row) => !row.success)
          .map((row) => (
            <tr key={row.row}>
              <Cell right>{row.row}</Cell>
              <Cell
                center
                success={row.errors.name === null}
                error={row.errors.name !== null}
              >
                {row.errors.name ? (
                  row.errors.name.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.email === null}
                error={row.errors.email !== null}
              >
                {row.errors.email ? (
                  row.errors.email.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.webAddress === null}
                error={row.errors.webAddress !== null}
              >
                {row.errors.webAddress ? (
                  row.errors.webAddress.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.address === null}
                error={row.errors.address !== null}
              >
                {row.errors.address ? (
                  row.errors.address.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.city === null}
                error={row.errors.city !== null}
              >
                {row.errors.city ? (
                  row.errors.city.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.areaCode === null}
                error={row.errors.areaCode !== null}
              >
                {row.errors.areaCode ? (
                  row.errors.areaCode.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.countryCode === null}
                error={row.errors.countryCode !== null}
              >
                {row.errors.countryCode ? (
                  row.errors.countryCode.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.paymentTerms === null}
                error={row.errors.paymentTerms !== null}
              >
                {row.errors.paymentTerms ? (
                  row.errors.paymentTerms.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.currency === null}
                error={row.errors.currency !== null}
              >
                {row.errors.currency ? (
                  row.errors.currency.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.vatIdentification === null}
                error={row.errors.vatIdentification !== null}
              >
                {row.errors.vatIdentification ? (
                  row.errors.vatIdentification.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.businessRegistry === null}
                error={row.errors.businessRegistry !== null}
              >
                {row.errors.businessRegistry ? (
                  row.errors.businessRegistry.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.leiCode === null}
                error={row.errors.leiCode !== null}
              >
                {row.errors.leiCode ? (
                  row.errors.leiCode.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.dunsCode === null}
                error={row.errors.dunsCode !== null}
              >
                {row.errors.dunsCode ? (
                  row.errors.dunsCode.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.erpSupplierCode === null}
                error={row.errors.erpSupplierCode !== null}
              >
                {row.errors.erpSupplierCode ? (
                  row.errors.erpSupplierCode.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.customField1 === null}
                error={row.errors.customField1 !== null}
              >
                {row.errors.customField1 ? (
                  row.errors.customField1.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.customField2 === null}
                error={row.errors.customField2 !== null}
              >
                {row.errors.customField2 ? (
                  row.errors.customField2.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.customField3 === null}
                error={row.errors.customField3 !== null}
              >
                {row.errors.customField3 ? (
                  row.errors.customField3.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell
                center
                success={row.errors.status === null}
                error={row.errors.status !== null}
              >
                {row.errors.status ? (
                  row.errors.status.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
              <Cell center>
                <CheckmarkIcon className={styles.checkmark} />
              </Cell>
              <Cell
                center
                success={row.errors.notes === null}
                error={row.errors.notes !== null}
              >
                {row.errors.notes ? (
                  row.errors.notes.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell>
            </tr>
          ))}
      </tbody>
    </BasicTable>
    <Description spacious>
      Columns marked with * indicate required values (column order is important,
      header titles are not).
    </Description>
  </>
);
