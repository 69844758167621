import React from "react";
import classNames from "classnames";
import styles from "./Badge.module.scss";

export interface BadgeProps {
  title: string;
  icon?: React.ReactNode;
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({ title, icon, className }) => {
  return (
    <div className={classNames(styles.badge, className)}>
      {icon && icon}
      <div className={styles.title}>{title}</div>
    </div>
  );
};
