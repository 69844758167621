import React from "react";
import { Description } from "../Description/Description";
import { Button } from "../Button/Button";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import styles from "./SelectionList.module.scss";

export interface SelectionListProps {
  selections: { id: string; name: string }[];
  noSelectionText?: string;
  label?: string;
  removingSelectionId?: string;
  onRemove(organization: { id: string }): void;
}

export const SelectionList: React.FC<SelectionListProps> = ({
  selections,
  noSelectionText,
  label,
  removingSelectionId,
  onRemove,
}) => {
  return (
    <div className={styles.wrap}>
      {label && <FieldLabel label={label} />}
      {selections.length === 0 && (
        <Description>{noSelectionText || "Nothing selected yet"}</Description>
      )}
      {selections.map((selection, index) => (
        <div key={index} className={styles.selection}>
          <div className={styles.name}>
            {selection.name || <em>Unknown</em>}{" "}
          </div>
          <Button
            data-testid="c47880c55a"
            text
            disabled={removingSelectionId !== undefined}
            loading={removingSelectionId === selection.id}
            onClick={() => onRemove(selection)}
          >
            Remove
          </Button>
        </div>
      ))}
    </div>
  );
};
