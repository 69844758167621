import React from "react";
import styles from "./MaintenanceView.module.scss";

export interface MaintenanceViewProps {
  message?: React.ReactNode;
  error?: Error;
}

export const MaintenanceView: React.FC<MaintenanceViewProps> = ({
  message,
  error,
}) => {
  const defaultMessage = (
    <>
      <p>
        You are either using some old browser or we have planned maintenance.
      </p>
      <p>Please use Chrome, Firefox, Safari or Edge or check again later.</p>
    </>
  );
  const isDevelopmentMode = process.env.NODE_ENV === "development";

  return (
    <div className={styles.view}>
      <h1 className={styles.title}>Something ain&apos;t right…</h1>
      <div className={styles.message}>{message || defaultMessage}</div>
      {error && isDevelopmentMode && (
        <div className={styles.error}>{error.message}</div>
      )}
    </div>
  );
};
