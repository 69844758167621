import React from "react";
import classNames from "classnames";
import styles from "./Code.module.scss";

export interface CodeProps {
  title?: string;
  className?: string;
}

export const Code: React.FC<CodeProps> = ({ title, children, className }) => (
  <>
    {title && (
      <p>
        <span className={styles.title}>{title}</span>
      </p>
    )}
    <code className={classNames(styles.code, className)}>{children}</code>
  </>
);
