export const config = {
  ssl: window.location.protocol === "https:",
  host: window.location.hostname,
  port:
    window.location.port.length > 0 ? parseInt(window.location.port, 10) : 80,
  serverUrl: process.env.REACT_APP_SERVER_URL || "/graphql",
  clientVersion: process.env.REACT_APP_VERSION || "n/a",
  debug: process.env.NODE_ENV === "development",
  productionServerPort: process.env.PRODUCTION_SERVER_PORT || 3001,
  inboundEmailDomain:
    process.env.REACT_APP_INBOUND_EMAIL_DOMAIN || "cf.procurementflow.com",
};

export type Config = typeof config;
