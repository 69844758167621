import React from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray } from "react-hook-form";
import { equals, isEmpty, not } from "ramda";
import { EditIcon } from "../../theme/svg/EditIcon";
import { LinkIcon } from "../../theme/svg/LinkIcon";
import { UploadedAttachment } from "../UploadedAttachment/UploadedAttachment";
import { CurrencyEnum } from "../../schema";
import { formatDate } from "../../services/formatDate";
import { calculateRowTotal } from "../../services/calculateItemRowTotal";
import { Unbox } from "../../lib/helpers/typescript-helpers";
import { SourcingEventTemplate } from "../RfqEventForm/RfqEventForm";
import { CalendarIcon } from "../../theme/svg/CalendarIcon";
import { NumberInput } from "../NumberInput/NumberInput";
import styles from "./RfqItemQuotations.module.scss";

export interface RfqItemQuotationsProps {
  className?: string;
  isEditable?: boolean;
  baseCurrency?: CurrencyEnum;
}

// locally used type definitions
type ItemPriceInfoList = {
  quantity: string;
  price: string;
}[];

type RowTotalsList = {
  total: string;
}[];

export const RfqItemQuotations: React.FC<RfqItemQuotationsProps> = (props) => {
  const {
    className,
    isEditable = true,
    baseCurrency = CurrencyEnum.EUR,
  } = props;

  // keep row totals calculation result if it differs from previous
  const [rowTotals, dispatchRowTotals] = React.useReducer(
    (state: RowTotalsList[], action: { payload: RowTotalsList[] }) => {
      if (!equals(action.payload, state)) {
        return action.payload;
      }

      return state;
    },
    Array(0),
  );

  const { control, register, watch, getValues } = useFormContext();

  const { fields: items } = useFieldArray<
    Unbox<SourcingEventTemplate["items"]>
  >({
    control,
    name: "items",
  });

  // set up watch for item related form changes (mainly quantities)
  watch("items");

  // whenever something changes about the form data, calculate current row total prices
  React.useEffect(() => {
    // user filled data part from the form
    const currentPriceInfo = getValues().items?.map(
      (item: { priceInfo: { price: string } }) => ({
        priceInfo: item.priceInfo,
      }),
    );

    // data from server (which was used to render the form)
    const defaultPriceInfo = items?.map((item) => ({
      priceInfo: item.priceInfo,
    }));

    // combine the two
    const combinedPriceInfo = defaultPriceInfo.reduce<ItemPriceInfoList[]>(
      (acc, curr, itemIndex) => {
        if (!curr || !curr.priceInfo) {
          return acc;
        }

        const mergedPriceInfo = curr.priceInfo.reduce<
          {
            quantity: string;
            price: string;
          }[]
        >((piAcc, piCurr, piIndex) => {
          const mergedObj = {
            ...piCurr,
            ...currentPriceInfo[itemIndex].priceInfo[piIndex],
          };

          return piAcc.concat([mergedObj]);
        }, []);

        return acc.concat([mergedPriceInfo]);
      },
      [],
    );

    // calculate row totals for each item, quantity, unit price combo
    const rowTotalValues = combinedPriceInfo.map((item) =>
      item.map((itemPrice) => ({
        total: calculateRowTotal({
          quantity: parseFloat(itemPrice.quantity),
          unitPrice: parseFloat(itemPrice.price),
          currency: baseCurrency,
        }),
      })),
    );

    // update the state
    dispatchRowTotals({ payload: rowTotalValues });
  });

  return (
    <div className={classNames(styles["item-list"], className)}>
      {items?.map((item, itemIndex) => {
        return (
          <>
            <div className={styles["item-container"]} key={item.id}>
              <div className={styles["item-title"]}>
                <div className={styles["item-number"]}>{itemIndex + 1}</div>
                <div className={styles["item-name"]}>{item.itemData?.name}</div>
              </div>

              <div className={styles["item-info-body"]}>
                <div className={styles["primary-info"]}>
                  <div className={styles["item-info"]}>
                    {/* additional info */}
                    <h4 className={styles["subheading"]}>Item info</h4>
                    {item.itemData?.itemAdditionalInfo && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <EditIcon />
                        </div>
                        <div className={styles["field-item-info"]}>
                          {item.itemData?.itemAdditionalInfo}
                        </div>
                      </div>
                    )}

                    {/* expected delivery */}
                    {item.itemData?.expectedDelivery && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <CalendarIcon />
                        </div>
                        <div
                          className={styles["field-item-info"]}
                        >{`Exp delivery: ${formatDate(
                          item.itemData?.expectedDelivery,
                        )}`}</div>
                      </div>
                    )}

                    {/* link field */}
                    {item.itemData?.url && (
                      <div className={styles["field-item"]}>
                        <div className={styles["field-item-icon"]}>
                          <LinkIcon />
                        </div>
                        <a
                          href={
                            item.itemData?.url.startsWith("http")
                              ? item.itemData?.url
                              : `https://${item.itemData?.url}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles["field-item-info"]}
                        >
                          {item.itemData?.url}
                        </a>
                      </div>
                    )}

                    {/* own code */}
                    {item.itemData?.ownCode && (
                      <div className={styles["field-item"]}>
                        <div
                          className={classNames(
                            styles["field-item-icon"],
                            styles["field-item-icon--wide"],
                          )}
                        >
                          Customer Item Code
                        </div>
                        <div
                          className={classNames(
                            styles["field-item-info"],
                            styles["field-item-info--wide"],
                          )}
                        >
                          {item.itemData?.ownCode}
                        </div>
                      </div>
                    )}

                    {/* supplier code */}
                    {item.itemData?.supplierCode.isEditable && (
                      <div className={styles["field-item"]}>
                        <div
                          className={classNames(
                            styles["field-item-icon"],
                            styles["field-item-icon--wide"],
                          )}
                        >
                          Vendor Item Code
                        </div>
                        <div
                          className={classNames(
                            styles["field-item-info"],
                            styles["field-item-info--wide"],
                          )}
                        >
                          <input
                            className={styles["supplier-code-input"]}
                            type="text"
                            defaultValue={
                              item.itemData?.supplierCode.value ?? undefined
                            }
                            name={`items[${itemIndex}].itemData.supplierCode.value`}
                            readOnly={!isEditable}
                            ref={register()}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={styles["offer"]}>
                    <h4 className={styles["subheading"]}>Your offer</h4>
                    <div className={styles["quantities"]}>
                      {item.priceInfo?.map((pi, index) => (
                        <div
                          className={styles["quantity-price-variant"]}
                          key={index}
                        >
                          {/* item quantity */}
                          <div className={styles["item-quantity"]}>{`${
                            pi.quantity
                          } ${item.itemData?.unit ?? ""}`}</div>

                          {/* unit price */}
                          <div
                            key={index}
                            className={classNames(
                              styles["unit-price"],
                              className,
                            )}
                          >
                            <NumberInput
                              className={styles["unit-price-input"]}
                              name={`items[${itemIndex}].priceInfo[${index}].price`}
                              value={pi.price ?? ""}
                              inputRef={register()}
                              readOnly={!isEditable}
                            />
                            <div className={styles["currency"]}>
                              {baseCurrency}
                              {item.itemData?.unit
                                ? ` / ${item.itemData.unit}`
                                : ""}
                            </div>
                          </div>
                          <div className={styles["row-total"]}>
                            {not(isEmpty(rowTotals))
                              ? `( ${rowTotals[itemIndex][index].total} )`
                              : "(...)"}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className={styles["secondary-info"]}>
                  {not(isEmpty(item.customFields)) && (
                    <div className={styles["custom-fields"]}>
                      {/* custom fields */}
                      <h4 className={styles["subheading"]}>
                        Item specifications
                      </h4>
                      {item.customFields?.map((customField, index) => {
                        return (
                          (customField.label || customField.value) && (
                            <div key={index} className={styles["field-item"]}>
                              <div className={styles["field-item-info"]}>
                                <div>
                                  <div className={styles["custom-field-label"]}>
                                    {customField.label}
                                  </div>
                                  <div className={styles["custom-field-value"]}>
                                    {customField.value}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}

                  {not(isEmpty(item.itemQuestions)) && (
                    <div className={styles["item-questions"]}>
                      {/* item questions */}
                      <h4 className={styles["subheading"]}>
                        Questions regarding the item
                      </h4>
                      {item.itemQuestions?.map((itemQuestion, index) => {
                        return (
                          (itemQuestion.question || itemQuestion.answer) && (
                            <div
                              key={index}
                              className={classNames(
                                styles["field-item"],
                                styles["align-items-flex-start"],
                              )}
                            >
                              <div className={styles["field-item-info"]}>
                                <div>
                                  <div className={styles["item-question"]}>
                                    {itemQuestion.question}
                                  </div>
                                  <div>
                                    <textarea
                                      className={styles["item-answer-input"]}
                                      defaultValue={itemQuestion.answer ?? ""}
                                      name={`items[${itemIndex}].itemQuestions[${index}].answer`}
                                      readOnly={!isEditable}
                                      ref={register()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}

                  {not(isEmpty(item.itemData?.attachments)) && (
                    <div className={styles["item-attachments"]}>
                      {/* item attachments */}
                      <h4 className={styles["subheading"]}>Attachments</h4>
                      {!isEmpty(item.itemData?.attachments) && (
                        <div className={styles["field-item"]}>
                          <div className={styles["field-item-info"]}>
                            {item.itemData?.attachments?.map(
                              (attachment: any) => (
                                <UploadedAttachment
                                  key={attachment.id}
                                  attachment={{
                                    id: attachment.id,
                                    filename: attachment.title,
                                    url: attachment.url,
                                  }}
                                  secondary
                                />
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};
