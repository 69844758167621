import React from "react";
import { View } from "../../components/View/View";

// TODO: make the view pretty
export const VersionMismatchView: React.FC = () => (
  <View>
    You are using old version of the application, please{" "}
    <a
      data-testid="d4cbe23b8b"
      href="#reload"
      onClick={(e) => {
        e.preventDefault();

        window.location.reload();
      }}
    >
      reload the page
    </a>
  </View>
);
