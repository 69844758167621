import { ValidatorFn } from "../lib/react-apollo-hooks-form";

export function maxLength(maxLength: number): ValidatorFn {
  return (value) => {
    if (typeof value !== "string") {
      throw new Error(
        `The maxLength validator expects a "string" value but got a "${typeof value}"`,
      );
    }

    if (typeof value === "string" && value.length > maxLength) {
      return `Expected no more than ${maxLength} characters`;
    }
  };
}
