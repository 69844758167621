import { useEffect } from "react";

export function useGlobalKeyPress(keyCode: number, onKeyPressed?: () => void) {
  useEffect(() => {
    function onKeyPress(e: KeyboardEvent) {
      if (typeof onKeyPressed !== "function" || e.keyCode !== keyCode) {
        return;
      }

      onKeyPressed();
    }

    window.addEventListener("keyup", onKeyPress);

    return () => window.removeEventListener("keyup", onKeyPress);
  }, [keyCode, onKeyPressed]);
}
