import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { isEmpty } from "ramda";
import { Button } from "../../components/Button/Button";
import { Chip } from "../../components/Chip/Chip";
import { Field } from "../../components/Field/Field";
import {
  ItemAutosuggest,
  AutosuggestItem,
} from "../../components/ItemAutosuggest/ItemAutosuggest";
import { Modal } from "../../components/Modal/Modal";
import { Select } from "../../components/Select/Select";
import { SelectDefaultContact } from "../../components/SelectDefaultContact/SelectDefaultContact";
import { currencies } from "../../constants";
import {
  ViewerOrganizations,
  useSupplierById,
  SupplierStatusEnum,
  useSelectDefaultContact,
  Category,
  Country,
  useCreateCategory,
  useAddSupplierCategory,
  useRemoveSupplierCategory,
  SupplierByIdOrganization,
  useUpdateSupplier,
  CountryCodeEnum,
  CurrencyEnum,
} from "../../schema";
import { matches } from "../../services/matches";
import { isUrl } from "../../validators/isUrl";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./EditSupplierModal.module.scss";

export interface EditSupplierModalProps {
  organization: ViewerOrganizations;
  organizationCategories: SupplierByIdOrganization["categories"];
  countries: Country[];
  supplierId: string;
  onModalClose(): void;
}

export interface EditSupplierFormInputs {
  name: string | null;
  webAddress: string | null;
  address: string | null;
  city: string | null;
  areaCode: string | null;
  countryCode: string | null;
  paymentTerms: string | null;
  currency: string | null;
  vatIdentification: string | null;
  businessRegistry: string | null;
  leiCode: string | null;
  dunsCode: string | null;
  erpSupplierCode: string | null;
  customField1: string | null;
  customField2: string | null;
  customField3: string | null;
  status: string | null;
  notes: string | null;
}

export const EditSupplierModal: React.FC<EditSupplierModalProps> = ({
  organization,
  organizationCategories,
  countries,
  supplierId,
  onModalClose,
}) => {
  const [categoryDisplayValue, setCategoryDisplayValue] = useState("");
  const [chosenCategories, setChosenCategories] = React.useState<
    Pick<Category, "id" | "name">[]
  >([]);

  const fetchCategories = async (value: string): Promise<AutosuggestItem[]> => {
    return organizationCategories
      .filter((category) => matches(value, category.name))
      .map((category) => ({
        ...category,
        isUsed: chosenCategories.find((cat) => cat.id === category.id)
          ? true
          : false,
      }))
      .sort((a, b) =>
        a.isUsed && !b.isUsed ? 1 : !a.isUsed && b.isUsed ? -1 : 0,
      );
  };

  // set up creating category mutation
  const [createCategory, { loading: isCreateCategoryLoading }] =
    useCreateCategory({
      refetchQueries: ["SupplierById"],
      awaitRefetchQueries: true,
    });

  // set up adding selected category to supplier
  const [addSupplierCategory, { loading: isAddSupplierCategoryLoading }] =
    useAddSupplierCategory({
      refetchQueries: ["SupplierById"],
      awaitRefetchQueries: true,
    });

  // set up removing selected category from supplier
  const [removeSupplierCategory] = useRemoveSupplierCategory({
    refetchQueries: ["SupplierById"],
    awaitRefetchQueries: true,
  });

  // handle creating a new category
  const handleCreateCategory = async (name: string) => {
    // create a new category
    const { data: creationResult, errors } = await createCategory({
      variables: { organizationId: organization.id, name, parentId: null },
    });

    if (!creationResult || errors) {
      return;
    }

    // add created category to supplier
    addSupplierCategory({
      variables: {
        categoryId: creationResult.createCategory.id,
        supplierId,
        organizationId: organization.id,
      },
    });

    // clear display value
    setCategoryDisplayValue("");
  };

  // handle choosing a category from list
  const handleChooseCategory = async (suggestion?: AutosuggestItem) => {
    // don't add category if none is selected or the category has already been added
    if (
      !suggestion ||
      chosenCategories.find(
        (existingCategory) => existingCategory.id === suggestion.id,
      )
    ) {
      return;
    }

    // add category to supplier
    addSupplierCategory({
      variables: {
        categoryId: suggestion.id,
        supplierId,
        organizationId: organization.id,
      },
    });

    // clear display value
    setCategoryDisplayValue("");
  };

  // handle removing added category from supplier
  const handleRemoveCategory = (categoryId: string) => {
    // remove category from supplier
    removeSupplierCategory({
      variables: {
        categoryId,
        supplierId,
        organizationId: organization.id,
      },
    });
  };

  // load supplier by id
  const {
    data,
    loading: isFetching,
    error: fetchError,
  } = useSupplierById({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      supplierId,
    },
  });

  // get supplier info if loaded
  const supplier = data?.viewer ? data.viewer.supplierById : undefined;

  // initialize categories
  React.useEffect(() => {
    if (supplier?.categories) {
      setChosenCategories(supplier.categories);
    }
  }, [supplier]);

  const [updateSupplier, { loading: isUpdateSupplierLoading }] =
    useUpdateSupplier({
      refetchQueries: ["SrmView"],
      awaitRefetchQueries: true,
    });

  const { register, watch, handleSubmit, errors } =
    useForm<EditSupplierFormInputs>();

  const onSubmit = async (formData: EditSupplierFormInputs) => {
    const updateResult = await updateSupplier({
      variables: {
        supplierId,
        ...formData,
        name: formData.name ?? "",
        countryCode: formData.countryCode
          ? (formData.countryCode as CountryCodeEnum)
          : null,
        currency: formData.currency
          ? (formData.currency as CurrencyEnum)
          : null,
        status: formData.status
          ? (formData.status as SupplierStatusEnum)
          : null,
      },
    }).catch((_err) => {
      throw new Error("Could not save supplier data");
    });

    if (updateResult) {
      onModalClose();
    }
  };

  // Configure set default contact mutation
  const [
    setDefaultContactPerson,
    { loading: loadingSelectDefaultContact, error: errorSelectDefaultContact },
  ] = useSelectDefaultContact();

  // handle loading error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading supplier failed"
        error={fetchError}
        modal={{ title: "Edit supplier", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !supplier) {
    return <LoadingView overlay />;
  }

  // Set default contact
  const setDefaultContact = async (id: string) => {
    await setDefaultContactPerson({
      variables: {
        contactPersonId: id,
      },
      refetchQueries: ["SupplierById"],
      awaitRefetchQueries: true,
    });
  };

  // <Modal> title
  const nameFieldValue = watch("name", supplier.name);
  const title = isEmpty(nameFieldValue) ? "Edit supplier" : nameFieldValue;

  return (
    <Modal
      title={title}
      footer={
        <>
          <Button
            data-testid="cdd84c43f3"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="9b598e000b"
            form="edit-supplier-form"
            type="submit"
            loading={loadingSelectDefaultContact || isUpdateSupplierLoading}
          >
            Save
          </Button>
        </>
      }
      onCloseRequested={onModalClose}
    >
      <form
        autoComplete="off"
        id="edit-supplier-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles["form-section"]}>
          <Field
            name="name"
            defaultValue={supplier.name ?? undefined}
            inputRef={register({ required: "Name is required" })}
            short
            autofocus
            label="Name"
            errors={[errors?.name?.message ?? ""]}
          />
          <SelectDefaultContact
            defaultContactPersonId={supplier.defaultContactPerson.id}
            contactPersons={supplier.contactPersons}
            error={errorSelectDefaultContact}
            loading={loadingSelectDefaultContact}
            setDefaultContact={setDefaultContact}
          />
          <Field
            name="webAddress"
            defaultValue={supplier.webAddress ?? undefined}
            inputRef={register({ validate: isUrl })}
            short
            label="Web address"
            errors={[errors?.webAddress?.message ?? ""]}
          />
          <Field
            name="address"
            defaultValue={supplier.address ?? undefined}
            inputRef={register()}
            short
            label="Address"
            errors={[errors?.address?.message ?? ""]}
          />
          <Field
            name="city"
            defaultValue={supplier.city ?? undefined}
            inputRef={register()}
            short
            label="City"
            errors={[errors?.city?.message ?? ""]}
          />
          <Field
            name="areaCode"
            defaultValue={supplier.areaCode ?? undefined}
            inputRef={register()}
            short
            label="ZIP / postal"
            errors={[errors?.areaCode?.message ?? ""]}
          />
          <Select
            name="countryCode"
            options={
              countries
                ? [{ label: "-", value: "" }].concat(
                    countries.map((country) => ({
                      label: country.name,
                      value: country.code,
                    })),
                  )
                : []
            }
            value={supplier.countryCode ?? undefined}
            inputRef={register()}
            short
            label="Country"
          />
        </div>
        <div className={styles["form-section"]}>
          <Field
            name="paymentTerms"
            defaultValue={supplier.paymentTerms ?? undefined}
            inputRef={register()}
            short
            label="Payment Terms"
            errors={[errors?.paymentTerms?.message ?? ""]}
          />
          <Select
            name="currency"
            options={currencies.map((currency) => ({
              label: currency.name,
              value: currency.symbol,
            }))}
            value={supplier.currency ?? undefined}
            inputRef={register()}
            short
            label="Currency"
          />
          <Field
            name="vatIdentification"
            defaultValue={supplier.vatIdentification ?? undefined}
            inputRef={register()}
            short
            label="VAT identification"
            errors={[errors?.vatIdentification?.message ?? ""]}
          />
          <Field
            name="businessRegistry"
            defaultValue={supplier.businessRegistry ?? undefined}
            inputRef={register()}
            short
            label="Business registry"
            errors={[errors?.businessRegistry?.message ?? ""]}
          />
          <Field
            name="leiCode"
            defaultValue={supplier.leiCode ?? undefined}
            inputRef={register()}
            short
            label="LEI code"
            errors={[errors?.leiCode?.message ?? ""]}
          />
          <Field
            name="dunsCode"
            defaultValue={supplier.dunsCode ?? undefined}
            inputRef={register()}
            short
            label="D-U-N-S code"
            errors={[errors?.dunsCode?.message ?? ""]}
          />
          <Field
            name="erpSupplierCode"
            defaultValue={supplier.erpSupplierCode ?? undefined}
            inputRef={register()}
            short
            label="ERP Supplier code"
            errors={[errors?.erpSupplierCode?.message ?? ""]}
          />
        </div>
        <div className={styles["form-section"]}>
          <Field
            name="customField1"
            defaultValue={supplier.customFields?.customField1 ?? undefined}
            inputRef={register()}
            short
            label="Custom field 1"
            errors={[errors?.customField1?.message ?? ""]}
          />
          <Field
            name="customField2"
            defaultValue={supplier.customFields?.customField2 ?? undefined}
            inputRef={register()}
            short
            label="Custom field 2"
            errors={[errors?.customField2?.message ?? ""]}
          />
          <Field
            name="customField3"
            defaultValue={supplier.customFields?.customField3 ?? undefined}
            inputRef={register()}
            short
            label="Custom field 3"
            errors={[errors?.customField3?.message ?? ""]}
          />
        </div>
        <div className={styles["form-section"]}>
          <Select
            name="status"
            options={[
              {
                label: SupplierStatusEnum.APPROVED,
                value: SupplierStatusEnum.APPROVED,
              },
              {
                label: SupplierStatusEnum.INACTIVE,
                value: SupplierStatusEnum.INACTIVE,
              },
              {
                label: SupplierStatusEnum.NEW,
                value: SupplierStatusEnum.NEW,
              },
              {
                label: SupplierStatusEnum.ARCHIVED,
                value: SupplierStatusEnum.ARCHIVED,
              },
            ]}
            value={supplier.status ?? undefined}
            inputRef={register()}
            short
            label="Status"
          />
          <ItemAutosuggest
            className={styles["category-autosuggest"]}
            displayAvatar={false}
            loading={isCreateCategoryLoading || isAddSupplierCategoryLoading}
            label="Category"
            fetchSuggestions={fetchCategories}
            value={categoryDisplayValue}
            chosenSuggestion={undefined}
            onChange={(newCategoryValue) =>
              setCategoryDisplayValue(newCategoryValue)
            }
            onCreateNew={handleCreateCategory}
            onChoose={handleChooseCategory}
          />
          <div className={styles["supplier-categories-list"]}>
            {chosenCategories.map((category) => (
              <Chip
                key={category.id}
                id={category.id}
                className={styles["chip"]}
                onRemove={() => handleRemoveCategory(category.id)}
              >
                {category.name}
              </Chip>
            ))}
          </div>
          <Field
            name="notes"
            defaultValue={supplier.notes ?? undefined}
            inputRef={register()}
            short
            label="Notes"
            errors={[errors?.notes?.message ?? ""]}
          />
        </div>
      </form>
    </Modal>
  );
};
