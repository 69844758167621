import React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import {
  ActivityFeedItemFragment,
  ActivityTypeEnum,
  ApprovalWorkflowStatusEnum,
} from "../schema";
import { Button } from "../components/Button/Button";
import styles from "../components/FeedUpdate/FeedUpdate.module.scss";
import { OpenExternalIcon } from "../theme/svg/OpenExternalIcon";
import { SwimlaneId } from "../constants";
import {
  scrollIntoViewByRef,
  StringToRefObjectMap,
} from "./scrollIntoViewByRef";
import { convertHtmlStringToSafeJsx } from "./convertHtmlStringToSafeJsx";
import { formatDatetime } from "./formatDatetime";
import { assertUnreachable } from "./assertUnreachable";
import { formatReplaceUnderscores } from "./formatReplaceUnderscores";
import { getCodePrefixBySwimlaneId } from "./getCodePrefixBySwimlaneId";

export function getMessageByActivity(
  organizationUrlName: string,
  history: RouteComponentProps["history"] | undefined,
  activity: ActivityFeedItemFragment,
  activityRefs?: StringToRefObjectMap,
) {
  if (!activity.data || !activity.data.__typename) {
    return null;
  }

  switch (activity.data.__typename) {
    case ActivityTypeEnum.ASSIGN: {
      const { assigneeInfo, itemInfo } = activity.data;

      // fail if no itemInfo is present
      if (!itemInfo || (itemInfo && !itemInfo.code)) {
        return null;
      }

      return (
        <>
          {assigneeInfo ? (
            <>
              Assigned <strong>{itemInfo.code}</strong> “{itemInfo.name}“ to{" "}
              <span
                style={{ color: "#00bf3a" }}
              >{`${assigneeInfo.firstName} ${assigneeInfo.lastName}`}</span>
            </>
          ) : (
            <>
              Marked <strong>{itemInfo.code}</strong> “{itemInfo.name}“ to be{" "}
              <span style={{ color: "#00bf3a" }}>Unassigned</span>
            </>
          )}
        </>
      );
    }

    case ActivityTypeEnum.DRAG_CARD: {
      const messageData = activity.data;

      return (
        <>
          {[SwimlaneId.PURCHASE_REQUEST, SwimlaneId.RFX].includes(
            SwimlaneId[messageData.fromType],
          ) ? (
            <div>
              Started{" "}
              <strong>
                {formatReplaceUnderscores(
                  getCodePrefixBySwimlaneId(SwimlaneId[messageData.toType]),
                )}
              </strong>{" "}
              by dragging card{" "}
              <strong>
                {formatReplaceUnderscores(
                  getCodePrefixBySwimlaneId(SwimlaneId[messageData.fromType]),
                )}
                -{messageData.fromCode}
              </strong>
            </div>
          ) : (
            <div>
              Moved card from{" "}
              <strong>{formatReplaceUnderscores(messageData.fromType)}</strong>{" "}
              to <strong>{formatReplaceUnderscores(messageData.toType)}</strong>
            </div>
          )}
        </>
      );
    }

    case ActivityTypeEnum.COMMENT: {
      const messageData = activity.data;

      return (
        <>
          {convertHtmlStringToSafeJsx(messageData.comment)}

          {activity.attachments && (
            <div className={styles.file}>
              {activity.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        data-testid="969748f5d1"
                        href={attachment.url}
                        className={styles.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.filename}>
                          {attachment.filename}
                        </span>{" "}
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </>
      );
    }

    case ActivityTypeEnum.REPLY: {
      const messageData = activity.data;

      return (
        <>
          {messageData.user.firstName} {messageData.user.lastName} replied to{" "}
          <Button
            data-testid="f15df691a5"
            className={styles["inline-button"]}
            text
            onClick={() => scrollIntoViewByRef(activityRefs, activity.parentId)}
          >
            comment
          </Button>
          .
        </>
      );
    }

    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST: {
      const messageData = activity.data;
      return (
        <>
          Created a new purchase request card{" "}
          <strong>PR-{messageData.code}</strong>
          {activity.attachments && (
            <div className={styles.file}>
              {activity.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        data-testid="74ecf20128"
                        href={attachment.url}
                        className={styles.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.filename}>
                          {attachment.filename}
                        </span>{" "}
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </>
      );
    }

    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST_PRODUCT: {
      const messageData = activity.data;

      return (
        <>
          Added a new purchase request product item{" "}
          <strong>{messageData.name}</strong> to{" "}
          <strong>PR-{messageData.purchaseRequestCode}</strong>
        </>
      );
    }

    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST_SERVICE: {
      const messageData = activity.data;

      return (
        <>
          Added a new purchase request for service{" "}
          <strong>{messageData.name}</strong>
        </>
      );
    }

    case ActivityTypeEnum.EMAIL_INBOUND_MENTION: {
      return (
        <>
          <span>Replied to </span>
          <Button
            data-testid="00f960097d"
            className={styles["inline-button"]}
            text
            onClick={() => scrollIntoViewByRef(activityRefs, activity.parentId)}
          >
            comment
          </Button>{" "}
          by e-mail .
        </>
      );
    }

    case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER: {
      const messageData = activity.data;

      return (
        <>
          <a
            data-testid="b6b280012f"
            href={`mailto:${messageData.supplierInfo.email}`}
          >
            {messageData.supplierInfo.email}
          </a>
          <span> replied to </span>
          <Button
            data-testid="4051fa1b99"
            className={styles["inline-button"]}
            text
            onClick={() => scrollIntoViewByRef(activityRefs, activity.parentId)}
          >
            RFI
          </Button>
          .
        </>
      );
    }

    case ActivityTypeEnum.EMAIL_INBOUND_REQUESTER: {
      return (
        <>
          <span>Replied to </span>
          <Button
            data-testid="4318c7418e"
            className={styles["inline-button"]}
            text
            onClick={() => scrollIntoViewByRef(activityRefs, activity.parentId)}
          >
            comment
          </Button>
          .
        </>
      );
    }

    case ActivityTypeEnum.EMAIL_OUTBOUND_MENTION: {
      const messageData = activity.data;

      // FIXME: Create the actual activity on server side
      return (
        <>
          <div>{messageData.emailOutboundMentionInfo}</div>
        </>
      );
    }

    case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER: {
      const messageData = activity.data;

      return (
        <div
          data-testid="9da1e19a78"
          onClick={() => {
            history?.push(`/${organizationUrlName}/email/${activity.id}`);
          }}
          className={styles["email-wrap"]}
        >
          <div>
            <span>To: </span>
            <a
              data-testid="39619b291c"
              href={`mailto:${messageData.supplierInfo.email}`}
            >
              {messageData.supplierInfo.email}
            </a>
          </div>
          <div>
            <span>Subject: </span>
            <span>{messageData.subject}</span>
          </div>
          <div>---</div>
          <div className={styles["email-body"]}>{messageData.message}</div>
          {activity.attachments && (
            <div className={styles.file}>
              {activity.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        data-testid="693bc6388c"
                        href={attachment.url}
                        className={styles.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.filename}>
                          {attachment.filename}
                        </span>{" "}
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    case ActivityTypeEnum.CREATE_APPROVAL_WORKFLOW: {
      const messageData = activity.data;

      return (
        <>
          Created a new Approval Workflow draft{" "}
          <strong>{`AWF-${messageData.approvalWorkflowInfo.code}`}</strong>
        </>
      );
    }

    case ActivityTypeEnum.UPDATE_APPROVAL_WORKFLOW: {
      const messageData = activity.data;

      const workflowStatus = messageData.approvalWorkflowInfo.status;
      const workflowStats = messageData.approvalWorkflowStats;

      switch (workflowStatus) {
        case ApprovalWorkflowStatusEnum.IN_PROGRESS: {
          return (
            <>
              Sent{" "}
              <Link
                data-testid="09e2742be8"
                to={messageData.relativeCardInfo.url}
              >
                <strong>{messageData.relativeCardInfo.code}</strong>
              </Link>{" "}
              {`"${messageData.relativeCardInfo.name}"`} to Approval Workflow (
              <strong>{`AWF-${messageData.approvalWorkflowInfo.code}`}</strong>){" "}
              {workflowStats.approvedRequestCount}/
              {workflowStats.totalRequestCount}
            </>
          );
        }

        // TODO: add cancelling reason
        case ApprovalWorkflowStatusEnum.CANCELLED: {
          return (
            <>
              <strong>{`AWF-${messageData.approvalWorkflowInfo.code}`}</strong>{" "}
              <Link
                data-testid="a42efae874"
                to={messageData.relativeCardInfo.url}
              >
                <strong>{messageData.relativeCardInfo.code}</strong>
              </Link>{" "}
              {`"${messageData.relativeCardInfo.name}"`} got{" "}
              <span>Cancelled</span>
            </>
          );
        }

        case ApprovalWorkflowStatusEnum.APPROVED: {
          return (
            <>
              <strong>{`AWF-${messageData.approvalWorkflowInfo.code}`}</strong>{" "}
              <Link
                data-testid="a42efae874"
                to={messageData.relativeCardInfo.url}
              >
                <strong>{messageData.relativeCardInfo.code}</strong>
              </Link>{" "}
              {`"${messageData.relativeCardInfo.name}"`} got{" "}
              <span>Approved!</span>
            </>
          );
        }

        case ApprovalWorkflowStatusEnum.DECLINED: {
          return (
            <>
              <Link
                data-testid="170cd8a0fa"
                to={messageData.relativeCardInfo.url}
              >
                <strong>{messageData.relativeCardInfo.code}</strong>
              </Link>{" "}
              {`"${messageData.relativeCardInfo.name}"`}
              <strong>
                {" "}
                {`AWF-${messageData.approvalWorkflowInfo.code}`}
              </strong>{" "}
              got <span>Declined</span> with comment{" "}
              {messageData.approvalWorkflowInfo.comment}.
            </>
          );
        }

        case ApprovalWorkflowStatusEnum.DRAFT: {
          return (
            <>
              Updated Approval Workflow{" "}
              <strong> AWF-{messageData.approvalWorkflowInfo.code}</strong> info
            </>
          );
        }

        default:
          return assertUnreachable(
            workflowStatus,
            `Unhandled workflow status: ${workflowStatus}. This should not happen.`,
          );
      }
    }

    case ActivityTypeEnum.CREATE_APPROVAL_REQUEST: {
      const messageData = activity.data;

      return (
        <>
          Added{" "}
          <strong>{`${messageData.approverInfo.firstName} ${messageData.approverInfo.lastName}`}</strong>{" "}
          as approver. Approval due date{" "}
          <strong>{`${formatDatetime(
            messageData.approvalRequestInfo.dueDate,
          )}`}</strong>
          .
        </>
      );
    }

    case ActivityTypeEnum.UPDATE_APPROVAL_REQUEST: {
      const messageData = activity.data;

      const {
        approvalWorkflowInfo,
        approvalRequestInfo,
        approvalWorkflowStats,
      } = messageData;

      return (
        <>
          <strong>AWF-{approvalWorkflowInfo.code}</strong> got decision{" "}
          <strong>{approvalRequestInfo.decision}</strong> (
          {approvalWorkflowStats.approvedRequestCount}/
          {approvalWorkflowStats.totalRequestCount})
          {approvalRequestInfo.approverComment && (
            <>{` with comment: "${approvalRequestInfo.approverComment}"`}</>
          )}
        </>
      );
    }

    case ActivityTypeEnum.ADD_ATTACHMENT: {
      return (
        <>
          Uploaded a new attachment
          <div className={styles["file"]}>
            {activity.data.url && (
              <a
                className={styles["attachment"]}
                href={activity.data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={styles["filename"]}>
                  {activity.data.filename}
                </span>
                <OpenExternalIcon className={styles["open-external-icon"]} />
              </a>
            )}
          </div>
        </>
      );
    }

    case ActivityTypeEnum.CREATE_SUPPLIER: {
      return null;
    }

    // TODO: The following events are to be handled as they are implemented
    case ActivityTypeEnum.DRAG_ITEM: {
      return null;
    }
    case ActivityTypeEnum.ADD_ITEM: {
      const { name, addItemQuantity, addItemUnit } = activity.data;

      return (
        <>
          Added item <strong>{name}</strong> {addItemQuantity} {addItemUnit}
        </>
      );
    }
    case ActivityTypeEnum.ADD_ITEMS: {
      return null;
    }

    default:
      return null;
  }
}
