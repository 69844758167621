import React, { ReactNode } from "react";
import { PopperProps } from "react-popper";
import useOnClickOutside from "use-onclickoutside";
import classNames from "classnames";
import { useGlobalKeyPress } from "../../hooks/useGlobalKeyPress";
import { Separator } from "../Separator/Separator";
import styles from "./Dropdown.module.scss";

export interface DropdownProps {
  className?: string;
  isFilter?: boolean;
  onCloseRequested?(): void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  className,
  isFilter,
  children,
  onCloseRequested,
  ...rest
}) => {
  const wrapRef = React.useRef<HTMLDivElement>(null);

  // handle clicking outside the dropdown content, call the callback if supplied
  useOnClickOutside(wrapRef, () => {
    if (typeof onCloseRequested !== "function") {
      return;
    }

    // without the condition menu filters cannot be selected
    if (!isFilter) {
      onCloseRequested();
    }
  });

  // listen escape keypress
  const ESCAPE_KEY_CODE = 27;

  useGlobalKeyPress(ESCAPE_KEY_CODE, onCloseRequested);

  return (
    <div
      {...rest}
      ref={wrapRef}
      className={classNames(styles.dropdown, className)}
    >
      {children}
    </div>
  );
};

export interface DropdownSelectorProps {
  hasArrow?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  className?: string;
  onClick(): void;
}

export const DropdownSelector: React.FC<DropdownSelectorProps> =
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLButtonElement, DropdownSelectorProps>(
    ({ className, children, onClick, hasArrow }, ref) => (
      <button
        data-testid="719be1ae1e"
        type="button"
        ref={ref}
        className={classNames(
          styles.dropdown__selector,
          {
            [styles["dropdown__selector--has-arrow"]]: hasArrow,
          },
          className,
        )}
        onClick={(e) => {
          e.stopPropagation();

          onClick();
        }}
      >
        {children}
      </button>
    ),
  );

export interface DropdownContentProps {
  open: boolean;
  right?: boolean;
  className?: string;
  listClassname?: string;
  ref?: React.Ref<HTMLDivElement>;
  placement?: PopperProps["placement"];
  style?: React.CSSProperties;
  isFilter?: boolean;
  footerAddon?: ReactNode;
  onCloseRequested?(): void;
}

// eslint-disable-next-line react/display-name
export const DropdownContent: React.FC<DropdownContentProps> = React.forwardRef<
  HTMLDivElement,
  DropdownContentProps
>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (
    {
      className,
      listClassname,
      children,
      right,
      open: isOpen,
      placement,
      style,
      isFilter,
      footerAddon,
      onCloseRequested,
    },
    ref,
  ) => {
    const wrapRef = React.useRef<HTMLDivElement>(null);

    // handle clicking outside the dropdown content, call the callback if supplied
    useOnClickOutside(wrapRef, () => {
      if (typeof onCloseRequested !== "function") {
        return;
      }

      if (isFilter) {
        onCloseRequested();
      }
    });

    if (!isOpen && isFilter) {
      return null;
    }

    return (
      <div
        className={classNames(
          styles.dropdown__container,
          {
            [styles["dropdown__container--open"]]: isOpen,
            [styles["dropdown__container--right"]]: right,
            [styles["dropdown__container--hidden"]]: !isOpen,
          },
          className,
        )}
        style={style}
        data-placement={placement}
        ref={isFilter ? wrapRef : ref}
      >
        <ul className={classNames(styles.dropdown__list, listClassname)}>
          {children}
        </ul>
        {footerAddon && (
          <div className={styles.drodown__footer_addon}>
            <Separator zeroMarginBottom />
            {footerAddon}
          </div>
        )}
      </div>
    );
  },
);

export interface DropdownItemProps {
  className?: string;
  nowrap?: boolean;
  onClick?(): void;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  className,
  nowrap,
  onClick,
}) => {
  return (
    <li
      className={classNames(
        styles.dropdown__item,
        { [styles["dropdown__item--nowrap"]]: nowrap },
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();

        if (onClick !== undefined) {
          onClick();
        }
      }}
    >
      {children}
    </li>
  );
};
