import React from "react";
import { Maybe } from "../../schema";
import styles from "./SectionSubtitle.module.scss";

export interface SectionSubtitleProps {
  title: string;
  value?: Maybe<string>;
}

export const SectionSubtitle: React.FC<SectionSubtitleProps> = ({
  title,
  value,
}) => (
  <>
    <h4 className={styles["section-subtitle"]}>{title}:</h4>
    <p>{value}</p>
  </>
);
