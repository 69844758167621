import { intervalToDuration, formatDuration } from "date-fns";

export function formatDateTimeDifference(dateLeft: Date, dateRight: Date) {
  const durations = intervalToDuration({ start: dateLeft, end: dateRight });
  const formattedDuration = formatDuration(durations, {
    format: ["days", "hours", "minutes"],
  });

  const substitutedFormats = formattedDuration
    .replace(/\sday[\w]?/, "d")
    .replace(/\shour[\w]?/, "h")
    .replace(/\sminute[\w]?/, "m");

  return substitutedFormats;
}
