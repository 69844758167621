import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import { PurchaseRequestCard } from "../../components/PurchaseRequestCard/PurchaseRequestCard";
import {
  CreatePurchaseRequestFormData,
  PurchaseRequestForm,
} from "../../components/PurchaseRequestForm/PurchaseRequestForm";
import { useRouter } from "../../hooks/useRouter";
import { useForm as useApolloForm } from "../../lib/react-apollo-hooks-form";
import {
  RequesterPortalViewOrganization,
  ViewerViewer,
  CardStatusLevelEnum,
  PurchaseRequestItemTypeEnum,
  PurchaseRequestStatusEnum,
  useCreatePurchaseRequestFromPortal,
  CreatePurchaseRequestFromPortalMutationVariables,
  UpdatePurchaseRequestFromPortalMutationVariables,
  useUpdatePurchaseRequestFromPortal,
  Maybe,
  RequesterPortalViewDepartment,
  RequesterPortalViewProject,
  RequesterPortalViewCostCentre,
} from "../../schema";
import { convertPRFormDataToMutation } from "../../services/convertPRFormDataToMutation";
import { isDefined } from "../../services/isDefined";
import { CompleteReadyIcon } from "../../theme/svg/CompleteReadyIcon";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./CreateRequestFromPortalView.module.scss";

interface CreateRequestFromPortalViewProps {
  organization: RequesterPortalViewOrganization;
  viewer: ViewerViewer;
  department: Maybe<RequesterPortalViewDepartment>;
  project: Maybe<RequesterPortalViewProject>;
  costCentre: Maybe<RequesterPortalViewCostCentre>;
}

export const CreateRequestFromPortalView: React.FC<CreateRequestFromPortalViewProps> = ({
  organization,
  viewer,
  department,
  project,
  costCentre,
}) => {
  const { history } = useRouter();

  const [files, setFiles] = React.useState<File[]>([]);

  // Configure form
  const defaultOptions = {
    refetchQueries: ["CreatePurchaseRequestView", "RequesterPortalView"],
    awaitRefetchQueries: true,
  };

  // Configure create purchase request form
  const { submit, loading: isCreating, error } = useApolloForm({
    mutation: useCreatePurchaseRequestFromPortal,
    options: defaultOptions,
    onSuccess: async (response) => {
      if (response.createPurchaseRequestFromPortal) {
        history.push(
          `/${organization.urlName}/purchase-requests/${response.createPurchaseRequestFromPortal.code}`,
        );
      }
    },
  });
  // Configure update purchase request form
  const {
    submit: updatePR,
    loading: isUpdating,
    error: errorUpdating,
  } = useApolloForm({
    mutation: useUpdatePurchaseRequestFromPortal,
    options: defaultOptions,
    onSuccess: async (response) => {
      if (response.updatePurchaseRequestFromPortal) {
        history.push(
          `/${organization.urlName}/purchase-requests/${response.updatePurchaseRequestFromPortal.code}`,
        );
      }
    },
  });

  const methods = useForm<CreatePurchaseRequestFormData>({
    defaultValues: {
      items: [
        { name: "", quantity: "", type: PurchaseRequestItemTypeEnum.PRODUCT },
      ],
    },
  });

  // Control form submission
  const savePurchaseRequest = async (
    values: CreatePurchaseRequestFormData,
    newStatus?: PurchaseRequestStatusEnum,
    purchaseRequestId?: string,
  ) => {
    // We need to know the purchase request id if has created on excel upload
    const createdPrId = purchaseRequestId;

    // Create a new purchase request
    if (!createdPrId) {
      const purchaseRequestDetails: CreatePurchaseRequestFromPortalMutationVariables = {
        ...convertPRFormDataToMutation(values),
        status: isDefined(newStatus)
          ? PurchaseRequestStatusEnum[newStatus]
          : PurchaseRequestStatusEnum.DRAFT,
        organizationId: organization.id,
        attachments: files,
      };

      submit(purchaseRequestDetails);
    } else {
      // Else update purchase request(If has created from excel upload)
      const purchaseRequestDetails: UpdatePurchaseRequestFromPortalMutationVariables = {
        ...convertPRFormDataToMutation(values),
        status: isDefined(newStatus)
          ? PurchaseRequestStatusEnum[newStatus]
          : PurchaseRequestStatusEnum.DRAFT,
        purchaseRequestId: createdPrId,
      };

      updatePR(purchaseRequestDetails);
    }
  };

  const draftPurchaseRequest = methods.handleSubmit((values) => {
    savePurchaseRequest(values);
  });

  const readyPurchaseRequest = methods.handleSubmit((values) => {
    savePurchaseRequest(values, PurchaseRequestStatusEnum.CREATED_FROM_EMAIL);
  });

  return (
    <div>
      {(isCreating || isUpdating) && <LoadingView overlay></LoadingView>}
      <PurchaseRequestCard
        status={{ text: "NEW", level: CardStatusLevelEnum.DEFAULT }}
        title={<h1 className={styles["page-title"]}>New Purchase Request</h1>}
        isEditing={false}
        secondary
      >
        <FormProvider {...methods}>
          <PurchaseRequestForm
            files={files}
            setFiles={setFiles}
            organization={organization}
            viewer={viewer}
            error={error || errorUpdating}
            savePurchaseRequest={savePurchaseRequest}
            fromPortal
            department={department}
            project={project}
            costCentre={costCentre}
          />
        </FormProvider>
      </PurchaseRequestCard>
      <div className={styles["form-footer"]}>
        <Button secondary onClick={() => draftPurchaseRequest()}>
          Save
        </Button>
        <Button onClick={() => readyPurchaseRequest()}>
          <CompleteReadyIcon /> Request ready
        </Button>
      </div>
    </div>
  );
};
