import React from "react";
import classNames from "classnames";
import styles from "./Separator.module.scss";

export interface SeparatorProps {
  title?: React.ReactNode;
  infoText?: React.ReactNode;
  zeroMarginTop?: boolean;
  zeroMarginBottom?: boolean;
  className?: string;
  titleClassName?: string;
}

export const Separator: React.FC<SeparatorProps> = ({
  title,
  infoText,
  zeroMarginTop,
  zeroMarginBottom,
  className,
  titleClassName,
}) => (
  <div
    className={classNames(
      styles.wrap,
      {
        [styles["wrap--with-title"]]: title !== undefined,
        [styles["wrap--zero-margin-top"]]: zeroMarginTop,
        [styles["wrap--zero-margin-bottom"]]: zeroMarginBottom,
      },
      className,
    )}
  >
    {title && (
      <span className={classNames(styles.title, titleClassName)}>{title}</span>
    )}
    <div className={styles.separator} />
    {infoText && <span className={styles["info-text"]}>{infoText}</span>}
    <div className={styles.separator} />
  </div>
);
