import { ViewerOrganizations } from "../schema";
import { useRouter } from "./useRouter";

// returns active organization from url
export function useActiveOrganization(
  organizations: ViewerOrganizations[],
): ViewerOrganizations | undefined {
  const { location } = useRouter();

  // return undefined if the user is not part of any organizations
  if (organizations.length === 0) {
    return undefined;
  }

  // extract company url name from url
  const tokens = location.pathname.split("/");
  const locationUrlName = tokens.length >= 2 ? tokens[1] : undefined;

  // attempt to find active organization by url name, default to first
  let activeOrganization = organizations.find(
    (organization) => organization.urlName === locationUrlName,
  );

  // attempt to use last used organization if available
  const lastUsedOrganizationUrlName: string | undefined =
    window.localStorage.activeOrganizationUrlName;

  if (!activeOrganization && lastUsedOrganizationUrlName !== undefined) {
    activeOrganization = organizations.find(
      (organization) => organization.urlName === lastUsedOrganizationUrlName,
    );
  }

  // default to first organization
  if (!activeOrganization) {
    activeOrganization = organizations[0];
  }

  // store the last used active organization in local storage
  window.localStorage.activeOrganizationUrlName = activeOrganization.urlName;

  return activeOrganization;
}
