import React, { useState, useEffect } from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  useUpdateUserProfile,
  useViewer,
  ViewerCountries,
  ViewerViewer,
} from "../../schema";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Description } from "../../components/Description/Description";
import { Unit } from "../../components/Unit/Unit";

export interface UpdateUserProfileProps {
  viewer: ViewerViewer;
  id: string;
  firstName: string;
  lastName: string;
  countries?: ViewerCountries[];
  onModalClose(): void;
}

export const UpdateUserProfile: React.FC<UpdateUserProfileProps> = ({
  viewer,
  id,
  firstName,
  lastName,
  countries,
  onModalClose,
}) => {
  // setup form
  const {
    loading: isSaving,
    error: saveError,
    useInput,
    useSelect,
    submit: updateUser,
  } = useForm({
    mutation: useUpdateUserProfile,
    options: {
      refetchQueries: ["Viewer"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // load user by id
  const { data, loading: isFetching, error: fetchError } = useViewer({
    fetchPolicy: "network-only",
    variables: {
      id,
      firstName,
      lastName,
    },
  });

  // configure inputs
  const phoneNumberInput = useInput({ name: "phoneNumber", optional: true });
  const addressInput = useInput({ name: "address", optional: true });
  const cityInput = useInput({ name: "city", optional: true });
  const areaCodeInput = useInput({ name: "areaCode", optional: true });
  const countryInput = useSelect({
    name: "countryCode",
    options: countries
      ? [{ label: "-", value: "" }].concat(
          countries.map((country) => ({
            label: country.name,
            value: country.code,
          })),
        )
      : [],
    optional: true,
    defaultValue: "",
  });

  // setup state
  const [isInitialized, setIsInitialized] = useState(false);

  // get user info if loaded
  const user = data?.viewer;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.phoneNumber) {
      phoneNumberInput.setValue(user.phoneNumber);
    }

    if (user.address) {
      addressInput.setValue(user.address);
    }

    if (user.city) {
      cityInput.setValue(user.city);
    }

    if (user.areaCode) {
      areaCodeInput.setValue(user.areaCode);
    }

    if (user.countryCode) {
      countryInput.setValue(user.countryCode);
    }

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // handle fetch error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading user information failed"
        error={fetchError}
        modal={{ title: "Edit user", onModalClose }}
      />
    );
  }

  // handle save error
  if (saveError) {
    return (
      <ErrorView
        title="Saving user information failed"
        error={saveError}
        modal={{ title: "Edit user", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !user || !isInitialized) {
    return <LoadingView overlay />;
  }

  const submit = () => {
    updateUser({
      id,
      firstName,
      lastName,
    });
  };

  return (
    <Modal
      title={`${viewer.firstName} ${viewer.lastName}`}
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button
            data-testid="0dc5973bac"
            secondary
            onClick={() => {
              onModalClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="e96eac1498"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form>
        <Unit>
          <FieldLabel label="Email" />
          <Unit small>{viewer.email}</Unit>
          <Description>
            <p>
              Please contact your organization ProcurementFlow key-user to
              change email.
            </p>
          </Description>
        </Unit>
        <Field {...phoneNumberInput} short label="Phone" autofocus />
        <Field {...addressInput} short label="Address" />
        <Field {...cityInput} short label="City" />
        <Field {...areaCodeInput} short label="ZIP / Postal code" />
        <Select {...countryInput} short label="Country" />
      </Form>
    </Modal>
  );
};
