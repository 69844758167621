import React from "react";
import { Header } from "../../components/Header/Header";
import { ProfileDropdown } from "../../components/ProfileDropdown/ProfileDropdown";
import { ViewerViewer } from "../../schema";
import styles from "./ForbiddenView.module.scss";

export interface ForbiddenViewProps {
  viewer: ViewerViewer;
}

export const ForbiddenView: React.FC<ForbiddenViewProps> = ({ viewer }) => {
  return (
    <div className={styles.wrap}>
      <Header>
        <ProfileDropdown viewer={viewer} user={viewer} />
      </Header>
      <div className={styles.content}>
        You don&apos;t have access to required page
      </div>
    </div>
  );
};
