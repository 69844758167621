import {
  CardStatusLevelEnum,
  PurchaseRequestByCodePurchaseRequestByCode,
  PaginatedPurchaseRequestsEdges,
  ApprovalWorkflowStatusEnum,
  PurchaseRequestStatusEnum,
} from "../schema";
import { CardInfo } from "../components/Card/Card";
import { AvatarUser } from "../components/Avatar/Avatar";
import { DraggableType } from "../components/Swimlane/Swimlane";
import { CardStatus } from "../components/Status/Status";
import { getItemInfo } from "./getItemInfo";
import { pick } from "./pick";
import { getWorkflowStatusText } from "./getWorkflowStatusText";
import { getWorkflowStatusLevel } from "./getWorkflowStatusLevel";

export function getPurchaseRequestCardInfo(
  info: PaginatedPurchaseRequestsEdges,
  users: AvatarUser[],
  assigningCardId: string | undefined,
): CardInfo {
  const prefix = "PR";
  const cardCode = `${prefix}-${info.code}`;

  return {
    ...pick(info, "id", "code", "name", "suppliers", "assignee", "createdDate"),
    type: DraggableType.CARD,
    prefix,
    status: getCardStatus(info),
    items: info.items.map((item) => getItemInfo(item, info.id, cardCode)),
    users,
    projects: info.project ? [info.project] : [],
    requestors: [info.requestor],
    description: "", // TODO: implement dynamic description
    labels: [], // TODO: use real labels once implmeneted
    attachmentCount: null, // TODO: use real attachment count once implemented
    commentCount: null, // TODO: use real comment count once implemented
    isPurchaseRequestCard: true,
    assigning: info.id === assigningCardId,
  };
}

export function getCardStatus(
  purchaseRequest:
    | PaginatedPurchaseRequestsEdges
    | PurchaseRequestByCodePurchaseRequestByCode,
): CardStatus {
  // return card's own status if no approval workflow exists, is cancelled, request is archived or any items are in other swimlanes
  if (
    !purchaseRequest.latestApprovalWorkflow ||
    purchaseRequest.latestApprovalWorkflow.status ===
      ApprovalWorkflowStatusEnum.CANCELLED ||
    purchaseRequest.status === PurchaseRequestStatusEnum.IN_PROGRESS ||
    purchaseRequest.status === PurchaseRequestStatusEnum.ARCHIVED
  ) {
    return {
      text: purchaseRequest.cardStatus.text,
      level: purchaseRequest.cardStatus.level,
    };
  }

  // otherwise return approval workflow status
  if (purchaseRequest.latestApprovalWorkflow) {
    return {
      text: getWorkflowStatusText(
        purchaseRequest.latestApprovalWorkflow.status,
      ),
      level: getWorkflowStatusLevel(
        purchaseRequest.latestApprovalWorkflow.status,
      ),
    };
  }

  return { text: "UNKNOWN", level: CardStatusLevelEnum.WARNING };
}
