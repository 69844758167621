import React, { Suspense, useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { GraphqlEvent } from "./services/setupGraphqlClient";
import { assertUnreachable } from "./services/assertUnreachable";
import { VersionMismatchView } from "./views/VersionMismatchView/VersionMismatchView";
import { addEventListener, client } from "./graphql-client";
import "./theme/main.scss";
import { BrowserSupportChecker } from "./components/BrowserSupportChecker/BrowserSupportChecker";
import { LoadingView } from "./views/LoadingView/LoadingView";

// const { client, addEventListener } = setupGraphqlClient();

export const Root: React.FC = () => {
  const [isVersionMismatch, setIsVersionMismatch] = useState(false);

  // listen for graphql events
  useEffect(() => {
    addEventListener((event) => {
      switch (event) {
        case GraphqlEvent.VERSION_MISMATCH:
          setIsVersionMismatch(true);
          break;

        default:
          // eslint-disable-next-line no-undef
          return assertUnreachable(
            event,
            `GraphQL event "${event}" is not handled, this should not happen`,
          );
      }
    });
  }, []);

  // show version mismatch view if current client version does not match latest version
  if (isVersionMismatch) {
    return <VersionMismatchView />;
  }

  // render main app surrounded by required context providers
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <Suspense fallback={<LoadingView />}>
          <Router>
            <BrowserSupportChecker>
              <App />
            </BrowserSupportChecker>
          </Router>
        </Suspense>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
