import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  useCreateOrganization,
  OrganizationByIdCountries,
  OrganizationByIdCurrencies,
} from "../../schema";
import { notEmpty } from "../../validators/notEmpty";

export interface CreateOrganizationProps {
  countries?: OrganizationByIdCountries[];
  currencies?: OrganizationByIdCurrencies[];
  onModalClose(): void;
}

export const CreateOrganization: React.FC<CreateOrganizationProps> = ({
  countries,
  currencies,
  onModalClose,
}) => {
  // setup form
  const {
    useInput,
    useSelect,
    loading,
    error,
    submit: createOrganization,
  } = useForm({
    mutation: useCreateOrganization,
    options: {
      refetchQueries: ["OrganizationsManagement"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const companyNameInput = useInput({
    name: "companyName",
    validators: [notEmpty],
  });
  const addressInput = useInput({ name: "address", validators: [notEmpty] });
  const cityInput = useInput({ name: "city", validators: [notEmpty] });
  const areaCodeInput = useInput({ name: "areaCode", validators: [notEmpty] });
  const countryCodeInput = useSelect({
    name: "countryCode",
    options: countries
      ? countries.map((country) => ({
          label: country.name,
          value: country.code,
        }))
      : [],
  });
  const baseCurrencyInput = useSelect({
    name: "baseCurrency",
    options: currencies
      ? currencies.map((currency) => ({
          label: currency.name,
          value: currency.symbol,
        }))
      : [],
  });
  const urlNameInput = useInput({ name: "urlName", validators: [notEmpty] });

  // submits the form
  const submit = () => {
    createOrganization();
  };

  return (
    <Modal
      title="Add new organization"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="9981f55839" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="beaecabfe7"
            loading={loading}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={submit}>
        <Field {...companyNameInput} short label="Organization" />
        <Field {...urlNameInput} short label="Url name" />
        <Select {...countryCodeInput} short label="Country" />
        <Field {...cityInput} short label="City" />
        <Field {...addressInput} short label="Address" />
        <Field {...areaCodeInput} short label="Area code" />
        <Select {...baseCurrencyInput} short label="Currency" />
      </Form>
    </Modal>
  );
};
