import { AutosuggestItem } from "../components/ItemAutosuggest/ItemAutosuggest";
import { PurchaseRequestByCodeDepartments } from "../schema";

export function departmentToAutosuggestItem(
  department: PurchaseRequestByCodeDepartments,
): AutosuggestItem {
  return {
    id: department.id,
    name: department.name,
  };
}
