import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { useRouter } from "../../hooks/useRouter";
import styles from "./StartSourcingEventModal.module.scss";
import eSourcingInstruction from "./images/eSourcing-instruction.gif";

export interface StartSourcingEventModalProps {
  organizationUrlName: string;
}

export const StartSourcingEventModal: React.FC<StartSourcingEventModalProps> = ({
  organizationUrlName,
}) => {
  const { history } = useRouter();

  const onModalCancel = () => {
    history.push(`/sourcing-events/${organizationUrlName}`);
  };

  return (
    <Modal
      title="Here is how to start an eSourcing event!"
      onCloseRequested={onModalCancel}
    >
      <div className={styles["wrap"]}>
        <div className={styles["description"]}>
          <div className={styles["description_text"]}>
            Just drag Purchase Requests (PR) to eSourcing
          </div>
          <img
            src={eSourcingInstruction}
            alt="eSourcing instruction"
            className={styles["description_gif"]}
          ></img>
        </div>
        <div className={styles["action"]}>
          <div className={styles["action_text"]}>
            In case you need to add something new to eSourcing then first
          </div>
          <Button
            onClick={() =>
              history.push(`/${organizationUrlName}/create-purchase-request`)
            }
          >
            Create a new PR
          </Button>
        </div>
      </div>
    </Modal>
  );
};
