import { isEmail } from "../validators/isEmail";

export function getInitials(name: string): string {
  // attempt to find initial from the first half of the email
  if (isEmail(name) === undefined) {
    return getInitials(name.split("@")[0]);
  }

  // split name
  const names = name.trim().split(/[ .-]/);

  // return first two characters of a single name
  if (names.length === 1) {
    return name.substr(0, 2).toUpperCase();
  }

  // return first letter of first and last name
  return `${names[0].substr(0, 1).toUpperCase()}${names[names.length - 1]
    .substr(0, 1)
    .toUpperCase()}`;
}
