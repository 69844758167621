import React, { useState, useEffect } from "react";
import { Avatar } from "../Avatar/Avatar";
import { User } from "../../schema";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import styles from "./UpdateNotice.module.scss";

export interface UpdateNoticeProps {
  eventTitle?: React.ReactNode;
  eventTime?: Date;
  eventDescription?: React.ReactNode;
  avatarUser?: User;
  onCloseRequested?: () => void;
}

export const UpdateNotice: React.FC<UpdateNoticeProps> = ({
  eventTitle,
  eventTime = new Date(),
  eventDescription,
  avatarUser,
  onCloseRequested,
}) => {
  const [timeDiff, setTimeDiff] = useState(formatTimeAgo(eventTime));

  // Update time diff every ten seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDiff(formatTimeAgo(eventTime));
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div className={styles.wrap}>
      <div className={styles["content-wrap"]}>
        <div className={styles["avatar"]}>
          <Avatar user={avatarUser} />
        </div>
        <div className={styles["event-container"]}>
          <div className={styles["event-meta"]}>
            <div className={styles["event-title"]}>
              <div>{eventTitle}</div>
            </div>
            <div className={styles["event-time"]}>{timeDiff}</div>
          </div>
          {eventDescription && (
            <div className={styles["event-description"]}>
              {eventDescription}
            </div>
          )}
        </div>
        <button
          data-testid="5fa4af8af7ad"
          onClick={onCloseRequested}
          className={styles["close-button"]}
        ></button>
      </div>
    </div>
  );
};
