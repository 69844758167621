import React from "react";
import classNames from "classnames";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { getCombinedApolloErrorMessage } from "../../services/getCombinedApolloErrorMessage";
import { Loading } from "../Loading/Loading";
import {
  DEFAULT_UPLOAD_FILE_SIZE_MAX_LIMIT,
  DEFAULT_UPLOAD_FILE_SIZE_MIN_LIMIT,
  DEFAULT_UPLOAD_MIME_TYPES,
} from "../../constants";
import styles from "./Attachments.module.scss";

export interface AttachmentProps extends DropzoneOptions {
  rejectTypeText?: React.ReactNode;
  rejectTooSmallText?: React.ReactNode;
  rejectTooLargeText?: React.ReactNode;
  error?: Error;
  loading?: boolean;
  secondaryIconColor?: boolean;
  className?: string;
  secondary?: boolean;
  title?: string;
}

export const Attachments: React.FC<AttachmentProps> = ({
  rejectTypeText,
  rejectTooSmallText,
  rejectTooLargeText,
  error,
  loading,
  secondary,
  secondaryIconColor,
  children,
  minSize = DEFAULT_UPLOAD_FILE_SIZE_MIN_LIMIT,
  maxSize = DEFAULT_UPLOAD_FILE_SIZE_MAX_LIMIT,
  multiple,
  className,
  title,
  accept = DEFAULT_UPLOAD_MIME_TYPES,
  ...options
}) => {
  // setup dropzone
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    draggedFiles,
    fileRejections,
  } = useDropzone({
    minSize,
    maxSize,
    accept,
    ...options,
  });

  // resolve combined error message
  const errorMessage = error ? getCombinedApolloErrorMessage(error) : undefined;

  // resove rejection state and reason
  const isRejectedMultiple = draggedFiles.length > 1 && multiple === false;
  const isRejected = fileRejections.length > 0 || isRejectedMultiple;
  const isRejectedTooSmall =
    minSize &&
    fileRejections.some((rejection) => rejection.file.size < minSize);
  const isRejectedTooLarge =
    maxSize &&
    fileRejections.some((rejection) => rejection.file.size > maxSize);
  const isTypeRejected =
    isRejected &&
    !isRejectedTooSmall &&
    !isRejectedTooLarge &&
    !isRejectedMultiple;

  // resolve texts to show
  const rejectText = isRejectedMultiple
    ? "Please provide a single file"
    : isTypeRejected
    ? rejectTypeText || "Please provide valid file"
    : isRejectedTooSmall
    ? rejectTooSmallText || "Provided file is too small"
    : isRejectedTooLarge
    ? rejectTooLargeText || "Provided file is too large"
    : "";

  // render dropzone
  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(
          styles.dropzone,
          {
            [styles["dropzone--collaboration-feed"]]: secondaryIconColor,
            [styles["dropzone--loading"]]: loading,
            [styles["dropzone--rejected"]]: isRejected,
            [styles["dropzone--with-error"]]:
              errorMessage !== undefined && !isDragActive,
            [styles["dropzone--active"]]: isDragActive && !isRejected,
            [styles["dropzone--secondary"]]: secondary,
          },
          className,
        )}
      >
        {title ? title : "ADD FILES"}
      </div>
      <input {...getInputProps()} />
      {isRejected ? (
        <div
          className={classNames(styles["warning"], {
            [styles["warning--collaboration-feed"]]: secondaryIconColor,
          })}
        >
          {rejectText}
        </div>
      ) : errorMessage ? (
        <div className={styles.error}>{errorMessage}</div>
      ) : loading ? (
        <Loading />
      ) : (
        children
      )}
    </>
  );
};
