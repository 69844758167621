import React from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { Button } from "../Button/Button";
import { Field } from "../Field/Field";
import { PlusIcon } from "../../theme/svg/PlusIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { useReadOnlyContext } from "../../contexts/readonly-context";
import styles from "./InputList.module.scss";

export interface InputListProps {
  className?: string;
}

export const InputList: React.FC<InputListProps> = (props) => {
  const { className } = props;

  // use read only context
  const isReadOnly = useReadOnlyContext();

  const { register, errors } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    name: "generalQuestions",
  });

  const handleAppend = () => {
    append({ question: "" });
  };

  return (
    <div className={classNames(styles.wrap, className)}>
      <ul>
        {fields.map((field, index) => {
          return (
            <div key={field.id} className={styles.item}>
              <span className={styles.number}>{`${index + 1}.`}</span>
              <Controller
                as={Field}
                defaultValue={field ? field?.question : ""}
                readOnly={isReadOnly}
                className={styles.input}
                name={`generalQuestions[${index}].question`}
                inputRef={register({
                  required: "Please fill or remove this field",
                })}
                errors={errors?.questions
                  ?.filter(
                    (_error: typeof errors, idx: number) => idx === index,
                  )
                  .map((error: typeof errors) => error.question.message)}
              />

              {isReadOnly ? null : (
                <Button
                  icon={<RemoveIcon />}
                  className={styles.clear}
                  onClick={() => remove(index)}
                />
              )}
            </div>
          );
        })}
      </ul>

      {isReadOnly ? null : (
        <div className={styles.add}>
          <Button quentary icon={<PlusIcon />} onClick={handleAppend}>
            Add question
          </Button>
        </div>
      )}
    </div>
  );
};
