export function matches(value: string, text: string | null | undefined) {
  if (typeof text !== "string") {
    return false;
  }

  const valueRef = value.trim().toLowerCase();
  const textRef = text.trim().toLowerCase();

  return textRef.includes(valueRef);
}
