import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CreateRequestorMutationVariables } from "../../schema";
import { splitFullName } from "../../services/splitFullName";
import { isEmail } from "../../validators/isEmail";
import { Button } from "../Button/Button";
import { Field } from "../Field/Field";
import styles from "./RequestorInput.module.scss";

interface RequestorInputProps {
  isCreateRequestorLoading: boolean;
  requesterTempName: string;
  setAreRequestorFieldsActive: React.Dispatch<React.SetStateAction<boolean>>;
  submitCreatingRequestor: (
    values: Pick<
      CreateRequestorMutationVariables,
      "firstName" | "lastName" | "email"
    >,
  ) => Promise<void>;
}

export const RequestorInput: React.FC<RequestorInputProps> = ({
  isCreateRequestorLoading,
  requesterTempName,
  setAreRequestorFieldsActive,
  submitCreatingRequestor,
}) => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, register, errors, setValue } = useFormContext<
    CreateRequestorMutationVariables
  >();

  useEffect(() => {
    if (!requesterTempName) return;

    // get first name and last name & fill below fields
    const [firstName, lastName] = splitFullName(requesterTempName);
    if (firstName) setValue("firstName", firstName);
    if (lastName) setValue("lastName", lastName);
    emailRef.current?.focus();
  }, [requesterTempName, setValue]);

  return (
    <>
      <div className={styles["names"]}>
        <Field
          name="firstName"
          short
          label="First name"
          required
          inputRef={register({ required: true })}
        />
        <Field
          name="lastName"
          short
          label="Last name"
          required
          inputRef={register({ required: true })}
        />
      </div>
      <div className={styles["email"]}>
        <Field
          name="email"
          label="Email"
          autofocus
          required
          inputRef={(e: HTMLInputElement) => {
            emailRef.current = e;
            register(e, {
              required: true,
              validate: (value) => isEmail(value),
            });
          }}
          errors={errors.email && ["Please enter valid email"]}
        />
      </div>
      <div className={styles["buttons"]}>
        <Button
          secondary
          onClick={() => {
            setAreRequestorFieldsActive(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(submitCreatingRequestor)}
          loading={isCreateRequestorLoading}
          disabled={isCreateRequestorLoading}
        >
          Save
        </Button>
      </div>
    </>
  );
};
