import { isEmpty } from "ramda";
import { ValidatorFn } from "../lib/react-apollo-hooks-form";

// https://github.com/segmentio/is-url
export const isUrl: ValidatorFn = (value) => {
  // do not check the empty value
  if (isEmpty(value)) {
    return;
  }
  const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;

  const invalidUrlError = "Please enter valid url";

  if (typeof value !== "string") {
    return invalidUrlError;
  }

  if (!localhostDomainRE.test(value) && !nonLocalhostDomainRE.test(value)) {
    return invalidUrlError;
  }
};
