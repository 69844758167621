import React from "react";
import { sanitize } from "dompurify";

export function getSafeHtmlComponent(
  dirtyHtmlString: string,
  className?: string,
) {
  let safeHtml = sanitize(dirtyHtmlString, { ALLOWED_ATTR: ["style"] });

  safeHtml = safeHtml.replace(/\n/g, "<br />");

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: safeHtml }} />
  );
}
