import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { ViewerOrganizations } from "../../schema";
import { CompanyLogo } from "../CompanyLogo/CompanyLogo";
import styles from "./OrganizationChoice.module.scss";

export interface Organization {
  id: string;
  companyName: string;
  urlName: string;
  companyLogoUrl: string | null;
}

export interface OrganizationChoiceProps {
  organizations: Organization[];
  activeOrganization: ViewerOrganizations;
}

export const OrganizationChoice: React.FC<OrganizationChoiceProps> = ({
  organizations,
  activeOrganization,
}) => {
  // handle dropdown open/closed state
  const [isOpen, setIsOpen] = React.useState(false);

  // don't render anything if the user does not belong to any organizations (should not really happen)
  if (organizations.length === 0) {
    return null;
  }

  // render just the active organization if the user only belongs to a single organization
  if (organizations.length === 1) {
    return (
      <div data-testid="organization" className={styles["organization"]}>
        {activeOrganization.companyLogoUrl ? (
          <CompanyLogo
            url={activeOrganization.companyLogoUrl}
            alt={activeOrganization.companyName}
            title={activeOrganization.companyName}
          />
        ) : (
          activeOrganization.companyName
        )}
      </div>
    );
  }

  // render organization choice dropdown if user belongs to multiple organizations
  return (
    <Dropdown
      data-testid="43e559255d"
      onCloseRequested={() => setIsOpen(false)}
      className={styles["organization-choice"]}
    >
      <DropdownSelector
        hasArrow
        className={styles.selector}
        onClick={() => setIsOpen(!isOpen)}
      >
        {activeOrganization.companyLogoUrl ? (
          <CompanyLogo
            url={activeOrganization.companyLogoUrl}
            alt={activeOrganization.companyName}
            title={activeOrganization.companyName}
          />
        ) : (
          activeOrganization.companyName
        )}
      </DropdownSelector>
      <DropdownContent
        right
        open={isOpen}
        className={styles.content}
        onCloseRequested={() => setIsOpen(false)}
      >
        {organizations.map((organization) => (
          <DropdownItem key={organization.id} onClick={() => setIsOpen(false)}>
            <Link
              data-testid="1bb81dd1ff"
              to={`/${organization.urlName}`}
              onClick={() => setIsOpen(false)}
            >
              {organization.companyName}
            </Link>
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};
