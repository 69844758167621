import React from "react";
import classNames from "classnames";
import { CardStatusLevelEnum } from "../../schema";
import styles from "./Status.module.scss";

export interface CardStatus {
  level: CardStatusLevelEnum;
  text: string;
}

export interface StatusProps extends CardStatus {
  secondary?: boolean;
  className?: string;
}

export const Status: React.FC<StatusProps> = ({
  level,
  text,
  secondary,
  className,
}) => {
  return (
    <span
      className={classNames(
        styles.status,
        {
          [styles["status--secondary"]]: secondary,
          [styles["status--default"]]: level === CardStatusLevelEnum.DEFAULT,
          [styles["status--alert"]]: level === CardStatusLevelEnum.ALERT,
          [styles["status--warning"]]: level === CardStatusLevelEnum.WARNING,
          [styles["status--info"]]: level === CardStatusLevelEnum.INFO,
          [styles["status--success"]]: level === CardStatusLevelEnum.SUCCESS,
          [styles["status--pink"]]: level === CardStatusLevelEnum.PINK,
        },
        className,
      )}
      title={text}
    >
      {secondary !== true ? text : null}
    </span>
  );
};
