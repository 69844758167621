import React, { useEffect } from "react";
import { ApolloError } from "apollo-client";
import classNames from "classnames";
import { SuggestionDataItem } from "react-mentions";
import { useCardContextInfo } from "../../contexts/card-context";
import { CardTypeEnum, useMarkInboundEmailReadStatus } from "../../schema";
import { delay } from "../../services/delay";
import { formatDatetime } from "../../services/formatDatetime";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { AttachmentIcon } from "../../theme/svg/AttachmentIcon";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { UpArrowIcon } from "../../theme/svg/UpArrowIcon";
import { Avatar } from "../Avatar/Avatar";
import { Button } from "../Button/Button";
import { EmailReplyForm } from "../EmailReplyForm/EmailReplyForm";
import { RequesterEmailReplyForm } from "../EmailReplyForm/RequesterEmailReplyForm";
import styles from "./Email.module.scss";

export interface EmailProps {
  id: string;
  fromUser: {
    id: string;
    email: string;
    avatarUrl?: string;
    firstName: string;
    lastName: string;
  };
  ccUser?: { firstName?: string; lastName?: string; email: string };
  subject?: string;
  ccEmail?: string | null;
  createdDate: Date;
  bodyText: React.ReactNode;
  attachments?: { id?: string; filename: string; url: string | null }[];
  addressBookEmails?: SuggestionDataItem[];
  className?: string;
  showReplyForm?: boolean;
  hasBeenRead?: boolean;
  displayNarrowView?: boolean;
  isInitiallyOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  onReplyCancel?: () => void;
  onReplySuccess?: (email: string) => void;
  onReplyError?: (email: string | ApolloError) => void;
}

export const Email: React.FC<EmailProps> = (props) => {
  const {
    id,
    fromUser,
    subject,
    ccEmail,
    addressBookEmails,
    createdDate,
    ccUser,
    bodyText,
    attachments,
    className,
    showReplyForm = false,
    hasBeenRead = false,
    displayNarrowView = false,
    isInitiallyOpen = false,
    onToggle,
    onReplyCancel,
    onReplySuccess,
    onReplyError,
  } = props;

  const [isEmailOpen, setIsEmailOpen] = React.useState(isInitiallyOpen);
  const [isReplyFormOpen, setIsReplyFormOpen] = React.useState(false);
  const [isReplyWithCc, setIsReplyWithCc] = React.useState(false);
  const [hasBeenReadLocal, setHasBeenReadLocal] = React.useState(hasBeenRead);

  // update read local state if external state changes
  React.useEffect(() => {
    setHasBeenReadLocal(hasBeenRead);
  }, [hasBeenRead]);

  const [markInboundEmailReadStatus] = useMarkInboundEmailReadStatus();

  // use card context
  const card = useCardContextInfo();

  // handle reply callbacks
  const handleReplyCancel = () => {
    setIsReplyFormOpen(false);
    setIsReplyWithCc(false);

    if (typeof onReplyCancel === "function") {
      onReplyCancel();
    }
  };

  const handleReplySuccess = (email: string) => {
    setIsReplyFormOpen(false);
    setIsEmailOpen(false);
    setIsReplyWithCc(false);

    if (typeof onReplySuccess === "function") {
      onReplySuccess(email);
    }
  };

  const handleReplyError = (error: string | ApolloError) => {
    if (typeof onReplyError === "function") {
      onReplyError(error);
    }
  };

  const handleHasBeenRead = async () => {
    if (!hasBeenRead) {
      // wait before marking read
      await delay(1000);

      // update local state
      setHasBeenReadLocal(true);

      // and also persist
      markInboundEmailReadStatus({
        variables: { inboundEmailId: id, hasBeenRead: true },
      });
    }
  };

  const handleToggle = () => {
    setIsEmailOpen(!isEmailOpen);
    setIsReplyFormOpen(false);
    handleHasBeenRead();
    onToggle && onToggle(!isEmailOpen);
  };

  // If the prop isInitiallyOpen is truthy, we need to trigger the function to set email read
  useEffect(() => {
    if (isInitiallyOpen) {
      handleHasBeenRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitiallyOpen]);

  if (!card) {
    return null;
  }

  return (
    <div className={classNames(styles["wrap"], className)}>
      <div className={styles["avatar"]} onClick={handleToggle}>
        <Avatar user={fromUser} />
      </div>

      <div
        className={classNames(styles["info-container"], {
          [styles["info-container--hasBeenRead"]]: hasBeenReadLocal,
        })}
        onClick={handleToggle}
      >
        <div className={styles["info-details"]}>
          <div
            className={classNames(styles["from-address"], {
              [styles["from-address--narrow"]]: displayNarrowView,
            })}
          >
            {displayNarrowView
              ? `${fromUser.firstName} ${fromUser.lastName}:`
              : `${fromUser.firstName} ${fromUser.lastName} <${fromUser.email}>:`}
          </div>
          <div className={styles["from-subject"]}>{subject ?? ""}</div>
          {attachments && attachments.length > 0 && (
            <AttachmentIcon className={styles["has-attachments"]} />
          )}
        </div>
      </div>

      <div
        className={classNames(styles["created-time-ago"], {
          [styles["created-time-ago--hasBeenRead"]]: hasBeenReadLocal,
        })}
        onClick={handleToggle}
      >
        {!displayNarrowView && formatTimeAgo(createdDate)}
      </div>

      <div className={styles["toggle-cell"]}>
        <Button
          className={styles["toggle-button"]}
          onClick={handleToggle}
          icon={isEmailOpen ? <DownArrowIcon /> : <UpArrowIcon />}
        />
      </div>

      {isEmailOpen && (
        <>
          <div className={styles["guideline"]}></div>

          <div className={styles["body"]}>
            {ccUser && (
              <div className={styles["cc-container"]}>
                <div className={styles["field-title"]}>Cc</div>
                <div className={styles["field-value"]}>
                  {`${ccUser.firstName ?? ""} ${ccUser.lastName ?? ""} <${
                    ccUser?.email
                  }>`}
                </div>
              </div>
            )}
            <div className={styles["info-sent-time"]}>
              {displayNarrowView
                ? formatTimeAgo(createdDate)
                : formatDatetime(createdDate)}
            </div>
            <div className={styles["subject-container"]}>
              <div className={styles["field-title"]}>Subject</div>
              <div className={styles["field-value"]}>{subject}</div>
            </div>
            {ccEmail && (
              <div className={styles["subject-container"]}>
                <div className={styles["field-"]}>
                  Cc: {ccEmail.split(",")[0]}
                </div>
              </div>
            )}
            <div className={styles["body-text"]}>{bodyText}</div>
            {attachments &&
              attachments.length > 0 &&
              attachments.map((attachment) =>
                attachment.url ? (
                  <a
                    key={attachment.url}
                    href={attachment.url}
                    className={styles["attachments-list"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>{attachment.filename}</div>
                    <OpenExternalIcon
                      className={styles["open-external-icon"]}
                    />
                  </a>
                ) : null,
              )}
            {showReplyForm && !isReplyFormOpen && (
              <div>
                <Button
                  className={styles["email-actions"]}
                  text
                  onClick={() => {
                    setIsReplyWithCc(false);
                    setIsReplyFormOpen(true);
                  }}
                >
                  Reply
                </Button>
                {ccEmail && (
                  <Button
                    className={styles["email-actions"]}
                    text
                    onClick={() => {
                      setIsReplyWithCc(true);
                      setIsReplyFormOpen(true);
                    }}
                  >
                    Reply all
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      )}

      {showReplyForm &&
        isReplyFormOpen &&
        card.type !== CardTypeEnum.PURCHASE_REQUEST && (
          <>
            <EmailReplyForm
              className={styles["reply-form"]}
              parentId={id}
              subject={`RE: ${subject}`}
              defaultCcEmails={ccEmail && isReplyWithCc ? ccEmail : undefined}
              receiver={{
                id: fromUser.id,
                firstName: fromUser.firstName,
                lastName: fromUser.lastName,
                email: fromUser.email,
              }}
              addressBookEmails={addressBookEmails}
              onSendSuccess={handleReplySuccess}
              onSendError={handleReplyError}
              onCancel={handleReplyCancel}
              displayNarrowView={displayNarrowView}
            />
          </>
        )}

      {showReplyForm &&
        isReplyFormOpen &&
        card.type === CardTypeEnum.PURCHASE_REQUEST && (
          <>
            <RequesterEmailReplyForm
              className={styles["reply-form"]}
              parentId={id}
              subject={`RE: ${subject}`}
              defaultCcEmails={ccEmail && isReplyWithCc ? ccEmail : undefined}
              receiver={{
                id: fromUser.id,
                firstName: fromUser.firstName,
                lastName: fromUser.lastName,
                email: fromUser.email,
              }}
              addressBookEmails={addressBookEmails}
              onSendSuccess={handleReplySuccess}
              onSendError={handleReplyError}
              onCancel={handleReplyCancel}
              displayNarrowView={displayNarrowView}
            />
          </>
        )}
    </div>
  );
};
