import { AutosuggestItem } from "../components/ItemAutosuggest/ItemAutosuggest";
import { PurchaseRequestByCodeCostCentres } from "../schema";

export function costCentreToAutosuggestItem(
  costCentre: PurchaseRequestByCodeCostCentres,
): AutosuggestItem {
  return {
    id: costCentre.id,
    name: costCentre.name,
  };
}
