import React, { useCallback } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Container } from "../../components/Container/Container";
import { SupplierIcon } from "../../theme/svg/SupplierIcon";
import { ValuePair } from "../../components/ValuePair/ValuePair";
import { NotAvailable } from "../../components/NotAvailable/NotAvailable";
import { View } from "../../components/View/View";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { ViewerViewer, useUserUpdateProfilePicture } from "../../schema";
import { Avatar } from "../../components/Avatar/Avatar";
import { useRouter } from "../../hooks/useRouter";
import { UpdateUserProfile } from "./UpdateUserProfile";
import styles from "./UserProfileView.module.scss";

export interface UserProfileViewProps {
  viewer: ViewerViewer;
}

export const UserProfileView: React.FC<UserProfileViewProps> = ({ viewer }) => {
  const { history } = useRouter();

  // setup updating profile picture
  const [
    userUpdateProfilePicture,
    { loading, error },
  ] = useUserUpdateProfilePicture({
    refetchQueries: ["UsersManagement", "Viewer"],
    awaitRefetchQueries: true,
  });

  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        return;
      }

      userUpdateProfilePicture({
        variables: {
          profilePicture: acceptedFiles[0],
        },
      });
    },
    [userUpdateProfilePicture],
  );

  const userCountry = viewer.countries.find(
    (country) => country.code === viewer.countryCode,
  );

  return (
    <>
      <View secondary>
        <h1>
          {viewer.firstName} {viewer.lastName}
        </h1>

        <div className={styles["section-wrap"]}>
          <div className={styles.contacts}>
            <Container
              small
              icon={<SupplierIcon />}
              title="My contacts"
              addon={
                <Link
                  data-testid="c13a15f17c"
                  to={`/my-info/edit-user`}
                  className={styles.edit}
                >
                  Edit
                </Link>
              }
            >
              <ValuePair
                name="Name"
                value={
                  (
                    <>
                      {viewer.firstName} {viewer.lastName}
                    </>
                  ) || <NotAvailable />
                }
              />
              <ValuePair
                name="Email"
                value={viewer.email || <NotAvailable />}
              />
              <ValuePair
                name="Phone"
                value={viewer.phoneNumber || <NotAvailable />}
              />
              <ValuePair
                name="Address"
                value={viewer.address || <NotAvailable />}
              />
              <ValuePair name="City" value={viewer.city || <NotAvailable />} />
              <ValuePair
                name="ZIP / postal"
                value={viewer.areaCode || <NotAvailable />}
              />
              <ValuePair
                name="Country"
                value={userCountry ? userCountry.name : <NotAvailable />}
              />
            </Container>
          </div>

          <div className={styles["side-section"]}>
            <Container title={"My profile picture"}>
              <Dropzone
                multiple={false}
                loading={loading}
                error={error}
                label="Profile picture"
                accept={["image/jpeg", "image/png"]}
                rejectTypeText="Please provide valid image (.jpg or .png)"
                onDrop={onDrop}
              >
                {viewer.avatarUrl === null ? (
                  "Drag file here"
                ) : (
                  <Avatar extraLarge user={viewer} />
                )}
              </Dropzone>
            </Container>

            <Container title="My e-mail templates"></Container>

            <Container title="My planned activities"></Container>
            <Container title="My past activities"></Container>
          </div>
        </div>
      </View>
      <Switch>
        <Route exact path={"/my-info/edit-user"}>
          <UpdateUserProfile
            viewer={viewer}
            id={viewer.id}
            firstName={viewer.firstName}
            lastName={viewer.lastName}
            countries={viewer.countries}
            onModalClose={() => history.push("/my-info")}
          />
        </Route>
      </Switch>
    </>
  );
};
