/**
 * Given an argument that can be `undefined` this type guard function checks
 * the value and returns `true` as a *value* if the value is not `undefined` and
 * narrows also the *type* of the argument to be without `undefined`.
 *
 *
 * @example
 *
 * let x: string | undefined;
 *
 * if (isDefined(x)) {
 *   x; // x: string
 * }
 *
 */
export function isDefined<T>(arg: T | undefined): arg is T {
  return typeof arg !== "undefined";
}
