import React from "react";
import classNames from "classnames";
import { View } from "../../components/View/View";
import { Loading } from "../../components/Loading/Loading";
import { Overlay } from "../../components/Overlay/Overlay";
import styles from "./LoadingView.module.scss";

export interface LoadingViewProps {
  overlay?: boolean;
  className?: string;
}

export const LoadingView: React.FC<LoadingViewProps> = ({
  overlay,
  className,
}) => {
  if (overlay) {
    return (
      <Overlay>
        <Loading large />
      </Overlay>
    );
  }

  return (
    <View className={classNames(styles["loading-view"], className)}>
      <Loading large />
    </View>
  );
};
