import React from "react";
import { Route, Switch } from "react-router";
import { Button } from "../../components/Button/Button";
import { Cell, BasicTable } from "../../components/BasicTable/BasicTable";
import { alertNotImplemented } from "../../services/alertNotImplemented";
import { EditIcon } from "../../theme/svg/EditIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { useRouter } from "../../hooks/useRouter";
import { Row } from "../../components/Row/Row";
import { formatDatetime } from "../../services/formatDatetime";
import { OrganizationsManagementAdmin } from "../../schema";
import { CreateOrganization } from "./CreateOrganization";
import { EditOrganization } from "./EditOrganization";
import styles from "./AdminView.module.scss";

interface OrganizationsManagementProps {
  organizations?: OrganizationsManagementAdmin["organizations"];
  countries?: OrganizationsManagementAdmin["countries"];
  currencies?: OrganizationsManagementAdmin["currencies"];
}

export const OrganizationsManagement: React.FC<OrganizationsManagementProps> =
  ({ organizations, countries, currencies }) => {
    const { history } = useRouter();

    return (
      <div>
        <Row secondary>
          <Row secondary>
            <h1>Organization management</h1>
            <Button
              data-testid="80c9921dfe"
              add
              title="Add new organization"
              onClick={() =>
                history.push("/admin/organizations/add-new-organization")
              }
              className={styles["add-button"]}
            />
          </Row>
        </Row>
        <BasicTable>
          <thead>
            <tr>
              <Cell secondary nowrap header>
                Organization
              </Cell>
              <Cell secondary nowrap header>
                Address
              </Cell>
              <Cell secondary nowrap header center compact>
                City
              </Cell>
              <Cell secondary nowrap header compact>
                Area code
              </Cell>
              <Cell secondary nowrap header center compact>
                Country
              </Cell>

              <Cell secondary nowrap header center compact>
                Created date
              </Cell>
              <Cell secondary nowrap header center compact>
                Updated date
              </Cell>
              <Cell secondary nowrap header center compact>
                Status
              </Cell>
              <Cell compact />
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations.map((organization) => (
                <tr key={organization.id}>
                  <Cell secondary strong>
                    {organization.companyName} ({organization.urlName})
                  </Cell>
                  <Cell secondary>{organization.address}</Cell>
                  <Cell secondary center>
                    {organization.city}
                  </Cell>
                  <Cell secondary>{organization.areaCode}</Cell>
                  <Cell secondary center>
                    <img
                      src={`https://flagcdn.com/w20/${organization.countryCode.toLowerCase()}.png`}
                      alt={organization.countryCode}
                      title={organization.countryCode}
                    />
                  </Cell>
                  <Cell secondary center>
                    {formatDatetime(organization.createdDate)}
                  </Cell>
                  <Cell secondary center>
                    {formatDatetime(organization.updatedDate)}
                  </Cell>
                  <Cell secondary center>
                    {organization.isActive ? "active" : "inactive"}
                  </Cell>
                  <Cell secondary>
                    <Row>
                      <Button
                        data-testid="9d3e2cf21e"
                        icon={<EditIcon className={styles["button-icon"]} />}
                        onClick={() =>
                          history.push(
                            `/admin/organizations/${organization.id}`,
                          )
                        }
                      />
                      <Button
                        data-testid="1963583e0f"
                        icon={<RemoveIcon className={styles["button-icon"]} />}
                        onClick={() => alertNotImplemented()}
                      />
                    </Row>
                  </Cell>
                </tr>
              ))}
          </tbody>
        </BasicTable>
        <Switch>
          <Route path={"/admin/organizations/add-new-organization"}>
            <CreateOrganization
              countries={countries}
              currencies={currencies}
              onModalClose={() => history.push("/admin/organizations")}
            />
          </Route>
        </Switch>
        {organizations &&
          organizations.map((organization) => (
            <Switch key={organization.id}>
              <Route path={`/admin/organizations/${organization.id}`}>
                <EditOrganization
                  countries={countries}
                  currencies={currencies}
                  organizationId={organization.id}
                  onModalClose={() => history.push("/admin/organizations")}
                />
              </Route>
            </Switch>
          ))}
      </div>
    );
  };
