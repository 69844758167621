import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import styles from "./AccordionSecondary.module.scss";

export interface AccordionProps {
  secondary?: boolean;
  initiallyOpen?: boolean;
  isClosable?: boolean;
  title?: string | React.ReactNode;
  small?: boolean;
}

export const AccordionSecondary: React.FC<AccordionProps> = ({
  secondary,
  initiallyOpen = true,
  isClosable = true,
  title,
  children,
  small,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(initiallyOpen);
  const [isAccordionClosable, setIsAccordionClosable] = useState(isClosable);
  const [isSmallView, setIsSmallView] = useState(false);

  // rerender prop if initiallyOpen changed
  useEffect(() => {
    setIsAccordionOpen(initiallyOpen);
  }, [initiallyOpen]);

  // set state if prop changes
  useEffect(() => {
    setIsAccordionClosable(isClosable);
  }, [isClosable]);

  // Secondary allow toggle accordion only in small view
  useEffect(() => {
    const width =
      "innerWidth" in window
        ? window.innerWidth
        : document.documentElement.offsetWidth;
    setIsSmallView(width < 900);
  }, []);

  const toggleOpen = () => {
    if (isAccordionClosable) setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div
      className={classNames(styles["accordion"], {
        [styles["accordion--open"]]: isAccordionOpen,
        [styles["accordion--secondary"]]: secondary,
        [styles["accordion--small"]]: small,
      })}
    >
      <div
        className={styles.title}
        onClick={() => {
          if (secondary) {
            if (isAccordionClosable && isSmallView) {
              toggleOpen();
            }
          } else {
            toggleOpen();
          }
        }}
      >
        <>
          {typeof title === "string" ? <h3>{title}</h3> : title}
          <DownArrowIcon
            className={classNames(styles.arrow, {
              [styles["arrow--open"]]: isAccordionOpen,
            })}
          />
        </>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
