export function initializeCrispCustomerChat(id?: string) {
  if (!id) {
    return;
  }

  window.$crisp = [];

  window.CRISP_WEBSITE_ID = id;

  (function () {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = true;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
}
