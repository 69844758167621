export function formatFilesize(bytes: number, fixed = 1): string {
  bytes = Math.abs(bytes);

  const RADIX = 1e3;
  const UNITS = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let loop = 0;

  // calculate
  while (bytes >= RADIX) {
    bytes /= RADIX;
    ++loop;
  }
  return `${bytes.toFixed(fixed)} ${UNITS[loop]}`;
}
