import React from "react";
import { ViewerViewer } from "../schema";

export const ViewerContext = React.createContext<ViewerViewer | undefined>(
  undefined,
);

export interface ViewerProviderProps {
  viewer: ViewerViewer;
  children?: React.ReactNode;
}

export function ViewerContextProvider({
  viewer,
  children,
}: ViewerProviderProps) {
  return (
    <ViewerContext.Provider value={viewer}>{children}</ViewerContext.Provider>
  );
}

/**
 * Convenient use context hook to be used inside ViewerContextProvider
 *
 * @returns React.useContext(ViewerContext)
 *
 * @example  const viewer = useViewer()
 */
export function useViewer() {
  return React.useContext(ViewerContext);
}
