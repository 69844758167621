import {
  BaseDraggable,
  BaseDroppable,
  DraggableType,
  SwimlaneInfo,
  getCardSwimlane,
} from "../components/Swimlane/Swimlane";

import {
  AddPurchaseRequestItemsToRfxMutation,
  AddPurchaseRequestItemsToRfxVariables,
  CardPositionMethodEnum,
  CardTypeEnum,
  CreateOrderFromRfxMutation,
  CreateOrderFromRfxVariables,
  CreateRfxFromPurchaseRequestMutation,
  CreateRfxFromPurchaseRequestVariables,
  OrderTypeEnum,
  UpdateCardPositionMutation,
  UpdateCardPositionVariables,
  UpdateOrderTypeMutation,
  UpdateOrderTypeVariables,
  CreateOrderFromPurchaseRequestMutation,
  CreateOrderFromPurchaseRequestVariables,
  CreateOrderFromPurchaseRequestItemsMutation,
  CreateOrderFromPurchaseRequestItemsVariables,
  AddPurchaseRequestItemsToOrderMutation,
  AddPurchaseRequestItemsToOrderVariables,
} from "../schema";

import { CardInfo } from "../components/Card/Card";

import { CardItemInfo } from "../components/CardItem/CardItem";
import { SwimlaneId } from "../constants";
import { MutationFn } from "../lib/react-apollo-hooks-form";
import { sendGoogleAnalyticsEvent } from "./sendGoogleAnalyticsEvent";

// parameters for persisting drag operations
export interface PersistDragResultParameters {
  from: BaseDroppable;
  to: BaseDroppable;
  draggedItem: BaseDraggable;
  relativeItem: BaseDraggable | undefined;
  relativeMethod: CardPositionMethodEnum;
  swimlanes: SwimlaneInfo[];
  mutations: {
    createRfxFromPurchaseRequest: MutationFn<
      CreateRfxFromPurchaseRequestMutation,
      CreateRfxFromPurchaseRequestVariables
    >;
    createOrderFromRfx: MutationFn<
      CreateOrderFromRfxMutation,
      CreateOrderFromRfxVariables
    >;
    createOrderFromPurchaseRequest: MutationFn<
      CreateOrderFromPurchaseRequestMutation,
      CreateOrderFromPurchaseRequestVariables
    >;
    createOrderFromPurchaseRequestItems: MutationFn<
      CreateOrderFromPurchaseRequestItemsMutation,
      CreateOrderFromPurchaseRequestItemsVariables
    >;
    updateOrderType: MutationFn<
      UpdateOrderTypeMutation,
      UpdateOrderTypeVariables
    >;
    addPurchaseRequestItemsToRfx: MutationFn<
      AddPurchaseRequestItemsToRfxMutation,
      AddPurchaseRequestItemsToRfxVariables
    >;
    addPurchaseRequestItemsToOrder: MutationFn<
      AddPurchaseRequestItemsToOrderMutation,
      AddPurchaseRequestItemsToOrderVariables
    >;
    updateCardPosition: MutationFn<
      UpdateCardPositionMutation,
      UpdateCardPositionVariables
    >;
  };
  setLoadingSwimlaneId(
    value: React.SetStateAction<SwimlaneId | undefined>,
  ): void;
}

export interface PersistKanbanDragResult {
  isSuccess: boolean;
  error?: string;
}

// attempts to persist drag result (asynchronous operation)
// returns true for success or string error message when the operation is not valid or failed
export async function persistKanbanDrag(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const { from, to, draggedItem, relativeItem, relativeMethod } = info;

  // handle creating rfx from purchase request
  if (from.id === SwimlaneId.PURCHASE_REQUEST && to.id === SwimlaneId.RFX) {
    sendGoogleAnalyticsEvent({
      category: to.id,
      action: `drag-card_${from.id}-${to.id}`,
      label: "Task dragged",
    });
    return persistCreateRfxFromPurchaseRequest(info);
  }

  // handle creating purchase order from rfx
  if (from.id === SwimlaneId.RFX && to.id === SwimlaneId.PURCHASE_ORDER) {
    sendGoogleAnalyticsEvent({
      category: to.id,
      action: `drag-card_${from.id}-${to.id}`,
      label: "Task dragged",
    });
    return persistCreateOrderFromRfx(info);
  }

  // handle creating purchase order from purchase request
  if (
    from.id === SwimlaneId.PURCHASE_REQUEST &&
    to.id === SwimlaneId.PURCHASE_ORDER
  ) {
    sendGoogleAnalyticsEvent({
      category: to.id,
      action: `drag-card_${from.id}-${to.id}`,
      label: "Task dragged",
    });
    return persistCreateOrderFromPurchaseRequest(info);
  }

  const orderSwimlanes: string[] = [
    SwimlaneId.PURCHASE_ORDER,
    SwimlaneId.RECEIVING,
    SwimlaneId.INVOICE,
  ];

  // handle dragging from one of the order swimlanes to another to change status
  if (
    to.id !== from.id &&
    orderSwimlanes.includes(from.id) &&
    orderSwimlanes.includes(to.id)
  ) {
    sendGoogleAnalyticsEvent({
      category: to.id,
      action: `drag-card_${from.id}-${to.id}`,
      label: "Task dragged",
    });
    return persistUpdateOrderType(info);
  }

  // handle dragging item to a card
  if (
    draggedItem.type === DraggableType.ITEM &&
    from.type === DraggableType.CARD
  ) {
    sendGoogleAnalyticsEvent({
      category: SwimlaneId.PURCHASE_REQUEST,
      action: `drag-item-to-card`,
      label: "Item dragged",
    });
    return persistAddPurchaseRequestItemsToCard(info);
  }

  // handle rearranging cards in a single swimlane
  if (from.id === to.id) {
    sendGoogleAnalyticsEvent({
      category: to.id,
      action: `rearrange-cards-within-swimlane`,
      label: "Cards rearrange",
    });
    return persistUpdateCardPosition(info);
  }

  // none of the above returned so a non-supported drag operation was attempted
  // TODO: remove once drag functionality is complete
  console.warn(
    `dragging card from ${from.id} to ${to.id} is not yet implemented`,
    {
      item: draggedItem,
      from,
      to,
      relativeItem,
      relativeMethod,
    },
  );

  return {
    isSuccess: false,
    error:
      "Requested transition is not valid, please follow the standard card status transition flow",
  };
}

async function persistCreateRfxFromPurchaseRequest(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const {
    to,
    draggedItem,
    relativeItem,
    relativeMethod,
    mutations,
    setLoadingSwimlaneId,
  } = info;

  // validate if it is legal to perform drag from current PR
  validatePurchaseRequestDraggingAttempt(draggedItem);

  // set the currently loading swimlane
  setLoadingSwimlaneId(to.id as SwimlaneId);

  try {
    await mutations.createRfxFromPurchaseRequest({
      variables: {
        purchaseRequestId: draggedItem.id,
        relativeRfxId: relativeItem ? relativeItem.id : null,
        method: relativeMethod,
      },
    });

    return { isSuccess: true };
  } catch (error) {
    console.warn("creating rfx from purchase request failed", error);

    // TODO: extract real reason from the error (requires improving server side validation)
    return {
      isSuccess: false,
      error: "Requested purchase request is not valid for creating RFX",
    };
  } finally {
    // clear currently loading swimlane
    setLoadingSwimlaneId(undefined);
  }
}

async function persistCreateOrderFromRfx(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const {
    to,
    draggedItem,
    relativeItem,
    relativeMethod,
    mutations,
    setLoadingSwimlaneId,
  } = info;

  // set the currently loading swimlane
  setLoadingSwimlaneId(to.id as SwimlaneId);

  try {
    await mutations.createOrderFromRfx({
      variables: {
        rfxId: draggedItem.id,
        relativeOrderId: relativeItem ? relativeItem.id : null,
        method: relativeMethod,
        rfxItemIds: null,
        supplierId: null,
        hideRfx: null,
        isCreatedFromSourcingEvent: null,
      },
    });

    return { isSuccess: true };
  } catch (error) {
    console.warn("creating order from rfx failed", error);

    // TODO: extract real reason from the error (requires improving server side validation)
    return {
      isSuccess: false,
      error: "Requested RFX is not valid for creating order",
    };
  } finally {
    // clear currently loading swimlane
    setLoadingSwimlaneId(undefined);
  }
}

async function persistCreateOrderFromPurchaseRequest(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const {
    to,
    draggedItem,
    relativeItem,
    relativeMethod,
    mutations,
    setLoadingSwimlaneId,
  } = info;

  // validate if it is legal to perform drag from current PR
  validatePurchaseRequestDraggingAttempt(draggedItem);

  // set the currently loading swimlane
  setLoadingSwimlaneId(to.id as SwimlaneId);

  try {
    await mutations.createOrderFromPurchaseRequest({
      variables: {
        purchaseRequestId: draggedItem.id,
        relativeOrderId: relativeItem ? relativeItem.id : null,
        method: relativeMethod,
      },
    });

    return { isSuccess: true };
  } catch (error) {
    console.warn("creating order from purchase request failed", error);

    // TODO: extract real reason from the error (requires improving server side validation)
    return {
      isSuccess: false,
      error: "Requested Purchase Request is not valid for creating order",
    };
  } finally {
    // clear currently loading swimlane
    setLoadingSwimlaneId(undefined);
  }
}

async function persistAddPurchaseRequestItemsToCard(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const { from, to, draggedItem, swimlanes, mutations, setLoadingSwimlaneId } =
    info;

  // we now know that we dragged an item from a card to another card
  const fromCard = from as CardInfo;
  const toCard = to as CardInfo;
  const item = draggedItem as CardItemInfo;
  const toSwimlane = getCardSwimlane(toCard.id, swimlanes);

  if (!toSwimlane) {
    throw new Error(
      "Swimlane for destination card could not be found, this should not happen",
    );
  }

  // make sure the parent card has correct status
  if (!["PR READY", "APPROVED", "IN PROGRESS"].includes(fromCard.status.text)) {
    return {
      isSuccess: false,
      error: `Unable to move given item to ${toSwimlane.title}, the Purchase Request needs to be ready`,
    };
  }

  // handle dragging item from PR card to RFX card (note that toCard.prefix can be one of RFX, RFI, RFQ, RFX)
  if (fromCard.prefix === "PR" && toCard.prefix.substring(0, 2) === "RF") {
    // only allow dragging an item from PR card to RFX card that is in DRAFT status
    if (!toCard.status.text.match(/.*draft/i)) {
      return {
        isSuccess: false,
        error: `Unable to move given item to RFX-${toCard.code} because it is already ongoing or completed`,
      };
    }
    // the item id is formatted as CARD_ID.ITEM_ID to make it unique, extract the actual purchase request item id
    const purchaseRequestItemId = item.id.split(".")[1];

    // set the currently loading swimlane
    setLoadingSwimlaneId(toSwimlane.id as SwimlaneId);

    try {
      // attempt to add requested purchase request item to given rfx
      await mutations.addPurchaseRequestItemsToRfx({
        variables: {
          rfxId: toCard.id,
          purchaseRequestItems: [purchaseRequestItemId],
        },
      });

      return { isSuccess: true };
    } catch (error) {
      console.warn("adding purchase request items to rfx failed", error);

      // TODO: extract real reason from the error (requires improving server side validation)
      return {
        isSuccess: false,
        error:
          "Requested purchase request item is not valid for adding to given RFX",
      };
    } finally {
      // clear currently loading swimlane
      setLoadingSwimlaneId(undefined);
    }
  }
  // handle dragging purchase request item to existing purchase order
  else if (
    fromCard.prefix === "PR" &&
    toCard.prefix === "PO" &&
    toSwimlane.id === SwimlaneId.PURCHASE_ORDER
  ) {
    const purchaseRequestItemId = item.id.split(".")[1];

    setLoadingSwimlaneId(toSwimlane.id as SwimlaneId);

    try {
      await mutations.addPurchaseRequestItemsToOrder({
        variables: {
          orderId: toCard.id,
          purchaseRequestItems: [purchaseRequestItemId],
        },
      });

      return { isSuccess: true };
    } catch (error) {
      console.warn("adding purchase request items to order failed", error);

      // TODO: extract real reason from the error (requires improving server side validation)
      return {
        isSuccess: false,
        error:
          "Requested purchase request item is not valid for adding to given order",
      };
    } finally {
      // clear currently loading swimlane
      setLoadingSwimlaneId(undefined);
    }
    //
  } else {
    // handle moving items in the same swimlane
    if (fromCard.prefix === toCard.prefix) {
      // handle moving items in the same card
      if (fromCard.id === toCard.id) {
        return {
          isSuccess: false,
          error: "Reordering card items is not yet implemented",
        };
      }

      // handle moving items between cards in the same swimlane
      return {
        isSuccess: false,
        error: "Moving items from one card to another is not yet supported",
      };
    }

    console.warn(
      `dragging ${fromCard.prefix} items to ${toCard.prefix} card is not yet supported`,
    );

    return {
      isSuccess: false,
      error: "Moving items between requested swimlanes is not valid",
    };
  }
}

async function persistUpdateOrderType(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const {
    to,
    draggedItem,
    relativeItem,
    relativeMethod,
    mutations,
    setLoadingSwimlaneId,
  } = info;

  // set the currently loading swimlane
  setLoadingSwimlaneId(to.id as SwimlaneId);

  try {
    await mutations.updateOrderType({
      variables: {
        orderId: draggedItem.id,
        type: getDraggedOrderType(to.id as SwimlaneId),
        relativeOrderId: relativeItem ? relativeItem.id : null,
        method: relativeMethod,
      },
    });

    return { isSuccess: true };
  } catch (error) {
    console.warn("updating order type failed", error);

    return {
      isSuccess: false,
      error:
        "Requested transition is not valid, please follow the standard card status transition flow",
    };
  } finally {
    // clear currently loading swimlane
    setLoadingSwimlaneId(undefined);
  }
}

async function persistUpdateCardPosition(
  info: PersistDragResultParameters,
): Promise<PersistKanbanDragResult> {
  // extract parameters
  const {
    from,
    draggedItem,
    relativeItem,
    relativeMethod,
    mutations,
    setLoadingSwimlaneId,
  } = info;

  if (!relativeItem) {
    throw new Error(
      "Updating card position should always be relative to some other card, this should not happen",
    );
  }

  // resolve card type from swimlane (to and from swimlane are the same)
  const cardType = getCardType(from.id as SwimlaneId);

  // set the currently loading swimlane
  setLoadingSwimlaneId(from.id as SwimlaneId);

  try {
    await mutations.updateCardPosition({
      variables: {
        itemId: draggedItem.id,
        relativeId: relativeItem.id,
        type: cardType,
        method: relativeMethod,
      },
    });

    return { isSuccess: true };
  } catch (error) {
    console.warn("updating card position failed", error);

    return {
      isSuccess: false,
      error: "Updating card position failed, please try again later",
    };
  } finally {
    // clear currently loading swimlane
    setLoadingSwimlaneId(undefined);
  }
}

function getDraggedOrderType(swimlaneId: SwimlaneId): OrderTypeEnum {
  switch (swimlaneId) {
    case SwimlaneId.PURCHASE_ORDER:
      return OrderTypeEnum.PURCHASE_ORDER;

    case SwimlaneId.RECEIVING:
      return OrderTypeEnum.RECEIVING;

    case SwimlaneId.INVOICE:
      return OrderTypeEnum.INVOICE;

    default:
      throw new Error(
        `Unable to resolve order type from swimlane "${swimlaneId}"`,
      );
  }
}

function getCardType(swimlaneId: SwimlaneId): CardTypeEnum {
  switch (swimlaneId) {
    case SwimlaneId.PURCHASE_REQUEST:
      return CardTypeEnum.PURCHASE_REQUEST;

    case SwimlaneId.RFX:
      return CardTypeEnum.RFX;

    case SwimlaneId.PURCHASE_ORDER:
    case SwimlaneId.RECEIVING:
    case SwimlaneId.INVOICE:
      return CardTypeEnum.ORDER;

    default:
      throw new Error(
        `Unable to resolve card type from swimlane "${swimlaneId}"`,
      );
  }
}

function validatePurchaseRequestDraggingAttempt(draggedItem: BaseDraggable) {
  // we know the dragged item must be a card
  const card = draggedItem as CardInfo;

  // handle the purchase request not having any items
  const hasItems = card.items.length > 0;

  if (!hasItems) {
    return {
      isSuccess: false,
      error:
        "Please add at least one item to the purchase request before creating RFX from it",
    };
  }

  // handle any of the card items having already moved on from purchase request
  const hasItemsNotInPr = card.items.some(
    (item) => item.parentCode.substring(0, 2) !== "PR",
  );

  if (hasItemsNotInPr) {
    return {
      isSuccess: false,
      error:
        "Requested purchase request has items that have already been used, please move the unused items separately (if any)",
    };
  }

  // don't allow creating RFX from PR that has invalid status
  // note that this needs to be updated if purchase requests status texts logic is changed (ideally should use enums)
  switch (card.status.text) {
    case "DRAFT":
      return {
        isSuccess: false,
        error:
          "Draft purchase request needs to me marked complete before creating RFX from it",
      };

    case "APPROVAL WORKFLOW DRAFT":
      return {
        isSuccess: false,
        error:
          "Please start and complete the approval workflow before creating RFX from given purchase request",
      };

    case "WAITING APPROVALS":
      return {
        isSuccess: false,
        error:
          "Please wait for the approval workflow to complete before creating RFX from given purchase request",
      };

    case "DECLINED":
      return {
        isSuccess: false,
        error:
          "Unable to create RFX, approval on requested purchase request has been declined",
      };
  }
}
