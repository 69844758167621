import React from "react";
import classNames from "classnames";
import styles from "./Row.module.scss";

export interface RowProps {
  secondary?: boolean;
  className?: string;
}

export const Row: React.FC<RowProps> = ({ children, secondary, className }) => (
  <div
    className={classNames(
      styles.row,
      { [styles["row--secondary"]]: secondary },
      className,
    )}
  >
    {children}
  </div>
);
