import React from "react";
import classNames from "classnames";
import { SourcingEventPublicQuery } from "../../schema";
import { formatDatetime } from "../../services/formatDatetime";
import { Avatar } from "../Avatar/Avatar";
import styles from "./SourcingEventPublicInfo.module.scss";

export interface SourcingEventPublicInfoProps {
  className?: string;
  sourcingEventPublicInfo: SourcingEventPublicQuery["sourcingEventPublic"];
}

export const SourcingEventPublicInfo: React.FC<SourcingEventPublicInfoProps> = (
  props,
) => {
  const { className, sourcingEventPublicInfo } = props;

  return (
    <div className={classNames(styles["wrap"], className)}>
      <h2 className={styles["title"]}>Requests for Quotations</h2>

      <div className={styles["event-name"]}>{sourcingEventPublicInfo.name}</div>

      <section className={styles["event-info"]}>
        <div className={styles["table"]}>
          <div className={styles["row"]}>
            <div className={classNames(styles["cell"], styles["first-col"])}>
              Sent:
            </div>
            <div className={classNames(styles["cell"], styles["second-col"])}>
              {formatDatetime(sourcingEventPublicInfo.updatedDate)}
            </div>
          </div>

          <div className={styles["row"]}>
            <div className={classNames(styles["cell"], styles["first-col"])}>
              Response Expected:
            </div>
            <div className={classNames(styles["cell"], styles["second-col"])}>
              {formatDatetime(sourcingEventPublicInfo.responseDeadline)}
            </div>
          </div>

          <div className={styles["row"]}>
            <div className={classNames(styles["cell"], styles["first-col"])}>
              Contact Person:
            </div>
            <div
              className={classNames(
                styles["cell"],
                styles["second-col"],
                styles["name-with-avatar"],
              )}
            >
              <div
                className={styles["creator-name"]}
              >{`${sourcingEventPublicInfo.creator.firstName} ${sourcingEventPublicInfo.creator.lastName}`}</div>
              <Avatar user={sourcingEventPublicInfo.creator} />
            </div>
          </div>

          <div className={styles["row"]}>
            <div className={classNames(styles["cell"], styles["first-col"])}>
              Additional info:
            </div>
            <div className={classNames(styles["cell"], styles["second-col"])}>
              {sourcingEventPublicInfo.additionalInfo}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
