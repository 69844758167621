import React, { useState, useEffect, createRef, createContext } from "react";
import { FeedUpdate } from "../FeedUpdate/FeedUpdate";
import { useKanbanCollaborationSidebar } from "../../schema";
import { Loading } from "../Loading/Loading";
import { StringToRefObjectMap } from "../../services/scrollIntoViewByRef";
import styles from "./KanbanCollaborationSidebar.module.scss";

export interface KanbanCollaborationSideBarProps {
  organizationId: string;
  showActivityLoader?: boolean;
}

export const ActivityRefsContext: React.Context<StringToRefObjectMap> = createContext(
  new Map(),
);

export const KanbanCollaborationSidebar: React.FC<KanbanCollaborationSideBarProps> = ({
  organizationId,
  showActivityLoader,
}) => {
  const [showLoader, setShowLoader] = useState<boolean | undefined>(false);

  const { data, loading, error } = useKanbanCollaborationSidebar({
    variables: { organizationId, take: 1000 },
  });

  const activities = data?.viewer?.organization.activities ?? [];

  const activityRefs = activities.reduce((acc, value) => {
    acc.set(value.id, createRef());

    return acc;
  }, new Map() as StringToRefObjectMap<HTMLLIElement>);

  useEffect(() => {
    setShowLoader(showActivityLoader);
  }, [showActivityLoader]);

  return (
    <>
      {(showLoader || loading) && <Loading />}
      {error && <p>Sorry, we could not load the activity feed</p>}
      <ul className={styles["activities-list"]}>
        {activities &&
          activities.map((activity) => {
            return (
              <li
                className={styles["activities-list-item"]}
                key={activity.id}
                ref={activityRefs.get(activity.id)}
              >
                <ActivityRefsContext.Provider value={activityRefs}>
                  <FeedUpdate
                    activity={activity}
                    setShowLoader={setShowLoader}
                  />
                </ActivityRefsContext.Provider>
              </li>
            );
          })}
      </ul>
    </>
  );
};
