import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getWsGraphqlUrl } from "./getWsGraphqlUrl";

const subscriptionClient = new SubscriptionClient(getWsGraphqlUrl(), {
  reconnect: true,
  lazy: true,
});

export const wsLink = new WebSocketLink(subscriptionClient);
