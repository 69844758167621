import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import {
  useOrganizationById,
  useAdminUpdateOrganization,
  OrganizationByIdCountries,
  OrganizationByIdCurrencies,
  useAdminUpdateCompanyLogo,
} from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { LoadingView } from "../LoadingView/LoadingView";
import { ErrorView } from "../ErrorView/ErrorView";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { CompanyLogo } from "../../components/CompanyLogo/CompanyLogo";

export interface EditOrganizationProps {
  organizationId: string;
  countries?: OrganizationByIdCountries[];
  currencies?: OrganizationByIdCurrencies[];
  onModalClose(): void;
}

export const EditOrganization: React.FC<EditOrganizationProps> = ({
  countries,
  currencies,
  organizationId,
  onModalClose,
}) => {
  // load organization by id
  const { data, loading: isFetching, error: fetchError } = useOrganizationById({
    fetchPolicy: "network-only",
    variables: {
      organizationId,
    },
  });

  // setup updating company logo
  const [
    adminUpdateCompanyLogo,
    { loading: logoLoading, error: logoError },
  ] = useAdminUpdateCompanyLogo({
    refetchQueries: ["OrganizationsManagement"],
    awaitRefetchQueries: true,
  });

  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        return;
      }

      adminUpdateCompanyLogo({
        variables: {
          companyLogo: acceptedFiles[0],
          organizationId,
        },
      });
    },
    [adminUpdateCompanyLogo, organizationId],
  );

  // setup form
  const {
    useInput,
    useSelect,
    useCheckbox,
    loading: isSaving,
    error: saveError,
    submit: updateOrganization,
  } = useForm({
    mutation: useAdminUpdateOrganization,
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const companyNameInput = useInput({ name: "companyName" });
  const currencyInput = useSelect({
    name: "baseCurrency",
    options: currencies
      ? currencies.map((currency) => ({
          label: currency.name,
          value: currency.symbol,
        }))
      : [],
  });
  const urlNameInput = useInput({ name: "urlName" });
  const countrySelectInput = useSelect({
    name: "countryCode",
    options: countries
      ? countries.map((country) => ({
          label: country.name,
          value: country.code,
        }))
      : [],
  });
  const cityInput = useInput({ name: "city" });
  const addressInput = useInput({ name: "address" });
  const areaCodeInput = useInput({ name: "areaCode" });
  const isActiveInput = useCheckbox({ name: "isActive" });

  // setup state
  const [isInitialized, setIsInitialized] = useState(false);

  // get organization info if loaded
  const organization = data?.admin && data.admin.organizationById;

  useEffect(() => {
    if (!organization) {
      return;
    }

    companyNameInput.setValue(organization.companyName);

    currencyInput.setValue(organization.baseCurrency);

    urlNameInput.setValue(organization.urlName);

    cityInput.setValue(organization.city);

    countrySelectInput.setValue(organization.countryCode);

    addressInput.setValue(organization.address);

    areaCodeInput.setValue(organization.areaCode);

    isActiveInput.setValue(organization.isActive);

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  // handle fetch error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading organization failed"
        error={fetchError}
        modal={{ title: "Edit organization", onModalClose }}
      />
    );
  }

  // handle save error
  if (saveError) {
    return (
      <ErrorView
        title="Saving contact person failed"
        error={saveError}
        modal={{ title: "Edit contact person", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !organization || !isInitialized) {
    return <LoadingView overlay />;
  }

  const submit = () => {
    updateOrganization({
      organizationId,
    });
  };

  return (
    <Modal
      title="Edit organization"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button
            data-testid="e6890e35f5"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="49ebd90ab9"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form onSubmit={submit}>
        <Field {...companyNameInput} short label="Organization" autofocus />
        <Select {...currencyInput} short label="Currency" />
        <Field {...urlNameInput} short label="Url name" />
        <Select {...countrySelectInput} short label="Country" />
        <Field {...cityInput} short label="City" />
        <Field {...addressInput} short label="Address" />
        <Field {...areaCodeInput} short label="Area code" />
        <Field {...isActiveInput} short label="Organization is active" />
        <Dropzone
          multiple={false}
          loading={logoLoading}
          error={logoError}
          label="Upload organization logo"
          accept={["image/jpeg", "image/png"]}
          rejectTypeText="Please provide valid image (.jpg or .png)"
          onDrop={onDrop}
        >
          {organization.companyLogoUrl === null ? (
            "Drag file here"
          ) : (
            <CompanyLogo
              url={organization.companyLogoUrl}
              alt={organization.companyName}
              title={organization.companyName}
            />
          )}
        </Dropzone>
      </Form>
    </Modal>
  );
};
