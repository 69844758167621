import React, { useRef } from "react";
import { ApolloError } from "apollo-client";
import { isEmpty } from "ramda";
import { Accordion } from "../../components/Accordion/Accordion";
import { AddAndEmailSuppliersSection } from "../../components/AddAndEmailSuppliers/AddAndEmailSuppliersSection";
import { Button } from "../../components/Button/Button";
import { CollaborationSidebar } from "../../components/CollaborationSidebar/CollaborationSidebar";
import { CommentForm } from "../../components/CommentForm/CommentForm";
import { ComparisonTable } from "../../components/ComparisonTable/ComparisonTable";
import { Field } from "../../components/Field/Field";
import { LabelDropdown } from "../../components/LabelDropdown/LabelDropdown";
import { Modal } from "../../components/Modal/Modal";
import { ProgressStep } from "../../components/ProgressStep/ProgressStep";
import { PurchaseRequestItems } from "../../components/PurchaseRequestItems/PurchaseRequestItems";
import {
  RfqEventForm,
  SourcingEventTemplate,
} from "../../components/RfqEventForm/RfqEventForm";
import { RequestTypeSelection } from "../../components/RfxTypeSelection/RequestTypeSelection";
import { Select } from "../../components/Select/Select";
import { SelectedSuppliers } from "../../components/SelectedSuppliers/SelectedSuppliers";
import { Separator } from "../../components/Separator/Separator";
import { SourcingEventInfo } from "../../components/SourcingEventInfo/SourcingEventInfo";
import {
  StatusNotice,
  EventTypeEnum,
} from "../../components/StatusNotice/StatusNotice";
import { UserSelectDropdown } from "../../components/UserSelectDropdown/UserSelectDropdown";
import { CardContextProvider } from "../../contexts/card-context";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  ActivityRelativeTypeEnum,
  CardTypeEnum,
  ViewerOrganizations,
  ViewerViewer,
  useAssignCard,
  useRemovePurchaseRequestItem,
  useRfxByCode,
  useUpdateRfx,
  useUpdateRfxName,
  useActivitySubscription,
  RfxStatusEnum,
  SwimlaneIdEnum,
  RfxTypeEnum,
  SourcingEventStatusEnum,
  SourcingRequestStatusEnum,
  useAddSupplierResolution,
  RfxSupplierResolutionEnum,
  useCreateOrderFromRfx,
  RfxNextStepEnum,
  useUpdateRfxType,
  useCompleteSourcingEvent,
  useArchiveRfx,
  useUpdateSourcingEventItemWinners,
} from "../../schema";
import { assertUnreachable } from "../../services/assertUnreachable";
import { checkDownloadUrlValidity } from "../../services/checkDownloadUrlValidity";
import { isDefined } from "../../services/isDefined";
import { EditIcon } from "../../theme/svg/EditIcon";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";
import { notEmpty } from "../../validators/notEmpty";
import { ErrorView } from "../ErrorView/ErrorView";
import { filterUsersWhoCanBeAssignedToCards } from "../KanbanView/KanbanView";
import { LoadingView } from "../LoadingView/LoadingView";
import { RfqMetadataForm } from "../../components/RfqMetadataForm/RfqMetadataForm";
import { ReadyIcon } from "../../theme/svg/ReadyIcon";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { ReadOnlyContextProvider } from "../../contexts/readonly-context";
import { UniqueSelectedSupplier } from "../../components/RfqItemQuestionnaire/RfqItemQuestionnaire";
import { useRfqMachine, RfqState, RfqMachineEventsEnum } from "./Rfq.machine";
import styles from "./RfxView.module.scss";

export interface RfxViewProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  code: string;
  onModalClose(): void;
}

export const RfqEventFormContext = React.createContext<
  | {
      isRfqEventFormDirty: boolean;
      setRfqEventFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const RfxView: React.FC<RfxViewProps> = ({
  viewer,
  organization,
  code,
  onModalClose,
}) => {
  // use router
  const { history } = useRouter();

  const [removingPurchaseRequestItemId, setRemovingPurchaseRequestItemId] =
    React.useState<string | undefined>(undefined);
  const [isCommentFormLoading, setIsCommentFormLoading] = React.useState(false);
  const [statusNotice, setStatusNotice] = React.useState({
    isVisible: false,
    eventTitle: "",
    eventDescription: "",
  });

  // comparision table item selection
  const comparisonTableRef =
    useRef<{ handleClearSelectedItems: () => void }>(null);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  const [isRfxNameEditable, setIsRfxNameEditable] = React.useState(false);

  const [selectedSuppliers, setSelectedSuppliers] = React.useState<
    UniqueSelectedSupplier[]
  >([]);

  const [
    isAddAndEmailSuppliersSectionOpen,
    setIsAddAndEmailSuppliersSectionOpen,
  ] = React.useState(true);

  const [
    isSelectedSuppliersSectionVisible,
    setIsSelectedSuppliersSectionVisible,
  ] = React.useState(false);

  const [isSelectedSuppliersSectionOpen, setIsSelectedSuppliersSectionOpen] =
    React.useState(false);

  const [isRfqEventFormDirty, setRfqEventFormDirty] = React.useState(false);

  // configure rfx update form
  const {
    submit: updateRfx,
    loading: isUpdateRfxLoading,
    error: updateError,
  } = useForm({
    mutation: useUpdateRfx,
    options: {
      refetchQueries: ["PaginatedRfx", "RfxByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure rfx type update
  const {
    submit: updateRfxType,
    loading: isUpdateRfxTypeLoading,
    error: updateRfxTypeError,
  } = useForm({
    mutation: useUpdateRfxType,
    options: {
      refetchQueries: ["PaginatedRfx", "RfxByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure item cherry picking
  const [
    updateSourcingEventItemWinnder,
    { loading: isUpdateSourcingEventItemWinndersLoading },
  ] = useUpdateSourcingEventItemWinners({
    refetchQueries: ["PaginatedRfx", "RfxByCode"],
    awaitRefetchQueries: true,
  });

  // configure archiving
  const [archiveRfx, { loading: isArchiveRfxLoading }] = useArchiveRfx({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedRfx"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onModalClose();
    },
  });

  // configure update rfx name form
  const {
    useInput,
    submit: updateRfxName,
    loading: isUpdateNameLoading,
    error: updateNameError,
  } = useForm({
    mutation: useUpdateRfxName,
    options: {
      refetchQueries: ["PaginatedRfx", "RfxByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure name input
  const nameInput = useInput({ name: "name", validators: [notEmpty] });

  // attempt to load rfx info
  const {
    data,
    error: getRfxError,
    loading: isGetRfxLoading,
    refetch: refetchRfxByCode,
  } = useRfxByCode({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      code,
      withDeleted: true,
    },
  });

  // setup removing rfx item
  const [removePurchaseRequestItem] = useRemovePurchaseRequestItem({
    refetchQueries: ["RfxByCode", "PaginatedRfx", "PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
  });

  // setup assigning card
  const [assignCard, { loading: isAssigningCard }] = useAssignCard({
    refetchQueries: ["RfxByCode", "PaginatedRfx"],
    awaitRefetchQueries: true,
  });

  // setup moving rfx to po
  const [createOrderFromRfx] = useCreateOrderFromRfx();

  // resolve info
  const rfx = data?.viewer ? data.viewer.rfxByCode : undefined;
  const suppliers = data?.viewer ? data.viewer.organization.suppliers : [];
  const users = data?.viewer ? data.viewer.organization.users : [];

  const usersWhoCanBeAssignedToCards =
    filterUsersWhoCanBeAssignedToCards(users);

  // calculate the next RFX step
  const nextStep = rfx?.nextStep;

  // mutation for completing RFQ
  const [
    completeSourcingEvent,
    {
      loading: loadingCompleteSourcingEvent,
      error: completeSourcingEventError,
    },
  ] = useCompleteSourcingEvent({
    variables: { sourcingEventId: rfx?.sourcingEvent?.id ?? "" },
    refetchQueries: ["PaginatedRfx", "RfxByCode"],
    awaitRefetchQueries: true,
  });

  // handle adding supplier resolution
  const [addSupplierResolution, { loading: addSupplierResolutionLoading }] =
    useAddSupplierResolution();

  const { state: rfqCurrentState, send } = useRfqMachine({
    completeRfq: completeSourcingEvent,
    sourcingEvent: rfx?.sourcingEvent ?? null,
  });

  // handle completeing sourcing event
  const handleCompleteSourcingEvent = async () => {
    // do nothing when rfx is not found
    if (!rfx || !rfx.sourcingEvent) {
      return;
    }

    // get all suppliers that have not moved to PO
    const questionaire = rfx.sourcingEvent
      .questionnaire as SourcingEventTemplate;
    const suppliersToNominate = questionaire.items
      .filter((item) => !item.hasMovedToPo && item.winningSupplierId)
      .map((item) => item.winningSupplierId)
      .filter((item, index, self) => self.indexOf(item) === index) as string[];

    for await (const supplierId of suppliersToNominate) {
      await addSupplierResolution({
        variables: {
          rfxId: rfx.id,
          supplierId,
          resolution: RfxSupplierResolutionEnum.NOMINATE_TO_PO,
        },
      });
    }

    // start rfx completion
    send({
      type: RfqMachineEventsEnum.START_COMPLETION,
    });
  };

  // subscribe to activities
  const { data: activitySubscriptionData } = useActivitySubscription({
    variables: {
      organizationId: organization.id,
      relativeId: rfx ? rfx.id : null,
    },
    skip: !rfx,
  });

  const [selectedRfqSupplier, setSelectedRfqSupplier] = React.useState<
    string | undefined
  >();

  // refetch RfxByCode data if new subscription events occur
  React.useEffect(() => {
    refetchRfxByCode();
  }, [activitySubscriptionData, refetchRfxByCode]);

  // set default input values once rfx is loaded
  React.useEffect(() => {
    if (!rfx) {
      return;
    }

    // check if at least one email is sent to at least one supplier
    const areEmailsSentToSuppliers = rfx.emails.length > 0;

    if (areEmailsSentToSuppliers) {
      setIsSelectedSuppliersSectionVisible(true);
      setIsSelectedSuppliersSectionOpen(true);
    }

    // set input default values
    nameInput.setValue(rfx.name);
  }, [rfx]);

  // load RfqMachine state
  React.useEffect(() => {
    if (!rfx || rfx.type !== RfxTypeEnum.RFQ || !rfx.sourcingEvent) {
      return;
    }

    switch (rfx.sourcingEvent.status) {
      case SourcingEventStatusEnum.DRAFT: {
        send({
          type: RfqMachineEventsEnum.LOAD_STATE,
          stateToLoad: RfqState.CREATED,
        });
        return;
      }

      case SourcingEventStatusEnum.IN_PROGRESS: {
        send({
          type: RfqMachineEventsEnum.LOAD_STATE,
          stateToLoad: RfqState.ONGOING,
        });
        return;
      }

      case SourcingEventStatusEnum.CANCELLED:
      case SourcingEventStatusEnum.COMPLETE: {
        send({
          type: RfqMachineEventsEnum.LOAD_STATE,
          stateToLoad: RfqState.COMPLETE,
        });
        return;
      }

      default:
        assertUnreachable(
          rfx.sourcingEvent.status,
          "Unhandled sourcing event status. This should not happen.",
        );
    }
  }, [rfx, send]);

  // handle update error
  React.useEffect(() => {
    if (
      updateError ||
      updateRfxTypeError ||
      updateNameError ||
      completeSourcingEventError
    ) {
      setStatusNotice({
        isVisible: true,
        eventTitle: "Oops! Something went wrong",
        eventDescription: updateError || updateNameError || "",
      });
    }
  }, [
    updateError,
    updateRfxTypeError,
    updateNameError,
    completeSourcingEventError,
  ]);

  const userMentionData = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const supplierMentionData = suppliers.map((supplier) => ({
    id: supplier.id,
    display: supplier.name
      ? supplier.name
      : supplier.defaultContactPerson.email
      ? supplier.defaultContactPerson.email
      : "[missing name]",
  }));

  // handle loading rfx error
  if (getRfxError) {
    return (
      <Modal wide title="Loading rfx failed" onCloseRequested={onModalClose}>
        <ErrorView
          title="Requested rfx could not be found"
          error={getRfxError}
        />
      </Modal>
    );
  }

  // handle loading (also avoid form content flicker)
  if (isGetRfxLoading || !rfx) {
    return <LoadingView overlay />;
  }

  // submits updating rfx name change
  const submitUpdateRfxName = async () => {
    await updateRfxName({
      rfxId: rfx.id,
    });

    setIsRfxNameEditable(false);
  };

  // sets RFI as ready
  const submitRfxIsReady = async () => {
    await updateRfx({
      rfxId: rfx.id,
      name: rfx.name,
      type: rfx.type ?? RfxTypeEnum.RFI,
      isRfiComplete: rfx.type === RfxTypeEnum.RFI,
      isRfpComplete: rfx.type === RfxTypeEnum.RFP,
      isRfqComplete: rfx.type === RfxTypeEnum.RFQ,
    });
  };

  // changes RFX type or creates a PO
  const handleNextStep = async () => {
    // next step is RFP or RFQ
    if (
      nextStep &&
      (nextStep === RfxNextStepEnum.RFP || nextStep === RfxNextStepEnum.RFQ)
    ) {
      const mapRfxNextStepToType = (nextStep: RfxNextStepEnum) => {
        switch (nextStep) {
          case RfxNextStepEnum.RFP: {
            return RfxTypeEnum.RFP;
          }

          case RfxNextStepEnum.RFQ: {
            return RfxTypeEnum.RFQ;
          }

          default:
            return RfxTypeEnum.RFI;
        }
      };

      const mapRfxTypeToResolution = (rfxType: RfxTypeEnum) => {
        switch (rfxType) {
          case RfxTypeEnum.RFI: {
            return RfxSupplierResolutionEnum.NOMINATE_TO_RFI;
          }

          case RfxTypeEnum.RFP: {
            return RfxSupplierResolutionEnum.NOMINATE_TO_RFP;
          }

          case RfxTypeEnum.RFQ: {
            return RfxSupplierResolutionEnum.NOMINATE_TO_RFQ;
          }

          default:
            assertUnreachable(rfxType, "Unhandled RFX type");
        }
      };

      await updateRfx({
        rfxId: rfx.id,
        name: rfx.name,
        type: mapRfxNextStepToType(nextStep),
        isRfiComplete: rfx.type === RfxTypeEnum.RFI,
        isRfpComplete: rfx.type === RfxTypeEnum.RFP,
        isRfqComplete: rfx.type === RfxTypeEnum.RFQ,
      });

      // suppliers without resolution in this step
      const unselectedSuppliers =
        (rfx.supplierResolutions &&
          rfx.supplierResolutions.filter((sr) => sr.resolution === null)) ??
        [];

      // set so far unselected supplier resolutions to be selected as current rfx type
      for (const supplier of unselectedSuppliers) {
        await addSupplierResolution({
          variables: {
            rfxId: rfx.id,
            resolution: rfx.type ? mapRfxTypeToResolution(rfx.type) : null,
            supplierId: supplier.supplierId,
          },
          refetchQueries: ["RfxByCode"],
          awaitRefetchQueries: true,
        });
      }

      history.push(`/${organization.urlName}/${nextStep}-${rfx.code}`);
    }

    // next step is PO
    if (nextStep && nextStep === RfxNextStepEnum.PO) {
      await submitRfxIsReady();

      const { data, errors } = await createOrderFromRfx({
        variables: {
          rfxId: rfx.id,
          method: null,
          relativeOrderId: null,
          rfxItemIds: null,
          supplierId: null,
          hideRfx: null,
          isCreatedFromSourcingEvent: null,
        },
      });

      const createdOrderCode = data?.createOrderFromRfx.code;

      if (createdOrderCode) {
        history.push(`/${organization.urlName}/PO-${createdOrderCode}`);
      }

      // TODO: handle creation error
      if (errors) {
        setStatusNotice({
          isVisible: true,
          eventTitle: "Could not create a Purchase Order",
          eventDescription: "Please try again",
        });
      }
    }
  };

  // sets RFI to ongoing
  const submitRfxIsOngoing = async () => {
    await updateRfx({
      rfxId: rfx.id,
      name: rfx.name,
      type: rfx.type ?? RfxTypeEnum.RFI,
      isRfiComplete: false,
      isRfpComplete: false,
      isRfqComplete: false,
    });
  };

  // attempts to remove requested rfx item
  const removeItem = async (itemId: string) => {
    setRemovingPurchaseRequestItemId(itemId);

    try {
      await removePurchaseRequestItem({
        variables: { purchaseRequestItemId: itemId },
      });
    } finally {
      setRemovingPurchaseRequestItemId(undefined);
    }
  };

  const handleRequestTypeSelection = async (
    selectedRequestType: RfxTypeEnum,
  ) => {
    await updateRfxType({
      rfxId: rfx.id,
      type: selectedRequestType,
    });
  };

  const handleSendEmailSuccess = (
    suppliers: {
      id: string;
      defaultContactPerson?: { email?: string | null };
      name?: string | null;
    }[],
  ) => {
    setStatusNotice({
      isVisible: true,
      eventTitle: "Email sent to suppliers",
      eventDescription: `Sent to ${suppliers
        .map((supplier) => supplier.defaultContactPerson?.email)
        .join(", ")}`,
    });

    setIsAddAndEmailSuppliersSectionOpen(false);
  };

  const handleSendEmailError = (error: string | ApolloError) => {
    setStatusNotice({
      isVisible: true,
      eventTitle: "Oops! Sending the email failed",
      eventDescription: `Server said "${error}"`,
    });
  };

  const handleSendReplySuccess = (email: string) => {
    setStatusNotice({
      isVisible: true,
      eventTitle: "Reply sent to supplier",
      eventDescription: `Sent to ${email}`,
    });
  };

  const handleSendReplyError = (error: string | ApolloError) => {
    setStatusNotice({
      isVisible: true,
      eventTitle: "Oops! Sending the reply failed",
      eventDescription: `Server said "${error}"`,
    });
  };

  const handleOnSupplierChange = (
    uniqueSelectedSuppliers: UniqueSelectedSupplier[],
  ) => {
    setSelectedSuppliers(uniqueSelectedSuppliers);
  };

  const handleItemSelect = (itemIds: string[]) => {
    setSelectedItems(itemIds);
  };

  const handleSaveWinner = async (
    _event?: React.MouseEvent<HTMLButtonElement>,
    clearSelectedSupplier = true,
  ) => {
    if (!selectedRfqSupplier || !rfx.sourcingEvent) {
      return;
    }

    // if no items are selected mark all items as winner for selected supplier
    let itemIds = selectedItems;
    const sourcingEventQuestionnaire = rfx.sourcingEvent
      .questionnaire as SourcingEventTemplate;

    if (itemIds.length <= 0) {
      itemIds = sourcingEventQuestionnaire.items.map((item) => item.id);
    }

    await updateSourcingEventItemWinnder({
      variables: {
        sourcingEventId: rfx.sourcingEvent.id,
        rfxId: rfx.id,
        supplierId: selectedRfqSupplier,
        itemIds,
        markRfxAsComplete: false,
      },
    });

    // clear checkboxes
    if (comparisonTableRef.current) {
      // call child components function to clear its state
      comparisonTableRef.current.handleClearSelectedItems();
    }

    // clear selected items state and selected supplier
    setSelectedItems([]);

    if (clearSelectedSupplier) {
      setSelectedRfqSupplier("");
    }
  };

  // creates PO from RFQ
  const handleFromRfqToPO = async () => {
    if (!selectedRfqSupplier) {
      return;
    }

    const questionaire = rfx.sourcingEvent
      ? (rfx.sourcingEvent.questionnaire as SourcingEventTemplate)
      : undefined;

    // check if all of sourcing event items have moved to order
    const itemsMovedToPoCount = questionaire
      ? questionaire.items.filter((item) => item.hasMovedToPo).length
      : 0;

    const isRfxFinished = questionaire
      ? itemsMovedToPoCount >= questionaire.items.length - 1 ||
        selectedItems.length === 0
      : false;

    // save winners
    await handleSaveWinner(undefined, false);

    await addSupplierResolution({
      variables: {
        rfxId: rfx.id,
        supplierId: selectedRfqSupplier,
        resolution: RfxSupplierResolutionEnum.NOMINATE_TO_PO,
      },
    });

    // get items to move to PO
    const rfxItemIds =
      selectedItems.length > 0
        ? selectedItems
        : questionaire
        ? questionaire.items
            .filter((item) => !item.hasMovedToPo)
            .map((item) => item.id)
        : null;

    const { data, errors } = await createOrderFromRfx({
      variables: {
        rfxId: rfx.id,
        method: null,
        relativeOrderId: null,
        rfxItemIds,
        supplierId: selectedRfqSupplier,
        hideRfx: isRfxFinished,
        isCreatedFromSourcingEvent: true,
      },
    });

    if (isRfxFinished) {
      await submitRfxIsReady();
    }

    const createdOrderCode = data?.createOrderFromRfx.code;

    if (createdOrderCode) {
      history.push(`/${organization.urlName}/PO-${createdOrderCode}`);
    }

    // TODO: handle creation error
    if (errors) {
      setStatusNotice({
        isVisible: true,
        eventTitle: "Could not create a Purchase Order",
        eventDescription: "Please try again",
      });
    }
  };

  // rfx request type
  const requestType = rfx.type ?? "RFX";

  // completed sourcing requests
  const completedSourcingRequests =
    rfx?.sourcingEvent?.sourcingRequests?.filter(
      (req) => req.status === SourcingRequestStatusEnum.COMPLETE,
    ) ?? [];

  // completed supplier options for select
  const completedSupplierOptions = completedSourcingRequests
    .map((req) =>
      req.supplier
        ? { label: req.supplier.name ?? "", value: req.supplier.id }
        : undefined,
    )
    .filter(isDefined);

  // filter out deleted items for not archived rfx ( safety )
  const hasDeletedItems = rfx.items.some((item) => item.isDeleted);

  if (rfx.deletedDate === null && hasDeletedItems) {
    rfx.items = rfx.items.filter((item) => !item.isDeleted);
  }

  // store this value for showing winnder selection and comparison table
  const canSelectWinnder =
    !isEmpty(completedSupplierOptions) &&
    [RfxStatusEnum.ONGOING].includes(rfx.status as RfxStatusEnum);

  // render edit rfx modal
  return (
    <Modal
      wide
      title={
        <div className={styles["rfx-name-wrap"]}>
          <div
            className={styles["rfx-code"]}
          >{`${requestType}-${rfx.code}`}</div>
          {!isRfxNameEditable && (
            <>
              <div>{rfx.name}</div>
              <EditIcon
                className={styles["name-edit-icon"]}
                onClick={() => {
                  setIsRfxNameEditable(true);
                }}
              />
            </>
          )}
          {isRfxNameEditable && (
            <>
              <Field {...nameInput} className={styles["name-input"]} />
              <Button
                secondary
                onClick={async () => {
                  nameInput.setValue(rfx.name);
                  setIsRfxNameEditable(false);
                }}
                className={styles["save-name-button"]}
              >
                Cancel
              </Button>
              <Button
                disabled={!nameInput.value}
                loading={isUpdateNameLoading}
                onClick={async () => {
                  if (nameInput.value) {
                    await submitUpdateRfxName();
                  }
                }}
                className={styles["save-name-button"]}
              >
                Save
              </Button>
            </>
          )}
        </div>
      }
      addon={
        <div className={styles["addon-wrap"]}>
          <LabelDropdown
            className={styles["label-dropdown"]}
            label={rfx.cardStatus.text}
            level={rfx.cardStatus.level}
            dropdownItems={[
              ...(!rfx.sourcingEvent &&
              rfx.status === RfxStatusEnum.COMPLETE &&
              rfx.deletedDate === null
                ? [
                    {
                      id: "1",
                      icon: <EditIcon />,
                      text: "Edit",
                      onClick: async () => {
                        submitRfxIsOngoing();
                      },
                    },
                  ]
                : []),
              ...(rfx.sourcingEvent &&
              rfx.sourcingEvent.status !== SourcingEventStatusEnum.DRAFT
                ? [
                    {
                      id: "2",
                      icon: <ExcelIcon />,
                      text: "Download offers",
                      onClick: async () => {
                        return checkDownloadUrlValidity(
                          `/api/export/esourcing/${rfx.sourcingEvent?.id}`,
                        );
                      },
                    },
                  ]
                : [
                    {
                      id: "3",
                      icon: <ExcelIcon />,
                      text: "Download items",
                      onClick: async () => {
                        return checkDownloadUrlValidity(
                          `/api/export/rfx/${rfx.id}`,
                        );
                      },
                    },
                  ]),
              ...(rfx.sourcingEvent &&
              rfx.status === RfxStatusEnum.ONGOING &&
              rfqCurrentState.value !== RfqState.CHANGING_METADATA &&
              rfx.deletedDate === null
                ? [
                    {
                      id: "4",
                      icon: <EditIcon />,
                      text: "Edit",
                      onClick: async () => {
                        send({
                          type: RfqMachineEventsEnum.START_CHANGING_METADATA,
                        });
                      },
                    },
                  ]
                : []),
              ...(rfx.sourcingEvent && rfx.status === RfxStatusEnum.ONGOING
                ? [
                    {
                      id: "5",
                      icon: <ReadyIcon style={{ fill: "green" }} />,
                      text: "Mark complete",
                      onClick: async () => {
                        await handleCompleteSourcingEvent();
                      },
                    },
                  ]
                : []),
              ...(rfx.deletedDate === null
                ? [
                    {
                      id: "6",
                      icon: <ArchiveIcon className={styles["archive-icon"]} />,
                      text: "Archive",
                      onClick: async () => {
                        await archiveRfx({
                          variables: {
                            rfxId: rfx.id,
                          },
                        });
                      },
                    },
                  ]
                : []),
            ]}
          />
          <UserSelectDropdown
            large
            title={
              rfx.assignee === null
                ? `Click to assign ${requestType}`
                : `[${rfx.assignee.firstName} ${rfx.assignee.lastName}] Click to change ${requestType} assignee`
            }
            activeUser={rfx.assignee}
            users={usersWhoCanBeAssignedToCards}
            loading={isAssigningCard}
            onChoose={(user) =>
              assignCard({
                variables: {
                  itemId: rfx.id,
                  assigneeId: user.id,
                  type: CardTypeEnum.RFX,
                },
              })
            }
            onUnassign={() =>
              assignCard({
                variables: {
                  itemId: rfx.id,
                  assigneeId: null,
                  type: CardTypeEnum.RFX,
                },
              })
            }
          />
        </div>
      }
      sidebarTitle="Collaboration feed"
      sidebar={
        <>
          {/* <SidebarFilter title="Show only Updates" onClick={() => alertNotImplemented()} /> */}
          <CommentForm
            orgUsers={userMentionData ? userMentionData : []}
            orgSuppliers={supplierMentionData ? supplierMentionData : []}
            organizationId={organization.id}
            parentId={null}
            relativeId={rfx.id}
            relativeType={ActivityRelativeTypeEnum.RFX}
            setIsCommentFormLoading={setIsCommentFormLoading}
          />
          <Separator />
          <LatestActivityProvider
            organizationId={organization.id}
            relativeId={rfx.id}
          >
            <CollaborationSidebar
              activities={rfx.activities}
              showActivityLoader={isCommentFormLoading}
            />
          </LatestActivityProvider>
        </>
      }
      footer={
        <>
          {/* Cancel submitting sourcing event form */}
          {rfqCurrentState.value === RfqState.CHANGING_METADATA && (
            <Button
              form="rfq-event-form"
              type="button"
              onClick={() => {
                send({ type: RfqMachineEventsEnum.STOP_CHANGING_METADATA });
              }}
              tertiary
            >
              Cancel
            </Button>
          )}
          {/* Submit for sourcing event form */}
          {((rfx.type === RfxTypeEnum.RFQ && !rfx.sourcingEvent) ||
            rfx.sourcingEvent?.status === SourcingEventStatusEnum.DRAFT ||
            rfqCurrentState.value === RfqState.CHANGING_METADATA) && (
            <Button
              form="rfq-event-form"
              type="submit"
              secondary={!isRfqEventFormDirty}
            >
              Save
            </Button>
          )}
          {rfx.status === RfxStatusEnum.ONGOING && nextStep && (
            <Button
              data-testid="0fua89f7af"
              onClick={handleNextStep}
              disabled={isUpdateRfxLoading}
            >
              {`Continue with ${mapNextRfxStepToLabel(nextStep)}`}
            </Button>
          )}
        </>
      }
      className={styles["modal"]}
      headerClassName={styles["modal-header"]}
      onCloseRequested={onModalClose}
    >
      <div className={styles["wrap"]}>
        <CardContextProvider card={{ ...rfx, type: CardTypeEnum.RFX }}>
          <ViewerContextProvider viewer={viewer}>
            <RfqEventFormContext.Provider
              value={{ isRfqEventFormDirty, setRfqEventFormDirty }}
            >
              <ReadOnlyContextProvider
                isReadOnly={
                  rfx.sourcingEvent?.status ===
                    SourcingEventStatusEnum.IN_PROGRESS &&
                  rfqCurrentState.value !== RfqState.CHANGING_METADATA
                }
              >
                {rfx.status === RfxStatusEnum.DRAFT && rfx.type === null && (
                  <div className={styles["request-type-section"]}>
                    <RequestTypeSelection
                      onRequestTypeSelect={handleRequestTypeSelection}
                      isDisabled={rfx.deletedDate !== null}
                    />
                  </div>
                )}

                {/* show progress */}
                {rfx.type && (
                  <div className={styles["progress-steps"]}>
                    <ProgressStep
                      className={styles["step"]}
                      stepSymbol="1"
                      swimlaneType={SwimlaneIdEnum.RFX}
                      isActive={rfx.type === RfxTypeEnum.RFI}
                    >
                      RFI
                    </ProgressStep>
                    <ProgressStep
                      className={styles["step"]}
                      stepSymbol="2"
                      swimlaneType={SwimlaneIdEnum.RFX}
                      isActive={rfx.type === RfxTypeEnum.RFP}
                    >
                      RFP/RFQ
                    </ProgressStep>
                    <ProgressStep
                      className={styles["step"]}
                      stepSymbol="3"
                      swimlaneType={SwimlaneIdEnum.RFX}
                      isActive={rfx.type === RfxTypeEnum.RFQ}
                    >
                      eSourcing
                    </ProgressStep>
                  </div>
                )}

                {/* show RFI and RFP forms */}
                {(rfx.type === RfxTypeEnum.RFI ||
                  rfx.type === RfxTypeEnum.RFP) && (
                  <div>
                    <div>
                      {/* status notices */}
                      {statusNotice.isVisible && (
                        <StatusNotice
                          level={EventTypeEnum.SUCCESS}
                          eventTitle={statusNotice.eventTitle}
                          eventDescription={statusNotice.eventDescription}
                          onCloseRequested={() => {
                            setStatusNotice({
                              isVisible: false,
                              eventTitle: "",
                              eventDescription: "",
                            });
                          }}
                        />
                      )}
                      {statusNotice.isVisible &&
                        (updateError || updateRfxTypeError) && (
                          <StatusNotice
                            level={EventTypeEnum.ALERT}
                            eventTitle={statusNotice.eventTitle}
                            eventDescription={statusNotice.eventDescription}
                            onCloseRequested={() => {
                              setStatusNotice({
                                isVisible: false,
                                eventTitle: "",
                                eventDescription: "",
                              });
                            }}
                          />
                        )}

                      {/* add and email suppliers */}
                      <Accordion
                        title="Add and email suppliers"
                        isInitiallyOpen={isAddAndEmailSuppliersSectionOpen}
                      >
                        {data && (
                          <AddAndEmailSuppliersSection
                            data={data}
                            nameInput={nameInput}
                            onSendSuccess={handleSendEmailSuccess}
                            onSendError={handleSendEmailError}
                            className={styles["add-and-email-suppliers"]}
                          />
                        )}
                      </Accordion>
                    </div>

                    {/* selected suppliers */}
                    {data && isSelectedSuppliersSectionVisible && (
                      <Accordion
                        title="Selected suppliers"
                        isInitiallyOpen={isSelectedSuppliersSectionOpen}
                      >
                        <SelectedSuppliers
                          data={data}
                          onSendReplySuccess={handleSendReplySuccess}
                          onSendReplyError={handleSendReplyError}
                        />
                      </Accordion>
                    )}

                    {/* items */}
                    <Accordion
                      title="Items"
                      infoText={"Items are not attached to supplier emails"}
                      isInitiallyOpen={false}
                    >
                      <PurchaseRequestItems
                        isEditable={rfx.status !== RfxStatusEnum.COMPLETE}
                        items={rfx.items}
                        loadingItemId={removingPurchaseRequestItemId}
                        onEdit={(itemId, itemType) => {
                          itemType === "PRODUCT"
                            ? history.push(
                                `/${organization.urlName}/edit-product/RFX-${code}/item-${itemId}`,
                              )
                            : history.push(
                                `/${organization.urlName}/edit-service/RFX-${code}/item-${itemId}`,
                              );
                        }}
                        onDelete={(itemId) => removeItem(itemId)}
                        onRequestLinkClick={(requestCode) =>
                          history.push(
                            `/${organization.urlName}/${requestCode}`,
                          )
                        }
                      />
                    </Accordion>
                  </div>
                )}

                {rfx.type === RfxTypeEnum.RFQ && (
                  <>
                    {/* sourcing event creation form */}
                    {[RfqState.INIT, RfqState.CREATED].includes(
                      rfqCurrentState.value as RfqState,
                    ) && (
                      <RfqEventForm
                        organization={organization}
                        rfx={rfx}
                        onSuppliersChange={handleOnSupplierChange}
                      />
                    )}

                    {/* sourcing event metadata update form */}
                    {[RfqState.CHANGING_METADATA].includes(
                      rfqCurrentState.value as RfqState,
                    ) && (
                      <RfqMetadataForm organization={organization} rfx={rfx} />
                    )}

                    {/* sourcing event read-only info */}
                    {rfx.sourcingEvent &&
                      [RfqState.ONGOING, RfqState.COMPLETE].includes(
                        rfqCurrentState.value as RfqState,
                      ) && (
                        <>
                          <Accordion
                            title="Sourcing event info"
                            isInitiallyOpen={true}
                          >
                            <div className={styles["sourcing-event-info"]}>
                              <SourcingEventInfo
                                rfx={rfx}
                                sourcingOrganization={organization}
                                sourcingEvent={rfx.sourcingEvent}
                                sourcingEventCreator={rfx.sourcingEvent.creator}
                              />
                            </div>
                          </Accordion>

                          <Accordion
                            title="Sourcing event items and questions"
                            isInitiallyOpen={false}
                          >
                            <RfqEventForm
                              organization={organization}
                              rfx={rfx}
                            />
                          </Accordion>
                        </>
                      )}
                    {/* sent sourcing requests (offers) */}
                    {rfx.sourcingEvent &&
                      [RfqState.ONGOING, RfqState.COMPLETE].includes(
                        rfqCurrentState.value as RfqState,
                      ) && (
                        <Accordion title="Offers" isInitiallyOpen={true}>
                          <div className={styles["offers"]}>
                            <ComparisonTable
                              ref={comparisonTableRef}
                              rfx={rfx}
                              baseCurrency={organization.baseCurrency}
                              showItemSelect={canSelectWinnder}
                              onItemSelect={handleItemSelect}
                            />
                          </div>
                        </Accordion>
                      )}
                    {/* nominated supplier select when rfx is ongoing (and in future: rfq is complete)*/}
                    {canSelectWinnder && (
                      <div className={styles["winner-select-outer"]}>
                        <div className={styles["winner-select-inner"]}>
                          <h4 className={styles["winner-select-title"]}>
                            DECISION
                          </h4>

                          <Select
                            className={styles["winner-select"]}
                            label="Select the winner:"
                            value={selectedRfqSupplier}
                            onChange={(e) => {
                              if (!isEmpty(e.target.value)) {
                                setSelectedRfqSupplier(e.target.value);
                              } else {
                                setSelectedRfqSupplier(undefined);
                              }
                            }}
                            options={[{ label: "-", value: "" }].concat(
                              completedSupplierOptions,
                            )}
                          />
                          <div className={styles["winner-select-container"]}>
                            <Button
                              className={styles["winner-select-submit"]}
                              disabled={
                                !selectedRfqSupplier ||
                                addSupplierResolutionLoading ||
                                isUpdateSourcingEventItemWinndersLoading
                              }
                              onClick={handleSaveWinner}
                            >
                              Save winner
                            </Button>
                            <Button
                              className={styles["winner-select-submit"]}
                              disabled={
                                !selectedRfqSupplier ||
                                addSupplierResolutionLoading ||
                                isUpdateSourcingEventItemWinndersLoading
                              }
                              onClick={handleFromRfqToPO}
                            >
                              Save winner and create PO
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* enable sending out sourcing requests and later communication */}
                    {[RfqState.CREATED, RfqState.ONGOING].includes(
                      rfqCurrentState.value as RfqState,
                    ) && (
                      <Accordion
                        title="Send out eSourcing requests"
                        isInitiallyOpen={isAddAndEmailSuppliersSectionOpen}
                      >
                        {data && rfx.sourcingEvent && (
                          <AddAndEmailSuppliersSection
                            data={data}
                            initialSubject={`[${rfx.type}-${rfx.code}] ${rfx.sourcingEvent.name}`}
                            templateMessage={
                              "Hello ${supplierName},\n\nHere is the request for quotation."
                            } // eslint-disable-line no-template-curly-in-string
                            nameInput={nameInput}
                            onSendSuccess={handleSendEmailSuccess}
                            onSendError={handleSendEmailError}
                            className={styles["add-and-email-suppliers"]}
                            visibilitySelectedSuppliers={selectedSuppliers}
                          />
                        )}
                      </Accordion>
                    )}

                    {/* show correspondence bar until ready */}
                    {[RfxStatusEnum.ONGOING, RfxStatusEnum.COMPLETE].includes(
                      rfx.status as RfxStatusEnum,
                    ) && (
                      <Accordion title="Correspondence" isInitiallyOpen={false}>
                        {data && (
                          <SelectedSuppliers
                            data={data}
                            onSendReplySuccess={handleSendReplySuccess}
                            onSendReplyError={handleSendReplyError}
                          />
                        )}
                      </Accordion>
                    )}
                  </>
                )}

                {(isUpdateRfxLoading ||
                  isUpdateRfxTypeLoading ||
                  isUpdateNameLoading ||
                  addSupplierResolutionLoading ||
                  loadingCompleteSourcingEvent ||
                  isArchiveRfxLoading) && <LoadingView overlay />}
              </ReadOnlyContextProvider>
            </RfqEventFormContext.Provider>
          </ViewerContextProvider>
        </CardContextProvider>
      </div>
    </Modal>
  );
};

function mapNextRfxStepToLabel(nextStep: RfxNextStepEnum) {
  switch (nextStep) {
    case RfxNextStepEnum.PO: {
      return "Purchase Order";
    }

    case RfxNextStepEnum.RFP: {
      return "RFP/RFQ";
    }

    case RfxNextStepEnum.RFQ: {
      return "eSourcing";
    }

    default:
      return "";
  }
}
