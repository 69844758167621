import * as React from "react";
export const SendDocumentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 248 246" fill="none" {...props}>
    <path
      d="M4.5 6.2c-.3.7-.4 53.9-.3 118.3l.3 117 90.7.3 90.8.2V64.5l-29.8-29.8L126.5 5H65.7C18 5 4.8 5.3 4.5 6.2zM114 50.5V78h54v146H23V23h91v27.5zm40 8.8c0 .4-4.8.6-10.7.5l-10.8-.3-.3-11.5-.3-11.5 11.1 11c6 6 11 11.3 11 11.8z"
      fill={props.fill}
    />
    <rect
      x={103.5}
      y={164.5}
      width={100}
      height={53}
      rx={12.5}
      fill={props.fill}
      stroke="#fff"
      strokeWidth={5}
    />
    <path
      d="M187.902 234.703l53.981-39.131c4.131-2.994 4.131-9.15 0-12.144l-53.981-39.131c-4.959-3.594-11.902-.052-11.902 6.073v78.26c0 6.125 6.943 9.667 11.902 6.073z"
      fill={props.fill}
      stroke="#fff"
      strokeWidth={5}
    />
    <path fill={props.fill} d="M169 167h26v48h-26z" />
  </svg>
);
