import {
  CardStatusLevelEnum,
  PurchaseRequestsDetailsByCodePurchaseRequestByCode,
  ApprovalWorkflowStatusEnum,
  PurchaseRequestStatusEnum,
  RequesterPortalViewPurchaseRequests,
} from "../schema";
import { CardStatus } from "../components/Status/Status";
import { getWorkflowStatusText } from "./getWorkflowStatusText";
import { getWorkflowStatusLevel } from "./getWorkflowStatusLevel";

export function getRequesterPortalCardStatus(
  purchaseRequest:
    | RequesterPortalViewPurchaseRequests
    | PurchaseRequestsDetailsByCodePurchaseRequestByCode,
): CardStatus {
  // return card's own status if no approval workflow exists, is cancelled or any request items are in other swimlanes
  if (
    !purchaseRequest.latestApprovalWorkflow ||
    purchaseRequest.latestApprovalWorkflow.status ===
      ApprovalWorkflowStatusEnum.CANCELLED ||
    purchaseRequest.status === PurchaseRequestStatusEnum.IN_PROGRESS
  ) {
    switch (purchaseRequest.cardStatus.text) {
      case "NEW":
        return { text: "SENT", level: CardStatusLevelEnum.DEFAULT };

      case "PR READY":
        return { text: "IN PROGRESS", level: CardStatusLevelEnum.INFO };

      case "IN PROGRESS":
        return { text: "IN PROGRESS", level: CardStatusLevelEnum.INFO };

      default:
        return {
          text: purchaseRequest.cardStatus.text,
          level: purchaseRequest.cardStatus.level,
        };
    }
  }

  // otherwise return approval workflow status
  if (purchaseRequest.latestApprovalWorkflow) {
    switch (
      getWorkflowStatusText(purchaseRequest.latestApprovalWorkflow.status)
    ) {
      case "APPROVED":
        return {
          text: "APPROVED",
          level: CardStatusLevelEnum.SUCCESS,
        };

      case "WAITING APPROVALS":
        return {
          text: "IN APPROVAL",
          level: CardStatusLevelEnum.DEFAULT,
        };

      default:
        return {
          text: getWorkflowStatusText(
            purchaseRequest.latestApprovalWorkflow.status,
          ),
          level: getWorkflowStatusLevel(
            purchaseRequest.latestApprovalWorkflow.status,
          ),
        };
    }
  }

  return { text: "UNKNOWN", level: CardStatusLevelEnum.WARNING };
}
