import React from "react";
import classNames from "classnames/bind";
import { SwimlaneIdEnum } from "../../schema";
import styles from "./ProgressStep.module.scss";

export interface ProgressStepProps {
  children?: React.ReactNode;
  stepSymbol?: string;
  className?: string;
  swimlaneType?: SwimlaneIdEnum;
  isActive?: boolean;
}

interface ProgressStepColorScheme extends React.CSSProperties {
  "--backgroundActiveColor"?: string;
  "--symbolActiveBackgroundColor"?: string;
}

const swimlaneTypeToColorScheme: { [key: string]: ProgressStepColorScheme } = {
  [SwimlaneIdEnum.PURCHASE_REQUEST]: {
    "--backgroundActiveColor": styles.laneColorPrBody,
    "--symbolActiveBackgroundColor": styles.colorSuccess,
  },
  [SwimlaneIdEnum.RFX]: {
    "--backgroundActiveColor": styles.laneColorRfxBody,
    "--symbolActiveBackgroundColor": styles.colorSuccess,
  },
  [SwimlaneIdEnum.PURCHASE_ORDER]: {
    "--backgroundActiveColor": styles.laneColorOrderBody,
    "--symbolActiveBackgroundColor": styles.colorSuccess,
  },
  [SwimlaneIdEnum.RECEIVING]: {
    "--backgroundActiveColor": styles.laneColorReceivingBody,
    "--symbolActiveBackgroundColor": styles.colorSuccess,
  },
  [SwimlaneIdEnum.INVOICE]: {
    "--backgroundActiveColor": styles.laneColorInvoicesBody,
    "--symbolActiveBackgroundColor": styles.colorSuccess,
  },
};

export const ProgressStep: React.FC<ProgressStepProps> = (props) => {
  const {
    children,
    stepSymbol,
    className,
    swimlaneType = SwimlaneIdEnum.PURCHASE_REQUEST,
    isActive,
  } = props;

  // bind classNames with styles
  const cn = classNames.bind((styles as unknown) as Record<string, string>);

  return (
    <div
      className={cn({ "wrap": true, "wrap--active": isActive }, className)}
      style={swimlaneTypeToColorScheme[swimlaneType]}
    >
      <div
        className={cn({ "step-symbol": true, "step-symbol--active": isActive })}
      >
        {stepSymbol}
      </div>
      <h2 className={cn({ "title": true, "title--active": isActive })}>
        {children}
      </h2>
    </div>
  );
};
