import React from "react";
import { Button } from "../../components/Button/Button";
import { Field } from "../../components/Field/Field";
import { Form } from "../../components/Form/Form";
import { Modal } from "../../components/Modal/Modal";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { useCreateContactPerson } from "../../schema";
import { isEmail } from "../../validators/isEmail";

export interface CreateContactPersonModalProps {
  supplierId: string;
  onModalClose(): void;
}

export const CreateContactPersonModal: React.FC<CreateContactPersonModalProps> = ({
  supplierId,
  onModalClose,
}) => {
  // setup form
  const {
    useInput,
    useCheckbox,
    loading,
    error,
    submit: createContactPerson,
  } = useForm({
    mutation: useCreateContactPerson,
    options: {
      refetchQueries: ["SupplierById"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const firstNameInput = useInput({ name: "firstName", optional: true });
  const lastNameInput = useInput({ name: "lastName", optional: true });
  const positionInput = useInput({ name: "position", optional: true });
  const phoneNumberInput = useInput({ name: "phoneNumber", optional: true });
  const emailInput = useInput({ name: "email", validators: [isEmail] });
  const notesInput = useInput({ name: "notes", optional: true });
  const isDefaultCheckbox = useCheckbox({ name: "isDefault" });

  // submits the form
  const submit = () => {
    createContactPerson({
      supplierId,
    });
  };

  return (
    <Modal
      title="Add new contact person"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button
            data-testid="55081b0da2"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="ff3fe94156"
            loading={loading}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={submit}>
        <Field {...firstNameInput} short autofocus label="First name" />
        <Field {...lastNameInput} short label="Last name" />
        <Field {...positionInput} short label="Position" />
        <Field {...phoneNumberInput} short label="Phone" />
        <Field {...emailInput} short label="email" />
        <Field {...notesInput} short label="Notes" />
        <Field {...isDefaultCheckbox} checkbox label="Is default">
          Is Default?
        </Field>
      </Form>
    </Modal>
  );
};
