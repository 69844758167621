import React from "react";
import classNames from "classnames";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { Button } from "../Button/Button";
import { Description } from "../Description/Description";
import styles from "./SupplierList.module.scss";

export interface Supplier {
  id: string;
  name: string | null;
  defaultContactPerson: {
    email: string;
  };
}

export interface SupplierListProps {
  suppliers: {
    id: string;
    defaultContactPerson: { email?: string | null };
    name?: string | null | undefined;
  }[];
  removingSupplierId?: string;
  hideLogo?: boolean;
  onRemove(supplier: {
    id: string;
    email?: string | null | undefined;
    name?: string | null | undefined;
  }): void;
}

export const SupplierList: React.FC<SupplierListProps> = ({
  suppliers,
  removingSupplierId,
  hideLogo = true,
  onRemove,
}) => (
  <div className={styles.wrap}>
    {suppliers.length === 0 && (
      <Description>
        No suppliers have been added, use the form above to add the first
        potential supplier.
      </Description>
    )}
    {suppliers.map((supplier) => (
      <div key={supplier.id} className={styles.row}>
        <div className={styles.supplier}>
          <div
            className={classNames(styles.logo, {
              [styles["logo--hidden"]]: hideLogo,
            })}
          ></div>
          <div className={classNames(styles["name-container"], "notranslate")}>
            <div className={styles["name-text"]}>
              {supplier.name || supplier.defaultContactPerson.email || (
                <em>Unknown supplier</em>
              )}
            </div>
          </div>

          {supplier.name && supplier.defaultContactPerson.email && (
            <div className={styles["email-container"]}>
              <div className={styles["email-text"]}>
                {supplier.defaultContactPerson.email}
              </div>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <Button
            data-testid="9fd3c72eb7"
            icon={<RemoveIcon className={styles["button-icon"]} />}
            disabled={removingSupplierId !== undefined}
            loading={removingSupplierId === supplier.id}
            onClick={() => onRemove(supplier)}
          />
        </div>
      </div>
    ))}
  </div>
);
