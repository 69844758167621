import { ActivityTypeEnum, ActivityFeedItemFragment, User } from "../schema";
import { UpdateNoticeProps } from "../components/UpdateNotice/UpdateNotice";

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

type ActivityFeedItemFragmentWithOptionalAttachments = Overwrite<
  Omit<ActivityFeedItemFragment, "replies">,
  { attachments?: ActivityFeedItemFragment["attachments"] }
>;

export type GetUpdateNoticeByActivityArgs = {
  activity: ActivityFeedItemFragmentWithOptionalAttachments;
};

export function getUpdateNoticeByActivity({
  activity,
}: GetUpdateNoticeByActivityArgs): UpdateNoticeProps | undefined {
  if (!activity.data || !activity.data.__typename) {
    return;
  }

  switch (activity.data.__typename) {
    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST: {
      const { code, creator } = activity.data;

      return {
        eventTitle: `New PR-${code}`,
        eventTime: activity.createdDate,
        eventDescription: `by ${creator.firstName} ${creator.lastName}`,
        avatarUser: creator as User,
      };
    }

    default:
      return;
  }
}
