import React from "react";
import { ViewerOrganizations } from "../../schema";
import { View } from "../../components/View/View";
import styles from "./AnalyticsAndKpiView.module.scss";

export interface AnalyticsAndKpiViewProps {
  organization: ViewerOrganizations;
}

export const AnalyticsAndKpiView: React.FC<AnalyticsAndKpiViewProps> = () => {
  return (
    <View secondary>
      <div className={styles["image-wrap"]}>
        <img
          src={require("./analytics.jpg")}
          className={styles.image}
          alt="analytics"
        />
      </div>
    </View>
  );
};
