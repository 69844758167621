import React, { forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";

import { Field } from "../Field/Field";
import { Button } from "../Button/Button";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import styles from "./EsourcingQuestionFieldList.module.scss";

export interface EsourcingQuestionFieldListProps {
  itemIndex: number;
  className?: string;
}

export const EsourcingQuestionFieldList = forwardRef(
  (props: EsourcingQuestionFieldListProps, ref) => {
    const { itemIndex, className } = props;

    const { register } = useFormContext();
    const { fields, remove, append } = useFieldArray({
      name: `items[${itemIndex}].esourcingFields.questions`,
    });

    useImperativeHandle(ref, () => ({
      append,
    }));

    const handleRemove = (questionIndex: number) => {
      return function () {
        remove(questionIndex);
      };
    };

    return (
      <div className={classNames(className)}>
        {fields.map((listItem, questionIndex) => {
          return (
            <div key={listItem.id} className={styles["esourcing-field"]}>
              <Controller
                as={Field}
                key={questionIndex}
                defaultValue={listItem.question ?? ""}
                name={`items[${itemIndex}].esourcingFields.questions[${questionIndex}].question`}
                label={`eSourcing Question ${questionIndex + 1}`}
                inputRef={register()}
                short
              />
              <Button
                className={styles["remove-button"]}
                icon={<RemoveIcon />}
                onClick={handleRemove(questionIndex)}
              />
            </div>
          );
        })}
      </div>
    );
  },
);
