import React, { useEffect } from "react";
import classNames from "classnames";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import { Separator } from "../Separator/Separator";
import { useAccordion } from "./Accordion.headless";
import styles from "./Accordion.module.scss";

export interface AccordionProps {
  isInitiallyOpen?: boolean;
  isSwitchable?: boolean;
  title?: React.ReactNode;
  infoText?: React.ReactNode;
  icon?: JSX.Element;
  className?: string;
  secondary?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  isInitiallyOpen = false,
  isSwitchable = true,
  title,
  infoText,
  icon,
  children,
  className,
  secondary,
}) => {
  const [isAccordionSwitchable, setIsAccordionSwitchable] = React.useState(
    isSwitchable,
  );

  const { isOpen, toggle, setOpen, setClosed } = useAccordion({
    initialState: { isOpen: isInitiallyOpen },
  });

  // set state if prop changes
  useEffect(() => {
    setIsAccordionSwitchable(isSwitchable);

    // when prop value changes, react accordingly
    if (isInitiallyOpen) {
      setOpen();
    } else {
      setClosed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwitchable, isInitiallyOpen]);

  return (
    <div
      className={classNames(styles["accordion-container"], {
        [styles["accordion-container--secondary"]]: secondary,
      })}
    >
      {isAccordionSwitchable ? (
        <div className={classNames(styles["accordion-header"], className)}>
          {icon && <div className={styles["icon"]}>{icon}</div>}
          <button
            data-testid="4a79e6dd07"
            type="button"
            className={styles.accordion}
            onClick={() => {
              toggle();
            }}
          >
            <Separator
              className={styles.title}
              infoText={infoText}
              title={
                <>
                  {isOpen ? (
                    <DownArrowIcon className={styles["arrow-icon"]} />
                  ) : (
                    <DownArrowIcon
                      className={classNames(
                        styles["arrow-icon"],
                        styles["arrow-icon--closed"],
                      )}
                    />
                  )}
                  {title}
                </>
              }
            />
          </button>
        </div>
      ) : (
        <Separator />
      )}
      <div
        data-state={isOpen ? "OPEN" : "CLOSED"}
        className={styles["children-state"]}
      >
        {children}
      </div>
    </div>
  );
};
