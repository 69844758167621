import React from "react";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import { Description } from "../Description/Description";

export const ExcelSuppliersImportExampleTable: React.FC = () => (
  <>
    <BasicTable>
      <thead>
        <tr>
          <Cell header nowrap>
            Name*
          </Cell>
          <Cell header nowrap>
            Email*
          </Cell>
          <Cell header nowrap>
            Web
          </Cell>
          <Cell header nowrap>
            Address
          </Cell>
          <Cell header nowrap>
            City
          </Cell>
          <Cell header nowrap>
            Zip / postal
          </Cell>
          <Cell header nowrap>
            Country
          </Cell>
          <Cell header nowrap>
            Payment Terms
          </Cell>
          <Cell header nowrap>
            Currency
          </Cell>
          <Cell header nowrap>
            VAT identification
          </Cell>
          <Cell header nowrap>
            Business registry
          </Cell>
          <Cell header nowrap>
            LEI code
          </Cell>
          <Cell header nowrap>
            D-U-N-S code
          </Cell>
          <Cell header nowrap>
            ERP Supplier code
          </Cell>
          <Cell header nowrap>
            Custom field 1
          </Cell>
          <Cell header nowrap>
            Custom field 2
          </Cell>
          <Cell header nowrap>
            Custom field 3
          </Cell>
          <Cell header nowrap>
            Status
          </Cell>
          <Cell header nowrap>
            Our contact
          </Cell>
          <Cell header nowrap>
            Notes
          </Cell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
        </tr>
        <tr>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
        </tr>
      </tbody>
    </BasicTable>
    <Description spacious>
      Columns marked with * indicate required values (column order is important,
      header titles are not).
    </Description>
  </>
);
