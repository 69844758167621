import * as React from "react";
export const DuplicateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 1024 1024" {...props}>
    <path
      d="M469.333333 426.666667H384v128H256v85.333333h128v128h85.333333v-128h128v-85.333333h-128z"
      fill=""
    />
    <path
      d="M128 938.666667h597.333333a42.666667 42.666667 0 0 0 42.666667-42.666667V298.666667a42.666667 42.666667 0 0 0-42.666667-42.666667H128a42.666667 42.666667 0 0 0-42.666667 42.666667v597.333333a42.666667 42.666667 0 0 0 42.666667 42.666667zM170.666667 341.333333h512v512H170.666667V341.333333z"
      fill=""
    />
    <path
      d="M896 85.333333H341.333333v85.333334h512v512h85.333334V128a42.666667 42.666667 0 0 0-42.666667-42.666667z"
      fill=""
    />
  </svg>
);
