import React from "react";
import { View } from "../View/View";
import styles from "./ErrorBoundary.module.scss";

interface ErrorBoundaryProps {
  errorLogger?: (error: Error, info: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (this.props.errorLogger) {
      // TODO: Implement and inject a useful logger
      this.props.errorLogger(error, info);
    }
  }

  render() {
    // render error state if any
    if (this.state.hasError) {
      // TODO: show nice error page
      return <View className={styles.wrap}>Oops! Something went wrong</View>;
    }

    // render normal children
    return this.props.children;
  }
}
