import React from "react";
import classNames from "classnames";
import { SuggestionDataItem } from "react-mentions";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import { Field } from "../Field/Field";
import { Attachments } from "../Attachments/Attachments";
import { Button } from "../Button/Button";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { useSendEmailToRequester, EmailCardTypeEnum } from "../../schema";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";
import { notEmpty } from "../../validators/notEmpty";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import { Avatar } from "../Avatar/Avatar";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import { UpArrowIcon } from "../../theme/svg/UpArrowIcon";
import { useCardContextInfo } from "../../contexts/card-context";
import { useViewer } from "../../contexts/viewer-context";
import { EmailCcInput } from "../EmailCcInput/EmailCcInput";
import styles from "./EmailReplyForm.module.scss";

export interface RequesterEmailReplyFormProps {
  parentId: string;
  receiver: {
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  };
  subject: string;
  defaultCcEmails?: string | null;
  addressBookEmails?: SuggestionDataItem[];
  className?: string;
  displayNarrowView?: boolean;
  onSendSuccess: (email: string) => void;
  onSendError: (email: string) => void;
  onCancel?: () => void;
}

export const RequesterEmailReplyForm: React.FC<RequesterEmailReplyFormProps> =
  ({
    parentId,
    receiver,
    subject,
    defaultCcEmails,
    addressBookEmails,
    className,
    displayNarrowView = false,
    onSendSuccess,
    onSendError,
    onCancel,
  }) => {
    // email attachments
    const [emailFiles, setEmailFiles] = React.useState<File[]>();
    const [isExtraInfoVisible, setIsExtraInfoVisible] = React.useState(true);
    const [isCcFieldVisible, setIsCcFieldVisible] = React.useState(
      defaultCcEmails ? true : false,
    );
    const [ccEmails, setCcEmails] = React.useState<string[]>(
      defaultCcEmails ? defaultCcEmails.split(",") : [],
    );

    // use viewer context
    const sender = useViewer();

    // use card context
    const card = useCardContextInfo();

    // set files state on drop
    const onEmailDrop = React.useCallback<OnDropCallbackFn>(
      (acceptedFiles) => {
        // set selected file
        setEmailFiles([...(emailFiles ? emailFiles : []), ...acceptedFiles]);
      },
      [emailFiles],
    );

    // configure email form
    const {
      useInput: useReplyInput,
      submit: submitReply,
      loading: isSubmitReplyLoading,
      reset: resetReplyForm,
    } = useForm({
      mutation: useSendEmailToRequester,
      options: {
        refetchQueries: ["PurchaseRequestByCode"],
        awaitRefetchQueries: true,
      },
      onSuccess: () => {
        resetReplyForm();
        setEmailFiles(undefined);
        onSendSuccess(receiver.email);
      },
      onError: () => {
        onSendError(receiver.email);
      },
    });

    // configure email inputs
    const ccInput = useReplyInput({
      name: "ccEmail",
      optional: true,
    });
    const messageInput = useReplyInput({
      name: "message",
      validators: [notEmpty],
    });

    // handle form on cancelling
    const handleCancel = () => {
      setEmailFiles(undefined);
      resetReplyForm();

      if (typeof onCancel === "function") {
        onCancel();
      }
    };

    const handleCcEmailChange = (updatedEmails: string[]) => {
      setCcEmails(updatedEmails);
    };

    // handle missing data
    if (!card || !receiver || !sender) {
      throw Error(
        "No card, receiver or sender present. This component must be used inside CardContextProvider and ViewerContextProvider.",
      );
    }

    // sends supplier email
    const sendEmail = () => {
      submitReply({
        parentId,
        toEmail: receiver.email,
        ccEmail: isCcFieldVisible ? ccEmails.join(",") : undefined,
        cardId: card.id,
        cardType: card.type as unknown as EmailCardTypeEnum, // TODO: make narrower here or wider on server side
        requesterId: receiver.id,
        subject,
        attachments: emailFiles,
      });
    };

    return (
      <div className={classNames(styles["wrap"], className)}>
        {/* first row */}
        <div className={styles["avatar"]}>
          <Avatar user={sender} />
        </div>

        <div className={styles["email-to"]}>
          <span className={styles["field-label"]}>TO</span>
          <div
            className={styles["field-value"]}
          >{`${receiver.firstName} ${receiver.lastName} <${receiver.email}>`}</div>
        </div>

        {!displayNarrowView && (
          <div className={styles["toggle-cell"]}>
            <Button
              className={styles["toggle-button"]}
              onClick={() => {
                setIsExtraInfoVisible(!isExtraInfoVisible);
              }}
              icon={isExtraInfoVisible ? <DownArrowIcon /> : <UpArrowIcon />}
            />
          </div>
        )}

        {/* extra info area  */}
        {!displayNarrowView && isExtraInfoVisible && (
          <div className={styles["extra-info"]}>
            <div className={styles["email-from"]}>
              <span className={styles["field-label"]}>FROM</span>
              <div
                className={styles["field-value"]}
              >{`${sender.firstName} ${sender.lastName} <${sender.email}>`}</div>
            </div>
            <div className={styles["email-subject"]}>
              <span className={styles["field-label"]}>SUBJECT</span>
              <div className={styles["field-value"]}>{subject}</div>
            </div>
          </div>
        )}

        {/* body area */}
        <div className={styles["body-area"]}>
          <div className={styles["cc-toggle-container"]}>
            <Button
              text
              className={classNames({
                [styles["add-button"]]: !isCcFieldVisible,
                [styles["close-button"]]: isCcFieldVisible,
              })}
              onClick={() => {
                setIsCcFieldVisible(!isCcFieldVisible);

                if (isCcFieldVisible) {
                  ccInput.reset();
                }
              }}
            >
              {isCcFieldVisible ? "Remove CC" : "Add CC"}
            </Button>
          </div>

          {isCcFieldVisible && (
            <div>
              <span className={styles["field-label"]}>CC</span>
              <EmailCcInput
                input={ccInput}
                ccEmails={ccEmails}
                addressBookEmails={addressBookEmails}
                handleCcEmailChange={handleCcEmailChange}
              />
            </div>
          )}

          <div className={styles["reply-field"]}>
            <span className={styles["field-label"]}>REPLY</span>
            <Field {...messageInput} textarea placeholder="Message" />
          </div>
        </div>

        {/* footer area */}
        <div className={styles["footer-area"]}>
          <div className={styles["footer-button-row"]}>
            <Attachments
              multiple
              loading={false}
              onDrop={(acceptedFiles) => onEmailDrop(acceptedFiles)}
              className={styles["attachments-button"]}
            />

            <Button
              data-testid="d31dbc1e7a"
              secondary
              onClick={handleCancel}
              className={styles["footer-button"]}
            >
              Cancel
            </Button>

            <Button
              data-testid="324007cbe7"
              onClick={sendEmail}
              loading={isSubmitReplyLoading}
              disabled={isSubmitReplyLoading}
              className={styles["footer-button"]}
            >
              Send email
            </Button>
          </div>

          <div className={styles["attachments"]}>
            {emailFiles && emailFiles.length > 0 && (
              <div>
                {emailFiles.map((file) => (
                  <div key={file.name} className={styles["attachment-item"]}>
                    <DeleteFileIcon
                      className={classNames(styles["delete-file-icon"])}
                      onClick={() =>
                        setEmailFiles(
                          emailFiles.filter(
                            (stateFile) => file.name !== stateFile.name,
                          ),
                        )
                      }
                    />
                    {file.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {isSubmitReplyLoading && <LoadingView overlay />}
      </div>
    );
  };
