import React from "react";
import { PriceMap } from "./calculateItemPriceTotals";
import { formatMoney } from "./formatMoney";

export function renderPrices(prices: PriceMap) {
  return Object.keys(prices).map((currency) => (
    <div key={currency}>
      {formatMoney(prices[currency] as number)} {currency}
    </div>
  ));
}
