import React from "react";
import { Link } from "react-router-dom";
import { View } from "../../../components/View/View";
import { useTimerSubscription } from "../../../schema";
import { LoadingView } from "../../LoadingView/LoadingView";
import { ErrorView } from "../../ErrorView/ErrorView";

export const SubscriptionsView: React.FC = () => {
  const { data, error, loading } = useTimerSubscription();

  if (error) {
    return <ErrorView error={error} />;
  }

  if (loading || !data) {
    return <LoadingView />;
  }

  const timer = data.timer;

  return (
    <View>
      <h1>
        <Link data-testid="6d02d91ba2" to="/experiments">
          Experiments
        </Link>{" "}
        / Subscriptions
      </h1>
      {timer}
    </View>
  );
};
