import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { View } from "../../components/View/View";
import { ViewerViewer } from "../../schema";
import { MaintenanceView } from "../MaintenanceView/MaintenanceView";
import { DragDropView } from "./DragDropView/DragDropView";
import styles from "./ExperimentsView.module.scss";
import { UploadView } from "./UploadView/UploadView";
import { SubscriptionsView } from "./SubscriptionView/SubscriptionView";
import { FormView } from "./FormView/FormView";

export interface ExperimentsViewProps {
  viewer: ViewerViewer | null;
}

export const ExperimentsView: React.FC<ExperimentsViewProps> = ({ viewer }) => (
  <View className={styles.view}>
    <Switch>
      <Route exact path="/experiments/drag-drop">
        <DragDropView />
      </Route>
      <Route exact path="/experiments/upload">
        <UploadView viewer={viewer} />
      </Route>
      <Route exact path="/experiments/subscriptions">
        <SubscriptionsView />
      </Route>
      <Route exact path="/experiments/maintenanceview">
        <MaintenanceView />
      </Route>
      <Route exact path="/experiments/form">
        <FormView />
      </Route>
      <Route>
        <h1>Experiments</h1>
        <ul>
          <li>
            <Link data-testid="0b24e874ed" to="/experiments/drag-drop">
              Drag and Drop
            </Link>
          </li>
          <li>
            <Link data-testid="3136ac106f" to="/experiments/upload">
              Upload
            </Link>
          </li>
          <li>
            <Link data-testid="1656e27fe7" to="/experiments/subscriptions">
              Subscriptions
            </Link>
          </li>
          <li>
            <Link data-testid="1656e27fe7" to="/experiments/form">
              Form
            </Link>
          </li>
          <li>
            <Link to="/experiments/maintenanceview">Maintenance view</Link>
          </li>
        </ul>
      </Route>
    </Switch>
  </View>
);
