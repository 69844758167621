import React from "react";
import classNames from "classnames";

import { LoadingIcon } from "../../theme/svg/LoadingIcon";
import styles from "./Loading.module.scss";

export interface LoadingProps {
  small?: boolean;
  large?: boolean;
  padded?: boolean;
  show?: boolean;
  className?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  small,
  large,
  padded,
  show,
  className,
}) => (
  <div data-testid="is-loading" className={styles.loading}>
    {" "}
    <LoadingIcon
      className={classNames(className, styles.icon, {
        [styles["icon--small"]]: small,
        [styles["icon--large"]]: large,
        [styles["loading--padded"]]: padded,
        [styles["icon--showable"]]: typeof show === "boolean",
        [styles["icon--show"]]: show,
      })}
    />{" "}
  </div>
);
