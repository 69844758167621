import { ApprovalWorkflowStatusEnum, CardStatusLevelEnum } from "../schema";
import { assertUnreachable } from "./assertUnreachable";

export function getWorkflowCardStatusLevel(status: ApprovalWorkflowStatusEnum) {
  switch (status) {
    case ApprovalWorkflowStatusEnum.DECLINED:
    case ApprovalWorkflowStatusEnum.CANCELLED: {
      return CardStatusLevelEnum.ALERT;
    }

    case ApprovalWorkflowStatusEnum.APPROVED:
    case ApprovalWorkflowStatusEnum.DRAFT:
    case ApprovalWorkflowStatusEnum.IN_PROGRESS: {
      return CardStatusLevelEnum.SUCCESS;
    }

    default:
      return assertUnreachable(
        status,
        `Approval workflow status "${status}" is not handled, this should not happen`,
      );
  }
}
