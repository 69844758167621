import React from "react";
import { PurchaseRequestDetails } from "../../components/PurchaseRequestDetails/PurchaseRequestDetails";
import { PurchaseRequestSummary } from "../../components/PurchaseRequestSummary/PurchaseRequestSummary";
import { useActiveOrganization } from "../../hooks/useActiveOrganization";
import { useRouter } from "../../hooks/useRouter";
import {
  ViewerViewer,
  usePurchaseRequestsDetailsByCode,
  RequesterPortalViewOrganization,
  PurchaseRequestsDetailsByCodePurchaseRequestByCode,
} from "../../schema";
import { getRequesterPortalCardStatus } from "../../services/getRequesterPortalCardStatus";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";

interface RouteParams {
  purchaseRequestId: string;
}

interface DetailRequestFromPortalViewProps {
  organization: RequesterPortalViewOrganization;
  viewer: ViewerViewer;
}

export const DetailRequestFromPortalView: React.FC<DetailRequestFromPortalViewProps> = ({
  organization,
  viewer,
}) => {
  // extract request type from URL parameters
  const { match, history } = useRouter<RouteParams>();
  const { purchaseRequestId } = match.params;
  const activeOrganization = useActiveOrganization(viewer.organizations);

  // Initial states
  let purchaseRequest:
    | PurchaseRequestsDetailsByCodePurchaseRequestByCode
    | undefined = undefined;

  // Go to edit purchase request
  const editPurchaseRequest = () => {
    history.push(`${match.url}/edit`);
  };

  // Resolve required information
  // Load purchase request
  const {
    data,
    error: purchaseRequestError,
    loading,
  } = usePurchaseRequestsDetailsByCode({
    fetchPolicy: "network-only",
    variables: {
      organizationId: activeOrganization!.id,
      code: purchaseRequestId,
    },
  });

  // ERROR VIEW IF HAS ERROR
  if (purchaseRequestError !== undefined)
    return <ErrorView error={purchaseRequestError}></ErrorView>;

  // LOADING
  if (loading || !data) {
    return <LoadingView overlay></LoadingView>;
  }

  if (!organization) {
    return <WithoutOrganizationView viewer={viewer} />;
  }

  purchaseRequest = data?.viewer
    ? data.viewer.purchaseRequestByCode
    : undefined;

  return (
    <>
      {!purchaseRequest ? (
        <LoadingView overlay></LoadingView>
      ) : (
        <>
          <PurchaseRequestSummary
            assignee={purchaseRequest.assignee || undefined}
            cardStatus={getRequesterPortalCardStatus(purchaseRequest)}
            code={purchaseRequest.code}
            department={purchaseRequest.department || undefined}
            name={purchaseRequest.name}
            id={purchaseRequest.id}
            items={purchaseRequest.items || undefined}
            project={purchaseRequest.project || undefined}
          />
          <PurchaseRequestDetails
            {...{ purchaseRequest, editPurchaseRequest, viewer }}
          />
        </>
      )}
    </>
  );
};
