import {
  NotificationsNotifications,
  ActivityTypeEnum,
  ActivityFeedItemFragment,
  NotificationsRelativeInfo,
} from "../schema";
import {
  NotificationItem,
  NotificationItemType,
} from "../components/NotificationDropdown/NotificationDropdown";
import { getNotificationMessageByActivity } from "./getNotificationMessageByActivity";
import { assertUnreachable } from "./assertUnreachable";

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type ActivityFeedItemFragmentWithOptionalAttachments = Overwrite<
  Omit<ActivityFeedItemFragment, "replies">,
  {
    attachments?: ActivityFeedItemFragment["attachments"];
    relativeInfo?: NotificationsRelativeInfo | null;
  }
>;

export function getNotificationInfo(
  organizationUrl: string,
  notification: NotificationsNotifications,
): NotificationItem {
  return {
    id: notification.id,
    type: getNotificationType(notification.activity.type),
    user: getNotificationUser(notification),
    text: getNotificationMessageByActivity(
      organizationUrl,
      undefined,
      notification.activity,
    ),
    code: getNotificationCode(notification.activity),
    hasSeen: notification.hasSeen,
    date: notification.createdDate,
  };
}

function getNotificationUser(notification: NotificationsNotifications) {
  const activity = notification.activity;
  const unknownName = "Someone";

  switch (activity.data.__typename) {
    case ActivityTypeEnum.COMMENT:
    case ActivityTypeEnum.REPLY:
    case ActivityTypeEnum.EMAIL_INBOUND_MENTION:
    case ActivityTypeEnum.EMAIL_OUTBOUND_MENTION: {
      const name = `${activity.data.user.firstName} ${activity.data.user.lastName}`;

      return { name };
    }
    case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER:
    case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER: {
      const name =
        activity.data.supplierInfo.name ||
        activity.data.supplierInfo.email ||
        unknownName;

      return { name };
    }

    default: {
      const authorName = notification.activity.author
        ? `${notification.activity.author.firstName} ${notification.activity.author.lastName}`
        : undefined;

      return {
        name: authorName || unknownName,
      };
    }
  }
}

function getNotificationCode(
  activity: ActivityFeedItemFragmentWithOptionalAttachments,
) {
  switch (activity.data.__typename) {
    case ActivityTypeEnum.ASSIGN: {
      const { itemInfo } = activity.data;

      return itemInfo.code;
    }

    case ActivityTypeEnum.COMMENT:
    case ActivityTypeEnum.REPLY: {
      const { relativeInfo } = activity.data;

      if (relativeInfo.__typename !== "ActivityRelativeCardInfo") {
        return null;
      }

      return relativeInfo.code;
    }

    case ActivityTypeEnum.EMAIL_INBOUND_REQUESTER:
    case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER:
    case ActivityTypeEnum.EMAIL_INBOUND_MENTION: {
      const relativeInfo = activity.relativeInfo;

      if (relativeInfo?.__typename === "ActivityRelativeCardInfo") {
        return relativeInfo.code;
      }

      return null;
    }

    case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER: {
      const data = activity.data;
      const { rfxInfo, orderInfo } = data;

      if (rfxInfo) {
        return `${rfxInfo.type}-${rfxInfo.code}`;
      }

      if (orderInfo) {
        return `${orderInfo.type}-${orderInfo.code}`;
      }

      return null;
    }

    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST: {
      const messageData = activity.data;

      return `PR-${messageData.code}`;
    }

    default:
      return null;
  }
}

function getNotificationType(
  activityType: ActivityTypeEnum,
): NotificationItemType {
  switch (activityType) {
    case ActivityTypeEnum.ASSIGN:
    case ActivityTypeEnum.DRAG_CARD:
    case ActivityTypeEnum.ADD_ATTACHMENT:
    case ActivityTypeEnum.DRAG_ITEM:
    case ActivityTypeEnum.CREATE_APPROVAL_REQUEST:
    case ActivityTypeEnum.CREATE_APPROVAL_WORKFLOW:
    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST:
    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST_PRODUCT:
    case ActivityTypeEnum.CREATE_PURCHASE_REQUEST_SERVICE:
    case ActivityTypeEnum.UPDATE_APPROVAL_REQUEST:
    case ActivityTypeEnum.UPDATE_APPROVAL_WORKFLOW:
    case ActivityTypeEnum.EMAIL_INBOUND_MENTION:
    case ActivityTypeEnum.EMAIL_INBOUND_REQUESTER:
    case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER:
    case ActivityTypeEnum.EMAIL_OUTBOUND_MENTION:
    case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER:
    case ActivityTypeEnum.ADD_ITEM:
    case ActivityTypeEnum.ADD_ITEMS:
      return NotificationItemType.INFO;

    case ActivityTypeEnum.COMMENT:
    case ActivityTypeEnum.CREATE_SUPPLIER:
    case ActivityTypeEnum.UPDATE_SUPPLIER:
    case ActivityTypeEnum.REPLY:
      return NotificationItemType.COMMENT;

    default:
      return assertUnreachable(
        activityType,
        `Activity "${activityType}" is not handled, this should not happen`,
      );
  }
}
