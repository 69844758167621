import React, { useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { Link } from "react-router-dom";
import { Debug } from "../../../components/Debug/Debug";
import { View } from "../../../components/View/View";

interface Item {
  itemId: string;
  name: string;
  suppliers: Supplier[];
}

interface Supplier {
  supplierId: string;
  name: string;
  isSelected: boolean;
}

interface FormValues {
  email: string;
  items: Item[];
}

interface ItemRowProps {
  item: Item;
  itemIndex: number;
  onSuppliersChanged(): void;
}

export const ItemRow: React.FC<ItemRowProps> = ({
  item,
  itemIndex,
  onSuppliersChanged,
}) => {
  const { register, control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray<Supplier>({
    control,
    name: `items[${itemIndex}].suppliers`,
    keyName: "id",
  });

  useEffect(() => {
    if (fields.length < 3) {
      append({
        supplierId: "hookSupplier",
        name: "Hook supplier",
        isSelected: true,
      });
    }
  }, [fields]);

  const excludedItemSuppliersWatch = watch(`items[${itemIndex}].suppliers`);

  useEffect(() => {
    onSuppliersChanged();
  }, [excludedItemSuppliersWatch]);

  return (
    <div>
      <h3>{item.name}</h3>

      {fields.map((supplierField, supplierIndex) => (
        <p key={supplierField.id}>
          <label>
            <input
              type="hidden"
              name={`items[${itemIndex}].suppliers[${supplierIndex}].supplierId`}
              value={supplierField.supplierId}
              ref={register()}
            />
            <input
              type="hidden"
              name={`items[${itemIndex}].suppliers[${supplierIndex}].name`}
              value={supplierField.name}
              ref={register()}
            />
            <input
              type="checkbox"
              ref={register()}
              name={`items[${itemIndex}].suppliers[${supplierIndex}].isSelected`}
              defaultChecked={supplierField.isSelected}
            />
            Supplier {supplierField.name}
          </label>
        </p>
      ))}

      <p>
        <button onClick={() => remove(0)}>Remove first</button>
      </p>
      <p>
        <button
          onClick={() =>
            append({
              supplierId: "supplier4",
              name: "Fourth supplier",
              isSelected: true,
            })
          }
        >
          Append supplier
        </button>
      </p>

      <Debug title={`Item ${item.name}`}>{{ fields }}</Debug>
    </div>
  );
};

function getUniqueSelectedSuppliers(items: Item[]): Supplier[] {
  const uniqueSuppliers: Supplier[] = [];

  for (const item of items) {
    for (const supplier of item.suppliers) {
      if (
        supplier.isSelected &&
        !uniqueSuppliers.find(
          (uniqueSupplier) => uniqueSupplier.supplierId === supplier.supplierId,
        )
      ) {
        uniqueSuppliers.push(supplier);
      }
    }
  }

  return uniqueSuppliers;
}

export const FormView: React.FC = () => {
  const items: Item[] = [
    {
      itemId: "item1",
      name: "First item",
      suppliers: [
        {
          supplierId: "supplier1",
          name: "First supplier",
          isSelected: false,
        },
        {
          supplierId: "supplier2",
          name: "Second supplier",
          isSelected: true,
        },
        {
          supplierId: "supplier3",
          name: "Third supplier",
          isSelected: false,
        },
      ],
    },
    {
      itemId: "item2",
      name: "Second item",
      suppliers: [
        {
          supplierId: "supplier5",
          name: "Coop",
          isSelected: true,
        },
        {
          supplierId: "supplier6",
          name: "Prisma",
          isSelected: false,
        },
        {
          supplierId: "supplier7",
          name: "Selver",
          isSelected: true,
        },
      ],
    },
  ];

  const [selectedSuppliers, setSelectedSuppliers] = useState(
    getUniqueSelectedSuppliers(items),
  );

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: "john@smith.com",
      items,
    },
  });
  const { register, getValues, handleSubmit } = formMethods;

  const [submittedValues, setSubmittedValues] = useState<FormValues | null>(
    null,
  );

  const onSubmit = (values: FormValues) => {
    setSubmittedValues(values);
  };

  const onSuppliersChanged = () => {
    const { items } = getValues();

    const uniqueSelectedSuppliers = getUniqueSelectedSuppliers(items);

    const haveSelectedSuppliersChanged = !areArraysEqual(
      uniqueSelectedSuppliers,
      selectedSuppliers,
    );

    if (haveSelectedSuppliersChanged) {
      setSelectedSuppliers(getUniqueSelectedSuppliers(items));
    }
  };

  return (
    <View>
      <h1>
        <Link data-testid="6d02d91ba2" to="/experiments">
          Experiments
        </Link>{" "}
        / Form
      </h1>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>
            <input type="text" name="email" ref={register()} />
          </p>

          {items.map((item, index) => (
            <ItemRow
              key={item.itemId}
              item={item}
              itemIndex={index}
              onSuppliersChanged={onSuppliersChanged}
            />
          ))}

          <input type="submit" />
        </form>
      </FormProvider>

      <Debug title="selectedSuppliers">{selectedSuppliers}</Debug>
      <Debug title="getValues">{getValues()}</Debug>
      <Debug title="submittedValues">{submittedValues}</Debug>
    </View>
  );
};

function areArraysEqual<T>(a: T[], b: T[]) {
  if (a.length !== b.length) {
    return false;
  }

  return JSON.stringify(a) === JSON.stringify(b);
}
