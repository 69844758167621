import React from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray } from "react-hook-form";
import { PlusIcon } from "../../theme/svg/PlusIcon";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import { Button } from "../Button/Button";
import { NumberInput } from "../NumberInput/NumberInput";
import { useReadOnlyContext } from "../../contexts/readonly-context";
import styles from "./QuantityList.module.scss";

export interface QuantityListProps {
  className?: string;
  unit?: string | null;
  itemIndex: number;
  onChange?: (itemId: number) => void;
}

export const QuantityList: React.FC<QuantityListProps> = (props) => {
  const { className, unit, itemIndex, onChange } = props;

  // use read only context
  const isReadOnly = useReadOnlyContext();

  const { register, control, watch, setError, errors } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `items[${itemIndex}].priceInfo`,
    keyName: "id",
  });

  const currentPriceInfoList = watch(`items[${itemIndex}].priceInfo`);

  // when quantity is added or removed from current item's list notify parent
  React.useEffect(() => {
    if (typeof onChange === "function") {
      onChange(itemIndex);
    }
  }, [currentPriceInfoList]);

  const handleAppend = () => {
    append({ quantity: "" });
  };

  const handleRemove = (index: number) => {
    if (currentPriceInfoList && currentPriceInfoList.length === 1) {
      return function () {
        setError(`items[${itemIndex}].priceInfo[${index}].quantity`, {
          type: "manual",
          message:
            "Each item should have at least one quantity. The suggested default for services is 1.",
          shouldFocus: true,
        });
      };
    }

    return function () {
      remove(index);
    };
  };

  return (
    <div className={classNames(styles["wrap"], className)}>
      {fields.map((field, priceInfoIndex) => {
        return (
          <div key={`${field.id}`} className={styles["item"]}>
            {!isReadOnly && (
              <Button
                icon={<DeleteFileIcon />}
                className={styles["remove-button"]}
                onClick={handleRemove(priceInfoIndex)}
              />
            )}
            <NumberInput
              value={field?.quantity ?? ""}
              className={styles["quantity-input"]}
              name={`items[${itemIndex}].priceInfo[${priceInfoIndex}].quantity`}
              readOnly={isReadOnly}
              errors={
                errors?.items &&
                errors.items[itemIndex] &&
                Array.isArray(errors.items[itemIndex].priceInfo) &&
                errors.items[itemIndex].priceInfo.map(
                  (error: typeof errors) => error.quantity.message,
                )
              }
              inputRef={register()}
            />
            <div className={styles["quantity-unit"]}>{unit}</div>
          </div>
        );
      })}

      {!isReadOnly && (
        <Button
          className={styles["add-button"]}
          icon={<PlusIcon className={styles["add-button-icon"]} />}
          onClick={handleAppend}
        ></Button>
      )}
    </div>
  );
};
