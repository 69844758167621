export function round(value: number, decimalPlaces = 2): number {
  if (value === 0) {
    return 0;
  }

  const multiplier = Math.pow(10, decimalPlaces);

  const roundedValue = Math.round(value * multiplier) / multiplier;

  if (roundedValue === 0) {
    return round(value, decimalPlaces + 1);
  }

  return roundedValue;
}
