import React from "react";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { SupplierIcon } from "../../theme/svg/SupplierIcon";
import { ContactPersonIcon } from "../../theme/svg/ContactPersonIcon";
import { Select } from "../Select/Select";
import {
  CountryCodeEnum,
  useCountries,
  useSupplierSelfRegistration,
} from "../../schema";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import { Button } from "../Button/Button";
import { FieldError } from "../FieldError/FieldError";
import styles from "./SupplierSelfRegistrationForm.module.scss";

export interface SupplierSelfRegistrationFormProps {
  className?: string;
  email: string;
  organizationId: string;
  sourcingEventLinkToken: string;
  sourcingEventContactPersonId: string;
  handleRegistrationSuccess: () => void;
}

export interface SupplierSelfRegistrationFormInputs {
  companyName: string;
  url: string;
  zip: string;
  city: string;
  countryCode: string;
  euVatNumber: string;
  duns: string;
  contactName: string;
  position: string;
}

export const SupplierSelfRegistrationForm: React.FC<SupplierSelfRegistrationFormProps> = (
  props,
) => {
  const {
    className,
    email,
    organizationId,
    sourcingEventLinkToken,
    sourcingEventContactPersonId,
    handleRegistrationSuccess,
  } = props;

  const {
    data: countriesData,
    loading: isCountriesDataLoading,
  } = useCountries();

  const [
    supplierSelfRegistration,
    { loading: isSupplierSelfRegistrationLoading },
  ] = useSupplierSelfRegistration();

  const { register, handleSubmit, control, errors } = useForm<
    SupplierSelfRegistrationFormInputs
  >();

  const onSubmit = async (formData: SupplierSelfRegistrationFormInputs) => {
    // send data
    const {
      data: registrationResponse,
      errors: registrationErrors,
    } = await supplierSelfRegistration({
      variables: {
        ...formData,
        countryCode: formData.countryCode as CountryCodeEnum,
        sourcingEventContactPersonId,
        sourcingEventLinkToken,
        organizationId,
        email,
      },
    });

    // no response form server or errors
    if (!registrationResponse || registrationErrors) {
      // TODO: show notification about errors
      return;
    }

    handleRegistrationSuccess();
  };

  if (!countriesData || isCountriesDataLoading) {
    return <LoadingView />;
  }

  return (
    <div className={classNames(styles["wrap"], className)}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={styles["email-lookup-form"]}
      >
        {/* Company details */}
        <section
          className={classNames(styles["subform"], styles["company-details"])}
        >
          <div className={styles["header"]}>
            <SupplierIcon className={styles["header-icon"]} />
            <div>Company details</div>
          </div>
          <div className={styles["form-body"]}>
            <div className={styles["table"]}>
              {/* company name */}
              <div className={styles["row"]}>
                <label
                  htmlFor="companyName"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                    styles["label--required"],
                  )}
                >
                  Official name
                </label>
                <input
                  type="text"
                  name="companyName"
                  ref={register({ required: "Company name is required" })}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
                {errors?.companyName !== undefined && (
                  <FieldError
                    key={"test"}
                    error={errors?.companyName.message}
                  />
                )}
              </div>

              {/* web address */}
              <div className={styles["row"]}>
                <label
                  htmlFor="url"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                  )}
                >
                  Web address
                </label>
                <input
                  type="text"
                  name="url"
                  ref={register()}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>

              {/* city */}
              <div className={styles["row"]}>
                <label
                  htmlFor="city"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                  )}
                >
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  ref={register()}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>

              {/* zip */}
              <div className={styles["row"]}>
                <label
                  htmlFor="zip"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                  )}
                >
                  ZIP / Postal code
                </label>
                <input
                  type="text"
                  name="zip"
                  ref={register()}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>

              {/* country */}
              <div className={styles["row"]}>
                <label
                  htmlFor="countryCode"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                  )}
                >
                  Country
                </label>
                <Controller
                  className={styles["input"]}
                  name="countryCode"
                  as={Select}
                  control={control}
                  options={
                    countriesData.countries
                      ? [{ label: "-", value: "" }].concat(
                          countriesData.countries.map((country) => ({
                            label: country.name,
                            value: country.code,
                          })),
                        )
                      : []
                  }
                />
              </div>

              {/* EU VAT number */}
              <div className={styles["row"]}>
                <label
                  htmlFor="euVatNumber"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                    styles["label--secondary"],
                  )}
                >
                  EU VAT number
                </label>
                <input
                  type="text"
                  name="euVatNumber"
                  ref={register()}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>

              {/* D-U-N-S */}
              <div className={styles["row"]}>
                <label
                  htmlFor="duns"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                    styles["label--secondary"],
                  )}
                >
                  D-U-N-S
                </label>
                <input
                  type="text"
                  name="duns"
                  ref={register()}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Contact person */}
        <section
          className={classNames(styles["subform"], styles["contact-person"])}
        >
          <div className={styles["header"]}>
            <ContactPersonIcon className={styles["header-icon"]} />
            <div>Contact person</div>
          </div>
          <div className={styles["form-body"]}>
            <div className={styles["table"]}>
              {/* Name */}
              <div className={styles["row"]}>
                <label
                  htmlFor="contactName"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                    styles["label--required"],
                  )}
                >
                  Name
                </label>
                <input
                  type="text"
                  name="contactName"
                  ref={register({ required: "Name is required" })}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
                {errors?.contactName !== undefined && (
                  <FieldError
                    key={"test"}
                    error={errors?.contactName.message}
                  />
                )}
              </div>

              {/* Email */}
              <div className={styles["row"]}>
                <label
                  htmlFor="email"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                    styles["label"],
                  )}
                >
                  Email
                </label>
                <input
                  type="text"
                  name="contactName"
                  value={email}
                  readOnly
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>

              {/* Position */}
              <div className={styles["row"]}>
                <label
                  htmlFor="position"
                  className={classNames(
                    styles["cell"],
                    styles["first-col"],
                    styles["label"],
                  )}
                >
                  Position
                </label>
                <input
                  type="text"
                  name="position"
                  ref={register()}
                  className={classNames(
                    styles["cell"],
                    styles["second-col"],
                    styles["input"],
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        <div className={styles["form-actions"]}>
          <Button
            type="submit"
            className={styles["submit-button"]}
            loading={isSupplierSelfRegistrationLoading}
          >
            Request Access
          </Button>
        </div>
      </form>
    </div>
  );
};
