import { compose, replace, toLower } from "ramda";

export function encodeNameForURL(eventName: string) {
  return compose(
    encodeURIComponent,
    toLower,
    replace(/[^a-zA-Z0-9-]/g, ""),
    replace(/ /g, "-"),
  )(eventName);
}
