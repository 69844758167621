import React from "react";
import {
  ViewerOrganizations,
  useGetProjectsSuppliersDepartments,
} from "../../schema";
import { ErrorView } from "../../views/ErrorView/ErrorView";
import {
  SelectedFilters,
  FilterDropdownsOpen,
} from "../../views/MemberView/MemberView";
import { Button } from "../Button/Button";
import { DateRangeFilter } from "../DateRangeFilter/DateRangeFilter";
import { DropdownItem } from "../Dropdown/Dropdown";
import { SubMenu } from "../Menu/Menu";
import { MenuFilter } from "../MenuFilter/MenuFilter";

export interface MainMenuFiltersProps {
  isOpen: boolean;
  activeOrganization: ViewerOrganizations;
  selectedFilters: SelectedFilters | undefined;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<SelectedFilters | undefined>
  >;
  filterDropdownsOpen: FilterDropdownsOpen;
  setFilterDropdownsOpen: React.Dispatch<
    React.SetStateAction<FilterDropdownsOpen>
  >;
}

export const MainMenuFilters: React.FC<MainMenuFiltersProps> = ({
  isOpen,
  activeOrganization,
  selectedFilters,
  setSelectedFilters,
  filterDropdownsOpen,
  setFilterDropdownsOpen,
}) => {
  const { data, error } = useGetProjectsSuppliersDepartments({
    variables: { id: activeOrganization.id },
  });

  // extract data
  const projects = data?.viewer && data.viewer.organization.projects;
  const departments = data?.viewer && data.viewer.organization.departments;
  const suppliers = data?.viewer && data.viewer.organization.suppliers;

  if (error) {
    return (
      <ErrorView error="Project, supplier and department data not found." />
    );
  }

  return (
    <>
      {isOpen && (
        <SubMenu isOpen={isOpen}>
          <MenuFilter
            title={
              selectedFilters?.project
                ? selectedFilters.project.name
                : "All Projects/Products"
            }
            label="Projects"
            filterDropdownsOpen={filterDropdownsOpen}
            setFilterDropdownsOpen={setFilterDropdownsOpen}
          >
            <DropdownItem
              onClick={() => {
                selectedFilters
                  ? setSelectedFilters(
                      Object.assign({}, selectedFilters, {
                        project: undefined,
                      }),
                    )
                  : setSelectedFilters({ project: undefined });
                setFilterDropdownsOpen(
                  Object.assign({}, filterDropdownsOpen, { project: false }),
                );
              }}
            >
              <Button data-testid="25b2baf034" text>
                All Projects/Products
              </Button>
            </DropdownItem>
            {projects &&
              projects.map((project) => {
                return (
                  <DropdownItem
                    key={project.id}
                    onClick={() => {
                      selectedFilters
                        ? setSelectedFilters(
                            Object.assign({}, selectedFilters, {
                              project: { id: project.id, name: project.name },
                            }),
                          )
                        : setSelectedFilters({
                            project: { id: project.id, name: project.name },
                          });
                      setFilterDropdownsOpen(
                        Object.assign({}, filterDropdownsOpen, {
                          project: false,
                        }),
                      );
                    }}
                  >
                    <Button data-testid="7bb0b7ac6a" text>
                      {project.name}
                    </Button>
                  </DropdownItem>
                );
              })}
          </MenuFilter>

          <MenuFilter
            title={
              selectedFilters?.supplier
                ? selectedFilters.supplier.name
                  ? selectedFilters.supplier.name
                  : selectedFilters.supplier.email
                : "All Suppliers"
            }
            label="Suppliers"
            filterDropdownsOpen={filterDropdownsOpen}
            setFilterDropdownsOpen={setFilterDropdownsOpen}
          >
            <DropdownItem
              onClick={() => {
                selectedFilters
                  ? setSelectedFilters(
                      Object.assign({}, selectedFilters, {
                        supplier: undefined,
                      }),
                    )
                  : setSelectedFilters({ supplier: undefined });
                setFilterDropdownsOpen(
                  Object.assign({}, filterDropdownsOpen, { supplier: false }),
                );
              }}
            >
              <Button data-testid="43c2639167" text>
                All suppliers
              </Button>
            </DropdownItem>
            {suppliers &&
              suppliers.map((supplier) => {
                return (
                  <DropdownItem
                    key={supplier.id}
                    onClick={() => {
                      selectedFilters
                        ? setSelectedFilters(
                            Object.assign({}, selectedFilters, {
                              supplier: {
                                id: supplier.id,
                                name: supplier.name,
                                email: supplier.defaultContactPerson.email,
                              },
                            }),
                          )
                        : supplier.name && supplier.defaultContactPerson.email
                        ? setSelectedFilters({
                            supplier: {
                              id: supplier.id,
                              name: supplier.name,
                              email: supplier.defaultContactPerson.email,
                            },
                          })
                        : supplier.name
                        ? setSelectedFilters({
                            supplier: {
                              id: supplier.id,
                              name: supplier.name,
                            },
                          })
                        : supplier.defaultContactPerson.email
                        ? setSelectedFilters({
                            supplier: {
                              id: supplier.id,
                              email: supplier.defaultContactPerson.email,
                            },
                          })
                        : setSelectedFilters(undefined);
                      setFilterDropdownsOpen(
                        Object.assign({}, filterDropdownsOpen, {
                          supplier: false,
                        }),
                      );
                    }}
                  >
                    <Button data-testid="974d973fb0" text>
                      {supplier.name
                        ? supplier.name
                        : supplier.defaultContactPerson.email}
                    </Button>
                  </DropdownItem>
                );
              })}
          </MenuFilter>

          <MenuFilter
            title={
              selectedFilters?.department
                ? selectedFilters.department.name
                : "All Units"
            }
            label="Units"
            filterDropdownsOpen={filterDropdownsOpen}
            setFilterDropdownsOpen={setFilterDropdownsOpen}
          >
            <DropdownItem
              onClick={() => {
                selectedFilters
                  ? setSelectedFilters(
                      Object.assign({}, selectedFilters, {
                        department: undefined,
                      }),
                    )
                  : setSelectedFilters({ department: undefined });
                setFilterDropdownsOpen(
                  Object.assign({}, filterDropdownsOpen, { department: false }),
                );
              }}
            >
              <Button data-testid="c626e1893f" text>
                All Units
              </Button>
            </DropdownItem>
            {departments &&
              departments.map((department) => {
                return (
                  <DropdownItem
                    key={department.id}
                    onClick={() => {
                      selectedFilters
                        ? setSelectedFilters(
                            Object.assign({}, selectedFilters, {
                              department: {
                                id: department.id,
                                name: department.name,
                              },
                            }),
                          )
                        : setSelectedFilters({
                            department: {
                              id: department.id,
                              name: department.name,
                            },
                          });
                      setFilterDropdownsOpen(
                        Object.assign({}, filterDropdownsOpen, {
                          department: false,
                        }),
                      );
                    }}
                  >
                    <Button data-testid="8850f509b6" text>
                      {department.name}
                    </Button>
                  </DropdownItem>
                );
              })}
          </MenuFilter>
          <DateRangeFilter
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </SubMenu>
      )}
    </>
  );
};
