import React from "react";
import { Row, UseTableHooks } from "react-table";
import styles from "./IndeterminateCheckbox.module.scss";

export interface DataGridRowSelectProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  indeterminate?: boolean;
}

/**
 * Checkbox with indeterminate state
 * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox)
 *
 */
export const IndeterminateCheckbox: React.FC<DataGridRowSelectProps> = (
  props,
) => {
  const { inputRef, indeterminate = false, ...rest } = props;

  const innerRef = React.useRef<HTMLInputElement>(null);

  const resolvedRef = inputRef || innerRef;

  React.useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input
        type="checkbox"
        ref={resolvedRef}
        onClick={(e) => e.stopPropagation()}
        {...rest}
        className={styles["checkbox"]}
      />
    </>
  );
};

/**
 *  Hook to use to configure the `react-table` useTable hook
 */
export function useIndeterminateCheckbox<D extends Record<string, unknown>>(
  hooks: UseTableHooks<D>,
) {
  hooks.visibleColumns.push((columns) => [
    // Let's make a column for selection
    {
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/display-name
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: { row: Row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    },
    ...columns,
  ]);
}
