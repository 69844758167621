import React from "react";
import classNames from "classnames";
import { PurchaseRequestItemTypeEnum, CurrencyEnum } from "../../schema";
import { calculateItemPriceTotals } from "../../services/calculateItemPriceTotals";
import { formatDate } from "../../services/formatDate";
import { formatMoney } from "../../services/formatMoney";
import { renderPrices } from "../../services/renderPrices";
import { SectionSubtitleProps } from "../SectionSubtitle/SectionSubtitle";
import { isValidURL } from "../../services/isValidUrl";
import { CardStatus, Status } from "../Status/Status";
import styles from "./RequestedItems.module.scss";

interface RequestItemDetailsProps extends SectionSubtitleProps {
  isLink?: boolean;
  linkTitle?: string;
  total?: string;
}

// Component that renders title and value
const RequestItemDetails: React.FC<RequestItemDetailsProps> = ({
  title,
  value,
  isLink,
  linkTitle,
  total,
}) => {
  // Return blank div to maintain element position
  if (!value) {
    return <div className={styles.blank}></div>;
  }

  return (
    <div>
      <h4 className={styles["item-details-subtitle"]}>{title}:</h4>
      {isLink && value && isValidURL(value) ? (
        <a
          href={value.startsWith("http") ? value : `https://${value}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles["item-details-link"]}
        >
          {linkTitle ? linkTitle : value}
        </a>
      ) : (
        <p className={styles["item-details-value"]} title={total}>
          {value}
        </p>
      )}
    </div>
  );
};

export type RequestedItem = {
  name: string;
  type: PurchaseRequestItemTypeEnum;
  quantity?: number | null;
  unit?: string | null;
  unitPrice?: number | null;
  currency?: CurrencyEnum | null;
  expectedDeliveryDate?: Date | string | null;
  productUrl?: string | null;
  additionalInfo?: string | null;
  attachments?: { id: string; filename: string; url?: string | null }[] | null;
  customFields?: { [key: string]: string } | null;
  suggestedSupplier?: string | null;
  supplierCode?: string | null;
  code?: string | null;
  parentCode?: string | null;
  externalIdentifier?: string | null;
  status?: CardStatus;
};

export interface RequestedItemsProps {
  items: RequestedItem[];
  totalTitle?: string;
  secondary?: boolean;
}

export const RequestedItems: React.FC<RequestedItemsProps> = ({
  items,
  totalTitle = "Total",
  secondary,
}) => {
  // calculate total price of all items for all currencies
  const totalPrice = calculateItemPriceTotals(items);
  // no items might have price available
  const hasTotalPrice = Object.keys(totalPrice).length > 0;

  return (
    <>
      <div className={styles.items}>
        <table>
          <tbody>
            {items.map((item, index) => {
              return (
                <tr className={styles.item} key={index}>
                  <td valign="top">
                    <span className={styles.badge}>{index + 1}</span>
                  </td>
                  <td>
                    <div className={styles["title"]}>
                      <h3>{item.name}</h3>
                      {item.parentCode && (
                        <h4 className={styles["title-parent"]}>
                          {item.parentCode}
                        </h4>
                      )}
                      {item.status && (
                        <Status
                          {...item.status}
                          className={styles["title-status"]}
                        />
                      )}
                      {item.externalIdentifier && (
                        <h4 className={styles["title-parent"]}>
                          {item.externalIdentifier}
                        </h4>
                      )}
                    </div>
                    <div className={styles["item-details"]}>
                      <div
                        className={classNames(
                          styles["group"],
                          styles["group--info"],
                          {
                            [styles["group--secondary"]]: secondary,
                          },
                        )}
                      >
                        <RequestItemDetails
                          title="Link"
                          value={item.productUrl}
                          isLink={!!item.productUrl}
                        />
                        <RequestItemDetails
                          title="Supplier"
                          value={item.suggestedSupplier}
                        />
                        <RequestItemDetails
                          title="Supplier code"
                          value={item.supplierCode}
                        />

                        <RequestItemDetails
                          title="Exp delivery"
                          value={
                            item.expectedDeliveryDate
                              ? formatDate(item.expectedDeliveryDate)
                              : undefined
                          }
                        />

                        <RequestItemDetails
                          title="Our code"
                          value={item.code}
                        />
                        <RequestItemDetails
                          title="Additional Info"
                          value={item.additionalInfo}
                        />

                        {item.attachments?.map((attachment, index) => (
                          <RequestItemDetails
                            key={`Attachment ${index}`}
                            title={`Attachment ${index + 1}`}
                            isLink={true}
                            linkTitle={attachment.filename}
                            value={attachment.url}
                          />
                        ))}
                      </div>
                      <div
                        className={classNames(
                          styles["group"],
                          styles["group--values"],
                          {
                            [styles["group--values-product"]]:
                              item.type === PurchaseRequestItemTypeEnum.PRODUCT,
                          },
                        )}
                      >
                        <RequestItemDetails
                          title="Unit price"
                          value={
                            item.unitPrice && item.currency
                              ? `${item.unitPrice} ${item.currency}`
                              : undefined
                          }
                        />
                        <RequestItemDetails
                          title="Qty"
                          value={item.quantity ? `${item.quantity}` : undefined}
                        />
                        <RequestItemDetails
                          title="Item Total"
                          value={
                            item.quantity && item.unitPrice && item.currency
                              ? `${formatMoney(
                                  item.quantity * item.unitPrice,
                                )} ${item.currency}`
                              : undefined
                          }
                          total={
                            item.quantity && item.unitPrice && item.currency
                              ? `${formatMoney(
                                  item.quantity * item.unitPrice,
                                )} ${item.currency}`
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {hasTotalPrice && (
        <h2 className={styles["total"]}>
          {totalTitle}: <strong>{renderPrices(totalPrice)}</strong>
        </h2>
      )}
    </>
  );
};
