import React from "react";
import classNames from "classnames";
import { Button } from "../Button/Button";
import { RfxTypeEnum } from "../../schema";
import { RfiIcon } from "../../theme/svg/RfiIcon";
import { RfpIcon } from "../../theme/svg/RfpIcon";
import { RfqIcon } from "../../theme/svg/RfqIcon";
import styles from "./RequestTypeSelection.module.scss";

export interface RequestTypeSelectionProps {
  onRequestTypeSelect: (requestType: RfxTypeEnum) => void;
  isDisabled?: boolean;
}

export const RequestTypeSelection: React.FC<RequestTypeSelectionProps> = (
  props,
) => {
  const { onRequestTypeSelect, isDisabled = false } = props;

  return (
    <div className={styles["wrap"]}>
      <h3 className={styles["label"]}>Choose the type of request:</h3>

      <div className={styles["button-row"]}>
        <Button
          icon={<RfiIcon />}
          className={classNames(styles["button"], {
            [styles["button--disabled"]]: isDisabled,
          })}
          onClick={() => {
            onRequestTypeSelect(RfxTypeEnum.RFI);
          }}
          disabled={isDisabled}
        >
          <div>
            <strong>RFI</strong>
          </div>
        </Button>
        <Button
          icon={<RfpIcon />}
          className={classNames(styles["button"], {
            [styles["button--disabled"]]: isDisabled,
          })}
          onClick={() => {
            onRequestTypeSelect(RfxTypeEnum.RFP);
          }}
          disabled={isDisabled}
        >
          <div>
            <strong>RFP / RFQ</strong>
          </div>
        </Button>
        <Button
          icon={<RfqIcon />}
          className={classNames(styles["button"], {
            [styles["button--disabled"]]: isDisabled,
          })}
          onClick={() => {
            onRequestTypeSelect(RfxTypeEnum.RFQ);
          }}
          disabled={isDisabled}
        >
          <div>
            <strong>eSourcing</strong>
          </div>
        </Button>
      </div>
    </div>
  );
};
