import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { ViewerOrganizations } from "../../schema";
import styles from "./MenuPortal.module.scss";

export interface MenuPortalProps {
  activeOrganization: Pick<ViewerOrganizations, "urlName">;
  approvalsRequests?: number;
  className?: string;
  purchaseRequests?: number;
  hasApproverRole?: boolean;
  hasRequesterRole?: boolean;
}

export const MenuPortal: React.FC<MenuPortalProps> = ({
  activeOrganization,
  approvalsRequests,
  className,
  children,
  purchaseRequests,
  hasApproverRole,
  hasRequesterRole,
}) => {
  return (
    <>
      <div className={classNames(className, styles.menu)}>
        <div className={styles.section}>
          {hasRequesterRole && (
            <NavLink
              // TODO: data-testid="n33u36scwc"
              to={`/${activeOrganization.urlName}/purchase-requests`}
              className={styles.link}
              activeClassName={styles["link--active"]}
            >
              Purchase Requests{" "}
              {!!purchaseRequests && (
                <span className={styles.badge}>{purchaseRequests}</span>
              )}
            </NavLink>
          )}
          {hasApproverRole && (
            <NavLink
              // TODO: data-testid="n33u36scwc"
              exact
              to={`/${activeOrganization.urlName}/approval-requests`}
              className={styles.link}
              activeClassName={styles["link--active"]}
            >
              Approval Requests{" "}
              {!!approvalsRequests && (
                <span className={styles.badge}>{approvalsRequests}</span>
              )}
            </NavLink>
          )}
        </div>
        {children !== undefined && (
          <div className={styles.section}>{children}</div>
        )}
      </div>
    </>
  );
};
