import React from "react";
import { CardTypeEnum } from "../schema";

export interface CardContextInfo {
  id: string;
  name: string;
  code: string;
  type: CardTypeEnum;
}

export interface CardContextProviderProps {
  card: CardContextInfo;
  children?: React.ReactNode;
}

export const CardContext = React.createContext<CardContextInfo | undefined>(
  undefined,
);

export function CardContextProvider({
  card,
  children,
}: CardContextProviderProps) {
  return <CardContext.Provider value={card}>{children}</CardContext.Provider>;
}

/**
 * Convenient use context hook to be used inside CardContextProvider
 *
 * @returns React.useContext(CardContext)
 *
 * @example  const card = useCardContextInfo()
 */
export function useCardContextInfo() {
  return React.useContext(CardContext);
}
