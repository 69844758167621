import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { TeamBoardIcon } from "../../theme/svg/TeamBoardIcon";
import { SrmIcon } from "../../theme/svg/SrmIcon";
import { AnalyticsIcon } from "../../theme/svg/AnalyticsIcon";
import {
  useFullTextSearch,
  UserRoleEnum,
  ViewerOrganizations,
  ViewerViewer,
} from "../../schema";
import { ApprovalsIcon } from "../../theme/svg/ApprovalsIcon";
import { EsourcingIcon } from "../../theme/svg/EsourcingIcon";
import { MenuSearch } from "../MenuSearch/MenuSearch";
import { useRouter } from "../../hooks/useRouter";
import { SelectedFilters } from "../../views/MemberView/MemberView";
import styles from "./Menu.module.scss";

export interface MenuProps {
  viewer: ViewerViewer;
  activeOrganization: ViewerOrganizations;
  selectedFilters?: SelectedFilters | undefined;
  className?: string;
  subMenu?: React.ReactNode;
}

export interface SubMenuProps {
  isOpen: boolean;
}

export const Menu: React.FC<MenuProps> = ({
  viewer,
  activeOrganization,
  selectedFilters,
  className,
  subMenu,
  children,
}) => {
  const { history } = useRouter();

  // prepare search query
  const [performFullTextSearch] = useFullTextSearch();

  // extract variables for useEffect dependencies
  const selectedProject = selectedFilters?.project;
  const selectedAssignee = selectedFilters?.assignee;
  const selectedDepartment = selectedFilters?.department;
  const selectedSupplier = selectedFilters?.supplier;
  const selectedDateRange = selectedFilters?.dateRange;

  const fetchSuggestions = async (searchInput: string) => {
    const response = await performFullTextSearch({
      variables: {
        searchInput,
        organizationId: activeOrganization.id,
        filter: {
          userId: selectedAssignee?.id || null,
          projectId: selectedProject?.id || null,
          supplierId: selectedSupplier?.id || null,
          departmentId: selectedDepartment?.id || null,
          dateRange: selectedDateRange || null,
          status: null,
        },
      },
    });

    const result = response.data?.fullTextSearch;

    return result && result.length > 0 ? result : [];
  };

  const onChoose = async (chosenCode: string) => {
    history.push(`/${activeOrganization.urlName}/${chosenCode}`);
  };

  return (
    <>
      <div className={classNames(className, styles.menu)}>
        <div className={styles.section}>
          <NavLink
            data-testid="n33u36scwc"
            to={`/${activeOrganization.urlName}`}
            className={classNames(styles.link, styles["link--has-icon"])}
            activeClassName={styles["link--active"]}
          >
            <TeamBoardIcon className={styles.icon} />
            Team Board
          </NavLink>
          <NavLink
            data-testid="73wl2ofaib"
            to={`/suppliers-and-contracts/${activeOrganization.urlName}`}
            className={classNames(styles.link, styles["link--has-icon"])}
            activeClassName={styles["link--active"]}
          >
            <SrmIcon className={styles.icon} />
            Suppliers & Contracts
          </NavLink>
          <NavLink
            data-testid="jfa90f8u9e"
            to={`/sourcing-events/${activeOrganization.urlName}`}
            className={classNames(
              styles.link,
              styles["link--has-icon"],
              styles["link-sourcing"],
            )}
            activeClassName={styles["link--active"]}
          >
            <EsourcingIcon className={styles.icon} />
            Sourcing
          </NavLink>
          <NavLink
            data-testid="v7d24kefla"
            to={`/approval-workflows/${activeOrganization.urlName}`}
            className={classNames(styles.link, styles["link--has-icon"])}
            activeClassName={styles["link--active"]}
          >
            Approvals
            <ApprovalsIcon className={styles.icon} />
          </NavLink>
          {activeOrganization.embeddedReport && (
            <NavLink
              data-testid="v7d24kefla"
              to={`/reporting/${activeOrganization.urlName}`}
              className={classNames(styles.link, styles["link--has-icon"])}
              activeClassName={styles["link--active"]}
            >
              Reporting
              <ApprovalsIcon className={styles.icon} />
            </NavLink>
          )}

          {
            // TODO uncomment once there is data to be shown
            /* <NavLink
            data-testid="q0y5kv67hx"
            to={`/analytics-and-kpi/${activeOrganization.urlName}`}
            className={classNames(styles.link, styles["link--has-icon"])}
            activeClassName={styles["link--active"]}
          >
            <AnalyticsIcon className={styles.icon} />
            Analytics & KPIs
          </NavLink> */
          }
          {/* TODO: implement activeClassName for /users as well */}
          {viewer.roles.includes(UserRoleEnum.ADMIN) && (
            <NavLink
              data-testid="te9tlhjkrl"
              to="/admin"
              className={classNames(styles.link, styles["link--has-icon"])}
              activeClassName={styles["link--active"]}
            >
              <AnalyticsIcon className={styles.icon} />
              Admin
            </NavLink>
          )}
          <MenuSearch fetchSuggestions={fetchSuggestions} onChoose={onChoose} />
        </div>
        {children !== undefined && (
          <div className={styles.section}>{children}</div>
        )}
      </div>
      {subMenu}
    </>
  );
};

export const SubMenu: React.FC<SubMenuProps> = ({ children }) => {
  return <div className={styles.submenu}>{children}</div>;
};
