import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import {
  AddressTypeEnum,
  Country,
  OrganizationAddressesAddresses,
  useCreateDepartment,
  useCreateOrganizationAddress,
  useOrganizationAddresses,
} from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { notEmpty } from "../../validators/notEmpty";
import { ErrorView } from "../ErrorView/ErrorView";
import {
  AutosuggestItem,
  ItemAutosuggest,
} from "../../components/ItemAutosuggest/ItemAutosuggest";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { Select } from "../../components/Select/Select";
import { LoadingView } from "../LoadingView/LoadingView";
import { maxLength } from "../../validators/maxLength";
import { isUrl } from "../../validators/isUrl";
import styles from "./CompanyDetailsView.module.scss";

export interface CreateDepartmentProps {
  organizationId: string;
  countries: Country[];
  onModalClose(): void;
}

export const CreateDepartment: React.FC<CreateDepartmentProps> = ({
  organizationId,
  countries,
  onModalClose,
}) => {
  // load department address data
  const {
    data,
    loading: isFetching,
    error: fetchError,
  } = useOrganizationAddresses({
    fetchPolicy: "network-only",
    variables: {
      organizationId,
    },
  });

  const addresses = data?.viewer?.organization?.addresses ?? [];

  // address selection states
  const [chosenShippingAddress, setChosenShippingAddress] = React.useState<
    OrganizationAddressesAddresses | undefined
  >(undefined);

  const [chosenInvoiceAddress, setChosenInvoiceAddress] = React.useState<
    OrganizationAddressesAddresses | undefined
  >(undefined);

  const [shippingAddressDisplayValue, setShippingAddressDisplayValue] =
    React.useState(
      chosenShippingAddress
        ? getAddressDisplayValue(chosenShippingAddress)
        : "",
    );

  const [invoiceAddressDisplayValue, setInvoiceAddressDisplayValue] =
    React.useState(
      chosenInvoiceAddress ? getAddressDisplayValue(chosenInvoiceAddress) : "",
    );

  const [isNewShippingAddressFormActive, setIsNewShippingAddressFormActive] =
    React.useState(false);

  const [isNewInvoiceAddressFormActive, setIsNewInvoiceAddressFormActive] =
    React.useState(false);

  // setup create department form
  const {
    useInput,
    loading,
    error,
    submit: createDepartment,
  } = useForm({
    mutation: useCreateDepartment,
    options: {
      refetchQueries: ["Viewer"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const nameInput = useInput({
    name: "name",
    validators: [notEmpty],
  });

  const codeInput = useInput({
    name: "code",
    validators: [maxLength(9)],
  });

  const domainInput = useInput({
    name: "domain",
    validators: [maxLength(255), isUrl],
  });

  const submit = () => {
    createDepartment({
      organizationId,
      shippingAddressId: chosenShippingAddress?.id,
      invoiceAddressId: chosenInvoiceAddress?.id,
    });
  };

  // set up new shipping address form and inputs
  const {
    useInput: useNewShippingAddressInput,
    useSelect: useNewShippingAddressSelect,
    submit: submitNewShippingAddress,
    loading: isSubmitNewShippingAddressLoading,
    error: submitNewShippingAddressError,
    reset: resetNewShippingAddressFields,
  } = useForm({
    mutation: useCreateOrganizationAddress,
    options: {
      refetchQueries: ["Viewer"],
      awaitRefetchQueries: true,
    },
    onSuccess: (result) => {
      const createdAddress = result.createOrganizationAddress;
      setChosenShippingAddress(createdAddress);
      setShippingAddressDisplayValue(getAddressDisplayValue(createdAddress));
      setIsNewShippingAddressFormActive(false);
      resetNewShippingAddressFields();
    },
  });

  const shippingLocationNameInput = useNewShippingAddressInput({
    name: "locationName",
    optional: true,
  });
  const shippingRecipientNameInput = useNewShippingAddressInput({
    name: "recipientName",
    optional: true,
  });
  const shippingAddressInput = useNewShippingAddressInput({
    name: "address",
    validators: [notEmpty],
  });
  const shippingCityInput = useNewShippingAddressInput({
    name: "city",
    validators: [notEmpty],
  });
  const shippingAreaCodeInput = useNewShippingAddressInput({
    name: "areaCode",
    validators: [notEmpty],
  });
  const shippingCountrySelect = useNewShippingAddressSelect({
    name: "countryCode",
    options: [{ label: "-", value: "" }].concat(
      countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    ),
    validators: [notEmpty],
  });

  // set up new invoice address form and inputs
  const {
    useInput: useNewInvoiceAddressInput,
    useSelect: useNewInvoiceAddressSelect,
    submit: submitNewInvoiceAddress,
    loading: isSubmitNewInvoiceAddressLoading,
    error: submitNewInvoiceAddressError,
    reset: resetNewInvoiceAddressFields,
  } = useForm({
    mutation: useCreateOrganizationAddress,
    options: {
      refetchQueries: ["Viewer"],
      awaitRefetchQueries: true,
    },
    onSuccess: (result) => {
      const createdAddress = result.createOrganizationAddress;
      setChosenInvoiceAddress(createdAddress);
      setInvoiceAddressDisplayValue(getAddressDisplayValue(createdAddress));
      setIsNewInvoiceAddressFormActive(false);
      resetNewInvoiceAddressFields();
    },
  });

  const invoiceLocationNameInput = useNewInvoiceAddressInput({
    name: "locationName",
    optional: true,
  });
  const invoiceRecipientNameInput = useNewInvoiceAddressInput({
    name: "recipientName",
    optional: true,
  });
  const invoiceAddressInput = useNewInvoiceAddressInput({
    name: "address",
    validators: [notEmpty],
  });
  const invoiceCityInput = useNewInvoiceAddressInput({
    name: "city",
    validators: [notEmpty],
  });
  const invoiceAreaCodeInput = useNewInvoiceAddressInput({
    name: "areaCode",
    validators: [notEmpty],
  });
  const invoiceCountrySelect = useNewInvoiceAddressSelect({
    name: "countryCode",
    options: [{ label: "-", value: "" }].concat(
      countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    ),
    validators: [notEmpty],
  });

  // filters organization addresses based on user input
  const fetchAddresses = async (value: string) => {
    const addressAutosuggestItems = addresses.map((addr) => {
      return {
        id: addr.id,
        address: getAddressDisplayValue(addr),
      };
    }) as AutosuggestItem[];

    const result =
      value.length === 0
        ? addressAutosuggestItems
        : addressAutosuggestItems.filter((item) => {
            return item.name ? new RegExp(value, "gi").test(item.name) : false;
          });

    return result;
  };

  // handle save errors
  if (error) {
    return (
      <ErrorView
        title="Saving Business Unit failed"
        error={error}
        modal={{ title: "Create Business Unit", onModalClose }}
      />
    );
  }

  if (fetchError) {
    return (
      <ErrorView
        title="Could not get address data"
        error={fetchError}
        modal={{ title: "Create Business Unit", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      title="Add new Business Unit"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="cefjaofa888b" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="74ecfjaof9a"
            loading={loading}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={submit} className={styles["form"]}>
        <div>
          <Field {...nameInput} label="Business Unit name" short />
          <Field {...codeInput} label="Code" short />
        </div>

        <div>
          <ItemAutosuggest
            short
            label={"Delivery info"}
            displayAvatar={false}
            getCreateNewText={() => "Create a new address"}
            placeholder="Start typing to enter a new delivery address"
            fetchSuggestions={fetchAddresses}
            value={shippingAddressDisplayValue}
            chosenSuggestion={chosenShippingAddress}
            onChange={(newValue) => setShippingAddressDisplayValue(newValue)}
            onChoose={(address) => {
              const chosenAddress = addresses.find(
                (addr) => addr.id === address?.id,
              );
              setChosenShippingAddress(chosenAddress);
            }}
            onCreateNew={(value) => {
              resetNewShippingAddressFields();
              shippingLocationNameInput.setValue(value);
              setIsNewShippingAddressFormActive(true);
              setChosenShippingAddress(undefined);
              setShippingAddressDisplayValue("");
            }}
          />
          {isNewShippingAddressFormActive && (
            <div className={styles["delivery-info-new-address"]}>
              <ErrorMessage
                error={
                  submitNewShippingAddressError &&
                  "Error when creating new address. Please try again later."
                }
              />
              <Field
                {...shippingLocationNameInput}
                autofocus
                label="Location name"
                placeholder="[i.e HQ, Building B] - NB This is hidden from PDF"
              />
              <Field
                {...shippingRecipientNameInput}
                label="Company name, recipient contact person"
                placeholder="Company name"
              />
              <Field
                {...shippingAddressInput}
                label="Address"
                placeholder="Address"
              />
              <Field {...shippingCityInput} label="City" placeholder="City" />
              <Field
                {...shippingAreaCodeInput}
                label="Zip / Post code"
                placeholder="Zip / Post code"
              />
              <Select
                {...shippingCountrySelect}
                label="Country"
                className={styles["address-country-select"]}
              />
              <div className={styles["delivery-info-new-address-buttons"]}>
                <Button
                  data-testid="82cc2ca25d"
                  secondary
                  onClick={() => {
                    setIsNewShippingAddressFormActive(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="0a40eb8653"
                  onClick={() =>
                    submitNewShippingAddress({
                      organizationId,
                      addressType: AddressTypeEnum.SHIPPING_ADDRESS,
                    })
                  }
                  loading={isSubmitNewShippingAddressLoading}
                  disabled={isSubmitNewShippingAddressLoading}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>

        <div>
          <ItemAutosuggest
            short
            label={"Business entity to be invoiced"}
            displayAvatar={false}
            getCreateNewText={() => "Create a new address"}
            placeholder="Start typing to enter a new invoice address"
            fetchSuggestions={fetchAddresses}
            value={invoiceAddressDisplayValue}
            chosenSuggestion={chosenInvoiceAddress}
            onChange={(newValue) => setInvoiceAddressDisplayValue(newValue)}
            onChoose={(address) => {
              const chosenAddress = addresses.find(
                (addr) => addr.id === address?.id,
              );
              setChosenInvoiceAddress(chosenAddress);
            }}
            onCreateNew={(value) => {
              resetNewInvoiceAddressFields();
              invoiceLocationNameInput.setValue(value);
              setIsNewInvoiceAddressFormActive(true);
              setChosenInvoiceAddress(undefined);
              setInvoiceAddressDisplayValue("");
            }}
          />
          {isNewInvoiceAddressFormActive && (
            <div className={styles["delivery-info-new-address"]}>
              <ErrorMessage
                error={
                  submitNewInvoiceAddressError &&
                  "Error when creating new address. Please try again later."
                }
              />
              <Field
                {...invoiceLocationNameInput}
                autofocus
                label="Location name"
                placeholder="[i.e HQ, Building B] - NB This is hidden from PDF"
              />
              <Field
                {...invoiceRecipientNameInput}
                label="Company name, recipient contact person"
                placeholder="Company name"
              />
              <Field
                {...invoiceAddressInput}
                label="Address"
                placeholder="Address"
              />
              <Field {...invoiceCityInput} label="City" placeholder="City" />
              <Field
                {...invoiceAreaCodeInput}
                label="Zip / Post code"
                placeholder="Zip / Post code"
              />
              <Select
                {...invoiceCountrySelect}
                label="Country"
                className={styles["address-country-select"]}
              />
              <div className={styles["delivery-info-new-address-buttons"]}>
                <Button
                  data-testid="82cc2ca25d"
                  secondary
                  onClick={() => {
                    setIsNewInvoiceAddressFormActive(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="0a40eb8653"
                  onClick={() =>
                    submitNewInvoiceAddress({
                      organizationId,
                      addressType: AddressTypeEnum.INVOICE_ADDRESS,
                    })
                  }
                  loading={isSubmitNewInvoiceAddressLoading}
                  disabled={isSubmitNewInvoiceAddressLoading}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
        <div>
          <Field {...domainInput} required={false} label="Domain" short />
        </div>
      </Form>
    </Modal>
  );
};

function getAddressDisplayValue(address: OrganizationAddressesAddresses) {
  return `[${address.locationName}] ${address.recipientName} - ${address.address}, ${address.city}, ${address.areaCode} ${address.countryCode}`;
}
