import * as React from "react";
export const UpArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 452 258" {...props}>
    <g clipPath="url(#UpArrowIcon_svg__clip0)">
      <path d="M225.922 0a31.545 31.545 0 00-22.369 9.263L9.269 203.549A31.642 31.642 0 0054.017 248.3L225.922 76.385l171.906 171.909a31.646 31.646 0 0044.748-.003 31.643 31.643 0 00-.004-44.748L248.291 9.256A31.549 31.549 0 00225.922 0z" />
    </g>
    <defs>
      <clipPath id="UpArrowIcon_svg__clip0">
        <path
          transform="matrix(1 0 0 -1 0 257.565)"
          d="M0 0h451.846v257.565H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
