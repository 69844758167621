import React from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray } from "react-hook-form";
import { RevealingInput } from "../RevealingInput/RevealingInput";
import { Button } from "../Button/Button";
import { PlusIcon } from "../../theme/svg/PlusIcon";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import styles from "./CustomFieldList.module.scss";

export interface CustomFieldListProps {
  className?: string;
}

export const CustomFieldList: React.FC<CustomFieldListProps> = (props) => {
  const { className } = props;

  const { register } = useFormContext();
  const { fields, remove, append } = useFieldArray({ name: "customFields" });

  const handleAppend = () => {
    append({ label: "", value: "" });
  };

  return (
    <div className={classNames(styles["list"], className)}>
      {fields.map((listItem, index) => (
        <div key={listItem.id} className={styles["list-item"]}>
          <div className={styles["first-col"]}>
            <div
              className={styles["remove-button"]}
              onClick={() => remove(index)}
            >
              <DeleteFileIcon />
            </div>
            <div className={styles["list-item-sequence"]}>
              <div className={styles["sequence-num"]}>{index + 1}</div>
            </div>
          </div>

          <div className={styles["list-item-fields"]}>
            <RevealingInput
              name={`customFields[${index}].label`}
              inputRef={register()}
              defaultValue={listItem.label}
              className={styles["title-input"]}
              placeholder="Add Title"
              onEditingFinished={() => Promise.resolve("test")}
            />
            <RevealingInput
              name={`customFields[${index}].value`}
              inputRef={register()}
              defaultValue={listItem.value}
              className={styles["value-input"]}
              placeholder="Add Value"
              onEditingFinished={() => Promise.resolve("test")}
            />
          </div>
        </div>
      ))}

      <Button
        className={styles["add-button"]}
        quentary
        icon={<PlusIcon className={styles["add-button-icon"]} />}
        onClick={handleAppend}
      ></Button>
    </div>
  );
};
