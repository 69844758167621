import React from "react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import { SourcingEvent } from "../../schema";

export enum RfqState {
  INIT = "INIT",
  CREATED = "CREATED",
  ONGOING = "ONGOING",
  CHANGING_METADATA = "CHANGING_METADATA",
  COMPLETING = "COMPLETING",
  COMPLETE = "COMPLETE",
}

export enum RfqMachineEventsEnum {
  LOAD_STATE = "LOAD_STATE",
  CREATED_SOURCING_EVENT = "CREATED_SOURCING_EVENT",
  SOURCING_REQUESTS_SENT = "SOURCING_REQUESTS_SENT",
  START_CHANGING_METADATA = "START_CHANGING_METADATA",
  STOP_CHANGING_METADATA = "STOP_CHANGING_METADATA",
  START_COMPLETION = "START_COMPLETION",
  COMPLETED_SOURCING_EVENT = "COMPLETED_SOURCING_EVENT",
}

export interface RfqMachineContext {
  [key: string]: any;
}

export type RfqMachineState = {
  value: keyof typeof RfqState;
  context: RfqMachineContext;
};

export type RfqMachineEvent =
  | { type: RfqMachineEventsEnum.LOAD_STATE; stateToLoad: RfqState }
  | { type: RfqMachineEventsEnum.CREATED_SOURCING_EVENT }
  | { type: RfqMachineEventsEnum.SOURCING_REQUESTS_SENT }
  | { type: RfqMachineEventsEnum.START_CHANGING_METADATA }
  | { type: RfqMachineEventsEnum.STOP_CHANGING_METADATA }
  | { type: RfqMachineEventsEnum.START_COMPLETION }
  | { type: RfqMachineEventsEnum.COMPLETED_SOURCING_EVENT };

export type RfqMachineProps = {
  sourcingEvent: Pick<SourcingEvent, "id" | "status"> | null;
  completeRfq: () => Promise<any>;
};

// create parameterized statechart
export function createRfqMachine({ completeRfq }: RfqMachineProps) {
  return createMachine<RfqMachineContext, RfqMachineEvent, RfqMachineState>({
    id: "rfqMachine",
    initial: RfqState.INIT,
    states: {
      [RfqState.INIT]: {
        on: {
          CREATED_SOURCING_EVENT: { target: RfqState.CREATED },
        },
      },
      [RfqState.CREATED]: {
        on: {
          SOURCING_REQUESTS_SENT: { target: RfqState.ONGOING },
          START_COMPLETION: { target: RfqState.COMPLETING },
        },
      },
      [RfqState.ONGOING]: {
        on: {
          START_CHANGING_METADATA: { target: RfqState.CHANGING_METADATA },
          START_COMPLETION: { target: RfqState.COMPLETING },
        },
      },
      [RfqState.COMPLETING]: {
        invoke: {
          id: "complete-rfq",
          src: completeRfq,
          onDone: {
            target: RfqState.COMPLETE,
          },
          onError: { target: RfqState.ONGOING },
        },
      },
      [RfqState.CHANGING_METADATA]: {
        on: {
          STOP_CHANGING_METADATA: { target: RfqState.ONGOING },
        },
      },
      [RfqState.COMPLETE]: {},
    },
    on: {
      LOAD_STATE: [
        {
          target: RfqState.CREATED,
          internal: true,
          cond: (_context, event) => {
            return event.stateToLoad === RfqState.CREATED;
          },
        },
        {
          target: RfqState.ONGOING,
          internal: true,
          cond: (_context, event) => {
            return event.stateToLoad === RfqState.ONGOING;
          },
        },
        {
          target: RfqState.COMPLETE,
          internal: true,
          cond: (_context, event) => {
            return event.stateToLoad === RfqState.COMPLETE;
          },
        },
      ],
    },
  });
}

// hook for component usage
export function useRfqMachine({ completeRfq, sourcingEvent }: RfqMachineProps) {
  const machine = createRfqMachine({
    completeRfq,
    sourcingEvent,
  });

  const [state, send] = useMachine(machine);

  // when machine props change, issue update event
  React.useEffect(() => {
    send({
      type: RfqMachineEventsEnum.LOAD_STATE,
      stateToLoad:
        ((sourcingEvent?.status as unknown) as RfqState) ?? RfqState.INIT,
    });
  }, [completeRfq, sourcingEvent, send]);

  return { state, send };
}
