import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { Field } from "../../components/Field/Field";
import { Form } from "../../components/Form/Form";
import { Modal } from "../../components/Modal/Modal";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  ViewerOrganizations,
  useContactPersonById,
  useUpdateContactPerson,
} from "../../schema";
import { isEmail } from "../../validators/isEmail";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

export interface ContactPersonModalProps {
  defaultContactPersonId: string;
  organization: ViewerOrganizations;
  onModalClose(): void;
}

interface RouteParams {
  contactPersonId: string;
}

export const EditContactPersonModal: React.FC<ContactPersonModalProps> = ({
  organization,
  defaultContactPersonId,
  onModalClose,
}) => {
  const { match } = useRouter<RouteParams>();

  const contactPersonId = match.params.contactPersonId;

  // load contact person by id
  const { data, loading: isFetching, error: fetchError } = useContactPersonById(
    {
      fetchPolicy: "network-only",
      variables: {
        organizationId: organization.id,
        contactPersonId,
      },
    },
  );

  // setup form
  const {
    useInput,
    useCheckbox,
    loading: isSaving,
    error: saveError,
    submit: updateContactPerson,
  } = useForm({
    mutation: useUpdateContactPerson,
    options: {
      refetchQueries: ["SupplierById"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const firstNameInput = useInput({ name: "firstName", optional: true });
  const lastNameInput = useInput({ name: "lastName", optional: true });
  const positionInput = useInput({ name: "position", optional: true });
  const phoneNumberInput = useInput({ name: "phoneNumber", optional: true }); //TODO: add user-friendly error message
  const emailInput = useInput({ name: "email", validators: [isEmail] });
  const notesInput = useInput({ name: "notes", optional: true });
  const isDefaultCheckbox = useCheckbox({ name: "isDefault" });

  // setup state
  const [isInitialized, setIsInitialized] = useState(false);

  // get contact person info if loaded
  const contactPerson = data?.viewer
    ? data.viewer.contactPersonById
    : undefined;

  useEffect(() => {
    if (!contactPerson) {
      return;
    }

    if (contactPerson.firstName) {
      firstNameInput.setValue(contactPerson.firstName);
    }

    if (contactPerson.lastName) {
      lastNameInput.setValue(contactPerson.lastName);
    }

    if (contactPerson.position) {
      positionInput.setValue(contactPerson.position);
    }

    if (contactPerson.phoneNumber) {
      phoneNumberInput.setValue(contactPerson.phoneNumber);
    }

    isDefaultCheckbox.setValue(contactPerson.id === defaultContactPersonId);
    emailInput.setValue(contactPerson.email);

    if (contactPerson.notes) {
      notesInput.setValue(contactPerson.notes);
    }

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactPerson]);

  // handle fetch error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading contact person failed"
        error={fetchError}
        modal={{ title: "Edit contact person", onModalClose }}
      />
    );
  }

  // handle save error
  if (saveError) {
    return (
      <ErrorView
        title="Updating contact person failed"
        error={saveError}
        modal={{ title: "Edit contact person", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !contactPerson || !isInitialized) {
    return <LoadingView overlay />;
  }

  // submits the form
  const submit = () => {
    updateContactPerson({
      contactPersonId,
    });
  };

  return (
    <Modal
      title={"Contact person"}
      footer={
        <>
          <Button
            data-testid="abf3c24a93"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="2b956cf016"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
      onCloseRequested={onModalClose}
    >
      <Form onSubmit={submit}>
        <Field {...firstNameInput} short autofocus label="First Name" />
        <Field {...lastNameInput} short label="Last Name" />
        <Field {...positionInput} short label="Position" />
        <Field {...phoneNumberInput} short label="Phone" />
        <Field {...emailInput} short label="Email" />
        <Field {...notesInput} short label="Notes" />
        <Field
          {...isDefaultCheckbox}
          checkbox
          label="Is default"
          isFieldEditable={!(contactPerson.id === defaultContactPersonId)}
        >
          Is Default?
        </Field>
      </Form>
    </Modal>
  );
};
