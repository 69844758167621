import React, { useCallback, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Form } from "../../components/Form/Form";
import { Button } from "../../components/Button/Button";
import {
  ViewerOrganizations,
  useUploadSuppliersExcel,
  ViewerViewer,
  UserRoleEnum,
} from "../../schema";
import { Modal } from "../../components/Modal/Modal";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { FieldError } from "../../components/FieldError/FieldError";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { ExcelSuppliersImportExampleTable } from "../../components/ExcelImportExampleTable/ExcelSuppliersImportExampleTable";
import { ExcelSuppliersImportErrorsTable } from "../../components/ExcelImportErrorsTable/ExcelSuppliersImportErrorsTable";
import { TooltipIcon } from "../../theme/svg/TooltipIcon";
import styles from "./CreateSuppliersExcel.module.scss";

export interface UploadExcelFormProps {
  organization: ViewerOrganizations;
  viewer: ViewerViewer;
  onModalClose(): void;
}

export const CreateSuppliersExcel: React.FC<UploadExcelFormProps> = ({
  organization,
  viewer,
  onModalClose,
}) => {
  // TODO: consider using useForm and implementing useDropzone etc
  // setup state (not using form for a single input)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  // setup uploading suppliers excel
  const [uploadSuppliersExcel, { loading, error, data }] =
    useUploadSuppliersExcel({
      fetchPolicy: "no-cache",
      refetchQueries: ["SrmView"],
      awaitRefetchQueries: true,
    });

  // handles file drop
  const onDrop = useCallback<OnDropCallbackFn>((acceptedFiles) => {
    // return if not exactly one file was provided
    if (acceptedFiles.length !== 1) {
      return;
    }

    // set selected file
    setSelectedFile(acceptedFiles[0]);
  }, []);

  // get import result
  const uploadResult = data ? data.uploadSupplierExcel : undefined;

  const submit = async () => {
    // we can only upload a file if it has been selected
    if (!selectedFile) {
      return;
    }

    // attempt to import the suppliers excel file
    await uploadSuppliersExcel({
      variables: {
        organizationId: organization.id,
        excel: selectedFile,
      },
    });

    // clear selected file
    setSelectedFile(undefined);
  };

  const hasErrors = uploadResult && !uploadResult.success && !selectedFile;

  useEffect(() => {
    if (uploadResult?.success) onModalClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResult?.success]);

  const hasKeyUserRole = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER].includes(role),
  );

  if (!hasKeyUserRole) {
    return <Redirect to={`/suppliers-and-contracts/${organization.urlName}`} />;
  }

  return (
    <Modal
      wide
      title="Upload Suppliers from Excel"
      onCloseRequested={() => onModalClose()}
      footer={
        <>
          <a
            href="https://www.procurementflow.com/helpcenter/how-to-import-suppliers-to-procurementflow-srm"
            target="_blank"
            rel="noopener noreferrer"
            className={styles["help-link"]}
          >
            <TooltipIcon />
            <span>Excel import user guide</span>
          </a>
          <Button
            data-testid="671b09ce6d"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="e6bd4d54d7"
            loading={loading}
            disabled={loading || selectedFile === undefined}
            onClick={submit}
          >
            Import
          </Button>
        </>
      }
    >
      <Form onSubmit={submit}>
        <Dropzone
          required
          multiple={false}
          loading={loading}
          error={error}
          selectedFile={selectedFile}
          label="Spreadsheet to import"
          accept={[
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          rejectTypeText="Please provide valid spreadsheet file (.csv, .xls, .xlsx)"
          onDrop={onDrop}
        >
          Drag and drop your supplier list Excel / CSV file here
        </Dropzone>

        {hasErrors && uploadResult ? (
          <div>
            <FieldError error="Provided spreadsheet file is not valid, please fix the issues listed below and try again" />
            <ExcelSuppliersImportErrorsTable rows={uploadResult.rows} />
          </div>
        ) : (
          <>
            <FieldLabel label="Example spreadsheet structure" />
            <ExcelSuppliersImportExampleTable />
          </>
        )}
      </Form>
    </Modal>
  );
};
