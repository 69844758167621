import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import {
  UpdatePurchaseRequestFormData,
  PurchaseRequestForm,
} from "../../components/PurchaseRequestForm/PurchaseRequestForm";
import { useActiveOrganization } from "../../hooks/useActiveOrganization";
import { useRouter } from "../../hooks/useRouter";
import { useForm as useApolloForm } from "../../lib/react-apollo-hooks-form";
import {
  ViewerViewer,
  usePurchaseRequestsDetailsByCode,
  RequesterPortalViewOrganization,
  PurchaseRequestStatusEnum,
  PurchaseRequestsDetailsByCodePurchaseRequestByCode,
  useUpdatePurchaseRequestFromPortal,
  UpdatePurchaseRequestFromPortalMutationVariables,
} from "../../schema";
import { convertPRFormDataToMutation } from "../../services/convertPRFormDataToMutation";
import { isDefined } from "../../services/isDefined";
import { CompleteReadyIcon } from "../../theme/svg/CompleteReadyIcon";
import { ItemParams } from "../EditPurchaseRequestItemView/EditPurchaseRequestItemView";
import { EditPurchaseRequestProduct } from "../EditPurchaseRequestItemView/EditPurchaseRequestProduct";
import { EditPurchaseRequestService } from "../EditPurchaseRequestItemView/EditPurchaseRequestService";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";
import styles from "./EditRequestFromPortalView.module.scss";

interface RouteParams {
  purchaseRequestId: string;
}
interface EditRequestFromPortalViewProps {
  organization: RequesterPortalViewOrganization;
  viewer: ViewerViewer;
}

export const EditRequestFromPortalView: React.FC<EditRequestFromPortalViewProps> =
  ({ organization, viewer }) => {
    // extract request type from URL parameters
    const { match, history } = useRouter<RouteParams>();
    const { purchaseRequestId } = match.params;
    const activeOrganization = useActiveOrganization(viewer.organizations);

    // Initial states
    let purchaseRequest:
      | PurchaseRequestsDetailsByCodePurchaseRequestByCode
      | undefined = undefined;
    const [editingItem, setIsEditingItem] = useState<
      { item: ItemParams; itemType: string } | undefined
    >(undefined);

    const [files, setFiles] = React.useState<File[]>([]);

    // Configure form
    const methods = useForm<UpdatePurchaseRequestFormData>();

    // Configure create purchase request form
    const {
      submit,
      loading: isCreating,
      error: createError,
    } = useApolloForm({
      mutation: useUpdatePurchaseRequestFromPortal,
      options: {
        refetchQueries: [
          "PurchaseRequestsDetailsByCode",
          "RequesterPortalView",
        ],
        awaitRefetchQueries: true,
      },
      onSuccess: () => {
        setFiles([]);
      },
    });

    // Control form submission
    const savePurchaseRequest = async (
      values: UpdatePurchaseRequestFormData,
      newStatus?: PurchaseRequestStatusEnum,
    ) => {
      // Create a new purchase request
      const purchaseRequestDetails: UpdatePurchaseRequestFromPortalMutationVariables =
        {
          ...convertPRFormDataToMutation(values),
          status: isDefined(newStatus)
            ? PurchaseRequestStatusEnum[newStatus]
            : PurchaseRequestStatusEnum.DRAFT,
          purchaseRequestId: values.purchaseRequestId,
          attachments: files,
        };

      submit(purchaseRequestDetails);
    };

    const draftPurchaseRequest = methods.handleSubmit((values: any) => {
      savePurchaseRequest(values);
    });

    const readyPurchaseRequest = methods.handleSubmit((values: any) => {
      savePurchaseRequest(values, PurchaseRequestStatusEnum.READY);
    });

    // Resolve required information
    // Load purchase request
    const {
      data,
      error: purchaseRequestError,
      loading,
      refetch,
    } = usePurchaseRequestsDetailsByCode({
      fetchPolicy: "network-only",
      variables: {
        organizationId: activeOrganization!.id,
        code: purchaseRequestId,
      },
    });

    // ERROR VIEW IF HAS ERROR
    if (purchaseRequestError !== undefined)
      return <ErrorView error={purchaseRequestError}></ErrorView>;

    // LOADING
    if (loading || !data) {
      return <LoadingView overlay></LoadingView>;
    }

    if (!organization) {
      return <WithoutOrganizationView viewer={viewer} />;
    }

    const onSuccessEditItem = async () => {
      // Refetch purchase request to get update item
      await refetch();
      setIsEditingItem(undefined);
    };

    purchaseRequest = data?.viewer
      ? data.viewer.purchaseRequestByCode
      : undefined;

    // Redirect to view details if purchase request isn't draft
    if (purchaseRequest?.status !== PurchaseRequestStatusEnum.DRAFT)
      history.replace(match.url.slice(0, -5));

    return (
      <>
        {(isCreating || !purchaseRequest) && (
          <LoadingView overlay></LoadingView>
        )}
        {purchaseRequest && (
          <>
            <FormProvider {...methods}>
              <PurchaseRequestForm
                files={files}
                setFiles={setFiles}
                organization={organization}
                purchaseRequest={purchaseRequest}
                viewer={viewer}
                error={createError}
                savePurchaseRequest={savePurchaseRequest}
                setIsEditingItem={setIsEditingItem}
                isEditing
                fromPortal
                department={null}
                project={null}
                costCentre={null}
              />
              <div className={styles["form-footer"]}>
                <Button secondary onClick={() => draftPurchaseRequest()}>
                  Save
                </Button>
                <Button onClick={() => readyPurchaseRequest()}>
                  <CompleteReadyIcon /> Request ready
                </Button>
              </div>
              {editingItem && (
                <>
                  {editingItem.itemType === "PRODUCT" ? (
                    <EditPurchaseRequestProduct
                      organization={organization}
                      product={editingItem.item}
                      onSuccess={onSuccessEditItem}
                      onCancel={() => setIsEditingItem(undefined)}
                      onModalClose={() => setIsEditingItem(undefined)}
                    />
                  ) : (
                    <EditPurchaseRequestService
                      organization={organization}
                      service={editingItem.item}
                      onSuccess={onSuccessEditItem}
                      onCancel={() => setIsEditingItem(undefined)}
                      onModalClose={() => setIsEditingItem(undefined)}
                    />
                  )}
                </>
              )}
            </FormProvider>
          </>
        )}
      </>
    );
  };
