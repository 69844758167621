import * as React from "react";
export const RfpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 192 246" {...props}>
    <path d="M4.5 6.2c-.3.7-.4 53.9-.3 118.3l.3 117 90.7.3 90.8.2V64.5l-29.8-29.8L126.5 5H65.7C18 5 4.8 5.3 4.5 6.2zM114 50.5V78h54v146H23V23h91v27.5zm40 8.8c0 .4-4.8.6-10.7.5l-10.8-.3-.3-11.5-.3-11.5 11.1 11c6 6 11 11.3 11 11.8z" />
    <path
      d="M30.41 150.124q-.7 0-1.26-.49-.49-.56-.49-1.26v-45.43q0-.77.49-1.26.49-.56 1.26-.56h19.25q9.24 0 14.42 4.2 5.25 4.2 5.25 11.83 0 4.9-2.31 8.33-2.24 3.43-6.23 5.32l9.45 17.08q.21.42.21.77 0 .56-.42 1.05-.42.42-1.05.42h-9.87q-2.03 0-2.87-1.89l-7.7-15.19h-6.72v15.33q0 .77-.56 1.26-.49.49-1.26.49zm19.18-27.37q3.01 0 4.55-1.47 1.61-1.54 1.61-4.2t-1.61-4.27q-1.54-1.61-4.55-1.61h-7.77v11.55zm29.492 27.37q-.7 0-1.26-.49-.49-.56-.49-1.26v-45.43q0-.77.49-1.26.49-.56 1.26-.56h32.55q.77 0 1.26.56.56.49.56 1.26v7.42q0 .77-.56 1.26-.49.49-1.26.49h-21.42v9.73h20.02q.77 0 1.26.56.56.49.56 1.26v7.42q0 .77-.56 1.26-.49.49-1.26.49h-20.02v15.54q0 .7-.49 1.26-.49.49-1.26.49zm43.477 0q-.7 0-1.26-.49-.49-.56-.49-1.26v-45.43q0-.77.49-1.26.49-.56 1.26-.56h19.81q9.1 0 14.28 4.13t5.18 12.11q0 7.98-5.18 11.97-5.18 3.92-14.28 3.92h-7.98v15.12q0 .7-.49 1.26-.49.49-1.26.49zm19.46-26.88q2.87 0 4.48-1.47 1.68-1.54 1.68-4.48 0-2.73-1.54-4.41-1.47-1.68-4.62-1.68h-7.84v12.04z"
      aria-label="RFP"
      fontWeight={700}
      fontSize={700}
      fontFamily="Rubik"
      letterSpacing={0}
      wordSpacing={0}
    />
  </svg>
);
