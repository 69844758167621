export function splitFullName(fullName: string): [string, string] {
  const spaceSplittedFullName = fullName.split(" ");
  const commaAndSpaceSplittedFullName = fullName.split(", ");

  // there is no comma to split names
  if (
    commaAndSpaceSplittedFullName.length === 1 &&
    spaceSplittedFullName.length > commaAndSpaceSplittedFullName.length
  ) {
    if (spaceSplittedFullName.length === 2) {
      return [`${spaceSplittedFullName[0]}`, `${spaceSplittedFullName[1]}`];
    }

    if (spaceSplittedFullName.length === 3) {
      return [
        `${spaceSplittedFullName[0]} ${spaceSplittedFullName[1]}`,
        `${spaceSplittedFullName[2]}`,
      ];
    }

    if (spaceSplittedFullName.length === 4) {
      return [
        `${spaceSplittedFullName[0]} ${spaceSplittedFullName[1]} ${spaceSplittedFullName[2]}`,
        `${spaceSplittedFullName[3]}`,
      ];
    }
  }

  // there is a comma to split names in a simple case
  if (
    commaAndSpaceSplittedFullName.length > 1 &&
    spaceSplittedFullName.length >= commaAndSpaceSplittedFullName.length
  ) {
    if (commaAndSpaceSplittedFullName.length === 2) {
      return [
        `${commaAndSpaceSplittedFullName[1]}`,
        `${commaAndSpaceSplittedFullName[0]}`,
      ];
    }
  }

  // default
  return [`${spaceSplittedFullName}`, ""];
}
