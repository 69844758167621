import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
// eslint-disable-next-line import/named
import { Controller, useFormContext, ArrayField } from "react-hook-form";
import { currencies } from "../../constants";
import {
  useCreateQuantityUnit,
  PurchaseRequestItemTypeEnum,
  PurchaseRequestsDetailsByCodeItems,
  CurrencyEnum,
  useRemovePurchaseRequestItemAttachment,
  Attachment,
} from "../../schema";
import { matches } from "../../services/matches";
import {
  quantityUnitToAutosuggestItem,
  QuantityUnitInfo,
} from "../../services/quantityUnitToAutosuggestItem";
import { PlusIcon } from "../../theme/svg/PlusIcon";
import { ProductIcon } from "../../theme/svg/ProductIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { ServiceIcon } from "../../theme/svg/ServiceIcon";
import { AccordionSecondary } from "../Accordion/AccordionSecondary";
import { Attachments } from "../Attachments/Attachments";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";

import { Button } from "../Button/Button";
import { ButtonGroup } from "../ButtonGroup/ButtonGroup";
import { Datepicker } from "../Datepicker/Datepicker";
import { Field } from "../Field/Field";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import {
  ItemAutosuggest,
  AutosuggestItem,
} from "../ItemAutosuggest/ItemAutosuggest";
import { NonUploadedFiles } from "../NonUploadedFiles/NonUploadedFiles";
import {
  CreatePurchaseRequestFormData,
  PurchaseRequestByCodeOrganizationWithOptionalParameters,
} from "../PurchaseRequestForm/PurchaseRequestForm";
import { Select } from "../Select/Select";
import { UploadedAttachment } from "../UploadedAttachment/UploadedAttachment";
import { NumberInput } from "../NumberInput/NumberInput";
import { EsourcingQuestionFieldList } from "../EsourcingQuestionFieldList/EsourcingQuestionFieldList";
import { EsourcingQuantityFieldList } from "../EsourcingQuantityFieldList/EsourcingQuantityFieldList";
import styles from "./PurchaseRequestItemForm.module.scss";

type OptionalFieldConfig = {
  key: string;
  text: string;
  active?: boolean;
};

export type EsourcingListRefType = {
  itemIndex: number;
  append: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined,
  ) => void;
};

export type EsourcingFieldType = "QUESTION" | "QUANTITY";

interface PurchaseRequestItemFormProps {
  index: number;
  organization: PurchaseRequestByCodeOrganizationWithOptionalParameters;
  units: QuantityUnitInfo[];
  value: Partial<ArrayField<PurchaseRequestItemProp, "id">>;
  purchaseRequestItem?: PurchaseRequestsDetailsByCodeItems;
  active?: boolean;
  removeItem?: (index: number, id?: string | undefined) => Promise<void>;
  fromPortal?: boolean;
}

export type PurchaseRequestItemProp = {
  id?: string | null;
  name: string;
  type: PurchaseRequestItemTypeEnum;
  quantity?: number | string | null;
  unit?: string | null;
  suggestedSupplier?: string | null;
  unitPrice?: string | null;
  currency?: string | null;
  expectedDeliveryDate?: string | null;
  productUrl?: string | null;
  additionalInfo?: string | null;
  code?: string | null;
  supplierCode?: string | null;
  attachments?: Pick<Attachment, "id" | "filename" | "url">[] | null;
  files?: File[];
  esourcingFields?: { [key: string]: string | null };
};

export const PurchaseRequestItemForm: React.FC<PurchaseRequestItemFormProps> =
  ({
    index,
    organization,
    units,
    value,
    purchaseRequestItem,
    active,
    removeItem,
    fromPortal,
  }) => {
    const appendQuestionRef = useRef<EsourcingListRefType>();
    const appendQuantityRef = useRef<EsourcingListRefType>();
    const { control, errors, register, setValue, unregister } =
      useFormContext<CreatePurchaseRequestFormData>();
    // Initial states
    const [quantityUnitDisplayValue, setQuantityUnitDisplayValue] =
      useState("");
    const [chosenQuantityUnit, setChosenQuantityUnit] =
      useState<AutosuggestItem>();
    const [itemType, setItemType] = useState<PurchaseRequestItemTypeEnum>(
      purchaseRequestItem
        ? purchaseRequestItem.type
        : PurchaseRequestItemTypeEnum.PRODUCT,
    );
    const [expectedDeliveryDate, setExpectedDeliveryDate] =
      useState<Date | null>(null);
    const [attachments, setAttachments] = useState<
      Pick<Attachment, "id" | "filename" | "url">[]
    >([]);
    const [files, setFiles] = useState<File[] | undefined>([]);
    const [optionalFields, setOptionalFields] = useState(
      new Map<string, OptionalFieldConfig>([
        ["productUrl", { key: "productUrl", text: "Link" }],
        ["additionalInfo", { key: "additionalInfo", text: "Additional Info" }],
        ["code", { key: "code", text: "Our code" }],
        ["supplierCode", { key: "supplierCode", text: "Supplier code" }],
        ["attachments", { key: "attachments", text: "Attachments" }],
      ]),
    );
    const organizationBaseCurrency = organization.baseCurrency || "EUR";
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyEnum>(
      organizationBaseCurrency,
    );
    const nameRef = useRef<HTMLInputElement | null>(null);

    // Used to control refetch queries;
    const suggestBaseOptions = {
      refetchQueries: ["Viewer", "RequesterPortalView"],
      awaitRefetchQueries: true,
    };

    // Configure suggestions
    const suggestQuantityUnits = async (value: string) =>
      value.length === 0
        ? units
        : units
            ?.filter((unit) => matches(value, unit.name))
            .map(quantityUnitToAutosuggestItem);

    // Setup creation of suggest items
    const [createQuantityUnit, { loading: isCreatingQuantityUnit }] =
      useCreateQuantityUnit(suggestBaseOptions);

    // Handle item type
    useEffect(() => {
      if (itemType === PurchaseRequestItemTypeEnum.SERVICE) {
        unregister(`items[${index}].quantity`);
        unregister(`items[${index}].unit`);
        setChosenQuantityUnit(undefined);
      } else {
        if (purchaseRequestItem) {
          // Set old unit if user have toggled item type
          const prIUnit = units.find(
            (unit) => unit.name === purchaseRequestItem.unit,
          );

          if (prIUnit) {
            setChosenQuantityUnit(prIUnit);
            setQuantityUnitDisplayValue(prIUnit.name);
            setValue(`items[${index}].unit`, prIUnit.name);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemType]);

    // Configure attachments
    const onDrop = useCallback<OnDropCallbackFn>(
      (acceptedFiles) => {
        // set selected file
        setFiles([...(files ?? []), ...acceptedFiles]);
      },
      [files],
    );

    useEffect(() => {
      if (files && files.length > 0) {
        setValue(`items[${index}].files`, files);
      } else {
        unregister(`items[${index}].files`);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    // setup removing attachment
    const [removeAttachment] = useRemovePurchaseRequestItemAttachment();

    // Remove all attachments
    const removeAllAttachments = () => {
      if (
        purchaseRequestItem?.attachments &&
        purchaseRequestItem.attachments.length > 0
      ) {
        purchaseRequestItem.attachments.forEach((i) => {
          removeAttachment({
            variables: {
              attachmentId: i.id,
              purchaseRequestItemId: purchaseRequestItem.id,
            },
          });
        });
      }
    };

    // Handle optional fields
    // Toggle visibility of the optional field
    const toggleOptionalField = (field: string) => {
      setOptionalFields((old) => {
        const temp = new Map(old);
        const current = old?.get(field);
        if (current) {
          if (current.active) {
            // unregister field if is removing
            unregister(`items[${index}].${field}`);
            // remove all attachments
            if (field === "attachments") {
              removeAllAttachments();
            }
          }

          // Toggle visibility of the optional field
          temp.set(field, { ...current, active: !current.active });
        }
        return temp;
      });
    };

    // Render optional field base on field type
    const renderOptionalField = (field: OptionalFieldConfig) => {
      // Used to get purchase request field value as the field maybe with different key
      const getFieldValue = (key: keyof PurchaseRequestItemProp) => {
        if (!purchaseRequestItem) {
          return;
        }

        // TODO: Render files
        if (key === "files") {
          return;
        }

        return purchaseRequestItem ? purchaseRequestItem[key] : undefined;
      };

      switch (field.key) {
        case "additionalInfo":
          return (
            <Controller
              as={Field}
              className={styles.input}
              textareaRef={register}
              defaultValue={value.additionalInfo || ""}
              name={`items[${index}].additionalInfo`}
              label={field.text}
              textarea
              short
            />
          );

        case "attachments":
          return (
            <div>
              <FieldLabel label="Attachments" />

              <div className={styles.attachments}>
                {attachments &&
                  attachments.length > 0 &&
                  attachments.map((attachment) => (
                    <UploadedAttachment
                      key={attachment.id}
                      attachment={attachment}
                      onRemove={() =>
                        purchaseRequestItem?.id &&
                        removeAttachment({
                          variables: {
                            attachmentId: attachment.id,
                            purchaseRequestItemId: purchaseRequestItem.id,
                          },
                        })
                      }
                    />
                  ))}
                {files && files.length > 0 && (
                  <NonUploadedFiles files={files} setFiles={setFiles} />
                )}
              </div>

              <Controller
                as={Attachments}
                control={control}
                name={`items[${index}].files`}
                multiple
                loading={false}
                onDrop={(acceptedFiles: File[]) => onDrop(acceptedFiles)}
              />
            </div>
          );

        default:
          return (
            <Controller
              as={Field}
              className={styles.input}
              inputRef={register}
              defaultValue={
                getFieldValue(field.key as keyof PurchaseRequestItemProp) || ""
              }
              name={`items[${index}].${field.key}`}
              key={`items[${index}].${field.key}`}
              label={field.text}
              short
            />
          );
      }
    };

    const addEsourcingQuestionField = () => {
      if (appendQuestionRef.current) {
        appendQuestionRef.current.append({ question: "" });
      }
    };

    const addEsourcingQuantityField = () => {
      if (appendQuantityRef.current) {
        appendQuantityRef.current.append({ quantity: "" });
      }
    };

    // Fill item if is editing purchase request
    useEffect(() => {
      if (!purchaseRequestItem) {
        return;
      }

      // If has value set optional field visible
      setOptionalFields(
        new Map<string, OptionalFieldConfig>([
          [
            "productUrl",
            {
              key: "productUrl",
              text: "Link",
              active: !!purchaseRequestItem.productUrl,
            },
          ],
          [
            "additionalInfo",
            {
              key: "additionalInfo",
              text: "Additional Info",
              active: !!purchaseRequestItem.additionalInfo,
            },
          ],
          [
            "code",
            {
              key: "code",
              text: "Our code",
              active: !!purchaseRequestItem.code,
            },
          ],
          [
            "supplierCode",
            {
              key: "supplierCode",
              text: "Supplier code",
              active: !!purchaseRequestItem.supplierCode,
            },
          ],
          [
            "customFields",
            {
              key: "customFields",
              text: "Custom Fields",
              active: !!purchaseRequestItem.customFields,
            },
          ],
          [
            "attachments",
            {
              key: "attachments",
              text: "Attachments",
              active: purchaseRequestItem.attachments
                ? purchaseRequestItem.attachments.length > 0
                : false,
            },
          ],
        ]),
      );

      const prIUnit = units.find(
        (unit) => unit.name === purchaseRequestItem.unit,
      );

      if (prIUnit) {
        setChosenQuantityUnit(prIUnit);
        setQuantityUnitDisplayValue(prIUnit.name);
        setValue(`items[${index}].unit`, prIUnit.name);
      }

      if (purchaseRequestItem.suggestedSupplier) {
        setValue(
          `items[${index}].suggestedSupplier`,
          purchaseRequestItem.suggestedSupplier,
        );
      }

      if (purchaseRequestItem.expectedDeliveryDate) {
        setExpectedDeliveryDate(
          new Date(purchaseRequestItem.expectedDeliveryDate),
        );
        setValue(
          `items[${index}].expectedDeliveryDate`,
          new Date(purchaseRequestItem.expectedDeliveryDate),
        );
      }

      if (purchaseRequestItem.currency) {
        setSelectedCurrency(purchaseRequestItem.currency);
        setValue(`items[${index}].currency`, purchaseRequestItem.currency);
      }

      if (purchaseRequestItem.unitPrice) {
        setValue(`items[${index}].unitPrice`, purchaseRequestItem.unitPrice);
      }

      if (purchaseRequestItem.additionalInfo) {
        setValue(
          `items[${index}].additionalInfo`,
          purchaseRequestItem.additionalInfo,
        );
      }

      if (purchaseRequestItem.productUrl) {
        setValue(`items[${index}].productUrl`, purchaseRequestItem.productUrl);
      }

      if (purchaseRequestItem.attachments) {
        setAttachments(
          purchaseRequestItem.attachments as Pick<
            Attachment,
            "id" | "filename" | "url"
          >[],
        );
      }

      if (purchaseRequestItem.esourcingFields) {
        setValue(
          `items[${index}].esourcingFields`,
          purchaseRequestItem.esourcingFields,
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseRequestItem]);

    // Set focus on first element;
    useEffect(() => {
      if (active) nameRef.current?.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        className={classNames(styles.item, {
          [styles["item--portal"]]: fromPortal,
        })}
      >
        <div className={styles["item-info"]}>
          {purchaseRequestItem?.id && (
            <input
              type="hidden"
              name={`items[${index}].id`}
              defaultValue={purchaseRequestItem.id}
              ref={register}
            />
          )}
          <Controller
            as={Field}
            control={control}
            className={classNames(styles.input, styles["input--name"])}
            defaultValue={value?.name || ""}
            inputRef={(e: HTMLInputElement) => {
              nameRef.current = e;
            }}
            errors={
              errors.items && errors.items[index]?.name
                ? ["Should not be left empty"]
                : undefined
            }
            name={`items[${index}].name`}
            label="Item name"
            rules={{ required: true }}
            required
          />
          {itemType === PurchaseRequestItemTypeEnum.PRODUCT && (
            <>
              <NumberInput
                className={styles["input"]}
                name={`items[${index}].quantity`}
                inputRef={register({ required: true })}
                label="Quantity"
                errors={
                  errors.items && errors.items[index]?.quantity
                    ? ["Should not be left empty"]
                    : undefined
                }
                required
              />
              <div className={styles.input}>
                <Controller
                  as={ItemAutosuggest}
                  control={control}
                  rules={{ required: true }}
                  errors={
                    errors.items && errors.items[index]?.unit
                      ? ["Should not be left empty"]
                      : undefined
                  }
                  fetchSuggestions={suggestQuantityUnits}
                  value={quantityUnitDisplayValue}
                  defaultValue={quantityUnitDisplayValue}
                  chosenSuggestion={chosenQuantityUnit}
                  onChange={(newValue: any) => newValue}
                  onChoose={(unit: AutosuggestItem | undefined) =>
                    setChosenQuantityUnit(unit)
                  }
                  onCreateNew={async (name: string) => {
                    const createdQuantityUnitResponse =
                      await createQuantityUnit({
                        variables: {
                          organizationId: organization.id,
                          name: name,
                        },
                      });
                    const createdQuantityUnit =
                      createdQuantityUnitResponse.data?.createQuantityUnit;

                    if (createdQuantityUnit) {
                      setChosenQuantityUnit(
                        quantityUnitToAutosuggestItem(createdQuantityUnit),
                      );
                      setQuantityUnitDisplayValue(name);
                    }
                  }}
                  loading={isCreatingQuantityUnit}
                  name={`items[${index}].unit`}
                  label="Unit"
                  required
                />
              </div>
            </>
          )}
          <div className={styles["item-type"]}>
            <Controller
              control={control}
              name={`items[${index}].type`}
              defaultValue={value?.type}
              render={({ onChange, value }) => (
                <ButtonGroup
                  relativeId={index.toString()}
                  options={[
                    {
                      key: PurchaseRequestItemTypeEnum.PRODUCT,
                      title: "Product",
                      icon: <ProductIcon />,
                    },
                    {
                      key: PurchaseRequestItemTypeEnum.SERVICE,
                      title: "Service",
                      icon: <ServiceIcon />,
                    },
                  ]}
                  selectedKey={value}
                  allowUnselect={false}
                  onChange={(selected) => {
                    onChange(selected.value);
                    setItemType(selected.value as PurchaseRequestItemTypeEnum);
                  }}
                  large
                />
              )}
            />
          </div>
          {removeItem && (
            <div className={styles["button"]}>
              <Button
                icon={<RemoveIcon className={styles["button--remove"]} />}
                onClick={() => removeItem(index, purchaseRequestItem?.id)}
              />
            </div>
          )}
        </div>
        <AccordionSecondary title="Optional" initiallyOpen={false} small>
          <div className={styles.optionals}>
            <div className={styles["optional-fields-area"]}>
              <Controller
                as={Field}
                control={control}
                className={styles.input}
                defaultValue={purchaseRequestItem?.suggestedSupplier || ""}
                name={`items[${index}].suggestedSupplier`}
                label="Suggested supplier"
                short
              />
              <NumberInput
                name={`items[${index}].unitPrice`}
                label="Price"
                inputRef={register}
                className={styles["fields-price"]}
                addon={
                  <Controller
                    as={Select}
                    control={control}
                    name={`items[${index}].currency`}
                    options={currencies.map((currency) => ({
                      value: currency.symbol,
                      label: currency.symbol,
                    }))}
                    defaultValue={selectedCurrency}
                    secondary
                  />
                }
              />
              <Controller
                control={control}
                render={(props) => (
                  <Datepicker
                    onChange={(date) => {
                      if (date) {
                        setExpectedDeliveryDate(date);
                        props.onChange(date);
                      }
                    }}
                    selected={expectedDeliveryDate}
                    minDate={new Date()}
                    onClear={() => {
                      setExpectedDeliveryDate(null);
                      unregister(`items[${index}].expectedDeliveryDate`);
                    }}
                    label="Delivery date"
                  />
                )}
                defaultValue={expectedDeliveryDate}
                name={`items[${index}].expectedDeliveryDate`}
              />
              {Array.from(optionalFields.values())
                .filter((val) => val.active)
                .map((value) => {
                  return (
                    <div key={value.key} className={styles["optional-field"]}>
                      {renderOptionalField(value)}
                      <Button
                        icon={<RemoveIcon />}
                        onClick={() => toggleOptionalField(value.key)}
                      />
                    </div>
                  );
                })}
              {/* {Array.from(esourcingFields.values()).map((field) => ( */}
              <EsourcingQuestionFieldList
                className={styles.input}
                itemIndex={index}
                ref={appendQuestionRef}
              />
              <EsourcingQuantityFieldList
                className={styles.input}
                itemIndex={index}
                ref={appendQuantityRef}
              />
              {/* ))} */}
            </div>
            <div>
              {Array.from(optionalFields.values()).map((value, index) => (
                <button
                  key={index}
                  className={classNames(styles["add-optional"], {
                    [styles["add-optional--active"]]: value.active,
                  })}
                  onClick={() => {
                    toggleOptionalField(value.key);
                  }}
                >
                  <PlusIcon /> Add {value.text}
                </button>
              ))}
              <button
                key={index}
                className={styles["add-optional"]}
                onClick={() => {
                  addEsourcingQuestionField();
                }}
              >
                <PlusIcon /> Add eSourcing questions
              </button>
              <button
                key={index}
                className={styles["add-optional"]}
                onClick={() => {
                  addEsourcingQuantityField();
                }}
              >
                <PlusIcon /> Add eSourcing quantity
              </button>
            </div>
          </div>
        </AccordionSecondary>
      </div>
    );
  };
