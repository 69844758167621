import { InputTransformFn } from "../lib/react-apollo-hooks-form";

export function formatTextNumber(decimalPlaces: number): InputTransformFn {
  return (value) => {
    // return empty string if something other than a string is provided (should not really happen)
    if (typeof value !== "string") {
      return "";
    }

    // allow empty string
    if (value.length === 0) {
      return "";
    }

    // extract integer and fractional digits
    const numbersOnly = value.replace(/[^0-9.]/g, "");
    const containsDecimal = numbersOnly.indexOf(".") !== -1;
    const tokens = numbersOnly.split(".");
    const integerDigits = tokens[0];
    const fractionalDigits = tokens[1];

    // add spaces after every block of three integers
    // const formattedInteger = integerDigits.replace(/(\d)(?=(\d{3})+$)/g, "$1 ");

    // limit the result to configured decimal places
    const numericText = containsDecimal
      ? `${integerDigits}.${fractionalDigits.substr(0, decimalPlaces)}`
      : integerDigits;

    return numericText;
  };
}
