import * as React from "react";
export const ProductIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.932 8.022L22.63 3.746a.583.583 0 00-.362-.286l-.003-.001L12.677.907a.595.595 0 00-.305 0L2.735 3.458h-.003a.583.583 0 00-.362.287L.068 8.022a.57.57 0 00.267.792l2.003.934v5.841c0 .222.128.424.33.52l9.602 4.57a.592.592 0 00.505 0h.004l9.6-4.57a.577.577 0 00.331-.52V9.75v-.023l1.955-.911a.57.57 0 00.267-.793zm-12.408-5.96l7.667 2.04L12.5 6.915l-7.687-2.81 7.71-2.042zm-.811 5.795L3.164 4.733 1.39 8.03l8.44 3.934 1.883-4.106zm-8.204 7.37v-4.933l6.359 2.964a.591.591 0 00.784-.284l1.287-2.807v9.072l-8.43-4.012zm9.778-7.37l1.883 4.106 8.44-3.934-1.774-3.296-8.549 3.124z"
    />
  </svg>
);
