import React from "react";
import classNames from "classnames";
import { ApprovalBadge } from "../ApprovalBadge/ApprovalBadge";
import { CardStatusLevelEnum, ApprovalWorkflowStatusEnum } from "../../schema";
import { Row } from "../Row/Row";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import styles from "./ApprovalRequest.module.scss";

export interface ApprovalRequestProps {
  level: CardStatusLevelEnum;
  purchaseRequestCreatedDate: string | null;
  workflowUpdatedDate: string | null;
  secondary?: boolean;
  title: string | null;
  status: string;
  statusCode: ApprovalWorkflowStatusEnum;
  price: string | null;
  requester: string | null;
  className?: string;
  dueDate?: string | null;
  creatorComment?: string | null;
  onClick?(): void;
  code: string;
}

interface ApprovalSummaruItemProps {
  title: string;
  value?: string | null;
  observation?: string;
}

const AprrovalSummaryItem: React.FC<ApprovalSummaruItemProps> = ({
  title,
  value,
  observation,
}) => {
  return (
    <div className={styles["summary-item"]}>
      {title}
      <div className={styles["summary-item__value"]}>
        <strong>{value}</strong> {observation && <small>{observation}</small>}
      </div>
    </div>
  );
};

export const ApprovalRequest: React.FC<ApprovalRequestProps> = ({
  purchaseRequestCreatedDate,
  workflowUpdatedDate,
  secondary,
  title,
  status,
  statusCode,
  price,
  requester,
  className,
  dueDate,
  onClick,
  code,
}) => {
  return (
    <div
      data-testid="e155f4b6f7"
      className={classNames(
        styles.container,
        { [styles["container--secondary"]]: secondary },
        className,
        {
          [styles["status--approved"]]:
            statusCode === ApprovalWorkflowStatusEnum.APPROVED,
          [styles["status--canceled"]]:
            statusCode === ApprovalWorkflowStatusEnum.CANCELLED,
          [styles["status--draft"]]:
            statusCode === ApprovalWorkflowStatusEnum.DRAFT,
          [styles["status--declined"]]:
            statusCode === ApprovalWorkflowStatusEnum.DECLINED,
          [styles["status--inprogress"]]:
            statusCode === ApprovalWorkflowStatusEnum.IN_PROGRESS,
        },
      )}
      onClick={onClick}
    >
      <Row className={styles.row}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            AWF-{code}: {title}
          </h2>
        </div>
        <ApprovalBadge
          level={statusCode}
          text={status}
          className={styles.status}
        />
      </Row>
      <Row className={styles.row}>
        <div className={styles.content}>
          <div className={styles.summary}>
            <AprrovalSummaryItem
              title="Requested by:"
              value={requester}
              observation={`about ${purchaseRequestCreatedDate}`}
            />
            <AprrovalSummaryItem title="Amount Requested:" value={price} />
            <AprrovalSummaryItem
              title="Decision by:"
              value={dueDate || "Not specified"}
            />
            <AprrovalSummaryItem
              title="Time since last change:"
              value={workflowUpdatedDate}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <DownArrowIcon
            className={classNames(styles["details-view-link"], {
              [styles["details-view-link--secondary"]]: secondary,
            })}
          />
        </div>
      </Row>
    </div>
  );
};
