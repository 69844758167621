import * as React from "react";
export const ExcelIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Layer 1" viewBox="0 0 22 20" {...props}>
    <path
      d="M20.58 17.3h-9.294a.664.664 0 01-.664-.664V3.361a.664.664 0 01.664-.663h9.3a.664.664 0 01.664.663V16.64a.664.664 0 01-.664.664z"
      fill="#eceff1"
    />
    <path
      d="M13.942 6.681h-2.656a.664.664 0 010-1.328h2.656a.664.664 0 010 1.328zm0 2.656h-2.656a.664.664 0 010-1.328h2.656a.664.664 0 110 1.328zm0 2.655h-2.656a.664.664 0 010-1.328h2.656a.664.664 0 010 1.328zm0 2.656h-2.656a.664.664 0 010-1.328h2.656a.664.664 0 110 1.328zm3.983-7.967H16.6a.664.664 0 010-1.328h1.327a.664.664 0 010 1.328zm0 2.656H16.6a.664.664 0 010-1.328h1.327a.664.664 0 110 1.328zm0 2.655H16.6a.664.664 0 010-1.328h1.327a.664.664 0 110 1.328zm0 2.656H16.6a.664.664 0 010-1.328h1.327a.664.664 0 110 1.328z"
      fill="#388e3c"
    />
    <path
      d="M11.71.2a.654.654 0 00-.546-.141L.542 2.046A.663.663 0 000 2.7v14.6a.662.662 0 00.542.652l10.622 1.992a.66.66 0 00.774-.522.707.707 0 00.012-.13V.705A.663.663 0 0011.71.2z"
      fill="#2e7d32"
    />
    <path
      d="M9.13 12.22l-2.1-2.4 2.124-2.73a.665.665 0 00-1.048-.817L6.14 8.8 4.483 6.908a.664.664 0 00-1 .874L5.31 9.869l-1.851 2.38a.665.665 0 001.009.867l.04-.052L6.2 10.886l1.93 2.206a.659.659 0 00.929.07l.008-.007a.662.662 0 00.062-.936z"
      fill="#fafafa"
    />
  </svg>
);
