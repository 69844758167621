import * as React from "react";
export const DeleteFileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="1 1 7 7" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.001 4.167l2.083-2.084.834.834L4.834 5l2.084 2.083-.834.834-2.083-2.084-2.083 2.084-.834-.834L3.168 5 1.084 2.917l.834-.834L4 4.167z"
      fill="red"
    />
  </svg>
);
