import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { View } from "../../../components/View/View";
import { ViewerViewer, useUpdateProfilePicture } from "../../../schema";
import {
  Dropzone,
  OnDropCallbackFn,
} from "../../../components/Dropzone/Dropzone";
import { Avatar } from "../../../components/Avatar/Avatar";
import styles from "./UploadView.module.scss";

export interface UploadViewProps {
  viewer: ViewerViewer | null;
}

export const UploadView: React.FC<UploadViewProps> = ({ viewer }) => {
  const [updateProfilePicture, { loading, error }] = useUpdateProfilePicture({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
  });

  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        return;
      }

      updateProfilePicture({
        variables: {
          profilePicture: acceptedFiles[0],
        },
      });
    },
    [updateProfilePicture],
  );

  return (
    <View className={styles.view}>
      <h1>
        <Link data-testid="31b55d997b" to="/experiments">
          Experiments
        </Link>{" "}
        / Upload
      </h1>
      <Avatar large user={viewer} className={styles.avatar} />
      <Dropzone
        multiple={false}
        loading={loading}
        error={error}
        label="Profile picture"
        accept={["image/jpeg", "image/png"]}
        rejectTypeText="Please provide valid image (.jpg or .png)"
        onDrop={onDrop}
      >
        Drop new avatar picture here
      </Dropzone>
    </View>
  );
};
