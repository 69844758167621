import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useRouter } from "../../hooks/useRouter";
import { TriangleArrowIcon } from "../../theme/svg/TriangleArrowIcon";
import styles from "./Tabs.module.scss";

export interface TabsProps {
  secondary?: boolean;
}

export interface TabProps {
  to: string;
  label: string;
  icon?: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
  hasArrow?: boolean;
  active?: boolean;
  className?: string;
}

export const Tabs: React.FC<TabsProps> = ({ secondary, children }) => (
  <div
    className={classNames(styles.tabs, {
      [styles["tabs--primary"]]: !secondary,
      [styles["tabs--secondary"]]: secondary,
    })}
  >
    {children}
  </div>
);

export const Tab: React.FC<TabProps> = ({
  to,
  label,
  icon,
  secondary,
  primary = !secondary,
  hasArrow,
  active,
  className,
  children,
}) => {
  const { location } = useRouter();

  const isActive = location.pathname.includes(to);

  return (
    <Link
      data-testid="96faa23996"
      to={to}
      className={classNames(
        styles.link,
        {
          [styles["link--primary"]]: primary,
          [styles["link--primary-active"]]: isActive && primary,
          [styles["link--secondary"]]: secondary,
        },
        className,
      )}
    >
      <div
        className={classNames({
          [styles.tab]: primary,
          [styles["tab--secondary"]]: secondary,
          [styles["tab--secondary-active"]]:
            (isActive && secondary) || (active && secondary),
        })}
      >
        {primary && label}
        {secondary && (
          <>
            {icon}
            {label}
          </>
        )}
        {secondary && isActive && hasArrow && (
          <TriangleArrowIcon className={styles["triangle-arrow"]} />
        )}
      </div>
      {children && children}
    </Link>
  );
};
