import React, { useEffect, useState } from "react";
import { Modal } from "../../components/Modal/Modal";
import {
  ViewerOrganizations,
  useUserUpdateOrganization,
  useViewer,
  OrderByCodeCountries,
} from "../../schema";
import { Button } from "../../components/Button/Button";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import { Form } from "../../components/Form/Form";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { currencies } from "../../constants";

export interface UpdateOrganizationProps {
  activeOrganization: ViewerOrganizations;
  countries: OrderByCodeCountries[];
  onModalClose(): void;
}

export const UpdateOrganization: React.FC<UpdateOrganizationProps> = ({
  activeOrganization,
  countries,
  onModalClose,
}) => {
  // load active organization
  const { loading: isFetching, error: fetchError } = useViewer({
    fetchPolicy: "network-only",
    variables: {
      organizationId: activeOrganization.id,
    },
  });

  // setup form
  const {
    useInput,
    useSelect,
    loading: isSaving,
    error: saveError,
    submit: updateOrganization,
  } = useForm({
    mutation: useUserUpdateOrganization,
    onSuccess: () => onModalClose(),
  });

  // setup state
  const [isInitialized, setIsInitialized] = useState(false);

  // configure inputs
  const addressInput = useInput({ name: "address" });
  const cityInput = useInput({ name: "city" });
  const areaCodeInput = useInput({ name: "areaCode" });
  const countrySelect = useSelect({
    name: "countryCode",
    options: [
      {
        label: activeOrganization.country.name,
        value: activeOrganization.country.name,
      },
    ].concat(
      countries
        ? countries.map((country) => ({
            label: country.name,
            value: country.code,
          }))
        : [],
    ),
    defaultValue: "",
  });
  const baseCurrencySelect = useSelect({
    name: "baseCurrency",
    options: currencies.map((currency) => ({
      value: currency.symbol,
      label: currency.name,
    })),
  });
  const vatIdentificationInput = useInput({
    name: "vatIdentification",
    optional: true,
  });
  const businessRegistryInput = useInput({
    name: "businessRegistry",
    optional: true,
  });
  const leiCodeInput = useInput({ name: "leiCode", optional: true });
  const dunsCodeInput = useInput({ name: "dunsCode", optional: true });
  const embeddedReportInput = useInput({
    name: "embeddedReport",
    optional: true,
  });
  const poStatusWebhookUrlInput = useInput({
    name: "poStatusWebhookUrl",
    optional: true,
  });

  useEffect(() => {
    if (!activeOrganization) {
      return;
    }

    addressInput.setValue(activeOrganization.address);
    cityInput.setValue(activeOrganization.city);
    areaCodeInput.setValue(activeOrganization.areaCode);
    countrySelect.setValue(activeOrganization.country.code);
    baseCurrencySelect.setValue(activeOrganization.baseCurrency);

    if (activeOrganization.vatIdentification) {
      vatIdentificationInput.setValue(activeOrganization.vatIdentification);
    }

    if (activeOrganization.businessRegistry) {
      businessRegistryInput.setValue(activeOrganization.businessRegistry);
    }

    if (activeOrganization.leiCode) {
      leiCodeInput.setValue(activeOrganization.leiCode);
    }

    if (activeOrganization.dunsCode) {
      dunsCodeInput.setValue(activeOrganization.dunsCode);
    }

    if (activeOrganization.embeddedReport) {
      embeddedReportInput.setValue(activeOrganization.embeddedReport);
    }

    if (activeOrganization.poStatusWebhookUrl) {
      poStatusWebhookUrlInput.setValue(activeOrganization.poStatusWebhookUrl);
    }

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization]);

  // handle loading error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading organization failed"
        error={fetchError}
        modal={{ title: "Update organization", onModalClose }}
      />
    );
  }

  // handle saving error
  if (saveError) {
    return (
      <ErrorView
        title="Updating organization failed"
        error={saveError}
        modal={{ title: activeOrganization.companyName, onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !activeOrganization || !isInitialized) {
    return <LoadingView overlay />;
  }

  // submits the form
  const submit = () => {
    updateOrganization({
      organizationId: activeOrganization.id,
      companyName: activeOrganization.companyName,
    });
  };

  return (
    <Modal
      title={activeOrganization.companyName}
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button
            data-testid="2b5f4623f4"
            secondary
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            data-testid="0e83d4e80f"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form onSubmit={submit}>
        <Field {...addressInput} short autofocus label="Address" />
        <Field {...cityInput} short label="City" />
        <Field {...areaCodeInput} short label="ZIP / Postal code" />
        <Select {...countrySelect} short label="Country" />
        <Select {...baseCurrencySelect} short label="Base currency" />
        <Field
          {...vatIdentificationInput}
          short
          label="VAT identification number"
        />
        <Field
          {...businessRegistryInput}
          short
          label="Local business registry number"
        />
        <Field {...leiCodeInput} short label="LEI code" />
        <Field {...dunsCodeInput} short label="D-U-N-S code" />
        <Field
          {...embeddedReportInput}
          short
          autofocus
          label="Embedded Report"
        />
        <Field
          {...poStatusWebhookUrlInput}
          short
          autofocus
          label="Purchase Order Status Webhook Url"
        />
      </Form>
    </Modal>
  );
};
