import React from "react";
import classNames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import format from "date-fns/format";
import parse from "date-fns/parse";
import addBusinessDays from "date-fns/addBusinessDays";
import * as R from "ramda";
import { SourcingEventSection } from "../SourcingEventSection/SourcingEventSection";
import { Accordion } from "../Accordion/Accordion";
import { InputList } from "../InputList/InputList";
import {
  RfqItemQuestionnaire,
  UniqueSelectedSupplier,
} from "../RfqItemQuestionnaire/RfqItemQuestionnaire";
import {
  useCreateOrUpdateSourcingEvent,
  ViewerOrganizations,
  RfxByCodeRfxByCode,
  PurchaseRequestItemTypeEnum,
  Attachment,
} from "../../schema";
import { isDefined } from "../../services/isDefined";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import {
  StatusNotice,
  EventTypeEnum,
  StatusNoticeProps,
} from "../../components/StatusNotice/StatusNotice";
import { scrollToRef } from "../../services/scrollToRef";
import { Unbox } from "../../lib/helpers/typescript-helpers";
import { RfqEventFormContext } from "../../views/RfxView/RfxView";
import { useReadOnlyContext } from "../../contexts/readonly-context";
import styles from "./RfqEventForm.module.scss";

export interface RfqEventFormProps {
  className?: string;
  organization: ViewerOrganizations;
  rfx: RfxByCodeRfxByCode;
  onSuppliersChange?: (
    uniqueSelectedSuppliers: UniqueSelectedSupplier[],
  ) => void;
}

export interface EsourcingField {
  questions: {
    question: string;
  }[];
  quantities: {
    quantity: string;
  }[];
}

export interface SourcingEventTemplate {
  generalQuestions: {
    question: string;
    answer: string | null;
  }[];
  items: {
    id: string;
    itemData: {
      type: PurchaseRequestItemTypeEnum;
      name: string;
      unit: string | null;
      expectedDelivery: string | null;
      url: string | null;
      ownCode: string | null;
      supplierCode: { value: string | null; isEditable: boolean };
      itemAdditionalInfo: string | null;
      suggestedSupplier: string | null;
      attachments: { id: string; title: string | null; url: string }[];
    };
    customFields: {
      label: string | null;
      value: string | null;
      isEditable: boolean;
    }[];
    esourcingFields: EsourcingField | null;
    itemQuestions: { question: string; answer: string | null }[];
    excludedItemSuppliers: {
      supplierId: string;
      supplierName: string;
      isSelected: boolean;
    }[];
    priceInfo: {
      quantity: number | string | null;
      price: number | string | null;
      isRowWinner?: boolean;
    }[];
    winningSupplierId?: string;
    hasMovedToPo?: boolean;
  }[];
}

export interface SourcingEventDatabaseTemplate
  extends Omit<SourcingEventTemplate, "items"> {
  items: {
    id: string;
    itemData: {
      type: PurchaseRequestItemTypeEnum;
      name: string;
      unit: string | null;
      expectedDelivery: string | null;
      url: string | null;
      ownCode: string | null;
      supplierCode: { value: string | null; isEditable: boolean };
      itemAdditionalInfo: string | null;
      attachments: { id: string; title: string | null; url: string }[];
    };
    customFields: {
      label: string | null;
      value: string | null;
      isEditable: boolean;
    }[];
    itemQuestions: { question: string; answer: string | null }[];
    excludedItemSuppliers: string[];
    priceInfo: {
      quantity: number | string | null;
      price: number | string | null;
    }[];
  }[];
}

export interface RfqEventFormInputs {
  eventName?: string;
  responseDeadline?: Date | string | null;
  additionalInfo?: string;
  selectedAttachments: (Pick<Attachment, "id" | "filename" | "url" | "size"> & {
    selected: boolean;
  })[];
  generalQuestions: SourcingEventTemplate["generalQuestions"];
  items: SourcingEventTemplate["items"];
}

export interface RfqEventFormResult {
  eventName?: string;
  responseDeadline?: Date | string | null;
  additionalInfo?: string;
  selectedAttachments: (Pick<Attachment, "id" | "filename" | "url" | "size"> & {
    selected: boolean;
  })[];
  generalQuestions: SourcingEventDatabaseTemplate["generalQuestions"];
  items: SourcingEventDatabaseTemplate["items"];
}

export const RfqEventForm: React.FC<RfqEventFormProps> = (props) => {
  const { organization, className, rfx, onSuppliersChange } = props;

  // attachments to be uploaded
  const [attachedFiles, setAttachedFiles] = React.useState<File[]>([]);

  // use parent's controls for setting the form dirty
  const rfqEventFormContext = React.useContext(RfqEventFormContext);

  // use read only context
  const isReadOnly = useReadOnlyContext();

  // state change status notice state
  const [statusNotice, setStatusNotice] =
    React.useState<StatusNoticeProps | null>(null);
  const topRef = React.useRef<HTMLDivElement>(null);
  const bottomRef = React.useRef<HTMLDivElement>(null);

  const items = rfx.items;

  const sourcingEvent = rfx.sourcingEvent;
  const sourcingEventQuestionnaire = sourcingEvent?.questionnaire as
    | SourcingEventTemplate
    | undefined;

  // setup create/update sourcing event mutation
  const [
    createOrUpdateSourcingEvent,
    {
      loading: isCreateOrUpdateSourcingEventLoading,
      error: isCreateOrUpdateSourcingEventError,
    },
  ] = useCreateOrUpdateSourcingEvent({
    refetchQueries: ["PaginatedRfx", "RfxByCode"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setAttachedFiles([]);
    },
    onError: () => {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Sorry! There was an error while saving the sourcing event",
        eventDescription: "Please try again",
      });

      // scroll to status message
      scrollToRef(topRef);
    },
  });

  // handle saving error
  React.useEffect(() => {
    if (isCreateOrUpdateSourcingEventError) {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Something unexpected happened",
        eventDescription:
          "We couldn't create or update the sourcing event. Please try again.",
      });
    }
  }, [isCreateOrUpdateSourcingEventError]);

  const getItemSuppliers = (itemId: string) => {
    const sourcingEventItems = sourcingEventQuestionnaire?.items || [];
    const filteredItems = sourcingEventItems.find((item) => item.id === itemId);

    const excludedItemSuppliers = rfx.suppliers.map((supplier) => {
      const filteredSuppliers = filteredItems?.excludedItemSuppliers;
      const isSelected =
        filteredSuppliers !== undefined
          ? !(filteredSuppliers as unknown as string[]).includes(supplier.id)
          : true;

      return {
        supplierId: supplier.id,
        supplierName: supplier.name,
        isSelected,
      };
    });

    return excludedItemSuppliers;
  };

  const getItemEsourcingQuantities = (
    quantities: EsourcingField["quantities"],
  ) => {
    if (!quantities) {
      return [];
    }

    const esourcingQuantities = quantities
      .filter(
        (esourcingQuantity) => !isNaN(parseFloat(esourcingQuantity.quantity)),
      )
      .map((esourcingQuantity) => ({
        quantity: parseFloat(esourcingQuantity.quantity),
        price: null,
      }));

    return esourcingQuantities;
  };

  const combinedItemInfo = items?.reduce<any>((acc, item) => {
    const priceInfoDefaults = item.esourcingFields?.quantities
      ? [
          { quantity: item.quantity ?? 1, price: null, isDefault: true },
          ...getItemEsourcingQuantities(item.esourcingFields?.quantities),
        ]
      : [{ quantity: item.quantity ?? 1, price: null, isDefault: true }];

    return acc.concat({
      id: item.id,
      itemData: {
        type: item.type as PurchaseRequestItemTypeEnum,
        name: item.name,
        unit: item.unit,
        expectedDelivery: (item.expectedDeliveryDate as string) ?? null,
        url: item.productUrl,
        ownCode: item.code,
        supplierCode: {
          value: item.supplierCode,
          isEditable: R.not(R.isEmpty(item.supplierCode)),
        },
        itemAdditionalInfo: item.additionalInfo,
        suggestedSupplier: item.suggestedSupplier,
        attachments:
          item.attachments?.map((attachment) => ({
            id: attachment.id,
            title: attachment.filename,
            url: attachment.url ?? "",
          })) ?? [],
      },
      itemQuestions: R.compose<any, any, any, any>(
        R.propOr(item.esourcingFields?.questions ?? [], "itemQuestions"),
        R.defaultTo({}),
        R.find(R.propEq("id", item.id)),
      )(sourcingEventQuestionnaire?.items ?? []) as Unbox<
        SourcingEventTemplate["items"]
      >["itemQuestions"],
      customFields: R.compose<any, any, any, any>(
        R.map(
          R.compose<any, any, any>(
            R.assoc("isEditable", false),
            R.zipObj(["label", "value"]),
          ),
        ),
        R.toPairs,
        R.propOr({}, "customFields"),
      )(item) as Unbox<SourcingEventTemplate["items"]>["customFields"],
      excludedItemSuppliers: getItemSuppliers(item.id),
      priceInfo: (R.compose<any, any, any, any>(
        R.propOr([...priceInfoDefaults], "priceInfo"),
        R.defaultTo({}),
        R.find(R.propEq("id", item.id)),
      )(sourcingEventQuestionnaire?.items ?? []) ?? []) as Unbox<
        SourcingEventTemplate["items"]
      >["priceInfo"],
    });
  }, []);

  const combinedDefaults: RfqEventFormInputs = {
    eventName: sourcingEvent?.name || rfx.name,
    additionalInfo: sourcingEvent?.additionalInfo ?? "",
    responseDeadline: sourcingEvent?.responseDeadline
      ? format(new Date(sourcingEvent.responseDeadline), "dd/MM/yyyy HH:mm")
      : format(
          addBusinessDays(new Date(), 2).setMinutes(0),
          "dd/MM/yyyy HH:mm",
        ),
    generalQuestions: sourcingEventQuestionnaire?.generalQuestions ?? [],
    selectedAttachments:
      rfx.attachments?.map((rfxAttachment) =>
        R.assoc(
          "selected",
          R.includes(
            rfxAttachment.id,
            sourcingEvent?.attachments?.map((a) => a.id) ?? [],
          ),
          rfxAttachment,
        ),
      ) ?? [],
    items: combinedItemInfo,
  };

  const formMethods = useForm<RfqEventFormInputs>({
    defaultValues: combinedDefaults,
    shouldUnregister: false,
  });

  const { handleSubmit, errors, formState } = formMethods;

  const { isDirty, isSubmitted, isSubmitSuccessful } = formState;

  React.useEffect(() => {
    if (!rfqEventFormContext) {
      return;
    }

    if (isDirty && !isSubmitted) {
      rfqEventFormContext.setRfqEventFormDirty(true);
    } else if (!isDirty || (isSubmitted && isSubmitSuccessful)) {
      rfqEventFormContext.setRfqEventFormDirty(false);
    }
  }, [formState]);

  const onSubmit = async (
    formData: RfqEventFormInputs & {
      attachments: { [attachmentId: string]: boolean };
    },
  ) => {
    const combinedDataWithFilledFormData = combinedFormData({
      filledFormData: formData,
      combinedDefaults,
    });

    await createOrUpdateSourcingEvent({
      variables: {
        sourcingEventId: sourcingEvent?.id ?? null,
        organizationId: organization.id,
        rfxId: rfx.id,
        questionnaire: JSON.stringify({
          generalQuestions: combinedDataWithFilledFormData.generalQuestions,
          items: combinedDataWithFilledFormData.items,
        }),
        name: combinedDataWithFilledFormData.eventName ?? "",
        responseDeadline:
          typeof combinedDataWithFilledFormData.responseDeadline === "string"
            ? parse(
                combinedDataWithFilledFormData.responseDeadline,
                "dd/MM/yyyy HH:mm",
                new Date(),
              )
            : combinedDataWithFilledFormData.responseDeadline,
        additionalInfo: isDefined(combinedDataWithFilledFormData.additionalInfo)
          ? combinedDataWithFilledFormData.additionalInfo
          : null,
        selectedAttachmentIds:
          R.compose<{ [k: string]: boolean }, any, any>(
            R.prop("true"),
            R.invert,
          )(formData.attachments) ?? [],
        uploadedAttachments: attachedFiles,
      },
    });

    window.location.reload();
  };

  const handleOnSuppliersChange = (
    uniqueSuppliers: UniqueSelectedSupplier[],
  ) => {
    if (typeof onSuppliersChange === "function") {
      onSuppliersChange(uniqueSuppliers);
    }
  };

  // now we can pass this to child components too
  const triggerSubmit = handleSubmit(onSubmit);

  // trigger submit and reload after attaching files
  React.useEffect(() => {
    if (attachedFiles.length > 0) {
      (async () => {
        await triggerSubmit();

        window.location.reload();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(attachedFiles)]);

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Please fill in the fields that are required",
      });

      // scroll to status message
      scrollToRef(topRef);
    }
  }, [errors]);

  // for `isReadOnly` skip the Accordions
  const GroupWrapper = isReadOnly ? EmptyWrapperElement : Accordion;

  return (
    <FormProvider {...formMethods}>
      {/* display status notices */}
      <div ref={topRef}></div>
      {statusNotice && (
        <StatusNotice
          level={statusNotice.level}
          eventTitle={statusNotice.eventTitle}
          eventDescription={statusNotice.eventDescription}
          onCloseRequested={() => {
            setStatusNotice(null);
          }}
        />
      )}

      {/* display form */}
      <form
        autoComplete="off"
        id="rfq-event-form"
        onSubmit={triggerSubmit}
        className={styles["form"]}
      >
        <div className={classNames(styles["wrap"], className)}>
          <GroupWrapper isInitiallyOpen={true} title={"Sourcing event"}>
            <SourcingEventSection
              attachedFiles={attachedFiles}
              setAttachedFiles={setAttachedFiles}
            />
          </GroupWrapper>

          <GroupWrapper isInitiallyOpen={true} title={"Questions"}>
            <InputList className={styles["input-list"]} />
          </GroupWrapper>

          <GroupWrapper isInitiallyOpen={true} title={"Items"}>
            <RfqItemQuestionnaire
              organization={organization}
              rfx={rfx}
              displayHeader
              triggerSubmit={triggerSubmit}
              onSuppliersChange={handleOnSuppliersChange}
            />
          </GroupWrapper>
        </div>
        <div ref={bottomRef}></div>
      </form>

      {/* handle loading */}
      {isCreateOrUpdateSourcingEventLoading && <LoadingView overlay />}
    </FormProvider>
  );
};

/**
 * Combine initial default values with filled in form data
 */
function combinedFormData({
  filledFormData,
  combinedDefaults,
}: {
  filledFormData: RfqEventFormInputs;
  combinedDefaults: RfqEventFormInputs;
}): RfqEventFormResult {
  const formItems: SourcingEventDatabaseTemplate["items"] = [];

  combinedDefaults.items.map((defaultItem) => {
    const itemIndex = filledFormData.items.findIndex(
      (item) => item.id === defaultItem.id,
    );

    if (itemIndex !== -1) {
      formItems.push({
        id: defaultItem.id,
        itemData: {
          ...defaultItem.itemData,
          supplierCode: {
            value: null,
            isEditable: true,
          },
        },
        customFields: defaultItem.customFields ?? [],
        itemQuestions:
          filledFormData.items[itemIndex].itemQuestions?.map((iq) => ({
            question: iq.question,
            answer: null,
          })) ?? [],
        excludedItemSuppliers: filledFormData.items[itemIndex]
          .excludedItemSuppliers
          ? filledFormData.items[itemIndex].excludedItemSuppliers
              .filter((supplier) => !supplier.isSelected)
              .map((supplier) => supplier.supplierId)
          : [],
        priceInfo:
          filledFormData.items[itemIndex].priceInfo.map((pi) => ({
            quantity:
              typeof pi.quantity === "number"
                ? pi.quantity.toString()
                : pi.quantity,
            price: null,
          })) ?? [],
      });
    }
  });

  return {
    additionalInfo: filledFormData.additionalInfo,
    eventName: filledFormData.eventName,
    responseDeadline: filledFormData.responseDeadline ?? null,
    selectedAttachments: combinedDefaults.selectedAttachments,
    generalQuestions:
      filledFormData.generalQuestions?.map((q) => ({
        question: q.question,
        answer: null,
      })) ?? [],
    items: formItems,
  };
}

function EmptyWrapperElement(props: { children: JSX.Element }) {
  return <>{props.children}</>;
}
