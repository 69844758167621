import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Button } from "../../components/Button/Button";
import {
  ViewerOrganizations,
  useCreateSupplier,
  ViewerViewer,
  UserRoleEnum,
} from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { notEmpty } from "../../validators/notEmpty";
import { isEmail } from "../../validators/isEmail";
import { useRouter } from "../../hooks/useRouter";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";

export interface NewSupplierModalProps {
  organization: ViewerOrganizations;
  viewer: ViewerViewer;
}

export const CreateSupplierModal: React.FC<NewSupplierModalProps> = ({
  organization,
  viewer,
}) => {
  const { history } = useRouter();

  // setup form
  const { useInput, loading, error, submit: createSupplier } = useForm({
    mutation: useCreateSupplier,
    options: {
      refetchQueries: ["SrmView"],
      awaitRefetchQueries: true,
    },
    onSuccess: (result) => {
      history.replace(
        `/suppliers-and-contracts/${organization.urlName}/${result.createSupplier.id}`,
      );
    },
  });

  // handle submit
  const createSupplierWithOverrides = () => {
    createSupplier({ organizationId: organization.id });
  };

  // handle cancel
  const onModalCancel = () => {
    history.replace(`/suppliers-and-contracts/${organization.urlName}`);
  };

  // configure inputs
  const nameInput = useInput({ name: "name", validators: [notEmpty] });
  const emailInput = useInput({ name: "email", validators: [isEmail] });

  const hasKeyUserRole = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER].includes(role),
  );

  return (
    <Modal
      title="Add new supplier"
      onCloseRequested={onModalCancel}
      footer={
        <>
          {hasKeyUserRole && (
            <Button
              secondary
              push
              icon={<ExcelIcon />}
              onClick={() => {
                history.push(
                  `/suppliers-and-contracts/${organization.urlName}/import-suppliers`,
                );
              }}
            >
              Upload excel
            </Button>
          )}
          <Button
            data-testid="d4c247c474"
            secondary
            onClick={() => onModalCancel()}
          >
            Cancel
          </Button>
          <Button
            data-testid="90bfeb2ca0"
            loading={loading}
            onClick={() => createSupplierWithOverrides()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={createSupplierWithOverrides}>
        <Field {...nameInput} short autofocus label="Name" />
        <Field {...emailInput} short label="Email" />
        {/* TODO: implement contact person and country select */}
        {/* <Field short label="Contact person" /> */}
        {/* <Select label="Country" options={[{ value: "EST", label: "EST" }, { value: "FIN", label: "FIN" }]} /> */}
      </Form>
    </Modal>
  );
};
