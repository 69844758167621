import { ApolloError } from "apollo-client";
import React from "react";
import { ContactPerson } from "../../schema";
import { PinIcon } from "../../theme/svg/PinIcon";
import { Button } from "../Button/Button";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { Form } from "../Form/Form";
import { Loading } from "../Loading/Loading";
import styles from "./SelectDefaultContact.module.scss";

export interface SelectDefaultContactProps {
  contactPersons: Pick<ContactPerson, "id" | "email">[];
  defaultContactPersonId: string;
  error?: ApolloError;
  loading?: boolean;
  setDefaultContact: (id: string) => Promise<void>;
}

export const SelectDefaultContact: React.FC<SelectDefaultContactProps> = ({
  contactPersons,
  defaultContactPersonId,
  error,
  loading,
  setDefaultContact,
}) => {
  return (
    <Form error={error}>
      <FieldLabel label="E-mail" required />
      <div className={styles.wrap}>
        {contactPersons.map((contact) => (
          <div key={contact.id} className={styles.item}>
            <span>
              {contact.email}
              {contact.id === defaultContactPersonId && <PinIcon />}
            </span>
            <Button
              onClick={() => setDefaultContact(contact.id)}
              disabled={contact.id === defaultContactPersonId}
              text
            >
              {contact.id === defaultContactPersonId
                ? "Default"
                : "Make default"}
            </Button>
          </div>
        ))}
        {loading && (
          <div className={styles.isLoading}>
            <Loading />
          </div>
        )}
      </div>
    </Form>
  );
};
