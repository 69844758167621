import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { Button } from "../Button/Button";
import { SupplierLookupResultEnum, useSupplierLookup } from "../../schema";
import { FieldError } from "../FieldError/FieldError";
import styles from "./SupplierLookupForm.module.scss";

export interface SupplierLookupFormProps {
  linkToken: string;
  className?: string;
  organizationId: string;
  handleSupplierNotFound: (email: string) => void;
  handleSupplierFound: (email: string) => void;
  handleManySuppliersFound: (email: string) => void;
}

export interface SupplierLookupFormInputs {
  email: string;
}

export const SupplierLookupForm: React.FC<SupplierLookupFormProps> = (
  props,
) => {
  const {
    className,
    linkToken,
    organizationId,
    handleSupplierNotFound,
    handleSupplierFound,
    handleManySuppliersFound,
  } = props;

  const { register, handleSubmit, errors } = useForm<
    SupplierLookupFormInputs
  >();

  // setup supplierLookup (no refetch needed)
  const [
    supplierLookup,
    { loading: isSupplierLookupLoading },
  ] = useSupplierLookup();

  const onSubmit = async (formData: SupplierLookupFormInputs) => {
    // send data
    const { data: lookupResponse, errors: lookupErrors } = await supplierLookup(
      {
        variables: {
          email: formData.email,
          organizationId,
          sourcingEventLinkToken: linkToken,
        },
      },
    );

    const supplierLookupResult = lookupResponse?.supplierLookup;

    // no response form server or errors
    if (!supplierLookupResult || lookupErrors) {
      // TODO: show notification about errors
      return;
    }

    // exactly one supplier was found by domain
    if (supplierLookupResult.result === SupplierLookupResultEnum.FOUND_ONE) {
      handleSupplierFound(supplierLookupResult.email);
    }

    // many related suppliers were found by domain
    if (supplierLookupResult.result === SupplierLookupResultEnum.FOUND_MANY) {
      handleManySuppliersFound(supplierLookupResult.email);
    }
    // supplier was not found
    if (supplierLookupResult.result === SupplierLookupResultEnum.NOT_FOUND) {
      handleSupplierNotFound(supplierLookupResult.email);
    }
  };

  return (
    <div className={classNames(styles["wrap"], className)}>
      {/* Email lookup form */}
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={styles["email-lookup-form"]}
      >
        <label
          htmlFor="email"
          className={classNames(
            styles["email-lookup-label"],
            styles["email-lookup-label--required"],
          )}
        >
          Email address
        </label>
        <input
          type="email"
          name="email"
          ref={register({ required: "Email is required" })}
          className={styles["email-lookup-field"]}
          placeholder="Enter your email address"
        />
        {errors?.email !== undefined && (
          <FieldError key={"test"} error={errors?.email.message} />
        )}
        <div className={styles["email-lookup-actions"]}>
          <Button
            type="submit"
            className={styles["submit-button"]}
            loading={isSupplierLookupLoading}
          >
            Request Access
          </Button>
        </div>
      </form>
    </div>
  );
};
