import React from "react";
import classNames from "classnames";
import styles from "./DummyInputPlaceholder.module.scss";

export interface DummyInputPlaceholderProps {
  className?: string;
}

export const DummyInputPlaceholder: React.FC<DummyInputPlaceholderProps> = (
  props,
) => {
  const { className } = props;

  return <div className={classNames(styles["wrap"], className)}></div>;
};
