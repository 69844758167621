import React, { useCallback, useState } from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Field } from "../../components/Field/Field";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { ViewerOrganizations, useCreateSupplierAttachment } from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { Form } from "../../components/Form/Form";
import { Datepicker } from "../../components/Datepicker/Datepicker";
import { notEmpty } from "../../validators/notEmpty";

export interface AddAttachmentProps {
  activeOrganization: ViewerOrganizations;
  supplierId: string;
  onModalClose(): void;
}

export interface DropzoneFile {
  name: string;
  path: string;
  size: number;
  type: string;
  lastModified: number;
}

export const AddAttachment: React.FC<AddAttachmentProps> = ({
  activeOrganization,
  supplierId,
  onModalClose,
}) => {
  // setup state
  const [file, setFile] = useState<File>();

  const onDrop = useCallback<OnDropCallbackFn>((acceptedFiles) => {
    // return if not exactly one file was provided
    if (acceptedFiles.length !== 1) {
      return;
    }

    // set selected file
    setFile(acceptedFiles[0]);
  }, []);

  // setup form
  const {
    loading: isSaving,
    error: saveError,
    useInput,
    useDatepicker,
    submit: uploadAttachment,
  } = useForm({
    mutation: useCreateSupplierAttachment,
    options: {
      refetchQueries: ["SupplierAttachment"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure input
  const nameInput = useInput({ name: "notes", validators: [notEmpty] });
  const documentDateDatepicker = useDatepicker({
    name: "documentDate",
    optional: true,
  });
  const validityDateDatepicker = useDatepicker({
    name: "validityDate",
    optional: true,
  });

  const submit = () => {
    uploadAttachment({
      attachments: [file],
      organizationId: activeOrganization.id,
      supplierId,
    });

    // clear selected file
    setFile(undefined);
  };

  return (
    <Modal
      title="Add a document or contract"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="fe5127146b" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="08f0e17883"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={saveError} onSubmit={submit}>
        <Field {...nameInput} short label="Name" />
        <Datepicker {...documentDateDatepicker} label="Document date" />
        <Datepicker {...validityDateDatepicker} label="Validity date" />
        <Dropzone
          required
          multiple
          loading={isSaving}
          error={saveError ? new Error(saveError) : undefined}
          label="Upload attachment"
          onDrop={onDrop}
        >
          {file
            ? `Attachment ${file.name} successfully added!`
            : "Search or drop file here"}
        </Dropzone>
      </Form>
    </Modal>
  );
};
