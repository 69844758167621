import React, { createRef } from "react";
import classNames from "classnames";
import { Link, Switch, Route } from "react-router-dom";
import { Activity } from "../../components/Activity/Activity";
import { Avatar } from "../../components/Avatar/Avatar";
import { Button } from "../../components/Button/Button";
import { Container } from "../../components/Container/Container";
import feedUpdateStyles from "../../components/FeedUpdate/FeedUpdate.module.scss";
import { NotAvailable } from "../../components/NotAvailable/NotAvailable";
import { Separator } from "../../components/Separator/Separator";
import { BasicTable, Cell } from "../../components/BasicTable/BasicTable";
import { ValuePair } from "../../components/ValuePair/ValuePair";
import { View } from "../../components/View/View";
import { useRouter } from "../../hooks/useRouter";
import {
  ViewerOrganizations,
  useSupplierById,
  ActivityTypeEnum,
  useSupplierAttachment,
  SupplierByIdCategories,
} from "../../schema";
import { formatDate } from "../../services/formatDate";
import { getMessageByActivity } from "../../services/getMessageByActivity";
import { StringToRefObjectMap } from "../../services/scrollIntoViewByRef";
import { ContactPersonIcon } from "../../theme/svg/ContactPersonIcon";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { PinIcon } from "../../theme/svg/PinIcon";
import { SupplierIcon } from "../../theme/svg/SupplierIcon";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { Chip } from "../../components/Chip/Chip";
import { AddAttachment } from "./AddAttachment";
import { CreateContactPersonModal } from "./CreateContactPersonModal";
import { EditContactPersonModal } from "./EditContactPersonModal";
import { EditSupplierModal } from "./EditSupplierModal";
import styles from "./SuppliersAndContractsDetailView.module.scss";

export interface SuppliersAndContractsDetailViewProps {
  organization: ViewerOrganizations;
}

interface RouteParams {
  supplierId: string;
}

export const SuppliersAndContractsDetailView: React.FC<SuppliersAndContractsDetailViewProps> = ({
  organization,
}) => {
  const { history, match } = useRouter<RouteParams>();

  const supplierId = match.params.supplierId;

  // load supplier by id
  const { data, loading, error } = useSupplierById({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      supplierId,
    },
  });

  // const load attachment
  const {
    data: attachmentData,
    loading: attachmentLoading,
    error: attachmentError,
  } = useSupplierAttachment({
    fetchPolicy: "network-only",
    variables: {
      supplierId: supplierId,
      organizationId: organization.id,
    },
  });

  if (error) {
    return (
      <ErrorView title="Requested supplier could not be found" error={error} />
    );
  }

  if (attachmentError) {
    return (
      <ErrorView
        title="Requested attachment could not be found"
        error={attachmentError}
      />
    );
  }

  if (
    loading ||
    !data ||
    !data.viewer ||
    attachmentLoading ||
    !attachmentData ||
    !attachmentData.viewer
  ) {
    return <LoadingView />;
  }

  const supplier = data.viewer.supplierById;
  const organizationCategories = data.viewer.organization.categories;
  const supplierCategories = data.viewer.supplierById.categories;
  const countries = data.viewer.countries;
  const attachments = attachmentData.viewer.supplierById.attachments;

  // find supplier country info
  const country = countries.find(
    (country) => country.code === supplier.countryCode,
  );

  const activityRefs = supplier.activities.reduce((acc, value) => {
    acc.set(value.id, createRef());

    return acc;
  }, new Map() as StringToRefObjectMap<HTMLLIElement>);

  return (
    <>
      <View secondary>
        <h1>{supplier.name}</h1>

        <div className={styles["section-wrap"]}>
          <div className={styles.contacts}>
            <Container
              small
              icon={<SupplierIcon />}
              title="Supplier details"
              addon={
                <Link
                  data-testid="6247ef98a8"
                  to={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/edit-company`}
                  className={styles.edit}
                >
                  Edit
                </Link>
              }
              className={classNames(
                styles.container,
                styles["container--stretched"],
              )}
            >
              <ValuePair
                name="Name"
                value={supplier.name || <NotAvailable />}
              />
              <ValuePair
                name="Web"
                value={supplier.webAddress || <NotAvailable />}
              />
              <ValuePair
                name="Address"
                value={supplier.address || <NotAvailable />}
              />
              <ValuePair
                name="City"
                value={supplier.city || <NotAvailable />}
              />
              <ValuePair
                name="ZIP / postal"
                value={supplier.areaCode || <NotAvailable />}
              />
              <ValuePair
                name="Country"
                value={country ? country.name : <NotAvailable />}
              />
              {supplier.paymentTerms && (
                <ValuePair name="Payment Terms" value={supplier.paymentTerms} />
              )}
              {supplier.currency && (
                <ValuePair name="Currency" value={supplier.currency} />
              )}
              {supplier.vatIdentification && (
                <ValuePair
                  name="VAT identification"
                  value={supplier.vatIdentification}
                />
              )}
              {supplier.businessRegistry && (
                <ValuePair
                  name="Business registry"
                  value={supplier.businessRegistry}
                />
              )}
              {supplier.leiCode && (
                <ValuePair name="LEI code" value={supplier.leiCode} />
              )}
              {supplier.dunsCode && (
                <ValuePair name="D-U-N-S code" value={supplier.dunsCode} />
              )}
              {supplier.erpSupplierCode && (
                <ValuePair
                  name="ERP Supplier code"
                  value={supplier.erpSupplierCode}
                />
              )}
              {supplier.customFields && supplier.customFields.customField1 && (
                <ValuePair
                  name="Custom field 1"
                  value={supplier.customFields.customField1}
                />
              )}
              {supplier.customFields && supplier.customFields.customField2 && (
                <ValuePair
                  name="Custom field 2"
                  value={supplier.customFields.customField2}
                />
              )}
              {supplier.customFields && supplier.customFields.customField3 && (
                <ValuePair
                  name="Custom field 3"
                  value={supplier.customFields.customField3}
                />
              )}
            </Container>
            <div>
              {supplier.contactPersons.map((contactPerson) => (
                <Container
                  key={contactPerson.id}
                  small
                  icon={<ContactPersonIcon />}
                  title={
                    contactPerson.id === supplier.defaultContactPerson.id ? (
                      <span className={styles["default-contact"]}>
                        Contact <PinIcon />
                      </span>
                    ) : (
                      "Contact"
                    )
                  }
                  className={styles.container}
                  addon={
                    <Link
                      data-testid="495ac50845"
                      to={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/edit-contact-person/${contactPerson.id}`}
                      className={styles.edit}
                    >
                      Edit
                    </Link>
                  }
                >
                  {(contactPerson.firstName || contactPerson.lastName) && (
                    <ValuePair
                      name="Name"
                      value={`${
                        contactPerson.firstName ? contactPerson.firstName : ""
                      } ${
                        contactPerson.lastName ? contactPerson.lastName : ""
                      }`}
                    />
                  )}
                  {contactPerson.position && (
                    <ValuePair name="Position" value={contactPerson.position} />
                  )}
                  {contactPerson.phoneNumber && (
                    <ValuePair name="Phone" value={contactPerson.phoneNumber} />
                  )}
                  <ValuePair name="Email" value={contactPerson.email} />
                  {contactPerson.notes && (
                    <ValuePair name="Notes" value={contactPerson.notes} />
                  )}
                </Container>
              ))}
              <Button
                data-testid="7533bcb840"
                onClick={() =>
                  history.push(
                    `/suppliers-and-contracts/${organization.urlName}/${supplier.id}/add-contact-person`,
                  )
                }
              >
                Add new contact
              </Button>
            </div>
          </div>

          <div className={classNames(styles["side-section"], styles.container)}>
            <Container
              title={"Supplier State"}
              addon={
                <Link
                  data-testid="6247ef98a8"
                  to={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/edit-company`}
                  className={styles.edit}
                >
                  Edit
                </Link>
              }
            >
              <ValuePair
                name="Status"
                value={supplier.status || <NotAvailable />}
              />
              <ValuePair
                name="Categories"
                value={renderCategories(supplierCategories)}
              />
              <ValuePair
                name="Notes"
                value={supplier.notes || <NotAvailable />}
              />
            </Container>

            <Container title="Contracts and documents">
              {attachments.map((attachment) => (
                <div key={attachment.id}>
                  <BasicTable>
                    <tbody>
                      <tr>
                        <Cell quinary>
                          <div className={styles.file}>
                            {attachment.notes && (
                              <p className={styles.title}>{attachment.notes}</p>
                            )}
                            {attachment.url && (
                              <a
                                data-testid="83cbfcb787"
                                href={attachment.url}
                                className={styles.attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className={styles.filename}>
                                  {attachment.filename}
                                </span>{" "}
                                <OpenExternalIcon
                                  className={styles["open-external-icon"]}
                                />
                              </a>
                            )}
                          </div>
                        </Cell>
                        <Cell quinary compact>
                          <div className={styles["uploader-wrapper"]}>
                            <Avatar large user={attachment.creator} />
                            <div className={styles["uploader-data"]}>
                              <div className={styles["uploader-name"]}>
                                {attachment.creator.firstName}{" "}
                                {attachment.creator.lastName}
                              </div>
                              <div className={styles["upload-date"]}>
                                {attachment.documentDate && (
                                  <div className={styles["attachment-date"]}>
                                    Document:{" "}
                                    {formatDate(attachment.documentDate)}
                                  </div>
                                )}
                                {attachment.validityDate && (
                                  <div className={styles["attachment-date"]}>
                                    Validity:{" "}
                                    {formatDate(attachment.validityDate)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Cell>
                      </tr>
                    </tbody>
                  </BasicTable>
                  <Separator />
                </div>
              ))}
              <Link
                data-testid="770d0ff3e1"
                to={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/upload-attachment`}
              >
                <Button data-testid="b7567c44a6" add push />
              </Link>
            </Container>

            <Container title="Planned activities"></Container>
            <Container title="Past activities and interactions">
              {supplier.mentions.map(
                (mention) =>
                  mention.activity.type === ActivityTypeEnum.COMMENT && (
                    <Activity
                      key={mention.id}
                      dateAreaContent={formatDate(mention.createdDate)}
                      infoAreaHeader={
                        <>
                          {mention.activity.author ? (
                            <span
                              className={classNames(
                                styles.highlight,
                                styles["whitespace-right"],
                              )}
                            >
                              {mention.activity.author.firstName}{" "}
                              {mention.activity.author.lastName}
                            </span>
                          ) : (
                            <span
                              className={classNames(
                                styles["whitespace-right"],
                                styles.highlight,
                              )}
                            >
                              unknown
                            </span>
                          )}
                          commented:
                        </>
                      }
                      infoAreaBody={getMessageByActivity(
                        organization.urlName,
                        undefined,
                        mention.activity,
                      )}
                    />
                  ),
              )}
              {supplier.activities.map((activity) => {
                switch (activity.data.__typename) {
                  case ActivityTypeEnum.CREATE_SUPPLIER: {
                    return (
                      <Activity
                        key={activity.id}
                        dateAreaContent={formatDate(activity.createdDate)}
                        infoAreaHeader={
                          <>
                            {activity.data.creator ? (
                              <span
                                className={classNames(
                                  styles.highlight,
                                  styles["whitespace-right"],
                                )}
                              >
                                {activity.data.creator.firstName}{" "}
                                {activity.data.creator.lastName}
                              </span>
                            ) : (
                              <span
                                className={classNames(
                                  styles["whitespace-right"],
                                  styles.highlight,
                                )}
                              >
                                unknown
                              </span>
                            )}
                            created a new supplier
                          </>
                        }
                      />
                    );
                  }

                  case ActivityTypeEnum.UPDATE_SUPPLIER: {
                    return (
                      <Activity
                        key={activity.id}
                        dateAreaContent={formatDate(activity.createdDate)}
                        infoAreaHeader={
                          <>
                            {activity.data.creator ? (
                              <span
                                className={classNames(
                                  styles.highlight,
                                  styles["whitespace-right"],
                                )}
                              >
                                {activity.data.creator.firstName}{" "}
                                {activity.data.creator.lastName}
                              </span>
                            ) : (
                              <span
                                className={classNames(
                                  styles["whitespace-right"],
                                  styles.highlight,
                                )}
                              >
                                unknown
                              </span>
                            )}
                            {activity.data.changedFields.map((change, idx) => (
                              <div key={idx}>
                                <span>changed supplier </span>
                                <span className={styles["highlight"]}>
                                  {" "}
                                  {change.fieldName}{" "}
                                </span>
                                <span> from </span>
                                <span className={styles["highlight"]}>
                                  {" "}
                                  {change.oldValue}{" "}
                                </span>
                                <span> to </span>
                                <span className={styles["highlight"]}>
                                  {" "}
                                  {change.newValue}
                                </span>
                              </div>
                            ))}
                          </>
                        }
                      />
                    );
                  }

                  case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER: {
                    const rfxInfo = activity.data.rfxInfo;

                    return (
                      <Activity
                        key={activity.id}
                        dateAreaContent={formatDate(activity.createdDate)}
                        infoAreaHeader={
                          <>
                            <span
                              className={classNames(
                                styles.highlight,
                                styles["whitespace-right"],
                              )}
                            >
                              {activity.author
                                ? `${activity.author.firstName} ${activity.author.lastName}`
                                : "Inbound email"}
                            </span>
                            {rfxInfo && rfxInfo.code ? (
                              <span
                                className={classNames(
                                  feedUpdateStyles["author-action"],
                                )}
                              >
                                sent {rfxInfo.type || "RFX"}-{rfxInfo.code}
                              </span>
                            ) : (
                              <span
                                className={classNames(
                                  feedUpdateStyles["author-action"],
                                )}
                              >
                                sent an email
                              </span>
                            )}
                          </>
                        }
                        infoAreaBody={
                          <div
                            className={classNames([
                              feedUpdateStyles["message-content"],
                            ])}
                          >
                            {getMessageByActivity(
                              organization.urlName,
                              history,
                              activity,
                              activityRefs,
                            )}
                          </div>
                        }
                      />
                    );
                  }

                  case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER: {
                    const { supplierInfo, emailInfo } = activity.data;

                    return (
                      <Activity
                        key={activity.id}
                        dateAreaContent={formatDate(activity.createdDate)}
                        infoAreaHeader={
                          <>
                            <span
                              className={classNames(
                                styles.highlight,
                                styles["whitespace-right"],
                              )}
                            >
                              {supplierInfo.name}
                            </span>
                            <span
                              className={classNames(
                                feedUpdateStyles["author-action"],
                              )}
                            >
                              responded:
                            </span>
                          </>
                        }
                        infoAreaBody={
                          <div
                            className={classNames([
                              feedUpdateStyles["message-content"],
                            ])}
                          >
                            <div
                              className={feedUpdateStyles["email-wrap"]}
                              onClick={() => {
                                history?.push(
                                  `/${organization.urlName}/email/${activity.id}`,
                                );
                              }}
                            >
                              <div>
                                <span>From: </span>
                                <a
                                  data-testid="39619b291c"
                                  href={`mailto:${supplierInfo.email}`}
                                >
                                  {supplierInfo.email}
                                </a>
                              </div>
                              <div>
                                <span>Subject: </span>
                                <span>{emailInfo.subject}</span>
                              </div>
                              <div>---</div>
                              <div className={feedUpdateStyles["email-body"]}>
                                {emailInfo.message}
                              </div>
                              {activity.attachments && (
                                <div className={feedUpdateStyles.file}>
                                  {activity.attachments.map((attachment) => {
                                    return (
                                      <div key={attachment.id}>
                                        {attachment.url && (
                                          <a
                                            data-testid="693bc6388c"
                                            href={attachment.url}
                                            className={
                                              feedUpdateStyles.attachment
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <span
                                              className={
                                                feedUpdateStyles.filename
                                              }
                                            >
                                              {attachment.filename}
                                            </span>{" "}
                                            <OpenExternalIcon
                                              className={
                                                feedUpdateStyles[
                                                  "open-external-icon"
                                                ]
                                              }
                                            />
                                          </a>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    );
                  }

                  default:
                    return <></>;
                }
              })}
            </Container>
          </div>
        </div>
      </View>
      <Switch>
        <Route
          path={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/edit-contact-person/:contactPersonId`}
        >
          <EditContactPersonModal
            defaultContactPersonId={supplier.defaultContactPerson.id}
            organization={organization}
            onModalClose={() =>
              history.replace(
                `/suppliers-and-contracts/${organization.urlName}/${supplier.id}`,
              )
            }
          />
        </Route>
        <Route
          path={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/add-contact-person`}
        >
          <CreateContactPersonModal
            supplierId={supplier.id}
            onModalClose={() =>
              history.replace(
                `/suppliers-and-contracts/${organization.urlName}/${supplier.id}`,
              )
            }
          />
        </Route>
        <Route
          path={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/edit-company`}
        >
          <EditSupplierModal
            organization={organization}
            organizationCategories={organizationCategories}
            countries={countries}
            supplierId={supplier.id}
            onModalClose={() =>
              history.replace(
                `/suppliers-and-contracts/${organization.urlName}/${supplier.id}`,
              )
            }
          />
        </Route>
        <Route
          path={`/suppliers-and-contracts/${organization.urlName}/${supplier.id}/upload-attachment`}
        >
          <AddAttachment
            activeOrganization={organization}
            supplierId={supplier.id}
            onModalClose={() =>
              history.replace(
                `/suppliers-and-contracts/${organization.urlName}/${supplier.id}`,
              )
            }
          />
        </Route>
      </Switch>
    </>
  );
};

function renderCategories(categories: SupplierByIdCategories[]) {
  return (
    <div className={styles["supplier-categories-list"]}>
      {categories.length > 0 &&
        categories.map((category) => (
          <Chip key={category.id} id={category.id} className={styles["chip"]}>
            {category.name}
          </Chip>
        ))}
      {categories.length === 0 && <NotAvailable />}
    </div>
  );
}
