import React from "react";
import classNames from "classnames";
import { RouteComponentProps } from "react-router-dom";
import {
  ActivityFeedItemReplies,
  ActivityTypeEnum,
  ActivityFeedItemFragment,
} from "../schema";
import styles from "../components/FeedUpdate/FeedUpdate.module.scss";
import { Avatar } from "../components/Avatar/Avatar";
import { EnvelopeIcon } from "../theme/svg/EnvelopeIcon";
import { getReplyMessageByActivity } from "./getReplyMessageByActivity";
import { formatTimeAgo } from "./formatTimeAgo";

export interface OrganizationInfo {
  id: string;
  companyName: string;
  urlName: string;
}

export interface RenderReplyProps {
  activity: ActivityFeedItemFragment;
  reply: ActivityFeedItemReplies;
  organization: OrganizationInfo;
  latestActivity: ActivityFeedItemFragment | undefined;
  history: RouteComponentProps["history"];
}

export const renderReply: React.FC<RenderReplyProps> = ({
  activity,
  reply,
  organization,
  latestActivity,
  history,
}) => {
  const renderReplyAvatarIcon = (replyType: ActivityTypeEnum) => {
    switch (replyType) {
      case ActivityTypeEnum.REPLY: {
        return (
          <Avatar className={styles["update-icon"]} user={activity.author} />
        );
      }

      default:
        return (
          <div
            className={classNames(styles["update-icon"], styles["graph-icon"])}
          >
            <EnvelopeIcon />
          </div>
        );
    }
  };

  return (
    <div className={styles["reply-wrap"]} key={reply.id}>
      <div className={classNames(styles["feed-update"])}>
        {renderReplyAvatarIcon(reply.type)}

        <div className={styles["section-update"]}>
          <span className={styles.author}>
            {reply.author
              ? `${reply.author.firstName} ${reply.author.lastName}`
              : "Inbound email"}
          </span>
          <span className={styles.date}>
            {formatTimeAgo(reply.createdDate)}
          </span>
          <br />
          <div className={styles["reply-message"]}>
            <div className={styles["reply-message-content-container"]}>
              <div
                className={classNames([
                  styles["reply-content"],
                  {
                    [styles["reply-content--latest"]]:
                      reply.id === latestActivity?.id,
                  },
                ])}
              >
                {getReplyMessageByActivity(
                  organization.urlName,
                  history,
                  reply,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
