import React from "react";
import classNames from "classnames";
import styles from "./Container.module.scss";

export interface ContainerProps {
  small?: boolean;
  title?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  addon?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  small,
  title,
  className,
  icon,
  addon,
}) => {
  return (
    <div
      className={classNames(
        styles["container-wrap"],
        { [styles["container-wrap--small"]]: small },
        className,
      )}
    >
      {(title || icon || addon) && (
        <div className={styles.header}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title && <span className={styles.title}>{title}</span>}
          {addon && <div className={styles.items}>{addon}</div>}
        </div>
      )}
      <div className={styles.container}>{children}</div>
    </div>
  );
};
