import React from "react";
import { Redirect } from "react-router";
import { View } from "../../components/View/View";
import { useRouter } from "../../hooks/useRouter";
import { useLoginSupplierUserWithToken } from "../../schema";
import { LogoIcon } from "../../theme/svg/LogoIcon";
import { LoginIllustration } from "../../theme/svg/LoginIllustration";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingView } from "../LoadingView/LoadingView";
import { useLogin } from "../../hooks/useLogin";
import styles from "./LoginSupplierView.module.scss";

interface Params {
  token?: string;
  sourcingRequestId?: string;
}

export const LoginSupplierView: React.FC = () => {
  // extract token from params if exists
  const { match } = useRouter<Params>();
  const { token, sourcingRequestId } = match.params;

  // attempt to login user using token if available
  const {
    loading: isLoginByTokenLoading,
    success: loginSuccess,
    error: loginError,
  } = useLogin({
    token,
    mutationFunction: useLoginSupplierUserWithToken,
  });

  if (isLoginByTokenLoading) {
    return <LoadingView />;
  } else if (loginSuccess) {
    // redirect to supplier sourcing request view
    return <Redirect to={`/esourcing/${sourcingRequestId}`} />;
  }

  return (
    <View className={styles.view}>
      <div className={styles.section}>
        <div className={styles.logo__wrapper}>
          <LogoIcon width="48px" height="48px" className={styles.logo} />
          <span>
            <strong>ProcurementFlow</strong>
          </span>
        </div>

        <h1>Supplier Portal Entrance</h1>
        <div>
          {loginError ? (
            <ErrorMessage error="Your session has expired. Please use the link from the eSourcing event invite e-mail to log in again or contact our support team at support@procurementflow.com if you have lost this e-mail." />
          ) : null}
          {sourcingRequestId && !token ? (
            <ErrorMessage error="Sorry! This magic link is broken. Please use the link from the eSourcing event invite e-mail to log in or contact our support team at support@procurementflow.com if you have any trouble with that." />
          ) : null}
          {!sourcingRequestId && !token ? (
            <p>
              Please use the link from the eSourcing event invite e-mail to log
              in
            </p>
          ) : null}
        </div>
      </div>
      <div className={styles.illustration__wrapper}>
        <LoginIllustration className={styles.illustration} />
      </div>
    </View>
  );
};
