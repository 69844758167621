import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { CollaborationSidebar } from "../../components/CollaborationSidebar/CollaborationSidebar";
import { CommentForm } from "../../components/CommentForm/CommentForm";
import { CorrespondenceBar } from "../../components/CorrespondenceBar/CorrespondenceBar";
import { Field } from "../../components/Field/Field";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Form } from "../../components/Form/Form";
import { LabelDropdown } from "../../components/LabelDropdown/LabelDropdown";
import { Modal } from "../../components/Modal/Modal";
import { PurchaseRequestItems } from "../../components/PurchaseRequestItems/PurchaseRequestItems";
import { Separator } from "../../components/Separator/Separator";
import { UserSelectDropdown } from "../../components/UserSelectDropdown/UserSelectDropdown";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  CardTypeEnum,
  OrderByCodeCountries,
  OrderByCodeOrderByCode,
  UserInfoFragment,
  ViewerOrganizations,
  useAssignCard,
  useUpdateOrder,
  SupplierInfoFragment,
  ActivityRelativeTypeEnum,
  useDeletePurchaseRequestItem,
  ViewerViewer,
  useArchiveOrder,
  CardStatusLevelEnum,
} from "../../schema";
import { checkDownloadUrlValidity } from "../../services/checkDownloadUrlValidity";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./InvoiceModal.module.scss";

export interface InvoiceModalProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  order: OrderByCodeOrderByCode;
  users: UserInfoFragment[];
  usersWhoCanBeAssignedToCards: UserInfoFragment[];
  countries: OrderByCodeCountries[];
  suppliers: SupplierInfoFragment[];
  onModalClose(): void;
}

export const InvoiceModal: React.FC<InvoiceModalProps> = ({
  viewer,
  organization,
  order,
  users,
  usersWhoCanBeAssignedToCards,
  suppliers,
  onModalClose,
}) => {
  const { history } = useRouter();

  // keep track of which purchase request we are deleting
  const [deletingPurchaseRequestItemId, setDeletingPurchaseRequestItemId] =
    useState<string | undefined>(undefined);

  const [isCommentFormLoading, setIsCommentFormLoading] = useState(false);

  const [showCorrespondenceBar, setShowCorrespondenceBar] = useState(false);

  // setup assigning card
  const [assignCard, { loading: isAssigningCard }] = useAssignCard({
    refetchQueries: ["OrderByCode", "PaginatedInvoices"],
    awaitRefetchQueries: true,
  });

  // setup deleting purchase request item
  const [deletePurchaseRequestItem] = useDeletePurchaseRequestItem({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedInvoices"],
    awaitRefetchQueries: true,
  });

  // archive
  const [archiveOrder, { loading: isArchiveOrderLoading }] = useArchiveOrder({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedInvoices"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onModalClose();
    },
  });

  const {
    useInput,
    useCheckbox,
    submit: updateOrder,
    loading: isUpdateOrderLoading,
    error: updateError,
  } = useForm({
    mutation: useUpdateOrder,
    options: {
      refetchQueries: ["PaginatedInvoices"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => {
      history.push(`/${organization.urlName}`);
    },
  });

  const invoiceNumberInput = useInput({
    name: "invoiceNumber",
    optional: true,
  });
  const invoiceReceivedCheckbox = useCheckbox({ name: "invoiceReceived" });
  const invoiceThreeWayMatchCheckbox = useCheckbox({
    name: "invoiceThreeWayMatch",
  });
  const invoiceApprovedCheckbox = useCheckbox({ name: "invoiceApproved" });
  const invoicePaidCheckbox = useCheckbox({ name: "invoicePaid" });

  const userMentionData = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const supplierMentionData = suppliers.map((supplier) => ({
    id: supplier.id,
    display: supplier.name
      ? supplier.name
      : supplier.defaultContactPerson.email
      ? supplier.defaultContactPerson.email
      : "[missing name]",
  }));

  // react to order changes
  useEffect(() => {
    if (!order) {
      return;
    }

    if (order.invoiceNumber) {
      invoiceNumberInput.setValue(order.invoiceNumber);
    }

    invoiceReceivedCheckbox.setValue(order.invoiceReceived);

    invoiceThreeWayMatchCheckbox.setValue(order.invoiceThreeWayMatch);

    invoiceApprovedCheckbox.setValue(order.invoiceApproved);

    invoicePaidCheckbox.setValue(order.invoicePaid);

    // show correspondence bar if there were any e-mails sent or received in preceding rfx stadium
    if (order.rfx && order.rfx.emails && order.rfx.emails.length > 0) {
      setShowCorrespondenceBar(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  //submits updating order
  const submit = () => {
    updateOrder({
      orderId: order.id,
      supplierId: order.supplierId,
      shippingAddressId: order.shippingAddressId,
      name: order.name,
      isConfirmationRequired: order.isConfirmationRequired,
      expectedConfirmationDate: order.expectedConfirmationDate,
      externalIdentifier: order.externalIdentifier,
      expectedDeliveryDate: order.expectedDeliveryDate,
      paymentTerm: order.paymentTerm,
      purchaseOrderDeliveryNotes: order.purchaseOrderDeliveryNotes,
      hasBeenSent: order.hasBeenSent,
      receivingIsConfirmationReceived: order.receivingIsConfirmationReceived,
      receivingGoodsReceived: order.receivingGoodsReceived,
      receivingGoodsReceivedPartially: order.receivingGoodsReceivedPartially,
      receivingIsAddedToStock: order.receivingIsAddedToStock,
      receivingWarehouseCode: order.receivingWarehouseCode,
      receivingNotes: order.receivingNotes,
    });
  };

  // attempts to delete requested purchase request item
  // const deleteItem = async (purchaseRequestItemId: string) => {
  //   setDeletingPurchaseRequestItemId(purchaseRequestItemId);

  //   try {
  //     await deletePurchaseRequestItem({ variables: { purchaseRequestItemId } });
  //   } finally {
  //     setDeletingPurchaseRequestItemId(undefined);
  //   }
  // };

  // handle loading (also avoid form content flicker)
  if (!order) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      wide
      title={`PO-${order.code} ${order.name} ${
        order.externalIdentifier ? order.externalIdentifier : ""
      }`}
      className={styles["modal"]}
      onCloseRequested={onModalClose}
      addon={
        <div className={styles["addon-wrap"]}>
          <LabelDropdown
            className={styles["label-dropdown"]}
            label={
              !isEmpty(order.cardStatus.text)
                ? order.cardStatus.text
                : "WAITING INVOICE"
            }
            level={order.cardStatus.level ?? CardStatusLevelEnum.INFO}
            dropdownItems={[
              ...(order.deletedDate === null
                ? [
                    {
                      id: "1",
                      icon: <ArchiveIcon className={styles["archive-icon"]} />,
                      text: "Archive",
                      onClick: async () => {
                        await archiveOrder({
                          variables: {
                            orderId: order.id,
                          },
                        });
                      },
                    },
                  ]
                : []),
              {
                id: "2",
                icon: <ExcelIcon />,
                text: "Download",
                onClick: async () => {
                  return checkDownloadUrlValidity(
                    `/api/export/order/${order.id}`,
                  );
                },
              },
            ]}
          />
          <UserSelectDropdown
            large
            title={
              order.assignee === null
                ? "Click to assign card"
                : `[${order.assignee.firstName} ${order.assignee.lastName}] Click to change card assignee`
            }
            activeUser={order.assignee}
            users={usersWhoCanBeAssignedToCards}
            loading={isAssigningCard}
            onChoose={(user) => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: user.id,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
            onUnassign={() => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: null,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
          />
        </div>
      }
      sidebarTitle="Collaboration feed"
      sidebar={
        <>
          {/* <SidebarFilter title="Show only Updates" onClick={() => alertNotImplemented()} /> */}
          <CommentForm
            orgUsers={userMentionData ? userMentionData : []}
            orgSuppliers={supplierMentionData ? supplierMentionData : []}
            organizationId={organization.id}
            parentId={null}
            relativeId={order.id}
            relativeType={ActivityRelativeTypeEnum.ORDER}
            setIsCommentFormLoading={setIsCommentFormLoading} // TODO add isCommentFormLoading aswell?
          />
          <Separator />
          <LatestActivityProvider
            organizationId={organization.id}
            relativeId={order.id}
          >
            <CollaborationSidebar
              activities={order.activities}
              showActivityLoader={isCommentFormLoading}
            />
          </LatestActivityProvider>
        </>
      }
      footer={
        <>
          <span className={styles["label-complete"]}>Process complete</span>
          <Button
            data-testid="7750d582c2"
            onClick={submit}
            loading={isUpdateOrderLoading}
            disabled={isUpdateOrderLoading}
          >
            Save
          </Button>
          {/* Send to archive */}
        </>
      }
    >
      <div className={styles["wrap"]}>
        <div className={styles["invoice-container"]}>
          <div className={styles["left-container"]}>
            <Form onSubmit={submit} error={updateError}>
              {order.supplier && (
                <>
                  <FieldLabel label="Supplier">
                    {order.supplier.name}
                  </FieldLabel>
                  <Separator />
                </>
              )}
              <Field
                {...invoiceNumberInput}
                short
                label="Invoice #"
                placeholder="Supplier Invoice number"
              />
              {/* <FieldLabel label="Invoice confirmation" /> */}
              <h4>Invoice status</h4>
              <Field {...invoiceReceivedCheckbox}>Invoice received</Field>
              <Field {...invoiceThreeWayMatchCheckbox}>3-way match</Field>
              <Field {...invoiceApprovedCheckbox}>Invoice approved</Field>
              <Field {...invoicePaidCheckbox}>Invoice paid</Field>
              <Separator />
              <PurchaseRequestItems
                items={order.items}
                loadingItemId={deletingPurchaseRequestItemId}
                onRequestLinkClick={(requestCode) =>
                  history.push(`/${organization.urlName}/${requestCode}`)
                }
              />
            </Form>
          </div>

          {showCorrespondenceBar && (
            <div className={styles["right-container"]}>
              <ViewerContextProvider viewer={viewer}>
                <CorrespondenceBar order={order} />
              </ViewerContextProvider>
            </div>
          )}
        </div>
        {isArchiveOrderLoading && <LoadingView overlay />}
      </div>
    </Modal>
  );
};
