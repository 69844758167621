import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ViewerViewer, useLogout } from "../../schema";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { Avatar, AvatarUser } from "../Avatar/Avatar";
import { formatRole } from "../../services/formatRole";
import { Separator } from "../Separator/Separator";
import styles from "./ProfileDropdown.module.scss";

export interface ProfileDropdownProps {
  viewer: ViewerViewer;
  user: AvatarUser;
  linkToMyInfo?: string;
  linkToCompanyDetails?: string;
  linkToTeamBoard?: string;
  linkToApproverPortal?: string;
  linkToRequesterPortal?: string;
  linkToHelpCenter?: string;
}

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  viewer,
  user,
  linkToMyInfo,
  linkToCompanyDetails,
  linkToTeamBoard,
  linkToApproverPortal,
  linkToRequesterPortal,
  linkToHelpCenter,
}) => {
  const [logout] = useLogout({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
  });

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Dropdown
      data-testid="a9837c162d"
      onCloseRequested={() => setIsOpen(false)}
      className={styles["profile-dropdown"]}
    >
      <DropdownSelector
        onClick={() => setIsOpen(!isOpen)}
        hasArrow
        className={styles["dropdown-selector"]}
      >
        <div className={styles["selector-wrapper"]}>
          <div
            className={styles["user-wrap"]}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div data-testid="user-name" className={styles.user}>
              {viewer.firstName}
            </div>
            <div data-testid="user-role" className={styles.role}>
              {viewer.roles.map((role) => formatRole(role)).join(", ")}
            </div>
          </div>
          <Avatar large user={user} className={styles.avatar} />
        </div>
      </DropdownSelector>
      <DropdownContent
        right
        open={isOpen}
        className={styles["dropdown-content"]}
        onCloseRequested={() => setIsOpen(false)}
      >
        <DropdownItem className={classNames(styles["list-avatar-wrap"])}>
          <Avatar
            large
            user={user}
            className={styles["list-avatar"]}
            hoverEffect={false}
          />
          <div className={styles["list-user-data-wrap"]}>
            <span className={styles["dropdown-user"]}>
              {viewer.firstName} {viewer.lastName}
            </span>
          </div>
        </DropdownItem>
        <Separator zeroMarginBottom />
        {linkToMyInfo && (
          <DropdownItem
            className={styles["list-item"]}
            onClick={() => {
              /* Do nothing? */
              setIsOpen(false);
            }}
          >
            <Link data-testid="02bd884944" to={linkToMyInfo}>
              My info
            </Link>
          </DropdownItem>
        )}
        {linkToCompanyDetails && (
          <DropdownItem
            className={styles["list-item"]}
            onClick={() => {
              // Do nothing ?
              setIsOpen(false);
            }}
          >
            <Link data-testid="d29c98721d" to={linkToCompanyDetails}>
              Company details
            </Link>
          </DropdownItem>
        )}
        {linkToTeamBoard && (
          <DropdownItem
            className={styles["list-item"]}
            onClick={() => {
              // Do nothing ?
              setIsOpen(false);
            }}
          >
            <Link to={linkToTeamBoard}>Team board</Link>
          </DropdownItem>
        )}
        {linkToApproverPortal && (
          <DropdownItem
            className={styles["list-item"]}
            onClick={() => {
              // Do nothing ?
              setIsOpen(false);
            }}
          >
            <Link to={linkToApproverPortal}>Approver portal</Link>
          </DropdownItem>
        )}
        {linkToRequesterPortal && (
          <DropdownItem
            className={styles["list-item"]}
            onClick={() => {
              // Do nothing ?
              setIsOpen(false);
            }}
          >
            <Link to={linkToRequesterPortal}>Requester portal</Link>
          </DropdownItem>
        )}
        {linkToHelpCenter && (
          <DropdownItem className={styles["list-item"]}>
            <a
              href={linkToHelpCenter}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center
            </a>
          </DropdownItem>
        )}
        <Separator zeroMarginBottom />
        <DropdownItem
          className={classNames(styles["list-item"], styles["logout-wrap"])}
          onClick={() => {
            logout();
          }}
        >
          <Link
            data-testid="169421a780"
            to="/login"
            className={styles.logout}
            tabIndex={0}
          >
            Sign out
          </Link>
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
