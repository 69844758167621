export interface PriceItem {
  unitPrice?: number | null;
  quantity?: number | null;
  currency?: string | null;
}

export type PriceMap = { [x: string]: number | undefined };

export function calculateItemPriceTotals(items: PriceItem[]) {
  return items.reduce<PriceMap>((sumMap, item) => {
    // extract item info
    const { currency, unitPrice, quantity } = item;

    // don't do anything if missing required info
    if (typeof currency !== "string" && typeof unitPrice !== "number") {
      return sumMap;
    }
    if (
      typeof currency === "string" &&
      typeof unitPrice === "number" &&
      typeof quantity === "number"
    ) {
      // calculate item sum
      const itemSum = unitPrice * quantity;
      const existingSum = sumMap[currency];

      // store currency sum
      sumMap[currency] =
        (existingSum !== undefined ? existingSum : 0) + itemSum;
      return sumMap;
    }
    if (typeof currency === "string" && typeof unitPrice === "number") {
      const existingSum = sumMap[currency];

      // store currency sum
      sumMap[currency] =
        (existingSum !== undefined ? existingSum : 0) + unitPrice;
      return sumMap;
    }

    return sumMap;
  }, {});
}
