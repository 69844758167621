import React from "react";
import classNames from "classnames";
import { ApprovalWorkflowStatusEnum } from "../../schema";
import styles from "./ApprovalBadge.module.scss";

export interface ApprovalBadgetatus {
  level: ApprovalWorkflowStatusEnum;
  text: string;
}

export interface ApprovalBadgeProps extends ApprovalBadgetatus {
  className?: string;
}

export const ApprovalBadge: React.FC<ApprovalBadgeProps> = ({
  level,
  text,
  className,
}) => {
  return (
    <span
      className={classNames(
        styles["approval-badge"],
        {
          [styles["approval-badge--approved"]]:
            level === ApprovalWorkflowStatusEnum.APPROVED,
          [styles["approval-badge--cancelled"]]:
            level === ApprovalWorkflowStatusEnum.CANCELLED,
          [styles["approval-badge--declined"]]:
            level === ApprovalWorkflowStatusEnum.DECLINED,
          [styles["approval-badge--draft"]]:
            level === ApprovalWorkflowStatusEnum.DRAFT,
          [styles["approval-badge--inprogress"]]:
            level === ApprovalWorkflowStatusEnum.IN_PROGRESS,
        },
        className,
      )}
      title={text}
    >
      {text}
    </span>
  );
};
