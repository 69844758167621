import React from "react";
import styles from "./ErrorMessage.module.scss";

export interface ErrorMessageProps {
  error?: Error | string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  const message = error instanceof Error ? error.message : error;

  return <div className={styles.wrap}>{message}</div>;
};
