import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export interface ProtectedRouteProps {
  allowed: boolean;
  redirect: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps & RouteProps> = ({
  allowed,
  redirect,
  ...rest
}) => (allowed ? <Route {...rest} /> : <Redirect to={redirect} />);
