import React from "react";
import { Route, Switch } from "react-router";
import { alertNotImplemented } from "../../services/alertNotImplemented";
import { Button } from "../../components/Button/Button";
import { Cell, BasicTable } from "../../components/BasicTable/BasicTable";
import { EditIcon } from "../../theme/svg/EditIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { useRouter } from "../../hooks/useRouter";
import { Row } from "../../components/Row/Row";
import { formatDatetime } from "../../services/formatDatetime";
import {
  UsersManagementAdmin,
  OrganizationsManagementAdmin,
} from "../../schema";
import { Avatar } from "../../components/Avatar/Avatar";
import { AddNewUser } from "./AddNewUser";
import { EditUser } from "./EditUser";
import styles from "./AdminView.module.scss";

interface UsersManagementProps {
  users?: UsersManagementAdmin["users"];
  organizations?: OrganizationsManagementAdmin["organizations"];
  countries?: UsersManagementAdmin["countries"];
}

export const UsersManagement: React.FC<UsersManagementProps> = ({
  users,
  organizations,
  countries,
}) => {
  const { history } = useRouter();

  return (
    <div>
      <Row secondary>
        <Row secondary>
          <h1>User management</h1>
          <Button
            data-testid="40a2cdf0fc"
            add
            title="Add new user"
            onClick={() => history.push("/admin/users/add-new-user")}
            className={styles["add-button"]}
          />
        </Row>
      </Row>
      <BasicTable>
        <thead>
          <tr>
            <Cell secondary nowrap header>
              Avatar
            </Cell>
            <Cell secondary nowrap header>
              Name
            </Cell>
            <Cell secondary nowrap header>
              Email
            </Cell>
            <Cell secondary nowrap header>
              Organization
            </Cell>
            <Cell secondary nowrap header compact>
              Role
            </Cell>
            <Cell secondary nowrap header compact>
              Created date
            </Cell>
            <Cell secondary nowrap header compact>
              Updated date
            </Cell>
            <Cell secondary nowrap header compact>
              Status
            </Cell>
            <Cell compact />
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user) => (
              <tr key={user.id}>
                <Cell secondary nowrap>
                  <Avatar large user={user} className={styles.avatar} />
                </Cell>
                <Cell secondary strong nowrap>
                  {user.firstName} {user.lastName}
                </Cell>
                <Cell secondary>{user.email}</Cell>
                <Cell secondary>
                  {user.organizations &&
                    user.organizations
                      .map((organization) => `${organization.companyName}`)
                      .join(", ")}
                </Cell>
                <Cell secondary>
                  {user.roles
                    .map((role) => role.charAt(0) + role.slice(1).toLowerCase())
                    .join(", ")}
                </Cell>
                <Cell secondary center>
                  {formatDatetime(user.createdDate)}
                </Cell>
                <Cell secondary center>
                  {formatDatetime(user.updatedDate)}
                </Cell>
                <Cell secondary>{user.status}</Cell>
                <Cell secondary>
                  <Row>
                    <Button
                      data-testid="54de595056"
                      icon={<EditIcon className={styles["button-icon"]} />}
                      onClick={() => history.push(`/admin/users/${user.id}`)}
                    />
                    <Button
                      data-testid="178979b4f1"
                      icon={<RemoveIcon className={styles["button-icon"]} />}
                      onClick={() => alertNotImplemented()}
                    />
                  </Row>
                </Cell>
              </tr>
            ))}
        </tbody>
      </BasicTable>
      <Switch>
        <Route exact path={"/admin/users/add-new-user"}>
          <AddNewUser
            organizations={organizations}
            countries={countries}
            onModalClose={() => history.push("/admin/users")}
          />
        </Route>
      </Switch>
      {users &&
        users.map((user) => (
          <Switch key={user.id}>
            <Route path={`/admin/users/${user.id}`}>
              <EditUser
                userId={user.id}
                onModalClose={() => history.push("/admin/users")}
                organizations={organizations ? organizations : []}
              />
            </Route>
          </Switch>
        ))}
    </div>
  );
};
