import { RefObject } from "react";

export type StringToRefObjectMap<T extends HTMLElement = HTMLElement> = Map<
  string,
  RefObject<T>
>;

export function scrollIntoViewByRef(
  refs?: StringToRefObjectMap,
  id?: string | null,
) {
  if (!refs || !id) {
    return;
  }

  const ref = refs.get(id);

  ref?.current &&
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
}
