import React, { useRef, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { SuggestionDataItem } from "react-mentions";
import { Button } from "../Button/Button";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { Form } from "../Form/Form";
import MentionInputField from "../MentionInputField/MentionInputField";
import { Attachments } from "../Attachments/Attachments";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";

import {
  useCreateComment,
  CreateCommentMutation,
  CreateCommentVariables,
  ActivityRelativeTypeEnum,
  ActivityTypeEnum,
} from "../../schema";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import styles from "./CommentForm.module.scss";

export interface CommentFormProps {
  orgUsers?: SuggestionDataItem[];
  orgSuppliers?: SuggestionDataItem[];
  organizationId: string;
  parentId: null;
  relativeId: string;
  relativeType: ActivityRelativeTypeEnum;
  setIsCommentFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommentForm: React.FC<CommentFormProps> = ({
  orgUsers,
  orgSuppliers,
  organizationId,
  parentId,
  relativeId,
  relativeType,
  setIsCommentFormLoading,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // collaboration feed files
  const [collaborationFeedFiles, setCollaborationFeedFiles] = useState<
    File[] | undefined
  >(undefined);

  // setup comment form and mutation fn
  const {
    useInput: useCommentInput,
    useMultiSelect: useMentionsInput,
    reset: resetCommentInput,
    submit: submitComment,
    loading: commentFormLoading,
  } = useForm<CreateCommentMutation, CreateCommentVariables>({
    mutation: useCreateComment,
    options: {
      refetchQueries: ["PurchaseRequestByCode", "RfxByCode", "OrderByCode"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => {
      setCollaborationFeedFiles(undefined);
    },
  });

  const commentInput = useCommentInput({ name: "comment", validators: [] });
  const mentionsInput = useMentionsInput({ name: "mentions", validators: [] });

  const createCommentWithOverrides =
    (overrides: Partial<CreateCommentVariables>) => () => {
      resetCommentInput();
      submitComment(overrides);
    };

  // set cf files state on drop
  const onCollaborationFeedDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // set selected files
      setCollaborationFeedFiles([
        ...(collaborationFeedFiles ?? []),
        ...acceptedFiles,
      ]);
    },
    [collaborationFeedFiles],
  );

  useEffect(() => {
    setIsCommentFormLoading(commentFormLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentFormLoading]);

  return (
    <>
      <div className={styles["comment-form"]}>
        <Form
          onSubmit={createCommentWithOverrides({
            organizationId: organizationId,
            parentId: parentId,
            relativeId: relativeId,
            relativeType: relativeType,
            type: ActivityTypeEnum.COMMENT,
            attachments: collaborationFeedFiles,
          })}
        >
          <MentionInputField
            formTextInput={commentInput}
            multiSelectInput={mentionsInput}
            mentionListData={orgUsers ? orgUsers : []}
            tagListData={orgSuppliers ? orgSuppliers : []}
            className={styles["textarea-wrap"]}
            label={"Add new update"}
            placeholder={"Add update or comment..."}
            inputRef={inputRef}
          />
          {collaborationFeedFiles && collaborationFeedFiles.length > 0 && (
            <div className={styles["attachments"]}>
              {collaborationFeedFiles.map((file) => (
                <div key={file.name}>
                  <DeleteFileIcon
                    className={classNames(styles["delete-file-icon"])}
                    onClick={() =>
                      setCollaborationFeedFiles(
                        collaborationFeedFiles.filter(
                          (stateFile) => file.name !== stateFile.name,
                        ),
                      )
                    }
                  />
                  <span className={styles["filename"]}>{file.name}</span>
                </div>
              ))}
            </div>
          )}
          <div className={styles["action-wrap"]}>
            <div className={styles.section}>
              <Button
                data-testid="84ba3e1175"
                text
                className={styles.action}
                onClick={() => {
                  if (inputRef?.current) {
                    commentInput.setValue(inputRef.current.value + " #");
                    inputRef.current.focus();
                  }
                }}
              >
                # Tag Supplier
              </Button>
              <Button
                data-testid="0efe8a4d00"
                text
                className={styles.action}
                onClick={() => {
                  if (inputRef?.current) {
                    commentInput.setValue(inputRef.current.value + " @");
                    inputRef.current.focus();
                  }
                }}
              >
                @ Mention Teammate
              </Button>
            </div>
            <Attachments
              multiple
              loading={false}
              onDrop={(acceptedFiles) => onCollaborationFeedDrop(acceptedFiles)}
              secondaryIconColor
            />
          </div>
          <Button
            data-testid="f6fde24dc2"
            className={styles.button}
            type="submit"
            disabled={commentFormLoading}
          >
            Post update
          </Button>
        </Form>
      </div>
    </>
  );
};
