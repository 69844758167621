import React from "react";
import { RouteComponentProps } from "react-router";
import { ActivityTypeEnum, ActivityFeedItemReplies } from "../schema";
import styles from "../components/FeedUpdate/FeedUpdate.module.scss";
import { OpenExternalIcon } from "../theme/svg/OpenExternalIcon";
import { convertHtmlStringToSafeJsx } from "./convertHtmlStringToSafeJsx";

export function getReplyMessageByActivity(
  organizationUrlName: string,
  history: RouteComponentProps["history"],
  reply: ActivityFeedItemReplies,
) {
  if (!reply.data || !reply.data.__typename) {
    return null;
  }

  switch (reply.data.__typename) {
    case ActivityTypeEnum.REPLY:
    case ActivityTypeEnum.COMMENT: {
      const messageData = reply.data;

      return <>{convertHtmlStringToSafeJsx(messageData.comment)}</>;
    }

    case ActivityTypeEnum.EMAIL_INBOUND_MENTION: {
      const messageData = reply.data;

      return (
        <div
          className={styles["email-wrap"]}
          onClick={() => {
            history.push(`/${organizationUrlName}/email/${reply.id}`);
          }}
        >
          <div className={styles["email-body"]}>
            {convertHtmlStringToSafeJsx(messageData.comment)}
          </div>
          {reply.attachments && (
            <div className={styles.file}>
              {reply.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        data-testid="693bc6388c"
                        href={attachment.url}
                        className={styles.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.filename}>
                          {attachment.filename}
                        </span>{" "}
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER: {
      const messageData = reply.data;

      return (
        <div
          data-testid="86bba80f0b"
          className={styles["email-wrap"]}
          onClick={() => {
            history.push(`/${organizationUrlName}/email/${reply.id}`);
          }}
        >
          <div>
            From:{" "}
            <a
              data-testid="d28983baea"
              href={`mailto:${messageData.supplierInfo.email}`}
            >
              {messageData.supplierInfo.email}
            </a>
          </div>
          <div>
            <span>Subject: {messageData.emailInfo.subject}</span>
          </div>
          <div>---</div>
          <div className={styles["email-body"]}>
            {messageData.emailInfo.message}
          </div>
          {reply.attachments && (
            <div className={styles.file}>
              {reply.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        data-testid="693bc6388c"
                        href={attachment.url}
                        className={styles.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.filename}>
                          {attachment.filename}
                        </span>{" "}
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    case ActivityTypeEnum.EMAIL_INBOUND_REQUESTER: {
      const messageData = reply.data;
      return <>{messageData.emailInboundRequesterInfo}</>;
    }

    default:
      return null;
  }
}
