import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  ViewerViewer,
  useOrganizationsManagement,
  useUsersManagement,
} from "../../schema";
import { View } from "../../components/View/View";
import { Tab, Tabs } from "../../components/Tabs/Tabs";
import { LoadingView } from "../LoadingView/LoadingView";
import { ErrorView } from "../ErrorView/ErrorView";
import { OrganizationsManagement } from "./OrganizationsManagement";
import { UsersManagement } from "./UsersManagement";

export interface AdminViewProps {
  viewer: ViewerViewer;
}

export const AdminView: React.FC<AdminViewProps> = () => {
  const { data: info } = useUsersManagement();
  const { data, loading, error } = useOrganizationsManagement();

  const organizations = data?.admin && data.admin.organizations;
  const countries = data?.admin && data.admin.countries;
  const currencies = data?.admin && data.admin.currencies;
  const users = info?.admin && info.admin.users;
  const adminOrganizations = data?.admin ? data.admin.organizations : [];
  const adminCountries = info?.admin && info.admin.countries;

  // handle error
  if (error) {
    return <ErrorView error={error} />;
  }

  // handle loading
  if (loading || !data) {
    return <LoadingView overlay />;
  }

  return (
    <View secondary>
      <Tabs>
        <Tab label="Organizations" to={"/admin/organizations"} />
        <Tab label="Users" to={"/admin/users"} />
      </Tabs>
      <Switch>
        <Route path="/admin/organizations">
          <OrganizationsManagement
            organizations={organizations}
            countries={countries}
            currencies={currencies}
          />
        </Route>
        <Route path="/admin/users">
          <UsersManagement
            users={users}
            organizations={adminOrganizations}
            countries={adminCountries}
          />
        </Route>
        <Route path="/admin">
          <Redirect to="/admin/organizations" />
        </Route>
      </Switch>
    </View>
  );
};
