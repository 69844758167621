import { ApprovalWorkflowStatusEnum, CardStatusLevelEnum } from "../schema";

export function getWorkflowStatusLevel(awfStatus: ApprovalWorkflowStatusEnum) {
  switch (awfStatus) {
    case ApprovalWorkflowStatusEnum.APPROVED: {
      return CardStatusLevelEnum.SUCCESS;
    }

    case ApprovalWorkflowStatusEnum.DECLINED: {
      return CardStatusLevelEnum.ALERT;
    }

    case ApprovalWorkflowStatusEnum.IN_PROGRESS: {
      return CardStatusLevelEnum.INFO;
    }

    default:
      return CardStatusLevelEnum.DEFAULT;
  }
}
