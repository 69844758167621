import React from "react";
import classNames from "classnames";
import styles from "./ValuePair.module.scss";

export interface ValuePairProps {
  name: React.ReactNode;
  value?: React.ReactNode;
  highlightName?: boolean;
  align?: boolean;
  className?: string;
  height?: number;
}

export const ValuePair: React.FC<ValuePairProps> = ({
  name,
  value,
  highlightName,
  align,
  height,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles["value-pair"],
        { [styles["value-pair--align"]]: align },
        className,
      )}
      style={{ height: `${height}px` }}
    >
      <span
        className={classNames(styles.name, {
          [styles["name--highlight"]]: highlightName,
        })}
      >
        {name}
      </span>
      <span className={styles.value}>
        {value !== undefined && value !== null ? value : "n/a"}
      </span>
    </div>
  );
};
