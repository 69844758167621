import React, { useEffect, useState, createContext } from "react";
import { DragDropContext, DragDropContextProps } from "react-beautiful-dnd";
import { Redirect, Route, Switch } from "react-router";
import { AnimatedList } from "../../components/AnimatedList/AnimatedList";
import { CardInfo } from "../../components/Card/Card";
import { CardItemInfo } from "../../components/CardItem/CardItem";
import { KanbanCollaborationSidebar } from "../../components/KanbanCollaborationSidebar/KanbanCollaborationSidebar";
import { Separator } from "../../components/Separator/Separator";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import {
  DraggableType,
  OnAssignCard,
  OnUnassignCard,
  Swimlane,
  SwimlaneInfo,
  Swimlanes,
  getCardSwimlane,
  getDroppable,
} from "../../components/Swimlane/Swimlane";
import { UpdateNotice } from "../../components/UpdateNotice/UpdateNotice";
import { View } from "../../components/View/View";
import {
  LANE_COLOR_INVOICES_BODY,
  LANE_COLOR_INVOICES_HEADER,
  LANE_COLOR_ORDER_BODY,
  LANE_COLOR_ORDER_HEADER,
  LANE_COLOR_PR_BODY,
  LANE_COLOR_PR_HEADER,
  LANE_COLOR_RECEIVING_BODY,
  LANE_COLOR_RECEIVING_HEADER,
  LANE_COLOR_RFX_BODY,
  LANE_COLOR_RFX_HEADER,
  SwimlaneId,
} from "../../constants";
import { useLatestActivity } from "../../contexts/latest-activity-context";
import { useRouter } from "../../hooks/useRouter";
import {
  UserStatusEnum,
  CardPositionMethodEnum,
  CardTypeEnum,
  ViewerOrganizations,
  ViewerViewer,
  useAddPurchaseRequestItemsToRfx,
  useAddPurchaseRequestItemsToOrder,
  useAssignCard,
  useCreateOrderFromRfx,
  useCreateOrderFromPurchaseRequest,
  useCreateOrderFromPurchaseRequestItems,
  useCreateRfxFromPurchaseRequest,
  useCreateRfxFromPurchaseRequestItems,
  useKanbanView,
  useUpdateCardPosition,
  useUpdateOrderType,
  ActivityTypeEnum,
  UserRoleEnum,
  UserInfoFragment,
  PurchaseRequestStatusEnum,
  usePaginatedPurchaseRequests,
  usePaginatedRfx,
  PaginatedPurchaseRequestsQuery,
  PaginatedRfxQuery,
  usePaginatedOrdersQuery,
  PaginatedOrdersQuery,
  usePaginatedReceivingQuery,
  PaginatedReceivingQuery,
  usePaginatedInvoicesQuery,
  PaginatedInvoicesQuery,
} from "../../schema";

import { getInvoiceCardInfo } from "../../services/getInvoiceCardInfo";
import { getOrderCardInfo } from "../../services/getOrderCardInfo";
import { getPurchaseRequestCardInfo } from "../../services/getPurchaseRequestCardInfo";
import { getReceivingCardInfo } from "../../services/getReceivingCardInfo";
import { getRfxCardInfo } from "../../services/getRfxCardInfo";
import { getUpdateNoticeByActivity } from "../../services/getUpdateNoticeByActivity";
import { persistKanbanDrag } from "../../services/persistKanbanDrag";
import { removeFocus } from "../../services/removeFocus";
import { sendGoogleAnalyticsEvent } from "../../services/sendGoogleAnalyticsEvent";
import { UploadItemsExcelToPurchaseRequest } from "../CreatePurchaseRequestItemView/UploadItemsExcelToPurchaseRequest";
import { CreatePurchaseRequestView } from "../CreatePurchaseRequestView/CreatePurchaseRequestView";
import { EditPurchaseRequestItemView } from "../EditPurchaseRequestItemView/EditPurchaseRequestItemView";
import { EmailView } from "../EmailView/EmailView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { SelectedFilters } from "../MemberView/MemberView";
import { OrderProgressView } from "../OrderProgressView/OrderProgressView";
import { PurchaseRequestView } from "../PurchaseRequestView/PurchaseRequestView";
import { RfxView } from "../RfxView/RfxView";
import styles from "./KanbanView.module.scss";

// parameters for creating card from items
interface OnCreateCardFromItemParameters {
  item: CardItemInfo;
  source: CardInfo;
  destination: SwimlaneInfo;
}

export interface KanbanViewProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  selectedFilters: SelectedFilters | undefined;
}

export type InfiniteScrollStateType = {
  [key in SwimlaneId]: {
    hasNextPage: boolean;
    previousCursor: string | null;
  };
};

export const OrganizationContext: React.Context<{
  id: string;
  companyName: string;
  urlName: string;
}> = createContext({
  id: "",
  companyName: "",
  urlName: "",
});

export const KanbanView: React.FC<KanbanViewProps> = ({
  viewer,
  organization,
  selectedFilters,
}) => {
  // use router
  const { history } = useRouter();
  const [infinityScrollState, setInfinityScrollState] =
    useState<InfiniteScrollStateType>({
      [SwimlaneId.PURCHASE_REQUEST]: {
        hasNextPage: false,
        previousCursor: null,
      },
      [SwimlaneId.RFX]: {
        hasNextPage: false,
        previousCursor: null,
      },
      [SwimlaneId.PURCHASE_ORDER]: {
        hasNextPage: false,
        previousCursor: null,
      },
      [SwimlaneId.RECEIVING]: {
        hasNextPage: false,
        previousCursor: null,
      },
      [SwimlaneId.INVOICE]: {
        hasNextPage: false,
        previousCursor: null,
      },
    });

  // extract variables for useEffect dependencies
  const selectedProject = selectedFilters?.project;
  const selectedAssignee = selectedFilters?.assignee;
  const selectedDepartment = selectedFilters?.department;
  const selectedSupplier = selectedFilters?.supplier;
  const selectedDateRange = selectedFilters?.dateRange;

  const filter = {
    userId: selectedAssignee?.id || null,
    projectId: selectedProject?.id || null,
    supplierId: selectedSupplier?.id || null,
    departmentId: selectedDepartment?.id || null,
    dateRange: selectedDateRange || null,
    status: null,
  };
  const variables = {
    organizationId: organization.id,
    take: 10,
    skip: null,
    cursor: null,
    filter,
  };
  // request kanban info
  const {
    data: kanbanData,
    error: kanbanDataError,
    loading: kanbanDataLoading,
    refetch: refetchKanbanView,
  } = useKanbanView({
    variables,
  });

  // request purchase request info
  const {
    data: purchaseRequestData,
    error: purchaseRequestDataError,
    loading: purchaseRequestDataLoading,
    refetch: refetchPurchaseRequests,
    fetchMore: fetchMorePurchaseRequests,
  } = usePaginatedPurchaseRequests({
    variables,
  });

  const hasMorePurchaseRequests =
    purchaseRequestData && purchaseRequestData.viewer
      ? purchaseRequestData?.viewer?.organization.paginatedPurchaseRequests
          .pageInfo.hasNextPage
      : false;

  // request rfx info
  const {
    data: rfxData,
    error: rfxDataError,
    loading: rfxDataLoading,
    refetch: refetchRfx,
    fetchMore: fetchMoreRfx,
  } = usePaginatedRfx({
    variables,
  });

  const hasMoreRfx =
    rfxData && rfxData.viewer
      ? rfxData.viewer.organization.paginatedRfx.pageInfo.hasNextPage
      : false;

  // request order info
  const {
    data: orderData,
    error: orderDataError,
    loading: orderDataLoading,
    refetch: refetchOrders,
    fetchMore: fetchMoreOrders,
  } = usePaginatedOrdersQuery({
    variables,
  });

  const hasMoreOrders =
    orderData && orderData.viewer
      ? orderData.viewer.organization.paginatedOrders.pageInfo.hasNextPage
      : false;

  // request receiving info
  const {
    data: receivingData,
    error: receivingDataError,
    loading: receivingDataLoading,
    refetch: refetchReceiving,
    fetchMore: fetchMoreReceiving,
  } = usePaginatedReceivingQuery({
    variables,
  });

  const hasMoreReceiving =
    receivingData && receivingData.viewer
      ? receivingData.viewer.organization.paginatedReceiving.pageInfo
          .hasNextPage
      : false;

  // request invoice info
  const {
    data: invoicesData,
    error: invoicesDataError,
    loading: invoicesDataLoading,
    refetch: refetchInvoices,
    fetchMore: fetchMoreInvoices,
  } = usePaginatedInvoicesQuery({
    variables,
  });

  const hasMoreInvoices =
    invoicesData && invoicesData.viewer
      ? invoicesData.viewer.organization.paginatedInvoices.pageInfo.hasNextPage
      : false;

  // setup assigning card
  const [doAssignCard] = useAssignCard({
    refetchQueries: [
      "PaginatedPurchaseRequests",
      "PaginatedRfx",
      "PaginatedOrders",
      "PaginatedReceiving",
      "PaginatedInvoices",
    ],
    awaitRefetchQueries: true,
  });

  // latest activity from LatestActivityContext
  const latestActivity = useLatestActivity();

  // refetch data if new subscription events occur or filters change
  useEffect(() => {
    refetchKanbanView();
    refetchPurchaseRequests();
    refetchRfx();
    refetchOrders();
    refetchReceiving();
    refetchInvoices();
  }, [
    latestActivity,
    selectedProject,
    selectedAssignee,
    selectedDepartment,
    selectedSupplier,
    refetchKanbanView,
    refetchPurchaseRequests,
    refetchRfx,
    refetchOrders,
    refetchReceiving,
    refetchInvoices,
  ]);

  // opens edit purchase request item card modal
  const showEditPurchaseRequestItem = (
    _swimlaneId: string,
    cardInfo: CardInfo,
  ) =>
    history.push(
      cardInfo.departmentCode
        ? `/${organization.urlName}/${cardInfo.prefix}-${cardInfo.departmentCode}-${cardInfo.code}`
        : `/${organization.urlName}/${cardInfo.prefix}-${cardInfo.code}`,
    );

  // setup state
  const [swimlanes, setSwimlanes] = useState<SwimlaneInfo[]>([]);
  const [assigningCardId, setAssigningCardId] = useState<string | undefined>(
    undefined,
  );
  const [draggedItemSwimlaneId, setDraggedItemSwimlaneId] = useState<
    SwimlaneId | undefined
  >(undefined);
  const [loadingSwimlaneId, setLoadingSwimlaneId] = useState<
    SwimlaneId | undefined
  >(undefined);
  const [, setErrorMessage] = useState<string | undefined>(undefined);

  // setup creating rfx from purchase request card
  const [createRfxFromPurchaseRequest] = useCreateRfxFromPurchaseRequest({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedRfx"],
    awaitRefetchQueries: true,
  });

  // setup creating order from rfx card
  const [createOrderFromRfx] = useCreateOrderFromRfx({
    refetchQueries: ["PaginatedRfx", "PaginatedOrders"],
    awaitRefetchQueries: true,
  });

  // setup creating order from purchase request
  const [createOrderFromPurchaseRequest] = useCreateOrderFromPurchaseRequest({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedOrders"],
    awaitRefetchQueries: true,
  });

  // setup creating order from purchase request items
  const [createOrderFromPurchaseRequestItems] =
    useCreateOrderFromPurchaseRequestItems({
      refetchQueries: ["PaginatedPurchaseRequests", "PaginatedOrders"],
      awaitRefetchQueries: true,
    });

  // setup adding purchase request items to order
  const [addPurchaseRequestItemsToOrder] = useAddPurchaseRequestItemsToOrder({
    refetchQueries: ["PaginatedOrders"],
    awaitRefetchQueries: true,
  });

  // setup updating order status
  const [updateOrderType] = useUpdateOrderType({
    refetchQueries: [
      "PaginatedOrders",
      "PaginatedReceiving",
      "PaginatedInvoices",
    ],
    awaitRefetchQueries: true,
  });

  // setup creating rfx from purchase request items
  const [createRfxFromPurchaseRequestItems] =
    useCreateRfxFromPurchaseRequestItems({
      refetchQueries: ["PaginatedPurchaseRequests", "PaginatedRfx"],
      awaitRefetchQueries: true,
    });

  // setup adding purchase request item to rfx
  const [addPurchaseRequestItemsToRfx] = useAddPurchaseRequestItemsToRfx({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedRfx"],
    awaitRefetchQueries: true,
  });

  // setup updating card positition (refetch is not really necessary)
  const [updateCardPosition] = useUpdateCardPosition();

  // attempts to nominate requested rfx supplier
  const assignCard = async (
    itemId: string,
    assigneeId: string | null,
    type: CardTypeEnum,
  ) => {
    setAssigningCardId(itemId);
    removeFocus();

    try {
      await doAssignCard({ variables: { itemId, assigneeId, type } });
    } finally {
      setAssigningCardId(undefined);
    }
  };

  // handle assigning card
  const onAssignCard: OnAssignCard = (swimlaneId, card, user) => {
    assignCard(card.id, user.id, getCardType(swimlaneId));
  };

  // handle assigning card
  const onUnassignCard: OnUnassignCard = (swimlaneId, card) => {
    assignCard(card.id, null, getCardType(swimlaneId));
  };

  const handleLoadMorePurchaseRequests = () => {
    // if initial request returns no data, do not try to fetch more
    if (!purchaseRequestData || !purchaseRequestData.viewer) {
      return;
    }

    const { endCursor, hasNextPage } =
      purchaseRequestData.viewer.organization.paginatedPurchaseRequests
        .pageInfo;
    const previousCursor =
      infinityScrollState[SwimlaneId.PURCHASE_REQUEST].previousCursor;

    // if new previous cursor matches with new one, do not attempt to fetch more data
    if (endCursor === previousCursor || !endCursor) {
      return;
    }

    // update previous cursor
    setInfinityScrollState({
      ...infinityScrollState,
      [SwimlaneId.PURCHASE_REQUEST]: {
        hasNextPage,
        previousCursor: endCursor,
      },
    });

    if (hasNextPage && endCursor) {
      fetchMorePurchaseRequests({
        variables: { take: 10, cursor: endCursor, filter },
        updateQuery: (
          prevResult: PaginatedPurchaseRequestsQuery,
          { fetchMoreResult },
        ) => {
          if (!fetchMoreResult || !fetchMoreResult.viewer) {
            return prevResult;
          }

          const previousEdges =
            prevResult && prevResult.viewer
              ? prevResult.viewer.organization.paginatedPurchaseRequests.edges
              : [];

          const newEdges =
            fetchMoreResult.viewer.organization.paginatedPurchaseRequests.edges;

          fetchMoreResult.viewer.organization.paginatedPurchaseRequests.edges =
            [...previousEdges, ...newEdges];

          return fetchMoreResult;
        },
      });
    }
  };

  const handleLoadMoreRfx = () => {
    // if initial request returns no data, do not try to fetch more
    if (!rfxData || !rfxData.viewer) {
      return;
    }

    const { endCursor, hasNextPage } =
      rfxData.viewer.organization.paginatedRfx.pageInfo;
    const previousCursor = infinityScrollState[SwimlaneId.RFX].previousCursor;

    // if new previous cursor matches with new one, do not attempt to fetch more data
    if (endCursor === previousCursor || !endCursor) {
      return;
    }

    // update previous cursor
    setInfinityScrollState({
      ...infinityScrollState,
      [SwimlaneId.RFX]: {
        hasNextPage,
        previousCursor: endCursor,
      },
    });

    if (hasNextPage && endCursor) {
      fetchMoreRfx({
        variables: { take: 10, cursor: endCursor, filter },
        updateQuery: (prevResult: PaginatedRfxQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.viewer) {
            return prevResult;
          }

          const previousEdges =
            prevResult && prevResult.viewer
              ? prevResult.viewer.organization.paginatedRfx.edges
              : [];

          const newEdges =
            fetchMoreResult.viewer.organization.paginatedRfx.edges;

          fetchMoreResult.viewer.organization.paginatedRfx.edges = [
            ...previousEdges,
            ...newEdges,
          ];

          return fetchMoreResult;
        },
      });
    }
  };

  const handleLoadMoreOrders = () => {
    // if initial request returns no data, do not try to fetch more
    if (!orderData || !orderData.viewer) {
      return;
    }

    const { endCursor, hasNextPage } =
      orderData.viewer.organization.paginatedOrders.pageInfo;
    const previousCursor =
      infinityScrollState[SwimlaneId.PURCHASE_ORDER].previousCursor;

    // if new previous cursor matches with new one, do not attempt to fetch more data
    if (endCursor === previousCursor || !endCursor) {
      return;
    }

    // update previous cursor
    setInfinityScrollState({
      ...infinityScrollState,
      [SwimlaneId.PURCHASE_ORDER]: {
        hasNextPage,
        previousCursor: endCursor,
      },
    });

    if (hasNextPage && endCursor) {
      fetchMoreOrders({
        variables: { take: 10, cursor: endCursor, filter },
        updateQuery: (
          prevResult: PaginatedOrdersQuery,
          { fetchMoreResult },
        ) => {
          if (!fetchMoreResult || !fetchMoreResult.viewer) {
            return prevResult;
          }

          const previousEdges =
            prevResult && prevResult.viewer
              ? prevResult.viewer.organization.paginatedOrders.edges
              : [];

          const newEdges =
            fetchMoreResult.viewer.organization.paginatedOrders.edges;

          fetchMoreResult.viewer.organization.paginatedOrders.edges = [
            ...previousEdges,
            ...newEdges,
          ];

          return fetchMoreResult;
        },
      });
    }
  };

  const handleLoadMoreReceiving = () => {
    // if initial request returns no data, do not try to fetch more
    if (!receivingData || !receivingData.viewer) {
      return;
    }

    const { endCursor, hasNextPage } =
      receivingData.viewer.organization.paginatedReceiving.pageInfo;
    const previousCursor =
      infinityScrollState[SwimlaneId.RECEIVING].previousCursor;

    // if new previous cursor matches with new one, do not attempt to fetch more data
    if (endCursor === previousCursor || !endCursor) {
      return;
    }

    // update previous cursor
    setInfinityScrollState({
      ...infinityScrollState,
      [SwimlaneId.RECEIVING]: {
        hasNextPage,
        previousCursor: endCursor,
      },
    });

    if (hasNextPage && endCursor) {
      fetchMoreReceiving({
        variables: { take: 10, cursor: endCursor, filter },
        updateQuery: (
          prevResult: PaginatedReceivingQuery,
          { fetchMoreResult },
        ) => {
          if (!fetchMoreResult || !fetchMoreResult.viewer) {
            return prevResult;
          }

          const previousEdges =
            prevResult && prevResult.viewer
              ? prevResult.viewer.organization.paginatedReceiving.edges
              : [];

          const newEdges =
            fetchMoreResult.viewer.organization.paginatedReceiving.edges;

          fetchMoreResult.viewer.organization.paginatedReceiving.edges = [
            ...previousEdges,
            ...newEdges,
          ];

          return fetchMoreResult;
        },
      });
    }
  };

  const handleLoadMoreInvoices = () => {
    // if initial request returns no data, do not try to fetch more
    if (!invoicesData || !invoicesData.viewer) {
      return;
    }

    const { endCursor, hasNextPage } =
      invoicesData.viewer.organization.paginatedInvoices.pageInfo;
    const previousCursor =
      infinityScrollState[SwimlaneId.INVOICE].previousCursor;

    // if new previous cursor matches with new one, do not attempt to fetch more data
    if (endCursor === previousCursor || !endCursor) {
      return;
    }

    // update previous cursor
    setInfinityScrollState({
      ...infinityScrollState,
      [SwimlaneId.INVOICE]: {
        hasNextPage,
        previousCursor: endCursor,
      },
    });

    if (hasNextPage && endCursor) {
      fetchMoreInvoices({
        variables: { take: 10, cursor: endCursor, filter },
        updateQuery: (
          prevResult: PaginatedInvoicesQuery,
          { fetchMoreResult },
        ) => {
          if (!fetchMoreResult || !fetchMoreResult.viewer) {
            return prevResult;
          }

          const previousEdges =
            prevResult && prevResult.viewer
              ? prevResult.viewer.organization.paginatedInvoices.edges
              : [];

          const newEdges =
            fetchMoreResult.viewer.organization.paginatedInvoices.edges;

          fetchMoreResult.viewer.organization.paginatedInvoices.edges = [
            ...previousEdges,
            ...newEdges,
          ];

          return fetchMoreResult;
        },
      });
    }
  };

  // handle creating new card from dragged item
  const onCreateCardFromItem = async ({
    item,
    source,
    destination,
  }: OnCreateCardFromItemParameters) => {
    if (item.type !== DraggableType.ITEM) {
      return;
    }

    // handle creating RFX from PR item
    if (source.prefix === "PR" && destination.id === SwimlaneId.RFX) {
      // make sure the source PR is complete or approved
      if (
        !["PR READY", "APPROVED", "IN PROGRESS"].includes(source.status.text)
      ) {
        setErrorMessage(
          "Unable to create RFX from given item, the purchase request needs to be ready",
        );

        return;
      }

      // the item id is formatted as CARD_ID.ITEM_ID to make it unique, extract the actual purchase request item id
      const purchaseRequestItemId = item.id.split(".")[1];

      // set the currently loading swimlane
      setLoadingSwimlaneId(destination.id);

      try {
        // attempt to create rfx from the purchase request item
        await createRfxFromPurchaseRequestItems({
          variables: {
            purchaseRequestItems: [purchaseRequestItemId],
          },
        });
      } finally {
        // clear currently loading swimlane
        setLoadingSwimlaneId(undefined);
      }
    }
    // handle creating purchase order from PR item
    else if (
      source.prefix === "PR" &&
      destination.id === SwimlaneId.PURCHASE_ORDER
    ) {
      // make sure the source PR is complete or approved
      if (
        !["PR READY", "APPROVED", "IN PROGRESS"].includes(source.status.text)
      ) {
        setErrorMessage(
          "Unable to create RFX from given item, the purchase request needs to be ready",
        );

        return;
      }

      // the item id is formatted as CARD_ID.ITEM_ID to make it unique, extract the actual purchase request item id
      const purchaseRequestItemId = item.id.split(".")[1];

      // set the currently loading swimlane
      setLoadingSwimlaneId(destination.id);

      try {
        // attempt to create purchase order from the purchase request item
        await createOrderFromPurchaseRequestItems({
          variables: {
            purchaseRequestItems: [purchaseRequestItemId],
          },
        });
      } finally {
        // clear currently loading swimlane
        setLoadingSwimlaneId(undefined);
      }
    } else {
      // there shouldn't be an option to make the drop in configurations not handled above
      throw new Error(
        "Creating card from item for given configuration should not be possible",
      );
    }
  };

  // update swimlanes info if modals info changes (is loaded/refetched)
  useEffect(() => {
    if (!kanbanData || !kanbanData.viewer) {
      return;
    }

    // extract loaded info
    const purchaseRequests =
      purchaseRequestData && purchaseRequestData.viewer
        ? purchaseRequestData.viewer.organization.paginatedPurchaseRequests
            .edges
        : [];

    const rfxs =
      rfxData && rfxData.viewer
        ? rfxData.viewer.organization.paginatedRfx.edges
        : [];

    const orders =
      orderData && orderData.viewer
        ? orderData.viewer.organization.paginatedOrders.edges
        : [];

    const receivings =
      receivingData && receivingData.viewer
        ? receivingData.viewer.organization.paginatedReceiving.edges
        : [];

    const invoices =
      invoicesData && invoicesData.viewer
        ? invoicesData.viewer.organization.paginatedInvoices.edges
        : [];

    const { users } = kanbanData.viewer.organization;

    const usersWhoCanBeAssignedToCards =
      filterUsersWhoCanBeAssignedToCards(users);

    // update swimlanes
    setSwimlanes([
      {
        id: SwimlaneId.PURCHASE_REQUEST,
        type: DraggableType.CARD,
        acceptItemsFrom: [],
        title: "Purchase Requests",
        emptyMessage: "Create your first purchase request",
        items: purchaseRequests
          .filter(
            (request) => request.status !== PurchaseRequestStatusEnum.ARCHIVED,
          )
          .map((item) =>
            getPurchaseRequestCardInfo(
              item,
              usersWhoCanBeAssignedToCards,
              assigningCardId,
            ),
          ),
        headerColor: LANE_COLOR_PR_HEADER,
        bodyColor: LANE_COLOR_PR_BODY,
        large: true,
        onSelectCard: showEditPurchaseRequestItem,
        onAssignCard,
        onUnassignCard,
        onLoadMore: handleLoadMorePurchaseRequests,
        onHasNextPage: hasMorePurchaseRequests,
        onAdd: () => {
          history.push(`/${organization.urlName}/create-purchase-request`);
        },
      },
      {
        id: SwimlaneId.RFX,
        type: DraggableType.CARD,
        acceptItemsFrom: [SwimlaneId.PURCHASE_REQUEST],
        title: "Sourcing",
        emptyMessage: "Drag purchase request here to start RFX",
        createFromItemsMessage:
          "Drag purchase request item here to create a new RFX",
        items: rfxs.map((item) =>
          getRfxCardInfo(item, usersWhoCanBeAssignedToCards, assigningCardId),
        ),
        headerColor: LANE_COLOR_RFX_HEADER,
        bodyColor: LANE_COLOR_RFX_BODY,
        onSelectCard: showEditPurchaseRequestItem,
        onAssignCard,
        onUnassignCard,
        onLoadMore: handleLoadMoreRfx,
        onHasNextPage: hasMoreRfx,
      },
      {
        id: SwimlaneId.PURCHASE_ORDER,
        type: DraggableType.CARD,
        acceptItemsFrom: [SwimlaneId.PURCHASE_REQUEST],
        title: "Purchase Orders",
        emptyMessage:
          "Drag RFX or purchase request here to make a purchase order",
        createFromItemsMessage:
          "Drag purchase request item here to create a new purchase order",
        items: orders.map((item) =>
          getOrderCardInfo(item, usersWhoCanBeAssignedToCards, assigningCardId),
        ),
        headerColor: LANE_COLOR_ORDER_HEADER,
        bodyColor: LANE_COLOR_ORDER_BODY,
        onSelectCard: showEditPurchaseRequestItem,
        onAssignCard,
        onUnassignCard,
        onLoadMore: handleLoadMoreOrders,
        onHasNextPage: hasMoreOrders,
      },
      {
        id: SwimlaneId.RECEIVING,
        type: DraggableType.CARD,
        acceptItemsFrom: [],
        title: "Receiving",
        emptyMessage: "Drag purchase order here once receiving",
        items: receivings.map((item) =>
          getReceivingCardInfo(
            item,
            usersWhoCanBeAssignedToCards,
            assigningCardId,
          ),
        ),
        headerColor: LANE_COLOR_RECEIVING_HEADER,
        bodyColor: LANE_COLOR_RECEIVING_BODY,
        onSelectCard: showEditPurchaseRequestItem,
        onAssignCard,
        onUnassignCard,
        onLoadMore: handleLoadMoreReceiving,
        onHasNextPage: hasMoreReceiving,
      },
      {
        id: SwimlaneId.INVOICE,
        type: DraggableType.CARD,
        acceptItemsFrom: [],
        title: "Invoices",
        emptyMessage: "Drag receiving here once invoice has been received",
        items: invoices.map((item) =>
          getInvoiceCardInfo(
            item,
            usersWhoCanBeAssignedToCards,
            assigningCardId,
          ),
        ),
        headerColor: LANE_COLOR_INVOICES_HEADER,
        bodyColor: LANE_COLOR_INVOICES_BODY,
        onSelectCard: showEditPurchaseRequestItem,
        onAssignCard,
        onUnassignCard,
        onLoadMore: handleLoadMoreInvoices,
        onHasNextPage: hasMoreInvoices,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kanbanData,
    purchaseRequestData,
    rfxData,
    orderData,
    receivingData,
    invoicesData,
    assigningCardId,
    selectedFilters,
    selectedProject,
    selectedAssignee,
    selectedDepartment,
    selectedSupplier,
  ]);

  // handle error
  if (
    kanbanDataError ||
    purchaseRequestDataError ||
    rfxDataError ||
    orderDataError ||
    receivingDataError ||
    invoicesDataError
  ) {
    const possibleErrors = [
      kanbanDataError,
      purchaseRequestDataError,
      rfxDataError,
      orderDataError,
      receivingDataError,
      invoicesDataError,
    ];

    const error = possibleErrors.find((err) => err !== undefined);

    // typescript does not understand the type?
    if (error) {
      return <ErrorView error={error} />;
    }
  }

  // handle loading state
  if (
    kanbanDataLoading ||
    purchaseRequestDataLoading ||
    rfxDataLoading ||
    orderDataLoading ||
    receivingDataLoading ||
    invoicesDataLoading ||
    !kanbanData
  ) {
    return <LoadingView />;
  }

  // handle user not logged in
  if (!kanbanData.viewer) {
    return <Redirect to="/login" />;
  }

  // drag and drop handlers
  const handlers: DragDropContextProps = {
    children: null,
    onDragStart({ source, type, draggableId /*, mode*/ }) {
      const sourceDroppable = getDroppable(source.droppableId, swimlanes);
      // the source droppable should always exist
      if (!sourceDroppable) {
        throw new Error(
          "Drag source droppable could not be found, this should not happen",
        );
      }

      // handle dragging items
      if (type === DraggableType.ITEM) {
        // find the card swimlane
        const swimlane = getCardSwimlane(sourceDroppable.id, swimlanes);

        // the swimlane should exist
        if (!swimlane) {
          throw new Error(
            `Dragged item "${draggableId}" source droppable "${source.droppableId}" parent could not be found, this should not happen`,
          );
        }

        // set currently dragged
        setDraggedItemSwimlaneId(swimlane.id as SwimlaneId);
      }
    },
    onDragEnd: async ({ source, destination, draggableId /*, reason*/ }) => {
      // not dragging any more, reset drag state
      setDraggedItemSwimlaneId(undefined);

      // don't do anything if the item was dragged outside of any droppables
      if (!destination) {
        return;
      }

      // don't do anything if nothing changed
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      // this will contain updated swimlanes info
      const updatedSwimlanes = [...swimlanes];

      // find the source droppable
      const sourceDroppable = getDroppable(
        source.droppableId,
        updatedSwimlanes,
      );

      // handle source lane not found, should not really happen
      if (!sourceDroppable) {
        throw new Error(
          `Source lane "${source.droppableId}" could not be found, this should not happen`,
        );
      }

      // find dragged item
      const draggedItem = sourceDroppable.items.find(
        (item) => item.id === draggableId,
      );

      // handle dragged item not found, should not really happen
      if (!draggedItem) {
        throw new Error(
          `Source item "${draggableId}" could not be found, this should not happen`,
        );
      }

      const destinationSuffix = destination.droppableId.substring(
        destination.droppableId.length - 4,
      );

      // handle creating card from items
      if (destinationSuffix === "-new") {
        const baseDestinationId = destination.droppableId.substring(
          0,
          destination.droppableId.length - 4,
        );
        const destinationDroppable = getDroppable(
          baseDestinationId,
          updatedSwimlanes,
        );

        // handle destination lane not found, should not really happen
        if (!destinationDroppable) {
          throw new Error(
            `Destination lane "${destination.droppableId}" could not be found, this should not happen`,
          );
        }

        // call the create card from items callback
        onCreateCardFromItem({
          item: draggedItem as CardItemInfo,
          source: sourceDroppable as CardInfo,
          destination: destinationDroppable as SwimlaneInfo,
        });

        return;
      }

      // find the destination droppable
      const destinationDroppable = getDroppable(
        destination.droppableId,
        updatedSwimlanes,
      );

      // handle destination droppable not found, should not really happen
      if (!destinationDroppable) {
        throw new Error(
          `Destination droppable "${destination.droppableId}" could not be found, this should not happen`,
        );
      }

      // resolve relative item and method (if dragged to first position, no update is needed)
      const isDraggedToFirstPosition = destination.index === 0;
      const relativeCardIndex = isDraggedToFirstPosition
        ? 0
        : sourceDroppable.id === destinationDroppable.id
        ? destination.index
        : destination.index - 1;
      const relativeItem =
        destinationDroppable.items[relativeCardIndex] !== undefined
          ? destinationDroppable.items[relativeCardIndex]
          : undefined;
      const relativeMethod = isDraggedToFirstPosition
        ? CardPositionMethodEnum.BEFORE
        : CardPositionMethodEnum.AFTER;

      // performs the drop operation
      const performDrop = () => {
        // remove item from source lane
        sourceDroppable.items.splice(source.index, 1);

        // add the item to the destination lane at requested index
        destinationDroppable.items.splice(destination.index, 0, draggedItem);

        // update data
        setSwimlanes(updatedSwimlanes);
      };

      // perform reverse of the drop operation
      const undoDrop = () => {
        destinationDroppable.items.splice(destination.index, 1);
        sourceDroppable.items.splice(source.index, 0, draggedItem);
        setSwimlanes([...updatedSwimlanes]);
      };

      // optimistically perform the drop operation in advance, this may get un-done if persist fails
      performDrop();

      // attempt to persist the drag result (returns true for validation success, string error message for fail)
      try {
        const { isSuccess: isDragSuccess, error: dragErrorMessage } =
          await persistKanbanDrag({
            from: sourceDroppable,
            to: destinationDroppable,
            draggedItem,
            relativeItem,
            relativeMethod,
            swimlanes,
            mutations: {
              createRfxFromPurchaseRequest,
              createOrderFromRfx,
              createOrderFromPurchaseRequest,
              createOrderFromPurchaseRequestItems,
              updateOrderType,
              addPurchaseRequestItemsToRfx,
              addPurchaseRequestItemsToOrder,
              updateCardPosition,
            },
            setLoadingSwimlaneId,
          });

        // handle drag failure (either client side validation or server error)
        if (!isDragSuccess) {
          setErrorMessage(dragErrorMessage);
          undoDrop();
        }
      } catch (error) {
        console.warn("persisting kanban drag failed", error);

        setErrorMessage("Requested operation failed, please try again later.");
        undoDrop();
      }
    },
  };

  return (
    <>
      <OrganizationContext.Provider value={organization}>
        <DragDropContext {...handlers}>
          <View className={styles.kanban}>
            {
              <AnimatedList
                className={styles["update-notice-container"]}
                nextRender={
                  latestActivity &&
                  latestActivity.author?.id !== viewer.id &&
                  latestActivity.type ===
                    ActivityTypeEnum.CREATE_PURCHASE_REQUEST
                    ? {
                        id: latestActivity.id,
                        component: (
                          <UpdateNotice
                            {...getUpdateNoticeByActivity({
                              activity: latestActivity,
                            })}
                          />
                        ),
                      }
                    : undefined
                }
              />
            }

            <Swimlanes>
              {swimlanes.map((swimlane) => {
                const swimlaneRefCallback = (element: HTMLElement) => {
                  // measure swimlane contents scrollable length in label
                  const scrollableLength =
                    element.scrollHeight - element.clientHeight;

                  sendGoogleAnalyticsEvent({
                    category: "Teams Boards",
                    action: `scroll_teams-boards-${swimlane.id}`,
                    label: scrollableLength,
                  });
                };
                return (
                  <Swimlane
                    data-testid={swimlane.id}
                    key={swimlane.id}
                    info={swimlane}
                    loading={swimlane.id === loadingSwimlaneId}
                    isAcceptingCreateFromItem={
                      draggedItemSwimlaneId !== undefined &&
                      swimlane.acceptItemsFrom.includes(draggedItemSwimlaneId)
                    }
                    organizationId={organization.id}
                    refCallback={swimlaneRefCallback}
                  />
                );
              })}
            </Swimlanes>
            <Sidebar openOnTop title="Collaboration feed">
              {/* <SidebarFilter title="Show only Updates" onClick={() => alertNotImplemented()} /> */}
              <Separator />
              <KanbanCollaborationSidebar organizationId={organization.id} />
            </Sidebar>
          </View>
          <Switch>
            <Route path={[`/${organization.urlName}/create-purchase-request`]}>
              <CreatePurchaseRequestView
                viewer={viewer}
                organization={organization}
                onModalClose={() => history.replace(`/${organization.urlName}`)}
              />
            </Route>
            <Route
              path={[
                `/${organization.urlName}/edit-:itemType(product|service)/:modalType(PR)-:code(.+)/item-:itemId(.+)`,
                `/${organization.urlName}/edit-:itemType(product|service)/:modalType(RFX)-:code(.+)/item-:itemId(.+)`,
                `/${organization.urlName}/edit-:itemType(product|service)/:modalType(PO)-:code(.+)/item-:itemId(.+)`,
              ]}
            >
              <EditPurchaseRequestItemView
                viewer={viewer}
                organization={organization}
                onModalClose={() => history.replace(`/${organization.urlName}`)}
              />
            </Route>

            <Route
              path={[
                `/${organization.urlName}/upload-items-excel/PR-:purchaseRequestCode`,
              ]}
            >
              <UploadItemsExcelToPurchaseRequest
                organization={organization}
                onModalClose={() => history.replace(`/${organization.urlName}`)}
              />
            </Route>

            <Route path={`/${organization.urlName}/PR-:code`}>
              {({ match }) => (
                <PurchaseRequestView
                  viewer={viewer}
                  organization={organization}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  code={match!.params.code}
                  onModalClose={() =>
                    history.replace(`/${organization.urlName}`)
                  }
                />
              )}
            </Route>

            <Route path={`/${organization.urlName}/RF(X|I|Q|P)-:code`}>
              {({ match }) => (
                <RfxView
                  viewer={viewer}
                  organization={organization}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  code={match!.params.code}
                  onModalClose={() =>
                    history.replace(`/${organization.urlName}`)
                  }
                />
              )}
            </Route>
            <Route path={`/${organization.urlName}/PO-:departmentCode-:code`}>
              {({ match }) => (
                <OrderProgressView
                  viewer={viewer}
                  organization={organization}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  code={match!.params.code}
                  onModalClose={() =>
                    history.replace(`/${organization.urlName}`)
                  }
                />
              )}
            </Route>
            <Route path={`/${organization.urlName}/PO-:code`}>
              {({ match }) => (
                <OrderProgressView
                  viewer={viewer}
                  organization={organization}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  code={match!.params.code}
                  onModalClose={() =>
                    history.replace(`/${organization.urlName}`)
                  }
                />
              )}
            </Route>
          </Switch>
        </DragDropContext>
      </OrganizationContext.Provider>

      <Switch>
        <Route exact path={`/${organization.urlName}/email/:activityId`}>
          <EmailView organizationId={organization.id} />
        </Route>
      </Switch>
    </>
  );
};

function getCardType(swimlaneId: string): CardTypeEnum {
  switch (swimlaneId) {
    case SwimlaneId.PURCHASE_REQUEST:
      return CardTypeEnum.PURCHASE_REQUEST;

    case SwimlaneId.RFX:
      return CardTypeEnum.RFX;

    case SwimlaneId.PURCHASE_ORDER:
    case SwimlaneId.RECEIVING:
    case SwimlaneId.INVOICE:
      return CardTypeEnum.ORDER;

    default:
      throw new Error(
        `Unable to map unexpected swimlane id "${swimlaneId}" to card type`,
      );
  }
}

export function filterUsersWhoCanBeAssignedToCards(users: UserInfoFragment[]) {
  return users.filter(
    (user) =>
      user.roles.some((role) =>
        [
          UserRoleEnum.ADMIN,
          UserRoleEnum.KEY_USER,
          UserRoleEnum.BUYER,
        ].includes(role),
      ) && user.status === UserStatusEnum.ACTIVE,
  );
}
