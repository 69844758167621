import { CurrencyEnum } from "../schema";
import { PriceItem } from "./calculateItemPriceTotals";
import { isDefined } from "./isDefined";

/**
 * Used to calculate row total values
 *
 */
export function calculateRowTotal({
  quantity,
  unitPrice,
  currency = CurrencyEnum.EUR,
}: PriceItem) {
  const numericQuantity =
    typeof quantity === "string"
      ? parseFloat(quantity)
      : typeof quantity === "number"
      ? quantity
      : undefined;
  const numericPrice =
    typeof unitPrice === "string"
      ? parseFloat(unitPrice)
      : typeof unitPrice === "number"
      ? unitPrice
      : undefined;

  // if price is not defined return 0 as sum
  if (!isDefined(numericPrice) || Number.isNaN(numericPrice)) {
    return "...";
  }

  // if quantity is not defined return unit price as sum (case of services)
  if (!isDefined(numericQuantity) || Number.isNaN(numericQuantity)) {
    return `${Number(numericPrice.toFixed(2)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    })} ${currency}`;
  }

  // otherwise multiply the two
  return `${Number((numericPrice * numericQuantity).toFixed(2)).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
    },
  )} ${currency}`;
}
