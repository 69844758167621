import * as React from "react";
export const EnvelopeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="6fb7c1ce-2ab3-48c1-835c-c6aae30c108a"
    viewBox="0 0 504.46 393.3"
    {...props}
  >
    <path d="M475.46 0H29A29 29 0 000 29v335.3a29 29 0 0029 29h446.46a29 29 0 0029-29V29a29 29 0 00-29-29zm-112 58L252.33 169.17 141.16 58zm83 277.3H58V58h1.13l172.69 172.69a29 29 0 0041 0L445.53 58h.93z" />
  </svg>
);
