import React, { CSSProperties } from "react";
import classNames from "classnames";
import { RenderElementProps } from "slate-react";
import { sanitize } from "dompurify";
import { Element } from "slate";
import styles from "./LinkElement.module.scss";

export interface LinkElementProps extends RenderElementProps {
  className?: string;
  element: LinkElementType;
  style?: CSSProperties;
}

export type LinkElementType = Element & { type: "link"; url: string };

export const LinkElement: React.FC<LinkElementProps> = (props) => {
  const { className, attributes, children, element, style } = props;

  return (
    <a
      style={style}
      href={`${sanitize(element.url)}`}
      className={classNames(styles["wrap"], className)}
      {...attributes}
    >
      {children}
    </a>
  );
};

// helper that determines if an element is a LinkElement
export function isLinkElement(element: Element): element is LinkElementType {
  return element.type === "link";
}
