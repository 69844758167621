import {
  RfxByCodeRfxByCode,
  RfxByCodeSuppliers,
  RfxSupplierResolutionEnum,
  RfxTypeEnum,
} from "../schema";

export function getSuppliersSelectedForCurrentRfxType(
  rfx: RfxByCodeRfxByCode,
  suppliersWithResolution: (RfxByCodeSuppliers & {
    resolution: RfxSupplierResolutionEnum | null;
  })[],
) {
  return suppliersWithResolution?.filter((swr) => {
    switch (swr.resolution) {
      case RfxSupplierResolutionEnum.NOMINATE_TO_PO: {
        return true;
      }

      case RfxSupplierResolutionEnum.NOMINATE_TO_RFQ: {
        return rfx?.type &&
          [RfxTypeEnum.RFI, RfxTypeEnum.RFP, RfxTypeEnum.RFQ].includes(rfx.type)
          ? true
          : false;
      }

      case RfxSupplierResolutionEnum.NOMINATE_TO_RFP: {
        return rfx?.type &&
          [RfxTypeEnum.RFI, RfxTypeEnum.RFP].includes(rfx.type)
          ? true
          : false;
      }

      case null: {
        return true;
      }

      default:
        return false;
    }
  });
}
