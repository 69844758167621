import { UserRoleEnum } from "../schema";
import { assertUnreachable } from "./assertUnreachable";

export function formatRole(role: UserRoleEnum): string {
  switch (role) {
    case UserRoleEnum.ADMIN:
      return "Admin";

    case UserRoleEnum.KEY_USER:
      return "Key User";

    case UserRoleEnum.BUYER:
      return "Buyer";

    case UserRoleEnum.REQUESTER:
      return "Requester";

    case UserRoleEnum.APPROVER:
      return "Approver";

    case UserRoleEnum.SUPPLIER:
      return "Supplier";

    default:
      return assertUnreachable(
        role,
        `User role "${role}" is not handled, this should not happen`,
      );
  }
}
