import {
  mergeDeepWith,
  concat,
  isEmpty,
  propEq,
  lens,
  find,
  findIndex,
  update,
  compose,
  lensProp,
  Lens,
  over,
} from "ramda";
import { RfxByCodeSourcingRequests } from "../../schema";
import { SourcingEventTemplate } from "../RfqEventForm/RfqEventForm";

interface RfxByCodeSourcingRequestWithTotalPrice
  extends RfxByCodeSourcingRequests {
  totalPrice?: string;
  isTotalPriceWinner?: boolean;
}

/**
 * Function that appends information about winning (minimal price) offers per purchase request items
 * */
export function addSourcingRequestWinners(
  sourcingRequests: RfxByCodeSourcingRequests[],
) {
  // Step 1: List items with all offered prices by quantities keyed by sourcing request ID
  const itemsWithOffers = sourcingRequests.reduce<{
    [itemId: string]: {
      [quantity: string]: { [sourcingRequestId: string]: string | null };
    };
  }>((sourcingReqAcc, currSourcingRequest) => {
    const currentSourcingRequestItems = (
      currSourcingRequest.quotation?.items as SourcingEventTemplate["items"]
    ).reduce<{
      [itemId: string]: {
        [quantity: string]: { [sourcingRequestId: string]: string | null };
      };
    }>((itemAcc, currItem) => {
      const currentItemPriceInfo = currItem.priceInfo.reduce<{
        [quantity: string]: { [sourcingRequestId: string]: string | null };
      }>((priceInfoAcc, currPriceInfo) => {
        return {
          ...priceInfoAcc,
          ...{
            [currPriceInfo.quantity ? currPriceInfo.quantity.toString() : "1"]:
              {
                [currSourcingRequest.id]: currPriceInfo.price
                  ? currPriceInfo.price.toString()
                  : null,
              },
          },
        };
      }, {});

      return { ...itemAcc, ...{ [currItem.id]: currentItemPriceInfo } };
    }, {});

    return mergeDeepWith(concat, sourcingReqAcc, currentSourcingRequestItems);
  }, {});

  // Step 2: For each item's quantity, find the minimal price offer(s)
  // example output:
  // {"itemID1": { 10: ["sourcingRequestID1"], 20: ["sourcingRequestID2"]  }, "itemID2": { 100: ["sourcingRequestID1", "sourcingRequestID2"] }}
  const winnerOffersPerItemQuantity = Object.entries(itemsWithOffers).reduce<{
    [itemId: string]: { [quantity: string]: string[] };
  }>((itemAcc, [itemId, offersByQuantity]) => {
    const minUnitPriceOffersPerQuantity = Object.entries(
      offersByQuantity,
    ).reduce<{
      [quantity: string]: string[];
    }>((quantityAcc, [quantity, offers]) => {
      // convert offers' prices to numeric values
      const offersWithNumericPrice = Object.entries(offers).reduce<{
        [sourcingRequestId: string]: number;
      }>(
        (a, [k, v]) => ({
          ...a,
          ...(v !== null && !isEmpty(v) ? { [k]: Number(v) } : {}),
        }),
        {},
      );

      // find the min value of the prices
      const minOfferPrice = Math.min(...Object.values(offersWithNumericPrice));

      return {
        ...quantityAcc,
        ...{
          [quantity]: getKeysByValue(offersWithNumericPrice, minOfferPrice),
        },
      };
    }, {});

    // accumulate for each item ID its corresponding min priced offer IDs
    return { ...itemAcc, ...{ [itemId]: minUnitPriceOffersPerQuantity } };
  }, {});

  // Step 3. For each winning sourcing request priceInfo append field `isRowWinner` to signify it
  const sourcingRequestsWithWinners = sourcingRequests.reduce<
    RfxByCodeSourcingRequests[]
  >((sourcingRequestsAcc, currSourcingRequest) => {
    const wonItemByQuantityIdsForCurrentRequest = Object.entries(
      winnerOffersPerItemQuantity,
    ).reduce<{
      [itemId: string]: string[];
    }>((itemAcc, [itemId, quantityWinnerRequests]) => {
      const quantityWinner = Object.entries(quantityWinnerRequests).reduce<
        string[]
      >((acc, [quantity, requestIds]) => {
        return [
          ...acc,
          ...(requestIds.includes(currSourcingRequest.id) ? [quantity] : []),
        ];
      }, []);

      return {
        ...itemAcc,
        ...(isEmpty(quantityWinner) ? {} : { [itemId]: quantityWinner }),
      };
    }, {});

    // eslint-disable-next-line
    const modifiedSourcingRequest = Object.entries(
      wonItemByQuantityIdsForCurrentRequest,
    ).reduce<any>((modifiedRequestAcc, [itemId, winningQuantities]) => {
      const priceInfoLens = compose(
        lensProp("quotation"),
        lensProp("items"),
        lensWhere(propEq("id", itemId)),
        lensProp("priceInfo"),
      ) as Lens<any, any>;

      const reqWithModifiedItem = over(
        priceInfoLens,
        (
          priceInfo: { quantity: string; price: string; totalPrice: string }[],
        ) => {
          return priceInfo.map((pi) => {
            return winningQuantities.includes(pi.quantity)
              ? { ...pi, isRowWinner: true }
              : pi;
          });
        },
        modifiedRequestAcc,
      );

      return { ...modifiedRequestAcc, ...reqWithModifiedItem };
    }, currSourcingRequest);

    return [...sourcingRequestsAcc, modifiedSourcingRequest];
  }, []);

  // Step 4. Calculate total price for all of the items per supplier
  const sourcingRequestsWithTotalPrices =
    sourcingRequestsWithWinners.map<RfxByCodeSourcingRequestWithTotalPrice>(
      (sourcingRequest: RfxByCodeSourcingRequestWithTotalPrice) => {
        const quotation = sourcingRequest.quotation as SourcingEventTemplate;

        // calculate total price from priceInfo items
        sourcingRequest.totalPrice = quotation.items
          .reduce((totalPriceAcc, totalPriceCurrent) => {
            // calculate total for all quantities
            // const priceInfoTotals = totalPriceCurrent.priceInfo.reduce(
            //   (priceInfoAcc, priceInfoCurrent) => {
            //     // check if price exists
            //     // check if price is parseable
            //     if (
            //       !priceInfoCurrent.price ||
            //       !priceInfoCurrent.quantity ||
            //       (typeof priceInfoCurrent.price === "string" &&
            //         isNaN(parseFloat(priceInfoCurrent.price))) ||
            //       (typeof priceInfoCurrent.quantity === "string" &&
            //         isNaN(parseFloat(priceInfoCurrent.quantity)))
            //     ) {
            //       return priceInfoAcc;
            //     }

            //     // convert price if its string
            //     const price =
            //       typeof priceInfoCurrent.price === "string"
            //         ? parseFloat(priceInfoCurrent.price)
            //         : priceInfoCurrent.price;

            //     // convert quantity if its string
            //     const quantity =
            //       typeof priceInfoCurrent.quantity === "string"
            //         ? parseFloat(priceInfoCurrent.quantity)
            //         : priceInfoCurrent.quantity;

            //     return priceInfoAcc + price * quantity;
            //   },
            //   0,
            // );

            const firstQuantityPriceInfo = totalPriceCurrent.priceInfo[0]; // get only first quantity

            // convert price if its string
            const price =
              typeof firstQuantityPriceInfo.price === "string"
                ? parseFloat(firstQuantityPriceInfo.price)
                : typeof firstQuantityPriceInfo.price === "number"
                ? firstQuantityPriceInfo.price
                : 0;

            // convert quantity if its string
            const quantity =
              typeof firstQuantityPriceInfo.quantity === "string"
                ? parseFloat(firstQuantityPriceInfo.quantity)
                : typeof firstQuantityPriceInfo.quantity === "number"
                ? firstQuantityPriceInfo.quantity
                : 0;

            return totalPriceAcc + price * quantity;
          }, 0)
          .toString();

        return sourcingRequest;
      },
    );

  // 5. Append total price winner and convert total prices to string
  const lowestTotalPriceIndex = sourcingRequestsWithTotalPrices.findIndex(
    (sourcingRequest) => {
      const totalPrice = sourcingRequest.totalPrice
        ? parseFloat(sourcingRequest.totalPrice)
        : undefined;

      return (
        totalPrice &&
        totalPrice ===
          Math.min(
            ...sourcingRequestsWithTotalPrices.map((obj) =>
              obj.totalPrice ? parseFloat(obj.totalPrice) : 0,
            ),
          )
      );
    },
  );

  if (lowestTotalPriceIndex !== -1) {
    sourcingRequestsWithTotalPrices[lowestTotalPriceIndex].isTotalPriceWinner =
      true;
  }

  return sourcingRequestsWithTotalPrices;
}

/**
 * Utility that gets all object keys by matching value
 */
function getKeysByValue(object: { [key: string]: number }, value: number) {
  return Object.keys(object).filter((key) => object[key] === value);
}

/**
 *
 * @example
 * const arr = [{id: 1}, {id: 2}]
 *
 * const res = R.over(
 *   lensWhere(R.propEq('id', 2)),
 *   R.assoc('name', 'Yo'),
 *   arr); // res:  [{id: 1}, {id: 2, name: 'Yo'}]
 *
 */
// eslint-disable-next-line
function lensWhere(func: (a: any) => boolean) {
  return lens(find(func), (focus, target) =>
    update(findIndex(func, target), focus, target),
  );
}
