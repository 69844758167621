import React from "react";

export type UseKeyBoardEventProps = {
  el?: GlobalEventHandlers | HTMLInputElement;
  eventType: "keydown" | "keyup" | "keypress";
  key: string;
  handler: (e: KeyboardEvent) => void;
};

export function useKeyboardEvent(props: UseKeyBoardEventProps) {
  const { el = window, eventType, key, handler: callback } = props;

  React.useEffect(() => {
    function listener(e: KeyboardEvent) {
      if (typeof callback === "function" && e.key === key) {
        callback(e);
      }
    }

    el.addEventListener(eventType, listener);

    return () => el.removeEventListener(eventType, listener);
  }, [key, callback, el, eventType]);
}
