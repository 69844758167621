import React, { useEffect } from "react";
import classNames from "classnames";
import { Draggable } from "react-beautiful-dnd";
import { AttachmentIcon } from "../../theme/svg/AttachmentIcon";
import { CommentHollowIcon } from "../../theme/svg/CommentHollowIcon";
import { Avatar, AvatarUser } from "../Avatar/Avatar";
import { CardStatus, Status } from "../Status/Status";
import { BaseDraggable } from "../Swimlane/Swimlane";
import { useGetPoExternalIdentifierLazyQuery } from "../../schema";
import styles from "./CardItem.module.scss";

export interface CardItemInfo extends BaseDraggable {
  id: string;
  name: string;
  parentCode: string;
  code?: string;
  quantity?: number;
  unit?: string;
  assignee?: AvatarUser | null;
  status?: CardStatus;
  attachmentCount?: number;
  commentCount?: number;
  isDisabled?: boolean;
}

export interface CardItemProps {
  info: CardItemInfo;
  index: number;
  organizationId: string;
}

export const CardItem: React.FC<CardItemProps> = ({
  info,
  index,
  organizationId,
}) => {
  const {
    id,
    name,
    code,
    quantity,
    unit,
    assignee,
    status,
    attachmentCount,
    commentCount,
    isDisabled,
  } = info;

  // Modal code
  const codeModal = code && code.split("-")[0];
  const codeNumber = code && code.split("-")[1];

  // query for getting PO external identifier
  const [
    getExternalIdentifier,
    { data },
  ] = useGetPoExternalIdentifierLazyQuery();

  // query for PO external identifier if item is in PO or further
  useEffect(() => {
    if (codeModal === "PO" && codeNumber) {
      getExternalIdentifier({
        variables: { code: codeNumber, organizationId: organizationId },
      });
    }
  }, [data, getExternalIdentifier, codeModal, codeNumber, organizationId]);

  // extract data
  const externalIdentifier =
    data && data.viewer && data.viewer.orderByCode.externalIdentifier;

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDisabled}>
      {(
        { innerRef, draggableProps, dragHandleProps },
        { isDragging /*, isDropAnimating, mode, draggingOver*/ },
      ) => (
        <div
          data-testid="a8274b4fb0"
          className={classNames(styles.item, {
            [styles["item--disabled"]]: isDisabled,
            [styles["item--dragging"]]: isDragging,
          })}
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          onClick={(e) => e.stopPropagation()}
        >
          {status && (
            <div className={styles.item__status}>
              <Status secondary level={status.level} text={status.text} />
            </div>
          )}

          <div className={styles.item__section}>
            <span className={styles.item__name} title={name}>
              {name}
            </span>
            {quantity && unit && (
              <span
                className={styles.item__amount}
              >{`${quantity} ${unit}`}</span>
            )}
          </div>

          {attachmentCount !== undefined && (
            <div className={styles["icon-wrapper"]}>
              <AttachmentIcon className={styles["attachment-icon"]} />
              {attachmentCount}
            </div>
          )}

          {commentCount !== undefined && (
            <div className={styles["icon-wrapper"]}>
              <CommentHollowIcon className={styles["comments-icon"]} />
              {commentCount}
            </div>
          )}

          {externalIdentifier
            ? code && (
                <div className={styles.item__code}>
                  {externalIdentifier + " " + code}
                </div>
              )
            : code && <div className={styles.item__code}>{code}</div>}

          {assignee !== undefined && (
            <Avatar className={styles.avatar} user={assignee} />
          )}
        </div>
      )}
    </Draggable>
  );
};
