import React from "react";
import classNames from "classnames";
import styles from "./View.module.scss";

export interface ViewProps {
  className?: string;
  secondary?: boolean;
}

export const View: React.FC<ViewProps> = ({
  className,
  children,
  secondary,
}) => (
  <>
    {secondary ? (
      <div
        className={classNames(
          styles.view,
          styles["view--secondary"],
          className,
        )}
      >
        <div className={styles["content-wrap"]}>{children}</div>
      </div>
    ) : (
      <div className={classNames(styles.view, className)}>{children}</div>
    )}
  </>
);
