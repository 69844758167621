import React from "react";
import classNames from "classnames";
import {
  Slate,
  Editable,
  withReact,
  RenderLeafProps,
  RenderElementProps,
  DefaultElement,
} from "slate-react";
import { Node, createEditor } from "slate";
import { withHistory } from "slate-history";
import { FieldProps } from "../Field/Field";
import { EditableModeContext } from "../Form/Form";
import { isDefined } from "../../services/isDefined";
import fieldStyles from "../Field/Field.module.scss";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { FieldError } from "../FieldError/FieldError";
import styles from "./RichTextEditor.module.scss";
import {
  isCodeElement,
  CodeElement,
  CodeElementProps,
} from "./elements/CodeElement/CodeElement";
import {
  isParagraphElement,
  ParagraphElement,
  ParagraphElementProps,
} from "./elements/ParagraphElement/ParagraphElement";
import { Commands } from "./commands/Commands";
import { serializeToPlainText } from "./serializers/serializeToPlainText";
import {
  isQuoteElement,
  QuoteElement,
  QuoteElementProps,
} from "./elements/QuoteElement/QuoteElement";
import {
  isLinkElement,
  LinkElement,
  LinkElementProps,
} from "./elements/LinkElement/LinkElement";
import { CustomLeaf } from "./leaves/CustomLeaf/CustomLeaf";

export interface RichTextEditorProps
  extends Pick<
    FieldProps,
    | "name"
    | "value"
    | "errors"
    | "label"
    | "required"
    | "isFieldEditable"
    | "placeholder"
    | "onValueChange"
  > {
  className?: string;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const {
    name,
    value,
    errors,
    label,
    required,
    isFieldEditable,
    placeholder,
    className,
    onValueChange,
  } = props;

  // select initial value
  const initialValue = () => {
    // from props
    if (typeof value === "string") {
      return [{ type: "paragraph", children: [{ text: value }] }];
    }

    // or component default
    return [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
  };

  // handle focus for styling
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);

  // component's internal state in Slate JSON format
  const [slateJSON, setSlateJSON] = React.useState<Node[]>(initialValue());

  // initialize an editor
  const editor = React.useMemo(
    () => withHistory(withReact(createEditor())),
    [],
  );

  // get form field's editable context
  const editableModeContextValue = React.useContext(EditableModeContext);

  // prop value of `isFieldEditable` takes precedence over editable context value
  const isEditable = isDefined(isFieldEditable)
    ? isFieldEditable
    : editableModeContextValue;

  // Ctrl + `some_other_key` presses will trigger editor commands
  const handleControlKeys = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!event.ctrlKey) {
      return;
    }

    switch (event.key) {
      case "`": {
        event.preventDefault();
        Commands.toggleCodeBlock(editor);
        break;
      }
      case ">": {
        event.preventDefault();
        Commands.toggleQuoteBlock(editor);
        break;
      }

      case "b": {
        event.preventDefault();
        Commands.toggleBoldMark(editor);
        break;
      }
    }
  };

  // Define a rendering function based on the element passed to `props`. We use
  // `useCallback` here to memoize the function for subsequent renders.
  const renderElement = React.useCallback((props: RenderElementProps) => {
    if (isLinkElement(props.element)) {
      return <LinkElement {...(props as LinkElementProps)} />;
    }

    if (isQuoteElement(props.element)) {
      return <QuoteElement {...(props as QuoteElementProps)} />;
    }

    if (isCodeElement(props.element)) {
      return <CodeElement {...(props as CodeElementProps)} />;
    }

    if (isParagraphElement(props.element)) {
      return <ParagraphElement {...(props as ParagraphElementProps)} />;
    }

    return <DefaultElement {...props} />;
  }, []);

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = React.useCallback((props: RenderLeafProps) => {
    return <CustomLeaf {...props} />;
  }, []);

  // update form element's value using their callback when the value changes
  React.useEffect(() => {
    const textValue = serializeToPlainText(slateJSON);

    if (typeof onValueChange === "function") {
      onValueChange(textValue);
    }
  }, [slateJSON, onValueChange]);

  // render the component
  return (
    <div className={classNames(styles["wrap"], className)}>
      {/* Field is editable */}
      {isEditable && (
        <>
          {label && label !== "" && (
            <FieldLabel label={label} htmlFor={name} required={required} />
          )}

          <div
            className={classNames(styles["editor"], {
              [styles["has-focus"]]: hasFocus,
            })}
          >
            {/* <EditorToolbar editor={editor} /> */}

            <Slate
              editor={editor}
              value={slateJSON}
              onChange={(value) => setSlateJSON(value)}
            >
              <Editable
                spellCheck
                placeholder={placeholder}
                renderLeaf={renderLeaf}
                renderElement={renderElement}
                onKeyDown={handleControlKeys}
                onBlur={() => setHasFocus(false)}
                onFocus={() => setHasFocus(true)}
              />
            </Slate>
          </div>

          {errors &&
            errors.map((error, index) => (
              <FieldError key={index} error={error} />
            ))}
        </>
      )}

      {/* Field is uneditable */}
      {!isEditable && (
        <div className={fieldStyles["information"]}>
          <div className={fieldStyles["left-column"]}>{label}</div>
          <div className={fieldStyles["right-column"]}>
            {serializeToPlainText(slateJSON) ?? "..."}
          </div>
        </div>
      )}
    </div>
  );
};
