import React, { useState } from "react";
import classNames from "classnames";
import {
  Mention,
  MentionsInput,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from "react-mentions";
import { Chip } from "../Chip/Chip";
import { InputInfo } from "../../lib/react-apollo-hooks-form";
import { isValueEmail } from "../../services/isValueEmail";
import { FieldError } from "../FieldError/FieldError";
import styles from "./EmailCcInput.module.scss";

export interface AddressBookEmailsInfo {
  id: string;
  display: string;
}

export interface EmailCcInputProps {
  input: InputInfo;
  ccEmails: string[] | null;
  addressBookEmails?: SuggestionDataItem[];
  className?: string;
  handleCcEmailChange?: (updatedEmails: string[]) => void;
}

export const EmailCcInput: React.FC<EmailCcInputProps> = ({
  input,
  ccEmails,
  addressBookEmails = [],
  className,
  handleCcEmailChange,
}) => {
  const [inputFieldValue, setInputFieldValue] = useState<string[]>(
    ccEmails || [],
  );

  const { value, setValue, setErrors, errors } = input;

  const onChange: OnChangeHandlerFunc = (
    _event: { target: { value: string } },
    newPlainTextValue: string,
  ) => {
    input.setValue(newPlainTextValue);
  };

  const mentionData = (
    query: string,
    _callback: (data: SuggestionDataItem[]) => void,
  ) => {
    const filtered = addressBookEmails.filter((email) => {
      const isNotUsed = !ccEmails?.includes(email.display);

      // filter emails by search param
      if (query.length >= 1 && isNotUsed) {
        const regex = new RegExp("\\b\\w*" + query + "\\w*\\b", "gi");

        return regex.test(email.display);
      }

      return isNotUsed;
    });

    // return 5 items from array
    return filtered.slice(0, 5);
  };

  const handleOnAdd = async (_id: string | number, display: string) => {
    // validate before adding
    if (!isValueEmail(display)) {
      setErrors(["Invalid email"]);

      return;
    }

    // validate if email already exists
    if (inputFieldValue.includes(display)) {
      input.setErrors(["Email already exists"]);
      setValue("");

      return;
    }

    // add cc mail to list
    const valuesCopy = inputFieldValue.slice();
    valuesCopy.push(display);

    if (typeof handleCcEmailChange === "function") {
      handleCcEmailChange(valuesCopy);
    }

    setInputFieldValue(valuesCopy);
    setValue("");
  };

  const handleOnKeyDown = async (event: React.KeyboardEvent) => {
    // user ready to add cc
    if (
      event.key === " " ||
      event.key === "," ||
      event.key === "Enter" ||
      event.key === "Tab"
    ) {
      event.preventDefault();

      // validate before adding
      if (typeof value === "number" || !isValueEmail(value)) {
        setErrors(["Invalid email"]);
        return;
      }

      // validate if email already exists
      if (inputFieldValue.includes(value)) {
        setErrors(["Email already exists"]);
        setValue("");

        return;
      }

      // add cc mail to list
      if (typeof value === "string") {
        const valuesCopy = inputFieldValue.slice();
        valuesCopy.push(value);
        if (typeof handleCcEmailChange === "function") {
          handleCcEmailChange(valuesCopy);
        }

        setInputFieldValue(valuesCopy);
        setValue("");
      }
    }
  };

  // remove bubble from list
  const handleRemoveValue = (valueIndex: number | string) => {
    if (!inputFieldValue || typeof valueIndex === "string") {
      return;
    }

    const valuesCopy = inputFieldValue.slice();
    valuesCopy.splice(valueIndex, 1);

    if (typeof handleCcEmailChange === "function") {
      handleCcEmailChange(valuesCopy);
    }

    setInputFieldValue(valuesCopy);

    // move focus back to input
    const inputElement = document.getElementById("valueInput");

    if (!inputElement) {
      return;
    }

    inputElement.focus();
  };

  const emailBubbles = () => {
    return ccEmails?.map((value, index) => (
      <Chip
        key={index}
        id={index}
        onRemove={handleRemoveValue}
        className={classNames(styles.chip_wrap, className)}
      >
        {value}
      </Chip>
    ));
  };

  return (
    <>
      <div className={classNames(styles.wrap, className)}>
        <div>
          {emailBubbles()}
          <MentionsInput
            id="valueInput"
            value={input.value.toString()}
            onKeyDown={handleOnKeyDown}
            onChange={onChange}
            classNames={styles}
            singleLine={true}
            placeholder="Enter CC email"
          >
            <Mention trigger="@" data={mentionData} onAdd={handleOnAdd} />
          </MentionsInput>
        </div>
      </div>
      {errors !== undefined &&
        errors.map((error, index) => (
          <FieldError
            key={index}
            error={error}
            className={classNames(styles.error, className)}
          />
        ))}
    </>
  );
};
