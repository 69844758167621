import React from "react";
import { CardContextProvider } from "../../contexts/card-context";
import {
  AddressBook,
  CardTypeEnum,
  PurchaseRequestByCodePurchaseRequestByCode,
} from "../../schema";
import { getEmailByType } from "../../services/getEmailByType";
import { AccordionTertiary } from "../Accordion/AccordionTertiary";
import {
  EventTypeEnum,
  StatusNotice,
  StatusNoticeProps,
} from "../StatusNotice/StatusNotice";
import styles from "./RequesterCorrespondenceBar.module.scss";

export type AddressBookWithOptionalParameters = Pick<
  AddressBook,
  "id" | "email"
>;
export interface RequesterCorrespondenceBarProps {
  purchaseRequest: PurchaseRequestByCodePurchaseRequestByCode;
  addressBook: AddressBookWithOptionalParameters[];
}

export const RequesterCorrespondenceBar: React.FC<RequesterCorrespondenceBarProps> =
  (props) => {
    const { purchaseRequest, addressBook } = props;

    const [statusNotice, setStatusNotice] =
      React.useState<StatusNoticeProps | null>(null);

    const requester = purchaseRequest.requestor;

    // handle correspondence bar email reply success
    const handleSendReplySuccess = (email: string) => {
      setStatusNotice({
        level: EventTypeEnum.SUCCESS,
        eventTitle: "Reply sent to requester",
        eventDescription: `Sent to ${email}`,
      });
    };

    return (
      <>
        {/* status notice */}
        {statusNotice && (
          <StatusNotice
            level={statusNotice.level}
            eventTitle={statusNotice.eventTitle}
            eventDescription={statusNotice.eventDescription}
            onCloseRequested={() => {
              setStatusNotice(null);
            }}
          />
        )}

        {/* Use RFX context here because all correspondence bar e-mails are related to RFX */}
        {purchaseRequest && (
          <CardContextProvider
            card={{ ...purchaseRequest, type: CardTypeEnum.PURCHASE_REQUEST }}
          >
            <div className={styles["correspondence-bar"]}>
              {requester && (
                <AccordionTertiary
                  key={requester.id}
                  title={`${requester.firstName} ${requester.lastName}`}
                  className={styles["correspondence-accordion"]}
                >
                  {purchaseRequest.emails.filter(
                    (email) => email.requesterId === requester.id,
                  ).length > 0
                    ? purchaseRequest.emails
                        .filter((email) => email.requesterId === requester.id)
                        .map((email) =>
                          getEmailByType({
                            displayNarrowView: true,
                            email,
                            addressBookEmails: addressBook,
                            onSendReplySuccess: handleSendReplySuccess,
                            className: styles["correspondence-email"],
                            isInitiallyOpen: true,
                          }),
                        )
                    : "No emails sent out yet"}
                </AccordionTertiary>
              )}
            </div>
          </CardContextProvider>
        )}
      </>
    );
  };
