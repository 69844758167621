import { ApolloError, OperationVariables } from "apollo-client";
import { GraphQLError } from "graphql";
import {
  ChangeEvent,
  FocusEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { FetchResult } from "apollo-link";
import { MutationHookOptions, MutationTuple } from "@apollo/react-hooks";
import { ExecutionResult, MutationFunctionOptions } from "@apollo/react-common";

// creates the mutation method, this usually comes from generated apollo client
export type CreateMutationFn<TData, TVariables = OperationVariables> = (
  baseOptions?: MutationHookOptions<TData, TVariables>,
) => MutationTuple<TData, TVariables>;

export type MutationFn<TData, TVariables> = (
  options?: MutationFunctionOptions<TData, TVariables>,
) => Promise<ExecutionResult<TData>>;

// form submit success callback
export type FormSuccessCallback<TData, TVariables = OperationVariables> = (
  result: TData,
  variables: TVariables,
) => void;

// form error callback
export type FormErrorCallback<TVariables = OperationVariables> = (
  error: ApolloError,
  variables: TVariables,
) => void;

// input value
export type InputValue = string | number | boolean | MultiSelectOption[] | null;

// map of values with keys from template type and values of input value type
export type ValueMap<T> = { [key in keyof T]: InputValue };

// map of errors s with keys from template type and values of string arrays (can have multiple errors per field)
export type ErrorsMap<T> = { [key in keyof T]?: string[] };

// map of fields
export type FieldMap<T> = { [key in keyof T]?: FieldInfo };

// validator function type - takes input value and returns a string if any issues are found
export type ValidatorFn = (value: InputValue) => string | undefined;

// TODO: consider alternate middleware-style validator syntax with optional continuation
// export type ValidatorFn = (value: InputValue, error: (message: string) => void, next: () => void) => void;

// transforms input value
export type InputTransformFn = (value: string) => string | number;

export type CustomInputTransformFn = (value: string) => string;

// useForm custom hook options
export type UseFormOptions<TData, TVariables = OperationVariables> = {
  mutation: CreateMutationFn<TData, TVariables>;
  options?: MutationHookOptions<TData, TVariables>;
  onSuccess?: FormSuccessCallback<TData, TVariables>;
  onError?: FormErrorCallback<TVariables>;
  override?: ValueMap<TVariables>;
};

// represents base field
export interface FieldInfo {
  validate(mode: InputValidationMode): boolean;
  setErrors(value: SetStateAction<string[]>): void;
  reset(): void;
}

// represents input field info
export interface InputInfo extends FieldInfo {
  name: string;
  value: string | number;
  errors: string[];
  required: boolean;
  setValue(value: SetStateAction<string | number>): void;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onBlur(e: FocusEvent<HTMLInputElement>): void;
}

// represents checkbox field info
export interface CheckboxInfo extends FieldInfo {
  name: string;
  value: boolean;
  errors: string[];
  checkbox: boolean;
  setValue(value: SetStateAction<boolean>): void;
  onChange(e: ChangeEvent<HTMLInputElement>): unknown;
  onBlur(e: FocusEvent<HTMLInputElement>): void;
}

// represents datepicker field info
export interface DatepickerInfo extends FieldInfo {
  name: string;
  selected: Date | null;
  errors: string[];
  required: boolean;
  setSelected(selected: SetStateAction<Date | null>): void;
  onChange(
    date: Date | null,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  onBlur(e: FocusEvent<HTMLInputElement>): void;
}

// represents select field info
export interface SelectInfo extends FieldInfo {
  name: string;
  options: SelectOption[];
  value: string;
  errors: string[];
  required: boolean;
  setValue(value: SetStateAction<string>): void;
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
  onBlur(e: FocusEvent<HTMLSelectElement>): void;
}

// represents multi-select input field info
export interface MultiSelectInfo extends FieldInfo {
  name: string;
  value: MultiSelectOption[];
  errors: string[];
  required: boolean;
  setValue(value: SetStateAction<MultiSelectOption[]>): void;
}

export interface CustomInputInfo extends FieldInfo {
  name: string;
  value: string;
  errors: string[];
  required: boolean;
  setValue(value: SetStateAction<string>): void;
  onValueChange(value: string): void;
}

// input custom hook options
export interface UseInputOptions<TVariables = OperationVariables> {
  name: keyof TVariables;
  validators?: ValidatorFn[];
  defaultValue?: string | number;
  defaultErrors?: string[];
  transform?: InputTransformFn;
  optional?: boolean;
}

// checkbox custom hook options
export interface UseCheckboxOptions<TVariables = OperationVariables> {
  name: keyof TVariables | string;
  validators?: ValidatorFn[];
  defaultValue?: boolean;
  defaultErrors?: string[];
}

// datepicker custom hook options
export interface UseDatepickerOptions<TVariables = OperationVariables> {
  name: keyof TVariables;
  validators?: ValidatorFn[];
  defaultValue?: Date | null;
  defaultErrors?: string[];
  optional?: boolean;
}

// select custom hook options
export interface UseSelectOptions<TVariables = OperationVariables> {
  name: keyof TVariables;
  options: SelectOption[];
  validators?: ValidatorFn[];
  defaultValue?: string;
  defaultErrors?: string[];
  optional?: boolean;
}

// select option
interface SelectOption {
  value: string;
  label: string;
}

export type MultiSelectOption = {
  [k: string]: string | number | boolean | null;
};

export interface UseMultiSelectOptions<TVariables = OperationVariables> {
  name: keyof TVariables;
  validators?: ValidatorFn[];
  defaultValue?: MultiSelectOption[];
  defaultErrors?: string[];
  optional?: boolean;
}

// custom input custom hook options
export interface UseCustomInputOptions<TVariables = OperationVariables> {
  name: keyof TVariables;
  validators?: ValidatorFn[];
  defaultValue?: string;
  defaultErrors?: string[];
  transform?: CustomInputTransformFn;
  optional?: boolean;
}

// validation graphql error interface
export interface ValidationGraphQLError<TVariables = OperationVariables>
  extends GraphQLError {
  extensions: {
    code: "BAD_USER_INPUT";
    exception: {
      errors: ErrorsMap<TVariables>;
    };
  };
}

// represents created form info
export type FormInfo = ReturnType<typeof useForm>;

// input validation mode
export type InputValidationMode = "normal" | "skip-if-empty" | "fix-only";

// is the library running in debug mode
const debug = process.env.NODE_ENV === "development";

export function useForm<TData, TVariables = OperationVariables>({
  mutation,
  options,
  onSuccess,
  onError,
}: UseFormOptions<TData, TVariables>) {
  // form state
  const [success, setIsSuccess] = useState(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<FetchResult<TData> | undefined>(
    undefined,
  );

  // form values dictionary
  const values: ValueMap<TVariables> = {} as ValueMap<TVariables>; //! gets properly populated later
  const inputs: FieldMap<TVariables> = {};

  // input hook
  const useInput = ({
    name,
    validators,
    defaultValue,
    defaultErrors,
    transform,
    optional,
  }: UseInputOptions<TVariables>): InputInfo => {
    // create input state
    const [value, setValue] = useState<string | number>(
      defaultValue === undefined ? "" : defaultValue,
    );
    const [errors, setErrors] = useState(defaultErrors || []);
    const [blurCount, setBlurCount] = useState(0);

    // check for empty string value
    const isValueEmpty = typeof value === "string" && value.length === 0;

    // store current value
    if (isValueEmpty) {
      values[name] = optional ? null : "";
    } else {
      values[name] = value;
    }

    // handles change event
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // use value transformer if available, default to dummy transformer that does nothing
      const transformValue =
        typeof transform === "function" ? transform : (value: string) => value;

      // get transformed value
      const newValue = transformValue(e.target.value);

      setValue(newValue);

      // check for empty string value
      const isValueEmpty =
        typeof newValue === "string" && newValue.length === 0;

      // store current value
      if (isValueEmpty) {
        values[name] = optional ? null : "";
      } else {
        values[name] = newValue;
      }
    };

    // handles blur event
    const onBlur = (_e: FocusEvent<HTMLInputElement>) => {
      setBlurCount(blurCount + 1);
    };

    // performs validation
    const validate = (mode: InputValidationMode) => {
      // don't do anything if no validators have been registered
      if (!validators) {
        // still clear server errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // consider valid if requested for empty value without outstanding errors
      if (
        (mode === "skip-if-empty" || optional) &&
        typeof value === "string" &&
        value.length === 0 &&
        errors.length === 0
      ) {
        // still clear existing errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // perform all validations
      let validationErrors: string[] = [];

      for (const validator of validators) {
        // perform validation
        const validationResult = validator(value);

        // add validation error if any
        if (typeof validationResult === "string") {
          validationErrors = [...validationErrors, validationResult];

          // only add the first issue
          break;
        }
      }

      if (mode === "fix-only") {
        // only update validation errors if there are now less than before
        if (validationErrors.length < errors.length) {
          setErrors(validationErrors);
        }
      } else {
        // update validation errors
        setErrors(validationErrors);
      }

      return validationErrors.length === 0;
    };

    // TODO: how to properly handle react-hooks/exhaustive-deps?
    // perform validation on blur
    useEffect(() => {
      validate("skip-if-empty");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurCount]);

    // perform positive validation on value change
    useEffect(() => {
      validate("fix-only");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // resets input
    const reset = () => {
      setValue(defaultValue || "");
      setErrors(defaultErrors || []);
      setBlurCount(0);
    };

    // build input info
    const input: InputInfo = {
      name: name.toString(),
      value,
      setValue,
      errors,
      required: !optional,
      reset,
      setErrors,
      onChange,
      onBlur,
      validate,
    };

    // store the input
    inputs[name] = input;

    return input;
  };

  // checkbox hook
  const useCheckbox = ({
    name,
    validators,
    defaultValue,
    defaultErrors,
  }: UseCheckboxOptions<TVariables>): CheckboxInfo => {
    // create input state
    const initialValue = defaultValue ?? false;
    const [value, setValue] = useState(initialValue);
    const [errors, setErrors] = useState(defaultErrors || []);
    const [blurCount, setBlurCount] = useState(0);

    values[name as keyof TVariables] = value;

    // handles change event
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // get transformed value
      const newValue = e.target.checked;
      setValue(newValue);

      values[name as keyof TVariables] = newValue;

      return newValue;
    };

    // handles blur event
    const onBlur = (_e: FocusEvent<HTMLInputElement>) => {
      setBlurCount(blurCount + 1);
    };

    // performs validation
    const validate = (mode: InputValidationMode) => {
      // don't do anything if no validators have been registered
      if (!validators) {
        // still clear server errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // perform all validations
      let validationErrors: string[] = [];

      for (const validator of validators) {
        // perform validation
        const validationResult = validator(value);

        // add validation error if any
        if (typeof validationResult === "string") {
          validationErrors = [...validationErrors, validationResult];

          // only add the first issue
          break;
        }
      }

      if (mode === "fix-only") {
        // only update validation errors if there are now less than before
        if (validationErrors.length < errors.length) {
          setErrors(validationErrors);
        }
      } else {
        // update validation errors
        setErrors(validationErrors);
      }

      return validationErrors.length === 0;
    };

    // TODO: how to properly handle react-hooks/exhaustive-deps?
    // perform validation on blur
    useEffect(() => {
      validate("skip-if-empty");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurCount]);

    // perform positive validation on value change
    useEffect(() => {
      validate("fix-only");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // resets input
    const reset = () => {
      setValue(initialValue);
      setErrors(defaultErrors || []);
      setBlurCount(0);
    };

    // build input info
    const input: CheckboxInfo = {
      name: name.toString(),
      value,
      setValue,
      errors,
      checkbox: true,
      reset,
      setErrors,
      onChange,
      onBlur,
      validate,
    };

    // store the input
    inputs[name as keyof TVariables] = input;

    return input;
  };

  // datepicker hook
  const useDatepicker = ({
    name,
    validators,
    defaultValue,
    defaultErrors,
    optional,
  }: UseDatepickerOptions<TVariables>): DatepickerInfo => {
    // create input state
    const [selected, setSelected] = useState<Date | null>(defaultValue || null);
    const [errors, setErrors] = useState(defaultErrors || []);
    const [blurCount, setBlurCount] = useState(0);

    // store current value
    if (selected === null) {
      values[name] = optional ? null : "";
    } else {
      values[name] = selected.toISOString();
    }

    // handles change event
    const onChange = (
      newSelected: Date | null,
      _event: React.SyntheticEvent<unknown> | undefined,
    ) => {
      setSelected(newSelected);

      // store current value
      if (newSelected === null) {
        values[name] = newSelected ? null : "";
      } else {
        values[name] = newSelected.toISOString();
      }
    };

    // handles blur event
    const onBlur = (_e: FocusEvent<HTMLInputElement>) => {
      setBlurCount(blurCount + 1);
    };

    // performs validation
    const validate = (mode: InputValidationMode) => {
      // don't do anything if no validators have been registered
      if (!validators) {
        // still clear server errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // consider valid if requested for empty value without outstanding errors
      if (
        (mode === "skip-if-empty" || optional) &&
        selected === null &&
        errors.length === 0
      ) {
        // still clear existing errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      let validationErrors: string[] = [];

      // perform all validations
      for (const validator of validators) {
        // perform validation
        const validationResult = validator(
          selected !== null ? selected.toISOString() : "",
        );

        // add validation error if any
        if (typeof validationResult === "string") {
          validationErrors = [...validationErrors, validationResult];

          // only add the first issue
          break;
        }
      }

      if (mode === "fix-only") {
        // only update validation errors if there are now less than before
        if (validationErrors.length < errors.length) {
          setErrors(validationErrors);
        }
      } else {
        // update validation errors
        setErrors(validationErrors);
      }

      return validationErrors.length === 0;
    };

    // TODO: how to properly handle react-hooks/exhaustive-deps?
    // perform validation on blur
    useEffect(() => {
      validate("skip-if-empty");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurCount]);

    // perform positive validation on value change
    useEffect(() => {
      validate("fix-only");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    // resets input
    const reset = () => {
      setSelected(defaultValue || null);
      setErrors(defaultErrors || []);
      setBlurCount(0);
    };

    // build input info
    const datepicker: DatepickerInfo = {
      name: name.toString(),
      selected,
      setSelected,
      errors,
      required: !optional,
      reset,
      setErrors,
      onChange,
      onBlur,
      validate,
    };

    // store the input
    inputs[name] = datepicker;

    return datepicker;
  };

  // input hook
  const useSelect = ({
    name,
    options,
    validators,
    defaultValue,
    defaultErrors,
    optional,
  }: UseSelectOptions<TVariables>): SelectInfo => {
    // resolve initial value, default to first option if no default value has been set
    const initialValue =
      defaultValue !== undefined
        ? defaultValue
        : options.length > 0
        ? options[0].value
        : "";

    // create input state
    const [value, setValue] = useState(initialValue);
    const [errors, setErrors] = useState(defaultErrors || []);
    const [blurCount, setBlurCount] = useState(0);

    // check for empty string value
    const isValueEmpty = value.length === 0;

    // store current value
    if (isValueEmpty) {
      values[name] = optional ? null : "";
    } else {
      values[name] = value;
    }

    // handles change event
    const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      // get transformed value
      const newValue = e.target.value;

      setValue(newValue);

      // check for empty string value
      const isValueEmpty = newValue.length === 0;

      if (isValueEmpty) {
        values[name] = optional ? null : "";
      } else {
        values[name] = value;
      }
    };

    // handles blur event
    const onBlur = (_e: FocusEvent<HTMLSelectElement>) => {
      setBlurCount(blurCount + 1);
    };

    // performs validation
    const validate = (mode: InputValidationMode) => {
      // don't do anything if no validators have been registered
      if (!validators) {
        // still clear server errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // consider valid if requested for empty value without outstanding errors
      if (
        (mode === "skip-if-empty" || optional) &&
        typeof value === "string" &&
        value.length === 0 &&
        errors.length === 0
      ) {
        // still clear existing errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // perform all validations
      let validationErrors: string[] = [];

      for (const validator of validators) {
        // perform validation
        const validationResult = validator(value);

        // add validation error if any
        if (typeof validationResult === "string") {
          validationErrors = [...validationErrors, validationResult];

          // only add the first issue
          break;
        }
      }

      if (mode === "fix-only") {
        // only update validation errors if there are now less than before
        if (validationErrors.length < errors.length) {
          setErrors(validationErrors);
        }
      } else {
        // update validation errors
        setErrors(validationErrors);
      }

      return validationErrors.length === 0;
    };

    // TODO: how to properly handle react-hooks/exhaustive-deps?
    // perform validation on blur
    useEffect(() => {
      validate("skip-if-empty");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurCount]);

    // perform positive validation on value change
    useEffect(() => {
      validate("fix-only");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // resets input
    const reset = () => {
      setValue(defaultValue || "");
      setErrors(defaultErrors || []);
      setBlurCount(0);
    };

    // build input info
    const input: SelectInfo = {
      name: name.toString(),
      options,
      value,
      setValue,
      errors,
      required: !optional,
      reset,
      setErrors,
      onChange,
      onBlur,
      validate,
    };

    // store the input
    inputs[name] = input;

    return input;
  };

  // multiselect hook
  const useMultiSelect = ({
    name,
    validators,
    defaultValue,
    defaultErrors,
    optional,
  }: UseMultiSelectOptions<TVariables>): MultiSelectInfo => {
    // create input state
    const [value, setValue] = useState<MultiSelectOption[]>(
      defaultValue === undefined ? [] : defaultValue,
    );
    const [errors, setErrors] = useState(defaultErrors || []);
    const [blurCount, setBlurCount] = useState(0);

    // check for empty array value
    const isValueEmpty = Array.isArray(value) && value.length === 0;

    // store current value
    if (isValueEmpty) {
      values[name] = optional ? null : [];
    } else {
      values[name] = value;
    }

    // performs validation
    const validate = (mode: InputValidationMode) => {
      // don't do anything if no validators have been registered
      if (!validators) {
        // still clear server errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // consider valid if requested for empty value without outstanding errors
      if (
        (mode === "skip-if-empty" || optional) &&
        Array.isArray(value) &&
        value.length === 0 &&
        errors.length === 0
      ) {
        // still clear existing errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // perform all validations
      let validationErrors: string[] = [];

      for (const validator of validators) {
        // perform validation
        const validationResult = validator(value);

        // add validation error if any
        if (typeof validationResult === "string") {
          validationErrors = [...validationErrors, validationResult];

          // only add the first issue
          break;
        }
      }

      if (mode === "fix-only") {
        // only update validation errors if there are now less than before
        if (validationErrors.length < errors.length) {
          setErrors(validationErrors);
        }
      } else {
        // update validation errors
        setErrors(validationErrors);
      }

      return validationErrors.length === 0;
    };

    // TODO: how to properly handle react-hooks/exhaustive-deps?
    // perform validation on blur
    useEffect(() => {
      validate("skip-if-empty");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurCount]);

    // perform positive validation on value change
    useEffect(() => {
      validate("fix-only");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // resets input
    const reset = () => {
      setValue(defaultValue || []);
      setErrors(defaultErrors || []);
      setBlurCount(0);
    };

    // build multi-select info
    const input: MultiSelectInfo = {
      name: name.toString(),
      value,
      setValue,
      errors,
      required: !optional,
      reset,
      setErrors,
      validate,
    };

    // store the input
    inputs[name] = input;

    return input;
  };

  // custom input hook
  const useCustomInput = ({
    name,
    validators,
    defaultValue,
    defaultErrors,
    transform,
    optional,
  }: UseCustomInputOptions<TVariables>): CustomInputInfo => {
    // create custom input state
    const [value, setValue] = useState<string>(
      defaultValue === undefined ? "" : defaultValue,
    );
    const [errors, setErrors] = useState(defaultErrors || []);
    const [blurCount, setBlurCount] = useState(0);

    // check for empty string value
    const isValueEmpty = typeof value === "string" && value.length === 0;

    // store current value
    if (isValueEmpty) {
      values[name] = optional ? null : "";
    } else {
      values[name] = value;
    }

    // handles value change event
    const onValueChange = (value: string) => {
      // use value transformer if available, default to dummy transformer that does nothing
      const transformValue =
        typeof transform === "function" ? transform : (value: string) => value;

      // get transformed value
      const newValue = transformValue(value);

      setValue(newValue);

      // check for empty string value
      const isValueEmpty =
        typeof newValue === "string" && newValue.length === 0;

      // store current value
      if (isValueEmpty) {
        values[name] = optional ? null : "";
      } else {
        values[name] = newValue;
      }
    };

    // performs validation
    const validate = (mode: InputValidationMode) => {
      // don't do anything if no validators have been registered
      if (!validators) {
        // still clear server errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // consider valid if requested for empty value without outstanding errors
      if (
        (mode === "skip-if-empty" || optional) &&
        typeof value === "string" &&
        value.length === 0 &&
        errors.length === 0
      ) {
        // still clear existing errors if any
        if (errors.length > 0) {
          setErrors([]);
        }

        return true;
      }

      // perform all validations
      let validationErrors: string[] = [];

      for (const validator of validators) {
        // perform validation
        const validationResult = validator(value);

        // add validation error if any
        if (typeof validationResult === "string") {
          validationErrors = [...validationErrors, validationResult];

          // only add the first issue
          break;
        }
      }

      if (mode === "fix-only") {
        // only update validation errors if there are now less than before
        if (validationErrors.length < errors.length) {
          setErrors(validationErrors);
        }
      } else {
        // update validation errors
        setErrors(validationErrors);
      }

      return validationErrors.length === 0;
    };

    // TODO: how to properly handle react-hooks/exhaustive-deps?
    // perform validation on blur
    useEffect(() => {
      validate("skip-if-empty");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurCount]);

    // perform positive validation on value change
    useEffect(() => {
      validate("fix-only");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // resets input
    const reset = () => {
      setValue(defaultValue || "");
      setErrors(defaultErrors || []);
      setBlurCount(0);
    };

    // build input info
    const input: CustomInputInfo = {
      name: name.toString(),
      value,
      setValue,
      errors,
      required: !optional,
      reset,
      setErrors,
      onValueChange,
      validate,
    };

    // store the input
    inputs[name] = input;

    return input;
  };

  // create mutation function with given options and variables
  const mutationOptions: MutationHookOptions<TData, TVariables> = {
    ...(options || {}),
  };
  const [submitMutation, { loading }] = mutation(mutationOptions);

  // validates all inputs
  const validate = () => {
    let areAllInputsValid = true;

    for (const inputName of Object.keys(inputs)) {
      const input = inputs[inputName as keyof typeof inputs];

      // call validate on all inputs
      if (input) {
        const isValid = input.validate("normal");

        if (!isValid) {
          areAllInputsValid = false;
        }
      }
    }

    return areAllInputsValid;
  };

  // submits the form
  const submit = async (override?: Partial<TVariables>) => {
    // don't submit the form if any of the validations fail
    if (!validate()) {
      return;
    }

    // clear form error if any
    if (formError !== undefined) {
      setFormError(undefined);
    }

    // build combined variables
    const variables = ({
      ...values,
      ...override,
    } as unknown) as TVariables;

    // attempt to submit the mutation
    try {
      // submit the mutation
      const result = await submitMutation({
        variables,
      });

      // data should be available for a successful mutation
      if (!result.data) {
        throw new Error(
          "Mutation succeeded but data is not present, this should not happen",
        );
      }

      // request was successful
      setIsSuccess(true);
      setResult(result);

      // call success callback if provided
      if (typeof onSuccess === "function") {
        onSuccess(result.data, variables);
      }
    } catch (error) {
      // check for error type
      if (isApolloError(error)) {
        if (error.networkError) {
          setFormError(
            debug
              ? error.networkError.message
              : "Oops! Something went wrong, please try again later",
          );
        } else {
          const fieldErrors = getFieldErrors(error.graphQLErrors);

          for (const [name, errors] of Object.entries(fieldErrors)) {
            // get input by name
            const input = inputs[name as keyof TVariables];

            // set input errors
            if (input && Array.isArray(errors)) {
              input.setErrors(errors);
            }
          }

          // if no field errors were extracted, something more generic must have gone wrong, show global message
          if (Object.keys(fieldErrors).length === 0) {
            setFormError(
              debug
                ? error.message
                : "Oops! Something went wrong, please try again later",
            );
          }

          // call error callback if provided
          if (typeof onError === "function") {
            onError(error, variables);
          }
        }
      } else {
        setFormError(
          debug
            ? error.message
            : "Oops! Something went wrong, please try again later",
        );
      }
    }
  };

  // resets the form and inputs if requested
  const reset = (resetInputs = true) => {
    setIsSuccess(false);
    setResult(undefined);
    setFormError(undefined);

    // also reset inputs if requested
    if (resetInputs) {
      for (const inputName of Object.keys(inputs)) {
        const input = inputs[inputName as keyof typeof inputs];

        // call validate on all inputs
        if (input) {
          input.reset();
        }
      }
    }
  };

  // return form info
  return {
    useInput,
    useCheckbox,
    useDatepicker,
    useSelect,
    useMultiSelect,
    useCustomInput,
    validate,
    submit,
    reset,
    loading,
    error: formError,
    success,
    variables: (values as unknown) as TVariables,
    result,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApolloError(error: any): error is ApolloError {
  return Array.isArray(error.graphQLErrors);
}

export function isValidationGraphQLError<TVariables = OperationVariables>(
  error: GraphQLError,
): error is ValidationGraphQLError<TVariables> {
  return (
    error.extensions !== undefined &&
    error.extensions.code === "BAD_USER_INPUT" &&
    error.extensions.exception !== undefined
  );
}

export function getFieldErrors<TVariables = OperationVariables>(
  graphQLErrors: readonly GraphQLError[],
): ErrorsMap<TVariables> {
  let fieldErrors: ErrorsMap<TVariables> = {};

  // resolve field errors
  graphQLErrors.forEach((graphqlError) => {
    if (isValidationGraphQLError<TVariables>(graphqlError)) {
      fieldErrors = {
        ...fieldErrors,
        ...graphqlError.extensions.exception.errors,
      };
    }
  });

  return fieldErrors;
}
