import { ExecutionResult } from "graphql";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import { Row } from "../Row/Row";
import { formatDate } from "../../services/formatDate";
import { formatMoney } from "../../services/formatMoney";
import { NotAvailable } from "../NotAvailable/NotAvailable";
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";
import { EditIcon } from "../../theme/svg/EditIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { renderPrices } from "../../services/renderPrices";
import {
  PurchaseRequestByCodeItems,
  RfxByCodeItems,
  PurchaseRequestItemTypeEnum,
  CreateRfxFromPurchaseRequestItemsMutation,
  ApprovalWorkflowStatusEnum,
  CreateOrderFromPurchaseRequestItemsMutation,
  PurchaseRequestItemProgressTypeEnum,
  AddPurchaseRequestItemsToRfxMutation,
  RfxTypeEnum,
  OrderTypeEnum,
  AddPurchaseRequestItemsToOrderMutation,
  Attachment,
} from "../../schema";
import { calculateItemPriceTotals } from "../../services/calculateItemPriceTotals";
import { Field } from "../../components/Field/Field";
import { InputInfo } from "../../lib/react-apollo-hooks-form";
import { ProductIcon } from "../../theme/svg/ProductIcon";
import { ServiceIcon } from "../../theme/svg/ServiceIcon";
import { UploadedAttachment } from "../UploadedAttachment/UploadedAttachment";
import { Avatar } from "../Avatar/Avatar";
import {
  Dropdown,
  DropdownItem,
  DropdownContent,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { TriangleArrowIcon } from "../../theme/svg/TriangleArrowIcon";
import { NumberInput } from "../NumberInput/NumberInput";
import { PurchaseRequestItemDetails } from "./PurchaseRequestItemDetails";
import styles from "./PurchaseRequestItems.module.scss";

export type PurchaseRequestItem = PurchaseRequestByCodeItems | RfxByCodeItems;

export interface ModalInfo {
  id: string;
  name: string;
  code: string;
  type: RfxTypeEnum | OrderTypeEnum | null;
}

export interface PurchaseRequestItemsProps {
  items: PurchaseRequestItem[];
  loadingItemId?: string;
  isEditable?: boolean;
  vatInputInfo?: InputInfo;
  showItemProgress?: boolean;
  approvalWorkFlowStatus?: ApprovalWorkflowStatusEnum;
  showCtaButtons?: boolean;
  showSelectAndSortButtons?: boolean;
  rfxDrafts?: ModalInfo[];
  orderDrafts?: ModalInfo[];
  isMovingItemsToRfx?: boolean;
  isMovingItemsToOrder?: boolean;
  selectedItems?: PurchaseRequestItem[];
  setSelectedItems?: React.Dispatch<
    React.SetStateAction<PurchaseRequestItem[]>
  >;
  toUploadExcelItems?(): void;
  onAdd?(): void;
  onEdit?(itemId: string, itemType: string): void;
  onDelete?(itemId: string): void;
  onRequestLinkClick?(code: string): void;
  createRfxFromItems?: () => Promise<
    ExecutionResult<CreateRfxFromPurchaseRequestItemsMutation>
  >;
  createOrderFromItems?: () => Promise<
    ExecutionResult<CreateOrderFromPurchaseRequestItemsMutation>
  >;
  addItemsToRfx?: (
    rfxId: string,
  ) => Promise<ExecutionResult<AddPurchaseRequestItemsToRfxMutation>>;
  addItemsToOrder?: (
    orderId: string,
  ) => Promise<ExecutionResult<AddPurchaseRequestItemsToOrderMutation>>;
}

export type PurchaseRequestItemWithSuggestedSupplier = PurchaseRequestItem & {
  suggestedSupplier: string;
};

export enum PurchaseRequestItemSortType {
  "NONE" = "NONE",
  "BY_SUPPLIER_ASCENDING" = "BY_SUPPLIER_ASCENDING",
  "BY_SUPPLIER_DESCENDING" = "BY_SUPPLIER_DESCENDING",
  "BY_NAME_ASCENDING" = "BY_NAME_ASCENDING",
  "BY_NAME_DESCENDING" = "BY_NAME_DESCENDING",
}

export const PurchaseRequestItems: React.FC<PurchaseRequestItemsProps> = ({
  items,
  loadingItemId,
  isEditable: isModalEditable = true,
  vatInputInfo,
  showItemProgress,
  approvalWorkFlowStatus,
  showCtaButtons,
  showSelectAndSortButtons = false,
  rfxDrafts,
  orderDrafts,
  isMovingItemsToRfx,
  isMovingItemsToOrder,
  selectedItems,
  setSelectedItems,
  toUploadExcelItems,
  onAdd,
  onEdit,
  onDelete: onDeleteItem,
  onRequestLinkClick,
  createRfxFromItems,
  createOrderFromItems,
  addItemsToRfx,
  addItemsToOrder,
}) => {
  // setup state
  const [includeVat, setIncludeVat] = useState(false);
  const [isSelectDropdownOpen, setIsSelectDropdownOpen] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [isRfxDropdownOpen, setIsRfxDropdownOpen] = useState(false);
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);
  const [activeSortOption, setActiveSortOption] = useState(
    PurchaseRequestItemSortType.NONE,
  );
  const [sortedItems, setSortedItems] = useState(items);

  useEffect(() => {
    const hasVatFilled =
      (vatInputInfo && vatInputInfo.value.toString().length > 0) || false;
    setIncludeVat(hasVatFilled);
  }, [vatInputInfo]);

  const getCheckboxCheckedState = (option: string) => {
    const selectedOptionItems = selectedItems
      ? selectedItems.filter(
          (selectedItem) => selectedItem.suggestedSupplier === option,
        )
      : [];

    const itemsWithOption = items.filter(
      (item) => item.suggestedSupplier === option,
    );

    return selectedOptionItems.length === itemsWithOption.length;
  };

  const handleSelectAllItems = () => {
    const itemsToSelect = selectedItems?.length === items.length ? [] : items;

    if (typeof setSelectedItems === "function") {
      setSelectedItems(itemsToSelect);
    }
  };

  const getSelectOptions = () => {
    const itemsWithSuppliers = items.filter(
      (item): item is PurchaseRequestItemWithSuggestedSupplier =>
        item.suggestedSupplier !== null &&
        item.suggestedSupplier.trim().length !== 0,
    );

    const suggestedSuppliers = itemsWithSuppliers
      .map((item) => item.suggestedSupplier)
      .filter(
        (suggestedSupplier, index, self) =>
          self.indexOf(suggestedSupplier) === index,
      )
      .sort((a, b) => a.localeCompare(b));

    return suggestedSuppliers;
  };

  const handleSelectSuggestedSupplier = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedOption: string,
  ) => {
    if (typeof setSelectedItems === "function") {
      const isChecked = e.target.checked;

      if (isChecked) {
        const itemsToAdd = items.filter(
          (item) =>
            !selectedItems?.includes(item) &&
            item.suggestedSupplier === selectedOption,
        );

        setSelectedItems(
          selectedItems ? selectedItems.concat(itemsToAdd) : itemsToAdd,
        );
      } else {
        selectedItems &&
          setSelectedItems(
            selectedItems.filter(
              (selectedItem) =>
                selectedItem.suggestedSupplier !== selectedOption,
            ),
          );
      }
    }
  };

  const handleItemSorting = (sortType: PurchaseRequestItemSortType) => {
    setActiveSortOption(sortType);
    setIsSortDropdownOpen(false);

    switch (sortType) {
      case PurchaseRequestItemSortType.BY_SUPPLIER_ASCENDING:
        return setSortedItems(
          [...items].sort((a, b) =>
            (a.suggestedSupplier || "").localeCompare(
              b.suggestedSupplier || "",
            ),
          ),
        );

      case PurchaseRequestItemSortType.BY_SUPPLIER_DESCENDING:
        return setSortedItems(
          [...items].sort((a, b) =>
            (b.suggestedSupplier || "").localeCompare(
              a.suggestedSupplier || "",
            ),
          ),
        );

      case PurchaseRequestItemSortType.BY_NAME_ASCENDING:
        return setSortedItems(
          [...items].sort((a, b) => a.name.localeCompare(b.name)),
        );

      case PurchaseRequestItemSortType.BY_NAME_DESCENDING:
        return setSortedItems(
          [...items].sort((a, b) => b.name.localeCompare(a.name)),
        );

      default:
        setSortedItems(items);
    }
  };

  // update items when their state changes
  useEffect(() => {
    handleItemSorting(activeSortOption);
  }, [items]);

  // handle no items
  if (items.length === 0) {
    // show message and possibility to add an item
    return (
      <div className={styles["empty-wrap"]}>
        {onAdd && toUploadExcelItems ? (
          <>
            <div className={styles["add-items"]}>
              <div className={styles["empty-message"]}>
                Click the button below to add the first item
              </div>
              <div
                className={styles["upload-excel"]}
                onClick={() => toUploadExcelItems && toUploadExcelItems()}
              >
                Upload from Excel
              </div>
            </div>
            <Button
              data-testid="6000a95671"
              add
              title="Add product or service"
              onClick={() => onAdd()}
            />
          </>
        ) : onAdd ? (
          <>
            <div className={styles["empty-message"]}>
              Click the button below to add the first item
            </div>
            <Button
              data-testid="6000a95671"
              add
              title="Add product or service"
              onClick={() => onAdd()}
            />
          </>
        ) : (
          <div className={styles["empty-message"]}>
            No items have been added yet
          </div>
        )}
      </div>
    );
  }

  // calculate total price of all items for all currencies
  const totalPrices = calculateItemPriceTotals(items);

  // no items might have price available
  const hasTotalPrice = Object.keys(totalPrices).length > 0;

  // VAT percent for calculations
  const currentVatPercent = Number(vatInputInfo?.value) ?? 0;

  // Construct Map<currency, total price>
  const totalPricesMap = new Map(Object.entries(totalPrices));

  const vatAmountsObj = calculateVatAmounts(totalPricesMap, currentVatPercent);

  // Calculate totals with VAT included
  const totalWithVatAmountsObj = calculateTotalWithVatAmounts(
    totalPricesMap,
    currentVatPercent,
  );

  const itemInPurchaseRequest = (item: PurchaseRequestItem) => {
    return (
      item.swimlaneProgress ===
      PurchaseRequestItemProgressTypeEnum.PURCHASE_REQUEST
    );
  };

  // render the items table
  return (
    <BasicTable>
      <thead>
        <tr className={styles["items-table-header"]}>
          <Cell header className={styles["cta-buttons"]}>
            {items.length > 1 && showSelectAndSortButtons && (
              <>
                <Dropdown
                  onCloseRequested={() => setIsSelectDropdownOpen(false)}
                >
                  <DropdownSelector
                    className={styles["dropdown-selector"]}
                    onClick={() =>
                      !isMovingItemsToRfx &&
                      !isMovingItemsToOrder &&
                      setIsSelectDropdownOpen(!isSelectDropdownOpen)
                    }
                  >
                    <Button
                      className={styles["move-to-button"]}
                      loading={isMovingItemsToRfx}
                      disabled={isMovingItemsToOrder}
                    >
                      Select
                      <TriangleArrowIcon
                        className={styles["triangle-arrow-icon"]}
                      />
                    </Button>
                  </DropdownSelector>

                  <DropdownContent
                    className={classNames(
                      styles["dropdown-content"],
                      styles["dropdown-content--autoWidth"],
                    )}
                    open={isSelectDropdownOpen}
                  >
                    <DropdownItem
                      className={classNames(
                        styles["dropdown-item"],
                        styles["dropdown-item--withSelect"],
                      )}
                    >
                      <Field
                        isFieldEditable={true}
                        checkbox
                        value={selectedItems?.length === items.length}
                        onCheckboxChange={handleSelectAllItems}
                      >
                        Select All
                      </Field>
                    </DropdownItem>
                    {getSelectOptions().map((option, index) => (
                      <DropdownItem
                        className={classNames(
                          styles["dropdown-item"],
                          styles["dropdown-item--withSelect"],
                        )}
                        key={index}
                      >
                        <Field
                          isFieldEditable={true}
                          checkbox
                          value={getCheckboxCheckedState(option)}
                          onCheckboxChange={(e) =>
                            handleSelectSuggestedSupplier(e, option)
                          }
                        >
                          {option}
                        </Field>
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </Dropdown>
                <Dropdown onCloseRequested={() => setIsSortDropdownOpen(false)}>
                  <DropdownSelector
                    className={styles["dropdown-selector"]}
                    onClick={() =>
                      !isMovingItemsToRfx &&
                      !isMovingItemsToOrder &&
                      setIsSortDropdownOpen(!isSortDropdownOpen)
                    }
                  >
                    <Button
                      className={styles["move-to-button"]}
                      loading={isMovingItemsToRfx}
                      disabled={isMovingItemsToOrder}
                    >
                      Sort by
                      <TriangleArrowIcon
                        className={styles["triangle-arrow-icon"]}
                      />
                    </Button>
                  </DropdownSelector>

                  <DropdownContent
                    className={classNames(
                      styles["dropdown-content"],
                      styles["dropdown-content--autoWidth"],
                    )}
                    open={isSortDropdownOpen}
                  >
                    <DropdownItem
                      className={classNames(styles["dropdown-item"])}
                      onClick={() =>
                        handleItemSorting(PurchaseRequestItemSortType.NONE)
                      }
                    >
                      None
                    </DropdownItem>
                    <DropdownItem
                      className={classNames(
                        styles["dropdown-item"],
                        styles["dropdown-item--withSorting"],
                      )}
                      onClick={() =>
                        handleItemSorting(
                          PurchaseRequestItemSortType.BY_NAME_ASCENDING,
                        )
                      }
                    >
                      Item name <span className={styles["sort-type"]}>A-Z</span>
                    </DropdownItem>
                    <DropdownItem
                      className={classNames(
                        styles["dropdown-item"],
                        styles["dropdown-item--withSorting"],
                      )}
                      onClick={() =>
                        handleItemSorting(
                          PurchaseRequestItemSortType.BY_NAME_DESCENDING,
                        )
                      }
                    >
                      Item name <span className={styles["sort-type"]}>Z-A</span>
                    </DropdownItem>
                    <DropdownItem
                      className={classNames(
                        styles["dropdown-item"],
                        styles["dropdown-item--withSorting"],
                      )}
                      onClick={() =>
                        handleItemSorting(
                          PurchaseRequestItemSortType.BY_SUPPLIER_ASCENDING,
                        )
                      }
                    >
                      Supplier <span className={styles["sort-type"]}>A-Z</span>
                    </DropdownItem>
                    <DropdownItem
                      className={classNames(
                        styles["dropdown-item"],
                        styles["dropdown-item--withSorting"],
                      )}
                      onClick={() =>
                        handleItemSorting(
                          PurchaseRequestItemSortType.BY_SUPPLIER_DESCENDING,
                        )
                      }
                    >
                      Supplier <span className={styles["sort-type"]}>Z-A</span>
                    </DropdownItem>
                  </DropdownContent>
                </Dropdown>
              </>
            )}
            {showCtaButtons && setSelectedItems && (
              <>
                <Dropdown onCloseRequested={() => setIsRfxDropdownOpen(false)}>
                  <DropdownSelector
                    className={styles["dropdown-selector"]}
                    onClick={() =>
                      !isMovingItemsToRfx &&
                      !isMovingItemsToOrder &&
                      setIsRfxDropdownOpen(!isRfxDropdownOpen)
                    }
                  >
                    <Button
                      className={styles["move-to-button"]}
                      loading={isMovingItemsToRfx}
                      disabled={isMovingItemsToOrder}
                    >
                      MOVE to eSourcing{" "}
                      <TriangleArrowIcon
                        className={styles["triangle-arrow-icon"]}
                      />
                    </Button>
                  </DropdownSelector>

                  <DropdownContent
                    className={styles["dropdown-content"]}
                    open={isRfxDropdownOpen}
                  >
                    {createRfxFromItems && (
                      <DropdownItem
                        className={styles["create-new"]}
                        onClick={async () => {
                          setIsRfxDropdownOpen(false);
                          await createRfxFromItems();
                          setSelectedItems([]);
                        }}
                      >
                        Create New RFX
                      </DropdownItem>
                    )}

                    {rfxDrafts &&
                      addItemsToRfx &&
                      rfxDrafts.map((rfx) => (
                        <DropdownItem
                          className={styles["dropdown-item"]}
                          key={rfx.id}
                          onClick={async () => {
                            setIsRfxDropdownOpen(false);
                            await addItemsToRfx(rfx.id);
                            setSelectedItems([]);
                          }}
                        >
                          {`Add to ${rfx.type || "RFX"}-${rfx.code} - "${
                            rfx.name
                          }"`}
                        </DropdownItem>
                      ))}
                  </DropdownContent>
                </Dropdown>

                <Dropdown
                  onCloseRequested={() => setIsOrderDropdownOpen(false)}
                >
                  <DropdownSelector
                    className={styles["dropdown-selector"]}
                    onClick={() =>
                      !isMovingItemsToRfx &&
                      !isMovingItemsToOrder &&
                      setIsOrderDropdownOpen(!isOrderDropdownOpen)
                    }
                  >
                    <Button
                      className={styles["move-to-button"]}
                      loading={isMovingItemsToOrder}
                      disabled={isMovingItemsToRfx}
                    >
                      MOVE to PO{" "}
                      <TriangleArrowIcon
                        className={styles["triangle-arrow-icon"]}
                      />
                    </Button>
                  </DropdownSelector>

                  <DropdownContent
                    className={styles["dropdown-content"]}
                    open={isOrderDropdownOpen}
                  >
                    {createOrderFromItems && (
                      <DropdownItem
                        className={styles["create-new"]}
                        onClick={async () => {
                          setIsOrderDropdownOpen(false);
                          await createOrderFromItems();
                          setSelectedItems([]);
                        }}
                      >
                        Create New PO
                      </DropdownItem>
                    )}

                    {orderDrafts &&
                      addItemsToOrder &&
                      orderDrafts.map((order) => (
                        <DropdownItem
                          className={styles["dropdown-item"]}
                          key={order.id}
                          onClick={async () => {
                            setIsOrderDropdownOpen(false);
                            await addItemsToOrder(order.id);
                            setSelectedItems([]);
                          }}
                        >
                          {`Add to PO-${order.code} - "${order.name}"`}
                        </DropdownItem>
                      ))}
                  </DropdownContent>
                </Dropdown>
              </>
            )}
          </Cell>
          <Cell header center compact>
            Unit Price
          </Cell>
          <Cell header center compact>
            Total
          </Cell>
          {isModalEditable && <Cell header compact />}
        </tr>
      </thead>
      <tbody>
        {sortedItems.map((item) => (
          <tr
            key={item.id}
            className={classNames({
              [styles["in-progress"]]:
                showItemProgress && !itemInPurchaseRequest(item),
            })}
          >
            <Cell>
              <Row className={styles["item-information"]}>
                <Field
                  checkbox
                  value={selectedItems?.includes(item)}
                  isFieldEditable={
                    !isModalEditable &&
                    (!approvalWorkFlowStatus ||
                      approvalWorkFlowStatus ===
                        ApprovalWorkflowStatusEnum.APPROVED) &&
                    itemInPurchaseRequest(item)
                  }
                  onCheckboxChange={(e) => {
                    if (e.target.checked) {
                      setSelectedItems &&
                        setSelectedItems(
                          selectedItems ? selectedItems.concat(item) : [item],
                        );
                    } else {
                      selectedItems &&
                        setSelectedItems &&
                        setSelectedItems(
                          selectedItems.filter(
                            (selectedItem) => selectedItem !== item,
                          ),
                        );
                    }
                  }}
                />
                {item.type === PurchaseRequestItemTypeEnum.PRODUCT ? (
                  <ProductIcon className={styles["type-icon"]} />
                ) : (
                  <ServiceIcon className={styles["type-icon"]} />
                )}

                <div className={styles["info-area"]}>
                  <div className={styles["info-area-header"]}>
                    <div className={styles["item-name"]}>{item.name}</div>

                    <Text
                      title={
                        item.quantity && item.unit ? (
                          `Qty ${item.quantity} ${item.unit}`
                        ) : (
                          <div>&nbsp;</div>
                        )
                      }
                      description={
                        <>
                          <div
                            className={classNames({
                              [styles["date-margin"]]:
                                item.attachments && item.attachments.length > 0,
                            })}
                          >
                            {"Exp delivery: "}
                            {item.expectedDeliveryDate
                              ? formatDate(item.expectedDeliveryDate)
                              : "Not specified"}
                          </div>

                          {(
                            item.attachments as Pick<
                              Attachment,
                              "id" | "filename" | "url"
                            >[]
                          ).map((attachment) => (
                            <UploadedAttachment
                              key={attachment.id}
                              attachment={attachment}
                              secondary
                            />
                          ))}
                        </>
                      }
                      className={styles["quantity-text"]}
                    />
                  </div>

                  {showItemProgress && !itemInPurchaseRequest(item) && (
                    <div className={styles["progress-info"]}>
                      <span>{item.parentCode}</span>
                      <Avatar className={styles.avatar} user={item.assignee} />
                    </div>
                  )}
                  <PurchaseRequestItemDetails
                    item={item}
                    className={styles["item-details"]}
                  />

                  {onRequestLinkClick && (
                    <div className={styles["request-info"]}>
                      <div>
                        <div
                          className={styles["request-info-text"]}
                        >{`Requested by: ${item.requestor.firstName} ${item.requestor.lastName}`}</div>
                        <div className={styles["request-info-text"]}>
                          {`Request: `}
                          <span
                            className={styles["request-info-link"]}
                            onClick={() =>
                              onRequestLinkClick(
                                `PR-${item.purchaseRequestCode}`,
                              )
                            }
                          >
                            {`PR-${item.purchaseRequestCode}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </Cell>
            <Cell right compact>
              {item.unitPrice && item.currency ? (
                `${formatMoney(item.unitPrice)} ${item.currency}`
              ) : (
                <NotAvailable />
              )}
            </Cell>
            <Cell right compact>
              {item.unitPrice && item.currency && item.quantity ? (
                `${formatMoney(item.unitPrice * item.quantity)} ${
                  item.currency
                }`
              ) : item.unitPrice && item.currency ? (
                `${formatMoney(item.unitPrice)} ${item.currency}`
              ) : (
                <NotAvailable />
              )}
            </Cell>
            {isModalEditable && !item.isDeleted && (onEdit || onDeleteItem) && (
              <Cell compact>
                <Row>
                  {onEdit && (
                    <Button
                      data-testid="964055598b"
                      icon={<EditIcon className={styles["button-icon"]} />}
                      onClick={() => {
                        onEdit(item.id, item.type);
                      }}
                    />
                  )}
                  {onDeleteItem && (
                    <Button
                      data-testid="e34f1715ec"
                      icon={<RemoveIcon className={styles["button-icon"]} />}
                      disabled={item.id === loadingItemId}
                      loading={item.id === loadingItemId}
                      onClick={() => onDeleteItem(item.id)}
                    />
                  )}
                </Row>
              </Cell>
            )}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <Cell right>
            {vatInputInfo && isModalEditable && hasTotalPrice && (
              <div className={styles["field-container"]}>
                <div className={styles["field-label"]}>Include VAT</div>
                <Field
                  name="include-vat"
                  checkbox
                  {...{
                    value: includeVat,
                    onChange: (_e) => {
                      setIncludeVat(!includeVat);
                    },
                  }}
                />
              </div>
            )}
          </Cell>
          <Cell right strong nowrap>
            {vatInputInfo && includeVat && hasTotalPrice && "Total (w/o VAT)"}
            {(!vatInputInfo || !includeVat) && hasTotalPrice && "Total"}
          </Cell>
          <Cell right strong nowrap primary={hasTotalPrice}>
            {renderPrices(totalPrices)}
          </Cell>
        </tr>
        {vatInputInfo && includeVat && hasTotalPrice && (
          <>
            <tr>
              <Cell></Cell>
              <Cell right strong>
                {hasTotalPrice && isModalEditable && "VAT"}
                {hasTotalPrice &&
                  !isModalEditable &&
                  `VAT (${currentVatPercent}%)`}
              </Cell>
              <Cell right strong nowrap primary={hasTotalPrice}>
                {renderPrices(vatAmountsObj)}
              </Cell>
              {isModalEditable && (
                <Cell primary>
                  <NumberInput
                    {...vatInputInfo}
                    placeholder="VAT %"
                    addon="%"
                    className={styles["vat-input"]}
                  />
                </Cell>
              )}
            </tr>
            <tr>
              <Cell></Cell>
              <Cell right strong>
                {hasTotalPrice && "Total"}
              </Cell>
              <Cell right strong nowrap primary={hasTotalPrice}>
                {renderPrices(totalWithVatAmountsObj)}
              </Cell>
            </tr>
          </>
        )}
        <tr>
          {isModalEditable && (
            <Cell className={styles["add-button-cell"]}>
              {onAdd && (
                <Button
                  data-testid="ae9be01630"
                  add
                  title="Add product or service"
                  onClick={() => onAdd()}
                />
              )}
            </Cell>
          )}
        </tr>
      </tfoot>
    </BasicTable>
  );
};

export function calculateVatAmounts(
  totalPricesMap: Map<string, number | undefined>,
  currentVatPercent: number,
) {
  const vatAmountsMap = new Map<string, number | undefined>();

  totalPricesMap.forEach((totalPrice, currencyCode) => {
    vatAmountsMap.set(
      currencyCode,
      totalPrice ? (totalPrice * currentVatPercent) / 100 : 0,
    );
  });

  const vatAmountsObj: {
    [key: string]: number | undefined;
  } = {};

  vatAmountsMap.forEach((value: number | undefined, key: string) => {
    vatAmountsObj[key] = value;
  });

  return vatAmountsObj;
}

export function calculateTotalWithVatAmounts(
  totalPricesMap: Map<string, number | undefined>,
  currentVatPercent: number,
) {
  const totalWithVatAmountsMap = new Map<string, number | undefined>();

  totalPricesMap.forEach((totalPrice, currencyCode) => {
    totalWithVatAmountsMap.set(
      currencyCode,
      totalPrice ? totalPrice * ((100 + currentVatPercent) / 100) : 0,
    );
  });

  const totalWithVatAmountsObj: {
    [key: string]: number | undefined;
  } = {};

  totalWithVatAmountsMap.forEach((value: number | undefined, key: string) => {
    totalWithVatAmountsObj[key] = value;
  });

  return totalWithVatAmountsObj;
}
