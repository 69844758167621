import React from "react";
import classNames from "classnames";
import format from "date-fns/format";
import { NavLink } from "react-router-dom";
import { Route, Switch } from "react-router";
import {
  RfxStatusEnum,
  RfxSupplierResolutionEnum,
  SourcingEventStatusEnum,
  SourcingRequestStatusEnum,
  useOrganizationSourcingEvents,
  ViewerOrganizations,
} from "../../schema";
import { View } from "../../components/View/View";
import { Cell, BasicTable } from "../../components/BasicTable/BasicTable";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { Avatar } from "../../components/Avatar/Avatar";
import { ClockIcon } from "../../theme/svg/ClockIcon";
import { FileCheckedIcon } from "../../theme/svg/FileCheckedIcon";
import { formatDateTimeDifference } from "../../services/formatDateDifference";
import { PersonCheckedIcon } from "../../theme/svg/PersonCheckedIcon";
import { Button } from "../../components/Button/Button";
import { useRouter } from "../../hooks/useRouter";
import styles from "./SourcingEventsView.module.scss";
import { StartSourcingEventModal } from "./StartSourcingEventModal";
export interface SourcingEventsViewProps {
  organization: Pick<ViewerOrganizations, "id" | "urlName">;
}

export const SourcingEventsView: React.FC<SourcingEventsViewProps> = ({
  organization,
}) => {
  const { history } = useRouter();

  // load sourcing events data
  const { data, loading, error } = useOrganizationSourcingEvents({
    fetchPolicy: "network-only",
    variables: { organizationId: organization.id },
  });

  // handle error
  if (error) {
    return (
      <ErrorView title="Sourcing events could not be found" error={error} />
    );
  }

  // handle loading
  if (loading || !data || !data.viewer) {
    return <LoadingView />;
  }

  const orgSourcingEvents = data.viewer.organization.sourcingEvents;

  // "active" sourcing events
  const activeSourcingEvents = orgSourcingEvents.filter(
    (se) =>
      se.rfx.status === RfxStatusEnum.ONGOING &&
      se.rfx.deletedDate === null &&
      se.status === SourcingEventStatusEnum.IN_PROGRESS &&
      new Date(se.responseDeadline) > new Date(),
  );

  // "unpublished" sourcing events
  const unpublishedSourcingEvents = orgSourcingEvents.filter(
    (se) =>
      se.rfx.deletedDate === null &&
      se.status === SourcingEventStatusEnum.DRAFT,
  );

  // "ended" sourcing events
  const endedSourcingEvents = orgSourcingEvents.filter(
    (se) =>
      se.rfx.status === RfxStatusEnum.COMPLETE ||
      se.rfx.deletedDate ||
      se.status === SourcingEventStatusEnum.COMPLETE ||
      (se.status === SourcingEventStatusEnum.IN_PROGRESS &&
        new Date(se.responseDeadline) <= new Date()),
  );

  return (
    <>
      <View secondary>
        <div className={styles["actions"]}>
          <Button
            onClick={() =>
              history.push(
                `/sourcing-events/${organization.urlName}/start-new-sourcing-event`,
              )
            }
          >
            Start New Sourcing Event
          </Button>
        </div>
        <h3 className={styles["table-heading"]}>Active Sourcing Events</h3>
        <BasicTable className={styles["table"]}>
          <thead>
            <tr>
              <Cell secondary header>
                # Request name
              </Cell>
              <Cell secondary header>
                Last Change
              </Cell>
              <Cell secondary header></Cell>
              <Cell secondary header>
                Expected Response
              </Cell>
              <Cell secondary header>
                Time left
              </Cell>
              <Cell secondary header>
                Quotes Received
              </Cell>
              <Cell></Cell>
            </tr>
          </thead>
          <tbody>
            {activeSourcingEvents.length === 0 ? (
              <tr>
                <td className={styles["no-data-cell"]} colSpan={7}>
                  Currently there are no active Sourcing Events
                </td>
              </tr>
            ) : (
              activeSourcingEvents.map((se) => (
                <tr key={se.id} className={styles["event-row"]}>
                  <Cell className={classNames(styles["cell"])}>
                    <div className={styles["event-title"]}>
                      <NavLink
                        to={`/${organization.urlName}/${se.rfx.type}-${se.rfx.code}`}
                        className={styles["event-title_rfx"]}
                      >{`${se.rfx.type}-${se.rfx.code}`}</NavLink>
                      <div className={styles["event-title_name"]}>
                        {se.name}
                      </div>
                    </div>
                  </Cell>
                  <Cell className={styles["cell"]}>
                    {formatDate(se.updatedDate)}
                  </Cell>
                  <Cell className={styles["cell"]}>
                    <Avatar user={se.creator} />
                  </Cell>
                  <Cell className={styles["cell"]}>
                    {formatDate(se.responseDeadline)}
                  </Cell>
                  <Cell className={styles["statistical-info"]}>
                    <div className={styles["time-left"]}>
                      <ClockIcon className={styles["time-left_icon"]} />
                      <div className={styles["time-left_text"]}>
                        {formatDateTimeDifference(
                          new Date(se.responseDeadline),
                          new Date(),
                        )}
                      </div>
                    </div>
                  </Cell>
                  <Cell className={styles["statistical-info"]}>
                    <div className={styles["quotes-received"]}>
                      <FileCheckedIcon
                        className={styles["quotes-received_icon"]}
                      />
                      <div className={styles["quotes-received_text"]}>
                        {
                          se.sourcingRequests.filter(
                            (sr) =>
                              sr.status === SourcingRequestStatusEnum.COMPLETE,
                          ).length
                        }
                      </div>
                    </div>
                  </Cell>
                  <Cell className={styles["statistical-info"]}></Cell>
                </tr>
              ))
            )}
          </tbody>
        </BasicTable>

        <h3 className={styles["table-heading"]}>Unpublished Sourcing Events</h3>
        <BasicTable className={styles["table"]}>
          <thead>
            <tr>
              <Cell secondary header>
                # Request name
              </Cell>
              <Cell secondary header>
                Last Change
              </Cell>
              <Cell secondary header></Cell>
              <Cell secondary header>
                Expected Response
              </Cell>
              <Cell secondary header></Cell>
              <Cell secondary header></Cell>
              <Cell></Cell>
            </tr>
          </thead>
          <tbody>
            {unpublishedSourcingEvents.length === 0 ? (
              <tr>
                <td className={styles["no-data-cell"]} colSpan={7}>
                  Currently there are no active Sourcing Events
                </td>
              </tr>
            ) : (
              unpublishedSourcingEvents.map((se) => (
                <tr key={se.id} className={styles["event-row"]}>
                  <Cell className={styles["cell"]}>
                    <div className={styles["event-title"]}>
                      <NavLink
                        to={`/${organization.urlName}/${se.rfx.type}-${se.rfx.code}`}
                        className={styles["event-title_rfx"]}
                      >{`${se.rfx.type}-${se.rfx.code}`}</NavLink>
                      <div className={styles["event-title_name"]}>
                        {se.name}
                      </div>
                    </div>
                  </Cell>
                  <Cell className={styles["cell"]}>
                    {formatDate(se.updatedDate)}
                  </Cell>
                  <Cell className={styles["cell"]}>
                    <Avatar user={se.creator} />
                  </Cell>
                  <Cell className={styles["cell"]}>
                    {formatDate(se.responseDeadline)}
                  </Cell>
                  <Cell className={styles["cell"]}></Cell>
                  <Cell className={styles["cell"]}></Cell>
                  <Cell className={styles["cell"]}></Cell>
                </tr>
              ))
            )}
          </tbody>
        </BasicTable>

        <h3 className={classNames(styles["table-heading"])}>
          Ended and Archived Sourcing Events
        </h3>
        <BasicTable className={styles["table"]}>
          <thead>
            <tr>
              <Cell secondary header>
                # Request name
              </Cell>
              <Cell secondary header>
                Last Change
              </Cell>
              <Cell secondary header></Cell>
              <Cell secondary header>
                Expected Response
              </Cell>
              <Cell secondary header>
                Requests Sent
              </Cell>
              <Cell secondary header>
                Quotes Received
              </Cell>
              <Cell secondary header>
                Nominated Supplier(s)
              </Cell>
            </tr>
          </thead>
          <tbody>
            {endedSourcingEvents.length === 0 ? (
              <tr>
                <td className={styles["no-data-cell"]} colSpan={7}>
                  There are no ended or archived Sourcing Events
                </td>
              </tr>
            ) : (
              endedSourcingEvents.map((se) => (
                <tr key={se.id} className={styles["event-row"]}>
                  <Cell className={styles["cell"]}>
                    <div className={styles["event-title"]}>
                      <NavLink
                        to={`/${organization.urlName}/${se.rfx.type}-${se.rfx.code}`}
                        className={classNames(styles["event-title_rfx"])}
                      >{`${se.rfx.type}-${se.rfx.code}`}</NavLink>
                      <div className={styles["event-title_name"]}>
                        {se.name}
                      </div>
                    </div>
                  </Cell>
                  <Cell className={styles["cell"]}>
                    {formatDate(se.updatedDate)}
                  </Cell>
                  <Cell className={styles["cell"]}>
                    <Avatar user={se.creator} />
                  </Cell>
                  <Cell className={styles["cell"]}>
                    {formatDate(se.responseDeadline)}
                  </Cell>
                  <Cell className={styles["statistical-info--archived"]}>
                    <div className={styles["sourcing-requests"]}>
                      <PersonCheckedIcon
                        className={styles["sourcing-requests_icon"]}
                      />
                      <div className={styles["sourcing-requests_text"]}>
                        {se.sourcingRequests.length}
                      </div>
                    </div>
                  </Cell>
                  <Cell className={styles["statistical-info--archived"]}>
                    <div className={styles["quotes-received"]}>
                      <FileCheckedIcon
                        className={classNames(
                          styles["quotes-received_icon"],
                          styles["quotes-received_icon--archived"],
                        )}
                      />
                      <div
                        className={classNames(
                          styles["quotes-received_text"],
                          styles["quotes-received_text--archived"],
                        )}
                      >
                        {
                          // number of completed sourcing requests
                          se.sourcingRequests.filter(
                            (sr) =>
                              sr.status === SourcingRequestStatusEnum.COMPLETE,
                          ).length
                        }
                      </div>
                    </div>
                  </Cell>
                  <Cell
                    className={classNames(
                      styles["nominated-supplier"],
                      styles["statistical-info--archived"],
                    )}
                  >
                    {
                      // get nominated to PO supplier name
                      se.rfx.supplierResolutions
                        ?.filter(
                          (sr) =>
                            sr.resolution &&
                            sr.resolution ===
                              RfxSupplierResolutionEnum.NOMINATE_TO_PO,
                        )
                        .flatMap((sr) => sr.supplier.name)
                        .join(", ")
                    }
                  </Cell>
                </tr>
              ))
            )}
          </tbody>
        </BasicTable>
      </View>
      <Switch>
        <Route
          exact
          path={`/sourcing-events/${organization.urlName}/start-new-sourcing-event`}
        >
          <StartSourcingEventModal organizationUrlName={organization.urlName} />
        </Route>
      </Switch>
    </>
  );
};

function formatDate(dateString: string) {
  const formattedDate = format(new Date(dateString), "d-MMM-yyyy");

  return formattedDate.toUpperCase();
}
