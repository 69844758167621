import React from "react";
import classNames from "classnames";
import styles from "./BasicTable.module.scss";

export interface BasicTableProps {
  className?: string;
}

export const BasicTable: React.FC<BasicTableProps> = ({
  children,
  className,
}) => <table className={classNames(styles.table, className)}>{children}</table>;

export interface CellProps
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > {
  header?: boolean;
  compact?: boolean;
  center?: boolean;
  right?: boolean;
  strong?: boolean;
  nowrap?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  quaternary?: boolean;
  quinary?: boolean;
  success?: boolean;
  error?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<
    HTMLTableHeaderCellElement | HTMLTableDataCellElement
  >;
}

export const Cell: React.FC<CellProps> = ({
  header,
  compact,
  center,
  right,
  strong,
  nowrap,
  primary,
  secondary,
  tertiary,
  quaternary,
  quinary,
  success,
  error,
  className,
  onClick,
  children,
  ...rest
}) => {
  // resolve class names
  const combinedClassName = classNames(
    styles.cell,
    {
      [styles["cell--header"]]: header,
      [styles["cell--compact"]]: compact,
      [styles["cell--center"]]: center,
      [styles["cell--right"]]: right,
      [styles["cell--strong"]]: strong,
      [styles["cell--nowrap"]]: nowrap,
      [styles["cell--primary"]]: primary,
      [styles["cell--secondary"]]: secondary,
      [styles["cell--tertiary"]]: tertiary,
      [styles["cell--quaternary"]]: quaternary,
      [styles["cell--quinary"]]: quinary,
      [styles["cell--success"]]: success,
      [styles["cell--error"]]: error,
      [styles["cell--clickable"]]: onClick !== undefined,
    },
    className,
  );

  // use th for header
  if (header) {
    return (
      <th {...rest} className={combinedClassName} onClick={onClick}>
        {children}
      </th>
    );
  }

  // use td for normal table data
  return (
    <td {...rest} className={combinedClassName} onClick={onClick}>
      {children}
    </td>
  );
};
