import React, { useCallback, useState } from "react";
import classNames from "classnames";
import {
  useForm,
  useFieldArray,
  FormProvider,
  Controller,
} from "react-hook-form";
import { not } from "ramda";
import {
  ViewerViewer,
  useSourcingRequestById,
  useUpdateSourcingRequest,
  useCompleteSourcingRequest,
  SourcingRequestStatusEnum,
  UploadQuotationPricesExcelRows,
  useUploadQuotationPricesExcelMutation,
} from "../../schema";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { useRouter } from "../../hooks/useRouter";
import { LoadingView } from "../LoadingView/LoadingView";
import { Header } from "../../components/Header/Header";
import { ProfileDropdown } from "../../components/ProfileDropdown/ProfileDropdown";
import { QuestionInput } from "../../components/QuestionInput/QuestionInput";
import { Button } from "../../components/Button/Button";
import { RfqItemQuotations } from "../../components/RfqItemQuotations/RfqItemQuotations";
import { AddFiles } from "../../components/AddFiles/AddFiles";
import { SourcingEventInfo } from "../../components/SourcingEventInfo/SourcingEventInfo";
import {
  StatusNotice,
  EventTypeEnum,
  StatusNoticeProps,
} from "../../components/StatusNotice/StatusNotice";
import { SourcingEventTemplate } from "../../components/RfqEventForm/RfqEventForm";
import { Unbox } from "../../lib/helpers/typescript-helpers";
import {
  FormSection,
  FormSectionState,
} from "../../components/FormSection/FormSection";
import { Attachments } from "../../components/Attachments/Attachments";
import { OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { FieldError } from "../../components/FieldError/FieldError";
import { ExcelQuotationPricesImportErrorsTable } from "../../components/ExcelImportErrorsTable/ExcelQuitationPricesImportErrorsTable";
import { Modal } from "../../components/Modal/Modal";
import styles from "./SupplierPortalDetailView.module.scss";

export interface SupplierPortalViewProps {
  className?: string;
  viewer: ViewerViewer;
}

interface UrlParams {
  sourcingRequestId: string;
}

export type RfqQuotationFormInputs = {
  generalQuestions: SourcingEventTemplate["generalQuestions"];
  items: SourcingEventTemplate["items"];
  comment: string | null;
};

export const SupplierPortalDetailView: React.FC<SupplierPortalViewProps> = (
  props,
) => {
  // use router and extract sourcing request ID from params
  const { match } = useRouter<UrlParams>();
  const { sourcingRequestId } = match.params;

  // added/attached files state
  const [addedFiles, setAddedFiles] = React.useState<File[] | undefined>();

  const [isPreview, setIsPreview] = React.useState(false);

  // excel import state
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [hasExcelError, setHasExcelError] = useState<boolean>(false);
  const [excelUploadError, setExcelUploadError] = useState<
    UploadQuotationPricesExcelRows[] | undefined
  >(undefined);

  // Setup uploading product excel
  const [
    uploadQuotationPriceExcel,
    { loading: isUploadingQuotationPriceExcel },
  ] = useUploadQuotationPricesExcelMutation({
    refetchQueries: ["SourcingRequestById"],
    awaitRefetchQueries: true,
  });

  // state change status notice state
  const [statusNotice, setStatusNotice] =
    React.useState<StatusNoticeProps | null>(null);
  const statusNoticeRef = React.useRef<HTMLDivElement>(null);

  // spread props
  const { className, viewer } = props;

  // query data
  const {
    data: serverData,
    loading: isServerDataLoading,
    error: isServerDataError,
  } = useSourcingRequestById({
    variables: { sourcingRequestId },
  });

  // extract data from response
  const sourcingRequest = serverData?.viewer?.sourcingRequestById;
  const sourcingEvent = sourcingRequest?.sourcingEvent;
  const rfx = sourcingEvent?.rfx;
  const organization = sourcingEvent?.organization;
  const sourcingEventCreator = sourcingEvent?.creator;

  // sourcing request values from server
  const quotation = sourcingRequest?.quotation as SourcingEventTemplate;

  const sourcingRequestIsComplete =
    sourcingRequest?.status === SourcingRequestStatusEnum.COMPLETE;

  // initialize form data
  const combinedDefaults: RfqQuotationFormInputs = {
    generalQuestions: quotation?.generalQuestions,
    items: quotation?.items,
    comment: sourcingRequest?.comment ?? null,
  };

  // use form with field array
  const formMethods = useForm<RfqQuotationFormInputs>({
    defaultValues: combinedDefaults,
  });

  const { register, handleSubmit, control, reset } = formMethods;

  // use questionnaire fields array
  const { fields: questionnaireFields } = useFieldArray<
    Unbox<SourcingEventTemplate["generalQuestions"]>
  >({
    control,
    name: "generalQuestions",
  });

  React.useEffect(() => {
    if (combinedDefaults.items) {
      // reset form data when data loads from server
      reset(combinedDefaults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverData]);

  // query to update the sourcing request
  const [
    updateSourcingRequest,
    {
      loading: isUpdateSourcingRequestLoading,
      error: isUpdateSourcingRequestError,
    },
  ] = useUpdateSourcingRequest({
    refetchQueries: ["SourcingRequestById"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setStatusNotice({
        level: EventTypeEnum.SUCCESS,
        eventTitle: "Your quotation data was successfully saved!",
        eventDescription:
          'To send the quotation please press "Send Quotation" button',
      });

      // scroll to status message
      statusNoticeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    onError: () => {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Sorry! There was an error when saving the quotation",
        eventDescription: "Please try again",
      });

      // scroll to status message
      statusNoticeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  });

  // handle saving the form
  const onSave = async (formData: RfqQuotationFormInputs) => {
    const combinedDataWithFilledFormData = combinedFormData({
      filledFormData: formData,
      combinedDefaults,
    });

    await updateSourcingRequest({
      variables: {
        sourcingRequestId,
        quotation: JSON.stringify({
          generalQuestions: combinedDataWithFilledFormData.generalQuestions,
          items: combinedDataWithFilledFormData.items,
        }),
        comment: combinedDataWithFilledFormData.comment,
        attachments: addedFiles ?? [],
      },
      refetchQueries: ["SourcingRequestById"],
      awaitRefetchQueries: true,
    });
  };

  // query to complete the sourcing request
  const [
    completeSourcingRequest,
    {
      loading: isCompleteSourcingRequestLoading,
      error: isCompleteSourcingRequestError,
    },
  ] = useCompleteSourcingRequest({
    refetchQueries: ["SourcingRequestById"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setStatusNotice({
        level: EventTypeEnum.SUCCESS,
        eventTitle: "Thank You! Your quotation has been successfully sent.",
        eventDescription:
          "You can always return to this page using the invitation link sent to you by e-mail",
      });

      // scroll to status message
      statusNoticeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    onError: () => {
      setStatusNotice({
        level: EventTypeEnum.ALERT,
        eventTitle: "Sorry! There was an error when sending the quotation",
        eventDescription: "Please try again",
      });

      // scroll to status message
      statusNoticeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  });

  const onPreview = () => {
    setIsPreview(!isPreview);
  };

  // handle completing/sending the rfq
  const onSendRfq = async (formData: RfqQuotationFormInputs) => {
    const combinedDataWithFilledFormData = combinedFormData({
      filledFormData: formData,
      combinedDefaults,
    });

    await updateSourcingRequest({
      variables: {
        sourcingRequestId,
        quotation: JSON.stringify({
          generalQuestions: combinedDataWithFilledFormData.generalQuestions,
          items: combinedDataWithFilledFormData.items,
        }),
        comment: combinedDataWithFilledFormData.comment,
        attachments: addedFiles ?? [],
      },
    });

    completeSourcingRequest({
      variables: { sourcingRequestId },
    });
  };

  // handle adding files
  const handleFilesAdded = (files: File[] | undefined) => {
    setAddedFiles(files);
  };

  // handles file drop
  const onDropExcel = useCallback<OnDropCallbackFn>(
    async (acceptedFiles) => {
      // return if not exactly one file was provided
      if (acceptedFiles.length !== 1 || !organization || !sourcingRequest) {
        return;
      }

      // Remove error state
      setHasExcelError(false);

      // Upload excel file
      const uploadResult = await uploadQuotationPriceExcel({
        variables: {
          organizationId: organization.id,
          sourcingRequestId: sourcingRequest.id,
          excel: acceptedFiles[0],
        },
        refetchQueries: ["PaginatedPurchaseRequests"],
      });

      // If has invalid result return
      if (!uploadResult.data) return;

      // Has error on excel upload
      if (uploadResult.data.uploadQuotationPricesExcel.success === false) {
        // Set errors
        setExcelUploadError(uploadResult.data.uploadQuotationPricesExcel.rows);
        setIsErrorModalOpen(true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [organization, sourcingRequest],
  );

  // handle server side errors
  if (
    isServerDataError ||
    isUpdateSourcingRequestError ||
    isCompleteSourcingRequestError
  ) {
    setStatusNotice({
      level: EventTypeEnum.ALERT,
      eventTitle:
        "Sorry! There was an error while saving or sending the quotation",
    });

    // scroll to status
    statusNoticeRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  // handle server data missing
  if (
    isServerDataLoading ||
    isUpdateSourcingRequestLoading ||
    isCompleteSourcingRequestLoading ||
    isUploadingQuotationPriceExcel ||
    !serverData
  ) {
    return <LoadingView overlay />;
  }

  if (excelUploadError && isErrorModalOpen) {
    return (
      <Modal
        title="Import Error"
        onCloseRequested={() => setIsErrorModalOpen(false)}
      >
        <div className={styles["excel-item"]}>
          <>
            <FieldError error="Provided spreadsheet file is not valid, please fix the issues listed below and try again" />
            <div className={styles["excel-container"]}>
              <ExcelQuotationPricesImportErrorsTable rows={excelUploadError} />
            </div>
          </>
        </div>
      </Modal>
    );
  }

  return (
    <div
      data-state={sourcingRequest?.status}
      className={classNames(styles["view"], className)}
    >
      <ViewerContextProvider viewer={viewer}>
        {/* header */}
        <Header
          secondary
          organization={organization}
          viewer={viewer}
          title="Supplier Portal"
          isPortal
        >
          <ProfileDropdown viewer={viewer} user={viewer} />
        </Header>

        {/* TODO: menu */}
        <div className={styles["menu"]}></div>

        <div className={styles["content-wrap"]}>
          <div className={styles["content"]}>
            {/* status notice */}
            {statusNotice && (
              <div className={styles["status-wrap"]} ref={statusNoticeRef}>
                <StatusNotice
                  level={statusNotice.level}
                  eventTitle={statusNotice.eventTitle}
                  eventDescription={statusNotice.eventDescription}
                  onCloseRequested={() => {
                    setStatusNotice(null);
                  }}
                />
              </div>
            )}
            <FormSection
              state={
                sourcingRequestIsComplete
                  ? FormSectionState.SUCCESS
                  : FormSectionState.PENDING
              }
            >
              {/* sourcing event info */}
              <SourcingEventInfo
                rfx={rfx}
                sourcingEvent={sourcingEvent}
                sourcingOrganization={organization}
                sourcingEventCreator={sourcingEventCreator}
                isOfferSent={sourcingRequestIsComplete}
                isPreview={isPreview}
                sourcingRequest={sourcingRequest}
                displayContactLink={true}
                displayRequestStatus={true}
              />
            </FormSection>

            {/* form */}
            <form onSubmit={handleSubmit(onSave)}>
              <FormProvider {...formMethods}>
                <FormSection>
                  {/* questionnaire */}
                  <h4 className={styles["heading"]}>1. Questionnaire</h4>
                  {(combinedDefaults?.generalQuestions ?? []).length > 0 ? (
                    <div className={styles["questionnaire"]}>
                      {questionnaireFields.map((field, index) => {
                        return (
                          <div key={index}>
                            <QuestionInput
                              name={`generalQuestions[${index}].answer`}
                              questionText={field.question ?? ""}
                              answerValue={field.answer ?? ""}
                              isEditable={
                                !(sourcingRequestIsComplete || isPreview)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className={styles["questionnaire-no-questions"]}>
                      This questionnaire has no questions
                    </div>
                  )}
                </FormSection>

                <FormSection>
                  {/* quotation */}
                  <div
                    className={classNames(
                      styles["quotation-heading"],
                      styles["quotation-heading--links"],
                    )}
                  >
                    <h4 className={styles["heading"]}>2. Quotation</h4>
                    <div className={styles["links"]}>
                      <Controller
                        className={styles["links--import"]}
                        as={Attachments}
                        control={control}
                        multiple
                        secondary
                        loading={false}
                        accept={[
                          "text/csv",
                          "application/vnd.ms-excel",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ]}
                        onDrop={(acceptedFiles: File[]) =>
                          onDropExcel(acceptedFiles)
                        }
                        name="excel"
                        title="Import from Excel"
                      />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/api/export/sourcing-request/${sourcingRequestId}`}
                        className={styles["excel-link"]}
                      >
                        Export to Excel
                      </a>
                    </div>
                  </div>
                  <div className={styles["items"]}>
                    <RfqItemQuotations
                      isEditable={!(sourcingRequestIsComplete || isPreview)}
                      baseCurrency={organization?.baseCurrency}
                    />
                  </div>
                </FormSection>

                <FormSection>
                  {/* additional info */}
                  <h4 className={styles["heading"]}>3. Additional info</h4>
                  <label
                    className={classNames(styles["label"])}
                    htmlFor={"comment"}
                  >
                    Your comment
                  </label>
                  <textarea
                    rows={3}
                    className={styles["comments-field"]}
                    name={"comment"}
                    readOnly={sourcingRequestIsComplete || isPreview}
                    ref={register()}
                  />
                  <AddFiles
                    attachments={
                      sourcingRequest?.attachments
                        ? sourcingRequest.attachments
                        : []
                    }
                    isEditable={!(sourcingRequestIsComplete || isPreview)}
                    onAddFiles={handleFilesAdded}
                    className={styles["add-files"]}
                  />
                </FormSection>

                {not(
                  new Date(sourcingRequest?.responseDeadline).getTime() <
                    new Date().getTime(),
                ) && (
                  <div className={styles["offer-info"]}>
                    {sourcingRequestIsComplete
                      ? "The link to the completed offer was sent to your e-mail"
                      : "The link of the completed offer will be sent to your e-mail"}
                  </div>
                )}

                {!(sourcingRequestIsComplete || isPreview) &&
                  new Date(sourcingRequest?.responseDeadline).getTime() <
                    new Date().getTime() && (
                    <div className={styles["overdue"]}>
                      The deadline has passed
                    </div>
                  )}

                {/* form actions */}
                {!sourcingRequestIsComplete && (
                  <div className={styles["form-actions"]}>
                    {!isPreview && (
                      <Button secondary onClick={handleSubmit(onSave)}>
                        Save
                      </Button>
                    )}
                    {!isPreview && <Button onClick={onPreview}>Preview</Button>}
                    {isPreview && (
                      <Button secondary onClick={onPreview}>
                        Edit
                      </Button>
                    )}
                    {isPreview && (
                      <Button
                        disabled={
                          new Date(
                            sourcingRequest?.responseDeadline,
                          ).getTime() < new Date().getTime()
                        }
                        onClick={handleSubmit(onSendRfq)}
                      >
                        Send Quotation
                      </Button>
                    )}
                  </div>
                )}
              </FormProvider>
            </form>
          </div>
        </div>
      </ViewerContextProvider>
    </div>
  );
};

/**
 * Combine initial default values with filled in form data
 */
function combinedFormData({
  filledFormData,
  combinedDefaults,
}: {
  filledFormData: RfqQuotationFormInputs;
  combinedDefaults: RfqQuotationFormInputs;
}): RfqQuotationFormInputs {
  return {
    generalQuestions:
      combinedDefaults.generalQuestions?.map((q, idx) => ({
        question: q.question,
        answer: filledFormData.generalQuestions[idx].answer,
      })) ?? [],
    items: combinedDefaults.items?.map((item, itemIndex) => ({
      id: item.id,
      itemData: {
        ...item.itemData,
        supplierCode: {
          value:
            filledFormData.items[itemIndex].itemData.supplierCode.value ?? null,
          isEditable:
            combinedDefaults.items[itemIndex].itemData.supplierCode.isEditable,
        },
      },
      customFields: combinedDefaults.items[itemIndex].customFields ?? [],
      esourcingFields:
        combinedDefaults.items[itemIndex].esourcingFields ?? null,
      itemQuestions:
        combinedDefaults.items[itemIndex].itemQuestions.map((iq, idx) => {
          const answer = Array.isArray(
            filledFormData.items[itemIndex].itemQuestions,
          )
            ? filledFormData.items[itemIndex].itemQuestions[idx]?.answer ?? ""
            : "";

          return {
            question: iq.question,
            answer,
          };
        }) ?? [],
      excludedItemSuppliers:
        filledFormData.items[itemIndex].excludedItemSuppliers ?? [],
      priceInfo:
        combinedDefaults.items[itemIndex].priceInfo.map((pi, idx) => ({
          quantity: pi.quantity,
          price: filledFormData.items[itemIndex].priceInfo[idx].price,
        })) ?? [],
    })),
    comment: filledFormData.comment,
  };
}
