import React from "react";
import classNames from "classnames";
import { RenderLeafProps } from "slate-react";
import styles from "./CustomLeaf.module.scss";

export interface CustomLeafProps extends RenderLeafProps {
  className?: string;
}

export const CustomLeaf: React.FC<CustomLeafProps> = (props) => {
  const { className } = props;

  return (
    <span
      {...props.attributes}
      className={classNames(styles["wrap"], className)}
      style={{ fontWeight: props.leaf.bold ? "bold" : "normal" }}
    >
      {props.children}
    </span>
  );
};
