import { round } from "./round";

let stringValue = "";
export function formatMoney(value: string | number, decimalPlaces = 2) {
  // handle numeric values
  if (typeof value === "number") {
    if (isNaN(value)) {
      return "";
    }

    const rounded = round(value, decimalPlaces);

    // avoid scientific notation for very small numbers
    stringValue =
      rounded < 1
        ? rounded.toFixed(decimalPlaces).replace(/\.?0+$/, "")
        : rounded.toString();

    // detect rounding down to zero
    const isRoundedToZero = value > 0 && stringValue === "0";

    // notify of rounding to zero
    if (isRoundedToZero) {
      return `less than 0.${"0".repeat(decimalPlaces - 1)}1`;
    }
  } else {
    stringValue = value;
  }

  // extract integer and fractional digits
  const containsDecimal = stringValue.indexOf(".") !== -1;
  const tokens = stringValue.split(".");
  const integerDigits = tokens[0];
  const fractionalDigits = tokens[1];

  // add spaces after every block of three integers
  const formattedInteger = integerDigits.replace(/(\d)(?=(\d{3})+$)/g, "$1 ");

  // limit the result to configured decimal places
  const result = containsDecimal
    ? `${formattedInteger}.${fractionalDigits.substr(0, decimalPlaces)}`
    : formattedInteger;

  // return result with notification of approximate rounding if applied
  return result;
}
