export type ValueOf<T> = T[keyof T];

export type Unbox<T> = T extends (infer U)[] ? U : T;

export type Full<T> = {
  [P in keyof T]-?: T[P];
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export function isOfType<T>(
  obj: any,
  predicate: (obj: any) => boolean,
): obj is T {
  return predicate(obj);
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
