import React from "react";
import { PurchaseRequestsDetailsByCodeAttachments } from "../../schema";
import { RequestAttachments } from "../RequestAttachments/RequestAttachments";
import { SectionSubtitle } from "../SectionSubtitle/SectionSubtitle";
import styles from "./RequestDetails.module.scss";

export interface RequestDetailsProps {
  name?: string | null;
  notes?: string | null;
  requestor?: { firstName: string; lastName: string } | null;
  department?: { name: string } | null;
  project?: { name: string } | null;
  costCentre?: { name: string } | null;
  attachments?: PurchaseRequestsDetailsByCodeAttachments[] | null;
}

export const RequestDetails: React.FC<RequestDetailsProps> = ({
  name,
  notes,
  requestor,
  department,
  project,
  costCentre,
  attachments,
}) => {
  return (
    <>
      {name && <SectionSubtitle title="Request name" value={name} />}
      {notes && (
        <SectionSubtitle title="Additional information" value={notes} />
      )}
      <div className={styles["group"]}>
        {requestor && (
          <div className={styles["group-item"]}>
            <SectionSubtitle
              title="Requested by"
              value={`${requestor.firstName} ${requestor.lastName}`}
            />
          </div>
        )}
        {department && (
          <div className={styles["group-item"]}>
            <SectionSubtitle title="Department" value={department.name} />
          </div>
        )}
        {project && (
          <div className={styles["group-item"]}>
            <SectionSubtitle title="Project/Product" value={project.name} />
          </div>
        )}
        {costCentre && (
          <div className={styles["group-item"]}>
            <SectionSubtitle title="Const Centre" value={costCentre.name} />
          </div>
        )}
      </div>
      {attachments && <RequestAttachments attachments={attachments} />}
    </>
  );
};
