import React from "react";
import { UploadProductExcelRows } from "../../schema";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { Description } from "../Description/Description";
import styles from "./ExcelImportErrorsTable.module.scss";

export interface ExcelImportErrorsTableProps {
  rows: UploadProductExcelRows[];
}

export const ExcelProductsImportErrorsTable: React.FC<ExcelImportErrorsTableProps> =
  ({ rows }) => (
    <>
      <BasicTable>
        <thead>
          <tr>
            <Cell header compact>
              Row
            </Cell>
            <Cell header nowrap>
              Name*
            </Cell>
            <Cell header nowrap>
              Code
            </Cell>
            <Cell header nowrap>
              Suggested supplier
            </Cell>
            <Cell header nowrap>
              Supplier code
            </Cell>
            <Cell header nowrap>
              Quantity*
            </Cell>
            <Cell header nowrap>
              Unit*
            </Cell>
            <Cell header nowrap>
              Unit price
            </Cell>
            <Cell header nowrap>
              Currency
            </Cell>
            <Cell header nowrap>
              Expected delivery
            </Cell>
            <Cell header nowrap>
              Product url
            </Cell>
            <Cell header nowrap>
              Additional info
            </Cell>
            <Cell header nowrap>
              Custom field 1
            </Cell>
            <Cell header nowrap>
              Custom field 2
            </Cell>
            <Cell header nowrap>
              Custom field 3
            </Cell>
            <Cell header nowrap>
              Custom field 4
            </Cell>
            <Cell header nowrap>
              Custom field 5
            </Cell>
          </tr>
        </thead>
        <tbody>
          {rows
            .filter((row) => !row.success)
            .map((row) => (
              <tr key={row.row}>
                <Cell right>{row.row}</Cell>
                <Cell
                  center
                  success={row.errors.name === null}
                  error={row.errors.name !== null}
                >
                  {row.errors.name ? (
                    row.errors.name.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.code === null}
                  error={row.errors.code !== null}
                >
                  {row.errors.code ? (
                    row.errors.code.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.suggestedSupplier === null}
                  error={row.errors.suggestedSupplier !== null}
                >
                  {row.errors.suggestedSupplier ? (
                    row.errors.suggestedSupplier.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.supplierCode === null}
                  error={row.errors.supplierCode !== null}
                >
                  {row.errors.supplierCode ? (
                    row.errors.supplierCode.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.quantity === null}
                  error={row.errors.quantity !== null}
                >
                  {row.errors.quantity ? (
                    row.errors.quantity.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.unit === null}
                  error={row.errors.unit !== null}
                >
                  {row.errors.unit ? (
                    row.errors.unit.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.unitPrice === null}
                  error={row.errors.unitPrice !== null}
                >
                  {row.errors.unitPrice ? (
                    row.errors.unitPrice.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.currency === null}
                  error={row.errors.currency !== null}
                >
                  {row.errors.currency ? (
                    row.errors.currency.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.expectedDeliveryDate === null}
                  error={row.errors.expectedDeliveryDate !== null}
                >
                  {row.errors.expectedDeliveryDate ? (
                    row.errors.expectedDeliveryDate.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.productUrl === null}
                  error={row.errors.productUrl !== null}
                >
                  {row.errors.productUrl ? (
                    row.errors.productUrl.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                <Cell
                  center
                  success={row.errors.additionalInfo === null}
                  error={row.errors.additionalInfo !== null}
                >
                  {row.errors.additionalInfo ? (
                    row.errors.additionalInfo.join(", ")
                  ) : (
                    <CheckmarkIcon className={styles.checkmark} />
                  )}
                </Cell>
                {/* <Cell center success={row.errors.customFields === null} error={row.errors.customFields !== null}>
                {row.errors.customFields ? (
                  row.errors.customFields.join(", ")
                ) : (
                  <CheckmarkIcon className={styles.checkmark} />
                )}
              </Cell> */}
              </tr>
            ))}
        </tbody>
      </BasicTable>
      <Description spacious>
        Columns marked with * indicate required values (column order is
        important, header titles are not).
      </Description>
    </>
  );
