import React, { useState } from "react";
import { Route, Switch } from "react-router";
import classNames from "classnames";
import { Column } from "react-table";
import { Avatar } from "../../components/Avatar/Avatar";
import { Button } from "../../components/Button/Button";
import { Chip } from "../../components/Chip/Chip";
import { NotAvailable } from "../../components/NotAvailable/NotAvailable";
import { Row } from "../../components/Row/Row";
import { View } from "../../components/View/View";
import { useRouter } from "../../hooks/useRouter";
import {
  ViewerOrganizations,
  useSrmView,
  ViewerViewer,
  SupplierStatusEnum,
} from "../../schema";
import { formatDate } from "../../services/formatDate";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { DataGrid } from "../../components/DataGrid/DataGrid";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { CreateSupplierModal } from "./CreateSupplierModal";
import styles from "./SuppliersAndContractsListView.module.scss";

export interface SuppliersAndContractsListViewProps {
  organization: ViewerOrganizations;
  viewer: ViewerViewer;
}

export const SuppliersAndContractsListView: React.FC<SuppliersAndContractsListViewProps> =
  ({ organization, viewer }) => {
    const { history } = useRouter();
    const [withArchived, setWithArchived] = useState(false);

    // load suppliers info
    const { data, loading, error } = useSrmView({
      variables: {
        organizationId: organization.id,
        withArchived,
      },
    });

    // handle with archived checkbox logic
    const handleOnWithArchivedClicked = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setWithArchived(e.target.checked);
    };

    // handle error
    if (error) {
      return (
        <ErrorView
          title="Requested organization could not be found"
          error={error}
        />
      );
    }

    // handle loading
    if (loading || !data || !data.viewer) {
      return <LoadingView />;
    }

    const suppliers = data.viewer.organization.suppliers;

    // prepare data for the DataGrid
    const dataGridColumns: Column[] = [
      { Header: "Supplier", accessor: "name" },
      { Header: "", accessor: "status" },
      { Header: "Email", accessor: "email" },
      { Header: "CategoryNames", accessor: "categoryNames" }, // hidden field for the search to work
      { Header: "Category", accessor: "categories" },
      { Header: "CountryName", accessor: "countryName" }, // hidden field for the search to work
      { Header: "Country", accessor: "country" },
      { Header: "Documents", accessor: "attachmentsLength" },
      { Header: "Last activity", accessor: "lastActivityDate" },
      { Header: "Last contact", accessor: "lastContact" },
    ];

    const dataGridData = suppliers.map((supplier) => ({
      id: supplier.id,
      name: supplier.name,
      status: supplier.status && (
        <div
          title={supplier.status}
          className={classNames(styles["status"], {
            [styles["status--new"]]: supplier.status === SupplierStatusEnum.NEW,
            [styles["status--approved"]]:
              supplier.status === SupplierStatusEnum.APPROVED,
            [styles["status--inactive"]]:
              supplier.status === SupplierStatusEnum.INACTIVE,
            [styles["status--archived"]]:
              supplier.status === SupplierStatusEnum.ARCHIVED,
          })}
        >
          {supplier.status === SupplierStatusEnum.ARCHIVED && <ArchiveIcon />}
        </div>
      ),
      email: supplier.defaultContactPerson.email,
      categoryNames: supplier.categories
        .map((category) => category.name)
        .join(", "),
      categories: (
        <div className={styles["supplier-categories-list"]}>
          {supplier.categories.length > 0 &&
            supplier.categories.map((category) => (
              <Chip
                key={category.id}
                id={category.id}
                className={styles["chip"]}
              >
                {category.name}
              </Chip>
            ))}
          {supplier.categories.length === 0 && <NotAvailable alignLeft />}
        </div>
      ),
      countryName: supplier.country?.name || "",
      country: supplier.countryCode ? (
        <img
          src={`https://flagcdn.com/w20/${supplier.countryCode.toLowerCase()}.png`}
          alt={supplier.countryCode}
          title={supplier.countryCode}
          className={styles["center"]}
        />
      ) : (
        <NotAvailable />
      ),
      attachmentsLength: (
        <div className={styles["center-text"]}>
          <div className={classNames(styles["document-count"])}>
            {supplier.attachments.length}
          </div>
        </div>
      ),
      lastActivityDate: (
        <div className={styles["right-text"]}>
          {formatDate(supplier.lastActivityDate)}
        </div>
      ),
      lastContact: (
        <Avatar user={supplier.lastContact} className={styles["center"]} />
      ),
    }));

    return (
      <>
        <View secondary>
          <Row secondary>
            <h1>Suppliers &amp; Contracts</h1>
            <Button
              data-testid="0d7695e2fb"
              className={styles["create-new-supplier"]}
              onClick={() =>
                history.push(
                  `/suppliers-and-contracts/${organization.urlName}/create-new-supplier`,
                )
              }
            >
              Add new supplier
            </Button>
          </Row>
          <DataGrid
            withArchived={withArchived}
            columns={dataGridColumns}
            data={dataGridData}
            withHooks={{ useGlobalFilter: true }}
            styleOverloads={{ row: styles.row }}
            colsToHide={["categoryNames", "countryName"]} // necessary for the global search to work
            onRowClick={(id: string) =>
              history.push(
                `/suppliers-and-contracts/${organization.urlName}/${id}`,
              )
            }
            onWithArchivedClick={handleOnWithArchivedClicked}
          />
        </View>
        <Switch>
          <Route
            exact
            path={`/suppliers-and-contracts/${organization.urlName}/create-new-supplier`}
          >
            <CreateSupplierModal organization={organization} viewer={viewer} />
          </Route>
        </Switch>
      </>
    );
  };
