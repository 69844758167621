import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./Root";
import { initializeGoogleTagManager } from "./services/initializeGoogleTagManager";
import { initializeCrispCustomerChat } from "./services/initializeCrispCustomerChat";
import reportWebVitals from "./reportWebVitals";

initializeGoogleTagManager(process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID);

initializeCrispCustomerChat(process.env.REACT_APP_CRISP_WEBSITE_ID);

// render the root component
ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
