import React from "react";
import classNames from "classnames";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import { UpArrowIcon } from "../../theme/svg/UpArrowIcon";
import styles from "./AccordionTertiary.module.scss";
import { useAccordion } from "./Accordion.headless";

export interface AccordionTertiaryProps {
  isInitiallyOpen?: boolean;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  labelValue?: React.ReactText;
  className?: string;
}

export const AccordionTertiary: React.FC<AccordionTertiaryProps> = (props) => {
  const {
    isInitiallyOpen = true,
    children,
    title,
    labelValue,
    className,
  } = props;

  const { isOpen, toggle } = useAccordion({
    initialState: { isOpen: isInitiallyOpen },
  });

  return (
    <div className={classNames(styles["accordion-container"], className)}>
      <div
        className={classNames(styles["accordion-header"], {
          [styles["accordion-header--open"]]: isOpen,
        })}
        onClick={toggle}
      >
        <div className={styles["header-arrow"]}>
          {isOpen ? <DownArrowIcon /> : <UpArrowIcon />}
        </div>
        <div className={styles["header-title"]}>{title}</div>
        {labelValue && (
          <div className={styles["header-label"]}>{labelValue}</div>
        )}
      </div>

      <div className={styles["accordion-body"]}>{isOpen && children}</div>
    </div>
  );
};
