import React from "react";
import classNames from "classnames";
import { Loading } from "../Loading/Loading";
import { LoadingIcon } from "../../theme/svg/LoadingIcon";
import styles from "./Button.module.scss";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string;
  type?: "submit" | "reset" | "button";
  icon?: React.ReactNode;
  secondary?: boolean;
  tertiary?: boolean;
  quaternary?: boolean;
  quentary?: boolean;
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
  text?: boolean;
  block?: boolean;
  large?: boolean;
  add?: boolean;
  remove?: boolean;
  warning?: boolean;
  push?: boolean;
  reject?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  type,
  block,
  large,
  secondary,
  tertiary,
  quaternary,
  quentary,
  disabled,
  active,
  add,
  remove,
  warning,
  push,
  icon,
  loading,
  text,
  children,
  reject,
  ...rest
}) => {
  const renderContent = () => {
    if (icon) {
      // render icon, wrap icon text in a wrap if string is provided
      return (
        <>
          {loading ? <LoadingIcon /> : icon}
          {typeof children === "string" ? (
            <span className={styles["icon-text"]}>{children}</span>
          ) : (
            children
          )}
        </>
      );
    } else if (loading) {
      // hidden content is also rendered to show the button the same size as original
      return (
        <>
          <Loading small={!large} className={styles["loading-icon"]} />
          <span className={styles["loading-content"]}>{children}</span>
        </>
      );
    } else if (add || remove) {
      // render no content for the add or remove button
      return null;
    }

    return children;
  };

  return (
    <button
      type={type || "button"}
      className={classNames(
        styles.button,
        {
          [styles["button--block"]]: block,
          [styles["button--large"]]: large,
          [styles["button--primary"]]:
            !loading &&
            !secondary &&
            !tertiary &&
            !quaternary &&
            !quentary &&
            !disabled &&
            !add &&
            !icon &&
            !text,
          [styles["button--secondary"]]: secondary && !disabled,
          [styles["button--tertiary"]]: tertiary,
          [styles["button--quaternary"]]: quaternary,
          [styles["button--quentary"]]: quentary,
          [styles["button--active"]]: active,
          [styles["button--warning"]]: warning,
          [styles["button--disabled"]]: disabled || loading,
          [styles["button--add"]]: add && !loading,
          [styles["button--add-loading"]]: add && loading,
          [styles["button--remove"]]: remove && !loading,
          [styles["button--loading"]]: loading,
          [styles["button--icon"]]: icon !== undefined,
          [styles["button--text"]]: text,
          [styles["button--reject"]]: reject,
          [styles.push]: push,
        },
        className,
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {renderContent()}
    </button>
  );
};
