import React from "react";
import classNames from "classnames";
import styles from "./FormSection.module.scss";

export enum FormSectionState {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export interface FormSectionProps {
  className?: string;
  state?: FormSectionState;
  children?: React.ReactNode;
}

export const FormSection: React.FC<FormSectionProps> = (props) => {
  const { children, className, state } = props;

  return (
    <div data-state={state} className={classNames(styles["wrap"], className)}>
      {children}
    </div>
  );
};
