import React, { useState } from "react";
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { View } from "../../../components/View/View";
import { alertNotImplemented } from "../../../services/alertNotImplemented";
import { SwimlaneId } from "../../../constants";
import styles from "./DragDropView.module.scss";

enum DraggableType {
  CARD = "CARD",
  ITEM = "ITEM",
}

interface BaseDraggable {
  id: string;
  type: DraggableType;
}

interface BaseDroppable {
  id: string;
  type: DraggableType;
  items: BaseDraggable[];
}

interface SwimlaneInfo extends BaseDroppable {
  id: SwimlaneId;
  acceptItemsFrom: SwimlaneId[];
  title: string;
  items: CardInfo[];
}

interface CardInfo extends BaseDroppable, BaseDraggable {
  id: string;
  title: string;
  items: ItemInfo[];
}

interface ItemInfo extends BaseDraggable {
  id: string;
  name: string;
}

const initialLanes: SwimlaneInfo[] = [
  {
    id: SwimlaneId.PURCHASE_REQUEST,
    type: DraggableType.CARD,
    title: "Purchase requests",
    acceptItemsFrom: [],
    items: [
      {
        id: "card-pr-1",
        type: DraggableType.CARD,
        title: "Props",
        items: [
          {
            id: "item-1",
            type: DraggableType.ITEM,
            name: "First item",
          },
          {
            id: "item-2",
            type: DraggableType.ITEM,
            name: "Second item",
          },
        ],
      },
      {
        id: "card-pr-2",
        type: DraggableType.CARD,
        title: "Motors",
        items: [
          {
            id: "item-3",
            type: DraggableType.ITEM,
            name: "Third item",
          },
          {
            id: "item-4",
            type: DraggableType.ITEM,
            name: "Fourth item",
          },
        ],
      },
      {
        id: "card-pr-3",
        type: DraggableType.CARD,
        title: "Motor controller",
        items: [
          {
            id: "item-5",
            type: DraggableType.ITEM,
            name: "Fifth item",
          },
          {
            id: "item-6",
            type: DraggableType.ITEM,
            name: "Sixth item",
          },
        ],
      },
    ],
  },
  {
    id: SwimlaneId.RFX,
    type: DraggableType.CARD,
    title: "RFX",
    acceptItemsFrom: [SwimlaneId.PURCHASE_REQUEST],
    items: [
      {
        id: "card-rfx-1",
        type: DraggableType.CARD,
        title: "Frame",
        items: [
          {
            id: "item-7",
            type: DraggableType.ITEM,
            name: "Seventh item",
          },
          {
            id: "item-8",
            type: DraggableType.ITEM,
            name: "Eight item",
          },
        ],
      },
      {
        id: "card-rfx-2",
        type: DraggableType.CARD,
        title: "Battery",
        items: [
          {
            id: "item-9",
            type: DraggableType.ITEM,
            name: "Ninth item",
          },
          {
            id: "item-10",
            type: DraggableType.ITEM,
            name: "Tenth item",
          },
        ],
      },
    ],
  },
];

function getDroppable(
  id: string,
  swimlanes: SwimlaneInfo[],
): BaseDroppable | undefined {
  // resolve droppable type
  const isLane = Object.keys(SwimlaneId).indexOf(id) !== -1;

  // attempt to find droppable by type
  if (isLane) {
    return swimlanes.find((lane) => lane.id === id);
  } else {
    /// must be a card
    for (const lane of swimlanes) {
      const card = lane.items.find((card) => card.id === id);

      if (card !== undefined) {
        return card;
      }
    }

    return undefined;
  }
}

function getCardSwimlane(
  card: CardInfo,
  swimlanes: SwimlaneInfo[],
): SwimlaneInfo | undefined {
  return swimlanes.find(
    (swimlane) =>
      swimlane.items.find((item) => item.id === card.id) !== undefined,
  );
}

interface OnCreateCardFromItemsParameters {
  item: ItemInfo;
  source: CardInfo;
  destination: SwimlaneInfo;
}

export const DragDropView: React.FC = () => {
  const [swimlanes, setSwimlanes] = useState(initialLanes);
  const [draggedItemSwimlaneId, setDraggedItemSwimlaneId] = useState<
    SwimlaneId | undefined
  >(undefined);

  const onCreateCardFromItems = ({
    item,
    source,
    destination,
  }: OnCreateCardFromItemsParameters) => {
    console.log("onCreateCardFromItems", { item, source, destination });

    alertNotImplemented();
  };

  const handlers: DragDropContextProps = {
    children: null,
    /*
    {
      combine: null
      destination: {droppableId: "rfx", index: 1}
      draggableId: "pr-2"
      mode: "FLUID"
      reason: "DROP"
      source: {index: 1, droppableId: "purchase-requests"
    }

    {
      "source": {
        "index": 0,
        "droppableId": "purchase-requests"
      },
      "destination": {
        "droppableId": "purchase-requests",
        "index": 2
      },
      "draggableId": "pr-1",
      "reason": "DROP"
    }
    */
    onBeforeDragStart({ source, type, draggableId, mode }) {
      console.log("onBeforeDragStart", { source, type, draggableId, mode });
    },
    onDragStart({ source, type, draggableId, mode }) {
      const sourceDroppable = getDroppable(source.droppableId, swimlanes);

      console.log("onDragStart", {
        source,
        type,
        draggableId,
        mode,
        sourceDroppable,
      });

      if (type === DraggableType.ITEM) {
        const swimlane = getCardSwimlane(
          sourceDroppable as CardInfo,
          swimlanes,
        );

        // the swimlane sohould exist
        if (!swimlane) {
          throw new Error(
            `Dragged item "${draggableId}" source droppable "${source.droppableId}" parent could not be found, this should not happen`,
          );
        }

        setDraggedItemSwimlaneId(swimlane.id);
      }
    },
    onDragUpdate(info) {
      console.log("onDragUpdate", info);
    },
    onDragEnd({
      source,
      destination,
      draggableId,
      reason /*, combine, mode, type*/,
    }) {
      // not dragging any more, reset drag state
      setDraggedItemSwimlaneId(undefined);

      // don't do anything if the item was dragged outside of any droppables
      if (!destination) {
        return;
      }

      // don't do anything if nothing changed
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      // this will contain updated swimlanes info
      const updatedSwimlanes = [...swimlanes];

      // find the source droppable
      const sourceDroppable = getDroppable(
        source.droppableId,
        updatedSwimlanes,
      );

      // handle source lane not found, should not really happen
      if (!sourceDroppable) {
        throw new Error(
          `Source lane "${source.droppableId}" could not be found, this should not happen`,
        );
      }

      // find dragged item
      const draggedItem = sourceDroppable.items.find(
        (item) => item.id === draggableId,
      );

      // handle dragged item not found, should not really happen
      if (!draggedItem) {
        throw new Error(
          `Source item "${draggableId}" could not be found, this should not happen`,
        );
      }

      const destinationSuffix = destination.droppableId.substring(
        destination.droppableId.length - 4,
      );

      // handle creating card from items
      if (destinationSuffix === "-new") {
        const baseDestinationId = destination.droppableId.substring(
          0,
          destination.droppableId.length - 4,
        );
        const destinationDroppable = getDroppable(
          baseDestinationId,
          updatedSwimlanes,
        );

        // handle destination lane not found, should not really happen
        if (!destinationDroppable) {
          throw new Error(
            `Destination lane "${destination.droppableId}" could not be found, this should not happen`,
          );
        }

        // call the create card from items callback
        onCreateCardFromItems({
          item: draggedItem as ItemInfo,
          source: sourceDroppable as CardInfo,
          destination: destinationDroppable as SwimlaneInfo,
        });

        return;
      }

      const destinationDroppable = getDroppable(
        destination.droppableId,
        updatedSwimlanes,
      );

      // handle destination droppable not found, should not really happen
      if (!destinationDroppable) {
        throw new Error(
          `Destination droppable "${destination.droppableId}" could not be found, this should not happen`,
        );
      }

      // remove item from source lane
      sourceDroppable.items.splice(source.index, 1);

      // add the item to the destination lane at requested index
      destinationDroppable.items.splice(destination.index, 0, draggedItem);

      // update data
      setSwimlanes(updatedSwimlanes);

      console.log("onDragEnd", {
        source,
        destination,
        draggableId,
        reason,
        draggedItem,
        sourceLane: sourceDroppable,
        destinationLane: destinationDroppable,
      });
    },
  };

  return (
    <View>
      <h1>
        <Link data-testid="25b142d8cc" to="/experiments">
          Experiments
        </Link>{" "}
        / Drag and Drop
      </h1>
      <div className={styles.wrap}>
        <DragDropContext {...handlers}>
          {swimlanes.map((swimlane) => (
            <Swimlane
              key={swimlane.id}
              info={swimlane}
              isAcceptingCreateFromItem={
                draggedItemSwimlaneId !== undefined &&
                swimlane.acceptItemsFrom.includes(draggedItemSwimlaneId)
              }
            />
          ))}
        </DragDropContext>
      </div>
      {/* <Debug>{data}</Debug> */}
    </View>
  );
};

type SwimlaneProps = {
  info: SwimlaneInfo;
  isAcceptingCreateFromItem: boolean;
};

function Swimlane({ info, isAcceptingCreateFromItem }: SwimlaneProps) {
  return (
    <Droppable droppableId={info.id} type={DraggableType.CARD}>
      {(
        { innerRef, droppableProps, placeholder },
        { isDraggingOver /*, draggingOverWith, draggingFromThisWith*/ },
      ) => (
        <div
          className={classNames(styles.swimlane, {
            [styles["swimlane--over"]]: isDraggingOver,
          })}
        >
          <h2>{info.title}</h2>
          <Droppable
            droppableId={`${info.id}-new`}
            type={DraggableType.ITEM}
            isDropDisabled={!isAcceptingCreateFromItem}
          >
            {(
              {
                innerRef: subInnerRef,
                droppableProps: subDroppableProps,
                placeholder: subPlaceholder,
              },
              { isDraggingOver: subIsDraggingOver },
            ) => (
              <div
                ref={subInnerRef}
                {...subDroppableProps}
                className={classNames(styles["create-new-card"], {
                  [styles[
                    "create-new-card--visible"
                  ]]: isAcceptingCreateFromItem,
                  [styles["create-new-card--over"]]: subIsDraggingOver,
                })}
              >
                Drag purchase request item here to create a new RFX
                {subPlaceholder}
              </div>
            )}
          </Droppable>
          <div
            className={styles["swimlane-droppable"]}
            ref={innerRef}
            {...droppableProps}
          >
            {info.items.map((card, index) => (
              <Card key={card.id} info={card} index={index} />
            ))}
          </div>

          {placeholder}
          {/* <Debug>{{ isDraggingOver, draggingOverWith, draggingFromThisWith }}</Debug> */}
        </div>
      )}
    </Droppable>
  );
}

interface CardProps {
  info: CardInfo;
  index: number;
}

function Card({ info, index }: CardProps) {
  return (
    <Draggable draggableId={info.id} index={index}>
      {(
        { innerRef, draggableProps, dragHandleProps },
        { isDragging /*, isDropAnimating, mode, draggingOver*/ },
      ) => (
        <div
          className={classNames(styles.card, {
            [styles["card--dragging"]]: isDragging,
          })}
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
        >
          <h3>{info.title}</h3>
          <Droppable droppableId={info.id} type={DraggableType.ITEM}>
            {(
              { innerRef, droppableProps, placeholder },
              { isDraggingOver /*, draggingOverWith, draggingFromThisWith*/ },
            ) => (
              <div
                ref={innerRef}
                {...droppableProps}
                className={classNames(styles["card-items"], {
                  [styles["card-items--over"]]: isDraggingOver,
                })}
              >
                {info.items.map((item, index) => (
                  <Item key={item.id} info={item} index={index} />
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
          {/* <Debug title={title}>{{ index, id, title, items, isDragging, isDropAnimating, mode, draggingOver }}</Debug> */}
        </div>
      )}
    </Draggable>
  );
}

interface ItemProps {
  info: ItemInfo;
  index: number;
}

function Item({ info, index }: ItemProps) {
  return (
    <Draggable draggableId={info.id} index={index}>
      {(
        { innerRef, draggableProps, dragHandleProps },
        { isDragging /*, isDropAnimating, mode, draggingOver*/ },
      ) => (
        <div
          className={classNames(styles.item, {
            [styles["item--dragging"]]: isDragging,
          })}
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
        >
          <h4>{info.name}</h4>
          {/* <Debug>{{ index, id, name, isDragging, isDropAnimating, mode, draggingOver }}</Debug> */}
        </div>
      )}
    </Draggable>
  );
}
