import React from "react";
import {
  OrderTypeEnum,
  ViewerOrganizations,
  useOrderByCode,
  ViewerViewer,
} from "../../schema";
import { assertUnreachable } from "../../services/assertUnreachable";
import { Modal } from "../../components/Modal/Modal";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { filterUsersWhoCanBeAssignedToCards } from "../KanbanView/KanbanView";
import { InvoiceModal } from "./InvoiceModal";
import { ReceivingModal } from "./ReceivingModal";
import { PurchaseOrderModal } from "./PurchaseOrderModal";

export interface OrderProgressViewProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  code: string;
  onModalClose(): void;
}

export const OrderProgressView: React.FC<OrderProgressViewProps> = ({
  viewer,
  organization,
  code,
  onModalClose,
}) => {
  // attempt to load order info
  const { data, error, loading } = useOrderByCode({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      code,
      withDeleted: true,
    },
  });

  // handle loading rfx error
  if (error) {
    return (
      <Modal wide title="Loading order failed" onCloseRequested={onModalClose}>
        <ErrorView error="Requested order could not be found" />
      </Modal>
    );
  }

  // extract loaded data
  const order = data?.viewer?.orderByCode;
  const users = data?.viewer?.organization.users ?? [];
  const countries = data?.viewer?.countries ?? [];
  const suppliers = data?.viewer?.organization.suppliers ?? [];
  const addresses = data?.viewer?.organization.addresses ?? [];

  const usersWhoCanBeAssignedToCards =
    filterUsersWhoCanBeAssignedToCards(users);

  // handle loading
  if (loading || !order || !users) {
    return <LoadingView overlay />;
  }

  // render correct order modal based on type
  switch (order.type) {
    case OrderTypeEnum.PURCHASE_ORDER:
      return (
        <PurchaseOrderModal
          viewer={viewer}
          organization={organization}
          order={order}
          users={users}
          usersWhoCanBeAssignedToCards={usersWhoCanBeAssignedToCards}
          countries={countries}
          suppliers={suppliers}
          addresses={addresses}
          onModalClose={onModalClose}
        />
      );

    case OrderTypeEnum.RECEIVING:
      return (
        <ReceivingModal
          viewer={viewer}
          organization={organization}
          order={order}
          users={users}
          usersWhoCanBeAssignedToCards={usersWhoCanBeAssignedToCards}
          countries={countries}
          suppliers={suppliers}
          onModalClose={onModalClose}
        />
      );

    case OrderTypeEnum.INVOICE:
      return (
        <InvoiceModal
          viewer={viewer}
          organization={organization}
          order={order}
          users={users}
          usersWhoCanBeAssignedToCards={usersWhoCanBeAssignedToCards}
          countries={countries}
          suppliers={suppliers}
          onModalClose={onModalClose}
        />
      );

    default:
      return assertUnreachable(
        order.type,
        `Order type "${order.type}" is not handled, this should not happen`,
      );
  }
};
