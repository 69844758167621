import React, { forwardRef } from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";

import { Field } from "../Field/Field";

import styles from "./EsourcingQuestionFieldList.module.scss";

export interface EsourcingFieldListProps {
  className?: string;
}

export const EsourcingFieldList = forwardRef(
  (props: EsourcingFieldListProps) => {
    const { className } = props;

    const { register } = useFormContext();

    const { fields: questionFields } = useFieldArray({
      name: `esourcingFields.questions`,
    });

    const { fields: quantityFields } = useFieldArray({
      name: `esourcingFields.quantities`,
    });

    return (
      <div className={classNames(className)}>
        {questionFields.map((question, questionIndex) => {
          return (
            <div key={question.id} className={styles["esourcing-field"]}>
              <Controller
                as={Field}
                key={questionIndex}
                name={`esourcingFields.questions[${questionIndex}].question`}
                label={`eSourcing Questions ${questionIndex + 1}`}
                inputRef={register()}
                short
              />
            </div>
          );
        })}
        {quantityFields.map((quantity, quantityIndex) => {
          return (
            <div key={quantity.id} className={styles["esourcing-field"]}>
              <Controller
                as={Field}
                key={quantityIndex}
                name={`esourcingFields.quantities[${quantityIndex}].quantity`}
                label={`eSourcing Quantity ${quantityIndex + 1}`}
                inputRef={register()}
                short
              />
            </div>
          );
        })}
      </div>
    );
  },
);
