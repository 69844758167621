import React, { useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "./AnimatedList.module.scss";

export interface AnimatedListProps {
  className?: string;
  nextRender?: { id: string | number; component: React.ReactElement };
}

export const AnimatedList: React.FC<AnimatedListProps> = ({
  className,
  nextRender,
}) => {
  const [visibleItems, setVisibleItems] = useState(
    nextRender ? [nextRender] : [],
  );
  const [itemToRemove, setItemToRemove] = useState<string | number>();

  React.useEffect(() => {
    if (nextRender) {
      // add component if it isn't already added or is to be removed
      const shouldDisplayComponent =
        visibleItems.every((item) => item.id !== nextRender.id) &&
        nextRender.id !== itemToRemove;
      shouldDisplayComponent && setVisibleItems([...visibleItems, nextRender]);
    }
  }, [visibleItems, nextRender, itemToRemove]);

  const removeItem = (removeId: React.ReactText) => {
    return () => {
      setItemToRemove(removeId);
      const newItems = visibleItems.filter((item) => item.id !== removeId);
      setVisibleItems(newItems);
    };
  };

  return (
    <div className={className}>
      <TransitionGroup>
        {visibleItems.map((item) => (
          <CSSTransition
            key={item.id}
            in={true}
            timeout={500}
            classNames={{
              enter: styles["list-enter"],
              enterActive: styles["list-enter-active"],
              exit: styles["list-exit"],
              exitActive: styles["list-exit-active"],
            }}
          >
            <div key={item.id}>
              {React.cloneElement(item.component, {
                onCloseRequested: removeItem(item.id),
              })}
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
