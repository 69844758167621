import React from "react";
import classNames from "classnames";
import { ApolloError } from "apollo-client";
import { formatDistance } from "date-fns";
import { NotificationIcon } from "../../theme/svg/NotificationIcon";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownSelector,
} from "../../components/Dropdown/Dropdown";
import { LoadingIcon } from "../../theme/svg/LoadingIcon";
import { formatDate } from "../../services/formatDate";
import {
  useMarkAllNotificationsSeen,
  useMarkNotificationsSeenByIds,
  ViewerOrganizations,
} from "../../schema";
import { useRouter } from "../../hooks/useRouter";
import { assertUnreachable } from "../../services/assertUnreachable";
import { CommentIcon } from "../../theme/svg/CommentIcon";
import { MarkAsReadIcon } from "../../theme/svg/MarkAsReadIcon";
import styles from "./NotificationDropdown.module.scss";

export enum NotificationItemType {
  COMMENT = "COMMENT",
  INFO = "INFO",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

// TODO: will probably include more info about the user
export interface NotificationUser {
  name: string;
}

export interface NotificationItem {
  id: string;
  type: NotificationItemType;
  user: NotificationUser | null;
  text: React.ReactNode;
  code: string | null;
  hasSeen: boolean;
  date: Date;
}

export interface NotificationDropdownProps {
  activeOrganization: ViewerOrganizations;
  notifications: NotificationItem[];
  error?: ApolloError;
  loading?: boolean;
}

const MessageIcon: React.FC<{ className?: string }> = ({ className }) => (
  <span className={classNames(styles["list-item__label"], className)} />
);

export const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
  activeOrganization,
  notifications,
  // TODO: handle error
  error: _error,
  loading,
}) => {
  const { history } = useRouter();
  const [isOpen, setIsOpen] = React.useState(false);
  const [markNotificationsSeenByIds] = useMarkNotificationsSeenByIds();
  const [markAllNotificationsSeen] = useMarkAllNotificationsSeen();

  function toggleNotifications() {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  const handleOnClick = async (notification: NotificationItem) => {
    await markNotificationsSeenByIds({
      refetchQueries: ["Notifications"],
      variables: {
        notificationIds: [notification.id],
      },
    });

    if (notification.code) {
      history.push(`/${activeOrganization.urlName}/${notification.code}`);
    }

    // if its the last notification close the dropdown
    if (notifications.length <= 1) {
      setIsOpen(false);
    }
  };

  const onMarkAllNotificationsSeen = async () => {
    await markAllNotificationsSeen({
      refetchQueries: ["Notifications"],
      variables: {
        organizationId: activeOrganization.id,
      },
    });

    // close notifications dropdown
    setIsOpen(false);
  };

  // store unseen message count
  const unseenMessageCount = notifications.filter(
    (notification) => !notification.hasSeen,
  ).length;

  return (
    <Dropdown
      data-testid="685b022e28"
      onCloseRequested={() => setIsOpen(false)}
      className={styles.dropdown}
    >
      <DropdownSelector
        onClick={() => toggleNotifications()}
        className={styles.selector}
      >
        {loading ? (
          <LoadingIcon className={styles.loader} />
        ) : (
          <span
            className={classNames(styles.counter, {
              [styles["counter--active"]]: true,
            })}
          >
            {unseenMessageCount}
          </span>
        )}
        <NotificationIcon
          className={classNames(styles.icon, {
            [styles["icon--active"]]: isOpen || notifications.length <= 0,
          })}
        />
      </DropdownSelector>
      <DropdownContent
        open={isOpen}
        className={styles.content}
        footerAddon={
          <DropdownItem>
            <button
              data-testid="90a875c7c3"
              type="button"
              className={styles["expand-list"]}
              onClick={() => onMarkAllNotificationsSeen()}
            >
              Mark all as read
            </button>
          </DropdownItem>
        }
      >
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <DropdownItem
              key={notification.id}
              className={styles["list-item"]}
              onClick={() => handleOnClick(notification)}
            >
              {getNotificationItemIcon(notification.type, notification.hasSeen)}
              <span className={styles.username}>
                {notification.user?.name || "Someone"}
              </span>{" "}
              {notification.text}
              <div
                className={styles.date}
                title={formatDate(notification.date)}
              >
                {formatDistance(new Date(notification.date), new Date(), {
                  addSuffix: true,
                })}
              </div>
            </DropdownItem>
          ))
        ) : (
          <p className={styles["notifications-empty"]}>
            No notifications found
          </p>
        )}
      </DropdownContent>
    </Dropdown>
  );
};

function getNotificationItemIcon(type: NotificationItemType, hasSeen: boolean) {
  switch (type) {
    case NotificationItemType.INFO:
      return (
        <MessageIcon
          className={classNames({
            [styles["list-item__label--success"]]: !hasSeen,
          })}
        />
      );

    case NotificationItemType.SUCCESS:
      return (
        <MessageIcon
          className={classNames({
            [styles["list-item__label--success"]]: !hasSeen,
          })}
        />
      );

    case NotificationItemType.ERROR:
      return (
        <MessageIcon
          className={classNames({
            [styles["list-item__label--error"]]: !hasSeen,
          })}
        />
      );

    case NotificationItemType.COMMENT:
      return (
        <CommentIcon
          className={classNames(styles["list-item__icon"], {
            [styles["list-item__icon--success"]]: !hasSeen,
          })}
        />
      );

    default:
      return assertUnreachable(
        type,
        `Notification action type "${type}" is not handled, this should not happen`,
      );
  }
}
