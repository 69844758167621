import React from "react";
import classNames from "classnames";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

export function renderHighlighted(
  text: string,
  query: string,
  highlightClassname: string,
) {
  const matches = AutosuggestHighlightMatch(text, query);
  const parts = AutosuggestHighlightParse(text, matches);

  return parts.map((part, index) => (
    <span
      className={classNames({
        [highlightClassname]: part.highlight,
      })}
      key={index}
    >
      {part.text}
    </span>
  ));
}
