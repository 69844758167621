import * as React from "react";
export const LogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 39 39" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M28.88 27.102l-6.875-6.875c-.61-.61-.61-1.567 0-2.089l6.874-6.875c.61-.609 1.567-.609 2.089 0l6.875 6.875c.609.61.609 1.567 0 2.089l-6.875 6.875c-.61.522-1.566.522-2.089 0z"
        fill="#5EBE3F"
      />
      <path
        d="M27.052 28.842l-6.788-6.788c-.61-.609-1.566-.609-2.088 0L11.3 28.93c-.61.61-.61 1.567 0 2.089l6.875 6.875c.609.609 1.566.609 2.088 0l6.875-6.875a1.61 1.61 0 00-.087-2.176zm-1.915 1.915l-1.044 1.13c-.174.175-.522.175-.696 0l-6.092-6.09c-.174-.175-.174-.523 0-.697l1.045-1.044c.174-.174.522-.174.696 0l6.091 6.091c.174.088.174.436 0 .61z"
        fill="#009CDF"
        fillRule="nonzero"
      />
      <path
        d="M27.052 7.434L20.264.56c-.61-.609-1.566-.609-2.088 0L.596 18.14c-.608.608-.608 1.566 0 2.088l6.876 6.875c.609.609 1.566.609 2.088 0L27.14 9.522c.522-.608.522-1.566-.087-2.088zm-4.7 4.7l-1.044 1.044c-.174.174-.522.174-.696 0l-6.091-6.092c-.175-.174-.175-.522 0-.696l1.044-1.044c.174-.174.522-.174.696 0l6.092 6.091c.174.174.174.523 0 .697zm2.785-2.785l-1.044 1.044c-.174.174-.522.174-.696 0l-6.092-6.092c-.174-.174-.174-.522 0-.696l1.045-1.044c.174-.174.522-.174.696 0l6.091 6.092c.174.174.174.522 0 .696z"
        fill="#FEB900"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
