import React from "react";
import classNames from "classnames";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { Attachment } from "../../schema";
import { formatFilesize } from "../../services/formatFilesize";
import styles from "./UploadedAttachment.module.scss";

export interface UploadedAttachmentProps {
  attachment: Pick<Attachment, "id" | "filename" | "url"> & { size?: number };
  isEditable?: boolean;
  secondary?: boolean;
  onRemove?(): void;
}

export const UploadedAttachment: React.FC<UploadedAttachmentProps> = ({
  attachment,
  isEditable = true,
  secondary,
  onRemove,
}) => (
  <>
    {attachment.url && (
      <div className={styles["url-attachment"]} key={attachment.id}>
        {isEditable && onRemove && (
          <DeleteFileIcon
            className={classNames(styles["delete-file-icon"])}
            onClick={() => {
              onRemove();
            }}
          />
        )}
        <a
          href={attachment.url}
          className={styles["url-attachment-link"]}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span
            className={classNames(styles["filename"], {
              [styles["filename--secondary"]]: secondary,
            })}
          >
            {attachment.filename}
          </span>
          <OpenExternalIcon className={styles["open-external-icon"]} />
        </a>
        {attachment.size && (
          <span className={styles["file-size"]}>
            ( {formatFilesize(attachment.size)} )
          </span>
        )}
      </div>
    )}
  </>
);
