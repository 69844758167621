import React from "react";
import classNames from "classnames";
import { CloseIcon } from "../../theme/svg/CloseIcon";
import styles from "./Notification.module.scss";

export enum NotificationLevel {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export interface NotificationProps {
  show: boolean;
  warning?: boolean;
  onCloseRequested: () => void;
}

export const Notification: React.FC<NotificationProps> = ({
  show,
  warning,
  onCloseRequested,
  children,
}) => {
  return (
    <div
      className={classNames(styles.notification, {
        [styles.closed]: !show,
        [styles["notification--warning"]]: warning,
      })}
    >
      <div className={styles.message}>{children}</div>
      <div
        data-testid="60ed39b57a"
        title="Close notification"
        className={styles["close-wrap"]}
        onClick={onCloseRequested}
      >
        <CloseIcon className={styles["close-icon"]} />
      </div>
    </div>
  );
};
