import React from "react";
import classNames from "classnames";
import { Column, useTable, useGlobalFilter, useRowSelect } from "react-table";
import styles from "./DataGrid.module.scss";
import { DataGridGlobalFilter } from "./subcomponents/DataGridGlobalFilter/DataGridGlobalFilter";
import { useIndeterminateCheckbox } from "./subcomponents/IndeterminateCheckbox/IndeterminateCheckbox";

export interface DataGridProps<D extends Record<string, unknown>> {
  styleOverloads?: Partial<typeof styles>;
  columns: Column<D>[];
  data: D[];
  withHooks?: { useGlobalFilter?: boolean; useRowSelect?: boolean };
  colsToHide?: string[];
  withArchived?: boolean;
  onRowClick?: (id: string) => void;
  onWithArchivedClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function DataGrid<D extends Record<string, unknown>>(
  props: DataGridProps<D>,
) {
  const {
    styleOverloads,
    columns,
    data,
    withHooks,
    colsToHide = [],
    withArchived = false,
    onRowClick,
    onWithArchivedClick,
  } = props;

  // switch on hooks based on props
  const hooks = [
    ...(withHooks?.useGlobalFilter ? [useGlobalFilter] : []),
    ...(withHooks?.useRowSelect ? [useRowSelect] : []),
  ];

  const tableInstance = useTable<D>(
    {
      columns,
      data,
      initialState: { hiddenColumns: colsToHide },
    },
    ...hooks,
    ...(withHooks?.useRowSelect ? [useIndeterminateCheckbox] : []),
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div
      className={classNames(
        styles["datagrid-wrap"],
        styleOverloads?.["datagrid-wrap"],
      )}
    >
      {hooks.length > 0 && (
        <div
          className={classNames(
            styles["action-bar"],
            styleOverloads?.["action-bar"],
          )}
        >
          {hooks.includes(useGlobalFilter) && (
            <DataGridGlobalFilter
              withArchived={withArchived}
              tableInstance={tableInstance}
              onWithArchivedClick={onWithArchivedClick}
            />
          )}
        </div>
      )}
      <table
        {...getTableProps()}
        className={classNames(styles["table"], styleOverloads?.["table"])}
      >
        <thead
          className={classNames(styles["thead"], styleOverloads?.["thead"])}
        >
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, idx) => (
                <th
                  {...column.getHeaderProps()}
                  key={idx}
                  className={classNames(styles["th"], styleOverloads?.["th"])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={classNames(styles["tbody"], styleOverloads?.["tbody"])}
        >
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={idx}
                className={classNames(styles["row"], styleOverloads?.["row"])}
                onClick={() => {
                  typeof onRowClick === "function" && row.original.id
                    ? onRowClick(row.original.id as string)
                    : null;
                }}
              >
                {row.cells.map((cell, idx) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={idx}
                      className={classNames(
                        styles["td"],
                        styleOverloads?.["td"],
                      )}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
