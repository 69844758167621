import React from "react";
import { ViewerOrganizations, ViewerViewer, UserRoleEnum } from "../../schema";
import { Header } from "../Header/Header";
import { MenuPortal } from "../MenuPortal/MenuPortal";
import { ProfileDropdown } from "../ProfileDropdown/ProfileDropdown";
import styles from "./PortalLayout.module.scss";

interface RequesterPortalLayoutProps {
  children: JSX.Element;
  hasKanbanAccess: boolean;
  inProgressApprovalsRequests?: number;
  inProgressPurchaseRequests?: number;
  organization: ViewerOrganizations;
  viewer: ViewerViewer;
}

export const PortalLayout: React.FC<RequesterPortalLayoutProps> = ({
  children,
  hasKanbanAccess,
  inProgressApprovalsRequests,
  inProgressPurchaseRequests,
  organization,
  viewer,
}) => {
  const hasApproverRole = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER, UserRoleEnum.APPROVER].includes(
      role,
    ),
  );
  const hasRequesterRole = viewer.roles.some((role) =>
    [
      UserRoleEnum.ADMIN,
      UserRoleEnum.KEY_USER,
      UserRoleEnum.REQUESTER,
    ].includes(role),
  );

  return (
    <div className={styles.view}>
      <Header secondary organization={organization} viewer={viewer} isPortal>
        <ProfileDropdown
          viewer={viewer}
          user={viewer}
          linkToTeamBoard={
            hasKanbanAccess ? `/${organization.urlName}` : undefined
          }
          linkToHelpCenter={
            hasKanbanAccess
              ? "https://www.procurementflow.com/helpcenter"
              : undefined
          }
        />
      </Header>
      <MenuPortal
        activeOrganization={organization}
        approvalsRequests={inProgressApprovalsRequests}
        purchaseRequests={inProgressPurchaseRequests}
        hasApproverRole={hasApproverRole}
        hasRequesterRole={hasRequesterRole}
      />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
