import React from "react";
import classNames from "classnames";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { TriangleArrowIcon } from "../../theme/svg/TriangleArrowIcon";
import { CardStatusLevelEnum } from "../../schema";
import styles from "./LabelDropdown.module.scss";

export interface LabelDropdownItem {
  id: string | number;
  icon?: string | JSX.Element;
  text: React.ReactNode;
  onClick: () => void;
}

interface LabelDropdownColorScheme extends React.CSSProperties {
  "--backgroundColor"?: string;
  "--labelTextColor"?: string;
}

export interface LabelDropdownProps {
  className?: string;
  label: string;
  level?: CardStatusLevelEnum;
  dropdownItems: LabelDropdownItem[];
}

export const LabelDropdown: React.FC<LabelDropdownProps> = ({
  className,
  label,
  level = CardStatusLevelEnum.DEFAULT,
  dropdownItems,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const hasDropdownItems = dropdownItems.length >= 1;

  function toggleDropdown() {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  const levelToColorScheme: { [key: string]: LabelDropdownColorScheme } = {
    [CardStatusLevelEnum.DEFAULT]: {
      "--backgroundColor": styles.labelDefault,
      "--labelTextColor": styles.colorLight,
    },
    [CardStatusLevelEnum.ALERT]: {
      "--backgroundColor": styles.labelAlert,
      "--labelTextColor": styles.colorLight,
    },
    [CardStatusLevelEnum.INFO]: {
      "--backgroundColor": styles.labelInfo,
      "--labelTextColor": styles.colorLight,
    },
    [CardStatusLevelEnum.PINK]: {
      "--backgroundColor": styles.labelPink,
      "--labelTextColor": styles.colorLight,
    },
    [CardStatusLevelEnum.SUCCESS]: {
      "--backgroundColor": styles.labelSuccess,
      "--labelTextColor": styles.colorLight,
    },
    [CardStatusLevelEnum.WARNING]: {
      "--backgroundColor": styles.labelWarning,
      "--labelTextColor": styles.colorLight,
    },
  };

  return (
    <div
      className={classNames(
        styles["label-dropdown"],
        { [styles["label-dropdown--open"]]: isOpen },
        className,
      )}
      style={level ? levelToColorScheme[level] : levelToColorScheme.DEFAULT}
    >
      <Dropdown
        className={styles.dropdown}
        data-testid="1f54a6fa8af8f"
        onCloseRequested={() => setIsOpen(false)}
      >
        <div className={styles["dropdown-selector-container"]}>
          <div className={styles["dropdown-selector-item"]}>{label}</div>
          {hasDropdownItems && (
            <div
              onClick={() => toggleDropdown()}
              className={styles["dropdown-button-container"]}
            >
              <DropdownSelector
                onClick={() => toggleDropdown()}
                className={styles["dropdown-selector-button"]}
              >
                <TriangleArrowIcon className={styles["triangle-arrow-icon"]} />
              </DropdownSelector>
            </div>
          )}
        </div>
        <DropdownContent
          open={isOpen}
          className={classNames(styles["dropdown-content"])}
        >
          {dropdownItems.map((item) => (
            <DropdownItem
              key={item.id}
              className={styles["dropdown-item"]}
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
            >
              <div className={styles["dropdown-item-icon"]}>{item.icon}</div>
              <div className={styles["dropdown-item-text"]}>{item.text}</div>
            </DropdownItem>
          ))}
        </DropdownContent>
      </Dropdown>
    </div>
  );
};
