import { SwimlaneId } from "../constants";
import { assertUnreachable } from "./assertUnreachable";

export function getCodePrefixBySwimlaneId(swimlaneId: SwimlaneId) {
  switch (swimlaneId) {
    case SwimlaneId.PURCHASE_REQUEST: {
      return "PR";
    }

    case SwimlaneId.RFX: {
      return "RFX";
    }

    case SwimlaneId.PURCHASE_ORDER:
    case SwimlaneId.RECEIVING:
    case SwimlaneId.INVOICE: {
      return "PO";
    }

    default:
      return assertUnreachable(
        swimlaneId,
        `Unhandled swimlane value: ${swimlaneId}`,
      );
  }
}
