import { UserInfoFragment } from "../schema";
import { AutosuggestItem } from "../components/ItemAutosuggest/ItemAutosuggest";

export function userToAutosuggestItem(user: UserInfoFragment): AutosuggestItem {
  return {
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    avatarUrl: user.avatarUrl,
  };
}
