import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { GraphLineIcon } from "../../theme/svg/GraphLineIcon";
import { Avatar } from "../Avatar/Avatar";
import { Button } from "../Button/Button";
import {
  ActivityTypeEnum,
  CreateCommentMutation,
  CreateCommentVariables,
  useCreateComment,
  ActivityFeedItemFragment,
  ActivityRelativeTypeEnum,
} from "../../schema";
import FeedUpdateReplyInput from "../FeedUpdateReplyInput/FeedUpdateReplyInput";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { getMessageByActivity } from "../../services/getMessageByActivity";
import { Loading } from "../Loading/Loading";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { ActivityRefsContext } from "../CollaborationSidebar/CollaborationSidebar";
import { useRouter } from "../../hooks/useRouter";
import { OrganizationContext } from "../../views/KanbanView/KanbanView";
import { useLatestActivity } from "../../contexts/latest-activity-context";
import { renderReply } from "../../services/renderReply";
import styles from "./FeedUpdate.module.scss";

export interface FeedUpdateProps {
  activity: ActivityFeedItemFragment;
  setShowLoader: (loadingState: boolean) => void;
}

export const FeedUpdate: React.FC<FeedUpdateProps> = ({
  activity,
  setShowLoader,
}) => {
  const { history } = useRouter();

  const [showReplyForm, setShowReplyForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const organization = useContext(OrganizationContext);
  const activityRefs = useContext(ActivityRefsContext);
  const latestActivity = useLatestActivity();

  // setup comment reply form and mutation fn
  const {
    useInput,
    reset,
    submit,
    loading: sendingReply,
    error,
  } = useForm<CreateCommentMutation, CreateCommentVariables>({
    mutation: useCreateComment,
    options: {
      refetchQueries: ["RfxByCode", "OrderByCode"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => {
      reset();
      setShowLoader(false);
    },
  });

  // set up reply input field
  const replyInput = useInput({ name: "comment" });

  // set up reply submit mutation function with activity data
  const createReplyWithOverrides =
    (overrides: Partial<CreateCommentVariables>) => () => {
      setErrorMessage(null);
      setShowLoader(true);
      submit(overrides);
    };

  useEffect(() => {
    if (error) {
      setShowLoader(false);
      setShowReplyForm(true);
      setErrorMessage("Sorry! Something went wrong. Please try again.");
    }
  }, [error, setShowLoader]);

  const renderActivityAvatarIcon = (activityType: ActivityTypeEnum) => {
    switch (activityType) {
      case ActivityTypeEnum.COMMENT:
      case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER: {
        return (
          <Avatar className={styles["update-icon"]} user={activity.author} />
        );
      }

      default:
        return (
          <div
            className={classNames(styles["update-icon"], styles["graph-icon"])}
          >
            <GraphLineIcon />
          </div>
        );
    }
  };

  const renderActivity = (activity: ActivityFeedItemFragment) => {
    return (
      <div className={classNames(styles["feed-update"])}>
        {renderActivityAvatarIcon(activity.type)}

        <div className={styles["section-update"]}>
          <span className={styles.author}>
            {activity.author
              ? `${activity.author.firstName} ${activity.author.lastName}`
              : "Inbound email"}
          </span>
          <span className={styles.date}>
            {formatTimeAgo(activity.createdDate)}
          </span>
          <br />
          <div className={styles.message}>
            <div className={styles["message-content-container"]}>
              <div
                className={classNames([
                  styles["message-content"],
                  {
                    [styles["message-content--latest"]]:
                      activity.id === latestActivity?.id,
                  },
                ])}
              >
                {activity &&
                  getMessageByActivity(
                    organization.urlName,
                    history,
                    activity,
                    activityRefs,
                  )}
              </div>
            </div>
          </div>

          {!showReplyForm &&
            activity.data.__typename !== ActivityTypeEnum.REPLY &&
            activity.data.__typename !==
              ActivityTypeEnum.EMAIL_INBOUND_MENTION && (
              <div className={styles["respond-wrap"]}>
                <Button
                  data-testid="752c65be98"
                  text
                  onClick={() => setShowReplyForm(true)}
                  className={styles.respond}
                >
                  {activity.data.__typename === ActivityTypeEnum.COMMENT
                    ? "Reply"
                    : "Comment"}
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderActivity(activity)}

      {showReplyForm && (
        <FeedUpdateReplyInput
          className={styles["reply-input"]}
          replyInput={replyInput}
          submitReply={() => {
            createReplyWithOverrides({
              organizationId: activity.organizationId,
              relativeId: activity.relativeId,
              relativeType: activity.relativeType,
              parentId: activity.id,
              type: ActivityTypeEnum.REPLY,
            })();
            setShowReplyForm(false);
          }}
          discardReply={() => {
            reset();
            setShowReplyForm(false);
          }}
        />
      )}

      {errorMessage && <ErrorMessage error={errorMessage} />}

      {activity.replies
        .filter(
          (activity) =>
            activity.relativeType !== ActivityRelativeTypeEnum.SUPPLIER,
        )
        .map((reply) =>
          renderReply({
            reply,
            activity,
            organization,
            latestActivity,
            history,
          }),
        )}

      {sendingReply && (
        <div className={styles["loading-wrapper"]}>
          <Loading small />
        </div>
      )}
    </>
  );
};
