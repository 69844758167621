import React from "react";
import { ApolloError } from "apollo-client";
import classNames from "classnames";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as R from "ramda";
import { useCardContextInfo } from "../../contexts/card-context";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import {
  EmailCardTypeEnum,
  CardTypeEnum,
  Attachment,
  useSendEmailToSuppliers,
  useSendSourcingRequest,
} from "../../schema";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import { Attachments } from "../Attachments/Attachments";
import { Button } from "../Button/Button";
import { Chip } from "../Chip/Chip";
import { OnDropCallbackFn } from "../Dropzone/Dropzone";
import { Field } from "../Field/Field";
import { useViewer } from "../../contexts/viewer-context";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { RichTextEditor } from "../RichTextEditor/RichTextEditor";
import { AttachmentListSelectItem } from "../AttachmentListSelectItem/AttachmentListSelectItem";
import { formatFilesize } from "../../services/formatFilesize";
import { Unbox } from "../../lib/helpers/typescript-helpers";
import { isDefined } from "../../services/isDefined";
import { RfqEventFormContext } from "../../views/RfxView/RfxView";
import styles from "./EmailForm.module.scss";

export interface EmailFormProps {
  organization: { companyName: string };
  initialSubject?: string;
  templateMessage?: string;
  sourcingEventId?: string;
  sendButtonText?: string;
  suppliers: {
    id: string;
    defaultContactPerson: { email: string };
    name?: string | null;
  }[];
  attachments?: Pick<Attachment, "id" | "filename" | "url">[];
  onSupplierRemove: (id: string | number) => void;
  onSendSuccess: (
    suppliers: { id: string; email?: string | null; name?: string | null }[],
  ) => void;
  onSendError: (error: string | ApolloError) => void;
  onCancel?: () => void;
}

export interface EmailFormInputs {
  ccEmail: string | null;
  subject: string | null;
  selectedAttachments: (Pick<Attachment, "id" | "filename" | "url" | "size"> & {
    selected: boolean;
  })[];
}

export const EmailForm: React.FC<EmailFormProps> = ({
  organization,
  initialSubject,
  templateMessage,
  sourcingEventId,
  sendButtonText,
  suppliers,
  attachments = [],
  onSupplierRemove,
  onSendSuccess,
  onSendError,
  onCancel,
}) => {
  // email attachments to be uploaded from client side
  const [emailFiles, setEmailFiles] = React.useState<File[]>();
  const [isCcFieldVisible, setIsCcFieldVisible] = React.useState(false);

  // use viewer context
  const sender = useViewer();

  // use card context
  const card = useCardContextInfo();

  // use parent's controls for getting RfqEventForm's dirty status
  const rfqEventFormContext = React.useContext(RfqEventFormContext);

  // set files state on drop
  const onEmailDrop = React.useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // set selected file
      setEmailFiles([...(emailFiles ? emailFiles : []), ...acceptedFiles]);
    },
    [emailFiles],
  );

  // email form locally persisted state
  const [persistedFormValues, setPersistedFormValues] = useLocalStorage<{
    subject: string | null;
    message: string | null;
  }>(
    `EmailForm.${card?.id}.${sender?.id}${
      sourcingEventId ? "." + sourcingEventId : ""
    }`,
    {
      subject: null,
      message: null,
    },
  );

  // message
  const [message, setMessage] = React.useState<string | null>(
    persistedFormValues.message,
  );

  // send email to suppliers mutation
  const [sendEmailToSuppliers, { loading: isSendEmailToSuppliersLoading }] =
    useSendEmailToSuppliers({
      refetchQueries: ["RfxByCode", "OrderByCode"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setEmailFiles([]);
        onSendSuccess(suppliers);
      },
      onError: (e) => {
        onSendError(e);
      },
    });

  // send sourcing requests
  const [sendSourcingRequests, { loading: isSendSourcingRequestsLoading }] =
    useSendSourcingRequest({
      refetchQueries: ["PaginatedRfx", "RfxByCode", "OrderByCode"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setEmailFiles([]);
        onSendSuccess(suppliers);
      },
      onError: (e) => {
        onSendError(e);
      },
    });

  const defaultValues: EmailFormInputs = {
    ccEmail: null,
    subject: initialSubject ?? persistedFormValues.subject ?? "",
    selectedAttachments: [],
  };

  const formMethods = useForm<EmailFormInputs>({ defaultValues });

  const { register, handleSubmit, control, getValues, watch, reset } =
    formMethods;

  useFieldArray<Unbox<EmailFormInputs["selectedAttachments"]>>({
    control,
    name: "selectedAttachments",
  });

  // store subject and message values to localStorage until submitted
  React.useEffect(() => {
    setPersistedFormValues({ subject: getValues("subject"), message });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("subject"), message]);

  const supplierIds = suppliers.map((s) => s.id);

  if (!card || !sender) {
    throw Error(
      "No sender present. This component must be used inside CardContextProvider and ViewerContextProvider.",
    );
  }
  // sends supplier email
  const onSubmit = async (
    formData: EmailFormInputs & {
      attachments: { [attachmentId: string]: boolean };
    },
  ) => {
    if (supplierIds.length < 1) {
      throw Error("No supplier present");
    }

    // send out RFI or RFP
    if (!isDefined(sourcingEventId)) {
      await sendEmailToSuppliers({
        variables: {
          parentId: null,
          cardId: card.id,
          cardType: card.type as unknown as EmailCardTypeEnum,
          supplierIds,
          attachments: emailFiles ?? [],
          existingAttachmentIds:
            R.compose<any, any, any>(
              R.prop("true"),
              R.invert,
            )(formData.attachments) ?? [],
          toEmail: null,
          ccEmail: formData.ccEmail,
          subject: formData.subject ?? "",
          message: message ?? "",
          includePurchaseOrderPdf: true,
        },
      });
    }

    if (isDefined(sourcingEventId)) {
      await sendSourcingRequests({
        variables: {
          sourcingEventId,
          supplierIds,
          attachments: emailFiles ?? [],
          subject: formData.subject ?? "",
          message: message ?? "",
          existingAttachmentIds:
            R.compose<any, any, any>(
              R.prop("true"),
              R.invert,
            )(formData.attachments) ?? [],
        },
      });
    }
  };

  const triggerSubmit = handleSubmit(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form autoComplete="off" id="email-form" onSubmit={triggerSubmit}>
        <div className={styles["wrap"]}>
          <div className={styles.emailFrom}>
            <span className={styles.fieldLabel}>EMAIL FROM</span>
            <span
              className={styles["email-sender"]}
            >{`${sender.firstName} ${sender.lastName} <${sender.email}>`}</span>
          </div>

          <div className={styles.subjectField}>
            <span className={styles.fieldLabel}>EMAIL SUBJECT</span>
            <Field
              inputRef={register()}
              name="subject"
              className={styles.subjectInput}
            />
          </div>

          {isCcFieldVisible && (
            <div className={styles.ccField}>
              <span className={styles.fieldLabel}>CC</span>
              <Field inputRef={register()} name="ccEmail" />
            </div>
          )}

          <div className={styles.emailTo}>
            <span className={styles.fieldLabel}>EMAIL TO SUPPLIERS</span>
            <div className={styles["cc-toggle-container"]}>
              <Button
                text
                className={classNames({
                  [styles["add-button"]]: !isCcFieldVisible,
                  [styles["close-button"]]: isCcFieldVisible,
                })}
                onClick={() => {
                  setIsCcFieldVisible(!isCcFieldVisible);

                  if (isCcFieldVisible) {
                    reset({ ccEmail: "" });
                  }
                }}
              >
                {isCcFieldVisible ? "Remove CC" : "Add CC"}
              </Button>
            </div>
            <div className={styles.emailAddresses}>
              {suppliers.map((supplier) => {
                return (
                  <Chip
                    key={supplier.id}
                    id={supplier.id}
                    onRemove={onSupplierRemove}
                  >
                    {`${
                      supplier.name ?? supplier.defaultContactPerson.email
                    } <${supplier.defaultContactPerson.email}>`}
                  </Chip>
                );
              })}
            </div>
          </div>

          <div className={styles.messageField}>
            <span className={styles.fieldLabel}>EMAIL MESSAGE</span>
            <RichTextEditor
              value={persistedFormValues.message ?? templateMessage}
              onValueChange={(value) => {
                setMessage(value);
              }}
            />
          </div>

          <div className={styles["button-row"]}>
            {!sourcingEventId && (
              <Attachments
                className={styles["add-files"]}
                multiple
                loading={false}
                onDrop={(acceptedFiles) => onEmailDrop(acceptedFiles)}
              />
            )}

            <Button
              data-testid="d31dbc1e7a"
              secondary
              onClick={() => {
                reset();
                onCancel && onCancel();
              }}
            >
              Cancel
            </Button>

            <Button
              className={styles["send-button"]}
              data-testid="324007cbe7"
              type="submit"
              loading={
                isSendEmailToSuppliersLoading || isSendSourcingRequestsLoading
              }
              disabled={
                isSendEmailToSuppliersLoading ||
                isSendSourcingRequestsLoading ||
                rfqEventFormContext?.isRfqEventFormDirty
              }
            >
              {sendButtonText ?? "Send email"}
            </Button>
          </div>

          {!sourcingEventId && (
            <div className={styles["attachment-list"]}>
              <div className={styles["attachment-list-title"]}>
                Selected files will be added as attachments:
              </div>
              {/* PO attachment (can't be removed) */}
              {card.type === CardTypeEnum.ORDER && (
                <div
                  className={styles["po-pdf-attachment"]}
                  onClick={() => {
                    return (window.location.href = `/api/order/${card.id}/pdf`);
                  }}
                >
                  <span>{`${organization.companyName.replace(" ", "_")}_PO-${
                    card.code
                  }.pdf`}</span>
                  <OpenExternalIcon className={styles["open-external-icon"]} />
                </div>
              )}

              {attachments.map((attachment) => (
                <AttachmentListSelectItem
                  key={attachment.id}
                  attachment={attachment}
                />
              ))}

              <div className={styles["upload-list"]}>
                {/* attachments not yet reached server side */}
                {emailFiles && emailFiles.length > 0 && (
                  <div>
                    {emailFiles.map((file) => (
                      <div
                        key={file.name}
                        className={styles["attachment-item"]}
                      >
                        <DeleteFileIcon
                          className={classNames(styles["delete-file-icon"])}
                          onClick={() =>
                            setEmailFiles(
                              emailFiles.filter(
                                (stateFile) => file.name !== stateFile.name,
                              ),
                            )
                          }
                        />
                        <div>{file.name}</div>
                        <div className={styles["filesize"]}>{`(${formatFilesize(
                          file.size,
                        )})`}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {(isSendEmailToSuppliersLoading || isSendSourcingRequestsLoading) && (
            <LoadingView overlay />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
