import React, { CSSProperties } from "react";
import classNames from "classnames";
import { RenderElementProps } from "slate-react";
import { Element } from "slate";
import styles from "./ParagraphElement.module.scss";

export interface ParagraphElementProps extends RenderElementProps {
  className?: string;
  style?: CSSProperties;
}
export type ParagraphElementType = Element & { type: "paragraph" };

export const ParagraphElement: React.FC<ParagraphElementProps> = (props) => {
  const { className, attributes, children, style } = props;

  return (
    <p
      style={style}
      className={classNames(styles["wrap"], className)}
      {...attributes}
    >
      {children}
    </p>
  );
};

// helper that determines if an element is a Paragraph
export function isParagraphElement(
  element: Element,
): element is ParagraphElementType {
  return element.type === "paragraph";
}
