import classNames from "classnames";
import { Manager, Popper, Reference } from "react-popper";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Dropdown,
  DropdownContent,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { Checkbox } from "../Checkbox/Checkbox";
import { VisibilityIcon } from "../../theme/svg/VisibilityIcon";
import { RfxByCodeRfxByCode } from "../../schema";
import { handleFormSupplierChange } from "../../services/handleFormSupplierChange";
import styles from "./SupplierSelectDropdown.module.scss";

export interface SupplierSelectDropdownProps {
  rfx: RfxByCodeRfxByCode;
  itemIndex: number;
  onChange?: (itemId: number) => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

export type SupplierSelectDropdownField = {
  id: string;
  supplierId: string;
  supplierName: string;
  isSelected: boolean;
};

export const SupplierSelectDropdown: React.FC<SupplierSelectDropdownProps> = ({
  rfx,
  itemIndex,
  onChange,
  disabled,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [hasUpdated, setHasUpdated] = React.useState(false);
  const { register, control, watch } = useFormContext();

  const { fields, remove, append } = useFieldArray<SupplierSelectDropdownField>(
    {
      control,
      name: `items[${itemIndex}].excludedItemSuppliers`,
    },
  );

  const currentSuppliers = watch(`items[${itemIndex}]`);
  const suppliers = rfx.suppliers;

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(itemIndex);
    }
  }, [currentSuppliers]);

  useEffect(() => {
    handleFormSupplierChange(suppliers, fields, rfx.status, append, remove);
  }, [suppliers, fields]);

  function toggleDropdown() {
    if (!isDropdownOpen) {
      setHasUpdated(false);
    }

    !disabled && setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <Dropdown onCloseRequested={() => setIsDropdownOpen(false)}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <>
              <div className={styles["dropdown-selector-container"]}>
                <DropdownSelector
                  className={classNames(
                    styles["dropdown-button"],
                    styles["dropdown-selector-button"],
                  )}
                  onClick={() => toggleDropdown()}
                  ref={ref}
                >
                  <VisibilityIcon className={styles["button-icon"]} />
                </DropdownSelector>
              </div>
            </>
          )}
        </Reference>
        <Popper positionFixed>
          {({ ref, style, placement, scheduleUpdate }) => {
            if (isDropdownOpen && !hasUpdated) {
              scheduleUpdate();
              setHasUpdated(true);
            }

            return (
              <DropdownContent
                open={isDropdownOpen}
                style={{
                  ...style,
                  top: style.top + "5px",
                }}
                className={styles["dropdown-content"]}
                ref={ref}
                placement={placement}
              >
                {fields.map((field, supplierIndex) => {
                  return (
                    <div key={field.id}>
                      <input
                        type="hidden"
                        ref={register()}
                        name={`items[${itemIndex}].excludedItemSuppliers[${supplierIndex}].supplierId`}
                        value={field.supplierId}
                      />
                      <input
                        type="hidden"
                        ref={register()}
                        name={`items[${itemIndex}].excludedItemSuppliers[${supplierIndex}].supplierName`}
                        value={field.supplierName}
                      />
                      <Checkbox
                        key={field.id}
                        inputRef={register()}
                        defaultChecked={field.isSelected}
                        name={`items[${itemIndex}].excludedItemSuppliers[${supplierIndex}].isSelected`}
                        className={styles["dropdown-item"]}
                      >
                        {field.supplierName}
                      </Checkbox>
                    </div>
                  );
                })}
              </DropdownContent>
            );
          }}
        </Popper>
      </Manager>
    </Dropdown>
  );
};
