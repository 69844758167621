import { ApprovalWorkflowStatusEnum } from "../schema";
import { assertUnreachable } from "./assertUnreachable";

// note that if you change these then also update persistCreateRfxFromPurchaseRequest
export function getWorkflowStatusText(status: ApprovalWorkflowStatusEnum) {
  switch (status) {
    case ApprovalWorkflowStatusEnum.APPROVED: {
      return "APPROVED";
    }

    case ApprovalWorkflowStatusEnum.DECLINED: {
      return "DECLINED";
    }

    case ApprovalWorkflowStatusEnum.DRAFT: {
      return "APPROVAL WORKFLOW DRAFT";
    }

    case ApprovalWorkflowStatusEnum.IN_PROGRESS: {
      return "WAITING APPROVALS";
    }

    case ApprovalWorkflowStatusEnum.CANCELLED: {
      return "APPROVAL WORKFLOW CANCELLED";
    }

    default:
      return assertUnreachable(
        status,
        `UNHANDLED APPROVAL WORKFLOW STATUS VALUE: ${status}`,
      );
  }
}
