import React from "react";
import classNames from "classnames";
import { RenderSuggestion } from "react-autosuggest";
import { renderHighlighted } from "../../services/renderHighlighted";
import {
  Autosuggest,
  AutosuggestProps,
  GetCreateNewText,
  GetSuggestionValue,
  RenderAddon,
} from "../Autosuggest/Autosuggest";
import { Avatar, AvatarUser } from "../Avatar/Avatar";
import styles from "./ItemAutosuggest.module.scss";

export interface AutosuggestItem {
  id: string;
  name?: string | null;
  address?: string | null;
  companyName?: string | null;
  defaultContactPerson?: { email: string };
  email?: string | null;
  avatarUrl?: string | null;
  isUsed?: boolean;
  hasPermissionToCreateNew?: boolean;
}

// build props defining which of the props need to come from outside
export type ItemAutosuggestProps = Partial<AutosuggestProps<AutosuggestItem>> &
  Pick<
    AutosuggestProps<AutosuggestItem>,
    "value" | "chosenSuggestion" | "fetchSuggestions" | "onChange" | "onChoose"
  > & { displayAvatar?: boolean };

export const ItemAutosuggest: React.FC<ItemAutosuggestProps> = ({
  displayAvatar = true,
  ...rest
}) => {
  // returns selected suggestion value
  const getSuggestionValue: GetSuggestionValue<AutosuggestItem> = (
    suggestion,
  ) =>
    suggestion.name ||
    suggestion.email ||
    suggestion.defaultContactPerson?.email ||
    suggestion.companyName ||
    suggestion.address ||
    "[missing name]";

  // converts provider to virtual avatar user
  const getVirtualUser = (suggestion: AutosuggestItem): AvatarUser | null => {
    const name =
      suggestion.name ||
      suggestion.email ||
      suggestion.defaultContactPerson?.email ||
      "[missing name]";

    if (!name) {
      return null;
    }

    const [firstName, ...lastNames] = name.split(" ");

    return {
      id: suggestion.id,
      firstName,
      lastName: lastNames.join(" "),
      avatarUrl: suggestion.avatarUrl,
    };
  };

  const getEmail = (suggestion: AutosuggestItem): string => {
    return suggestion.defaultContactPerson
      ? suggestion.defaultContactPerson.email
      : suggestion.email || "N/A";
  };

  // renders the suggestion
  const renderSuggestion: RenderSuggestion<AutosuggestItem> = (
    suggestion,
    { query, isHighlighted },
  ) => {
    const text = getSuggestionValue(suggestion);

    return (
      <div
        className={classNames(styles.suggestion, {
          [styles["suggestion--highlight"]]: isHighlighted,
          [styles["suggestion--used"]]: suggestion.isUsed,
        })}
      >
        {displayAvatar && <Avatar large user={getVirtualUser(suggestion)} />}
        <div className={styles.name}>
          <div>{renderHighlighted(text, query, styles["text-highlight"])}</div>
          {suggestion.name &&
            (suggestion.email || suggestion.defaultContactPerson?.email) && (
              <div className={styles.email}>
                {renderHighlighted(
                  getEmail(suggestion),
                  query,
                  styles["text-highlight"],
                )}
              </div>
            )}
        </div>
      </div>
    );
  };

  // renders chosen suggestion addon
  const renderAddon: RenderAddon<AutosuggestItem> = (suggestion) =>
    suggestion && displayAvatar ? (
      <Avatar user={getVirtualUser(suggestion)} />
    ) : null;

  // returns text for creating new item
  const getCreateNewText: GetCreateNewText = (value) => `Create new "${value}"`;

  // render the generic autosuggest
  return (
    <Autosuggest
      suggestForEmpty
      clearable
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderAddon={renderAddon}
      getCreateNewText={getCreateNewText}
      {...rest}
    />
  );
};
