import React from "react";
import classNames from "classnames";
import { CardStatus, Status } from "../Status/Status";
import { CardStatusLevelEnum } from "../../schema";
import { DownArrowIcon } from "../../theme/svg/DownArrowIcon";
import styles from "./PurchaseRequestCard.module.scss";

export interface PurchaseRequestCardProps {
  status: CardStatus;
  title?: JSX.Element;
  onClick?(): void;
  isSummary?: boolean;
  isEditing?: boolean;
  secondary?: boolean;
}

export const PurchaseRequestCard: React.FC<PurchaseRequestCardProps> = ({
  children,
  title,
  status,
  onClick,
  isSummary,
  isEditing,
  secondary,
}) => (
  <div
    className={classNames(styles.container, {
      [styles["status--default"]]: status.level === CardStatusLevelEnum.DEFAULT,
      [styles["status--alert"]]: status.level === CardStatusLevelEnum.ALERT,
      [styles["status--warning"]]: status.level === CardStatusLevelEnum.WARNING,
      [styles["status--info"]]: status.level === CardStatusLevelEnum.INFO,
      [styles["status--success"]]: status.level === CardStatusLevelEnum.SUCCESS,
      [styles["status--withLink"]]: onClick,
      [styles["container--secondary"]]: secondary,
    })}
    onClick={onClick}
  >
    {title}
    {status && !isEditing && (
      <Status
        className={styles.badge}
        level={status.level}
        text={status.text}
      />
    )}
    {isSummary ? (
      <div className={styles["summary-header"]}>
        {children}
        {onClick && (
          <div className={styles.actions}>
            <DownArrowIcon className={styles["details-view-link"]} />
          </div>
        )}
      </div>
    ) : (
      children
    )}
  </div>
);
