export type VersionType = "major" | "minor" | "patch";

export function isVersionDifferent(
  version1: string,
  version2: string,
  versionType: VersionType,
) {
  const tokens1 = version1.split(".");
  const tokens2 = version2.split(".");

  if (tokens1.length !== 3) {
    throw new Error(
      `Expected version1 "${version1}" to be formatted by semantic versioning rules`,
    );
  }

  if (tokens2.length !== 3) {
    throw new Error(
      `Expected version2 "${version2}" to be formatted by semantic versioning rules`,
    );
  }

  const isMajorVersionDifferent = tokens1[0] !== tokens2[0];
  const isMinorVersionDifferent = tokens1[1] !== tokens2[1];
  const isPatchVersionDifferent = tokens1[2] !== tokens2[2];

  switch (versionType) {
    case "major":
      return isMajorVersionDifferent;

    case "minor":
      return isMajorVersionDifferent || isMinorVersionDifferent;

    case "patch":
      return (
        isMajorVersionDifferent ||
        isMinorVersionDifferent ||
        isPatchVersionDifferent
      );
  }
}
