import React from "react";
import classNames from "classnames";
import { getInitials } from "../../services/getInitials";
import { Loading } from "../Loading/Loading";
import styles from "./Avatar.module.scss";

export interface AvatarUser {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string | null;
  icon?: JSX.Element;
}

export interface AvatarProps {
  user?: AvatarUser | null;
  large?: boolean;
  extraLarge?: boolean;
  loading?: boolean;
  hoverEffect?: boolean;
  title?: string;
  text?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  user,
  large,
  extraLarge,
  loading,
  title,
  text,
  hoverEffect,
  className,
}) => {
  const name = user
    ? `${user.firstName}${user.lastName ? ` ${user.lastName}` : ""}`
    : "Unassigned";

  return (
    <React.Fragment>
      {text && <span>{text}</span>}
      <span
        title={title || name}
        className={classNames(
          styles.avatar,
          { [styles["avatar--large"]]: large },
          { [styles["avatar--extra-large"]]: extraLarge },
          { [styles["avatar--no-hover"]]: hoverEffect === false },
          { [styles["avatar--with-text"]]: text },
          className,
        )}
      >
        {!user && (
          <div
            className={classNames(styles.unassigned, {
              [styles["unassigned--loading"]]: loading,
            })}
          >
            ?
          </div>
        )}

        {user && user.avatarUrl && (
          <div
            className={classNames(styles.image, {
              [styles["image--loading"]]: loading,
            })}
            style={{ backgroundImage: `url(${user.avatarUrl})` }}
          />
        )}

        {user && !user.avatarUrl && !user.icon && (
          <div
            className={classNames(styles.initials, {
              [styles["initials--loading"]]: loading,
            })}
          >
            {getInitials(name)}
          </div>
        )}

        {user && !user.avatarUrl && user.icon && (
          <div
            className={classNames(styles.image, {
              [styles["initials--loading"]]: loading,
            })}
          >
            {user.icon}
          </div>
        )}
        <div
          className={classNames(styles["loading-wrap"], {
            [styles["loading-wrap--visible"]]: loading,
          })}
        >
          <Loading small={!large} />
        </div>
      </span>
    </React.Fragment>
  );
};
