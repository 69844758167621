import React from "react";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import { Description } from "../Description/Description";

export const ExcelProductsImportExampleTable: React.FC = () => (
  <>
    <BasicTable>
      <thead>
        <tr>
          <Cell header nowrap>
            Name*
          </Cell>
          <Cell header nowrap>
            Code
          </Cell>
          <Cell header nowrap>
            Suggested supplier
          </Cell>
          <Cell header nowrap>
            Supplier code
          </Cell>
          <Cell header nowrap>
            Quantity*
          </Cell>
          <Cell header nowrap>
            Unit*
          </Cell>
          <Cell header nowrap>
            Unit price
          </Cell>
          <Cell header nowrap>
            Currency
          </Cell>
          <Cell header nowrap>
            Expected delivery
          </Cell>
          <Cell header nowrap>
            Product url
          </Cell>
          <Cell header nowrap>
            Additional info
          </Cell>
          <Cell header nowrap>
            Custom field 1
          </Cell>
          <Cell header nowrap>
            Custom field 2
          </Cell>
          <Cell header nowrap>
            Custom field 3
          </Cell>
          <Cell header nowrap>
            Custom field 4
          </Cell>
          <Cell header nowrap>
            Custom field 5
          </Cell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Cell>First product name</Cell>
          <Cell center>XY12345</Cell>
          <Cell center>Company Ltd.</Cell>
          <Cell center>xyz-123</Cell>
          <Cell center>15.5</Cell>
          <Cell center>meters</Cell>
          <Cell center>22.9</Cell>
          <Cell center>USD</Cell>
          <Cell center>02/29/2020</Cell>
          <Cell>https://example.com/product-1</Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center>Example</Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
        </tr>
        <tr>
          <Cell>Second product name</Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center>6</Cell>
          <Cell center>pcs</Cell>
          <Cell center>1050</Cell>
          <Cell center></Cell>
          <Cell center>29.02.2020</Cell>
          <Cell></Cell>
          <Cell center>Example</Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center></Cell>
          <Cell center>Example</Cell>
          <Cell center></Cell>
        </tr>
      </tbody>
    </BasicTable>
    <Description spacious>
      Columns marked with * indicate required values (column order is important,
      header titles are not).
    </Description>
  </>
);
