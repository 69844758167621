import classNames from "classnames";
import { Manager, Popper, Reference } from "react-popper";
import React from "react";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownSelector,
} from "../Dropdown/Dropdown";
import { Avatar, AvatarUser } from "../Avatar/Avatar";
import styles from "./UserSelectDropdown.module.scss";

export interface UserSelectDropdownProps {
  users: AvatarUser[];
  activeUser: AvatarUser | null;
  title: string;
  large?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onChoose?(user: AvatarUser): void;
  onUnassign?(): void;
}

export const UserSelectDropdown: React.FC<UserSelectDropdownProps> = ({
  users,
  activeUser,
  title,
  large,
  loading,
  disabled,
  onChoose,
  onUnassign,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [hasUpdated, setHasUpdated] = React.useState(false);

  function toggleDropdown() {
    if (!isDropdownOpen) {
      setHasUpdated(false);
    }

    !disabled && setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <Manager>
      <Dropdown
        data-testid="401b9f9172"
        onCloseRequested={() => setIsDropdownOpen(false)}
      >
        <Reference>
          {({ ref }) => (
            <DropdownSelector
              onClick={() => toggleDropdown()}
              className={classNames(styles["dropdown-selector"], {
                [styles["dropdown-selector--disabled"]]: disabled,
              })}
              ref={ref}
            >
              <Avatar
                user={activeUser}
                large={large}
                title={title}
                loading={loading}
              />
            </DropdownSelector>
          )}
        </Reference>
        <Popper positionFixed placement="bottom-end">
          {({ ref, style, placement, scheduleUpdate }) => {
            if (isDropdownOpen && !hasUpdated) {
              scheduleUpdate();
              setHasUpdated(true);
            }

            return (
              <DropdownContent
                open={isDropdownOpen}
                style={style}
                className={styles["dropdown-content"]}
                listClassname={styles["dropdown-list"]}
                ref={ref}
                placement={placement}
              >
                {activeUser !== null && onUnassign && (
                  <DropdownItem
                    nowrap
                    onClick={() => {
                      setIsDropdownOpen(false);

                      onUnassign();
                    }}
                    className={styles["dropdown-item"]}
                    key="unassign"
                  >
                    <Avatar className={styles["list-avatar"]} />
                    Unassigned
                  </DropdownItem>
                )}
                {users
                  .filter((user) =>
                    activeUser ? user.id !== activeUser.id : true,
                  )
                  .map((user, index) => (
                    <DropdownItem
                      onClick={() => {
                        setIsDropdownOpen(false);

                        if (onChoose) {
                          onChoose(user);
                        }
                      }}
                      className={styles["dropdown-item"]}
                      key={index}
                    >
                      <Avatar user={user} className={styles["list-avatar"]} />
                      {user.firstName} {user.lastName}
                    </DropdownItem>
                  ))}
              </DropdownContent>
            );
          }}
        </Popper>
      </Dropdown>
    </Manager>
  );
};
