import React from "react";
import classNames from "classnames";
import { TableInstance, useAsyncDebounce } from "react-table";
import { SearchIcon } from "../../../../theme/svg/SearchIcon";
import { Field } from "../../../Field/Field";
import styles from "./DataGridGlobalFilter.module.scss";

export interface DataGridGlobalFilterProps<D extends Record<string, unknown>> {
  className?: string;
  tableInstance: TableInstance<D>;
  withArchived: boolean;
  onWithArchivedClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function DataGridGlobalFilter<D extends Record<string, unknown>>(
  props: DataGridGlobalFilterProps<D>,
) {
  const { className, tableInstance, withArchived, onWithArchivedClick } = props;
  const { globalFilter, setGlobalFilter, rows, preGlobalFilteredRows } =
    tableInstance;

  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <div className={classNames(styles["wrap"], className)}>
        <SearchIcon className={styles["search-icon"]} />
        <input
          type="text"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          className={styles["input"]}
        />
        <div className={styles["filter-stats"]}>
          <span className={styles["current-row-count"]}>{rows.length}</span>/
          <span className={styles["total-row-count"]}>
            {preGlobalFilteredRows.length}
          </span>
        </div>
      </div>
      <Field
        checkbox
        value={withArchived}
        className={styles["filter-archive"]}
        onCheckboxChange={(e) => {
          typeof onWithArchivedClick === "function" && onWithArchivedClick(e);
        }}
      >
        Show archived suppliers
      </Field>
    </>
  );
}
