import { ValidatorFn } from "../lib/react-apollo-hooks-form";

export const isNumber: ValidatorFn = (value) => {
  const error = "Expected a number";

  // expecting either a number or a string
  if (typeof value !== "number" && typeof value !== "string") {
    return error;
  }

  // resolve numeric value
  const numericValue = typeof value === "number" ? value : parseFloat(value);

  // return error if value is not valid
  if (isNaN(numericValue) || !isFinite(numericValue)) {
    return error;
  }
};
