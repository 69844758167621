import React from "react";
import classNames from "classnames";
import { useRouter } from "../../hooks/useRouter";
import { useSourcingEventPublic } from "../../schema";
import { SupplierPortalLayout } from "../SupplierPortalLayout/SupplierPortalLayout";
import { SourcingEventPublicInfo } from "../../components/SourcingEventPublicInfo/SourcingEventPublicInfo";
import { LoadingView } from "../LoadingView/LoadingView";
import { SupplierLookupForm } from "../../components/SupplierLookupForm/SupplierLookupForm";
import { SupplierSelfRegistrationForm } from "../../components/SupplierSelfRegistrationForm/SupplierSelfRegistrationForm";
import styles from "./SupplierPortalSupplierRegistrationView.module.scss";

export interface SupplierPortalSupplierRegistrationViewProps {
  className?: string;
}

interface UrlParams {
  sourcingEventLinkToken: string;
  urlEncodedEventName: string;
}

export const SupplierPortalSupplierRegistrationView: React.FC<SupplierPortalSupplierRegistrationViewProps> = (
  props,
) => {
  const { className } = props;

  // use router and extract sourcing request ID from params
  const { match } = useRouter<UrlParams>();
  const { sourcingEventLinkToken } = match.params;

  // state
  const [
    displaySourcingEventPublicInfo,
    setDisplaySourcingEventPublicInfo,
  ] = React.useState(true);
  const [
    displaySupplierLookupForm,
    setDisplaySupplierLookupForm,
  ] = React.useState(true);
  const [
    displaySupplierSelfRegistrationForm,
    setDisplaySupplierSelfRegistrationForm,
  ] = React.useState(true);
  const [displayLinkSent, setDisplayLinkSent] = React.useState(false);
  const [
    displayManyRelatedSuppliers,
    setDisplayManyRelatedSuppliers,
  ] = React.useState(false);

  const [checkedEmail, setCheckedEmail] = React.useState<string | null>(null);
  const [newEmail, setNewEmail] = React.useState<string | null>(null);

  // handle email lookup results
  const handleSupplierFound = (email: string) => {
    setDisplaySourcingEventPublicInfo(false);
    setDisplaySupplierLookupForm(false);
    setDisplayLinkSent(true);
    setDisplaySupplierSelfRegistrationForm(false);
    setDisplayManyRelatedSuppliers(false);

    setNewEmail(null);
    setCheckedEmail(email);
  };

  const handleManySuppliersFound = (email: string) => {
    setDisplaySourcingEventPublicInfo(true);
    setDisplaySupplierLookupForm(true);
    setDisplayLinkSent(false);
    setDisplaySupplierSelfRegistrationForm(false);
    setDisplayManyRelatedSuppliers(true);

    setNewEmail(null);
    setCheckedEmail(email);
  };

  const handleSupplierNotFound = (email: string) => {
    setDisplaySourcingEventPublicInfo(true);
    setDisplaySupplierLookupForm(false);
    setDisplayLinkSent(false);
    setDisplaySupplierSelfRegistrationForm(true);
    setDisplayManyRelatedSuppliers(false);

    setCheckedEmail(null);
    setNewEmail(email);
  };

  // handle self registration results
  const handleRegistrationSuccess = () => {
    setDisplaySourcingEventPublicInfo(false);
    setDisplaySupplierLookupForm(false);
    setDisplayLinkSent(true);
    setDisplaySupplierSelfRegistrationForm(false);
    setDisplayManyRelatedSuppliers(false);

    setNewEmail(null);
    setCheckedEmail(newEmail);
  };

  // server data
  const {
    data: serverData,
    loading: isServerDataLoading,
  } = useSourcingEventPublic({
    variables: { sourcingEventLinkToken },
  });

  const sourcingEventData = serverData?.sourcingEventPublic;

  if (!sourcingEventData || isServerDataLoading) {
    return <LoadingView />;
  }

  return (
    <SupplierPortalLayout organization={sourcingEventData.organization}>
      <>
        {/* Sourcing event info */}
        {displaySourcingEventPublicInfo && (
          <div className={classNames(styles["wrap"], className)}>
            <SourcingEventPublicInfo
              sourcingEventPublicInfo={sourcingEventData}
            />
          </div>
        )}

        {/* Supplier lookup form */}
        {displaySupplierLookupForm && (
          <SupplierLookupForm
            linkToken={sourcingEventLinkToken}
            handleSupplierFound={handleSupplierFound}
            handleManySuppliersFound={handleManySuppliersFound}
            handleSupplierNotFound={handleSupplierNotFound}
            organizationId={sourcingEventData.organization.id}
          />
        )}

        {/* Link sent info */}
        {displayLinkSent && checkedEmail && (
          <section className={styles["link-sent"]}>
            <div className={styles["link-sent-title"]}>Thank you!</div>
            <div
              className={styles["link-sent-text"]}
            >{`Access link was just sent to ${checkedEmail}!`}</div>
          </section>
        )}

        {/* Many related suppliers */}
        {displayManyRelatedSuppliers && checkedEmail && (
          <section className={styles["many-suppliers"]}>
            <div
              className={styles["link-sent-text"]}
            >{`Sorry! We could not send you the access link because there are many different supplier companies already registered with that (public) email domain. Please contact your contact person or use an email with your company's domain name.`}</div>
          </section>
        )}

        {/* Supplier self registration form */}
        {displaySupplierSelfRegistrationForm && newEmail && (
          <SupplierSelfRegistrationForm
            organizationId={sourcingEventData.organization.id}
            sourcingEventLinkToken={sourcingEventData.linkToken}
            sourcingEventContactPersonId={sourcingEventData.creator.id}
            email={newEmail}
            handleRegistrationSuccess={handleRegistrationSuccess}
          />
        )}
      </>
    </SupplierPortalLayout>
  );
};
