import { AutosuggestItem } from "../components/ItemAutosuggest/ItemAutosuggest";

export interface QuantityUnitInfo {
  id: string;
  name: string;
}

export function quantityUnitToAutosuggestItem(
  quantityUnit: QuantityUnitInfo,
): AutosuggestItem {
  return {
    id: quantityUnit.id,
    name: quantityUnit.name,
  };
}
