import React from "react";
import classNames from "classnames";
import styles from "./Text.module.scss";

export interface TextProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  wide?: boolean;
  reducedOpacity?: boolean;
  className?: string;
}

export const Text: React.FC<TextProps> = ({
  title,
  description,
  wide,
  reducedOpacity,
  className,
}) => (
  <span
    className={classNames(
      styles.text,
      { [styles["text--wide"]]: wide },
      className,
    )}
  >
    <span className={styles.title}>{title}</span>
    {description !== undefined && (
      <span
        className={classNames(styles.description, {
          [styles["reduced-opacity"]]: reducedOpacity,
        })}
      >
        {description}
      </span>
    )}
  </span>
);
