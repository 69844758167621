import { ArrayField } from "react-hook-form";
import { Supplier } from "../components/SupplierList/SupplierList";
import { SupplierSelectDropdownField } from "../components/SupplierSelectWithDropdown/SupplierSelectDropdown";
import { RfxStatusEnum } from "../schema";

export function handleFormSupplierChange(
  suppliers: Supplier[],
  fields: Partial<ArrayField<SupplierSelectDropdownField, "id">>[],
  rfxStatus: RfxStatusEnum,
  append: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined,
  ) => void,
  remove: (index?: number | number[] | undefined) => void,
) {
  // if rfx suppliers are same as in fields do nothing
  if (suppliers.length === fields.length) {
    return;
  }

  // if new supplier is added to rfx  append it to fields
  if (suppliers.length > fields.length) {
    if (fields.length === 0) {
      suppliers.map((supplier) => {
        append({
          supplierId: supplier.id,
          supplierName: supplier.name,
          isSelected: true,
        });
      });

      return;
    }

    const missingSupplier = suppliers.find(
      (supplier) =>
        fields.findIndex((field) => field.supplierId === supplier.id) === -1,
    );

    if (missingSupplier) {
      append({
        supplierId: missingSupplier.id,
        supplierName: missingSupplier.name,
        isSelected: true,
      });

      return;
    }
  }

  // if supplier removed also remove it from fields
  if (suppliers.length < fields.length) {
    fields.map((field) => {
      const shouldDelete =
        suppliers.findIndex(
          (supplier) =>
            typeof field.supplierId === "string" &&
            supplier.id === field.supplierId,
        ) === -1;

      if (!shouldDelete) {
        return;
      }

      const fieldIndex = fields.findIndex((f) => f.id === field.id);

      remove(fieldIndex);
    });
  }
}
