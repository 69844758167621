import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { Select } from "../../components/Select/Select";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import {
  useUserById,
  useAdminUpdateUser,
  UserRoleEnum,
  UserStatusEnum,
  OrganizationsManagementAdmin,
  useAddOrganizationToUser,
  useRemoveOrganizationFromUser,
  useAdminUpdateProfilePicture,
} from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { matches } from "../../services/matches";
import { ItemAutosuggest } from "../../components/ItemAutosuggest/ItemAutosuggest";
import { SelectionList } from "../../components/SelectionList/SelectionList";
import { Avatar } from "../../components/Avatar/Avatar";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Unit } from "../../components/Unit/Unit";
import { FieldError } from "../../components/FieldError/FieldError";

export interface EditUserProps {
  userId: string;
  organizations: OrganizationsManagementAdmin["organizations"];
  onModalClose(): void;
}

type RoleMap = { [role in keyof typeof UserRoleEnum]: boolean };

export const EditUser: React.FC<EditUserProps> = ({
  userId,
  organizations,
  onModalClose,
}) => {
  // setup state
  const [organizationValue, setOrganizationValue] = useState("");
  const [removingOrganizationId, setRemovingOrganizationId] = useState<
    string | undefined
  >(undefined);
  const [isInitialized, setIsInitialized] = useState(false);
  const [userRolesError, setUserRolesError] = useState<string>();

  // setup form
  const {
    useInput,
    useSelect,
    useCheckbox,
    loading: isSaving,
    error: saveError,
    submit: updateUser,
  } = useForm({
    mutation: useAdminUpdateUser,
    options: {
      refetchQueries: ["UsersManagement"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
    onError: (e) => {
      const roleErrors = e.graphQLErrors.filter(
        (err) => err?.extensions?.errors["roles"],
      );

      if (roleErrors.length > 0) {
        setUserRolesError("At least 1 role has to be selected");
      }
    },
  });

  // load user by id
  const { data, loading: isFetching, error: fetchError } = useUserById({
    fetchPolicy: "network-only",
    variables: {
      userId,
    },
  });

  // setup adding organization
  const [
    addOrganizationToUser,
    { loading: isAddingOrganization },
  ] = useAddOrganizationToUser({
    refetchQueries: ["UsersManagement"],
    awaitRefetchQueries: true,
  });

  // setup removing organization
  const [removeOrganizationFromUser] = useRemoveOrganizationFromUser({
    refetchQueries: ["UsersManagement"],
    awaitRefetchQueries: true,
  });

  // setup updating profile picture
  const [
    adminUpdateProfilePicture,
    { loading, error },
  ] = useAdminUpdateProfilePicture({
    refetchQueries: ["UsersManagement", "Viewer"],
    awaitRefetchQueries: true,
  });

  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        return;
      }

      adminUpdateProfilePicture({
        variables: {
          profilePicture: acceptedFiles[0],
          userId,
        },
      });
    },
    [adminUpdateProfilePicture, userId],
  );

  // configure inputs
  const firstNameInput = useInput({ name: "firstName" });
  const lastNameInput = useInput({ name: "lastName" });
  const emailInput = useInput({ name: "email" });
  const requesterRoleCheckbox = useCheckbox({ name: "role-requester" });
  const approverRoleCheckbox = useCheckbox({ name: "role-approver" });
  const buyerRoleCheckbox = useCheckbox({ name: "role-buyer" });
  const keyUserRoleCheckbox = useCheckbox({ name: "role-key-user" });
  const statuses = Object.keys(UserStatusEnum) as Array<
    keyof typeof UserStatusEnum
  >;
  const statusInput = useSelect({
    name: "status",
    options: statuses.map((status) => ({
      label: status.charAt(0) + status.slice(1).toLowerCase(),
      value: status,
    })),
  });

  // get user info if loaded
  const user = data?.admin && data.admin.userById;

  useEffect(() => {
    if (!user) {
      return;
    }

    firstNameInput.setValue(user.firstName);
    lastNameInput.setValue(user.lastName);
    emailInput.setValue(user.email);
    statusInput.setValue(user.status);
    requesterRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.REQUESTER));
    approverRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.APPROVER));
    buyerRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.BUYER));
    keyUserRoleCheckbox.setValue(user.roles.includes(UserRoleEnum.KEY_USER));

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // handle fetch error
  if (fetchError) {
    return (
      <ErrorView
        title="Loading user failed"
        error={fetchError}
        modal={{ title: "Edit user", onModalClose }}
      />
    );
  }

  // handle save error
  if (saveError) {
    return (
      <ErrorView
        title="Saving user failed"
        error={saveError}
        modal={{ title: "Edit user", onModalClose }}
      />
    );
  }

  // handle loading
  if (isFetching || !user || !isInitialized) {
    return <LoadingView overlay />;
  }

  const submit = () => {
    const selectedRolesMap: RoleMap = {
      [UserRoleEnum.REQUESTER]: requesterRoleCheckbox.value,
      [UserRoleEnum.APPROVER]: approverRoleCheckbox.value,
      [UserRoleEnum.BUYER]: buyerRoleCheckbox.value,
      [UserRoleEnum.KEY_USER]: keyUserRoleCheckbox.value,
      [UserRoleEnum.SUPPLIER]: false,
      [UserRoleEnum.ADMIN]: false,
    };

    const roleNames = Object.keys(selectedRolesMap) as UserRoleEnum[];
    const selectedRoles = roleNames.reduce<UserRoleEnum[]>((result, role) => {
      const hasRole = selectedRolesMap[role];

      if (!hasRole) {
        return result;
      }

      return [...result, role];
    }, []);

    updateUser({
      userId,
      organizationIds: user.organizations.map(
        (organization) => organization.id,
      ),
      roles: selectedRoles,
    });
  };

  // fetches organizations by entered value (filters from prefetched data)
  const fetchOrganizations = async (value: string) =>
    (value.length === 0
      ? organizations
      : organizations.filter((organization) =>
          matches(value, organization.companyName),
        )
    )
      .map((organization) => ({
        ...organization,
        isUsed:
          user.organizations.find(
            (existingOrganization) =>
              existingOrganization.id === organization.id,
          ) !== undefined,
      }))
      .sort((a, b) =>
        a.isUsed && !b.isUsed ? 1 : !a.isUsed && b.isUsed ? -1 : 0,
      );

  // attempts to remove requested user organization
  const removeOrganization = async (organizationId: string) => {
    setRemovingOrganizationId(organizationId);

    try {
      await removeOrganizationFromUser({
        variables: { userId: user.id, organizationId },
      });
    } finally {
      setRemovingOrganizationId(undefined);
    }
  };

  return (
    <Modal
      title="Edit user"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="f27d4c19bd" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="bf8ade5769"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form onSubmit={submit}>
        <Field {...firstNameInput} label="First name" />
        <Field {...lastNameInput} label="Last name" />
        <Field {...emailInput} label="Email" />
        <ItemAutosuggest
          short
          loading={isAddingOrganization}
          label="Add organization"
          placeholder="Enter organization name"
          fetchSuggestions={fetchOrganizations}
          chosenSuggestion={undefined}
          value={organizationValue}
          onChange={(newValue) => setOrganizationValue(newValue)}
          onChoose={async (organization) => {
            // don't add organization if none is selected or the organization has already been added
            if (
              !organization ||
              user.organizations.find(
                (existingOrganization) =>
                  existingOrganization.id === organization.id,
              )
            ) {
              return;
            }

            // add the organization to user
            await addOrganizationToUser({
              variables: { userId: user.id, organizationId: organization.id },
            });

            // clear value
            setOrganizationValue("");
          }}
        />
        <SelectionList
          label="User organizations"
          selections={user.organizations.map((organization) => ({
            id: organization.id,
            name: organization.companyName,
          }))}
          noSelectionText="No organizations added yet"
          removingSelectionId={removingOrganizationId}
          onRemove={(organization) => removeOrganization(organization.id)}
        />
        <FieldLabel label="Roles" />
        <Field {...requesterRoleCheckbox} checkbox>
          Requester
        </Field>
        <Field {...approverRoleCheckbox} checkbox>
          Approver
        </Field>
        <Field {...buyerRoleCheckbox} checkbox>
          Buyer
        </Field>
        <Field {...keyUserRoleCheckbox} checkbox>
          Key-user
        </Field>
        {userRolesError && (
          <Unit>
            <FieldError error={userRolesError} />
          </Unit>
        )}
        <Select {...statusInput} label="Status" />
        <Dropzone
          multiple={false}
          loading={loading}
          error={error}
          label="Profile picture"
          accept={["image/jpeg", "image/png"]}
          rejectTypeText="Please provide valid image (.jpg or .png)"
          onDrop={onDrop}
        >
          {user.avatarUrl === null ? (
            "Drag file here"
          ) : (
            <Avatar extraLarge user={user} />
          )}
        </Dropzone>
      </Form>
    </Modal>
  );
};
