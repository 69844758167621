import React from "react";
import classNames from "classnames";
import { EditIcon } from "../../theme/svg/EditIcon";
import { LinkIcon } from "../../theme/svg/LinkIcon";
import { SupplierIcon } from "../../theme/svg/SupplierIcon";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { PurchaseRequestItemProgressTypeEnum } from "../../schema";
import { PurchaseRequestItem } from "./PurchaseRequestItems";
import styles from "./PurchaseRequestItems.module.scss";

export interface PurchaseRequestItemDetailsProps {
  item: PurchaseRequestItem;
  className?: string;
}

export const PurchaseRequestItemDetails: React.FC<PurchaseRequestItemDetailsProps> =
  (props) => {
    const { item, className } = props;

    if (
      !item.suggestedSupplier &&
      !item.additionalInfo &&
      !item.code &&
      !item.productUrl &&
      !item.customFields &&
      !item.history
    ) {
      return <em className={styles["no-details"]}>no details provided</em>;
    }

    const deleteOrArchiveHistory = item.history
      ? item.history.find(
          (history) =>
            history.isDeleted &&
            history.progress !==
              PurchaseRequestItemProgressTypeEnum.PURCHASE_REQUEST,
        )
      : undefined;

    return (
      <div className={classNames(styles["wrap"], className)}>
        {deleteOrArchiveHistory && (
          <div
            className={classNames(
              styles["additional-row"],
              styles["important-text"],
            )}
          >
            <ArchiveIcon className={styles["row-icon"]} />
            Item deleted or archived in {deleteOrArchiveHistory.parentCode}
          </div>
        )}
        {item.suggestedSupplier && (
          <div className={styles["additional-row"]}>
            <SupplierIcon className={styles["row-icon"]} />{" "}
            {item.suggestedSupplier}
          </div>
        )}
        {item.additionalInfo && (
          <div className={styles["additional-row"]}>
            <EditIcon className={styles["row-icon"]} /> {item.additionalInfo}
          </div>
        )}
        {item.productUrl && (
          <div className={styles["additional-row"]}>
            <LinkIcon className={styles["row-icon"]} /> {item.productUrl}
          </div>
        )}
        {item.supplierCode && (
          <div className={styles["additional-row"]}>
            SC &nbsp;&nbsp; {item.supplierCode}
          </div>
        )}
        {item.code && (
          <div className={styles["additional-row"]}>
            OC &nbsp;&nbsp; {item.code}
          </div>
        )}
        {item.customFields &&
          Object.entries(item.customFields).map(([label, value], idx) => (
            <div key={label} className={styles["additional-row"]}>
              [{label ?? `CF${idx + 1}`}]: {value}
            </div>
          ))}
      </div>
    );
  };
