import React from "react";
import classNames from "classnames";
import { DeleteFileIcon } from "../../theme/svg/DeleteFileIcon";
import styles from "./NonUploadedFiles.module.scss";

export interface NonUploadedFilesProps {
  className?: string;
  files: File[];
  setFiles: (files: File[]) => void;
}

export const NonUploadedFiles: React.FC<NonUploadedFilesProps> = ({
  files,
  setFiles,
}) => (
  <div className={styles["attachments"]}>
    {files.map((file) => (
      <div key={file.name}>
        <DeleteFileIcon
          className={classNames(styles["delete-file-icon"])}
          onClick={() =>
            setFiles(files.filter((stateFile) => file.name !== stateFile.name))
          }
        />
        <span className={styles["filename"]}>{file.name}</span>
      </div>
    ))}
  </div>
);
