import { Text, Transforms, Editor } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";

type EditorType = Editor & ReactEditor & HistoryEditor;

export const Commands = {
  isBoldMarkActive(editor: EditorType) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.bold === true,
      universal: true,
    });

    return !!match;
  },

  isCodeBlockActive(editor: EditorType) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "code",
    });

    return !!match;
  },

  isQuoteBlockActive(editor: EditorType) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "quote",
    });

    return !!match;
  },

  toggleBoldMark(editor: EditorType) {
    const isActive = Commands.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true },
    );
  },

  toggleCodeBlock(editor: EditorType) {
    const isActive = Commands.isCodeBlockActive(editor);
    Transforms.setNodes(
      editor,
      { type: isActive ? null : "code" },
      { match: (n) => Editor.isBlock(editor, n) },
    );
  },

  toggleQuoteBlock(editor: EditorType) {
    const isActive = Commands.isQuoteBlockActive(editor);
    Transforms.setNodes(
      editor,
      { type: isActive ? null : "quote" },
      { match: (n) => Editor.isBlock(editor, n) },
    );
  },
};
