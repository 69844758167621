import React from "react";
import classNames from "classnames";
import * as R from "ramda";
import {
  OfferSentStatus,
  OfferSentStatusEnum,
} from "../OfferSentStatus/OfferSentStatus";
import {
  SourcingEvent,
  Organization,
  User,
  SourcingRequest,
  OutboundEmail,
  SourcingRequestByIdSourcingEvent,
} from "../../schema";
import { formatDatetime } from "../../services/formatDatetime";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { formatFilesize } from "../../services/formatFilesize";
import { EmailLink } from "../EmailLink/EmailLink";
import { isOfType } from "../../lib/helpers/typescript-helpers";
import { isDefined } from "../../services/isDefined";
import { config } from "../../config";
import styles from "./SourcingEventInfo.module.scss";

interface InfoItemProps {
  name: string;
  value: React.ReactNode;
  className?: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ name, value, className }) => {
  return (
    <div className={classNames(styles.item, className)}>
      <div className={styles["item-name"]}>{name}</div>
      <div className={styles["item-value"]}>{value}</div>
    </div>
  );
};

export interface SourcingEventInfoProps {
  isOfferSent?: boolean;
  isPreview?: boolean;
  rfx?: SourcingRequestByIdSourcingEvent["rfx"];
  sourcingOrganization?: Pick<
    Organization,
    "companyName" | "address" | "city" | "areaCode" | "country"
  >;
  sourcingEvent?: Pick<
    SourcingEvent,
    | "id"
    | "name"
    | "additionalInfo"
    | "status"
    | "linkToken"
    | "responseDeadline"
    | "sentDate"
  > & {
    attachments?: {
      id: string;
      filename: string;
      url: string | null;
      size: number;
    }[];
  };
  sourcingEventCreator?: Pick<
    User,
    "id" | "firstName" | "lastName" | "avatarUrl" | "email"
  >;
  sourcingRequest?: Pick<SourcingRequest, "updatedDate" | "supplierId">;
  displayContactLink?: boolean;
  displayRequestStatus?: boolean;
}

export const SourcingEventInfo: React.FC<SourcingEventInfoProps> = ({
  isOfferSent,
  isPreview,
  rfx,
  sourcingOrganization,
  sourcingEvent,
  sourcingEventCreator,
  sourcingRequest,
  displayContactLink = false,
  displayRequestStatus = false,
}) => {
  // information is missing to render this component
  if (
    !rfx ||
    !sourcingEvent ||
    !sourcingOrganization ||
    !sourcingEventCreator
  ) {
    return (
      <div className={styles["info-missing"]}>
        Sourcing event information is missing
      </div>
    );
  }

  // get outbound invitation e-mail address
  const outboundInvitationEmails = rfx?.emails.filter((email) => {
    return (
      email.__typename === "OutboundEmail" &&
      sourcingRequest &&
      email.supplierId === sourcingRequest.supplierId
    );
  });

  const invitationEmail =
    Array.isArray(outboundInvitationEmails) &&
    isOfType<OutboundEmail>(
      outboundInvitationEmails[0],
      (email) => email && email.__typename === "OutboundEmail",
    )
      ? outboundInvitationEmails[0]
      : undefined;

  const rfqEmailAddress = isDefined(invitationEmail)
    ? `${invitationEmail.id}@${config.inboundEmailDomain}`
    : undefined;

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles["header-details"]}>
          <div className={styles.code}>{`${rfx.type}-${rfx.code}:`}</div>
          <div className={styles.name}>{`${sourcingEvent.name}`}</div>
        </div>
        {displayRequestStatus && (
          <div className={styles["header-status"]}>
            <OfferSentStatus
              state={
                isOfferSent && sourcingRequest?.updatedDate
                  ? OfferSentStatusEnum.SENT
                  : isPreview
                  ? OfferSentStatusEnum.PREVIEW
                  : OfferSentStatusEnum.PENDING
              }
              dateTime={sourcingRequest?.updatedDate}
            />
          </div>
        )}
      </div>

      <div className={styles["info-wrapper"]}>
        <div className={styles["info-item-group"]}>
          <InfoItem
            name="Response expected"
            value={formatDatetime(sourcingEvent.responseDeadline)}
          />
          {sourcingEvent.sentDate && (
            <InfoItem
              name="Sent"
              value={formatDatetime(sourcingEvent.sentDate)}
            />
          )}
        </div>

        <div className={styles["info-item-group"]}>
          <InfoItem
            name="Official address"
            value={`${sourcingOrganization.address}, ${sourcingOrganization.city} ${sourcingOrganization.areaCode}, ${sourcingOrganization.country.name}`}
          />
          <InfoItem
            name="Requested by"
            value={sourcingOrganization.companyName}
          />
        </div>

        <div className={styles["info-item-group"]}>
          <InfoItem
            name="Contact Person"
            value={
              <>
                <div>{`${sourcingEventCreator.firstName} ${sourcingEventCreator.lastName}`}</div>
                {displayContactLink && (
                  <EmailLink
                    subject={`RE: [${rfx.type}-${rfx.code}] ${sourcingEvent.name}`}
                    toEmail={rfqEmailAddress}
                    contactPerson={{
                      id: sourcingEventCreator.id,
                      email: sourcingEventCreator.email,
                      firstName: sourcingEventCreator.firstName,
                      lastName: sourcingEventCreator.lastName,
                      avatarUrl: sourcingEventCreator.avatarUrl ?? undefined,
                    }}
                  />
                )}
              </>
            }
          />
          <InfoItem
            name="Attachments"
            value={
              <div className={styles["attachment-list"]}>
                {R.not(R.isEmpty(sourcingEvent.attachments)) ? (
                  sourcingEvent.attachments?.map((attachment) => (
                    <div className={styles["attachment"]} key={attachment.id}>
                      <a
                        href={attachment.url ?? "#"}
                        className={styles["attachment-link"]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={styles["filename"]}>
                          {attachment.filename}
                        </div>
                      </a>
                      <div className={styles["file-info"]}>
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                        <div className={styles["size"]}>{`(${formatFilesize(
                          attachment.size,
                        )})`}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>-</div>
                )}
              </div>
            }
          />
        </div>
      </div>
      {sourcingEvent.additionalInfo && (
        <div className={styles["info-item-group"]}>
          <InfoItem
            name="Additional Info"
            value={sourcingEvent.additionalInfo}
          />
        </div>
      )}
    </div>
  );
};
