import React, { CSSProperties } from "react";
import classNames from "classnames";
import { RenderElementProps } from "slate-react";
import { Element } from "slate";
import styles from "./QuoteElement.module.scss";

export interface QuoteElementProps extends RenderElementProps {
  className?: string;
  style?: CSSProperties;
}

export type QuoteElementType = Element & { type: "quote" };

export const QuoteElement: React.FC<QuoteElementProps> = (props) => {
  const { className, attributes, children, style } = props;

  return (
    <blockquote
      style={style}
      className={classNames(styles["wrap"], className)}
      {...attributes}
    >
      <p>{children}</p>
    </blockquote>
  );
};

// helper that determines if an element is a QuoteElement
export function isQuoteElement(element: Element): element is QuoteElementType {
  return element.type === "quote";
}
