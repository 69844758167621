import React from "react";

export interface ReadOnlyContextProviderProps {
  isReadOnly: boolean;
  children?: React.ReactNode;
}

export const ReadOnlyContext = React.createContext<boolean>(false);

export function ReadOnlyContextProvider({
  isReadOnly,
  children,
}: ReadOnlyContextProviderProps) {
  return (
    <ReadOnlyContext.Provider value={isReadOnly}>
      {children}
    </ReadOnlyContext.Provider>
  );
}

/**
 * Convenient use context hook to be used inside ReadOnlyProvider
 *
 * @returns React.useContext(ReadOnly)
 *
 * @example  const card = useReadOnlyContext()
 */
export function useReadOnlyContext() {
  return React.useContext(ReadOnlyContext);
}
