import { PaginatedRfxEdges } from "../schema";
import { CardInfo } from "../components/Card/Card";
import { AvatarUser } from "../components/Avatar/Avatar";
import { DraggableType } from "../components/Swimlane/Swimlane";
import { pick } from "./pick";
import { getItemInfo } from "./getItemInfo";

export function getRfxCardInfo(
  info: PaginatedRfxEdges,
  users: AvatarUser[],
  assigningCardId: string | undefined,
): CardInfo {
  const prefix = info.type === null ? "RFX" : info.type;
  const cardCode = `${prefix}-${info.code}`;

  return {
    ...pick(
      info,
      "id",
      "code",
      "name",
      "assignee",
      "suppliers",
      "projects",
      "requestors",
      "assignee",
      "createdDate",
    ),
    type: DraggableType.CARD,
    prefix,
    status: info.cardStatus,
    items: info.items.map((item) => getItemInfo(item, info.id, cardCode)),
    users,
    description: "", // TODO: implement dynamic description
    labels: [], // TODO: use real labels once implemented
    attachmentCount: null, // TODO: use real attachment count once implemented
    commentCount: null, // TODO: use real comment count once implemented
    assigning: info.id === assigningCardId,
  };
}
