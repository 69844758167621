import classNames from "classnames";
import React from "react";
import {
  CardStatus,
  PurchaseRequestItemInfoFragment,
  DepartmentInfoFragment,
  ProjectInfoFragment,
  UserInfoFragment,
  PurchaseRequestItem,
} from "../../schema";
import { formatDate } from "../../services/formatDate";
import { AvatarUser, Avatar } from "../Avatar/Avatar";
import { PurchaseRequestCard } from "../PurchaseRequestCard/PurchaseRequestCard";
import styles from "./PurchaseRequestSummary.module.scss";

interface SummaryItemProps {
  title?: string;
  value?: string | null;
  assignee?: AvatarUser | null;
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  title,
  value,
  assignee,
}) => {
  return (
    <div
      className={classNames(styles["summary-item"], {
        [styles["summary-item--empty"]]: !value,
      })}
    >
      {assignee ? (
        <div className={styles["summary-user"]}>
          <Avatar
            user={assignee}
            large
            title={title}
            className={styles["summary-user__avatar"]}
          />
          <div className={styles["summary-user-details"]}>
            {title}
            <div className={styles["summary-item__value"]}>
              <strong>
                {assignee.firstName} {assignee.lastName}
              </strong>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {value && (
            <>
              {title}
              <div className={styles["summary-item__value"]}>
                <strong>{value}</strong>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

interface PurchaseRequestSummaryProps {
  assignee?: UserInfoFragment;
  cardStatus: CardStatus;
  code: string;
  department?: DepartmentInfoFragment;
  id: string;
  items?: PurchaseRequestItemInfoFragment[] | PurchaseRequestItem[];
  name: string;
  onClick?: () => void;
  project?: ProjectInfoFragment;
}

export const PurchaseRequestSummary: React.FC<PurchaseRequestSummaryProps> = ({
  assignee,
  code,
  cardStatus,
  department,
  id,
  items = [],
  name,
  onClick,
  project,
}) => {
  const earliestDelivery = getEarliestDelivery(items);

  return (
    <PurchaseRequestCard
      title={
        <h1 className={styles["content-title"]}>
          <span className={styles["content-title__span"]}>{`PR-${code}`}:</span>{" "}
          {name}{" "}
          <span className={styles["content-title__span"]}>
            (
            {items.length > 1
              ? `${items.length} items`
              : items.length === 1
              ? "one item"
              : "zero item"}
            )
          </span>
        </h1>
      }
      key={id}
      status={cardStatus}
      onClick={onClick}
      isSummary
    >
      <div className={styles.summary}>
        <SummaryItem title="Earliest exp delivery" value={earliestDelivery} />
        <SummaryItem title="Unit" value={department?.name} />
        <SummaryItem title="Project" value={project?.name} />
        {assignee && <SummaryItem title="Assigned to" assignee={assignee} />}
      </div>
    </PurchaseRequestCard>
  );
};

function getEarliestDelivery(items: PurchaseRequestItemInfoFragment[]) {
  const earliestDelivery = items
    .map((e) => {
      return e.expectedDeliveryDate;
    })
    .sort()[0];

  return earliestDelivery ? formatDate(earliestDelivery) : undefined;
}
