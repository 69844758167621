import React from "react";
import classNames from "classnames";
import styles from "./FieldLabel.module.scss";

export interface FieldLabelProps {
  label: string;
  htmlFor?: string;
  className?: string;
  required?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({
  label,
  htmlFor,
  className,
  required,
  children,
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        styles.label,
        { [styles["label--required"]]: required },
        className,
      )}
    >
      {label}
      {children && <div className={styles.content}>{children}</div>}
    </label>
  );
};
