import React from "react";
import { ApolloError } from "apollo-client";
import classNames from "classnames";
import { Button } from "../../components/Button/Button";
import { EmailForm } from "../../components/EmailForm/EmailForm";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { Field } from "../../components/Field/Field";
import { SupplierList } from "../../components/SupplierList/SupplierList";
import { useForm, InputInfo } from "../../lib/react-apollo-hooks-form";
import {
  useAddSupplierToRfx,
  useCreateSupplier,
  useRemoveSupplierFromRfx,
  RfxByCodeQuery,
  RfxTypeEnum,
  useSupplierFullTextSearch,
  SupplierFullTextSearchResultTypeEnum,
  useSuppliersByCategoryLazyQuery,
  useSupplierByContactPersonLazyQuery,
} from "../../schema";
import { getSuppliersSelectedForCurrentRfxType } from "../../services/getSuppliersSelectedForCurrentRfxType";
import { isEmail } from "../../validators/isEmail";
import { notEmpty } from "../../validators/notEmpty";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import {
  SupplierAutosuggest,
  SupplierSuggestion,
} from "../SupplierAutosuggest/SupplierAutosuggest";
import { OnChoose } from "../Autosuggest/Autosuggest";
import { isValueEmail } from "../../services/isValueEmail";
import { encodeNameForURL } from "../../services/encodeNameForURL";
import { config } from "../../config";
import { UniqueSelectedSupplier } from "../RfqItemQuestionnaire/RfqItemQuestionnaire";
import styles from "./AddAndEmailSuppliers.module.scss";

export interface AddAndEmailSuppliersSectionProps {
  data: RfxByCodeQuery;
  nameInput: InputInfo;
  initialSubject?: string;
  sendButtonText?: string;
  templateMessage?: string;
  onSendSuccess?: (
    suppliers: { id: string; email?: string | null; name?: string | null }[],
  ) => void;
  onSendError?: (error: string | ApolloError) => void;
  className?: string;
  visibilitySelectedSuppliers?: UniqueSelectedSupplier[];
}

export const AddAndEmailSuppliersSection: React.FC<AddAndEmailSuppliersSectionProps> =
  (props) => {
    const {
      data,
      sendButtonText,
      initialSubject,
      templateMessage,
      className,
      onSendSuccess,
      onSendError,
      visibilitySelectedSuppliers,
    } = props;

    // STATE
    const [areSupplierFieldsActive, setAreSupplierFieldsActive] =
      React.useState(false);

    const [removingSupplierId, setRemovingSupplierId] = React.useState<
      string | undefined
    >();

    const [selectedSuppliers, setSelectedSuppliers] = React.useState<
      {
        id: string;
        defaultContactPerson: { email?: string | null };
        name?: string | null;
      }[]
    >([]);

    const [suppliersToEmail, setSuppliersToEmail] = React.useState<
      {
        id: string;
        defaultContactPerson: { email: string };
        name?: string | null | undefined;
      }[]
    >([]);

    // resolve info
    const rfx = data?.viewer ? data.viewer.rfxByCode : undefined;
    const organization = data?.viewer ? data.viewer?.organization : undefined;
    const items = rfx?.items ? rfx.items : [];
    const itemsAttachments = items.flatMap((item) =>
      item.attachments ? item.attachments : [],
    );
    const rfxAttachments = rfx?.attachments ?? [];
    const sourcingEvent = rfx?.sourcingEvent;

    const activeSuppliersWithResolution =
      rfx?.activeSuppliers !== undefined
        ? rfx.activeSuppliers.map((supplier) => {
            const supplierResolution =
              rfx.supplierResolutions &&
              rfx.supplierResolutions.find(
                (sr) => sr.supplierId === supplier.id,
              );

            return {
              ...supplier,
              ...{ resolution: supplierResolution?.resolution ?? null },
            };
          })
        : [];

    let suppliersToEmailConst = [...activeSuppliersWithResolution];

    if (
      suppliersToEmailConst &&
      suppliersToEmailConst.length > 0 &&
      visibilitySelectedSuppliers &&
      visibilitySelectedSuppliers.length > 0
    ) {
      suppliersToEmailConst = suppliersToEmailConst.filter((supplier) =>
        visibilitySelectedSuppliers.some(
          (selectedSupplier) => selectedSupplier.supplierId === supplier.id,
        ),
      );
    }

    // prepare search query
    const [performFullTextSearch, { loading: isSearchLoading }] =
      useSupplierFullTextSearch();

    // set default input values once rfx is loaded
    React.useEffect(() => {
      if (!rfx || !activeSuppliersWithResolution) {
        return;
      }

      // there are suppliers selected
      setSelectedSuppliers(
        getSuppliersSelectedForCurrentRfxType(
          rfx,
          activeSuppliersWithResolution,
        ),
      );

      // there are suppliers to email to
      setSuppliersToEmail(
        getSuppliersSelectedForCurrentRfxType(
          rfx,
          activeSuppliersWithResolution,
        ),
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rfx]);

    // set default input values once rfx is loaded
    React.useEffect(() => {
      if (!rfx || !activeSuppliersWithResolution) {
        return;
      }

      // there are suppliers to email to
      setSuppliersToEmail(
        getSuppliersSelectedForCurrentRfxType(rfx, suppliersToEmailConst),
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibilitySelectedSuppliers]);

    // setup adding supplier
    const [addSupplierToRfx, { loading: isAddingSupplier }] =
      useAddSupplierToRfx({
        refetchQueries: ["RfxByCode", "PaginatedRfx"],
        awaitRefetchQueries: true,
      });

    // handle loading (also avoid form content flicker)
    if (!rfx || !organization) {
      return <LoadingView overlay />;
    }

    // setup supplier by category lazy query
    const [getSuppliersByCategory, { loading: isSuppliersByCategoryLoading }] =
      useSuppliersByCategoryLazyQuery({
        onCompleted: (response) => {
          const supplierList = response.viewer?.suppliersByCategoryId;

          supplierList?.forEach((supplier) => {
            // don't add supplier if none is selected or the supplier has already been added
            if (
              !supplier ||
              selectedSuppliers.find(
                (existingSupplier) => existingSupplier.id === supplier.id,
              )
            ) {
              return;
            }
            // add the supplier to rfx
            addSupplierToRfx({
              variables: { rfxId: rfx.id, supplierId: supplier.id },
            });
          });
        },
      });

    // setup supplier by contact person lazy query
    const [
      getSupplierByContactPerson,
      { loading: isSuppliersByContactPersonLoading },
    ] = useSupplierByContactPersonLazyQuery({
      onCompleted: (response) => {
        const supplier = response.viewer?.supplierByContactPerson;

        if (!supplier) {
          return;
        }
        // add the supplier to rfx
        addSupplierToRfx({
          variables: { rfxId: rfx.id, supplierId: supplier.id },
        });
      },
    });

    // configure supplier form
    const {
      useInput: useSupplierInput,
      submit: createSupplier,
      loading: isCreateSupplierLoading,
      error: supplierError,
      reset: resetSupplierForm,
    } = useForm({
      mutation: useCreateSupplier,
      options: {
        refetchQueries: ["PaginatedRfx", "RfxByCode"],
        awaitRefetchQueries: true,
      },
      onSuccess: (response) => {
        setAreSupplierFieldsActive(false);
        resetSupplierForm();

        addSupplierToRfx({
          variables: { rfxId: rfx.id, supplierId: response.createSupplier.id },
        });
      },
    });

    // configure supplier inputs
    const supplierNameInput = useSupplierInput({
      name: "name",
      validators: [notEmpty],
    });
    const supplierEmailInput = useSupplierInput({
      name: "email",
      validators: [notEmpty, isEmail],
    });

    // setup removing supplier
    const [removeSupplierFromRfx] = useRemoveSupplierFromRfx({
      refetchQueries: ["RfxByCode", "PaginatedRfx"],
      awaitRefetchQueries: true,
    });

    // attempts to remove requested rfx supplier
    const removeSupplier = async (supplierId: string) => {
      setRemovingSupplierId(supplierId);

      try {
        await removeSupplierFromRfx({
          variables: { rfxId: rfx.id, supplierId },
        });
      } finally {
        setRemovingSupplierId(undefined);
      }
    };

    const fetchSuggestions = async (searchInput: string) => {
      const response = await performFullTextSearch({
        variables: {
          searchInput,
          organizationId: organization.id,
          rfxId: rfx.id,
        },
      });

      const result = response.data?.supplierFullTextSearch;

      return result && result.length > 0 ? result : [];
    };

    const onChoose: OnChoose<SupplierSuggestion> = async (chosenSuggestion) => {
      if (!chosenSuggestion) {
        return;
      }

      // when choosing SUPPLIER
      if (
        chosenSuggestion.type === SupplierFullTextSearchResultTypeEnum.SUPPLIER
      ) {
        // don't add supplier if none is selected or the supplier has already been added
        if (
          !chosenSuggestion ||
          selectedSuppliers.find(
            (existingSupplier) => existingSupplier.id === chosenSuggestion.id,
          )
        ) {
          return;
        }
        // add the supplier to rfx
        addSupplierToRfx({
          variables: { rfxId: rfx.id, supplierId: chosenSuggestion.id },
        });
      }

      // when choosing CATEGORY
      if (
        chosenSuggestion.type === SupplierFullTextSearchResultTypeEnum.CATEGORY
      ) {
        getSuppliersByCategory({
          variables: {
            categoryId: chosenSuggestion?.id,
            organizationId: organization.id,
          },
        });
      }

      // when choosing CONTACT PERSON
      if (
        chosenSuggestion.type ===
        SupplierFullTextSearchResultTypeEnum.CONTACT_PERSON
      ) {
        getSupplierByContactPerson({
          variables: {
            contactPersonId: chosenSuggestion?.id,
            organizationId: organization.id,
          },
        });
      }
    };

    const onCreateNew = (input: string) => {
      setAreSupplierFieldsActive(true);

      // fill correct input
      isValueEmail(input)
        ? supplierEmailInput.setValue(input)
        : supplierNameInput.setValue(input);
    };

    return (
      <div className={classNames(styles["wrap"], className)}>
        <div className={styles["grid"]}>
          {/* LEFT COLUMN */}
          <div className={styles["left-col"]}>
            <h4>List of suppliers</h4>

            <div className={styles["add-supplier"]}>
              <SupplierAutosuggest
                label="Add or choose supplier"
                fetchSuggestions={fetchSuggestions}
                onChoose={onChoose}
                onCreateNew={onCreateNew}
                loading={
                  isCreateSupplierLoading ||
                  isSearchLoading ||
                  isAddingSupplier ||
                  isSuppliersByCategoryLoading ||
                  isSuppliersByContactPersonLoading
                }
              />
            </div>

            {areSupplierFieldsActive && (
              <div>
                <ErrorMessage error={supplierError} />
                <div className={styles["supplier-info"]}>
                  <Field {...supplierNameInput} short label="Name" />
                  <Field
                    {...supplierEmailInput}
                    autofocus
                    short
                    label="Email"
                    required
                  />
                </div>
                <div className={styles["supplier-form-buttons"]}>
                  <Button
                    secondary
                    onClick={() => {
                      setAreSupplierFieldsActive(false);
                      resetSupplierForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      createSupplier({ organizationId: organization.id });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}

            <SupplierList
              suppliers={selectedSuppliers}
              removingSupplierId={removingSupplierId}
              onRemove={(supplier) => removeSupplier(supplier.id)}
            />
          </div>

          {/* RIGHT COLUMN */}
          <div className={styles["right-col"]}>
            {selectedSuppliers.length > 0 && (
              <div className={styles["email-form"]}>
                <h4>Email suppliers</h4>
                <EmailForm
                  attachments={
                    rfx.type === RfxTypeEnum.RFQ
                      ? []
                      : [...rfxAttachments, ...itemsAttachments]
                  }
                  sourcingEventId={rfx.sourcingEvent?.id}
                  organization={organization}
                  initialSubject={initialSubject ?? `[${rfx.type}] ${rfx.name}`}
                  sendButtonText={sendButtonText}
                  templateMessage={templateMessage}
                  suppliers={suppliersToEmail}
                  onSendError={(error) => {
                    onSendError && onSendError(error);
                  }}
                  onSendSuccess={(suppliers) => {
                    onSendSuccess && onSendSuccess(suppliers);
                  }}
                  onSupplierRemove={(id) => {
                    const newListOfSuppliersToEmail = suppliersToEmail.filter(
                      (supplier) => supplier.id !== id,
                    );
                    setSuppliersToEmail(newListOfSuppliersToEmail);
                  }}
                />
              </div>
            )}
            {/* link to sourcing event self service */}
            {sourcingEvent?.linkToken && (
              <div className={styles["sourcing-event-link"]}>
                <div className={styles["link-row"]}>
                  <div className={styles["event-link-title"]}>Event link:</div>
                  <div className={styles["url"]}>
                    {`${config.ssl ? "https://" : "http://"}${config.host}${
                      config.port === 80 ? "" : ":" + config.port
                    }/esourcing/registration/${
                      sourcingEvent.linkToken
                    }/${encodeNameForURL(sourcingEvent.name)}`}
                  </div>
                </div>
                <div className={styles["instructions"]}>
                  <div>
                    Want to send out eSourcing event from your traditional
                    mailbox?
                  </div>
                  <div>
                    Just select and copy the link and send it to new or existing
                    suppliers
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
