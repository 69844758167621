import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { Checkbox } from "../Checkbox/Checkbox";
import { Attachment } from "../../schema";
import { formatFilesize } from "../../services/formatFilesize";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { useReadOnlyContext } from "../../contexts/readonly-context";
import styles from "./AttachmentListSelectItem.module.scss";

export interface AttachmentListSelectProps {
  className?: string;
  attachment: Partial<Pick<Attachment, "id" | "filename" | "size" | "url">> & {
    selected?: boolean;
  };
}

export const AttachmentListSelectItem: React.FC<AttachmentListSelectProps> = (
  props,
) => {
  const { className, attachment } = props;

  // use read only context
  const isReadOnly = useReadOnlyContext();

  const { register } = useFormContext();

  return (
    <div className={classNames(styles["wrap"], className)}>
      {!isReadOnly && (
        <Checkbox
          className={styles["checkbox"]}
          name={`attachments[${attachment.id}]`}
          inputRef={register}
          defaultChecked={attachment.selected ?? false}
        />
      )}
      <a
        href={attachment.url ?? "#"}
        className={styles["attachment-link"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles["filename"]}>{attachment.filename}</div>
        <OpenExternalIcon className={styles["open-external-icon"]} />
      </a>
      {attachment.size && (
        <div className={styles["size"]}>{`(${formatFilesize(
          attachment.size,
        )})`}</div>
      )}
    </div>
  );
};
