import classNames from "classnames";
import React, { forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { getYear, getMonth, format } from "date-fns";
import range from "lodash/range";
import { SelectedFilters } from "../../views/MemberView/MemberView";
import { Button } from "../Button/Button";
import styles from "./DateRangeFilter.module.scss";
export interface DateRangeFilterProps {
  title?: string;
  label?: string;
  className?: string;
  selectedFilters: SelectedFilters | undefined;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<SelectedFilters | undefined>
  >;
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  selectedFilters,
  setSelectedFilters,
}) => {
  const [dateRange, setDateRange] = React.useState<(Date | null)[]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const years = range(2019, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    let formatStartDate: string | null = null;
    let formatEndDate: string | null = null;

    if (startDate) {
      formatStartDate = format(startDate, "yyyy-MM-dd");
    }

    if (endDate) {
      formatEndDate = format(endDate, "yyyy-MM-dd");
    }

    selectedFilters
      ? setSelectedFilters(
          Object.assign({}, selectedFilters, {
            dateRange: { startDate: formatStartDate, endDate: formatEndDate },
          }),
        )
      : setSelectedFilters({
          dateRange: { startDate: formatStartDate, endDate: formatEndDate },
        });
  }, [dateRange]);

  // eslint-disable-next-line react/display-name
  const ButtonInput = forwardRef<
    HTMLButtonElement,
    React.HTMLProps<HTMLButtonElement>
  >(({ value, onClick }, _ref) => (
    <>
      <p className={styles.label}>Date Range</p>
      <Button
        quentary
        className={classNames(styles["date-range-button"])}
        onClick={onClick}
      >
        {!startDate && !endDate ? "All Dates" : value}
      </Button>
    </>
  ));

  return (
    <div
      className={classNames(styles["date-range-wrapper"], {
        [styles["date-range-wrapper--arrow"]]: !startDate && !endDate,
      })}
    >
      <DatePicker
        selectsRange
        dateFormat="dd/MM/yyyy"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update as (Date | null)[]);
        }}
        isClearable={true}
        customInput={<ButtonInput />}
        popperPlacement="top"
        showPopperArrow={false}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) =>
                changeYear(parseFloat(value))
              }
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
      ></DatePicker>
    </div>
  );
};
