import React from "react";
import ApolloReactHooks from "@apollo/react-hooks";

// attempts to login with given token
export function useLogin({
  token,
  mutationFunction,
}: {
  token: string | undefined;
  mutationFunction: (
    baseOptions?: ApolloReactHooks.MutationHookOptions<any, any> | undefined,
  ) => ApolloReactHooks.MutationTuple<any, any>;
}) {
  const [attempted, setIsAttempted] = React.useState(false);
  const [success, setIsSuccess] = React.useState(false);
  const [loading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const [loginWithToken] = mutationFunction({
    variables: {
      token: token || "",
    },
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
    fetchPolicy: "no-cache",
  });

  if (token && !attempted) {
    setIsLoading(true);
    setIsAttempted(true);

    loginWithToken()
      .then((result) => {
        setIsSuccess(true);

        return result;
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  const reset = () => {
    setIsSuccess(false);
    setIsLoading(false);
    setError(undefined);
  };

  return {
    attempted,
    loading,
    success,
    error,
    reset,
  };
}
