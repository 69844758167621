import React, { useEffect, useState, createRef } from "react";
import classNames from "classnames";
import { Modal } from "../../components/Modal/Modal";
import {
  CardTypeEnum,
  OrderByCodeCountries,
  OrderByCodeOrderByCode,
  UserInfoFragment,
  ViewerOrganizations,
  useAssignCard,
  useUpdateOrder,
  useUpdateOrderHasBeenSent,
  SupplierInfoFragment,
  useUpdateOrderType,
  OrderTypeEnum,
  CardPositionMethodEnum,
  useRemovePurchaseRequestItem,
  ActivityRelativeTypeEnum,
  OrderByCodeAddresses,
  useCreateOrganizationAddress,
  useUpdateOrderStatus,
  AddressTypeEnum,
  OrderStatusEnum,
  PurchaseOrderReleaseMethodEnum,
  useUpdateOrderReleaseMethod,
  ViewerViewer,
  useArchiveOrder,
} from "../../schema";
import { UserSelectDropdown } from "../../components/UserSelectDropdown/UserSelectDropdown";
import { Button } from "../../components/Button/Button";
import { Field } from "../../components/Field/Field";
import { Accordion } from "../../components/Accordion/Accordion";
import { Separator } from "../../components/Separator/Separator";
import { Datepicker } from "../../components/Datepicker/Datepicker";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { useRouter } from "../../hooks/useRouter";
import { Form } from "../../components/Form/Form";
import { PurchaseRequestItems } from "../../components/PurchaseRequestItems/PurchaseRequestItems";
import { Select } from "../../components/Select/Select";
import {
  ItemAutosuggest,
  AutosuggestItem,
} from "../../components/ItemAutosuggest/ItemAutosuggest";
import { CommentForm } from "../../components/CommentForm/CommentForm";
import { CollaborationSidebar } from "../../components/CollaborationSidebar/CollaborationSidebar";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { currencies } from "../../constants";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { EditIcon } from "../../theme/svg/EditIcon";
import { matches } from "../../services/matches";
import { scrollToRef } from "../../services/scrollToRef";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { PurchaseOrderIcon } from "../../theme/svg/PurchaseOrderIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { SendDocumentIcon } from "../../theme/svg/SendDocumentIcon";
import { EmailForm } from "../../components/EmailForm/EmailForm";
import { CardContextProvider } from "../../contexts/card-context";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { checkDownloadUrlValidity } from "../../services/checkDownloadUrlValidity";
import { CorrespondenceBar } from "../../components/CorrespondenceBar/CorrespondenceBar";
import { notEmpty } from "../../validators/notEmpty";
import { LoadingView } from "../LoadingView/LoadingView";
import { LabelDropdown } from "../../components/LabelDropdown/LabelDropdown";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import styles from "./PurchaseOrderModal.module.scss";

export interface PurchaseOrderModalProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  order: OrderByCodeOrderByCode;
  users: UserInfoFragment[];
  usersWhoCanBeAssignedToCards: UserInfoFragment[];
  countries: OrderByCodeCountries[];
  suppliers: SupplierInfoFragment[];
  addresses: OrderByCodeAddresses[];
  onModalClose(): void;
}

export const PurchaseOrderModal: React.FC<PurchaseOrderModalProps> = ({
  viewer,
  organization,
  order,
  users,
  usersWhoCanBeAssignedToCards,
  countries,
  suppliers,
  addresses,
  onModalClose,
}) => {
  const { history } = useRouter();

  // Extract values
  const orderAttachments = order?.attachments ?? [];
  const orderItems = order?.items ?? [];
  const itemsAttachments = orderItems.flatMap((item) =>
    item.attachments ? item.attachments : [],
  );

  // SETUP STATE

  const [hasError, setHasError] = useState<string>();
  const errorRef = createRef<HTMLDivElement>();

  // is the whole form editable
  const [isEditable, setIsEditable] = useState(
    order.status !== OrderStatusEnum.PO_READY,
  );

  // simulate loading for PO PDF
  const [isPreviewPdfLoading, setIsPreviewPdfLoading] = React.useState(false);

  // are parts of form editable
  const [isOrderNumberEditable, setIsOrderNumberEditable] = useState(false);

  const [isPaymentTermEditable, setIsPaymentTermEditable] = useState(false);

  const [isNewShippingAddressFormActive, setIsNewShippingAddressFormActive] =
    useState(false);

  const [isNewInvoiceAddressFormActive, setIsNewInvoiceAddressFormActive] =
    React.useState(false);

  // Initially closed info field visibility states
  const [isSupplierInfoFieldVisible, setIsSupplierInfoFieldVisible] =
    useState(false);
  const [isDeliveryInfoFieldVisible, setIsDeliveryInfoFieldVisible] =
    useState(false);
  const [isBillingInfoFieldVisible, setIsBillingInfoFieldVisible] =
    useState(false);

  const [isSendingFormVisible, setIsSendingFormVisible] = useState(
    order.status === OrderStatusEnum.PO_READY,
  );

  // controls whether to show e-mail form
  const [showEmailForm, setShowEmailForm] = useState(false);

  // show the success of error message for email
  const [submitEmailSuccess, setSubmitEmailSuccess] = useState(false);
  // keep track of which purchase request we are deleting
  const [deletingPurchaseRequestItemId, setDeletingPurchaseRequestItemId] =
    useState<string | undefined>(undefined);

  const [chosenSupplier, setChosenSupplier] = useState<
    | {
        id: string;
        defaultContactPerson: { email: string };
        paymentTerms: string | null;
        name?: string | null;
      }
    | undefined
  >(suppliers.find((supplier) => supplier.id === order.supplierId));

  const [supplierDisplayValue, setSupplierDisplayValue] = useState(
    chosenSupplier
      ? chosenSupplier.name
        ? chosenSupplier.name
        : chosenSupplier.defaultContactPerson.email || ""
      : "",
  );

  const [chosenShippingAddress, setChosenShippingAddress] = useState<
    OrderByCodeAddresses | undefined
  >(addresses.find((address) => address.id === order.shippingAddressId));

  const [chosenInvoiceAddress, setChosenInvoiceAddress] = useState<
    OrderByCodeAddresses | undefined
  >(addresses.find((address) => address.id === order.invoiceAddressId));

  const [shippingAddressDisplayValue, setShippingAddressDisplayValue] =
    useState(
      chosenShippingAddress
        ? getAddressDisplayValue(chosenShippingAddress)
        : "",
    );

  const [invoiceAddressDisplayValue, setInvoiceAddressDisplayValue] =
    React.useState(
      chosenInvoiceAddress ? getAddressDisplayValue(chosenInvoiceAddress) : "",
    );

  const defaultPaymentTerm = "14 days";
  const [paymentTermValue, setPaymentTermValue] =
    useState<string>(defaultPaymentTerm);
  const paymentTermOptions = [
    { value: "7 days", label: "7 days" },
    { value: defaultPaymentTerm, label: defaultPaymentTerm },
    { value: "30 days", label: "30 days" },
  ];

  if (chosenSupplier?.paymentTerms) {
    const isSuppliersPaymentTermAlreadyIncluded = paymentTermOptions.some(
      (term) => term.value === chosenSupplier.paymentTerms,
    );

    if (!isSuppliersPaymentTermAlreadyIncluded) {
      paymentTermOptions.push({
        value: chosenSupplier.paymentTerms,
        label: chosenSupplier.paymentTerms,
      });
    }
  }

  const [isCommentFormLoading, setIsCommentFormLoading] = useState(false);

  const [showCorrespondenceBar, setShowCorrespondenceBar] = useState(false);

  // setup assigning card
  const [assignCard, { loading: isAssigningCard }] = useAssignCard({
    refetchQueries: ["OrderByCode", "PaginatedOrders"],
    awaitRefetchQueries: true,
  });

  // archive
  const [archiveOrder, { loading: isArchiveOrderLoading }] = useArchiveOrder({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedOrders"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onModalClose();
    },
  });

  const userMentionData = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const supplierMentionData = suppliers.map((supplier) => ({
    id: supplier.id,
    display: supplier.name
      ? supplier.name
      : supplier.defaultContactPerson.email
      ? supplier.defaultContactPerson.email
      : "[missing name]",
  }));

  // setup updating order swimlane
  const [updateOrderType, { loading: isLoadingUpdateOrderType }] =
    useUpdateOrderType({
      variables: {
        orderId: order.id,
        type: OrderTypeEnum.RECEIVING,
        relativeOrderId: order.id,
        method: CardPositionMethodEnum.AFTER,
      },
      refetchQueries: ["PaginatedPurchaseRequests", "PaginatedOrders"],
      awaitRefetchQueries: true,
    });

  // setup deleting purchase request item
  const [removePurchaseRequestItem] = useRemovePurchaseRequestItem({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedOrders"],
    awaitRefetchQueries: true,
  });

  const {
    useInput,
    useDatepicker,
    useSelect,
    validate,
    submit: updateOrder,
    loading: isUpdateOrderLoading,
    error: updateError,
    variables,
  } = useForm({
    mutation: useUpdateOrder,
    options: {
      refetchQueries: [
        "PaginatedOrders",
        "GetPoExternalIdentifier",
        "OrderByCode",
      ],
      awaitRefetchQueries: true,
    },
  });

  const nameInput = useInput({ name: "name", validators: [notEmpty] });
  const externalIdentifierInput = useInput({
    name: "externalIdentifier",
    optional: true,
  });
  const purchaseOrderIssueDatepicker = useDatepicker({
    name: "purchaseOrderIssueDate",
    validators: [notEmpty],
  });
  const expectedConfirmationDatepicker = useDatepicker({
    name: "expectedConfirmationDate",
    optional: true,
  });
  const expectedDeliveryDateDatepicker = useDatepicker({
    name: "expectedDeliveryDate",
    validators: [notEmpty],
  });
  const currencySelect = useSelect({
    name: "purchaseOrderCurrency",
    optional: true,
    options: [{ label: "-", value: "" }].concat(
      currencies.map((currency) => ({
        label: `${currency.symbol} (${currency.name})`,
        value: currency.symbol,
      })),
    ),
  });
  const purchaseOrderDeliveryNotesInput = useInput({
    name: "purchaseOrderDeliveryNotes",
    optional: true,
  });
  const purchaseOrderSupplierNotesInput = useInput({
    name: "purchaseOrderSupplierNotes",
    optional: true,
  });
  const purchaseOrderBillingNotesInput = useInput({
    name: "purchaseOrderBillingNotes",
    optional: true,
  });

  const vatPercentInput = useInput({ name: "vatPercent", optional: true });

  // set up new shipping address form and inputs
  const {
    useInput: useNewShippingAddressInput,
    useSelect: useNewShippingAddressSelect,
    submit: submitNewShippingAddress,
    loading: isSubmitNewShippingAddressLoading,
    error: submitNewShippingAddressError,
    reset: resetNewShippingAddressFields,
  } = useForm({
    mutation: useCreateOrganizationAddress,
    options: {
      refetchQueries: ["GetPoExternalIdentifier", "OrderByCode"],
      awaitRefetchQueries: true,
    },
    onSuccess: (result) => {
      const createdAddress = result.createOrganizationAddress;
      setChosenShippingAddress(createdAddress);
      setShippingAddressDisplayValue(getAddressDisplayValue(createdAddress));
      setIsNewShippingAddressFormActive(false);
      resetNewShippingAddressFields();
    },
  });

  const shippingLocationNameInput = useNewShippingAddressInput({
    name: "locationName",
    optional: true,
  });
  const shippingRecipientNameInput = useNewShippingAddressInput({
    name: "recipientName",
    optional: true,
  });
  const shippingAddressInput = useNewShippingAddressInput({
    name: "address",
    validators: [notEmpty],
  });
  const shippingCityInput = useNewShippingAddressInput({
    name: "city",
    validators: [notEmpty],
  });
  const shippingAreaCodeInput = useNewShippingAddressInput({
    name: "areaCode",
    validators: [notEmpty],
  });
  const shippingCountrySelect = useNewShippingAddressSelect({
    name: "countryCode",
    options: [{ label: "-", value: "" }].concat(
      countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    ),
    validators: [notEmpty],
  });

  // set up new invoice address form and inputs
  const {
    useInput: useNewInvoiceAddressInput,
    useSelect: useNewInvoiceAddressSelect,
    submit: submitNewInvoiceAddress,
    loading: isSubmitNewInvoiceAddressLoading,
    error: submitNewInvoiceAddressError,
    reset: resetNewInvoiceAddressFields,
  } = useForm({
    mutation: useCreateOrganizationAddress,
    options: {
      refetchQueries: ["GetPoExternalIdentifier", "OrderByCode"],
      awaitRefetchQueries: true,
    },
    onSuccess: (result) => {
      const createdAddress = result.createOrganizationAddress;
      setChosenInvoiceAddress(createdAddress);
      setInvoiceAddressDisplayValue(getAddressDisplayValue(createdAddress));
      setIsNewInvoiceAddressFormActive(false);
      resetNewInvoiceAddressFields();
    },
  });

  const invoiceLocationNameInput = useNewInvoiceAddressInput({
    name: "locationName",
    optional: true,
  });
  const invoiceRecipientNameInput = useNewInvoiceAddressInput({
    name: "recipientName",
    optional: true,
  });
  const invoiceAddressInput = useNewInvoiceAddressInput({
    name: "address",
    validators: [notEmpty],
  });
  const invoiceCityInput = useNewInvoiceAddressInput({
    name: "city",
    validators: [notEmpty],
  });
  const invoiceAreaCodeInput = useNewInvoiceAddressInput({
    name: "areaCode",
    validators: [notEmpty],
  });
  const invoiceCountrySelect = useNewInvoiceAddressSelect({
    name: "countryCode",
    options: [{ label: "-", value: "" }].concat(
      countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    ),
    validators: [notEmpty],
  });

  const releaseOptions = [
    {
      value: PurchaseOrderReleaseMethodEnum.SEND_EMAIL_INTERNALLY,
      label: "Send from here",
    },
    {
      value: PurchaseOrderReleaseMethodEnum.SEND_EMAIL_EXTERNALLY,
      label: "Download and send via email",
    },
    {
      value: PurchaseOrderReleaseMethodEnum.RELEASE_THROUGH_ERP,
      label: "Released through ERP",
    },
  ];

  // releasing options form
  const [visibleReleaseOptions, setVisibleReleaseOptions] =
    React.useState(releaseOptions);
  const [selectedReleaseOption, setSelectedReleaseOption] =
    React.useState<PurchaseOrderReleaseMethodEnum | null>(
      order.poReleaseMethod,
    );

  // update order status mutation
  const [updateOrderHasBeenSent, { loading: isLoadingUpdateOrderHasBeenSent }] =
    useUpdateOrderHasBeenSent({
      refetchQueries: [
        "PaginatedOrders",
        "PaginatedReceiving",
        "PaginatedInvoices",
        "OrderByCode",
      ],
      awaitRefetchQueries: true,
    });

  // update order release method mutation
  const [
    updateOrderReleaseMethod,
    { loading: isLoadingUpdateOrderReleaseMethod },
  ] = useUpdateOrderReleaseMethod({
    refetchQueries: ["PaginatedOrders", "OrderByCode"],
    awaitRefetchQueries: true,
  });

  const [emailHasBeenSentInternally, setEmailHasBeenSentInternally] =
    useState(false);

  // update order status mutation
  const [updateOrderStatus, { loading: isLoadingUpdateOrderStatus }] =
    useUpdateOrderStatus({
      refetchQueries: ["PaginatedOrders", "OrderByCode"],
      awaitRefetchQueries: true,
    });

  // react to order changes
  useEffect(() => {
    if (!order) {
      return;
    }

    if (order.status) {
      setIsEditable(order.status !== OrderStatusEnum.PO_READY);
      setIsSendingFormVisible(order.status === OrderStatusEnum.PO_READY);
    }

    nameInput.setValue(order.name);

    if (order.externalIdentifier) {
      externalIdentifierInput.setValue(order.externalIdentifier);
    }

    // default to organization base currency
    currencySelect.setValue(organization.baseCurrency);

    if (order.purchaseOrderCurrency) {
      currencySelect.setValue(order.purchaseOrderCurrency);
    }

    if (order.paymentTerm) {
      setPaymentTermValue(order.paymentTerm);
    }

    if (order.purchaseOrderSupplierNotes) {
      purchaseOrderSupplierNotesInput.setValue(
        order.purchaseOrderSupplierNotes,
      );
      setIsSupplierInfoFieldVisible(true);
    }

    if (order.purchaseOrderDeliveryNotes) {
      purchaseOrderDeliveryNotesInput.setValue(
        order.purchaseOrderDeliveryNotes,
      );
      setIsDeliveryInfoFieldVisible(true);
    }

    if (order.purchaseOrderBillingNotes) {
      purchaseOrderBillingNotesInput.setValue(order.purchaseOrderBillingNotes);
      setIsBillingInfoFieldVisible(true);
    }

    if (order.vatPercent) {
      vatPercentInput.setValue(order.vatPercent);
    }

    expectedConfirmationDatepicker.setSelected(
      order.expectedConfirmationDate &&
        new Date(order.expectedConfirmationDate),
    );

    purchaseOrderIssueDatepicker.setSelected(
      order.purchaseOrderIssueDate && new Date(order.purchaseOrderIssueDate),
    );
    expectedDeliveryDateDatepicker.setSelected(
      order.expectedDeliveryDate && new Date(order.expectedDeliveryDate),
    );

    // Set default autosuggest value for delivery address if there is only one address
    if (addresses.length === 1 && !chosenShippingAddress) {
      setChosenShippingAddress(addresses[0]);
      setShippingAddressDisplayValue(getAddressDisplayValue(addresses[0]));
    }

    setEmailHasBeenSentInternally(false);
    // Set `emailHasBeenSentInternally`
    if (
      order.hasBeenSent &&
      order.poReleaseMethod ===
        PurchaseOrderReleaseMethodEnum.SEND_EMAIL_INTERNALLY
    ) {
      setEmailHasBeenSentInternally(true);
    }

    // Set visible release options if order is already sent
    if (order.hasBeenSent && order.poReleaseMethod) {
      setVisibleReleaseOptions(
        releaseOptions.filter(
          (method) => method.value === order.poReleaseMethod,
        ),
      );
    }

    // show correspondence bar if there were any e-mails sent or received in preceding rfx stadium
    if (order.rfx && order.rfx.emails && order.rfx.emails.length > 0) {
      setShowCorrespondenceBar(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  // show email form when internal send option is selected
  useEffect(() => {
    setShowEmailForm(false);

    if (
      selectedReleaseOption ===
      PurchaseOrderReleaseMethodEnum.SEND_EMAIL_INTERNALLY
    ) {
      setShowEmailForm(true);
    }
  }, [selectedReleaseOption]);

  const handleOnChooseSupplier = (supplier: AutosuggestItem | undefined) => {
    if (!supplier) {
      setPaymentTermValue(defaultPaymentTerm);

      return setChosenSupplier(undefined);
    }

    const chosenSupplier = suppliers.find((supp) => supp.id === supplier.id);

    if (chosenSupplier?.paymentTerms) {
      setPaymentTermValue(chosenSupplier.paymentTerms);
    }

    setChosenSupplier(chosenSupplier);
  };

  // run input validations
  const runValidations = () => {
    // validate required form fields
    if (!validate()) {
      const message = "Please fill the required form fields";
      return { success: false, message };
    }

    // validate supplier
    if (!chosenSupplier || !chosenSupplier.id) {
      const message = "Please add or choose a supplier";
      return { success: false, message };
    }

    // validate shipping address
    if (!chosenShippingAddress || !chosenShippingAddress.id) {
      const message = "Please add or choose delivery info";
      return { success: false, message };
    }

    return { success: true, message: "" };
  };

  // convert vat percentage
  // form can return string or number so handle both
  const convertVatPercentage = (vatPercentage: string | number) => {
    if (typeof vatPercentage === "number") {
      return vatPercentage;
    }

    if (typeof vatPercentage === "string") {
      return parseFloat(vatPercentage);
    }

    return undefined;
  };

  // submits updating order
  const submit = async () => {
    // clear previous error
    setHasError(undefined);

    const validationResult = runValidations();

    if (!validationResult.success) {
      setHasError(validationResult.message);
      scrollToRef(errorRef);
      return false;
    }

    await updateOrder({
      orderId: order.id,
      supplierId: chosenSupplier ? chosenSupplier.id : null,
      shippingAddressId: chosenShippingAddress?.id ?? null,
      invoiceAddressId: chosenInvoiceAddress?.id ?? null,
      paymentTerm: paymentTermValue ?? null,
      isConfirmationRequired: Boolean(expectedConfirmationDatepicker.selected),
      receivingIsConfirmationReceived: order.receivingIsConfirmationReceived,
      receivingGoodsReceived: order.receivingGoodsReceived,
      receivingGoodsReceivedPartially: order.receivingGoodsReceivedPartially,
      receivingIsAddedToStock: order.receivingIsAddedToStock,
      receivingWarehouseCode: order.receivingWarehouseCode,
      receivingNotes: order.receivingNotes,
      invoiceNumber: order.invoiceNumber,
      invoiceReceived: order.invoiceReceived,
      invoiceThreeWayMatch: order.invoiceThreeWayMatch,
      invoiceApproved: order.invoiceApproved,
      invoicePaid: order.invoicePaid,
      hasBeenSent: order.hasBeenSent,
      vatPercent: convertVatPercentage(vatPercentInput.value),
    }).catch((_error) => {
      setHasError("Server refused to save updates");
      return false;
    });

    setIsOrderNumberEditable(false);
    return true;
  };

  const handleSetPOReady = async () => {
    // save form
    const isSubmitSuccessful = await submit();

    if (!isSubmitSuccessful) {
      return;
    }

    // if success then also update status
    await updateOrderStatus({
      variables: {
        orderId: order.id,
        status: OrderStatusEnum.PO_READY,
      },
    });
  };

  // attempts to delete requested purchase request item
  const deleteItem = async (purchaseRequestItemId: string) => {
    setDeletingPurchaseRequestItemId(purchaseRequestItemId);

    try {
      await removePurchaseRequestItem({ variables: { purchaseRequestItemId } });
    } finally {
      setDeletingPurchaseRequestItemId(undefined);
    }
  };

  // fetches suppliers by entered value (filters from prefetched data)
  const fetchSuppliers = async (value: string) =>
    value.length === 0
      ? suppliers
      : suppliers.filter(
          (supplier) =>
            matches(value, supplier.name) ||
            matches(value, supplier.defaultContactPerson.email),
        );

  // filters organization addresses based on user input
  const fetchAddresses = async (value: string) => {
    const addressAutosuggestItems = addresses.map((addr) => {
      return {
        id: addr.id,
        address: getAddressDisplayValue(addr),
      };
    }) as AutosuggestItem[];

    const result =
      value.length === 0
        ? addressAutosuggestItems
        : addressAutosuggestItems.filter((item) => {
            return item.name ? new RegExp(value, "gi").test(item.name) : false;
          });

    return result;
  };

  // handle loading (also avoid form content flicker)
  if (!order) {
    return <LoadingView overlay />;
  }

  // filter out deleted items for not archived order ( safety )
  const hasDeletedItems = order.items.some((item) => item.isDeleted);

  if (order.deletedDate === null && hasDeletedItems) {
    order.items = order.items.filter((item) => !item.isDeleted);
  }

  return (
    <Modal
      wide
      title={
        order.department?.code
          ? `PO-${order.department.code}-${order.code} ${
              variables.name ? variables.name : order.name
            }`
          : `PO-${order.code} ${variables.name ? variables.name : order.name}`
      }
      className={styles["modal"]}
      onCloseRequested={onModalClose}
      addon={
        <div className={styles["addon-wrap"]}>
          <LabelDropdown
            className={styles["label-dropdown"]}
            label={order.cardStatus.text}
            level={order.cardStatus.level}
            dropdownItems={[
              ...(order.deletedDate === null
                ? [
                    {
                      id: "1",
                      icon: <EditIcon />,
                      text: "Edit",
                      onClick: async () => {
                        await updateOrderStatus({
                          variables: {
                            orderId: order.id,
                            status: OrderStatusEnum.PO_NOT_RECEIVED,
                          },
                        });
                        setSubmitEmailSuccess(false);
                        setSelectedReleaseOption(null);
                        setVisibleReleaseOptions(releaseOptions);
                      },
                    },
                  ]
                : []),
              {
                id: "2",
                icon: <ExcelIcon />,
                text: "Download",
                onClick: async () => {
                  return checkDownloadUrlValidity(
                    `/api/export/order/${order.id}`,
                  );
                },
              },
              ...(order.deletedDate === null
                ? [
                    {
                      id: "3",
                      icon: <ArchiveIcon className={styles["archive-icon"]} />,
                      text: "Archive",
                      onClick: async () => {
                        await archiveOrder({
                          variables: {
                            orderId: order.id,
                          },
                        });
                      },
                    },
                  ]
                : []),
            ]}
          />
          <UserSelectDropdown
            large
            title={
              order.assignee === null
                ? `Click to assign ORDER`
                : `[${order.assignee.firstName} ${order.assignee.lastName}] Click to change ORDER assignee`
            }
            activeUser={order.assignee}
            users={usersWhoCanBeAssignedToCards}
            loading={isAssigningCard}
            onChoose={(user) => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: user.id,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
            onUnassign={() =>
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: null,
                  type: CardTypeEnum.ORDER,
                },
              })
            }
          />
        </div>
      }
      sidebarTitle="Collaboration feed"
      sidebar={
        <>
          {/* <SidebarFilter title="Show only Updates" onClick={() => alertNotImplemented()} /> */}
          <CommentForm
            orgUsers={userMentionData ? userMentionData : []}
            orgSuppliers={supplierMentionData ? supplierMentionData : []}
            organizationId={organization.id}
            parentId={null}
            relativeId={order.id}
            relativeType={ActivityRelativeTypeEnum.ORDER}
            setIsCommentFormLoading={setIsCommentFormLoading}
          />
          <Separator />
          <LatestActivityProvider
            organizationId={organization.id}
            relativeId={order.id}
          >
            <CollaborationSidebar
              activities={order.activities}
              showActivityLoader={isCommentFormLoading}
            />
          </LatestActivityProvider>
        </>
      }
      footer={
        <>
          <Button
            data-testid="15b65f2570"
            secondary
            onClick={async () => {
              if (!chosenSupplier) {
                setHasError("Please choose a supplier");
                scrollToRef(errorRef);
                return;
              }
              // A hack to simulate loading and then switch back
              // to non-loading state
              setIsPreviewPdfLoading(true);

              setTimeout(() => {
                setIsPreviewPdfLoading(false);
              }, 4500);

              return checkDownloadUrlValidity(`/api/order/${order.id}/pdf`);
            }}
            loading={isPreviewPdfLoading}
            disabled={isPreviewPdfLoading}
            className={styles["align-left"]}
          >
            Preview PDF
          </Button>
          {order.status !== OrderStatusEnum.PO_READY && (
            <>
              <Button
                data-testid="726f03196f"
                onClick={submit}
                secondary
                loading={isUpdateOrderLoading}
                disabled={isUpdateOrderLoading}
              >
                Save
              </Button>
              <Button
                data-testid="456ag31684"
                onClick={handleSetPOReady}
                loading={isLoadingUpdateOrderStatus}
                disabled={
                  isLoadingUpdateOrderStatus ||
                  !purchaseOrderIssueDatepicker.selected ||
                  !expectedDeliveryDateDatepicker.selected ||
                  !supplierDisplayValue ||
                  !shippingAddressDisplayValue
                }
              >
                PO is ready
              </Button>
            </>
          )}
          {order.status === OrderStatusEnum.PO_READY &&
            !order.hasBeenSent &&
            selectedReleaseOption &&
            [
              PurchaseOrderReleaseMethodEnum.SEND_EMAIL_EXTERNALLY,
              PurchaseOrderReleaseMethodEnum.RELEASE_THROUGH_ERP,
            ].includes(selectedReleaseOption) && (
              <Button
                data-testid="9ufa90ld2ww"
                onClick={async () => {
                  await updateOrderHasBeenSent({
                    variables: { orderId: order.id, hasBeenSent: true },
                  });

                  await updateOrderReleaseMethod({
                    variables: {
                      orderId: order.id,
                      poReleaseMethod:
                        PurchaseOrderReleaseMethodEnum[selectedReleaseOption],
                    },
                  });
                }}
                loading={
                  isLoadingUpdateOrderHasBeenSent ||
                  isLoadingUpdateOrderReleaseMethod ||
                  isLoadingUpdateOrderType
                }
                disabled={
                  isLoadingUpdateOrderHasBeenSent ||
                  isLoadingUpdateOrderReleaseMethod ||
                  isLoadingUpdateOrderType
                }
              >
                Ready to release PO
              </Button>
            )}
          {order.status === OrderStatusEnum.PO_READY && order.hasBeenSent && (
            <Button
              data-testid="0faf87a93ej"
              onClick={() => {
                updateOrderHasBeenSent({
                  variables: { orderId: order.id, hasBeenSent: true },
                });
                if (selectedReleaseOption) {
                  updateOrderReleaseMethod({
                    variables: {
                      orderId: order.id,
                      poReleaseMethod:
                        PurchaseOrderReleaseMethodEnum[selectedReleaseOption],
                    },
                  });
                }
                updateOrderType();
              }}
              loading={
                isLoadingUpdateOrderHasBeenSent ||
                isLoadingUpdateOrderReleaseMethod ||
                isLoadingUpdateOrderType
              }
              disabled={
                isLoadingUpdateOrderHasBeenSent ||
                isLoadingUpdateOrderReleaseMethod ||
                isLoadingUpdateOrderType
              }
            >
              Continue at Receiving
            </Button>
          )}
        </>
      }
    >
      {
        <div className={styles["wrap"]}>
          <div className={styles["purchase-order-container"]}>
            <div className={styles["left-container"]}>
              <Form
                onSubmit={submit}
                error={updateError}
                className={styles["purchase-order-form"]}
                isEditable={isEditable}
              >
                <div ref={errorRef}>
                  <ErrorMessage error={hasError} />
                </div>
                <div className={styles["order-number-container"]}>
                  <div className={styles["order-number-title"]}>
                    PURCHASE ORDER number
                  </div>
                  {!isOrderNumberEditable && (
                    <>
                      {!isUpdateOrderLoading && (
                        <div className={styles["order-number-title"]}>
                          {order.externalIdentifier ?? order.code}
                        </div>
                      )}
                      {isEditable && (
                        <Button
                          text
                          onClick={() => {
                            setIsOrderNumberEditable(true);
                          }}
                        >
                          EDIT
                        </Button>
                      )}
                    </>
                  )}
                  {isOrderNumberEditable && (
                    <>
                      <Field
                        {...externalIdentifierInput}
                        short
                        placeholder="ERP number etc."
                        className={styles["order-number-input"]}
                      />
                      {isEditable && (
                        <Button
                          text
                          onClick={() => {
                            externalIdentifierInput.reset();
                          }}
                        >
                          CLEAR
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <Accordion
                  icon={<PurchaseOrderIcon />}
                  isInitiallyOpen={!isSendingFormVisible}
                  isSwitchable={order.status === OrderStatusEnum.PO_READY}
                  title={<h3>PO info</h3>}
                >
                  <div className={styles["order-dates-container"]}>
                    <Datepicker
                      {...purchaseOrderIssueDatepicker}
                      label="PO issue date"
                      placeholder="PO issue date"
                      disabled={!isEditable}
                    />

                    <Datepicker
                      {...expectedDeliveryDateDatepicker}
                      label="Expected delivery date"
                      placeholder="Expected delivery date"
                      disabled={!isEditable}
                    />

                    <Datepicker
                      {...expectedConfirmationDatepicker}
                      label="Order confirmation date"
                      placeholder="Order confirmation date"
                      onClear={() =>
                        expectedConfirmationDatepicker.setSelected(null)
                      }
                      disabled={!isEditable}
                    />
                  </div>

                  <Separator />

                  <div className={styles["order-supplier-container"]}>
                    {isEditable && (
                      <h4 className={styles["block-required"]}>Supplier</h4>
                    )}

                    <ItemAutosuggest
                      short
                      required
                      label={!isEditable ? "Supplier" : undefined}
                      placeholder="Enter supplier name or email"
                      fetchSuggestions={fetchSuppliers}
                      value={supplierDisplayValue}
                      chosenSuggestion={chosenSupplier}
                      onChange={(newValue) => setSupplierDisplayValue(newValue)}
                      onChoose={(supplier) => handleOnChooseSupplier(supplier)}
                    />

                    {isEditable && (
                      <Button
                        text
                        className={classNames({
                          [styles["add-button"]]: !isSupplierInfoFieldVisible,
                          [styles["close-button"]]: isSupplierInfoFieldVisible,
                        })}
                        onClick={() => {
                          setIsSupplierInfoFieldVisible(
                            !isSupplierInfoFieldVisible,
                          );

                          if (isSupplierInfoFieldVisible) {
                            purchaseOrderSupplierNotesInput.reset();
                          }
                        }}
                      >
                        {isSupplierInfoFieldVisible
                          ? "Remove supplier required info from PO"
                          : "Add Supplier required info to PO"}
                      </Button>
                    )}

                    {isSupplierInfoFieldVisible && (
                      <Field
                        {...purchaseOrderSupplierNotesInput}
                        short
                        textarea
                        label="Supplier required info to PO"
                        placeholder="Notes will be included in PDF"
                        className={styles["order-supplier-notes-input"]}
                      />
                    )}
                  </div>

                  <div className={styles["delivery-info-container"]}>
                    {isEditable && (
                      <h4 className={styles["block-required"]}>
                        Delivery info
                      </h4>
                    )}

                    <ItemAutosuggest
                      short
                      required
                      label={!isEditable ? "Delivery info" : undefined}
                      displayAvatar={false}
                      getCreateNewText={() => "Create a new address"}
                      placeholder="Start typing to enter a new delivery address"
                      fetchSuggestions={fetchAddresses}
                      value={shippingAddressDisplayValue}
                      chosenSuggestion={chosenShippingAddress}
                      onChange={(newValue) =>
                        setShippingAddressDisplayValue(newValue)
                      }
                      onChoose={(address) => {
                        const chosenAddress = addresses.find(
                          (addr) => addr.id === address?.id,
                        );
                        setChosenShippingAddress(chosenAddress);
                      }}
                      onCreateNew={(value) => {
                        resetNewShippingAddressFields();
                        shippingLocationNameInput.setValue(value);

                        setIsNewShippingAddressFormActive(true);
                        setChosenShippingAddress(undefined);
                        setShippingAddressDisplayValue("");
                      }}
                    />
                    {isNewShippingAddressFormActive && (
                      <div className={styles["delivery-info-new-address"]}>
                        <ErrorMessage
                          error={
                            submitNewShippingAddressError &&
                            "Error when creating new address. Please try again later."
                          }
                        />
                        <Field
                          {...shippingLocationNameInput}
                          autofocus
                          label="Location name"
                          placeholder="[i.e HQ, Building B] - NB This is hidden from PDF"
                        />
                        <Field
                          {...shippingRecipientNameInput}
                          label="Company name, recipient contact person"
                          placeholder="Company name"
                        />
                        <Field
                          {...shippingAddressInput}
                          label="Address"
                          placeholder="Address"
                        />
                        <Field
                          {...shippingCityInput}
                          label="City"
                          placeholder="City"
                        />
                        <Field
                          {...shippingAreaCodeInput}
                          label="Zip / Post code"
                          placeholder="Zip / Post code"
                        />
                        <Select
                          {...shippingCountrySelect}
                          label="Country"
                          className={styles["address-country-select"]}
                        />
                        <div
                          className={
                            styles["delivery-info-new-address-buttons"]
                          }
                        >
                          <Button
                            data-testid="82cc2ca25d"
                            secondary
                            onClick={() => {
                              setIsNewShippingAddressFormActive(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            data-testid="0a40eb8653"
                            onClick={() =>
                              submitNewShippingAddress({
                                organizationId: organization.id,
                                addressType: AddressTypeEnum.SHIPPING_ADDRESS,
                              })
                            }
                            loading={isSubmitNewShippingAddressLoading}
                            disabled={isSubmitNewShippingAddressLoading}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}

                    {isEditable && (
                      <Button
                        text
                        className={classNames({
                          [styles["add-button"]]: !isDeliveryInfoFieldVisible,
                          [styles["close-button"]]: isDeliveryInfoFieldVisible,
                        })}
                        onClick={() => {
                          setIsDeliveryInfoFieldVisible(
                            !isDeliveryInfoFieldVisible,
                          );

                          if (isDeliveryInfoFieldVisible) {
                            purchaseOrderDeliveryNotesInput.reset();
                          }
                        }}
                      >
                        {isDeliveryInfoFieldVisible
                          ? "Remove additional delivery related comments"
                          : "Add additional delivery related comments"}
                      </Button>
                    )}

                    {isDeliveryInfoFieldVisible && (
                      <Field
                        {...purchaseOrderDeliveryNotesInput}
                        short
                        textarea
                        label="Delivery related comments"
                        placeholder="Notes to supplier (will be included in PDF)"
                        className={styles["delivery-info-notes"]}
                      />
                    )}
                  </div>

                  <div className={styles["payment-info-container"]}>
                    {isEditable && <h4>Payment info</h4>}
                    <ItemAutosuggest
                      short
                      required
                      label={
                        !isEditable
                          ? "Business entity to be invoiced"
                          : undefined
                      }
                      displayAvatar={false}
                      getCreateNewText={() => "Create a new address"}
                      placeholder="Start typing to enter a new invoice address"
                      fetchSuggestions={fetchAddresses}
                      value={invoiceAddressDisplayValue}
                      chosenSuggestion={chosenInvoiceAddress}
                      onChange={(newValue) =>
                        setInvoiceAddressDisplayValue(newValue)
                      }
                      onChoose={(address) => {
                        const chosenAddress = addresses.find(
                          (addr) => addr.id === address?.id,
                        );
                        setChosenInvoiceAddress(chosenAddress);
                      }}
                      onCreateNew={(value) => {
                        resetNewInvoiceAddressFields();
                        invoiceLocationNameInput.setValue(value);

                        setIsNewInvoiceAddressFormActive(true);
                        setChosenInvoiceAddress(undefined);
                        setInvoiceAddressDisplayValue("");
                      }}
                    />
                    {isNewInvoiceAddressFormActive && (
                      <div className={styles["delivery-info-new-address"]}>
                        <ErrorMessage
                          error={
                            submitNewInvoiceAddressError &&
                            "Error when creating new address. Please try again later."
                          }
                        />
                        <Field
                          {...invoiceLocationNameInput}
                          autofocus
                          label="Location name"
                          placeholder="[i.e HQ, Building B] - NB This is hidden from PDF"
                        />
                        <Field
                          {...invoiceRecipientNameInput}
                          label="Company name, recipient contact person"
                          placeholder="Company name"
                        />
                        <Field
                          {...invoiceAddressInput}
                          label="Address"
                          placeholder="Address"
                        />
                        <Field
                          {...invoiceCityInput}
                          label="City"
                          placeholder="City"
                        />
                        <Field
                          {...invoiceAreaCodeInput}
                          label="Zip / Post code"
                          placeholder="Zip / Post code"
                        />
                        <Select
                          {...invoiceCountrySelect}
                          label="Country"
                          className={styles["address-country-select"]}
                        />
                        <div
                          className={
                            styles["delivery-info-new-address-buttons"]
                          }
                        >
                          <Button
                            data-testid="82cc2ca25d"
                            secondary
                            onClick={() => {
                              setIsNewInvoiceAddressFormActive(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            data-testid="0a40eb8653"
                            onClick={() =>
                              submitNewInvoiceAddress({
                                organizationId: organization.id,
                                addressType: AddressTypeEnum.INVOICE_ADDRESS,
                              })
                            }
                            loading={isSubmitNewInvoiceAddressLoading}
                            disabled={isSubmitNewInvoiceAddressLoading}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                    {isEditable && (
                      <Button
                        text
                        className={classNames(styles["payment-info-button"], {
                          [styles["add-button"]]: !isBillingInfoFieldVisible,
                          [styles["close-button"]]: isBillingInfoFieldVisible,
                        })}
                        onClick={() => {
                          setIsBillingInfoFieldVisible(
                            !isBillingInfoFieldVisible,
                          );

                          if (isBillingInfoFieldVisible) {
                            purchaseOrderBillingNotesInput.reset();
                          }
                        }}
                      >
                        {isBillingInfoFieldVisible
                          ? "Remove additional billing instructions"
                          : "Add additional billing instructions"}
                      </Button>
                    )}
                    {isBillingInfoFieldVisible && (
                      <Field
                        {...purchaseOrderBillingNotesInput}
                        short
                        textarea
                        label="Additional billing instructions"
                        placeholder="Notes to supplier (will be included in PDF)"
                        className={styles["payment-info-notes"]}
                      />
                    )}
                    <div className={styles["payment-info-currency-select"]}>
                      <Select {...currencySelect} label="Currency" />
                    </div>
                    {isEditable && (
                      <div className={styles["payment-terms-label"]}>
                        Payment terms
                      </div>
                    )}
                    <div className={styles["payment-terms-container"]}>
                      {isPaymentTermEditable ? (
                        <>
                          <Field
                            short
                            placeholder="i.e 50% in advance and 50% after goods are received + 14 days"
                            className={styles["payment-terms-input"]}
                            {...{
                              value: paymentTermValue,
                              onChange: (e) => {
                                setPaymentTermValue(e.target.value);
                              },
                            }}
                          />
                          {isEditable && (
                            <Button
                              text
                              onClick={() => {
                                setIsPaymentTermEditable(false);
                                setPaymentTermValue(
                                  order.paymentTerm || defaultPaymentTerm,
                                );
                              }}
                            >
                              CLEAR
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          <Select
                            label={!isEditable ? "Payment terms" : undefined}
                            className={styles["payment-terms-input"]}
                            {...{
                              options: paymentTermOptions,
                              value: paymentTermValue,
                              onChange: (e) => {
                                setPaymentTermValue(e.target.value);
                              },
                            }}
                          />
                          {isEditable && (
                            <Button
                              text
                              onClick={() => {
                                setIsPaymentTermEditable(true);
                              }}
                            >
                              EDIT
                            </Button>
                          )}
                        </>
                      )}
                    </div>{" "}
                  </div>

                  <Separator />

                  <PurchaseRequestItems
                    isEditable={isEditable}
                    items={order.items}
                    loadingItemId={deletingPurchaseRequestItemId}
                    vatInputInfo={vatPercentInput}
                    onEdit={(itemId, itemType) => {
                      itemType === "PRODUCT"
                        ? history.push(
                            `/${organization.urlName}/edit-product/PO-${order.code}/item-${itemId}`,
                          )
                        : history.push(
                            `/${organization.urlName}/edit-service/PO-${order.code}/item-${itemId}`,
                          );
                    }}
                    onDelete={(purchaseRequestItemId) =>
                      deleteItem(purchaseRequestItemId)
                    }
                    onRequestLinkClick={(requestCode) =>
                      history.push(`/${organization.urlName}/${requestCode}`)
                    }
                  />
                </Accordion>
              </Form>

              {/* Send PO to supplier section */}
              {isSendingFormVisible && (
                <Accordion
                  icon={<SendDocumentIcon />}
                  isInitiallyOpen={true}
                  isSwitchable={true}
                  title={<h3>Send PO to Supplier</h3>}
                >
                  <div className={styles["send-to-supplier-wrap"]}>
                    {/* Releasing options form */}
                    <Form className={styles["releasing-options-form"]}>
                      <div className={styles["releasing-options-form-header"]}>
                        <SendDocumentIcon
                          className={
                            styles["releasing-options-form-header-icon"]
                          }
                        />
                        <h2
                          className={
                            styles["releasing-options-form-header-title"]
                          }
                        >
                          SEND PO to {order.supplier?.name}
                        </h2>
                      </div>

                      <div
                        className={styles["releasing-options-select-container"]}
                      >
                        <Select
                          label={undefined}
                          radio={true}
                          className={styles["releasing-options-select"]}
                          {...{
                            options: visibleReleaseOptions,
                            value: selectedReleaseOption
                              ? selectedReleaseOption
                              : undefined,
                            onChange: (e) => {
                              setSelectedReleaseOption(
                                e.target
                                  .value as PurchaseOrderReleaseMethodEnum,
                              );
                              setVisibleReleaseOptions(
                                releaseOptions.filter(
                                  (option) => option.value === e.target.value,
                                ),
                              );
                            },
                          }}
                        />
                        {selectedReleaseOption &&
                          !submitEmailSuccess &&
                          !order.hasBeenSent && (
                            <div
                              className={styles["releasing-options-clear"]}
                              onClick={() => {
                                setSelectedReleaseOption(null);
                                setVisibleReleaseOptions(releaseOptions);
                              }}
                            >
                              <RemoveIcon
                                className={
                                  styles["releasing-options-remove-icon"]
                                }
                              />
                            </div>
                          )}
                      </div>

                      {selectedReleaseOption &&
                        selectedReleaseOption !==
                          PurchaseOrderReleaseMethodEnum.SEND_EMAIL_INTERNALLY && (
                          <div
                            className={styles["po-pdf-link"]}
                            onClick={() => {
                              return (window.location.href = `/api/order/${order.id}/pdf`);
                            }}
                          >
                            <span>{`${organization.companyName.replace(
                              " ",
                              "_",
                            )}_PO-${order.code}.pdf`}</span>
                            <OpenExternalIcon
                              className={styles["open-external-icon"]}
                            />
                          </div>
                        )}
                    </Form>

                    {/* E-mail sending form */}
                    {(showEmailForm || emailHasBeenSentInternally) && (
                      <>
                        {(submitEmailSuccess || emailHasBeenSentInternally) && (
                          <div className={styles["email-sent"]}>
                            <div
                              className={
                                styles["email-sent-checkmark-container"]
                              }
                            >
                              <CheckmarkIcon
                                className={styles["email-sent-checkmark-icon"]}
                              />
                            </div>
                            <div className={styles["email-sent-text"]}>
                              PO Sent!
                            </div>
                            <div
                              className={styles["email-sent-flex-space"]}
                            ></div>
                          </div>
                        )}

                        {/* email error */}
                        {hasError && !submitEmailSuccess && (
                          <div className={styles["email-sent"]}>
                            <h4>
                              Sending request emails failed, please try again
                              later
                            </h4>
                          </div>
                        )}

                        {!order.hasBeenSent &&
                          !hasError &&
                          !submitEmailSuccess &&
                          chosenSupplier && (
                            <CardContextProvider
                              card={{ ...order, type: CardTypeEnum.ORDER }}
                            >
                              <ViewerContextProvider viewer={viewer}>
                                <EmailForm
                                  attachments={[
                                    ...orderAttachments,
                                    ...itemsAttachments,
                                  ]}
                                  organization={organization}
                                  suppliers={[
                                    {
                                      ...chosenSupplier,
                                      defaultContactPerson: {
                                        email:
                                          chosenSupplier.defaultContactPerson
                                            ?.email,
                                      },
                                    },
                                  ]}
                                  onSupplierRemove={(id) => {
                                    // TODO:
                                    console.log(
                                      `Requested to remove ID: ${id}`,
                                    );
                                  }}
                                  onSendSuccess={() => {
                                    setEmailHasBeenSentInternally(true);
                                    updateOrderHasBeenSent({
                                      variables: {
                                        orderId: order.id,
                                        hasBeenSent: true,
                                      },
                                    });
                                    updateOrderReleaseMethod({
                                      variables: {
                                        orderId: order.id,
                                        poReleaseMethod:
                                          PurchaseOrderReleaseMethodEnum.SEND_EMAIL_INTERNALLY,
                                      },
                                    });
                                    setSubmitEmailSuccess(true);
                                  }}
                                  onSendError={() => {
                                    setHasError(
                                      "Oops! Could not send this email. Please try again.",
                                    );
                                  }}
                                  onCancel={() => {
                                    setSelectedReleaseOption(null);
                                    setVisibleReleaseOptions(releaseOptions);
                                  }}
                                />
                              </ViewerContextProvider>
                            </CardContextProvider>
                          )}
                      </>
                    )}
                  </div>
                </Accordion>
              )}
            </div>
            {showCorrespondenceBar && (
              <div className={styles["right-container"]}>
                <ViewerContextProvider viewer={viewer}>
                  <CorrespondenceBar order={order} />
                </ViewerContextProvider>
              </div>
            )}
          </div>
          {isArchiveOrderLoading && <LoadingView overlay />}
        </div>
      }
    </Modal>
  );
};

function getAddressDisplayValue(address: OrderByCodeAddresses) {
  return `[${address.locationName}] ${address.recipientName} - ${address.address}, ${address.city}, ${address.areaCode} ${address.countryCode}`;
}
