import React, { forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { useFormContext, useFieldArray } from "react-hook-form";
import { PlusIcon } from "../../theme/svg/PlusIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { Button } from "../Button/Button";
import { DuplicateIcon } from "../../theme/svg/DuplicateIcon";
import styles from "./QuestionList.module.scss";

export interface QuestionListProps {
  className?: string;
  itemIndex: number;
  onDuplicate?: (itemIndex: number, questionIndex: number) => void;
}

export const QuestionList = forwardRef((props: QuestionListProps, ref) => {
  const { className, itemIndex, onDuplicate } = props;
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `items[${itemIndex}].itemQuestions`,
  });

  useImperativeHandle(ref, () => ({
    itemIndex,
    append,
  }));

  const handleAppend = () => {
    append({ question: "" });
  };

  const handleRemove = (questionIndex: number) => {
    return function () {
      remove(questionIndex);
    };
  };

  const handleDuplicate = (questionIndex: number) => {
    if (typeof onDuplicate === "function") {
      return onDuplicate(itemIndex, questionIndex);
    }
  };

  return (
    <div className={classNames(styles["wrap"], className)}>
      <h3 className={styles["heading"]}>Questions</h3>
      {fields.map((field, questionIndex) => {
        return (
          <div key={`${field.id}`} className={styles["item"]}>
            <div className={styles["item-icon"]}>{`Q${questionIndex + 1}`}</div>
            <input
              defaultValue={field?.question ?? ""}
              ref={register()}
              name={`items[${itemIndex}].itemQuestions[${questionIndex}].question`}
              type="text"
              className={styles["question-input"]}
              autoComplete="off"
            />
            <Button
              icon={<DuplicateIcon />}
              className={styles["duplicate-button"]}
              onClick={() => handleDuplicate(questionIndex)}
            />
            <Button
              icon={<RemoveIcon />}
              className={styles["remove-button"]}
              onClick={handleRemove(questionIndex)}
            />
          </div>
        );
      })}

      <Button
        className={styles["add-button"]}
        icon={<PlusIcon className={styles["add-button-icon"]} />}
        onClick={handleAppend}
      >
        Add question
      </Button>
    </div>
  );
});
