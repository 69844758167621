import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import {
  PurchaseRequestForm,
  CreatePurchaseRequestFormData,
} from "../../components/PurchaseRequestForm/PurchaseRequestForm";
import { useRouter } from "../../hooks/useRouter";
import { useForm as useApolloForm } from "../../lib/react-apollo-hooks-form";
import {
  ViewerOrganizations,
  ViewerViewer,
  useCreatePurchaseRequestView,
  PurchaseRequestItemTypeEnum,
  PurchaseRequestStatusEnum,
  useCreatePurchaseRequestFromPortal,
  CreatePurchaseRequestFromPortalMutationVariables,
  useUpdatePurchaseRequestFromPortal,
  UpdatePurchaseRequestFromPortalMutationVariables,
  useAssignCard,
  CardTypeEnum,
} from "../../schema";
import { convertPRFormDataToMutation } from "../../services/convertPRFormDataToMutation";
import { isDefined } from "../../services/isDefined";
import { ReadyIcon } from "../../theme/svg/ReadyIcon";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";
import styles from "./CreatePurchaseRequestView.module.scss";

interface CreatePurchaseRequestViewProps {
  onModalClose(): void;
  organization: ViewerOrganizations;
  viewer: ViewerViewer;
}

export const CreatePurchaseRequestView: React.FC<CreatePurchaseRequestViewProps> =
  ({ onModalClose, organization, viewer }) => {
    const { history } = useRouter();

    const [files, setFiles] = React.useState<File[]>([]);

    // Load required information
    const { data, error, loading } = useCreatePurchaseRequestView({
      fetchPolicy: "network-only",
      variables: {
        organizationId: organization.id,
      },
    });

    // Configure form
    const defaultOptions = {
      refetchQueries: [
        "CreatePurchaseRequestView",
        "PaginatedPurchaseRequests",
      ],
      awaitRefetchQueries: true,
    };

    // setup assigning card
    const [assignCard] = useAssignCard(defaultOptions);

    // Configure create purchase request form
    const {
      submit,
      loading: isCreating,
      error: createError,
    } = useApolloForm({
      mutation: useCreatePurchaseRequestFromPortal,
      options: defaultOptions,
      onSuccess: async (response, values) => {
        if (response.createPurchaseRequestFromPortal) {
          // On set request ready assignee user if has not informed
          values.status === PurchaseRequestStatusEnum.READY &&
            !response.createPurchaseRequestFromPortal.assigneeId &&
            (await assignCard({
              variables: {
                itemId: response.createPurchaseRequestFromPortal.id,
                assigneeId: viewer.id,
                type: CardTypeEnum.PURCHASE_REQUEST,
              },
            }));

          history.push(
            `/${organization.urlName}/PR-${response.createPurchaseRequestFromPortal.code}`,
          );
        }
      },
    });

    // Configure update purchase request form
    const {
      submit: updatePR,
      loading: isUpdating,
      error: errorUpdating,
    } = useApolloForm({
      mutation: useUpdatePurchaseRequestFromPortal,
      options: defaultOptions,
      onSuccess: async (response, values) => {
        if (response.updatePurchaseRequestFromPortal) {
          // On set request ready assignee user if has not informed
          values.status === PurchaseRequestStatusEnum.READY &&
            !response.updatePurchaseRequestFromPortal.assigneeId &&
            (await assignCard({
              variables: {
                itemId: response.updatePurchaseRequestFromPortal.id,
                assigneeId: viewer.id,
                type: CardTypeEnum.PURCHASE_REQUEST,
              },
            }));

          history.push(
            `/${organization.urlName}/PR-${response.updatePurchaseRequestFromPortal.code}`,
          );
        }
      },
    });

    const methods = useForm<CreatePurchaseRequestFormData>({
      defaultValues: {
        items: [
          { name: "", quantity: "", type: PurchaseRequestItemTypeEnum.PRODUCT },
        ],
      },
    });

    // Control form submission
    const savePurchaseRequest = async (
      values: CreatePurchaseRequestFormData,
      newStatus?: PurchaseRequestStatusEnum,
      purchaseRequestId?: string,
    ) => {
      // We need to know the purchase request id if has created before on excel upload
      const createdPrId = purchaseRequestId;

      const test = convertPRFormDataToMutation(values);

      console.log({ values, test });

      // Create a new purchase request
      if (!createdPrId) {
        const purchaseRequestDetails: CreatePurchaseRequestFromPortalMutationVariables =
          {
            ...convertPRFormDataToMutation(values),
            status: isDefined(newStatus)
              ? PurchaseRequestStatusEnum[newStatus]
              : PurchaseRequestStatusEnum.DRAFT,
            organizationId: organization.id,
            attachments: files,
          };

        console.log("create");

        submit(purchaseRequestDetails);
      } else {
        // Else update purchase request(If has created from excel upload)
        const purchaseRequestDetails: UpdatePurchaseRequestFromPortalMutationVariables =
          {
            ...convertPRFormDataToMutation(values),
            status: isDefined(newStatus)
              ? PurchaseRequestStatusEnum[newStatus]
              : PurchaseRequestStatusEnum.DRAFT,
            purchaseRequestId: createdPrId,
            attachments: files,
          };

        console.log("update");

        updatePR(purchaseRequestDetails);
      }
    };

    const draftPurchaseRequest = methods.handleSubmit((values: any) => {
      savePurchaseRequest(values);
    });

    const readyPurchaseRequest = methods.handleSubmit((values: any) => {
      savePurchaseRequest(values, PurchaseRequestStatusEnum.READY);
    });

    if (error !== undefined) {
      return <ErrorView error={error}></ErrorView>;
    }

    if (loading || !data?.viewer) {
      return <LoadingView overlay></LoadingView>;
    }

    if (!organization) {
      return <WithoutOrganizationView viewer={viewer} />;
    }

    // Check if can close modal
    const canCloseModal = () => {
      // If has dirty fields ask user
      if (methods.formState.isDirty) {
        const confirmed = window.confirm(
          "You have unsaved information, really wanna quit?",
        );
        if (confirmed) onModalClose();
      } else {
        onModalClose();
      }
    };

    return (
      <Modal
        wide
        title="Create a new purchase request"
        onCloseRequested={() => canCloseModal()}
        footer={
          <>
            <Button onClick={() => draftPurchaseRequest()} secondary>
              Save
            </Button>
            <Button onClick={() => readyPurchaseRequest()}>
              <ReadyIcon className={styles["ready-icon"]} /> Request ready
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          {(isCreating || isUpdating) && <LoadingView overlay></LoadingView>}
          <PurchaseRequestForm
            files={files}
            setFiles={setFiles}
            organization={data.viewer.organization}
            viewer={viewer}
            error={createError || errorUpdating}
            savePurchaseRequest={savePurchaseRequest}
            department={null}
            project={null}
            costCentre={null}
          />
        </FormProvider>
      </Modal>
    );
  };
