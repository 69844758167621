import React, { ChangeEvent } from "react";
import classNames from "classnames";
import styles from "./ButtonGroup.module.scss";

export interface ButtonGroupOption {
  key: string;
  title: string;

  isDisabled?: boolean;
  icon?: JSX.Element;
}

export interface ButtonGroupProps {
  relativeId: string;
  selectedKey?: string;
  options: ButtonGroupOption[];
  className?: string;
  onChange(info: { relativeId: string; value: string | null }): void;
  allowUnselect?: boolean;
  large?: boolean;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const {
    relativeId,
    options,
    selectedKey,
    onChange,
    className,
    large,
    allowUnselect = true,
  } = props;

  const [selectedOption, setSelectedOption] = React.useState(selectedKey);

  // update selected option if props change
  React.useEffect(() => {
    setSelectedOption(selectedKey);
  }, [selectedKey]);

  // handle selected option change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);

    onChange({ relativeId, value: e.target.value });
  };

  return (
    <div className={classNames(styles["wrap"], className)}>
      {options.map((option) => (
        <label
          key={`${relativeId}-${option.key}`}
          className={classNames(
            styles["radio-label"],
            {
              [styles["radio-label--active"]]: option.key === selectedOption,
            },
            { [styles["radio-label--disabled"]]: option.isDisabled },
            { [styles["radio-label--withIcon"]]: option.icon },
            { [styles["radio-label--large"]]: large },
          )}
        >
          <input
            id={`${relativeId}-${option.key}`}
            type="radio"
            disabled={option.isDisabled}
            value={option.key}
            checked={option.key === selectedOption}
            onChange={(e) => {
              if (!option.isDisabled) {
                handleChange(e);
              }
            }}
            onClick={(_e) => {
              if (option.key === selectedOption && allowUnselect) {
                setSelectedOption(undefined);
                onChange({ relativeId, value: null });
              }
            }}
            className={styles["radio-input"]}
          />
          {option.title}
          {option.icon}
        </label>
      ))}
    </div>
  );
};
