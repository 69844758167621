import React from "react";
import classNames from "classnames";
import { useRouter } from "../../hooks/useRouter";
import { ViewerViewer, UserRoleEnum } from "../../schema";
import { LogoIcon } from "../../theme/svg/LogoIcon";
import { LogoWithTextIcon } from "../../theme/svg/LogoWithTextIcon";
import { CompanyLogo } from "../CompanyLogo/CompanyLogo";
import styles from "./Header.module.scss";

interface HeaderProps {
  organization?: {
    urlName?: string | null;
    companyLogoUrl?: string | null;
    companyName?: string | null;
  };
  secondary?: boolean;
  viewer?: ViewerViewer;
  isPortal?: boolean;
  title?: string;
}

export const Header: React.FC<HeaderProps> = ({
  organization,
  secondary,
  viewer,
  isPortal,
  title,
  children,
}) => {
  const { history } = useRouter();

  // prevent loading pathname if you click the same link more than once
  const homePath = () => {
    if (history.location.pathname !== `/${organization?.urlName}`) {
      history.push(`/${organization?.urlName}`);
    }
  };

  return (
    <>
      {secondary ? (
        <div
          className={classNames(styles.header, {
            [styles["header-portal"]]: isPortal,
          })}
        >
          <div
            className={styles.logo}
            onClick={() => {
              if (
                organization &&
                viewer?.roles.some((role) =>
                  [
                    UserRoleEnum.ADMIN,
                    UserRoleEnum.BUYER,
                    UserRoleEnum.KEY_USER,
                  ].includes(role),
                )
              ) {
                homePath();
              }
              if (!organization) {
                history.push("/");
              }
            }}
          >
            {isPortal && organization?.companyLogoUrl ? (
              <CompanyLogo
                url={organization.companyLogoUrl}
                alt={organization.companyName ?? ""}
                title={organization.companyName ?? ""}
              />
            ) : (
              <LogoIcon className={styles.logo} />
            )}
            {title && <div className={styles.title}>{title}</div>}
          </div>
          {children !== undefined && (
            <div className={styles.section}>{children}</div>
          )}
        </div>
      ) : (
        <div className={styles.header}>
          <LogoWithTextIcon
            className={styles.logo}
            onClick={() => {
              if (
                viewer?.roles.some((role) =>
                  [
                    UserRoleEnum.ADMIN,
                    UserRoleEnum.BUYER,
                    UserRoleEnum.KEY_USER,
                  ].includes(role),
                )
              ) {
                homePath();
              }
            }}
          />
          {children !== undefined && (
            <div className={styles.section}>{children}</div>
          )}
        </div>
      )}
    </>
  );
};
