import React from "react";
import classNames from "classnames";
import { ViewerViewer } from "../../schema";
import { SupplierPortalLayout } from "../SupplierPortalLayout/SupplierPortalLayout";
import styles from "./SupplierPortalListView.module.scss";

export interface SupplierPortalListViewProps {
  className?: string;
  viewer: ViewerViewer;
}

export const SupplierPortalListView: React.FC<SupplierPortalListViewProps> = (
  props,
) => {
  const { className, viewer } = props;

  return (
    <SupplierPortalLayout className={classNames(className)} viewer={viewer}>
      <h1 className={styles["center"]}>
        All your eSourcing requests will be listed here...
      </h1>
    </SupplierPortalLayout>
  );
};
