import React from "react";
import classNames from "classnames";
import { Field } from "../Field/Field";
import { Avatar, AvatarUser } from "../Avatar/Avatar";
import { Button } from "../Button/Button";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { ThumbsUpIcon } from "../../theme/svg/ThumbsUpIcon";
import {
  ApprovalRequestDecisionEnum,
  ApprovalWorkflowStatusEnum,
  Maybe,
} from "../../schema";
import { InputInfo } from "../../lib/react-apollo-hooks-form";
import { formatCapitalizeString } from "../../services/formatCapitalizeString";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { QuestionIcon } from "../../theme/svg/QuestionIcon";
import { GraphLineIcon } from "../../theme/svg/GraphLineIcon";
import styles from "./ApprovalStatus.module.scss";

export interface ApprovalStatusProps {
  dueDate?: string | null;
  decision?: string | null;
  decisionDate?: string | null;
  status?: ApprovalRequestDecisionEnum | null;
  approver: AvatarUser;
  enableDecisionForm?: boolean;
  creatorComment?: string | null;
  approverComment?: string | null;
  participantName: string;
  commentInput?: InputInfo;
  sequenceNumber: number;
  approveAction?: () => void;
  rejectAction?: () => void;
}

export interface ApprovalStatusHeaderProps {
  creator: AvatarUser;
  awfCode: string;
  requestName: Maybe<string>;
  prCode?: string;
  status: string;
}

export const ApprovalStatus: React.FC<ApprovalStatusProps> = ({
  dueDate,
  decision,
  decisionDate,
  status,
  approver,
  enableDecisionForm,
  creatorComment,
  approverComment,
  participantName,
  commentInput,
  sequenceNumber,
  approveAction,
  rejectAction,
}) => {
  const renderStatusIcon = () => {
    if (status === ApprovalRequestDecisionEnum.APPROVED) {
      return (
        <CheckmarkIcon
          className={classNames(
            styles["status-icon"],
            styles["status-icon--success"],
          )}
        />
      );
    }
    if (status === ApprovalRequestDecisionEnum.DECLINED) {
      return (
        <RemoveIcon
          className={classNames(
            styles["status-icon"],
            styles["status-icon--error"],
          )}
        />
      );
    }
    return <QuestionIcon className={classNames(styles["status-icon"])} />;
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles["wrapper--form"]]: enableDecisionForm,
          [styles["wrapper--approved"]]:
            decision === ApprovalWorkflowStatusEnum.APPROVED,
          [styles["wrapper--cancelled"]]:
            decision === ApprovalWorkflowStatusEnum.CANCELLED,
          [styles["wrapper--draft"]]:
            decision === ApprovalWorkflowStatusEnum.DRAFT,
          [styles["wrapper--declined"]]:
            decision === ApprovalWorkflowStatusEnum.DECLINED,
          [styles["wrapper--inprogress"]]:
            decision === ApprovalWorkflowStatusEnum.IN_PROGRESS,
        })}
      >
        <div className={styles.position}>
          <div className={styles["position-badge"]}>{sequenceNumber}</div>
        </div>
        <div className={styles.content}>
          {creatorComment && (
            <div className={styles["content-note"]}>Note: {creatorComment}</div>
          )}
          <div
            className={styles["content-status"]}
            title={
              status && decisionDate
                ? `Decision: ${decisionDate}`
                : `Due date: ${dueDate}`
            }
          >
            {status ? (
              formatCapitalizeString(status)
            ) : (
              <i>
                {enableDecisionForm
                  ? "No decision"
                  : "Waiting for approval turn"}
              </i>
            )}
          </div>
          {!enableDecisionForm && approverComment && (
            <div className={styles["content-comment"]}>{approverComment}</div>
          )}
        </div>
        <div className={styles.user}>
          <div className={styles["user-avatar"]}>
            <div className={styles["user-avatar-badge"]}>
              {renderStatusIcon()}
            </div>
            <Avatar user={approver} />
          </div>
          <div>{participantName}</div>
        </div>
        {enableDecisionForm && (
          <div className={styles.comment}>
            {commentInput && (
              <Field
                textarea
                placeholder="Comment (optional)"
                {...commentInput}
              />
            )}
            <div className={styles["button-wrap"]}>
              {rejectAction && (
                <Button
                  data-testid="f05c91fa47"
                  reject
                  secondary
                  warning
                  onClick={() => rejectAction()}
                >
                  <ThumbsUpIcon className={styles["thumbs-down-icon"]} />
                  Reject
                </Button>
              )}
              {approveAction && (
                <Button
                  data-testid="f76eb0ae9a"
                  onClick={() => approveAction()}
                >
                  <ThumbsUpIcon className={styles["thumbs-up-icon"]} />
                  Approve
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const ApprovalStatusHeader: React.FC<ApprovalStatusHeaderProps> = ({
  creator,
  awfCode,
  requestName,
  prCode,
  status,
}) => {
  return (
    <>
      <div className={classNames(styles.wrapper, styles["wrapper--header"])}>
        <div className={styles.position}>
          <div className={styles["position-badge"]}>
            <GraphLineIcon className={styles["status-icon"]} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles["content-status"]}>
            {creator.firstName} {creator.lastName}
          </div>
          <div className={styles["content-comment"]}>
            Sent {prCode && <b>PR-{prCode}</b>}{" "}
            {requestName && <>&quot;{requestName}&quot;</>} to Approval Workflow
            (<b>AWF-{awfCode}</b>) {status}
          </div>
        </div>
      </div>
    </>
  );
};
