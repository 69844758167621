import React from "react";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import styles from "./RequestAttachments.module.scss";

export interface RequestAttachmentsProps {
  attachments?: { id: string; filename: string; url?: string | null }[] | null;
}

export const RequestAttachments: React.FC<RequestAttachmentsProps> = ({
  attachments,
}) => (
  <div className={styles["attachments"]}>
    {attachments &&
      attachments.length > 0 &&
      attachments.map((attachment) => {
        return (
          attachment.url && (
            <a
              key={attachment.id}
              href={attachment.url}
              className={styles["attachments-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles["filename"]}>{attachment.filename}</span>{" "}
              <OpenExternalIcon className={styles["open-external-icon"]} />
            </a>
          )
        );
      })}
  </div>
);
