import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { CollaborationSidebar } from "../../components/CollaborationSidebar/CollaborationSidebar";
import { CommentForm } from "../../components/CommentForm/CommentForm";
import { CorrespondenceBar } from "../../components/CorrespondenceBar/CorrespondenceBar";
import { Field } from "../../components/Field/Field";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Form } from "../../components/Form/Form";
import { LabelDropdown } from "../../components/LabelDropdown/LabelDropdown";
import { Modal } from "../../components/Modal/Modal";
import { PurchaseRequestItems } from "../../components/PurchaseRequestItems/PurchaseRequestItems";
import { Separator } from "../../components/Separator/Separator";
import { UserSelectDropdown } from "../../components/UserSelectDropdown/UserSelectDropdown";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  CardTypeEnum,
  OrderByCodeCountries,
  OrderByCodeOrderByCode,
  UserInfoFragment,
  ViewerOrganizations,
  useAssignCard,
  useUpdateOrder,
  useDeletePurchaseRequestItem,
  SupplierInfoFragment,
  ActivityRelativeTypeEnum,
  ViewerViewer,
  useArchiveOrder,
} from "../../schema";
import { checkDownloadUrlValidity } from "../../services/checkDownloadUrlValidity";
import { formatDate } from "../../services/formatDate";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./ReceivingModal.module.scss";

export interface ReceivingProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  order: OrderByCodeOrderByCode;
  users: UserInfoFragment[];
  usersWhoCanBeAssignedToCards: UserInfoFragment[];
  countries: OrderByCodeCountries[];
  suppliers: SupplierInfoFragment[];
  onModalClose(): void;
}

export const ReceivingModal: React.FC<ReceivingProps> = ({
  viewer,
  organization,
  order,
  users,
  usersWhoCanBeAssignedToCards,
  suppliers,
  onModalClose,
}) => {
  const { history } = useRouter();

  // keep track of which purchase request we are deleting
  const [deletingPurchaseRequestItemId, setDeletingPurchaseRequestItemId] =
    useState<string | undefined>(undefined);

  const [isCommentFormLoading, setIsCommentFormLoading] = useState(false);

  const [showCorrespondenceBar, setShowCorrespondenceBar] = useState(false);

  // setup assigning card
  const [assignCard, { loading: isAssigningCard }] = useAssignCard({
    refetchQueries: ["OrderByCode", "PaginatedReceiving"],
    awaitRefetchQueries: true,
  });

  // setup archive order
  const [archiveOrder, { loading: isArchiveOrderLoading }] = useArchiveOrder({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedReceiving"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onModalClose();
    },
  });

  // setup deleting purchase request item
  const [deletePurchaseRequestItem] = useDeletePurchaseRequestItem({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedReceiving"],
    awaitRefetchQueries: true,
  });

  const {
    useInput,
    useCheckbox,
    submit: updateOrder,
    loading: isUpdateOrderLoading,
    error: updateError,
  } = useForm({
    mutation: useUpdateOrder,
    options: {
      refetchQueries: ["PaginatedReceiving"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => {
      history.push(`/${organization.urlName}`);
    },
  });

  const receivingIsConfirmationReceivedCheckbox = useCheckbox({
    name: "receivingIsConfirmationReceived",
  });
  const receivingGoodsReceivedCheckbox = useCheckbox({
    name: "receivingGoodsReceived",
  });
  const receivingGoodsReceivedPartiallyCheckbox = useCheckbox({
    name: "receivingGoodsReceivedPartially",
  });
  const receivingIsPoCancelledCheckbox = useCheckbox({
    name: "receivingIsPoCancelled",
  });
  const receivingWarehouseCodeInput = useInput({
    name: "receivingWarehouseCode",
    optional: true,
  });
  const receivingNotesInput = useInput({
    name: "receivingNotes",
    optional: true,
  });

  const userMentionData = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const supplierMentionData = suppliers.map((supplier) => ({
    id: supplier.id,
    display: supplier.name
      ? supplier.name
      : supplier.defaultContactPerson.email
      ? supplier.defaultContactPerson.email
      : "[missing name]",
  }));

  useEffect(() => {
    if (!order) {
      return;
    }

    receivingIsConfirmationReceivedCheckbox.setValue(
      order.receivingIsConfirmationReceived,
    );

    receivingGoodsReceivedCheckbox.setValue(order.receivingGoodsReceived);

    receivingGoodsReceivedPartiallyCheckbox.setValue(
      order.receivingGoodsReceivedPartially,
    );

    receivingIsPoCancelledCheckbox.setValue(order.receivingIsPoCancelled);

    if (order.receivingWarehouseCode) {
      receivingWarehouseCodeInput.setValue(order.receivingWarehouseCode);
    }

    if (order.receivingNotes) {
      receivingNotesInput.setValue(order.receivingNotes);
    }

    // show correspondence bar if there were any e-mails sent or received in preceding rfx stadium
    if (order.rfx && order.rfx.emails && order.rfx.emails.length > 0) {
      setShowCorrespondenceBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  //submits updating order
  const submit = () => {
    updateOrder({
      orderId: order.id,
      supplierId: order.supplierId,
      name: order.name,
      isConfirmationRequired: order.isConfirmationRequired,
      expectedConfirmationDate: order.expectedConfirmationDate,
      externalIdentifier: order.externalIdentifier,
      expectedDeliveryDate: order.expectedDeliveryDate,
      paymentTerm: order.paymentTerm,
      purchaseOrderDeliveryNotes: order.purchaseOrderDeliveryNotes,
      hasBeenSent: order.hasBeenSent,
      invoiceNumber: order.invoiceNumber,
      invoiceReceived: order.invoiceReceived,
      invoiceThreeWayMatch: order.invoiceThreeWayMatch,
      invoiceApproved: order.invoiceApproved,
      invoicePaid: order.invoicePaid,
    });
  };

  // attempts to delete requested purchase request item
  const deleteItem = async (purchaseRequestItemId: string) => {
    setDeletingPurchaseRequestItemId(purchaseRequestItemId);

    try {
      await deletePurchaseRequestItem({ variables: { purchaseRequestItemId } });
    } finally {
      setDeletingPurchaseRequestItemId(undefined);
    }
  };

  // handle loading (also avoid form content flicker)
  if (!order) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      wide
      title={`PO-${order.code} ${order.name} ${
        order.externalIdentifier ? order.externalIdentifier : ""
      }`}
      className={styles["modal"]}
      onCloseRequested={onModalClose}
      addon={
        <div className={styles["addon-wrap"]}>
          <LabelDropdown
            className={styles["label-dropdown"]}
            label={order.cardStatus.text}
            level={order.cardStatus.level}
            dropdownItems={[
              ...(order.deletedDate === null
                ? [
                    {
                      id: "1",
                      icon: <ArchiveIcon className={styles["archive-icon"]} />,
                      text: "Archive",
                      onClick: async () => {
                        await archiveOrder({
                          variables: {
                            orderId: order.id,
                          },
                        });
                      },
                    },
                  ]
                : []),
              {
                id: "2",
                icon: <ExcelIcon />,
                text: "Download",
                onClick: async () => {
                  return checkDownloadUrlValidity(
                    `/api/export/order/${order.id}`,
                  );
                },
              },
            ]}
          />
          <UserSelectDropdown
            large
            title={
              order.assignee === null
                ? "Click to assign card"
                : `[${order.assignee.firstName} ${order.assignee.lastName}] Click to change card assignee`
            }
            activeUser={order.assignee}
            users={usersWhoCanBeAssignedToCards}
            loading={isAssigningCard}
            onChoose={(user) => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: user.id,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
            onUnassign={() => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: null,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
          />
        </div>
      }
      sidebarTitle="Collaboration feed"
      sidebar={
        <>
          {/* <SidebarFilter title="Show only Updates" onClick={() => alertNotImplemented()} /> */}
          <CommentForm
            orgUsers={userMentionData ? userMentionData : []}
            orgSuppliers={supplierMentionData ? supplierMentionData : []}
            organizationId={organization.id}
            parentId={null}
            relativeId={order.id}
            relativeType={ActivityRelativeTypeEnum.ORDER}
            setIsCommentFormLoading={setIsCommentFormLoading} // TODO add isCommentFormLoading aswell?
          />
          <Separator />
          <LatestActivityProvider
            organizationId={organization.id}
            relativeId={order.id}
          >
            <CollaborationSidebar
              activities={order.activities}
              showActivityLoader={isCommentFormLoading}
            />
          </LatestActivityProvider>
        </>
      }
      footer={
        <>
          <Button
            data-testid="e725accc68"
            secondary
            onClick={() => history.push(`/${organization.urlName}`)}
          >
            Cancel
          </Button>
          <Button
            data-testid="9eefb52149"
            onClick={submit}
            loading={isUpdateOrderLoading}
            disabled={isUpdateOrderLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <div className={styles["wrap"]}>
        <div className={styles["receiving-container"]}>
          <div className={styles["left-container"]}>
            <Form onSubmit={submit} error={updateError}>
              {order.isConfirmationRequired && (
                <FieldLabel label="Order confirmation">
                  Confirmation expected by:
                  <span className={styles["confirmation-date"]}>
                    {formatDate(order.expectedConfirmationDate)}
                  </span>
                </FieldLabel>
              )}
              <Field {...receivingIsConfirmationReceivedCheckbox}>
                Confirmation received
              </Field>
              <Separator />
              <h4>Goods status</h4>
              <Field {...receivingGoodsReceivedCheckbox}>Goods received</Field>
              <Field {...receivingGoodsReceivedPartiallyCheckbox}>
                Goods received partially
              </Field>
              <Field {...receivingIsPoCancelledCheckbox}>PO cancelled</Field>
              <Separator />
              <Field
                {...receivingWarehouseCodeInput}
                label="Receiving warehouse code"
                short
              ></Field>
              <Field
                {...receivingNotesInput}
                label="Receiving notes"
                textarea
              ></Field>
              <Separator />
              <PurchaseRequestItems
                items={order.items}
                loadingItemId={deletingPurchaseRequestItemId}
                onRequestLinkClick={(requestCode) =>
                  history.push(`/${organization.urlName}/${requestCode}`)
                }
              />
            </Form>
          </div>
          {showCorrespondenceBar && (
            <div className={styles["right-container"]}>
              <ViewerContextProvider viewer={viewer}>
                <CorrespondenceBar order={order} />
              </ViewerContextProvider>
            </div>
          )}
        </div>
        {isArchiveOrderLoading && <LoadingView overlay />}
      </div>
    </Modal>
  );
};
