import React from "react";
import { UploadQuotationPricesExcelRows } from "../../schema";
import { Cell, BasicTable } from "../BasicTable/BasicTable";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { Description } from "../Description/Description";
import styles from "./ExcelImportErrorsTable.module.scss";

export interface ExcelImportErrorsTableProps {
  rows: UploadQuotationPricesExcelRows[];
}

export const ExcelQuotationPricesImportErrorsTable: React.FC<ExcelImportErrorsTableProps> =
  ({ rows }) => (
    <>
      <BasicTable>
        <thead>
          <tr>
            <Cell header compact>
              Row
            </Cell>
            <Cell header nowrap>
              Unit price
            </Cell>
          </tr>
        </thead>
        <tbody>
          {rows
            .filter((row) => !row.success)
            .map((row) => {
              console.log({ row, errors: row.errors });
              return (
                <tr key={row.row}>
                  <Cell right>{row.row}</Cell>
                  <Cell
                    center
                    success={row.errors.unitPrice === null}
                    error={row.errors.unitPrice !== null}
                  >
                    {row.errors.unitPrice ? (
                      row.errors.unitPrice.join(", ")
                    ) : (
                      <CheckmarkIcon className={styles.checkmark} />
                    )}
                  </Cell>
                </tr>
              );
            })}
        </tbody>
      </BasicTable>
      <Description spacious>
        Columns marked with * indicate required values (column order is
        important, header titles are not).
      </Description>
    </>
  );
