import React from "react";
import classNames from "classnames";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { FieldError } from "../FieldError/FieldError";
import { EditableModeContext } from "../Form/Form";
import { isDefined } from "../../services/isDefined";
import fieldStyles from "../Field/Field.module.scss";
import { FieldProps } from "../Field/Field";
import { useKeyboardEvent } from "../../hooks/useKeyboardEvent";
import { isOfType } from "../../lib/helpers/typescript-helpers";
import styles from "./NumberInput.module.scss";

export interface NumberInputProps
  extends Pick<
    FieldProps,
    | "name"
    | "value"
    | "className"
    | "errors"
    | "label"
    | "required"
    | "addon"
    | "isFieldEditable"
    | "short"
    | "push"
    | "zeroMargin"
    | "placeholder"
    | "disabled"
    | "onChange"
    | "onBlur"
    | "currency"
  > {
  inputRef?: (ref: HTMLInputElement) => void;
  readOnly?: boolean;
}

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const {
    name,
    value,
    placeholder,
    className,
    inputRef,
    errors,
    label,
    required,
    addon,
    isFieldEditable,
    short,
    push,
    zeroMargin,
    disabled,
    currency,
    readOnly,
    onChange,
    onBlur,
  } = props;

  // field identifier
  const fieldId = `${name}-field`;

  // keep user inserted value in a state
  const [rawValue, setRawValue] = React.useState<string>("");

  // reference of the input for input transformations (comma to period e.g.)
  const innerRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (innerRef.current && value) {
      const stringValue = value.toString();

      setRawValue(stringValue);
      innerRef.current.value = stringValue;
    }
  }, [value]);

  // lose focus on input when "Enter" is pressed
  useKeyboardEvent({
    el: innerRef.current ?? undefined,
    eventType: "keydown",
    key: "Enter",
    handler: (e) => {
      if (
        isOfType<HTMLElement>(e.target, (target) => {
          return typeof target.blur === "function";
        })
      ) {
        e.target?.blur();
      }
    },
  });

  // if the inputRef exists and innerRef has a value for input, assign it to form's inputRef
  React.useEffect(() => {
    if (inputRef && innerRef.current) {
      inputRef(innerRef.current);
    }
  }, [inputRef]);

  const editableModeContextValue = React.useContext(EditableModeContext);

  // prop value of `isFieldEditable` takes precedence over editable context value
  const isEditable = isDefined(isFieldEditable)
    ? isFieldEditable
    : editableModeContextValue;

  // common field attributes
  const attributes = {
    id: fieldId,
    name,
    placeholder,
    disabled,
    readOnly,
  };

  // handle each character typed and update state
  // currently trims non-numeric and replaces each typed comma for period
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!innerRef || !innerRef.current) {
      return;
    }

    const onlyNumbersAndExponents = event.target.value.replace(
      /[^\d(e|E),.]/g,
      "",
    );

    const commaReplacedValue = onlyNumbersAndExponents.replace(/,/g, ".");

    setRawValue(commaReplacedValue);
    innerRef.current.value = commaReplacedValue;

    // call also callback
    if (typeof onChange === "function") {
      onChange(event);
    }
  };

  // handle blur event
  // convert from raw value to display value that is parsable to a valid number
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!innerRef || !innerRef.current) {
      return;
    }

    // convert raw value to valid number or NaN
    const newDisplayValueNr = parseFloat(rawValue);

    if (Number.isNaN(newDisplayValueNr)) {
      // NaN will set raw value to empty
      setRawValue("");
      innerRef.current.value = "";
    } else {
      // otherwise change the input value to be a valid number (as string)
      innerRef.current.value = newDisplayValueNr.toString();
    }

    // call also callback
    if (typeof onBlur === "function") {
      onBlur(event);
    }
  };

  const inputElement = (
    <input
      {...attributes}
      className={fieldStyles["input"]}
      name={name}
      ref={innerRef}
      onBlur={handleBlur}
      onChange={handleChange}
      type="text"
      pattern="[0-9eE\.]*"
      autoComplete="off"
    />
  );

  return (
    <div
      className={classNames(
        fieldStyles.field,
        {
          [fieldStyles["field--uneditable"]]: !isEditable,
          [fieldStyles["field--short"]]: short && isEditable,
          [fieldStyles.push]: push,
          [fieldStyles["zero-margin"]]: zeroMargin,
        },
        className,
      )}
    >
      {/* Field is editable */}
      {isEditable && (
        <>
          {label && label !== "" && (
            <FieldLabel label={label} htmlFor={name} required={required} />
          )}

          {addon !== undefined ? (
            <div className={fieldStyles["addon-wrap"]}>
              <div className={fieldStyles.addon}>{addon}</div>
              {inputElement}
            </div>
          ) : (
            inputElement
          )}

          {errors &&
            errors.map((error, index) => (
              <FieldError
                className={styles["field-error"]}
                key={index}
                error={error}
              />
            ))}
        </>
      )}

      {/* Field is uneditable */}
      {!isEditable && (
        <div className={fieldStyles["information"]}>
          <div className={fieldStyles["left-column"]}>{label}</div>
          <div className={fieldStyles["right-column"]}>
            {rawValue ?? "..."}
            {` ${currency}` ?? ""}
          </div>
        </div>
      )}
    </div>
  );
};
