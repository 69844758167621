import React from "react";
import classNames from "classnames";
import styles from "./NotAvailable.module.scss";

export interface NotAvailableProps {
  alignLeft?: boolean;
}

export const NotAvailable: React.FC<NotAvailableProps> = ({ alignLeft }) => (
  <div className={classNames(styles.wrap, alignLeft && styles["align-left"])}>
    ⋯
  </div>
);
