import React from "react";
import {
  StatusNotice,
  EventTypeEnum,
  StatusNoticeProps,
} from "../StatusNotice/StatusNotice";
import { AccordionTertiary } from "../Accordion/AccordionTertiary";
import { getEmailByType } from "../../services/getEmailByType";
import { OrderByCodeOrderByCode, CardTypeEnum } from "../../schema";
import { CardContextProvider } from "../../contexts/card-context";
import styles from "./CorrespondenceBar.module.scss";

export interface CorrespondenceBarProps {
  order: OrderByCodeOrderByCode;
}

export const CorrespondenceBar: React.FC<CorrespondenceBarProps> = (props) => {
  const { order } = props;

  const [
    statusNotice,
    setStatusNotice,
  ] = React.useState<StatusNoticeProps | null>(null);

  // handle correspondence bar email reply success
  const handleSendReplySuccess = (email: string) => {
    setStatusNotice({
      level: EventTypeEnum.SUCCESS,
      eventTitle: "Reply sent to supplier",
      eventDescription: `Sent to ${email}`,
    });
  };

  return (
    <>
      {/* status notice */}
      {statusNotice && (
        <StatusNotice
          level={statusNotice.level}
          eventTitle={statusNotice.eventTitle}
          eventDescription={statusNotice.eventDescription}
          onCloseRequested={() => {
            setStatusNotice(null);
          }}
        />
      )}

      {/* Use RFX context here because all correspondence bar e-mails are related to RFX */}
      {order.rfx && (
        <CardContextProvider card={{ ...order.rfx, type: CardTypeEnum.RFX }}>
          <div className={styles["correspondence-bar"]}>
            {order.rfx &&
              order.rfx.suppliers.map((supplier) => (
                <AccordionTertiary
                  key={supplier.id}
                  title={supplier.name}
                  className={styles["correspondence-accordion"]}
                >
                  {order.rfx &&
                  order.rfx.emails.filter(
                    (email) => email.supplierId === supplier.id,
                  ).length > 0
                    ? order.rfx.emails
                        .filter((email) => email.supplierId === supplier.id)
                        .map((email) =>
                          getEmailByType({
                            displayNarrowView: true,
                            email,
                            onSendReplySuccess: handleSendReplySuccess,
                            className: styles["correspondence-email"],
                          }),
                        )
                    : "No emails sent out yet"}
                </AccordionTertiary>
              ))}
          </div>
        </CardContextProvider>
      )}
    </>
  );
};
