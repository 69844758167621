import React from "react";
import { useActivitySubscription, ActivityFeedItemFragment } from "../schema";

export const LatestActivityContext = React.createContext<
  ActivityFeedItemFragment | undefined
>(undefined);

export interface LatestActivityProviderProps {
  organizationId: string;
  relativeId: string | null;
  children?: React.ReactNode;
}

export function LatestActivityProvider({
  organizationId,
  relativeId,
  children,
}: LatestActivityProviderProps) {
  // subscribe to activity updates
  const { data } = useActivitySubscription({
    variables: { organizationId, relativeId },
  });

  return (
    <LatestActivityContext.Provider value={data?.activity}>
      {children}
    </LatestActivityContext.Provider>
  );
}

/**
 * Convenient use context hook to be used inside LatestActivityProvider
 *
 * @returns React.useContext(LatestActivityContext)
 *
 * @example  const latestActivity = useLatestActivity()
 */
export function useLatestActivity() {
  return React.useContext(LatestActivityContext);
}
