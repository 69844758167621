import React from "react";
import { useParams } from "react-router-dom";
import { Modal } from "../../components/Modal/Modal";
import { Separator } from "../../components/Separator/Separator";
import { useRouter } from "../../hooks/useRouter";
import { OpenExternalIcon } from "../../theme/svg/OpenExternalIcon";
import { useEmailView, ActivityTypeEnum } from "../../schema";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./EmailView.module.scss";

export interface EmailViewProps {
  organizationId: string;
}

interface EmailViewRouterParams {
  activityId: string;
}

export const EmailView: React.FC<EmailViewProps> = ({ organizationId }) => {
  const { history } = useRouter();

  const { activityId } = useParams<EmailViewRouterParams>();

  const { data, loading } = useEmailView({
    variables: { organizationId, activityId },
  });

  if (loading || !data) {
    return <LoadingView overlay />;
  }

  const organization = data.viewer?.organization;
  const activity = data.viewer?.organization.activityById;

  const onModalClose = () => {
    if (organization) {
      history.push(`/${organization.urlName}`);
    }
  };

  let values;

  // depending on activity type get the values
  switch (activity?.data.__typename) {
    case ActivityTypeEnum.EMAIL_INBOUND_SUPPLIER: {
      values = {
        subject: activity?.data.emailInfo.subject,
        from: activity?.data.supplierInfo.email ?? "",
        to: activity?.data.emailInfo.email,
        body: activity?.data.emailInfo.message,
        attachments: activity?.attachments ?? [],
      };

      break;
    }
    case ActivityTypeEnum.EMAIL_INBOUND_MENTION: {
      values = {
        subject: activity?.data.emailInfo.subject,
        from: `${activity?.data.user.firstName} ${activity?.data.user.lastName}`,
        to: activity?.data.emailInfo.email,
        body: activity?.data.emailInfo.message,
        attachments: activity?.attachments ?? [],
      };

      break;
    }
    case ActivityTypeEnum.EMAIL_OUTBOUND_SUPPLIER: {
      values = {
        subject: activity?.data.subject,
        from: `${activity.data.creator.firstName} ${activity.data.creator.lastName}`,
        to: activity?.data.supplierInfo.email,
        body: activity?.data.message,
        attachments: activity?.attachments ?? [],
      };

      break;
    }

    default:
      values = { subject: "", from: "", to: "", body: "", attachments: [] };
  }

  return (
    <Modal title={values.subject} onCloseRequested={onModalClose}>
      <div className={styles.wrap}>
        {values.from && (
          <div className={styles.from}>
            <span>From: </span>
            <a href={`mailto:${values.from}`}>{values.from}</a>
          </div>
        )}
        {values.to && (
          <div className={styles.to}>
            <span>To: </span>
            <a href={`mailto:${values.to}`}>{values.to}</a>
          </div>
        )}
        <Separator />
        <div className={styles.body}>{values.body}</div>
        {values.attachments && values.attachments.length > 0 && (
          <>
            <Separator />
            <div className={styles.strong}>Attachments:</div>
            <div className={styles.file}>
              {values.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    {attachment.url && (
                      <a
                        data-testid="ol2b5ata56"
                        href={attachment.url}
                        className={styles.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.filename}>
                          {attachment.filename}
                        </span>{" "}
                        <OpenExternalIcon
                          className={styles["open-external-icon"]}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
