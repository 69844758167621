import classNames from "classnames";
import React from "react";
import { RenderSuggestion } from "react-autosuggest";
import { renderHighlighted } from "../../services/renderHighlighted";
import {
  Autosuggest,
  FetchSuggestions,
  GetSuggestionValue,
  OnChoose,
} from "../Autosuggest/Autosuggest";
import { SearchIcon } from "../../theme/svg/SearchIcon";
import {
  CardStatus,
  CardStatusLevelEnum,
  FullTextSearchItemParentInfo,
  FullTextSearchResultTypeEnum,
} from "../../schema";
import { Status } from "../Status/Status";
import styles from "./MenuSearch.module.scss";

export interface SearchSuggestion {
  id: string;
  name: string;
  code: string | null;
  prefix: string;
  parent: FullTextSearchItemParentInfo | null;
  type: FullTextSearchResultTypeEnum;
  status: CardStatus | null;
  isArchived: boolean;
}
export interface MenuSearchProps {
  fetchSuggestions: FetchSuggestions<SearchSuggestion>;
  onChoose: (suggestionCode: string) => void;
  className?: string;
}

export const MenuSearch: React.FC<MenuSearchProps> = (props) => {
  const { className, fetchSuggestions, onChoose } = props;
  const [userTypedValue, setUserTypedValue] = React.useState("");
  const [chosenSuggestion, setChosenSuggestion] =
    React.useState<SearchSuggestion>();

  const getSuggestionValue: GetSuggestionValue<SearchSuggestion> = (
    suggestion,
  ) => {
    return suggestion.name;
  };

  const getSuggestionCode = (suggestion: SearchSuggestion) => {
    switch (suggestion.type) {
      case FullTextSearchResultTypeEnum.PURCHASE_REQUEST_ITEM: {
        return `${suggestion.parent?.prefix}-${suggestion.parent?.code}`;
      }

      default:
        return `${suggestion.prefix}-${suggestion.code}`;
    }
  };

  const renderCodes = (suggestion: SearchSuggestion) => {
    const code =
      suggestion.type === FullTextSearchResultTypeEnum.PURCHASE_REQUEST_ITEM &&
      suggestion.parent
        ? `${suggestion.parent.prefix}-${suggestion.parent.code}`
        : `${suggestion.prefix}-${suggestion.code}`;

    const suggestionType =
      suggestion.type === FullTextSearchResultTypeEnum.PURCHASE_REQUEST_ITEM &&
      suggestion.parent
        ? suggestion.parent.type
        : suggestion.type;

    return (
      <Status
        level={CardStatusLevelEnum.DEFAULT}
        text={code}
        className={classNames(styles["status--margin"], {
          [styles["status--pr"]]:
            suggestionType === FullTextSearchResultTypeEnum.PURCHASE_REQUEST,
          [styles["status--rfx"]]:
            suggestionType === FullTextSearchResultTypeEnum.RFX,
          [styles["status--po"]]:
            suggestionType === FullTextSearchResultTypeEnum.ORDER,
          [styles["status--po-receiving"]]:
            suggestionType === FullTextSearchResultTypeEnum.ORDER_RECEIVING,
          [styles["status--po-invoice"]]:
            suggestionType === FullTextSearchResultTypeEnum.ORDER_INVOICE,
        })}
      />
    );
  };

  const renderStatuses = (suggestion: SearchSuggestion) => {
    const status =
      suggestion.type === FullTextSearchResultTypeEnum.PURCHASE_REQUEST_ITEM &&
      suggestion.parent
        ? suggestion.parent.status
        : suggestion.status;

    if (!status) {
      return;
    }

    return (
      <Status
        level={status.level}
        text={status.text}
        className={classNames(styles["status--margin"])}
      />
    );
  };

  const renderSuggestion: RenderSuggestion<SearchSuggestion> = (
    suggestion,
    { query, isHighlighted },
  ) => {
    return (
      <div
        className={classNames(styles.suggestion, {
          [styles["suggestion--highlight"]]: isHighlighted,
        })}
      >
        <div>
          {renderHighlighted(suggestion.name, query, styles["text-highlight"])}
        </div>
        {renderCodes(suggestion)}
        {renderStatuses(suggestion)}
        {suggestion.type ===
          FullTextSearchResultTypeEnum.PURCHASE_REQUEST_ITEM &&
          suggestion.code && (
            <div className={classNames(styles["code"])}>
              <small>Code: {suggestion.code}</small>
            </div>
          )}
      </div>
    );
  };

  const onChange = (value: string) => {
    setUserTypedValue(value);
  };

  const onChooseProxy: OnChoose<SearchSuggestion> = (chosen) => {
    if (!chosen) {
      return;
    }

    setChosenSuggestion(chosen);
    onChoose(getSuggestionCode(chosen));
    setUserTypedValue("");
  };

  return (
    <div className={classNames([styles["search-wrap"], className])}>
      <SearchIcon className={styles["search-icon"]} />
      <Autosuggest
        className={classNames(styles["search"])}
        value={userTypedValue}
        onChange={onChange}
        chosenSuggestion={chosenSuggestion}
        fetchSuggestions={fetchSuggestions}
        onChoose={onChooseProxy}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        placeholder="Search ProcurementFlow"
        wide={true}
      />
    </div>
  );
};
