import classNames from "classnames";
import React, { ChangeEvent, FocusEvent, useContext } from "react";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { FieldError } from "../FieldError/FieldError";
import { Radio } from "../Radio/Radio";
import { EditableModeContext } from "../Form/Form";
import { NotAvailable } from "../NotAvailable/NotAvailable";
import { isDefined } from "../../services/isDefined";
import styles from "./Select.module.scss";

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectProps {
  name?: string;
  options?: SelectOption[];
  label?: string;
  placeholder?: string;
  value?: string;
  errors?: string[];
  required?: boolean;
  secondary?: boolean;
  radio?: boolean;
  short?: boolean;
  className?: string;
  isSelectEditable?: boolean;
  onChange?(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void;
  onBlur?(e: FocusEvent<HTMLSelectElement>): void;
  inputRef?: (ref: HTMLSelectElement) => void;
}

export const Select: React.FC<SelectProps> = ({
  name,
  options,
  label,
  placeholder,
  value,
  errors,
  required,
  secondary,
  radio,
  short,
  className,
  isSelectEditable,
  onChange,
  onBlur,
  inputRef,
}) => {
  const fieldId = `${name}-select`;

  // context set by form to indicate if all its child inputs should be enabled (true) or disabled (false)
  const editableModeContextValue = useContext(EditableModeContext);
  const isEditable = isDefined(isSelectEditable)
    ? isSelectEditable
    : editableModeContextValue;

  // format value
  const displayValue = () => {
    if (!value) return <NotAvailable alignLeft />;

    return value;
  };

  // render as radio buttons if requested
  if (radio) {
    return (
      <>
        {label !== undefined && label !== "" && (
          <FieldLabel label={label} htmlFor={fieldId} required={required} />
        )}
        <div className={classNames(styles.radio, className)}>
          {Array.isArray(options) &&
            options.map((option) => (
              <Radio
                key={option.value}
                name={name}
                value={option.value}
                checked={option.value === value}
                onChange={onChange}
              >
                {option.label}
              </Radio>
            ))}
          {errors !== undefined &&
            errors.map((error, index) => (
              <FieldError key={index} error={error} />
            ))}
        </div>
      </>
    );
  }

  return (
    <>
      {/* Field is editable */}
      {isEditable && (
        <>
          {label !== undefined && label !== "" && (
            <FieldLabel label={label} htmlFor={fieldId} required={required} />
          )}
          <select
            name={name}
            value={typeof onChange === "function" ? value : undefined}
            defaultValue={typeof onChange !== "function" ? value : undefined}
            className={classNames(
              styles.select,
              {
                [styles["select--secondary"]]: secondary,
                [styles["select--short"]]: short,
              },
              className,
            )}
            onChange={onChange}
            onBlur={onBlur}
            data-testid={`${label}-${value}`}
            ref={inputRef}
          >
            {placeholder !== undefined && (
              <option className={styles.placeholder}>{placeholder}</option>
            )}
            {Array.isArray(options) &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
          {errors !== undefined &&
            errors.map((error, index) => (
              <FieldError key={index} error={error} />
            ))}
        </>
      )}

      {/* Field is uneditable */}
      {!isEditable && (
        <div className={styles["information"]}>
          <div className={styles["left-column"]}>{label}</div>
          <div className={styles["right-column"]}>{displayValue()}</div>
        </div>
      )}
    </>
  );
};
