import { ValidatorFn } from "../lib/react-apollo-hooks-form";

export const isEmail: ValidatorFn = (value) => {
  const invalidEmailError = "Please enter valid email";

  // only a string can be a valid email
  if (typeof value !== "string") {
    return invalidEmailError;
  }

  const emailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  // validate email
  if (!emailRegexp.test(value)) {
    return invalidEmailError;
  }
};
