import React from "react";
import classNames from "classnames";
import styles from "./Activity.module.scss";

export interface ActivityProps {
  dateAreaContent: React.ReactNode;
  infoAreaHeader: React.ReactNode;
  infoAreaBody?: React.ReactNode;
  infoAreaFooter?: React.ReactNode;
  addonsAreaContent?: React.ReactNode;
  className?: string;
}

export const Activity: React.FC<ActivityProps> = (props) => {
  const {
    className,
    dateAreaContent,
    infoAreaHeader,
    infoAreaBody,
    infoAreaFooter,
    addonsAreaContent,
  } = props;

  return (
    <div className={classNames(styles["activity-wrap"], className)}>
      <div className={styles["date-area"]}>{dateAreaContent}</div>

      <div className={styles["info-area"]}>
        <div className={styles["info-area-header"]}>{infoAreaHeader}</div>
        <div className={styles["info-area-body"]}>{infoAreaBody}</div>
        <div className={styles["info-area-footer"]}>{infoAreaFooter}</div>
      </div>

      <div className={styles["addons-area"]}>{addonsAreaContent}</div>
    </div>
  );
};
