import React from "react";
import classNames from "classnames";
import formatSize from "pretty-bytes";
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { Loading } from "../Loading/Loading";
import { getCombinedApolloErrorMessage } from "../../services/getCombinedApolloErrorMessage";
import {
  DEFAULT_UPLOAD_FILE_SIZE_MAX_LIMIT,
  DEFAULT_UPLOAD_FILE_SIZE_MIN_LIMIT,
  DEFAULT_UPLOAD_MIME_TYPES,
} from "../../constants";
import styles from "./Dropzone.module.scss";

export interface DropzoneProps extends DropzoneOptions {
  activeText?: React.ReactNode;
  rejectTypeText?: React.ReactNode;
  rejectTooSmallText?: React.ReactNode;
  rejectTooLargeText?: React.ReactNode;
  label?: string;
  error?: Error;
  selectedFile?: File;
  loading?: boolean;
  short?: boolean;
  required?: boolean;
}

export type OnDropCallbackFn = (
  acceptedFiles: File[],
  fileRejections?: FileRejection[],
  event?: DropEvent,
) => void;

export const Dropzone: React.FC<DropzoneProps> = ({
  activeText,
  rejectTypeText,
  rejectTooSmallText,
  rejectTooLargeText,
  label,
  error,
  selectedFile,
  loading,
  short,
  required,
  children,
  minSize = DEFAULT_UPLOAD_FILE_SIZE_MIN_LIMIT,
  maxSize = DEFAULT_UPLOAD_FILE_SIZE_MAX_LIMIT,
  multiple,
  accept = DEFAULT_UPLOAD_MIME_TYPES,
  ...options
}) => {
  // setup dropzone
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    draggedFiles,
    fileRejections,
  } = useDropzone({
    minSize,
    maxSize,
    accept,
    ...options,
  });

  // resolve combined error message
  const errorMessage = error ? getCombinedApolloErrorMessage(error) : undefined;

  // resove rejection state and reason
  const isRejectedMultiple = draggedFiles.length > 1 && multiple === false;
  const isRejected = fileRejections.length > 0 || isRejectedMultiple;
  const isRejectedTooSmall =
    minSize &&
    fileRejections.some((rejection) => rejection.file.size < minSize);
  const isRejectedTooLarge =
    maxSize &&
    fileRejections.some((rejection) => rejection.file.size > maxSize);
  const isTypeRejected =
    isRejected &&
    !isRejectedTooSmall &&
    !isRejectedTooLarge &&
    !isRejectedMultiple;

  // resolve texts to show
  const inactiveText = children || "Drag file here";
  const rejectText = isRejectedMultiple
    ? "Please provide a single file"
    : isTypeRejected
    ? rejectTypeText || "Please provide valid file"
    : isRejectedTooSmall
    ? rejectTooSmallText || "Provided file is too small"
    : isRejectedTooLarge
    ? rejectTooLargeText || "Provided file is too large"
    : "";

  // render dropzone
  return (
    <div>
      {label && <FieldLabel required={required} label={label} />}
      <div
        {...getRootProps()}
        className={classNames(styles.dropzone, {
          [styles["dropzone--short"]]: short,
          [styles["dropzone--loading"]]: loading,
          [styles["dropzone--rejected"]]: isRejected,
          [styles["dropzone--with-error"]]:
            errorMessage !== undefined && !isDragActive,
          [styles["dropzone--active"]]: isDragActive && !isRejected,
          [styles["dropzone--selected"]]: selectedFile !== undefined,
        })}
      >
        <input {...getInputProps()} />
        {isRejected ? (
          <div className={styles.warning}>{rejectText}</div>
        ) : isDragActive ? (
          activeText || inactiveText
        ) : errorMessage ? (
          <div className={styles.error}>{errorMessage}</div>
        ) : selectedFile ? (
          <div>
            You have selected &quot;{selectedFile.name}&quot; (
            {formatSize(selectedFile.size)})
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          inactiveText
        )}
      </div>
    </div>
  );
};
