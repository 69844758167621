import React from "react";
import classNames from "classnames";
import styles from "./CompanyLogo.module.scss";

export interface CompanyLogoProps {
  url: string;
  alt: string;
  title: string;
}

export const CompanyLogo: React.FC<CompanyLogoProps> = ({
  url,
  alt,
  title,
}) => {
  return (
    <div className={classNames(styles["company-logo"])}>
      <img src={url} alt={alt} title={title} />
    </div>
  );
};
