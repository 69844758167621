import React from "react";
import classNames from "classnames";
import styles from "./Grid.module.scss";

export interface GridProps {
  className?: string;
  children?: React.ReactNode;
  gridColumns?: string;
  gridAreas?: string;
}

interface GridVariables extends React.CSSProperties {
  "--gridTemplateColumns"?: string;
  "--gridTemplateAreas"?: string;
}

export const Grid: React.FC<GridProps> = (props) => {
  const { className, children, gridColumns, gridAreas } = props;

  const injectedCSSVariables: GridVariables = {
    "--gridTemplateColumns": gridColumns ? gridColumns : "auto auto",
    "--gridTemplateAreas": gridAreas ? `"${gridAreas}"` : `"left right"`,
  };

  return (
    <div
      style={injectedCSSVariables}
      className={classNames(styles["grid"], className)}
    >
      {children}
    </div>
  );
};
