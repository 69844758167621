import React, { useState } from "react";
import classNames from "classnames";
import { SidebarIcon } from "../../theme/svg/SidebarIcon";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import styles from "./Sidebar.module.scss";

export interface SidebarProps {
  title?: string;
  openOnTop?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
  children,
  title,
  openOnTop,
}) => {
  const [isSideBarVisible, setIsSidebarVisible] = useState(false);

  function toggleSidebar() {
    setIsSidebarVisible(!isSideBarVisible);
  }

  return (
    <div
      className={classNames(styles["sidebar-wrap"], {
        [styles["sidebar-wrap--is-open"]]: isSideBarVisible,
        [styles["sidebar-wrap--open-on-top"]]: openOnTop,
      })}
    >
      <div className={styles.sidebar}>
        {isSideBarVisible && (
          <div className={styles["sidebar-content-wrap"]}>
            <div className={styles["sidebar-content"]}>{children}</div>
          </div>
        )}
        <div
          data-testid="75bf8e53ba"
          className={classNames(styles["sidebar-handle"], {
            [styles["sidebar-handle--closed"]]: !isSideBarVisible,
          })}
          onClick={() => toggleSidebar()}
        >
          <div
            className={classNames(styles["icon-wrap"], {
              [styles["icon-wrap--sidebar-open"]]: isSideBarVisible,
            })}
          >
            <SidebarIcon />
          </div>
          {title && (
            <FieldLabel label={title} className={styles["sidebar-title"]} />
          )}
        </div>
      </div>
    </div>
  );
};
