import React from "react";
import classNames from "classnames";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  PurchaseRequestsDetailsByCodePurchaseRequestByCode,
  PurchaseRequestStatusEnum,
  ViewerViewer,
  ApprovalRequestDecisionEnum,
  useUpdatePurchaseRequestFromPortal,
} from "../../schema";
import { formatDatetime } from "../../services/formatDatetime";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { CompleteReadyIcon } from "../../theme/svg/CompleteReadyIcon";
import { AccordionSecondary } from "../Accordion/AccordionSecondary";
import {
  ApprovalStatusHeader,
  ApprovalStatus,
} from "../ApprovalStatus/ApprovalStatus";
import { Button } from "../Button/Button";
import { RequestAttachments } from "../RequestAttachments/RequestAttachments";
import { RequestDetails } from "../RequestDetails/RequestDetails";
import { RequestedItems } from "../RequestedItems/RequestedItems";
import { SectionSubtitle } from "../SectionSubtitle/SectionSubtitle";
import styles from "./PurchaseRequestDetails.module.scss";

interface PurchaseRequestDetailsProps {
  purchaseRequest: PurchaseRequestsDetailsByCodePurchaseRequestByCode;
  editPurchaseRequest?: () => void;
  viewer: ViewerViewer;
}

export const PurchaseRequestDetails: React.FC<PurchaseRequestDetailsProps> = ({
  purchaseRequest,
  editPurchaseRequest,
  viewer,
}) => {
  // Configure Form
  const { submit, loading } = useForm({
    mutation: useUpdatePurchaseRequestFromPortal,
    options: {
      refetchQueries: ["PurchaseRequestsView", "PurchaseRequestsDetailsByCode"],
      awaitRefetchQueries: true,
    },
  });

  const submitForm = async () => {
    if (purchaseRequest) {
      await submit({
        purchaseRequestId: purchaseRequest.id,
        name: purchaseRequest.name,
        departmentId: purchaseRequest.departmentId
          ? purchaseRequest.departmentId
          : null,
        projectId: purchaseRequest.projectId ? purchaseRequest.projectId : null,
        costCentreId: purchaseRequest.costCentreId
          ? purchaseRequest.costCentreId
          : null,
        status: PurchaseRequestStatusEnum.CREATED_FROM_EMAIL,
      });
    }
  };

  const currentAwf = purchaseRequest.latestApprovalWorkflow;

  return (
    <>
      <div className={styles["pr-sections"]}>
        <div className={styles["pr-section"]}>
          <AccordionSecondary
            title={
              <h3 className={styles["pr-section-title"]}>Request details</h3>
            }
          >
            <RequestDetails
              name={purchaseRequest.name}
              notes={purchaseRequest.notes}
              requestor={purchaseRequest?.requestor}
              department={purchaseRequest?.department}
              project={purchaseRequest?.project}
              costCentre={purchaseRequest?.costCentre}
              attachments={purchaseRequest?.attachments}
            />
          </AccordionSecondary>
          <AccordionSecondary
            title={
              <h3 className={styles["pr-section-title"]}>Requested items</h3>
            }
          >
            {purchaseRequest?.items && (
              <RequestedItems items={purchaseRequest.items} />
            )}
          </AccordionSecondary>
          {currentAwf && (
            <AccordionSecondary
              title={
                <h3
                  className={classNames(
                    styles["pr-section-title"],
                    styles["pr-section-title--alt"],
                  )}
                >
                  Approvals
                </h3>
              }
            >
              {currentAwf.comment && (
                <SectionSubtitle
                  title="Comment for approvers"
                  value={currentAwf.comment}
                />
              )}
              {purchaseRequest.attachments && (
                <RequestAttachments attachments={purchaseRequest.attachments} />
              )}
              <div className={styles["request-statuses"]}>
                {purchaseRequest.requestor && (
                  <ApprovalStatusHeader
                    creator={purchaseRequest.requestor}
                    awfCode={currentAwf.code}
                    requestName={currentAwf.requestName}
                    prCode={purchaseRequest.code}
                    status={`${currentAwf.approvalRequests.reduce(
                      (acc, value) => {
                        return value.decision ===
                          ApprovalRequestDecisionEnum.APPROVED
                          ? acc + 1
                          : acc;
                      },
                      0,
                    )}/${currentAwf.approvalRequests.length}`}
                  />
                )}
                {currentAwf.approvalRequests
                  .sort((a, b) =>
                    a.sequenceNumber < b.sequenceNumber ? -1 : 1,
                  )
                  .map((ar) => {
                    return (
                      <ApprovalStatus
                        key={ar.id}
                        approver={ar.approver}
                        dueDate={
                          ar.dueDate ? formatDatetime(ar.dueDate) : undefined
                        }
                        decisionDate={formatTimeAgo(ar.decisionDate)}
                        decision={ar.decision}
                        status={
                          ar.decision &&
                          ApprovalRequestDecisionEnum[ar.decision]
                        }
                        participantName={`${ar.approver.firstName} ${ar.approver.lastName}`}
                        enableDecisionForm={
                          !ar.decision && ar.approver.id === viewer.id
                        }
                        creatorComment={ar.creatorComment}
                        approverComment={ar.approverComment}
                        sequenceNumber={ar.sequenceNumber}
                      />
                    );
                  })}
              </div>
            </AccordionSecondary>
          )}
        </div>
      </div>
      {purchaseRequest?.status === PurchaseRequestStatusEnum.DRAFT && (
        <div className={styles["form-footer"]}>
          <Button secondary onClick={editPurchaseRequest} disabled={loading}>
            Edit
          </Button>
          <Button
            onClick={() => submitForm()}
            disabled={purchaseRequest.items.length === 0}
          >
            <CompleteReadyIcon /> Request ready
          </Button>
        </div>
      )}
    </>
  );
};
