import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import classNames from "classnames";
import { FieldError } from "../FieldError/FieldError";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { CalendarIcon } from "../../theme/svg/CalendarIcon";
import styles from "./Datepicker.module.scss";

// extend date picker props but allow missing onChange callback
export interface DatepickerProps
  extends Omit<ReactDatePickerProps, "onChange"> {
  name?: string;
  label?: string;
  placeholder?: string;
  errors?: string[];
  className?: string;
  required?: boolean;
  showTimeSelect?: boolean;
  disabled?: boolean;
  onChange?(date: Date | null, event: React.SyntheticEvent | undefined): void;
  onClear?(): void;
}

export const Datepicker: React.FC<DatepickerProps> = ({
  name,
  label,
  selected,
  placeholder,
  errors,
  className,
  required,
  showTimeSelect,
  disabled,
  onChange,
  ...rest
}) => {
  const fieldId = `${name}-datepicker`;

  return (
    <div className={classNames(styles.datepicker, className)}>
      {label !== undefined && label !== "" && (
        <FieldLabel label={label} htmlFor={fieldId} required={required} />
      )}
      <div className={styles["field-wrap"]}>
        <div className={styles.addon}>
          <CalendarIcon className={styles.icon} />
        </div>
        <DatePicker
          id={fieldId}
          name={name}
          selected={selected}
          placeholderText={placeholder}
          className={styles.input}
          showTimeSelect={showTimeSelect}
          disabled={disabled}
          onChange={
            typeof onChange === "function"
              ? onChange
              : () => {
                  /* do nothing */
                }
          }
          dateFormat="dd/MM/yyyy"
          {...rest}
        />
      </div>
      {errors !== undefined &&
        errors.map((error, index) => <FieldError key={index} error={error} />)}
    </div>
  );
};
