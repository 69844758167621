import * as React from "react";
export const LoginIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 491 309" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 215)">
        <ellipse
          fill={props.fill}
          cx={45.439}
          cy={10.044}
          rx={45.439}
          ry={10.044}
        />
        <path
          d="M45.44 10.044L19.914 89.928M45.44 10.044l25.523 79.884"
          stroke={props.fill}
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          d="M28.296 63.698L56.75 45.454M34.013 45.454l28.461 18.244"
          stroke={props.fill}
          strokeWidth={2}
          strokeLinecap="round"
        />
      </g>
      <g transform="translate(177 213)">
        <ellipse
          fill={props.fill}
          cx={45.439}
          cy={10.044}
          rx={45.439}
          ry={10.044}
        />
        <path
          d="M45.44 10.044L19.914 89.928M45.44 10.044l25.523 79.884"
          stroke={props.fill}
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          d="M28.296 63.698L56.75 45.454M34.013 45.454l28.461 18.244"
          stroke={props.fill}
          strokeWidth={2}
          strokeLinecap="round"
        />
      </g>
      <g transform="translate(162 48)">
        <path
          d="M161 142c.432 2.594 7.76 61.909 8.947 62.89 1.187.98 13.792 3.154 14.871 1.52.216-2.595.514-43.955 1.182-47.655"
          fill="#1F161E"
        />
        <path
          d="M19.23 129.88C14.143 127.37 24.75 177 52.664 177c27.915 0 74.902-42.499 82.51-42.499 16.794 0 56.549 32.958 59.666 32.958 2.387-2.386 7.16-6.56 7.16-8.2C202 157.62 163.937 95 135.456 95c-11.043-.01-104.74 40.55-116.226 34.88z"
          fill="#4E1600"
        />
        <path
          d="M35.125 130.94c5.641-1.272 12.661-4.567 18.875-7.94-16.905 5.494-30.757 9.058-34.756 7.092-2.724-1.334-.998 12.032 5.408 24.908-1.16-2.493-2.813-21.064 10.473-24.06z"
          fill="#1F161E"
        />
        <path
          d="M47.854 43.01c4.415.119 27.4-.556 33.7 5.43 4.673 4.436 19.205 42.632 21.446 56.043-9.76 4.946-80.155 38.578-101.942 23.786-.48-11.494 1.756-57.155 16.003-81.106 5.21-3.463 26.548-4.266 30.793-4.153z"
          fill="#F55C21"
        />
        <path d="M56 32H43v14.321c0 3.587 13 3.556 13 0V32z" fill="#F8CDBA" />
        <path
          d="M43 41c0-1.632 4.326-5.732 7-5.59.065-1.778-6.109-4.41-6.109-4.41L43 33.51V41z"
          fill="#1F161E"
        />
        <path
          d="M70.548 32c.3-1.423.452-2.874.452-4.33C71 16.808 62.721 8 52.5 8S34 16.808 34 27.67c0 1.456.152 2.907.452 4.33h36.096z"
          fill="#1F161E"
        />
        <path
          d="M66.063 19.268c-2.28-.342-4.555-2.11-6.142-3.268-9.048 6.536-14.844 6.627-15.47 6.627-.625 0-3.174 3.61-4.348 4.017l-.103-.06c.959 5.173 4.793 9.372 9.911 10.853 5.118 1.48 10.646-.01 14.287-3.853 3.642-3.843 4.775-9.382 2.93-14.316a4.383 4.383 0 01-1.065 0z"
          fill="#F8CDBA"
        />
        <circle fill="#1F161E" cx={42.5} cy={5.5} r={5.5} />
        <path
          d="M45 22c-1.12.063-2 1.164-2 2.5s.88 2.437 2 2.5M62 23c.545.868 1.948 2.387 0 4M55 28c0 .749.332 2.537 2 3"
          stroke="#1F161E"
          strokeLinecap="round"
        />
        <ellipse fill="#FA9E9F" cx={50} cy={27} rx={2} ry={1} />
        <ellipse fill="#FA9E9F" cx={66} cy={27} rx={2} ry={1} />
        <path
          d="M81 82.722L95.068 79c1.351 2.348 7.932 25.588 7.932 25.588L81.283 114c.284-4.404 1.14-24.53-.283-31.278z"
          fill="#F8CDBA"
        />
        <path
          d="M30 80c0 1.668-1.258 8.017-2 11M81 83c-.357-2.708-2.446-17.968-6-26"
          stroke="#1F161E"
          strokeLinecap="round"
        />
        <path
          d="M81 82.722L95.068 79c1.351 2.348 7.932 25.588 7.932 25.588L81.283 114c.284-4.404 1.14-24.53-.283-31.278z"
          fill="#F8CDBA"
        />
        <path
          d="M196 166.228c.312.486 4.479 5.71 5.953 5.772 1.475.061 10.427-6.115 10.034-7.442-.392-1.326-6.008-.466-6.974-.927-.966-.46-3.115-2.653-3.457-3.631M173.254 205.555c-.106.566-.677 7.235.348 8.316 1.026 1.08 11.72 2.955 12.341 1.727.621-1.228-4.041-4.555-4.4-5.564-.358-1.009-.434-4.076 0-5.034"
          fill="#1F161E"
        />
        <path fill="#969696" d="M155.462 106H107l11.538-31H167z" />
        <g fill="#FFF">
          <path d="M140.501 94.812l-3.283-3.296c-.29-.292-.29-.75 0-1.001l3.283-3.296c.291-.292.748-.292.998 0l3.283 3.296c.29.292.29.751 0 1.001l-3.283 3.296c-.291.25-.748.25-.998 0z" />
          <path
            d="M139.775 94.46l-3.256-3.242c-.292-.29-.751-.29-1.002 0l-3.298 3.283c-.292.291-.292.748 0 .998l3.298 3.283c.292.29.752.29 1.002 0l3.298-3.283a.766.766 0 00-.042-1.04zm-.918.914l-.501.54a.255.255 0 01-.334 0l-2.922-2.909a.252.252 0 010-.332l.5-.499a.255.255 0 01.335 0l2.922 2.91c.083.04.083.207 0 .29zM139.795 85.204L136.82 82.2c-.267-.267-.687-.267-.916 0l-7.704 7.683c-.267.266-.267.684 0 .913l3.013 3.004c.267.267.687.267.916 0l7.704-7.683c.229-.266.229-.684-.038-.913zm-2.06 2.054l-.458.457a.232.232 0 01-.305 0l-2.67-2.663a.23.23 0 010-.304l.458-.456a.232.232 0 01.305 0l2.67 2.662a.23.23 0 010 .304zm1.22-1.217l-.457.457a.232.232 0 01-.305 0l-2.67-2.663a.23.23 0 010-.304l.458-.457a.232.232 0 01.305 0l2.67 2.663a.23.23 0 010 .304z"
            fillRule="nonzero"
          />
        </g>
        <path fill="#969696" d="M67 105h48v1H67z" />
        <path
          d="M28.466 91.504c-.926 3.476-5.447 18.302-3.583 20.852 1.863 2.55 47.736-3.246 60.019-7.065 3.128-1.024 5.447-3.712 6.835-4.055a7.326 7.326 0 018.263 5.212c-4.905 2.375-75.722 38.53-98.668 22.44C-3.005 125.8 4.608 87.914 4.952 86c3.875.481 23.514 5.504 23.514 5.504z"
          fill="#F8CDBA"
        />
        <path
          d="M81 105.807a61.612 61.612 0 003.644-.895c3.18-.964 5.538-3.492 6.95-3.814 3.742-.587 7.364 1.525 8.406 4.902"
          stroke="#1F161E"
          strokeLinecap="round"
        />
      </g>
      <g transform="translate(344)">
        <path
          d="M138.686 292.022c.059.949.653 6.501-.098 7.482-1.38 1.776-13.423 1.686-14.018 1.174-.595-.513-1-1.655-.516-2.014.483-.359 5.195-1.59 5.81-2.064.614-.475 1.372-2.866.96-4.104M79.001 134.67c-2.06 4.674-19.366 55.597-18.965 63.022.4 7.425 40.444 83.93 42.655 84.738 2.212.807 11.399-2.015 12.14-4.024.742-2.01-13.951-47.667-14.351-59.359"
          fill="#1F161E"
        />
        <path
          d="M78.936 134.62c1.04 5.512 18.318 81.528 18.318 81.528l31.677 76.548c2.634 1.282 10.384 1.42 11.74 1.012 1.357-.407-5.155-73.71-7.73-84.097-2.574-10.388-9.35-82.501-9.35-82.501s-35.49.585-44.655 7.51z"
          fill="#453300"
        />
        <path
          d="M87.444 52.923c-5.2 2.44-38.35 18.824-41.199 18.824-2.848 0-33.151-17.2-34.932-18.357-1.696 1.704-4.282 4.387-3.654 5.262.629.875 35.378 33.49 38.324 33.49 2.947 0 32.791-9.122 35.11-11.628"
          fill="#FEB900"
        />
        <path
          d="M98.776 49.344c-29.816-4.909-22.713 81.73-19.777 85.014 2.936 3.284 43.151-4.606 45.766-7.712 2.615-3.105-5.065-76.48-25.989-77.302z"
          fill="#FEB900"
        />
        <path
          d="M113.06 278.791c.352.899 2.642 6.127 2.22 7.314-.762 2.151-12.2 5.746-12.923 5.424-.722-.321-1.464-1.312-1.119-1.804.345-.491 4.438-3.128 4.88-3.784.443-.656.417-3.208-.364-4.29"
          fill="#1F161E"
        />
        <path
          d="M93.588 54.132c0 2.088 8.981 2.248 8.981 0V40.165h-8.98v13.967z"
          fill="#F8CDBA"
        />
        <path
          d="M102.57 47.253c0-1.022-3.425-6.587-5.2-5.991 2.187-.792 4.395-1.442 5.2-.894v6.885z"
          fill={props.fill}
        />
        <ellipse
          fill="#F8CDBA"
          cx={96.661}
          cy={29.533}
          rx={12.053}
          ry={12.049}
        />
        <ellipse fill="#FA9E9F" cx={84.608} cy={31.187} rx={1.891} ry={1} />
        <ellipse fill="#FA9E9F" cx={96.897} cy={31.187} rx={1.891} ry={1} />
        <path
          d="M89.334 29.297c-.3.547-1.833 2.847-.215 3.78M93.116 35.44a3.331 3.331 0 001.89-1.418"
          stroke="#1F161E"
          strokeWidth={0.69}
          strokeLinecap="round"
        />
        <ellipse fill="#F8CDBA" cx={103.751} cy={30.478} rx={2.6} ry={2.599} />
        <path
          d="M124.231 130.418c13.25-.362 22.769-9.636 22.769-33.498 0-9.853-16.384-39.514-19.635-50.72-1.682-5.786-.329-22.811-8.915-31.475-4.138-4.17-22.335-3.185-27.032-1.221-4.697 1.964-4.145 7.882-3.666 8.808 1.123-.946 3.587-2.864 4.027-2.805 2.306.282 8.6 7.363 10.51 10.614 1.551 2.628-.84 18.943 2.188 23.606 3.029 4.664 13.75 2.214 13.75 2.214"
          fill="#1F161E"
        />
        <path
          d="M98.788 84.161c1.82 2.295 15.266 26.74 15.266 30.417 0 3.676-3.988 29.733-4.784 30.996 1.821 1.374 6.605 5.162 6.605 5.162s14.582-25.945 14.582-40.518c0-5.17-11.618-47.181-22.786-56.822"
          fill="#FEB900"
        />
        <path
          d="M98.788 84.11c1.804 2.302 15.125 26.82 15.125 30.507 0 1.827-.976 9.176-2.05 16.273"
          stroke="#1F161E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.53 49.374c-.556 4.845 13.536 21.752 19.027 26.703 5.485-6.934 2.193-24.11 2.193-24.11l-11.192-5.187-10.028 2.594z"
          fill="#1F161E"
        />
        <ellipse fill="#1F161E" cx={116.04} cy={6.852} rx={5.908} ry={6.852} />
        <path
          d="M82.244 60.484c-2.638 6.004-4.269 13.872-5.199 22.208"
          stroke="#1F161E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse fill="#F8CDBA" cx={5.672} cy={53.868} rx={5.672} ry={4.725} />
        <path
          d="M8.508 40.165L1.891 61.429"
          stroke={props.fill}
          strokeWidth={3}
          strokeLinecap="round"
        />
      </g>
      <g transform="translate(8 46)">
        <path
          fill="#1F161E"
          d="M119.695 84.552l9.21 3.231-7.05 20.037-9.21-3.232zM135 216.019c.392.881 2.479 6.184 3.635 6.726 2.093.973 12.932-4.61 13.225-5.348.292-.738.126-1.96-.485-2.064-.612-.104-5.45.94-6.234.79-.784-.15-2.598-1.959-2.811-3.265M0 134.808c0 35.746 11.387 43.253 22.714 43.253 28.694 0 66.023-22.984 68.13-23.87C90.183 157.95 86 253.26 86 253.808c.994.775 8.616 1.444 11.181.775C97.844 252.267 118 138.906 118 135.92c0-2.985-1.737-7.526-8.305-8.182-2.777-.278-41.412-1.88-41.412-1.88"
        />
        <path
          d="M96.674 108.858c8.722 12.595 47.67 100.511 47.324 102.566-.346 2.055-9.3 6.41-11.194 5.237-8.03-4.938-57.34-87.917-59.26-89.17-1.92-1.252-4.797-.768-6.51-.424-4.458-7.39 5.52-16.167 29.64-18.209z"
          fill="#004734"
        />
        <path
          d="M58.459 38.858c12.99 0 16.725 12.236 17.8 16.856 3.436 14.75-6.13 70.801-7.238 72.346-9.401 13.074-63.139 14.235-68.38 8.573-5.24-5.663 22.325-97.775 57.818-97.775z"
          fill="#80D464"
        />
        <path
          d="M87.997 251.858c-.171.99-1.464 6.786-.817 7.92 1.16 2.047 13.233 3.46 13.893 2.99.66-.472 1.206-1.625.771-2.049-.435-.423-5.011-2.32-5.572-2.908-.56-.587-1.028-3.195-.455-4.45"
          fill="#1F161E"
        />
        <path
          d="M56.01 41.856c-.359 1.086 9 3.836 9.36 2.755L69 33.614l-9.353-2.756-3.637 10.998z"
          fill="#F8CDBA"
        />
        <path
          d="M65 36.091c-.914-.262-7.553.443-8 1.767l2.362-7c1.455.419 5.638 5.233 5.638 5.233z"
          fill="#1F161E"
        />
        <circle fill="#F8CDBA" cx={70.5} cy={24.358} r={13.5} />
        <ellipse
          fill="#FA9E9F"
          transform="rotate(-72.67 81.597 30.532)"
          cx={81.597}
          cy={30.532}
          rx={1.221}
          ry={2.352}
        />
        <ellipse
          fill="#FA9E9F"
          transform="rotate(-72.67 67.498 26.141)"
          cx={67.498}
          cy={26.141}
          rx={1.221}
          ry={2.352}
        />
        <path
          d="M77.262 26.858c.299.689 2.073 3.706-1.262 4M71 31.858c-.75-.2-1.648-1.19-2-2"
          stroke="#1F161E"
          strokeWidth={1.2}
          strokeLinecap="round"
        />
        <path
          d="M74.518 6.497c-3.516-.444-13.724-3.51-16.044-5.639-1.098.584-5.363 4.452-8.84 9.686l4.936 5.01-6.238-2.907A25.901 25.901 0 0045 21.504c1.61 4.385 6.665 6.19 7.618 6.31 6.145.782 8.505-8.858 11.6-10.052 5.259-2.036 11.45 3.251 19.24 2.92 1.728-3.563-.52-13.117-8.94-14.185z"
          fill="#1F161E"
        />
        <circle fill="#F8CDBA" cx={58} cy={21.858} r={3} />
        <path
          d="M45.007 70.763c-.66 4.134-2.798 43.168 2.323 46.965 10.97 8.13 61.625-1.816 69.3-4.662.832-2.72 0-11.365 0-11.365-2.956-.119-40.26-.475-42.484-3.302-2.224-2.826-2.488-25.932-2.132-28.54"
          fill="#80D464"
        />
        <circle fill="#F8CDBA" cx={122} cy={106.858} r={7} />
        <path
          d="M74 97.858c-1.986-2.892-2.196-26.35-1.881-29M71 119.663c-10.49.562-19.659 0-23.651-2.87-2.277-1.623-3.141-9.852-3.349-18.935"
          stroke="#1F161E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
