import React from "react";
import { Header } from "../../components/Header/Header";
import { ProfileDropdown } from "../../components/ProfileDropdown/ProfileDropdown";
import { ViewerViewer } from "../../schema";
import styles from "./WithoutOrganizationView.module.scss";

export interface WithoutOrganizationViewProps {
  viewer: ViewerViewer;
}

export const WithoutOrganizationView: React.FC<WithoutOrganizationViewProps> = ({
  viewer,
}) => {
  return (
    <div className={styles.wrap}>
      <Header>
        <ProfileDropdown viewer={viewer} user={viewer} />
      </Header>
      <div className={styles.content}>
        You are currently not part of any organization
      </div>
    </div>
  );
};
