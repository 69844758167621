import React from "react";
import { Redirect } from "react-router-dom";
import { ApprovalRequest } from "../../components/ApprovalRequest/ApprovalRequest";
import { useActiveOrganization } from "../../hooks/useActiveOrganization";
import { useRouter } from "../../hooks/useRouter";
import {
  ViewerViewer,
  useViewerApprovalWorkflows,
  ApprovalWorkflowStatusEnum,
  UserRoleEnum,
} from "../../schema";
import { formatDatetime } from "../../services/formatDatetime";
import { formatTimeAgo } from "../../services/formatTimeAgo";
import { getWorkflowCardStatusLevel } from "../../services/getWorkflowCardStatusLevel";
import { ErrorView } from "../ErrorView/ErrorView";
import { ForbiddenView } from "../ForbiddenView/ForbiddenView";
import { LoadingView } from "../LoadingView/LoadingView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";
import styles from "./ApprovalRequestsView.module.scss";

export interface ApprovalRequestsViewProps {
  viewer: ViewerViewer;
}

export const ApprovalRequestsView: React.FC<ApprovalRequestsViewProps> = ({
  viewer,
}) => {
  // use router
  const { history } = useRouter();

  const activeOrganization = useActiveOrganization(viewer.organizations);

  const { data, loading, error } = useViewerApprovalWorkflows({
    variables: {
      approvalWorkflowStatuses: [
        ApprovalWorkflowStatusEnum.IN_PROGRESS,
        ApprovalWorkflowStatusEnum.APPROVED,
        ApprovalWorkflowStatusEnum.DECLINED,
      ],
    },
  });

  if (error !== undefined) {
    return <ErrorView error={error}></ErrorView>;
  }

  if (loading || !data) {
    return <LoadingView overlay></LoadingView>;
  }

  if (!activeOrganization) {
    return <WithoutOrganizationView viewer={viewer} />;
  }

  const approvalWorkflows = data.viewer?.approvalWorkflowsByStatus;

  const hasApproverRole = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER, UserRoleEnum.APPROVER].includes(
      role,
    ),
  );
  const hasRequesterRole = viewer.roles.some((role) =>
    [
      UserRoleEnum.ADMIN,
      UserRoleEnum.KEY_USER,
      UserRoleEnum.REQUESTER,
    ].includes(role),
  );

  if (!hasApproverRole) {
    if (hasRequesterRole) {
      return (
        <Redirect to={`/${activeOrganization.urlName}/purchase-requests`} />
      );
    } else {
      return <ForbiddenView viewer={viewer} />;
    }
  }

  return (
    <>
      {approvalWorkflows &&
        approvalWorkflows
          .sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
          .map((awf) => {
            // filter out current viewer's approval request
            const currentAr = awf.approvalRequests.find(
              (ar) => ar.approver.id === viewer.id,
            );

            return (
              <ApprovalRequest
                key={awf.id}
                purchaseRequestCreatedDate={formatTimeAgo(
                  awf.purchaseRequest?.createdDate,
                )}
                workflowUpdatedDate={formatTimeAgo(awf.updatedDate)}
                level={getWorkflowCardStatusLevel(awf.status)}
                title={awf.requestName}
                status={mapApprovalWorkflowStatusCodeToStatus(awf.status)}
                statusCode={awf.status}
                price={awf.approvalAmount ? `${awf.approvalAmount} EUR` : null}
                requester={`${awf.requestor?.firstName} ${awf.requestor?.lastName}`}
                className={styles["approval-request"]}
                dueDate={
                  currentAr?.dueDate
                    ? formatDatetime(currentAr.dueDate)
                    : undefined
                }
                creatorComment={currentAr?.creatorComment}
                onClick={() => {
                  history.push(
                    `/${activeOrganization.urlName}/approval-requests/${awf.id}`,
                  );
                }}
                code={awf.code}
              />
            );
          })}
    </>
  );
};

export function mapApprovalWorkflowStatusCodeToStatus(
  status: ApprovalWorkflowStatusEnum,
) {
  return status.replace(/_/g, " ");
}
