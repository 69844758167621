import {
  PurchaseRequestsDetailsByCodePurchaseRequestByCode,
  PurchaseRequestStatusEnum,
} from "../schema";

/**
 * Given a purchase request created from email and from portal has the same status (CREATED_FROM_EMAIL)
 * Try to look email and find if has type of InboundEmail and end with the cf.procurementflow.com address
 */
export function isCreatedByEmail(
  purchaseRequest: PurchaseRequestsDetailsByCodePurchaseRequestByCode,
) {
  return (
    purchaseRequest.status === PurchaseRequestStatusEnum.CREATED_FROM_EMAIL &&
    purchaseRequest.emails.some(
      (email) =>
        email.__typename === "InboundEmail" &&
        email.toEmail?.endsWith("cf.procurementflow.com"),
    )
  );
}
